<div class="mainContainer" fxLayout="column" fxLayoutGap="15px">
  <div fxLayout="row" fxLayoutGap="20px" class="filters">
    <div fxLayout="row" fxLayoutGap="20px">
      <mat-form-field class="select-filter">
        <mat-label>{{ 'Partition.Select' | translate }}</mat-label>
        <mat-select [(ngModel)]="filterOptions" multiple>
          <mat-option value="partitions">{{ 'Partition.Partitions' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="filterField" fxFlex>
        <input
          matInput
          (keyup)="applyFilter($event.target.value)"
          [(ngModel)]="partitionsDataService.dataSource.filter"
          placeholder="{{ 'Partition.SearchPartition' | translate }}"
        />
      </mat-form-field>
      <!-- <button mat-icon-button (click)="reloadPartitions()">
        <mat-icon>refresh</mat-icon>
      </button> -->
    </div>
    <div fxLayout="row" fxLayoutGap="25px" class="buttons-filters">
      <button class="button-filter" mat-raised-button color="primary" (click)="onNewPartitionClick()">
        {{ 'Partition.NewPartition' | translate }}
      </button>
      <button class="button-filter" mat-raised-button color="primary" (click)="notifyPartitions()">
        {{ 'Partition.NotifyPartitions' | translate }}
      </button>
    </div>
  </div>

  <div class="main-table-container mat-elevation-z8" fxFlex fxLayout="column" fxFlexFill>
    <div class="loading-shade" *ngIf="partitionsDataService.isLoadingResults">
      <mat-spinner *ngIf="partitionsDataService.isLoadingResults"></mat-spinner>
    </div>

    <div class="table-container" fxFlex>
      <table mat-table [dataSource]="partitionsDataService.dataSource" matSort>
        <!-- Nombre -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Partition.Name' | translate }}</th>
          <td
            onKeyPress
            onKeyDown
            onKeyUp
            mat-cell
            *matCellDef="let element"
            class="pointer"
            (click)="onCellPartitionClick(element._id, $event)"
          >
            {{ element.name }}
          </td>
        </ng-container>

        <!-- Cuenta -->
        <ng-container matColumnDef="account">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Partition.Account' | translate }}</th>
          <td
            onKeyPress
            onKeyDown
            onKeyUp
            mat-cell
            *matCellDef="let element"
            class="pointer"
            (click)="onCellPartitionClick(element._id, $event)"
          >
            {{ element.account }}
          </td>
        </ng-container>

        <!-- Dispositivo -->
        <ng-container matColumnDef="mac">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Partition.Panel' | translate }}</th>
          <td
            onKeyPress
            onKeyDown
            onKeyUp
            mat-cell
            *matCellDef="let element"
            class="pointer"
            (click)="onCellPartitionClick(element._id, $event)"
          >
            {{ element.mac }}
          </td>
        </ng-container>

        <!-- Receptora -->
        <ng-container matColumnDef="receiver">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Partition.Receiver' | translate }}</th>
          <td
            onKeyPress
            onKeyDown
            onKeyUp
            mat-cell
            *matCellDef="let element"
            class="pointer"
            (click)="onCellPartitionClick(element._id, $event)"
          >
            {{ element.receiver }}
          </td>
        </ng-container>

        <!-- Company -->
        <ng-container matColumnDef="company" *ngIf="this.authService.isAdmin()">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'AppComponent.Company' | translate }}</th>
          <td
            onKeyPress
            onKeyDown
            onKeyUp
            mat-cell
            *matCellDef="let element"
            class="pointer"
            (click)="onCellPartitionClick(element._id, $event)"
          >
            {{ this.authService.getCompanyName(element.company) }}
          </td>
        </ng-container>

        <!-- Número de partición -->
        <ng-container matColumnDef="partitionNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div class="header-align">{{ 'Partition.PartitionNumber' | translate }}</div>
          </th>
          <td
            onKeyPress
            onKeyDown
            onKeyUp
            mat-cell
            *matCellDef="let element"
            class="pointer horizontal-align-text"
            (click)="onCellPartitionClick(element._id, $event)"
          >
            {{ element.partitionNumber }}
          </td>
        </ng-container>

        <!-- Soporte -->
        <ng-container matColumnDef="supportEnabled">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div class="header-align">{{ 'Partition.Support' | translate }}</div>
          </th>
          <td
            onKeyPress
            onKeyDown
            onKeyUp
            mat-cell
            *matCellDef="let element"
            class="pointer horizontal-align-text"
            (click)="onCellPartitionClick(element._id, $event)"
          >
            {{ (element.supportEnabled ? 'Partition.Yes' : 'Partition.No') | translate }}
          </td>
        </ng-container>

        <!-- Usuarios -->
        <ng-container matColumnDef="users">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div class="header-align">{{ 'Partition.Users' | translate }}</div>
          </th>
          <td
            onKeyPress
            onKeyDown
            onKeyUp
            mat-cell
            *matCellDef="let element"
            class="pointer horizontal-align-text"
            (click)="onCellPartitionClick(element._id, $event)"
          >
            {{ element.users ? element.users.length : 0 }}
          </td>
        </ng-container>

        <!-- Acciones -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ 'Partition.Actions' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <button
              *ngIf="authService.user.enableTechSupport && element.mac"
              mat-icon-button
              matTooltip="{{ 'Panel.AddToConsole' | translate }}"
              matTooltipShowDelay="750"
              matTooltipPosition="after"
              (click)="addToTechSupport($event, element)"
              aria-hidden="false"
            >
              <ng-container *ngIf="element.selectedToSupport; else deviceAdd">
                <img
                  src="assets/icons/menues/device-remove-support.svg"
                  alt="device remove support icon"
                  width="30"
                  height="30"
                />
              </ng-container>
              <ng-template #deviceAdd>
                <img
                  src="assets/icons/menues/device-add-support.svg"
                  alt="device add support icon"
                  width="30"
                  height="30"
                />
              </ng-template>
            </button>
            <button
              mat-icon-button
              matTooltip="{{ 'Partition.Edit' | translate }}"
              matTooltipShowDelay="750"
              matTooltipPosition="after"
              (click)="updatePartition(element._id)"
              aria-hidden="false"
              aria-label="Editar"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              *ngIf="element.active"
              mat-icon-button
              matTooltip="{{ 'Partition.Deactivate' | translate }}"
              matTooltipShowDelay="750"
              matTooltipPosition="after"
              (click)="desactivatePartition(element._id)"
              aria-hidden="false"
              aria-label="Desactivar"
            >
              <mat-icon>block</mat-icon>
            </button>
            <button
              *ngIf="!element.active"
              mat-icon-button
              matTooltip="{{ 'Partition.Activate' | translate }}"
              matTooltipShowDelay="750"
              matTooltipPosition="after"
              (click)="activatePartition(element._id)"
              aria-hidden="false"
              aria-label="Activar"
            >
              <mat-icon>arrow_upward</mat-icon>
            </button>
            <button
              *ngIf="!element.active"
              mat-icon-button
              matTooltip="{{ 'Partition.Delete' | translate }}"
              matTooltipShowDelay="750"
              matTooltipPosition="after"
              (click)="deletePartition(element._id)"
              aria-hidden="false"
              aria-label="Eliminar"
            >
              <mat-icon>delete</mat-icon>
            </button>
            <button
              *ngIf="element.keyboard"
              mat-icon-button
              matTooltip="{{ 'Partition.Keyboard' | translate }}"
              matTooltipShowDelay="750"
              matTooltipPosition="after"
              (click)="openKeyboard(element.account, element.mac, element.apiKey)"
              aria-hidden="false"
              aria-label="Eliminar"
            >
              <mat-icon>dialpad_icon</mat-icon>
            </button>
            <button
              *ngIf="element.active"
              mat-icon-button
              matTooltip="{{ 'Partition.Notify' | translate }}"
              matTooltipShowDelay="750"
              matTooltipPosition="after"
              (click)="notifyPartition(element.mac, element.account, element.company)"
              aria-hidden="false"
              aria-label="Notificar"
            >
              <mat-icon>message</mat-icon>
            </button>
            <app-partition-alarm-trigger
              *ngIf="!element.isCommunicator && element.mac && !partitionsDataService.isLoadingResults"
              [partition]="element"
              [isLoadingResults]="!partitionsDataService.isLoadingResults"
            >
            </app-partition-alarm-trigger>
            <button
              *ngIf="element.mac"
              mat-icon-button
              matTooltipShowDelay="750"
              matTooltipPosition="after"
              matTooltip="{{ 'Panel.GoToMap' | translate }}"
              aria-hidden="false"
              (click)="panelStatusService.goToMap(element.mac, element.apiKey)"
            >
              <img style="width: 25px" src="assets/icons/image_maps.png" alt="maps" />
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{ disabled: !row.active && color[row._id] !== 'over', onMousehover: color[row._id] === 'over' }"
          (mouseover)="color[row._id] = 'over'"
          (mouseout)="color[row._id] = 'out'"
          onBlur
          onFocus
        >
          >
        </tr>
      </table>
    </div>

    <mat-paginator class="mat-paginator-sticky" [pageSize]="20" [pageSizeOptions]="[20, 50, 100]"></mat-paginator>
  </div>
</div>
