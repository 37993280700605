export const countryMasks: { [prefix: string]: string } = {
  '54': '(+00) (00) 0000-0000', // Argentina
  '591': '(+000) 0 000-0000', // Bolivia
  '55': '(+00) (00) 00000-0000', // Brazil
  '56': '(+00) 0 0000-0000', // Chile
  '57': '(+00) (0) 000-0000', // Colombia
  '506': '(+000) 0000-0000', // Costa Rica
  '53': '(+00) 0 000-0000', // Cuba
  '593': '(+000) 0 000-0000', // Ecuador
  '503': '(+000) 0000-0000', // El Salvador
  '502': '(+000) 0000-0000', // Guatemala
  '504': '(+000) 0000-0000', // Honduras
  '52': '(+00) (00) 0000-0000', // Mexico
  '505': '(+000) 0000-0000', // Nicaragua
  '507': '(+000) 0000-0000', // Panama
  '595': '(+000) 000 000-000', // Paraguay
  '51': '(+00) (00) 000-0000', // Peru
  '1 787': '(+0 000) 000-0000', // Puerto Rico
  '1 809': '(+0 000) 000-0000', // Dominican Republic
  '598': '(+000) 00 000-000', // Uruguay
  '58': '(+00) (000) 000-0000', // Venezuela
};

export const countryMasksList = [
  '54',
  '591',
  '55',
  '56',
  '57',
  '506',
  '53',
  '593',
  '503',
  '502',
  '504',
  '52',
  '505',
  '507',
  '595',
  '51',
  '1787',
  '1809',
  '598',
  '58',
];

export function getPhoneMask(phone: string): string {
  for (const prefix in countryMasks) {
    if (phone?.startsWith(prefix)) {
      return countryMasks[prefix];
    }
  }

  return '(+00) 0000000000000';
}

export function formatPhoneNumber(phone: string): string {
  for (const prefix in countryMasks) {
    if (phone?.startsWith(prefix)) {
      const mask = countryMasks[prefix];
      const cleanPhone = phone.replace(/\D/g, '');
      let formattedPhone = '';
      let phoneIndex = 0;

      // Aplica a máscara no número
      for (const char of mask) {
        if (char === '0') {
          formattedPhone += cleanPhone[phoneIndex] || '';
          phoneIndex++;
        } else {
          formattedPhone += char;
        }
      }

      return formattedPhone;
    }
  }

  return phone;
}
