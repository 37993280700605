import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelListComponent } from './components/panel-list/panel-list.component';
import { AppMaterialModule } from '../app-material/app-material.module';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PanelEditComponent } from './components/panel-edit/panel-edit.component';
import { PanelInfoComponent } from './components/panel-info/panel-info.component';
import { ConfigPanelComponent } from './components/config-panel/config-panel.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { PanelPasswordComponent } from './components/panel-password/panel-password.component';
import { MatSelectModule } from '@angular/material/select';
import { SignalStrengthComponent } from 'src/app/signal-strength/signal-strength.component';
import { PanelErrorsInfoButtonComponent } from './components/panel-errors-info-button/panel-errors-info-button.component';
import { PanelErrorsComponent } from './components/panel-errors/panel-errors.component';
import { PanelErrorsTableComponent } from './components/panel-errors-table/panel-errors-table.component';
import { PartitionsErrorsDetailsSummaryComponent } from '../partitions/partitions-errors-details-summary/partitions-errors-details-summary.component';
import { PanelErrorsInfoButtonWrapperComponent } from './components/panel-errors-info-button-wrapper/panel-errors-info-button-wrapper.component';
import { DatabusConfigurationComponent } from './components/databus-configuration/databus-configuration.component';
import { PanelDialogPartitionComponent } from './components/panel-dialog-partition/panel-dialog-partition.component';
import { PanelDialogComponent } from './components/panel-dialog/panel-dialog.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { GenericDialogComponent } from '../shared/components/generic-dialog/generic-dialog.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TooltipDeitresModule } from '../common/ui/tooltip/tooltipDeitres.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [
    PanelListComponent,
    PanelInfoComponent,
    PanelEditComponent,
    ConfigPanelComponent,
    PanelPasswordComponent,
    SignalStrengthComponent,
    PanelErrorsInfoButtonComponent,
    PanelErrorsInfoButtonWrapperComponent,
    PanelErrorsComponent,
    PanelErrorsTableComponent,
    PartitionsErrorsDetailsSummaryComponent,
    DatabusConfigurationComponent,
    PanelDialogComponent,
    PanelDialogPartitionComponent,
    GenericDialogComponent,
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatSlideToggleModule,
    TranslateModule,
    MatSelectModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    TooltipDeitresModule,
    AngularSvgIconModule.forRoot(),
    BrowserAnimationsModule,
    MatMenuModule,
    ToastrModule.forRoot({ timeOut: 0 }),
  ],
})
export class PanelModule {}
