import { Component, Input } from '@angular/core';
import { HoverMenuesService } from '../../services/hover-menues.service';

interface Params {
  icon: string;
  title: string;
  description: string;
  confirm: string;
  onConfirm;
  onClose;
}

@Component({
  selector: 'app-message-bubble',
  templateUrl: './message-bubble.component.html',
  styleUrls: ['./message-bubble.component.scss']
})
export class MessageBubbleComponent {
  @Input() params: Params;
  @Input() showMenu: boolean = false;
  @Input() heightReference: any = 0;

  constructor(
    public hoverMenuesService: HoverMenuesService,
  ) { }
  
  calcOffset = () => {
    const isSmallScreen = window.innerWidth <= 1300;

    let sideMenu = 205;
    let noneOpen = 75;

    if (isSmallScreen) {
      sideMenu = 160;
      noneOpen = 65;
    }

    return (this.showMenu ? sideMenu : noneOpen) +10;
  };

  onClose() {
    this.params.onClose();
  }

  onConfirm() {
    if (this.params.onConfirm) this.params.onConfirm();
  }
}
