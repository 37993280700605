import { PartitionListComponent } from './partitions/components/partition-list/partition-list.component';
import { AuthGuardService } from './auth/services/auth-guard.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginFormComponent } from './auth/login-form/login-form.component';
import { UserListComponent } from './users/components/user-list/user-list.component';
import { PanelListComponent } from './panel/components/panel-list/panel-list.component';
import { KeyboardComponent } from './keyboard/components/keyboard/keyboard.component';
import { TechSupportComponent } from './tech-support/tech-support/tech-support.component';
import { MapViewComponent } from './map/components/map-view/map-view.component';
import { SettingsComponent } from './settings/components/settings/settings.component';
import { ProfileComponent } from './profile/profile/profile.component';
// import { ReceiverSelectorComponent } from './stadistics/components/receiver-selector/receiver-selector.component';

const routes: Routes = [
  // Provisorio hasta tener otros componentes
  { path: '', component: LoginFormComponent, pathMatch: 'full', canActivate: [AuthGuardService] },
  { path: 'login', component: LoginFormComponent, canActivate: [AuthGuardService] },
  { path: 'partitions', component: PartitionListComponent, canActivate: [AuthGuardService] },
  { path: 'users', component: UserListComponent, canActivate: [AuthGuardService] },
  { path: 'panels', component: PanelListComponent, canActivate: [AuthGuardService] },
  { path: 'keyboard', component: KeyboardComponent, canActivate: [AuthGuardService] },
  { path: 'techsupport', component: TechSupportComponent, canActivate: [AuthGuardService] },
  { path: 'maps', component: MapViewComponent, canActivate: [AuthGuardService] },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuardService] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
