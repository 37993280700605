
<div class="info-title">{{ 'Settings.RequiredFields' | translate }}</div>
<div class="info-details">{{ 'Settings.RequiredFieldsDetails' | translate }}</div>
<div class="info-details" style="padding-bottom: 20px;">{{ 'Settings.RequiredFieldsDetails_2' | translate }}</div>

<form  *ngIf="editInfoForm" [formGroup]="editInfoForm" (ngSubmit)="onSubmit()">
    <div class="expanded-info-parent">
        <div class="expanded-info-column">
            <div class="expanded-info-title">
                {{ 'Settings.Company' | translate }}
            </div> 
            <input 
                class="expanded-info-input" 
                formControlName="name"
                matInput required 
                [(ngModel)]="companiesService.currentCompany.name"
            >
            <div *ngIf="getError('name')" class="expanded-info-detail error">
                <span *ngIf="getErrorType('name', 'required')">{{ 'Settings.ErrorControl.RequiredField' | translate }}</span>
            </div>
            <div *ngIf="!getError('name')" class="expanded-info-detail">
                {{ 'Settings.FieldDetails.Company' | translate }}
            </div>
        </div>
        <div class="expanded-info-column">
            <div class="expanded-info-title">
                {{ 'Settings.EZVIZAccountName' | translate }}
            </div>
            <input 
                class="expanded-info-input" 
                matInput 
                formControlName="EZVIZAccountName"
                [(ngModel)]="companiesService.currentCompany.EZVIZAccountName"
            >
            <div *ngIf="getError('EZVIZAccountName')" class="expanded-info-detail error">
                <span *ngIf="getErrorType('EZVIZAccountName', 'invalidParam')">{{ 'Settings.ErrorControl.EZVIZAccountName' | translate }}</span>
            </div>
            <div *ngIf="!getError('EZVIZAccountName')" class="expanded-info-detail">
                {{ 'Settings.FieldDetails.EZVIZAccountName' | translate }}
            </div>
        </div>
        <div class="expanded-info-column">
            <div class="expanded-info-title">
                {{ 'Settings.Email' | translate }}
            </div>
            <input 
                class="expanded-info-input" 
                matInput required 
                formControlName="email"
                [(ngModel)]="companiesService.currentCompany.email"
            >
            <div *ngIf="getError('email')" class="expanded-info-detail error">
                <span *ngIf="getErrorType('email', 'required')">{{ 'Settings.ErrorControl.RequiredField' | translate }}</span>
                <span *ngIf="getErrorType('email', 'invalidParam')">{{ 'Settings.ErrorControl.EmailFormat' | translate }}</span>
            </div>
            <div *ngIf="!getError('email')" class="expanded-info-detail">
                {{ 'Settings.FieldDetails.Email' | translate }}
            </div>
        </div>
    </div>
    <div class="info-title">{{ 'Settings.OptionalFields' | translate }}</div>

    <div class="expanded-info-parent">
        <div class="expanded-info-column">
            <div class="expanded-info-title">
                {{ 'Settings.Contact' | translate }}
            </div>
            <input 
                class="expanded-info-input" 
                matInput 
                formControlName="contact"
                [(ngModel)]="companiesService.currentCompany.contact"
            >
            <div *ngIf="getError('contact')" class="expanded-info-detail error">
                <span *ngIf="getErrorType('contact', 'invalidParam')">{{ 'Settings.ErrorControl.EmailFormat' | translate }}</span>
            </div>
            <div *ngIf="!getError('contact')" class="expanded-info-detail">
                {{ 'Settings.FieldDetails.Contact' | translate }}
            </div>
            
            <div class="expanded-info-title">
                {{ 'Settings.Language' | translate }}
            </div>
            <div 
                fxLayout="row" 
                fxLayoutGap="20px" 
                class="selector-field" 
                id="language-selector"
                (click)="onContextalMenu($event, !expand, contextualMenuType.Language, 'language-selector-edit-info')"
                onKeyPress
                onKeyDown
                onKeyUp
            >
                <div> {{ ('Settings.Languages.' + companiesService.currentCompany?.language) | translate }} </div> 
                <mat-icon id="language-selector-edit-info" class="language-selector">keyboard_arrow_down</mat-icon>
            </div>
            <div class="expanded-info-detail">
                {{ 'Settings.FieldDetails.Language' | translate }}
            </div>
            
            <div class="expanded-info-title">
                {{ 'Settings.WhatsappNumber' | translate }}
            </div>
            <input matInput 
                type="text"
                class="expanded-info-input" 
                formControlName="whatsappNumber"
                [(ngModel)]="companiesService.currentCompany.whatsappNumber"
                mask="{{ mask }}"
                dropSpecialCharacters="false"
            >
            <div class="expanded-info-detail">
                {{ 'Settings.FieldDetails.WhatsappNumber' | translate }}
            </div>
        </div>
        <div class="expanded-info-column">
            <div class="expanded-info-title">
                {{ 'Settings.NotificationEmail' | translate }}
            </div>
            <input 
                class="expanded-info-input" 
                matInput 
                formControlName="notificationEmail" 
                [(ngModel)]="notificationEmail"
            >
            <div *ngIf="getError('notificationEmail')" class="expanded-info-detail error">
                <span *ngIf="getErrorType('notificationEmail', 'invalidParam')">{{ 'Settings.ErrorControl.EmailFormat' | translate }}</span>
            </div>
            <div *ngIf="!getError('notificationEmail')" class="expanded-info-detail">
                {{ 'Settings.FieldDetails.NotificationEmail' | translate }}
            </div>

            <div class="expanded-info-title">
                {{ 'Settings.WebLink' | translate }}
            </div>
            <input 
                class="expanded-info-input" 
                matInput 
                formControlName="webUrl"
                [(ngModel)]="companiesService.currentCompany.webUrl"
            >
            <div *ngIf="getError('webUrl')" class="expanded-info-detail error">
                <span *ngIf="getErrorType('webUrl', 'invalidParam')">{{ 'Settings.ErrorControl.UrlFormat' | translate }}</span>
            </div>
            <div *ngIf="!getError('webUrl')" class="expanded-info-detail">
                {{ 'Settings.FieldDetails.WebLink' | translate }}
            </div>
            
            <div class="expanded-info-title">
                {{ 'Settings.Location' | translate }}
            </div>
            <input 
                class="expanded-info-input" 
                matInput 
                formControlName="location"
                [(ngModel)]="location"
            >
            <div *ngIf="getError('location')" class="expanded-info-detail error">
                <span *ngIf="getErrorType('location', 'invalidParam')">{{ 'Settings.ErrorControl.CoordFormat' | translate }}</span>
            </div>
            <div *ngIf="!getError('location')" class="expanded-info-detail">
                {{ 'Settings.FieldDetails.Location' | translate }}
            </div>
        </div>
        <div class="expanded-info-column">
            <div class="expanded-info-title">
                {{ 'Settings.TechUserCode' | translate }}
            </div>
            <input 
                class="expanded-info-input" 
                matInput 
                formControlName="techUserCode"
                [ngModel]="companiesService.currentCompany.techUserCode"
            >
            <div *ngIf="getError('techUserCode')" class="expanded-info-detail error">
                <span *ngIf="getErrorType('techUserCode', 'invalidParam')">{{ 'Settings.ErrorControl.CodeLenght' | translate }}</span>
            </div>
            <div *ngIf="!getError('techUserCode')" class="expanded-info-detail">
                {{ 'Settings.FieldDetails.TechUserCode' | translate }}
            </div>

            <div class="expanded-info-title">
                {{ 'Settings.LinkName' | translate }}
            </div>
            <input 
                class="expanded-info-input" 
                matInput 
                formControlName="linkName"
                [(ngModel)]="companiesService.currentCompany.linkName"
            >
            <div class="expanded-info-detail">
                {{ 'Settings.FieldDetails.LinkName' | translate }}
            </div>
        </div>
    </div>
</form>

<ul
    *ngIf="expand"
    [ngStyle]="{'position': 'absolute', 'top.px': contextualMenuService.menuPosition.top, 'left.px': contextualMenuService.menuPosition.left}"
    id="menu-contextual"
    class="button-container"
    [style.width]="contextualMenuWidth"
>
    <button
        *ngFor="let value of contextualMenuService.getMenuContent()"
        class="table-row"
        (click)="setLanguage(value)"
        (keypress)="setLanguage(value)"
        matTooltipShowDelay="750"
        matTooltipPosition="after"
    >
        {{ (contextualMenuService.getTranslateKey(value)) | translate }}
    </button>
</ul>

