<div class="mainContainer" fxLayout="column" fxLayoutGap="15px">
  <div fxLayout="row" fxLayoutGap="20px" class="filters">
    <div fxLayout="row" fxLayoutGap="20px">
      <mat-form-field class="select-filter">
        <mat-label>{{ 'User.Select' | translate }}</mat-label>
        <mat-select [(ngModel)]="filterOptions" multiple>
          <mat-option value="users">{{ 'User.Users' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="filterField" fxFlex>
        <input
          matInput
          (keyup)="applyFilter($event.target.value)"
          [(ngModel)]="usersDataService.dataSource.filter"
          placeholder="{{ 'User.SearchUser' | translate }}"
        />
      </mat-form-field>
      <!-- <button mat-icon-button (click)="getUsers()">
        <mat-icon>refresh</mat-icon>
      </button> -->
    </div>
    <div fxLayout="row" fxLayoutGap="25px" class="buttons-filters">
      <button class="button-filter" mat-raised-button color="primary" (click)="onNewUserClick()">
        {{ 'User.NewUser' | translate }}
      </button>
      <button class="button-filter" mat-raised-button color="primary" (click)="notifyUsers()">
        {{ 'User.NotifyUsers' | translate }}
      </button>
    </div>
  </div>

  <div class="main-table-container mat-elevation-z8" fxFlex fxLayout="column" fxFlexFill>
    <div class="loading-shade" *ngIf="usersDataService.isLoadingResults">
      <mat-spinner *ngIf="usersDataService.isLoadingResults"></mat-spinner>
    </div>

    <div class="table-container" fxFlex>
      <table mat-table [dataSource]="usersDataService.dataSource" matSort>
        <!-- Email -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'User.Email' | translate }}</th>
          <td
            mat-cell
            *matCellDef="let element"
            class="pointer"
            (click)="onCellUserClick(element._id, $event)"
            onKeyPress
            onKeyDown
            onKeyUp
          >
            {{ element.email }}
          </td>
        </ng-container>

        <!-- Company -->
        <ng-container matColumnDef="company" *ngIf="this.authService.isAdmin()">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'AppComponent.Company' | translate }}</th>
          <td
            mat-cell
            *matCellDef="let element"
            class="pointer"
            (click)="onCellUserClick(element._id, $event)"
            onKeyPress
            onKeyDown
            onKeyUp
          >
            {{ this.authService.getCompanyName(element.company) }}
          </td>
        </ng-container>

        <!-- Nombre -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'User.Name' | translate }}</th>
          <td
            mat-cell
            *matCellDef="let element"
            class="pointer"
            (click)="onCellUserClick(element._id, $event)"
            onKeyPress
            onKeyDown
            onKeyUp
          >
            {{ element.name }}
          </td>
        </ng-container>

        <!-- Apellido -->
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'User.Surname' | translate }}</th>
          <td mat-cell *matCellDef="let element" class="pointer" (click)="onCellUserClick(element._id, $event)">
            {{ element.lastName }}
          </td>
        </ng-container>

        <!-- Tipo -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'User.Type' | translate }}</th>
          <td mat-cell *matCellDef="let element" class="pointer" (click)="onCellUserClick(element._id, $event)">
            {{
              (element.type === 'tech' ? 'User.Technician' : element.type === 'final' ? 'User.Final' : 'User.Unknown')
                | translate
            }}
          </td>
        </ng-container>

        <!-- Tiempo de acceso seguro -->
        <ng-container matColumnDef="secureAccessTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div class="header-align">{{ 'User.AccessTime' | translate }}</div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="pointer horizontal-align-text"
            (click)="onCellUserClick(element._id, $event)"
          >
            {{ element.secureAccessTime ? element.secureAccessTime / 1000 : 0 }}
          </td>
        </ng-container>

        <!-- Particiones -->
        <ng-container matColumnDef="partitions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div class="header-align">{{ 'User.Partitions' | translate }}</div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="pointer horizontal-align-text"
            (click)="onCellUserClick(element._id, $event)"
          >
            {{ element.partitions ? element.partitions.length : 0 }}
          </td>
        </ng-container>

        <!-- Activo -->
        <ng-container matColumnDef="active">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div class="header-align">{{ 'User.UserActive' | translate }}</div>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="pointer horizontal-align-text"
            (click)="onCellUserClick(element._id, $event)"
          >
            {{ (element.active ? 'User.Yes' : 'User.No') | translate }}
          </td>
        </ng-container>

        <!-- Acciones -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{ 'User.Actions' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              matTooltip="{{ 'User.Edit' | translate }}"
              matTooltipShowDelay="750"
              matTooltipPosition="after"
              (click)="updateUser(element._id)"
              aria-hidden="false"
              aria-label="Editar"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              *ngIf="element.active"
              mat-icon-button
              matTooltip="{{ 'User.Deactivate' | translate }}"
              matTooltipShowDelay="750"
              matTooltipPosition="after"
              (click)="deactivateUser(element)"
              aria-hidden="false"
              aria-label="Desactivar"
            >
              <mat-icon>block</mat-icon>
            </button>
            <button
              *ngIf="!element.active"
              mat-icon-button
              matTooltip="{{ 'User.Activate' | translate }}"
              matTooltipShowDelay="750"
              matTooltipPosition="after"
              (click)="activateUser(element)"
              aria-hidden="false"
              aria-label="Activar"
            >
              <mat-icon>arrow_upward</mat-icon>
            </button>
            <button
              *ngIf="!element.active"
              mat-icon-button
              matTooltip="{{ 'User.Delete' | translate }}"
              matTooltipShowDelay="750"
              matTooltipPosition="after"
              (click)="deleteUser(element._id)"
              aria-hidden="false"
              aria-label="Eliminar"
            >
              <mat-icon>delete</mat-icon>
            </button>
            <button
              *ngIf="element.active"
              mat-icon-button
              matTooltip="{{ 'User.Notify' | translate }}"
              matTooltipShowDelay="750"
              matTooltipPosition="after"
              (click)="notifyUser(element._id, element.company, element.email)"
              aria-hidden="false"
              aria-label="Notificar"
            >
              <mat-icon>message</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{ disabled: !row.active && color[row._id] !== 'over', onMousehover: color[row._id] === 'over' }"
          (mouseover)="color[row._id] = 'over'"
          (mouseout)="color[row._id] = 'out'"
        ></tr>
      </table>
    </div>

    <mat-paginator class="mat-paginator-sticky" [pageSize]="20" [pageSizeOptions]="[20, 50, 100]"></mat-paginator>
  </div>
</div>
