
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import * as pkg from '../package.json';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const { version } = pkg;
Sentry.init({
  dsn: 'https://62b82b470cc8fc6702f884e43e03e4e2@o4509050786152448.ingest.us.sentry.io/4509079038001152',
  environment: environment.env === 'Production' ? 'production' : 'development',
  release: version,
  enabled: environment.production,
  ignoreErrors: ["ExpressionChangedAfterItHasBeenCheckedError"],
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
