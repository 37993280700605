import { Component, Inject } from '@angular/core';
import { Partition } from '../../models/partition';
import { AuthService } from 'src/app/auth/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogData } from 'src/app/users/components/user-form/user-form.component';
import { PartitionsService } from '../../services/partitions.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-partition-info',
  templateUrl: './partition-info.component.html',
  styleUrls: ['./partition-info.component.scss']
})
export class PartitionInfoComponent {

  isLoading = false;
  partition: Partition = new Partition();

  constructor(
    public authService: AuthService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<PartitionInfoComponent>,
    private loadingBar: LoadingBarService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    private abm: PartitionsService
  ) {
    dialogRef.disableClose = true;

    dialogRef.backdropClick().subscribe(() => this.onCloseClick());
    this.loadingBar.useRef().start();
    this.abm.getPartition(this.data.id).subscribe({
      next: async (res: any) => {
        if (res.success) {
          this.partition = {...res.partition};
          if (this.partition.users && this.partition.users.length > 0) {
            this.partition.users.sort((a , b) =>  {
              if (`${a.user?.lastName} ${a.user?.name}` > `${b.user?.lastName} ${b.user?.name}`) {
                return +1;
              }
              if (`${a.user?.lastName} ${a.user?.name}` < `${b.user?.lastName} ${b.user?.name}`) {
                return -1;
              }
              return 0;
            });
          }
          this.loadingBar.useRef().complete();
        } else {
          this.dialogRef.close({ status: 'error', message: await this.translateService.get('Partition.ErrorGetUser').toPromise() });
          this.loadingBar.useRef().stop();
        }
      },
      error: async (error) => {
        this.loadingBar.useRef().stop();

        if (error && error.status === 401) {
          this.dialogRef.close({ status: 'error', message: await this.translateService.get('Shared.SessionExpired').toPromise() });
          this.authService.logout();
        } else {
          this.dialogRef.close({ status: 'error', message: await this.translateService.get('Partition.ErrorGetUser').toPromise() });
        }
      }
    });
  }

  onCloseClick = () => {
    this.dialogRef.close({ status: 'cancel' });
  }
}
