<div class="info-title">{{ 'Settings.LogoAndColorsCompany' | translate }}</div>
<div class="logo-box">
    <div class="line-pointed-box center" style="margin-right: 1%;">
        <div class="icon-container">
            <img
                *ngIf="companiesService.currentCompany.logo"
                class="img"
                alt
                [src]="companiesService.currentCompany.logo"
                alt
            />
            <img
                *ngIf="!companiesService.currentCompany.logo"
                src="assets/icons/upload.svg"
                alt
                width="50"
                height="50"
            />
            <button *ngIf="companiesService.currentCompany.logo" class="close-button" (click)="removeIcon()">✖</button>
          </div>
        <div class="upload-logo-title">{{ 'Settings.UploadLogoTitle' | translate }}</div>
        <div class="upload-logo-detail">
            <p [innerHTML]="mensaje"></p>
        </div>
        <button mat-raised-button color="primary" (click)="fileInput.click()">
            <img
                *ngIf="companiesService.currentCompany.logo"
                src="assets/icons/upload.svg"
                alt
                width="15"
                height="15"
            />
            {{ 'Settings.UploadLogo' | translate }}
        </button>
        <input type="file" #fileInput (change)="onFileSelected($event)" accept="image/*" hidden>
    </div>
    <div class="line-pointed-box colors-container" style="margin-left: 1%;">
        <div class="row">
            <div class="upload-logo-title">{{ 'Settings.PrimaryColor' | translate }}</div>
        </div>
        <div class="row wide-box">
            <ng-container *ngFor="let color of primaryColors">
                <div 
                  class="color-circle" 
                  [style.background-color]="color.color" 
                  [style.color]="color.selectedColor"
                  (click)="setColor(color, 'primary')"
                  onKeyPress
                  onKeyDown
                  onKeyUp
                >
                    <mat-icon *ngIf="color.selected">check</mat-icon>
                </div>
            </ng-container>
            <div 
              *ngIf="!customColorSelected.primary" 
              class="rainbow-circle" 
              (click)="onSelectNewColor('primary')" 
              onKeyPress
              onKeyDown
              onKeyUp
            >
                <span style="color: white">+</span>
            </div>
            <div *ngIf="customColorSelected.primary" 
                class="color-circle" 
                [style.background-color]="companiesService.currentCompany.colorPalette.primary" 
                style.color="#000"
                (click)="onSelectNewColor('primary')"
                onKeyPress
                onKeyDown
                onKeyUp
            >
                <mat-icon>check</mat-icon>
            </div>
        </div>
        <div class="row">
            <div class="upload-logo-title">{{ 'Settings.SecondaryColor' | translate }}</div>
        </div>
        <div class="row wide-box">
            <ng-container *ngFor="let color of secondaryColors">
                <div 
                  class="color-circle" 
                  [style.background-color]="color.color" 
                  [style.color]="color.selectedColor"
                  (click)="setColor(color, 'secondary')"
                  onKeyPress
                  onKeyDown
                  onKeyUp
                >
                  <mat-icon *ngIf="color.selected">check</mat-icon>
                </div>
            </ng-container>
            <div 
              *ngIf="!customColorSelected.secondary" 
              class="rainbow-circle" 
              (click)="onSelectNewColor('secondary')"
              onKeyPress
              onKeyDown
              onKeyUp
            >
                <span style="color: white">+</span>
            </div>
            <div *ngIf="customColorSelected.secondary" 
                class="color-circle" 
                [style.background-color]="companiesService.currentCompany.colorPalette.secondary" 
                style.color="#000"
                (click)="onSelectNewColor('secondary')"
                onKeyPress
                onKeyDown
                onKeyUp
            >
                <mat-icon>check</mat-icon>
            </div>
        </div>
    </div>
</div>

<app-color-selector *ngIf="showColorSelector" (accion)="onNewColor($event)"></app-color-selector>