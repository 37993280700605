<!-- Map Type switch -->
<div class="map-type-menu-container prevent-select">
  <div class="map-type-grid-container">
    <div
      onKeyPress
      onKeyDown
      onKeyUp
      class="map-type-container"
      [ngClass]="{ 'active-map-type': customMapService.showPlaces }"
      (click)="setPlaces(!customMapService.showPlaces)"
    >
      <img alt matTooltip="{{ 'MeshNet.Places' | translate }}" class="map-type-icon" src="assets/icons/hospital.png" />
      <div class="map-type-label">{{ 'MeshNet.Places' | translate }}</div>
    </div>
    <div
      onKeyPress
      onKeyDown
      onKeyUp
      class="map-type-container"
      [ngClass]="{ 'active-map-type': customMapService.mapTypeId == 'terrain' }"
      (click)="setMapType('terrain')"
    >
      <img alt matTooltip="{{ 'MeshNet.Terrain' | translate }}" class="map-type-icon" src="assets/icons/relive.png" />
      <div class="map-type-label">{{ 'MeshNet.TerrainCaption' | translate }}</div>
    </div>
    <div
      onKeyPress
      onKeyDown
      onKeyUp
      class="map-type-container"
      [ngClass]="{ 'active-map-type': customMapService.mapTypeId == 'roadmap' }"
      (click)="setMapType('roadmap')"
    >
      <img alt matTooltip="{{ 'MeshNet.Roadmap' | translate }}" class="map-type-icon" src="assets/icons/mapa.png" />
      <div class="map-type-label">{{ 'MeshNet.RoadmapCaption' | translate }}</div>
    </div>
    <div
      onKeyPress
      onKeyDown
      onKeyUp
      class="map-type-container"
      [ngClass]="{ 'active-map-type': customMapService.mapTypeId == 'satellite' }"
      (click)="setMapType('satellite')"
    >
      <img
        alt
        matTooltip="{{ 'MeshNet.Satellite' | translate }}"
        class="map-type-icon"
        src="assets/icons/satelite.png"
      />
      <div class="map-type-label">{{ 'MeshNet.SatelliteCaption' | translate }}</div>
    </div>
  </div>
</div>
