import { Component, Inject, OnInit } from '@angular/core';
import { Command } from '../models/command';
import { SendCommandsServiceService } from '../services/send-commands-service.service';
import { ComandService } from '../services/comand.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm, FormBuilder } from '@angular/forms';
import { ParamTypes } from '../models/constants';
import { ConfirmActionDialogComponent } from 'src/app/shared/components/confirm-action-dialog/confirm-action-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { HelpListComponent } from 'src/app/shared/components/help-list/help-list.component';
import constants from 'src/app/shared/constants';

export interface DialogData {
  title: string;
  operation: string;
  submitText: string;
  promptText: string;
  id: string;
  command: any;
}

export interface Param {
  type: number;
  prompt: string;
  helpText: string;
  arrayValues: string[];
  name: string;
  value: any;
  errorMatcher: any;
}

@Component({
  selector: 'app-new-command',
  templateUrl: './new-command.component.html',
  styleUrls: ['./new-command.component.scss']
})
export class NewCommandComponent implements OnInit {
  selectedCommand: Command;
  isConfig: boolean = false;

  paramGet: any;
  paramSet: any;
  numericErrorMatcher = new NumberErrorMatcher();
  hexaErrorMatcher = new HexaErrorMatcher();

  paramGetList: Param[];
  paramSetList: Param[];
  commandPreview: string;

  parametersHint: string[];

  saveConfigurationTranslated: string;

  selectedSourceType = 'All';

  constructor(
    private translateService: TranslateService,
    public sendCommandService: SendCommandsServiceService,
    public commandService: ComandService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<NewCommandComponent>,
    public dialog: MatDialog,
  ) {
    this.parametersHint = Object.keys(constants.helpHints);

    // TODO: When messages-processor gets translations feature we need to change this to avoid using translations to hide the checkbox
    this.saveConfigurationTranslated = this.translateService.instant('TechSupport.Prompts.SaveConfiguration');
  }

  ngOnInit() {
    if (this.data.operation === 'update') {
      this.selectedCommand = this.sendCommandService.allCommands.find(c => c.id === this.data.command.id);
      this.onSelectCommand(this.selectedCommand, this.data.command);
      this.isConfig = !!this.data.command.set;
    }
  }

  getParamTypes = () => {
    return ParamTypes;
  }

  public changeCommandsList(sourceType: string) {
    this.selectedSourceType = sourceType;
  }

  onSelectCommand = async (command, commandValues = undefined) => {
    this.paramGetList = [];
    this.paramSetList = [];

    if (command && command.paramGetType !== undefined) {
      // Parametro get se considera como parametro de consulta y configuracion simultaneamente
      if (commandValues) command.value = commandValues.get;
      this.paramGetList.push(await this.processParam(command, 'Get'));
      this.paramSetList.push(await this.processParam(command, 'Get'));
    }

    if (command && command.paramSetType !== undefined) {
      if (command.paramSetType !== ParamTypes.Object) {
        // Se agrega el parametro de configuracion informado
        if (commandValues) command.value = commandValues.set;
        this.paramSetList.push(await this.processParam(command, 'Set'));
      } else {
        // Se agregan los parametros de configuracion informados
        for (let i = 0; i < command.paramObject.length; i++) {
          const param = command.paramObject[i];

          if (commandValues && commandValues.set) {
            param.value = commandValues.set[param.paramName];
          }

          this.paramSetList.push(await this.processParam(param, 'Set'));
        }
      }
    }
  }

  processParam = (param, type) => {
    let errorMatcher;
    // Se define el controlador de errores.
    if (param['param' + type + 'Type'] === ParamTypes.Number) {
      errorMatcher = new NumberErrorMatcher();
      errorMatcher.setLimits(param['param' + type + 'Min'], param['param' + type + 'Max'], this.selectedCommand.optionalParam);
    } else {
      errorMatcher = param['param' + type + 'Type'] === ParamTypes.Hexa ? new HexaErrorMatcher() : undefined;
      errorMatcher?.setOptional(this.selectedCommand.optionalParam);
    }
    return {
      type: param['param' + type + 'Type'],
      prompt: param['param' + type + 'Prompt'],
      helpText: param['param' + type + 'HelpText'],
      arrayValues: param['param' + type + 'Values'],
      name: param.paramName,
      min: param['param' + type + 'Min'],
      max: param['param' + type + 'Max'],
      value: this.data.operation === 'update' ? param.value : undefined,
      errorMatcher
    };
  }

  submit = () => {
    this.dialogRef.close({ status: 'success', command: this.parseCommand() });
  }

  parseCommand = () => {
    const command = {
      id: this.selectedCommand.id,
      name: this.selectedCommand.name,
      get: undefined,
      set: undefined,
      enable: this.data.operation === 'update' ? this.data.command.enable : true,
    }
    if (!this.isConfig && !this.selectedCommand?.onlySet) {
      command.get = this.getParams(
        this.paramGetList, 
        'get'
      );
    } else {
      command.get = this.getParams(
        this.paramSetList.filter(ps => !!this.paramGetList.find(pg => pg.prompt === ps.prompt)), 
        'get'
      );
      command.set = this.getParams(
        this.paramSetList.filter(ps => !this.paramGetList.find(pg => pg.prompt === ps.prompt)), 
        'set'
      );
    }
    return command;
  }

  parseValue = (param) => {
    // Method for parse param values 
    if (param.value) {
      if (param.type === ParamTypes.Number) {
        return Number(param.value);
      } else if (param.type === ParamTypes.Boolean) {
        return !!param.value;
      } else {
        return param.value;
      }
    } else {
      return undefined;
    }
  }

  getParams = (list, name) => {
    // For each param
    if (list && list.length > 0) {
      if (list.length === 1 && (!list[0].name || list[0].name === name)) {
        // Returns its parsed value to Number, Boolean or String
        return this.parseValue(list[0]);
      } else {
        // Same for multiple set params
        const result = {};
        for (let i = 0; i < list.length; i++) {
          result[list[i].name] = this.parseValue(list[i]);
        }
        return result;
      }
    }
    return undefined;
  }

  onCancelClick = () => {
    this.openConfirmDialog(this.translateService.instant('TechSupport.ExitForm'), this.translateService.instant('Shared.Leave'), (result: boolean) => {
      if (result) {
        this.dialogRef.close({ status: 'cancel' });
      }
    });
  }

  openConfirmDialog(promptText: string, submitText: string, callback: Function): void {
    const dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      maxWidth: '450px',
      autoFocus: false,
      data: { promptText, submitText }
    });

    dialogRef.afterClosed().subscribe(result => callback(result));
  }

  validNumber = (value) => {
    return /[0-9]*/.test(value);
  }

  validHexa = (value) => {
    return /[0-9a-fA-F]*/.test(value) && (value.length%2 === 0);
  }

  inputValidator = (event, param) => {
    // Control de Valores numericos o Hexadecimales
    const pattern = /^\d+$/;
    let replacePattern;

    if (param.type === ParamTypes.Number) {
      replacePattern = /[^0-9]/g;
    }
    if (param.type === ParamTypes.Hexa) {
      replacePattern = /[^0-9a-fA-F]/g;
      event.target.value =  event.target.value.toUpperCase();
    }

    this.validateInput(event, pattern, replacePattern, param);
  }

  inputValidatorHexa = (event, param) => {
    const pattern = /^\d+$/;
    const replacePattern = /[^0-9a-fA-F]/g;
    this.validateInput(event, pattern, replacePattern, param);
  }

  validateInput(event: any, pattern: any, replacePattern: any, param) {
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(replacePattern, '');
      param.value = event.target.value;
    }
    param.value =  event.target.value;
  }

  hexOutput(hexa: string) {
    // Method to format preview command
    // Remove the mac number and add a white space between bytes.
    if (hexa.length > 8) hexa = hexa.slice(8, hexa.length);
    hexa = hexa.toUpperCase();
    let auxHex = '';
    for (let n = 0; n < hexa.length; n += 2) {
      const part = hexa.substr(n, 2);
      auxHex += `${part} `;
    }
    return auxHex.slice(0, auxHex.length - 1);
  }

  checkMessage() {
    // Method for check the encode message preview, and param errors
    this.sendCommandService.checkMessage(
      this.commandService.getPreviewMac(),
      this.parseCommand()
    ).subscribe({
      next: (res: any) => {
        this.commandPreview = res.preview ? this.hexOutput(res.preview) : res.reason;
      },
      error: (error) => {
        this.commandPreview = error.reason;
      }
    });
  };
  
  openConfForm(paramName: string) {
    const dialogRef = this.dialog.open(HelpListComponent, {
      maxWidth: '850px',
      autoFocus: false,
      data: { paramType: paramName }
    });

    dialogRef.afterClosed();
  }

  validateParms() {
    if (!this.isConfig && !this.selectedCommand?.onlySet) {
      for (let i = 0; i < this.paramGetList.length; i++) {
        if (this.paramGetList[i].errorMatcher
          && !this.paramGetList[i].errorMatcher.getValid(this.paramGetList[i].value)) 
        {
          return false;
        }
      }
    } else {
      for (let i = 0; i < this.paramSetList.length; i++) {
        if (this.paramSetList[i].errorMatcher
          && !this.paramSetList[i].errorMatcher.getValid(this.paramSetList[i].value)) 
        {
          return false;
        }
      }
    }
    return true;
  };

  styleHint(param) {
    if (!param.errorMatcher || !param.errorMatcher.errorCheck) {
      return { 'margin-top': '-15px' };
    } else {
      return {};
    }
  }

  formatText(text: string) {
    if (text == undefined) return 'Sin descripcion.<ul></ul>\n';

    const pattern = new RegExp('\b*-\b*', 'g');
    let texts = text.split('/n')
    let items = [];
    for (let index = 1; index < texts.length; index++) {
      items.push(`<li>${texts[index].replace(pattern, '')}</li>`);
    }
    return `${texts[0]}<ul>${items.join('')}</ul>`
  }
}

export class NumberErrorMatcher implements ErrorStateMatcher {
  public errorCheck = false;
  isNumeric = false;
  minValue: number;
  maxValue: number;
  validRange = false;
  optional = false;

  public getValid(value: number): boolean {
    if (value !== undefined) return !isNaN(value) && this.checkLimits(value);
    return this.optional;
  }

  public setValid(val: boolean) {
    this.isNumeric = val;
  }

  public setLimits(min: number, max: number, opt: boolean) {
    this.minValue = min;
    this.maxValue = max;
    this.optional = opt;
  }

  private checkLimits(value) {
    return (this.minValue === undefined || this.minValue <= value) && (this.maxValue === undefined || this.maxValue >= value)
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    this.errorCheck = this.isNumeric || (
        ((control.touched && !this.optional) || form.submitted) 
        && (
          (control.errors !== undefined && control.errors !== null) 
          || !this.checkLimits(control.value)
        )
      ) 
    return this.errorCheck;
  }
}

export class HexaErrorMatcher implements ErrorStateMatcher {
  public errorCheck = false;
  isHexa = false;
  optional = false;

  public getValid(value: string): boolean {
    if (value !== undefined) return value?.length%2 === 0;
    return this.optional;
  }

  public setValid(val: boolean) {
    this.isHexa = val;
  }

  public setOptional(opt: boolean) {
    this.optional = opt;
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    this.errorCheck = this.isHexa || (
        ((control.touched && !this.optional) || form.submitted) 
        && ((control.errors !== undefined && control.errors !== null)
        || control.value?.length%2 !== 0));
    return this.errorCheck;
  }
}
