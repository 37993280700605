import {
  Directive,
  ElementRef,
  Input,
  ComponentRef,
  ApplicationRef,
  ComponentFactoryResolver,
  Injector,
  HostListener,
  OnDestroy,
  EmbeddedViewRef,
} from '@angular/core';
import { TooltipComponent } from 'src/app/common/ui/tooltip/tooltip/tooltip.component';

type TTooltipSize = 'small' | 'medium' | 'large' | 'xlarge' | 'fit';

@Directive({
  selector: '[tooltipDeitres]',
})
export class TooltipDeitresDirective implements OnDestroy {
  @Input() tooltipDeitres = '';
  @Input() tooltipSize: TTooltipSize = 'medium';
  @Input() tooltipMeshStatus: boolean;
  @Input() tooltipCustomLeft: number;
  @Input() tooltipCustomTop: number;
  @Input() tooltipCustomWidth: string;

  private componentRef: ComponentRef<any> = null;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly appRef: ApplicationRef,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly injector: Injector
  ) {}

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.componentRef !== null) return;

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TooltipComponent);
    this.componentRef = componentFactory.create(this.injector);
    this.appRef.attachView(this.componentRef.hostView);

    const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.setTooltipComponentProperties();
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.destroy();
  }

  private setTooltipComponentProperties() {
    if (this.componentRef === null) return;

    if (Array.isArray(this.tooltipDeitres)) {
      this.componentRef.instance.tooltip = this.tooltipDeitres.join('\n');
    } else {
      this.componentRef.instance.tooltip = this.tooltipDeitres;
    }

    // Here you change the position of the tooltip, if you want to customize the tooltip follow doc accesto.com
    const { left, right, bottom } = this.elementRef.nativeElement.getBoundingClientRect();
    this.componentRef.instance.left = (right - left) / 2 + left - 6;
    this.componentRef.instance.top = bottom - 230;

    if (this.tooltipCustomLeft != null) {
      this.componentRef.instance.left -= this.tooltipCustomLeft;
    }

    if (this.tooltipCustomTop != null) {
      this.componentRef.instance.top -= this.tooltipCustomTop;
    }

    this.componentRef.instance.tooltipSize = this.tooltipSize;

    this.componentRef.instance.tooltipMeshStatus = this.tooltipMeshStatus;
  }

  destroy(): void {
    if (this.componentRef === null) return;

    this.appRef.detachView(this.componentRef.hostView);
    this.componentRef.destroy();
    this.componentRef = null;
  }

  ngOnDestroy(): void {
    this.destroy();
  }
}
