<h1 mat-dialog-title>{{ 'Panel.PasswordConfig' | translate }}</h1>
<form name="f" #f="ngForm" novalidate formValidTimerRange>
  <mat-dialog-content>
    <!-- Row 1 -->
    <div class="container" fxLayout="row" fxLayoutGap="30px">
        <mat-form-field>
            <mat-label class="label-title">{{"Panel.PartitionNumber" | translate}}</mat-label>
            <mat-select  [(value)]="this.selectedAccount" (selectionChange) = "selectAccount()">
                <mat-option *ngFor="let part of partitions" [value]="part.number">
                  {{part.account}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <!-- Row 2 -->
    <div *ngFor="let user of currentAccountUsers" class="container" fxLayout="row" fxLayoutGap="30px">
      <!-- User Code -->
      <div class="field-container" fxFlex>
        <mat-form-field>
          <input name="{{'userID' + user.userID}}" [ngModel]="user.userID" placeholder='{{"Panel.UserID" | translate}}' matInput disabled/>
        </mat-form-field>
      </div>
      <!-- Password Field -->
      <div class="field-container" fxFlex>
        <mat-form-field>
          <input name="{{'password' + user.userID}}" [(ngModel)]="user.password" placeholder='{{"Login.Password" | translate}}' [formControl]="user.passCodeFormControl" [errorStateMatcher]="user.passwordErrorMatcher" matInput/>
          <mat-error *ngIf="user.passCodeFormControl.hasError('required')">{{"Panel.PasswordRequired" | translate}}</mat-error>
          <mat-error *ngIf="user.passCodeFormControl.hasError('maxlength') || user.passCodeFormControl.hasError('minlength')">{{"Panel.PasswordLenghtError" | translate}}</mat-error>
          <mat-error *ngIf="user.passCodeFormControl.hasError('pattern')">{{"Panel.PasswordHexError" | translate}}</mat-error>
        </mat-form-field>
      </div>
      <!-- Password Type Field -->
      <div class="field-container" fxFlex>
        <mat-form-field>
            <mat-label class="label-title">{{"Panel.PasswordType" | translate}}</mat-label>
            <mat-select  [(value)]="user.type">
                <mat-option value="0"> {{"Panel.Disarm" | translate}} </mat-option>
                <mat-option value="1"> {{"Panel.Duress" | translate}} </mat-option>
            </mat-select>
        </mat-form-field>
      </div>
      <!-- Save -->
      <div class="field-container" fxFlex>
        <button 
          mat-raised-button color="primary" 
          [disabled]="invalidSetButton(user) || user.isLoading" 
          (click)="sendCommand(user, selectedAccount)"
          [class.spinner]="user.isLoading"
        >
          {{ user.isLoading ? "" : "Panel.SetUp" | translate }}
        </button>
      </div>
      <!-- Delete -->
      <div class="field-container-button" fxFlex>
        <button
          mat-icon-button
          matTooltipShowDelay="250"
          matTooltipPosition="after"
          (click)="onDeletePassword(user, selectedAccount)"
          aria-hidden="false"
          [disabled]="user.isLoading"
        > <mat-icon>delete</mat-icon> </button>
      </div>
    </div>

    {{"Panel.NewUser" | translate}}
    <div class="container" fxLayout="row" fxLayoutGap="30px">
      <!-- User Code invalidUserControl(newUser.userID) || codeErrorMatcher.getCodeError()  -->
      <div class="field-container" fxFlex>
        <mat-form-field>
          <input name="newUserID" [(ngModel)]="newUser.userID" placeholder='{{"Panel.UserID" | translate}}' [formControl]="userCodeFormControl" [errorStateMatcher]="passwordErrorMatcher" matInput/>
          <mat-error *ngIf="userCodeFormControl.hasError('required')">{{"Panel.UserCodeRequired" | translate}}</mat-error>
          <mat-error *ngIf="userCodeFormControl.hasError('min') || userCodeFormControl.hasError('max')">{{"Panel.UserCodeError" | translate}}</mat-error>
        </mat-form-field>
      </div>
      <!-- Password Field -->
      <div class="field-container" fxFlex>
        <mat-form-field>
          <input name="newPassword" [(ngModel)]="newUser.password" placeholder='{{"Login.Password" | translate}}' [formControl]="passCodeFormControl" [errorStateMatcher]="passwordErrorMatcher" matInput/>
          <mat-error *ngIf="passCodeFormControl.hasError('required')">{{"Panel.PasswordRequired" | translate}}</mat-error>
          <mat-error *ngIf="passCodeFormControl.hasError('maxlength') || passCodeFormControl.hasError('minlength')">{{"Panel.PasswordLenghtError" | translate}}</mat-error>
          <mat-error *ngIf="passCodeFormControl.hasError('pattern')">{{"Panel.PasswordHexError" | translate}}</mat-error>
        </mat-form-field>
      </div>
      <!-- Password Type Field -->
      <div class="field-container" fxFlex>
        <mat-form-field>
            <mat-label class="label-title">{{"Panel.PasswordType" | translate}}</mat-label>
            <mat-select  [(value)]="newUser.type">
                <mat-option value="0"> {{"Panel.Disarm" | translate}} </mat-option>
                <mat-option value="1"> {{"Panel.Duress" | translate}} </mat-option>
            </mat-select>
        </mat-form-field>
      </div>
      <!-- Save -->
      <div class="field-container" fxFlex>
        <button 
            mat-raised-button color="primary" 
            [disabled]="invalidSetButton(newUser) || newUser.isLoading" 
            (click)="sendCommand(newUser, selectedAccount)"
            [class.spinner]="newUser.isLoading"
            >
            {{ (newUser.isLoading ? "" : "Panel.SetUp") | translate}}
        </button>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" mat-raised-button (click)="onCancelClick()">{{"Shared.Close" | translate}}</button>
  </mat-dialog-actions >
</form>
