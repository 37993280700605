<main class="tech-support-grid">
  <!-- Display de Mac -->
  <div class="mac-list shadow">
    <app-mac-list></app-mac-list>
  </div>
  <!-- Display de Comandos --> 
  <div class="command-selector shadow ">
    <app-comand-selector></app-comand-selector>
  </div>
  <!-- Display de Estado de comandos --> 
  <div class="command-state shadow">
    <app-command-state></app-command-state>
  </div>

  <div class="command-console shadow">
    <!-- Consola de comandos --> 
    <app-command-console></app-command-console>
  </div>
</main>
