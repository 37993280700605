<div class="modal">
  <mat-icon *ngIf="data.image?.matIcon" class="icon">{{ data.image.matIcon }}</mat-icon>
  <mat-icon *ngIf="data.image?.svg" svgIcon="custom_icon" class="icon"></mat-icon>
  <div class="title center" mat-dialog-title>{{ (data.title ?? 'Shared.Attention') | translate}}</div>
  <mat-dialog-content class="center">
    {{ data.description | translate}}
  </mat-dialog-content>
  <mat-dialog-actions class="button-line">
    <button 
      class="button-container-button"
      *ngIf="data.leftButton" 
      mat-button [mat-dialog-close]="true"  
      [style.background-color]="data.leftButton.color.background"
      [style.color]="data.leftButton.color.font"
      [style.border]="leftButtonBorder ?? ''"
      (click)="onLeft()"
    >
      {{ data.leftButton.title | translate}}
    </button>
    <button 
      class="button-container-button"
      *ngIf="data.rightButton" 
      mat-button [mat-dialog-close]="true"  
      [style.background-color]="data.rightButton.color.background"
      [style.color]="data.rightButton.color.font"
      [style.border]="rightButtonBorder ?? ''"
      (click)="onRight()"
    >
      {{ data.rightButton.title | translate}}
    </button>
  </mat-dialog-actions>
  <button class="close-button" (click)="closeModal()">
      <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>