import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SettingsComponent } from './components/settings/settings.component';
import { AppMaterialModule } from '../app-material/app-material.module';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTabsModule } from '@angular/material/tabs';
import { CompanyInfoComponent } from './components/company-info/company-info.component';
import { CompanyEditInfoComponent } from './components/company-edit/company-edit-info/company-edit-info.component';
import { CompanyEditUsersComponent } from './components/company-edit/company-edit-users/company-edit-users.component';
import { CompanyEditCustomizeComponent } from './components/company-edit/company-edit-customize/company-edit-customize.component';

import { CompanyEditComponent } from './components/company-edit/company-edit.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSliderModule } from '@angular/material/slider';
import { ColorSelectorComponent } from './components/company-edit/company-edit-customize/color-selector/color-selector.component';
import { CreateUserComponent } from './components/company-edit/company-edit-users/create-user/create-user.component';
import { NgxMaskModule } from 'ngx-mask';
import { CreateReceiverComponent } from './components/company-edit/company-edit-users/create-receiver/create-receiver.component';

@NgModule({
  declarations: [
    SettingsComponent,
    CompanyInfoComponent,
    CompanyEditInfoComponent,
    CompanyEditUsersComponent,
    CompanyEditCustomizeComponent,
    CompanyEditComponent,
    ColorSelectorComponent,
    CreateUserComponent,
    CreateReceiverComponent
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    TranslateModule,
    FlexLayoutModule,
    MatTabsModule,
    MatStepperModule,
    FormsModule, 
    ReactiveFormsModule,
    MatSliderModule,
    NgxMaskModule.forRoot(),
  ]
})
export class SettingsModule { }
