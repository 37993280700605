import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Command } from '../models/command';
import { AuthService } from 'src/app/auth/services/auth.service';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class SendCommandsServiceService {
  allCommands: Command[] = [];
  allAvailableCommands: Command[] = [];
  allAvailableCommandSources = [];

  encryptSecret =
    'MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgHj8wl48yGPBEd7oy/bNRB0zQViA6mlOsRJ41f03jX17JqobTTP2wzxYqisBmxxcPS+IbevzRyirdmOMHPuf5kuBEyJnThFsu4lKVJzQd3EZw40UqVJl3bD+czPFAEf47qp3C830b6We4OY6NB9t3mTm+Q0dT4HmXW1Bu+cU86CDAgMBAAE=';

  headers = new HttpHeaders({
    Authorization: environment.messagesProcessorToken,
  });

  convertCommandTypeNumberToLabel = {
    1: 'City7',
    2: 'DTrans',
    3: 'TelephoneLine',
    4: 'GenericMessage',
    5: 'City8',
    6: 'CityPanel',
    // 7: 'ContactId',
    8: 'Amiar',
    9: 'CityMesh',
  };

  allAvailableCommandsClusteredBySourceType = this.setupClusteredCommands();

  commandSources = [
    'All',
    'City7',
    'City8',
    'Amiar',
    'DTrans',
    'CityMesh',
    'CityPanel',
    'TelephoneLine',
    'GenericMessage',
    // 'ContactId',
  ];

  migrateToCityMesh = ['AN', 'CC', 'CT', 'WI', 'PL', 'WR'];

  private uriConfig = `${environment.webappEndpoint}/config`;

  constructor(private http: HttpClient, private authService: AuthService) {}

  setupClusteredCommands() {
    return {
      City7: [],
      DTrans: [],
      TelephoneLine: [],
      GenericMessage: [],
      City8: [],
      CityPanel: [],
      ContactId: [],
      Amiar: [],
      Citymesh: [],
      All: [],
    };
  }

  getAllCommands = () => {
    this.allAvailableCommandsClusteredBySourceType = this.setupClusteredCommands();

    this.http
      .post(`${this.uriConfig}/getCommands`, undefined, {
        headers: { Authorization: `Bearer ${this.authService.getToken()}` },
      })
      .subscribe((res: any) => {
        const hash = res.commands as Command[];
        this.allCommands = JSON.parse(CryptoJS.AES.decrypt(hash, this.encryptSecret).toString(CryptoJS.enc.Utf8));
        this.allAvailableCommands = this.allCommands.filter((p) =>
          this.authService.user.availableCommands.includes(p.id)
        );

        this.allAvailableCommands.forEach((c: Command) => {
          if (this.convertCommandTypeNumberToLabel[c.typeCommand] in this.allAvailableCommandsClusteredBySourceType) {
            this.allAvailableCommandsClusteredBySourceType[this.convertCommandTypeNumberToLabel[c.typeCommand]].push(c);
          }

          this.allAvailableCommandsClusteredBySourceType.All.push(c);
        });

        const migrateCommands = this.allAvailableCommandsClusteredBySourceType.All.filter(
          (c) => this.migrateToCityMesh.includes(c.abbr) && c.typeCommand === 9
        );

        this.allAvailableCommandsClusteredBySourceType.CityPanel =
          this.allAvailableCommandsClusteredBySourceType.CityPanel.concat(migrateCommands);

        this.allAvailableCommandSources = this.pickOnlyNotEmptyCommandSources();
        for (const commandSource of this.commandSources) {
          this.allAvailableCommandSources[commandSource]?.sort((c) => c.abbr);
        }
      });
  };

  pickOnlyNotEmptyCommandSources = () => {
    if (Object.keys(this.allAvailableCommandsClusteredBySourceType).length == 0) return ['All'];

    const res = [];

    for (const commandSource of this.commandSources) {
      const aux = this.allAvailableCommandsClusteredBySourceType[commandSource];

      if (aux && aux.length > 0) res.push(commandSource);
    }

    return res;
  };

  getAvailableReceivers = () => {
    return this.http.post(`${this.uriConfig}/getAvailableReceivers`, undefined, {
      headers: { Authorization: `Bearer ${this.authService.getToken()}` },
    });
  };

  sendCommand = (mac, command, controlIndex) => {
    const data = { mac, command, controlIndex };
    const hash = CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecret).toString();
    return this.http.post(
      `${this.uriConfig}/sendCommand`,
      { hash },
      { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }
    );
  };

  checkMessage = (mac, command) => {
    const data = {
      message: {
        id: command.id,
        mac,
        param: {
          get: command.get,
          set: command.set,
        },
      },
    };
    const hash = CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecret).toString();
    return this.http.post(
      `${this.uriConfig}/parseCommand`,
      { hash },
      { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }
    );
  };
}
