import { UserApp } from './../models/user-app';
import { AuthService } from './../../auth/services/auth.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private readonly uriUser = `${environment.webappEndpoint}/user`;

  constructor(private readonly http: HttpClient, private readonly auth: AuthService) {}

  notifyUser = async (notificationContent: object, usersFiltered: object) => {
    return this.http.post(
      `${this.uriUser}/notify`,
      { notificationContent, usersFiltered },
      { headers: { Authorization: `Bearer ${this.auth.getToken()}` } }
    );
  };

  addUser = (user: UserApp) => {
    let selectedLanguage = this.auth.selectedLanguage;
    return this.http.post(
      `${this.uriUser}/create`,
      { user, selectedLanguage },
      { headers: { Authorization: `Bearer ${this.auth.getToken()}` } }
    );
  };

  modifyUser = (user: UserApp) => {
    let selectedLanguage = this.auth.selectedLanguage;
    return this.http.post(
      `${this.uriUser}/update`,
      { user, selectedLanguage },
      { headers: { Authorization: `Bearer ${this.auth.getToken()}` } }
    );
  };

  activeUser = (user: UserApp, active: boolean) => {
    return this.http.post(
      `${this.uriUser}/active`,
      {
        id: user._id,
        company: user.company ?? this.auth.getPrimaryCompany()._id,
        active,
        selectedLanguage: this.auth.selectedLanguage,
      },
      { headers: { Authorization: `Bearer ${this.auth.getToken()}` } }
    );
  };

  getUsers = (active?: boolean, type?: string, perPage?: number, page?: number) => {
    let params = new HttpParams();
    params = params.append('company', this.auth.getPrimaryCompany()._id);
    if (active !== undefined) {
      params = params.append('active', active.toString());
    }
    if (type) {
      params = params.append('type', type);
    }

    if (perPage) {
      params = params.append('perPage', perPage.toString());
    }

    if (page) {
      params = params.append('page', page.toString());
    }

    return this.http.get(`${this.uriUser}/getAll`, {
      params,
      headers: { Authorization: `Bearer ${this.auth.getToken()}` },
    });
  };

  getUser = (id: string) => {
    return this.http.get(`${this.uriUser}/get/${id}`, { headers: { Authorization: `Bearer ${this.auth.getToken()}` } });
  };

  deleteUser = (id: string) => {
    let params = new HttpParams();
    let selectedLanguage = this.auth.selectedLanguage;
    params = params.append('selectedLanguage', selectedLanguage);
    return this.http.get(`${this.uriUser}/delete/${id}`, {
      params,
      headers: { Authorization: `Bearer ${this.auth.getToken()}` },
    });
  };

  generatePass = () => {
    let params = new HttpParams();
    let selectedLanguage = this.auth.selectedLanguage;
    params = params.append('selectedLanguage', selectedLanguage);
    return this.http.get(`${this.uriUser}/generatePass`, {
      params,
      headers: { Authorization: `Bearer ${this.auth.getToken()}` },
    });
  };
}
