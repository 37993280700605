import { Component, OnInit, Input, OnChanges, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/services/auth.service';
import { PanelService } from '../../../panel/services/panel.service';
import { PanelStatusService } from '../../service/panel-status.service';
import { ReceiversService } from 'src/app/shared/services/receivers.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import constants from 'src/app/shared/constants';
import mapConstants from '../../constants';
import mapIcons from '../../icons';
import { CustomMapService } from '../../service/custom-map.service';
import { PanelMapMark } from 'src/app/types';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { ConfirmActionDialogComponent } from 'src/app/shared/components/confirm-action-dialog/confirm-action-dialog.component';
import { HoverMenuesService } from '../../../hover-menues/services/hover-menues.service';

@Component({
  selector: 'app-panel-state-sidemenu',
  templateUrl: './panel-state-sidemenu.component.html',
  styleUrls: ['./panel-state-sidemenu.component.scss'],
})
export class PanelStateSidemenuComponent implements OnInit, OnChanges, OnDestroy {
  panel;

  @Input()
  set changePanel(value) {
    this.panel = value;
    this.noNeighborsCheck = 'unknown';
    this.cd.detectChanges();
    this.foundMarkPanel = undefined;

    this.showStatus = false;
    this.showDeviceType = false;
    this.showInterface = false;
    this.showHwFw = false;
    this.showPathSession = false;
  }

  foundMarkPanel: PanelMapMark;
  isLoadingResults = false;
  expandNeighbor = false;
  expandPath = false;
  expandPartitions = false;
  expandAddress: boolean = true;

  // Show/Hide
  showBasicInfo = true;
  showPartitions = true;

  showStatus = false;
  showDeviceType = false;
  showInterface = false;
  showHwFw = false;
  showPathSession = false;

  loadingState = true;
  intervalId = undefined;
  leftSidePanelStatusLeds = 'leftSidePanelStatusLeds';
  ledsScale = 1;
  pathEyeClicked = false;
  noNeighborsCheck = 'unknown';

  // Info leds
  public monitoringState;
  public receiverState;
  public meshNetState;

  constructor(
    public authService: AuthService,
    public panelStatus: PanelStatusService,
    private readonly router: Router,
    private readonly ps: PanelService,
    private readonly snackBar: MatSnackBar,
    private readonly sanitizer: DomSanitizer,
    private readonly receiversService: ReceiversService,
    private readonly translateService: TranslateService,
    private readonly route: ActivatedRoute,
    private readonly customMapService: CustomMapService,
    private readonly cd: ChangeDetectorRef,
    public hoverMenuesService: HoverMenuesService,
    public dialog: MatDialog
  ) {
    this.monitoringState = this.sanitizer.bypassSecurityTrustUrl(
      mapIcons.getIconCode(
        this.leftSidePanelStatusLeds,
        mapConstants.iconTypes.MonitoringState,
        'null',
        false,
        false,
        false,
        this.ledsScale
      )
    );
    this.receiverState = this.sanitizer.bypassSecurityTrustUrl(
      mapIcons.getIconCode(
        this.leftSidePanelStatusLeds,
        mapConstants.iconTypes.ReceiverState,
        'null',
        false,
        false,
        false,
        this.ledsScale
      )
    );
    this.meshNetState = this.sanitizer.bypassSecurityTrustUrl(
      mapIcons.getIconCode(
        this.leftSidePanelStatusLeds,
        mapConstants.iconTypes.DeviceState,
        'null',
        false,
        false,
        false,
        this.ledsScale
      )
    );
  }

  ngOnInit(): void {
    this.loadingState = false;
  }

  updates() {
    this.noNeighborsCheck = 'unknown';
  }

  ngOnChanges(): void {
    if (this.route.snapshot.queryParams?.recoverSelected) {
      this.panelStatus.clickedMarker(this.panel);

      /** WORK AROUND to clean query params from the URL
       * preventing the F5 Refresh to mess with the selection
       * mechanism and the circle around the marker when we click the marker
       */
      this.router.navigate([], { queryParams: {} });
    }
  }

  closeShowStatus = () => {
    this.hoverMenuesService.onCloseSideMenu();
  };

  switchShowDeviceType = (value) => {
    this.showDeviceType = value;
  };

  switchShowInterface = (value) => {
    this.showInterface = value;
  };

  switchShowHwFw = (value) => {
    this.showHwFw = value;
  };

  switchShowBasicInfo = (value) => {
    this.showBasicInfo = value;
  };

  switchShowPartitions = (value) => {
    this.showPartitions = value;
  };

  switchShowPathSession = (value) => {
    this.showPathSession = value;
  };

  public getType = (panel) => {
    if (panel.isCommunicator) {
      return 'CP-Comunicador';
    } else if (panel.type === 'citypanel') {
      return 'Citypanel';
    } else if (panel.type === 'city7') {
      return 'City 7';
    } else if (panel.type === 'city8') {
      return 'City 8';
    }
    return 'Desconocido';
  };

  public getSignal = (type) => {
    let res = '';
    const signal = this.panel[type + 'Signal'];
    if (signal === 100) {
      res += signal + '%   ';
    } else if (signal >= 10) {
      res += signal + '%    ';
    } else if (signal >= 0) {
      res += signal + '%     ';
    } else {
      res += '0%     ';
    }
    return res;
  };

  public getName = () => {
    if (this.panel.partitions?.length > 0) {
      const p = this.panel.partitions.find((p) => p.partitionNumber === 0);
      if (p) return p.name;
      return this.panel.partitions[0].name;
    }
    return this.panel.mac;
  };

  public closeControlNeighbor = (panel, qty, status) => {
    // Default time 3s for failed request
    // The time between neighbor reports is 300ms. The estimated time is calculated as 300 * Number of neighbors.
    const time = status ? 300 * qty : 3000;
    setTimeout(() => {
      panel.requesting = false;
      this.panelStatus.neighborQueue = this.panelStatus.neighborQueue.filter((p) => p.mac != panel.mac);
    }, time);
  };

  public reloadNeighbors = async (panel) => {
    if (this.panelStatus.neighborQueue.find((p) => p.mac === panel.mac)) {
      // Show an alert if the panel is requesting
      // Does not show error message
      return;
    }

    if (this.panelStatus.neighborQueue.length >= mapConstants.limitConcurrentRequest) {
      // Show an alert if the panel is disconectedthere are 5 panels requesting
      this.snackBar.open(
        await this.translateService.get('MeshNet.ErrorLimitRequest').toPromise(),
        await this.translateService.get('Shared.Close').toPromise(),
        { duration: constants.snackBarErrorDuration }
      );
      return;
    }

    const newEntry = {
      mac: panel.mac,
      failed: false,
    };
    // Si no hay 5 equipos consultando vecinos, y no se esta consultando vecinos al equipo indicadox
    this.panelStatus.neighborQueue.push(newEntry);

    this.isLoadingResults = true;
    panel.map.neighborsQty = panel?.map?.neighborsQty || 0;
    panel.map.neighbors = [];
    panel.requesting = true;
    this.customMapService.resetPath({ lat: panel.lat, lng: panel.lng });
    this.panelStatus.processMarkIcons();
    this.ps.informNeighbors(panel.mac).subscribe({
      next: async (res: any) => {
        if (res.success) {
          /**
           * @todo CI-50 - Review this quick win bugfix
           */
          const countParsed = parseInt(res.count);
          this.closeControlNeighbor(panel, countParsed, res.status);
          if (res.status) {
            if (countParsed !== 0 && countParsed != panel?.map?.neighborsQty) {
              panel.map.neighborsQty = countParsed;
            } else {
              panel.map.neighborsQty = panel?.map?.neighborsQty || countParsed;
            }
            if (res.count === '0') {
              // Show an alert if the panel doesnt have any neighbor
              this.noNeighborsCheck = 'neighbors-equal-zero';
              this.snackBar.open(
                await this.translateService.get('MeshNet.NoNeighbors').toPromise(),
                await this.translateService.get('Shared.Close').toPromise(),
                { duration: constants.snackBarErrorDuration }
              );
            }
          } else if (!res.count) {
            newEntry.failed = true;
            // Show an alert if the panel is disconected
            this.snackBar.open(
              await this.translateService.get('MeshNet.Error').toPromise(),
              await this.translateService.get('Shared.Close').toPromise(),
              { duration: constants.snackBarErrorDuration }
            );
          }
        }
        this.isLoadingResults = false;
      },
      error: async (error) => {
        this.isLoadingResults = false;

        if (error && error.status == 401) {
          this.snackBar.open(
            await this.translateService.get('Shared.SessionExpired').toPromise(),
            await this.translateService.get('Shared.Close').toPromise(),
            { duration: constants.snackBarErrorDuration }
          );
          this.authService.logout();
        } else {
          this.snackBar.open(
            await this.translateService.get('MeshNet.FailedGetDev').toPromise(),
            await this.translateService.get('Shared.Close').toPromise(),
            { duration: constants.snackBarErrorDuration }
          );
        }
      },
    });
  };

  public showNeighbors = async () => {
    if (this.panel?.map?.neighbors?.length > 0) {
      if (this.customMapService.showPanelPath?.type === mapConstants.PathTypes.Neighbor) {
        this.panelStatus.markPanelAsSelected(this.panel, true);
      } else {
        this.panelStatus.processPanelNeighbor(this.panel);
      }
    } else {
      if (this.panel?.map?.neighborsQty === 0) {
        // There are not neighbors
        this.snackBar.open(
          await this.translateService.get('MeshNet.NoNeighbors').toPromise(),
          await this.translateService.get('Shared.Close').toPromise(),
          { duration: constants.snackBarErrorDuration }
        );
      } else {
        // If neighborQty and neighbors does not match, and neighbors is empty
        // Reload neighbors
        this.reloadNeighbors(this.panel);
      }
    }

    // Redraw icon colors
    this.panelStatus.processMarkIcons();
  };

  public switchShowPartitionList = () => {
    this.expandPartitions = !this.expandPartitions;
  };

  public switchShowNeighborList = () => {
    this.expandNeighbor = !this.expandNeighbor;
  };

  public switchShowPathList = () => {
    this.expandPath = !this.expandPath;
  };

  public printDeviceStateIcon = () => {
    // Get Panel receiver
    const receiver = this.receiversService.filteredReceivers.find((r) => r.apiKey === this.panel.apiKey);

    // Get Monitoring Status
    this.monitoringState = this.sanitizer.bypassSecurityTrustUrl(
      mapIcons.getIconCode(
        this.leftSidePanelStatusLeds,
        mapConstants.iconTypes.MonitoringState,
        receiver?.monitoringStatus ? mapConstants.stateLocation.optimum : mapConstants.stateLocation.null,
        false,
        false,
        false,
        this.ledsScale
      )
    );

    // Get Receiver Status
    this.receiverState = this.sanitizer.bypassSecurityTrustUrl(
      mapIcons.getIconCode(
        this.leftSidePanelStatusLeds,
        mapConstants.iconTypes.ReceiverState,
        receiver?.status ? mapConstants.stateLocation.optimum : mapConstants.stateLocation.null,
        false,
        false,
        false,
        this.ledsScale
      )
    );

    // Get Mesh Net Status
    switch (this.panelStatus.getStatus(this.panel)) {
      case 'low':
        this.meshNetState = this.sanitizer.bypassSecurityTrustUrl(
          mapIcons.getIconCode(
            this.leftSidePanelStatusLeds,
            mapConstants.iconTypes.DeviceState,
            mapConstants.stateLocation.low,
            false,
            false,
            false,
            this.ledsScale
          )
        );
        break;
      case 'good':
        this.meshNetState = this.sanitizer.bypassSecurityTrustUrl(
          mapIcons.getIconCode(
            this.leftSidePanelStatusLeds,
            mapConstants.iconTypes.DeviceState,
            mapConstants.stateLocation.good,
            false,
            false,
            false,
            this.ledsScale
          )
        );
        break;
      case 'optimum':
        this.meshNetState = this.sanitizer.bypassSecurityTrustUrl(
          mapIcons.getIconCode(
            this.leftSidePanelStatusLeds,
            mapConstants.iconTypes.DeviceState,
            mapConstants.stateLocation.optimum,
            false,
            false,
            false,
            this.ledsScale
          )
        );
        break;
      case 'null':
        this.meshNetState = this.sanitizer.bypassSecurityTrustUrl(
          mapIcons.getIconCode(
            this.leftSidePanelStatusLeds,
            mapConstants.iconTypes.DeviceState,
            mapConstants.stateLocation.null,
            false,
            false,
            false,
            this.ledsScale
          )
        );
        break;
    }

    // Get General Status
    return this.meshNetState;
  };

  ngOnDestroy() {
    this.panelStatus.clearAfterClosingLeftSidePanel();
  }

  public goToList = () => {
    this.customMapService.mapClickHandler();
    this.router.navigate(['/panels'], { queryParams: { mac: this.panel.mac } });
  };

  public _processPath() {
    if (this.customMapService.showPanelPath?.type === mapConstants.PathTypes.Path) {
      this.panelStatus.clearMap(false);
      this.panelStatus.markPanelAsSelected(this.panel, true);
    } else {
      this.panelStatus.processPanelPath(this.panel);
    }
  }

  public showPath = async () => {
    this.pathEyeClicked = true;

    // Update neighbors path on map
    if (this.panel?.map?.parent) {
      this._processPath();
    } else {
      // It is a gateway
      this.snackBar.open(
        await this.translateService.get('MeshNet.PanelGateway').toPromise(),
        await this.translateService.get('Shared.Close').toPromise(),
        { duration: constants.snackBarErrorDuration }
      );
    }
  };

  public validPath = () => {
    if (
      !this.panel?.map ||
      this.panel?.map.path === undefined ||
      (this.panel?.map.parent != undefined && this.panel?.map.path.length === 0)
    ) {
      return false;
    }
    return true;
  };

  public getNeighborIconSource = () => {
    if (this.customMapService.showPanelPath?.type === mapConstants.PathTypes.Neighbor) {
      return 'visibility';
    }
    return 'visibility_off';
  };

  public getNeighborTooltip = () => {
    return (
      'MeshNet.' + (this.customMapService.showPanelPath?.type === mapConstants.PathTypes.Neighbor ? 'Hide' : 'Show')
    );
  };

  public getPathActive = () => {
    if (this.customMapService.showPanelPath?.type === mapConstants.PathTypes.Path) {
      return true;
    }
    return false;
  };

  public getCoords = () => {
    return parseFloat(this.panel.lat).toFixed(3) + ', ' + parseFloat(this.panel.lng).toFixed(3);
  };

  public toggleAddressShow = () => {
    this.expandAddress = !this.expandAddress;
  };

  public panelVersionExists() {
    return (
      this.panel.version?.deviceModel ||
      this.panel.version?.hardVersion ||
      this.panel.version?.firmVersion ||
      this.panel.version?.zigbeeVersion
    );
  }

  public reloadNeighborProcess = async () => {
    const title = await firstValueFrom(this.translateService.get('Panel.ActionConfirm'));
    const submitText = await firstValueFrom(this.translateService.get('Partition.Yes'));

    this.openConfirmDialog(title, submitText, () => {
      if (!this.panelStatus?.neighborReloadState) {
        this.panelStatus.neighborReloadState = {
          panel: this.panel,
          stage: 0,
          started: false,
        };
      }
    });
  };

  openConfirmDialog(promptText: string, submitText: string, callback: Function): void {
    const dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      maxWidth: '450px',
      autoFocus: false,
      data: { promptText, submitText },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        callback(result);
      }
    });
  }

  isConnected() {
    this.findPanelInMarks();

    return this.foundMarkPanel?.state != undefined && this.foundMarkPanel?.state != 'null';
  }

  findPanelInMarks() {
    if (this.foundMarkPanel) return;

    this.foundMarkPanel = this.panelStatus.rendererMarKList.find((p: PanelMapMark) => p._id === this.panel._id);
  }

  printRedirectDeviceIcon = () => {
    return this.sanitizer.bypassSecurityTrustUrl(mapIcons.getRedirectDeviceIcon('white', 1, 12, 12)); // NOSONAR
  };

  openSideMenuButton = () => {
    this.panelStatus.openSideMenu();
    this.hoverMenuesService.onOpenSideMenu();
    this.customMapService.saveCenter(16, { lat: this.panel.lat, lng: this.panel.lng });
    this.customMapService.zoomSavedForRestoringFocusAnimation = this.customMapService.map.getZoom();
  };

  translateInterfaceReport = (interfaceReport: string) => {
    return this.translateService.instant(`Interface.${interfaceReport}`);
  };

  onExit = () => {
    this.panelStatus.resetMapSelection();
  };
}
