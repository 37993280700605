<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{'Partition.PartitionDetail' | translate}}
    </mat-card-title>
  </mat-card-header>
  <div style="margin: 10px; border-color: primary;">
    <div>
      <mat-card-title-group>{{'Partition.Information' | translate}}:</mat-card-title-group>
      <mat-card-content class="divider">
        <dl class="divided">
          <dt><b>{{'Partition.Name' | translate}}:</b> {{partition.name}}</dt>
          <dt><b>{{'Partition.AccountNumber' | translate}}:</b> {{partition.account}}</dt>
          <dt><b>{{'Partition.DeviceMac' | translate}}:</b> {{partition.mac}}</dt>
          <dt><b>{{'Partition.Receiver' | translate}}:</b> {{partition.receiver}}</dt>
          <dt *ngIf="this.authService.isAdmin()"><b>{{"AppComponent.Company" | translate}}:</b> {{this.authService.getCompanyName(partition.company)}}</dt>
        </dl>
        <dl class="divided">
          <dt><b>{{'Partition.EnabTechSupport' | translate}}:</b> {{(partition.supportEnabled ? 'Partition.Yes' : 'Partition.No') | translate}}</dt>
          <dt><b>{{'Partition.BlockEventSend' | translate}}:</b> {{(partition.disallowEvents ? 'Partition.Yes' : 'Partition.No') | translate}}</dt>
          <!-- <dt><b>{{'Partition.DisallowRemoteSiren' | translate}}:</b> {{(partition.disallowRemoteSiren ? 'Partition.Yes' : 'Partition.No') | translate}}</dt> -->
          <dt><b>{{'Partition.CustomerAddress' | translate}}:</b> {{partition.address}}</dt>
          <dt><b>{{'Partition.PartitionNumber' | translate}}:</b> {{partition.partitionNumber}}</dt>
        </dl>
      </mat-card-content>
    </div>
    <div>
      <mat-card-title-group style="padding-bottom: 10px">{{'Partition.AssociatedUsers' | translate}}:</mat-card-title-group>
      <mat-card-content *ngIf="partition.users && partition.users.length > 0" style="max-height: 100px;overflow-y: auto;">
        <ul>
          <li *ngFor="let user of partition.users">{{ user.user?.lastName + ' ' + user.user?.name + ' - ' + user.user?.email + ' (Cod. usuario: ' + user.userCode + ')' }}</li>
        </ul>
      </mat-card-content>
      <mat-card-content *ngIf="!partition.users || partition.users.length === 0">{{'Partition.NoAssociatedUsers' | translate}}</mat-card-content>
    </div>
    <div class="divider">
      <div class="divided right-line">
        <div class="bottom-line">
          <mat-card-title-group style="margin-left: 5px;">{{'Partition.AdminNotes' | translate}}</mat-card-title-group>
        </div>
        <mat-card-content *ngIf="partition.adminNotes && partition.adminNotes !== ''" class="notes-box">
          <p class="notes">{{ partition.adminNotes }}</p>
        </mat-card-content>
      </div>
      <div class="divided">
        <div class="bottom-line">
          <mat-card-title-group style="margin-left: 5px;">{{'Partition.TechNotes' | translate}}</mat-card-title-group>
        </div>
        <mat-card-content *ngIf="partition.techNotes" class="notes-box">
          <p class="notes">{{ partition.techNotes.notes }}</p>
        </mat-card-content>
      </div>
    </div>
  </div>
</mat-card>
<div style="margin-top: 20px; text-align: right;">
  <button type="button" mat-raised-button (click)="onCloseClick()">{{'Shared.Close' | translate}}</button>
</div>
