import { Component, Inject } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ConfirmActionDialogComponent } from 'src/app/shared/components/confirm-action-dialog/confirm-action-dialog.component';
import { PanelService } from '../../services/panel.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Panel } from '../../models/panel.model';
import { TranslateService } from '@ngx-translate/core';

export interface DialogData {
  id: string;
}

@Component({
  selector: 'app-panel-edit',
  templateUrl: './panel-edit.component.html',
  styleUrls: []
})
export class PanelEditComponent {
  isLoading = true;
  panel: Panel = new Panel();

  constructor(
    private readonly ps: PanelService,
    private readonly dialogRef: MatDialogRef<PanelEditComponent>,
    private readonly loadingBar: LoadingBarService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    public authService: AuthService,
    private readonly translateService: TranslateService
  ) {
    dialogRef.disableClose = true;

    dialogRef.backdropClick().subscribe(() => {
      this.onCancelClick();
    });

    this.loadingBar.useRef().start();

    this.ps.getPanel(this.data.id, undefined).subscribe({
      next: (res: any) => {
        if (res.success) {
          if (res.panel.type === 'citypanel') {
            res.panel.type = 'City Panel';
          } else {
            res.panel.type = (res.panel.type === 'city8') ? 'City 8' : 'City 7';
          }
          this.panel = { ...res.panel };

          this.isLoading = false;
          this.loadingBar.useRef().complete();
        } else {
          this.showWarning('Panel.FailedGetDev', 'error');
          this.loadingBar.useRef().stop();
        }
      },
      error: (error) => {
        this.loadingBar.useRef().stop();
        if (error && error.status == 401) {
          this.showWarning('Shared.SessionExpire', 'error');
          this.authService.logout();
        } else {
          this.showWarning('Panel.FailedGetDev', 'error');
        }
      }
    });
  }

  showWarning = async (message, type) => {
    this.dialogRef.close({ status: type, message: await lastValueFrom(this.translateService.get(message)) });
  }

  openConfirmDialog(promptText: string, submitText: string, callback: Function): void {
    const dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      maxWidth: '450px',
      autoFocus: false,
      data: { promptText, submitText }
    });

    dialogRef.afterClosed().subscribe(result => callback(result));
  }

  submit = () => {
    this.isLoading = true;
    this.loadingBar.useRef().start();
    this.updatePanel();
  }

  updatePanel = async () => {
    this.ps.updatePanel(this.panel).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.showWarning('Panel.SuccessfullyModDev', 'success');
          this.loadingBar.useRef().complete();
        } else {
          if (res.code && res.code === 'invalidParams') {
            this.showWarning('Panel.InvalidParam', 'error');
          } else {
            this.showWarning('Panel.ErrorModDevice', 'error');
          }

          this.loadingBar.useRef().stop();
        }

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.loadingBar.useRef().stop();

        if (error && error.status == 401) {
          this.showWarning('Shared.SessionExpired', 'error');
          this.authService.logout();
        } else {
          this.showWarning('Panel.ErrorModDevice', 'error');
        }
      }
    });
  }

  onCancelClick = async () => {
    this.openConfirmDialog(await lastValueFrom(this.translateService.get('Panel.ExitForm')), await lastValueFrom(this.translateService.get('Shared.Leave')), (result: boolean) => {
      if (result) {
        this.dialogRef.close({ status: 'cancel' });
      }
    });
  }
}
