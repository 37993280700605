import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mac-table',
  templateUrl: './mac-table.component.html',
  styleUrls: ['./mac-table.component.scss'],
})
export class MacTableComponent implements OnInit, OnChanges {
  @Input()
  loading = false;
  @Input()
  title = '';
  @Input()
  dataset = [];
  @Input()
  set update(value) {
    this.updates();
  }

  _dataset = [];
  _savedDataset = [];
  _reload = true;

  constructor(
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.dataset?.currentValue) this._dataset = this.map2D(changes?.dataset?.currentValue);

    if (changes?.loading?.currentValue) this.reload();
  }

  ngOnInit(): void {}

  updates() {
    this._dataset = this.map2D(this.dataset);
    this.reload();
  }

  reload() {
    /** Trick to force reload the component */
    setTimeout(() => (this._reload = false));
    setTimeout(() => (this._reload = true));
  }

  public clip(value) {
    if (!value) return;

    this.clipboard.copy(`${value}`);
    this.snackBar.open(
      `${this.translateService.instant('Shared.Copied')}: ${value}`,
      this.translateService.instant('Shared.Close'),
      { duration: 1000 }
    );
  }

  public map2D(values) {
    if (!values) return;

    const mapped2d = [];

    for (let jump = 0; jump < values.length; jump += 2) {
      const firstCol = values[jump];
      const secondCol = values[jump + 1];
      mapped2d.push([firstCol, secondCol]);
    }

    return mapped2d;
  }
}
