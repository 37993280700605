<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{ 'TechSupport.CommandConsole' | translate }}
    </mat-card-title>
  </mat-card-header>
  <div>
    <div style="width: 100%">
      <!-- Filtros  -->
      <div class="container">
        <button
          class="mat-icon-button-m"
          mat-icon-button
          matTooltip="{{ 'TechSupport.CleanFilters' | translate }}"
          matTooltipShowDelay="750"
          matTooltipPosition="after"
          (click)="commandService.cleanFilters()"
          aria-hidden="false"
        >
          <mat-icon>refresh</mat-icon>
        </button>
        <mat-form-field class="form-field">
          <input
            matInput
            class="form-control"
            type="text"
            (keyup)="filter()"
            #name="ngModel"
            [(ngModel)]="commandService.filterMac"
            placeholder="{{ 'TechSupport.PinUpMac' | translate }}"
          />
        </mat-form-field>

        <mat-form-field class="form-field">
          <mat-label>{{ 'TechSupport.PinUpAccount' | translate }}</mat-label>
          <select
            [disabled]="panelsService.accountSelectState == 'fetching-accounts' && accounts.length == 0"
            class="prevent-select scrollbar"
            style="text-indent: 5px; font-family: 'Dm Sans', sans-serif"
            name="selectedCommand"
            [(ngModel)]="commandService.filterAccountNumber"
            (ngModelChange)="filter()"
            matNativeControl
          >
            <option [ngValue]="undefined" selected>{{ allOrNotFoundTrans }}</option>
            <option *ngFor="let p of accounts" [ngValue]="p.account">{{ p.account + ' - ' + p.name }}</option>
          </select>
          <mat-spinner
            *ngIf="panelsService.accountSelectState == 'fetching-accounts' && accounts.length == 0"
            color="primary"
            diameter="20"
            style="position: absolute; top: 5px; right: 15px"
          ></mat-spinner>
        </mat-form-field>

        <mat-form-field class="form-field">
          <input
            matInput
            class="form-control"
            type="text"
            (keyup)="filter()"
            #name="ngModel"
            [(ngModel)]="commandService.filterHex"
            placeholder="{{ 'TechSupport.PinUpMessage' | translate }}"
          />
        </mat-form-field>

        <button
          (click)="startStopConsoleLogs()"
          aria-hidden="false"
          class="start-button"
        >
          <mat-icon>{{ showLogs ? 'stop_circle' : 'play_circle' }}</mat-icon>
          {{ startStopButtonText }}
        </button>
      </div>

      <!-- Filtros de Mac -->
      <div style="margin-left: 9px; margin-right: 9px; margin-bottom: 9px">
        <mat-chip-list aria-label="Mac filters">
          <mat-chip *ngFor="let item of this.commandService.filteredMacs">
            <span style="font-family: 'Dm Sans', sans-serif; font-size: 12px">{{ item }}</span>
            <td class="col-1 text-sm-right align-right" (click)="onRemoveMac(item)">
              <mat-icon class="mat-icon-button-sm">close</mat-icon>
            </td>
          </mat-chip>
        </mat-chip-list>
      </div>

      <!-- Divisor -->
      <div class="divider-line"></div>

      <!-- Consola -->
      <div class="row" style="padding: 0.6%; height: 300px">
        <div class="col s5">
          <div class="card">
            <div
              #console
              class="scrollbar"
              style="font-family: 'Dm Sans', sans-serif; overflow-y: scroll; height: 300px"
              [ngStyle]="{ 'font-size': fontSize + 'px', 'white-space': wrap ? 'normal' : 'nowrap' }"
              onclick="hideMenu('menu-contextual');"
              oncontextmenu="showMenu(event, this.id, 'menu-contextual'); return false;"
            >
              <div *ngFor="let item of commandService.messagesFiltered">
                <div *ngIf="!item.response">
                  <div *ngIf="item.status">
                    <ng-container *ngIf="header"
                      ><span
                        >{{ formatDate(item.date) }} - {{ item.receiver }} - {{ item.account }} - {{ item.macs[0] }} -
                        {{ item.interface }}:
                      </span></ng-container
                    >
                    <span style="display: inline"> {{ item.hexMsg + '\n' }} </span><br />
                  </div>
                  <div *ngIf="!item.status">
                    <ng-container *ngIf="header && item.reason"
                      ><span style="color: red"
                        >{{ formatDate(item.date) }} - {{ item.receiver }} - {{ item.account }} - {{ item.macs[0] }} -
                        {{ item.reason }}:
                      </span></ng-container
                    >
                    <ng-container *ngIf="header && !item.reason"
                      ><span style="color: red"
                        >{{ formatDate(item.date) }} - {{ item.receiver }} - {{ item.account }} - {{ item.macs[0] }}:
                      </span></ng-container
                    >
                    <span style="color: red; display: inline"> {{ item.hexMsg + '\n' }} </span><br />
                  </div>
                </div>
                <div>
                  <div *ngIf="item.status && item.response">
                    <span
                      ><strong>{{ buildTextWhenStatusResponse(item) }} </strong></span
                    ><br />
                  </div>
                  <div *ngIf="!item.status && item.response">
                    <span style="color: red"
                      ><strong>{{ buildTextWhenStatusResponse(item) }}</strong></span
                    ><br />
                  </div>
                </div>
              </div>
              <div><br /><br /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>

<!-- Menu contextual de la consola -->
<ul id="menu-contextual" oncontextmenu="return false;">
  <li (click)="copyConsole()">{{ 'TechSupport.Copy' | translate }}</li>
  <li (click)="showHeader()">
    {{ header ? ('TechSupport.HideHeader' | translate) : ('TechSupport.ShowHeader' | translate) }}
  </li>
  <li (click)="changeFontSize(1)">{{ 'TechSupport.IncreaseFontSize' | translate }}</li>
  <li (click)="changeFontSize(-1)">{{ 'TechSupport.DecreaseFontSize' | translate }}</li>
  <li (click)="restoreFontSize()">{{ 'TechSupport.RestoreFontSiza' | translate }}</li>
  <li (click)="wrapText()">{{ wrap ? ('TechSupport.WrapOff' | translate) : ('TechSupport.WrapOn' | translate) }}</li>
  <li (click)="blockScroll()">
    {{ scrollIsBlocked ? ('TechSupport.UnlockScrollbar' | translate) : ('TechSupport.BlockScrollbar' | translate) }}
  </li>
  <li (click)="cleanConsole()">{{ 'TechSupport.CleanConsole' | translate }}</li>
</ul>
