export default {
    defaultReceiver: 'default',
    limitConcurrentRequest: 5,
    keyboardTypes: {
      Paradox: 'Paradox',
      DSC: 'DSC'
    },
    paradox: {
      ON: 'o',
      TSL: 't',
      MEM: 'm',
      BYP: 'e',
      OFF: 'v',
      STAY: 's',
      SLEEP: 'l',
      ARM: 'r',
      ONHOLD: 'O',
      TSLHOLD: 'M',
      BYPHOLD: 'E',
      OFFHOLD: 'V',
      STAYHOLD: 'S',
      SLEEPHOLD: 'L',
      ARMHOLD: 'R',
      ENTER: 'Q',
      DELETE: 'D',
      PANIC: 'P',
      MEDIC: 'A',
      FIRE: 'F'
    },
    dsc: {
      PANIC: 'P',
      MEDIC: 'A',
      FIRE: 'F'
    },
  }