import { Injectable } from '@angular/core';
import { WebppSocketService } from './webppSocket.service';

@Injectable({
  providedIn: 'root',
})
export class WebappSubscriptionService {
  subscriptionList = [];

  constructor(private readonly socket: WebppSocketService) {
    this.socket.listenEvent.subscribe((message) => this.handleSubscription(message));
  }

  private readonly handleSubscription = (message) => {
    const callbackList = this.subscriptionList?.filter((s) => s.channel === message.type);
    callbackList?.forEach((c) => {
      c.callback(message);
    });
  };

  public addSubscription = (callback, channel) => {
    this.subscriptionList.push({ callback, channel });
  };

  public closeSubscription = (callback, channel) => {
    this.subscriptionList = this.subscriptionList.filter((s) => s.channel !== channel && s.callback !== callback);
  };
}
