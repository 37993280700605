import { Component, Input } from '@angular/core';
import { Panel } from '../../models/panel.model';
import { PanelService } from '../../services/panel.service';
import { PanelErrorStatusService } from 'src/app/tech-support/services/panel-error-status-service';

interface IUpdatePanelErrorIconProps {
  panel: Panel;
  reloadCounter: number;
}

@Component({
  selector: 'app-panel-errors-info-button-wrapper',
  templateUrl: './panel-errors-info-button-wrapper.component.html',
  styleUrls: [],
})
export class PanelErrorsInfoButtonWrapperComponent {
  @Input() panel: Panel;
  public _reload = true;
  fetchControlAvailable = true;
  pendingFetch = false;
  errors;
  savedReloadCounter = 0;
  @Input() set update(value: IUpdatePanelErrorIconProps) {
    if (value.reloadCounter > this.savedReloadCounter) {
      this.refetchAndAnalyseErrors(value.panel);
    }

    this.savedReloadCounter = value.reloadCounter;
  }
  @Input() clicked: boolean;

  constructor(private readonly panelService: PanelService, public panelErrorStatusService: PanelErrorStatusService) {}

  // reload here is a technique to force the component to reload with the new data
  private reload() {
    setTimeout(() => (this._reload = false));
    setTimeout(() => (this._reload = true));
  }

  fetch(id: string) {
    // Controls the fetching requests, avoiding too many requests at once
    if (this.fetchControlAvailable) {
      this.fetchControlAvailable = false;

      this.panelService.getPanel(id, undefined).subscribe({
        next: (res: any) => {
          if (res.success) {
            this.panel = res.panel;
          }
        },
      });

      setTimeout(() => {
        this.fetchControlAvailable = true;

        if (this.pendingFetch) {
          this.panelService.getPanel(id, undefined).subscribe({
            next: (res: any) => {
              if (res.success) {
                this.panel = res.panel;
              }
            },
          });
        }

        this.pendingFetch = false;
      }, 10000);
    } else {
      this.pendingFetch = true;
    }
  }

  refetchAndAnalyseErrors = (element: Panel) => {
    this.fetch(element._id);

    // call reload after fetching new data, because Angular isn't refreshing the icon state on the time the code wants
    this.reload();

    this.errors = this.panelErrorStatusService.errorsPresent(this.panel);
  };
}
