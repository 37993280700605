import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogData, PanelDialogPartitionComponent } from '../panel-dialog-partition/panel-dialog-partition.component';
import { PartitionListComponent } from 'src/app/partitions/components/partition-list/partition-list.component';
import { TranslateService } from '@ngx-translate/core';
import { PartitionsService as PartitionsDataService } from 'src/app/shared/services/partitions.service';
import { UsersService as UsersDataService } from 'src/app/shared/services/users.service';
import { ConfirmActionDialogComponent } from 'src/app/shared/components/confirm-action-dialog/confirm-action-dialog.component';
import { PartitionsService } from 'src/app/partitions/services/partitions.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/services/auth.service';
import { GenericDialogComponent } from 'src/app/shared/components/generic-dialog/generic-dialog.component';
import { NgForm } from '@angular/forms';
import { UsersService } from 'src/app/users/services/users.service';
import { firstValueFrom } from 'rxjs';

export interface NotificationsData {
  title: string;
  operation: string;
  submitText: string;
  promptText: string;
  note: string;
  titleNote: string;
  final: boolean;
  technician: boolean;
}

@Component({
  selector: 'app-panel-dialog',
  templateUrl: './panel-dialog.component.html',
  styleUrls: ['./panel-dialog.component.scss'],
})
export class PanelDialogComponent {
  @ViewChild(PanelDialogPartitionComponent) panelDialogPartitionComponent: PanelDialogPartitionComponent;
  @ViewChild(PartitionListComponent) partitionListComponent: PartitionListComponent;
  @ViewChild('f') form: NgForm | undefined;
  snackBarDuration = 5000;
  isLoading: boolean = false;
  private previousTabIndex: number = -1;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    public usersDataService: UsersDataService,
    private readonly abmsUser: UsersService,
    @Inject(MAT_DIALOG_DATA) public dataNotif: NotificationsData,
    private readonly translateService: TranslateService,
    private readonly dialogRef: MatDialogRef<PanelDialogComponent>,
    public partitionsDataService: PartitionsDataService,
    private readonly abmsPartition: PartitionsService,
    private readonly snackBar: MatSnackBar,
    private readonly authService: AuthService
  ) {
    this.dataNotif.final = false;
    this.dataNotif.technician = false;
    dialogRef.disableClose = true;

    dialogRef.backdropClick().subscribe(() => this.onCancelClick());
  }

  ngOnInit() {
    if (this.usersDataService.users.length === 0) {
      this.getUsers();
    }
  }

  clearForms(): void {
    this.dataNotif.final = false;
    this.dataNotif.technician = false;
    this.dataNotif.note = null;
    this.dataNotif.titleNote = null;

    if (this.form) {
      Object.keys(this.form.controls).forEach((controlName) => {
        const control = this.form?.controls[controlName];
        if (control) {
          control.markAsUntouched();
          control.updateValueAndValidity();
        }
      });
    }
  }

  onTabChange(event: any): void {
    if (event.index !== this.previousTabIndex) {
      this.clearForms();
      this.previousTabIndex = event.index;
    }
  }

  onCancelClick = async () => {
    this.openConfirmDialog(
      await firstValueFrom(this.translateService.get('Partition.ExitForm')),
      await firstValueFrom(this.translateService.get('Shared.Leave')),
      (result: boolean) => {
        if (result) {
          this.dialogRef.close({ status: 'cancel' });
        }
      }
    );
  };
  openConfirmDialog(promptText: string, submitText: string, callback: Function): void {
    const dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      maxWidth: '450px',
      autoFocus: false,
      data: { promptText, submitText },
    });

    dialogRef.afterClosed().subscribe((result) => callback(result));
  }

  async filterPartitions() {
    const arrayPartition = {};
    this.partitionsDataService.partitions.forEach((partition) => {
      if (partition?.active) {
        const partitionObj = {
          mac: partition.mac,
          account: partition.account,
        };

        if (arrayPartition[partition.company]) {
          arrayPartition[partition.company].push(partitionObj);
        } else {
          arrayPartition[partition.company] = [partitionObj];
        }
      }
    });

    return arrayPartition;
  }

  async submitNotifyPartitions() {
    this.isLoading = true;

    const companyData = await this.filterPartitions();
    const notification = {
      title: this.dataNotif.titleNote,
      body: this.dataNotif.note,
    };

    const keys = Object.keys(companyData);

    let oneSucessMessage = false;

    for (const element of keys) {
      (await this.abmsPartition.notifyPartition(notification, companyData[element], undefined, element)).subscribe({
        next: (res: any) => {
          if (!oneSucessMessage && res.body.success) {
            oneSucessMessage = true;
            this.handleOperationResponse(res);
          }
        },
        error: (error) => {
          this.handleNotificationError(error);
        },
      });
    }
  }

  private async handleOperationResponse(res: any) {
    if (res.body.success) {
      this.dialog.open(GenericDialogComponent, {
        disableClose: true,
        autoFocus: false,
        data: {
          title: await firstValueFrom(this.translateService.get('Panel.OperationSuccess')),
          type: 'successConfirmation',
        },
      });
    } else {
      this.snackBar.open(
        await firstValueFrom(this.translateService.get('Panel.ErrorNot')),
        await firstValueFrom(this.translateService.get('Shared.Close')),
        { duration: this.snackBarDuration }
      );
    }
    this.isLoading = false;
    this.dialogRef.close();
  }

  private async handleNotificationError(error: any) {
    if (error && error.status === 401) {
      this.snackBar.open(
        await firstValueFrom(this.translateService.get('Shared.SessionExpired')),
        await firstValueFrom(this.translateService.get('Shared.Close')),
        { duration: this.snackBarDuration }
      );
      this.authService.logout();
    } else {
      this.snackBar.open(
        await firstValueFrom(this.translateService.get('Notification.ErrorNot')),
        await firstValueFrom(this.translateService.get('Shared.Close')),
        { duration: this.snackBarDuration }
      );
    }
  }

  async submitNotifyUsers() {
    const usersToNotify = await this.filterUsers();

    let techCount = 0;
    let finalCount = 0;

    const keys = Object.keys(usersToNotify);
    for (const element of keys) {
      usersToNotify[element] = this.filterUsersByType(
        this.dataNotif.final,
        this.dataNotif.technician,
        usersToNotify[element]
      );
      finalCount += usersToNotify[element].filter((user) => user.userType === 'final').length;
      techCount += usersToNotify[element].filter((user) => user.userType === 'tech').length;
    }

    const dialogConfirm = this.dialog.open(ConfirmActionDialogComponent, {
      disableClose: true,
      maxWidth: '450px',
      autoFocus: false,
      data: {
        promptText: await this.confirmUsersToNotify(usersToNotify, techCount, finalCount),
        submitText: await firstValueFrom(this.translateService.get('Shared.Continue')),
      },
    });

    dialogConfirm.afterClosed().subscribe((resultConfirm) => {
      this.isLoading = true;
      const notification = {
        title: this.dataNotif.titleNote,
        body: usersToNotify,
      };

      if (resultConfirm) {
        this.abmsUser
          .notifyUser(notification, usersToNotify)
          .then((observable) => {
            observable.subscribe({
              next: (res: any) => {
                this.handleNotifyUserResponse(res);
              },
              error: (error) => {
                this.handleNotifyUserError(error);
              },
            });
          })
          .catch((error) => {
            this.isLoading = false;
          });
      } else {
        firstValueFrom(this.translateService.get('Notification.CanceledNot')).then((canceledMessage) => {
          firstValueFrom(this.translateService.get('Shared.Close')).then((closeMessage) => {
            this.snackBar.open(canceledMessage, closeMessage, {
              duration: this.snackBarDuration,
            });
            this.isLoading = false;
          });
        });
      }
    });
  }

  private async handleNotifyUserResponse(res: any) {
    if (res.body.success) {
      this.dialog.open(GenericDialogComponent, {
        disableClose: true,
        autoFocus: false,
        data: {
          title: await firstValueFrom(this.translateService.get('Notification.SuccessfullyNot')),
          type: 'successConfirmation',
        },
      });
      this.isLoading = false;
      this.dialogRef.close();
    } else {
      this.snackBar.open(
        await firstValueFrom(this.translateService.get('Notification.ErrorNot')),
        await firstValueFrom(this.translateService.get('Shared.Close')),
        { duration: this.snackBarDuration }
      );
      this.isLoading = false;
    }
  }

  private async handleNotifyUserError(error: any) {
    if (error && error.status === 401) {
      await this.showSnackBar('Shared.SessionExpired', 'Shared.Close');
      this.authService.logout();
    } else {
      await this.showSnackBar('Notification.ErrorNot', 'Shared.Close');
    }
  }

  private async showSnackBar(messageKey: string, closeKey: string) {
    const message = await firstValueFrom(this.translateService.get(messageKey));
    const closeMessage = await firstValueFrom(this.translateService.get(closeKey));

    this.snackBar.open(message, closeMessage, { duration: this.snackBarDuration });
  }

  async filterUsers() {
    const usersToNotify = {};

    this.usersDataService.users.forEach((user) => {
      if (user?.active) {
        let userObj = {
          userId: user._id,
          userEmail: user.email,
          userType: user.type,
        };
        if (usersToNotify[user.company]) {
          usersToNotify[user.company].push(userObj);
        } else {
          usersToNotify[user.company] = [userObj];
        }
      }
    });
    return usersToNotify;
  }

  getUsers = async () => {
    if (!this.usersDataService.isLoadingResults) {
      this.usersDataService.requestUsers();
    }
  };

  filterUsersByType(final: boolean, technician: boolean, arrayUsers: any) {
    return arrayUsers.filter(
      (user) =>
        (final && technician) || (final && user.userType === 'final') || (technician && user.userType === 'tech')
    );
  }

  async confirmUsersToNotify(usersFiltered: any, techCount: number, finalCount: number) {
    let message: string;
    const keys = Object.keys(usersFiltered);
    if (keys.length === 1 && usersFiltered[keys[0]].length === 1) {
      message =
        (await firstValueFrom(this.translateService.get('Notification.NotifyTo'))) +
        usersFiltered[keys[0]][0].userEmail;
    } else if (techCount > 0 && finalCount > 0) {
      message =
        (await firstValueFrom(this.translateService.get('Notification.NotFinalU'))) +
        techCount +
        (await firstValueFrom(this.translateService.get('Notification.TechUsers'))) +
        finalCount;
    } else if (techCount > 0 && finalCount <= 0) {
      message = (await firstValueFrom(this.translateService.get('Notification.NotFinalU'))) + techCount;
    } else if (techCount <= 0 && finalCount > 0) {
      message = (await firstValueFrom(this.translateService.get('Notification.NotFinalT'))) + finalCount;
    }
    return message;
  }

  validateField(users?: boolean): boolean {
    let validate = false;

    if (users) {
      if (!(this.dataNotif.final || this.dataNotif.technician) || !this.dataNotif.note || !this.dataNotif.titleNote) {
        validate = true;
      }
    } else if (!this.dataNotif.note || !this.dataNotif.titleNote) {
      validate = true;
    }

    return validate;
  }

  sendToFinal() {
    this.dataNotif.final = !this.dataNotif.final;
  }

  sendToTechnician() {
    this.dataNotif.technician = !this.dataNotif.technician;
  }
}
