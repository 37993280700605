import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmActionDialogComponent } from '../components/confirm-action-dialog/confirm-action-dialog.component';
import { firstValueFrom } from 'rxjs';

export interface DialogDataNotif {
  title: string;
  operation: string;
  submitText: string;
  promptText: string;
  note: string;
  titleNote: string;
  final: boolean;
  technician: boolean;
}

@Component({
  selector: 'app-note-form',
  templateUrl: './note-form.component.html',
  styleUrls: ['./note-form.component.css'],
})
export class NoteFormComponent {
  constructor(
    private readonly translateService: TranslateService,
    private readonly dialogRef: MatDialogRef<NoteFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataNotif,
    public dialog: MatDialog
  ) {
    this.data.final = false;
    this.data.technician = false;
    this.dialogRef.disableClose = true;

    this.dialogRef.backdropClick().subscribe(() => {
      this.onCancelClick();
    });
  }

  submit(): void {
    this.dialogRef.close({
      status: true,
      data: this.data.note,
      titleNote: this.data.titleNote,
      final: this.data.final,
      technician: this.data.technician,
      title: this.data.title,
    });
  }

  validateField(): boolean {
    let validate: boolean = false;

    if (this.data.operation === 'Notify Users') {
      if (!this.data.final && !this.data.technician) {
        validate = true;
      }
    }

    if (
      this.data.note == undefined ||
      this.data.note == '' ||
      this.data.titleNote == undefined ||
      this.data.titleNote == ''
    ) {
      validate = true;
    }

    return validate;
  }

  sendToFinal() {
    this.data.final = !this.data.final;
  }

  sendToTechnician() {
    this.data.technician = !this.data.technician;
  }

  openConfirmDialog(promptText: string, submitText: string, callback: Function): void {
    const dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      maxWidth: '450px',
      autoFocus: false,
      data: { promptText, submitText },
    });

    dialogRef.afterClosed().subscribe((result) => callback(result));
  }

  onCancelClick = async () => {
    this.openConfirmDialog(
      await firstValueFrom(this.translateService.get('User.ExitForm')),
      await firstValueFrom(this.translateService.get('Shared.Leave')),
      (result: boolean) => {
        if (result) {
          this.dialogRef.close({ status: false });
        }
      }
    );
  };
}
