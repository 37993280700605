<h1 mat-dialog-title>{{'Panel.DeviceSettings' | translate}}</h1>
<form name="f" #f="ngForm" novalidate formValidTimerRange>
  <mat-dialog-content>
    <!-- Data bus configuration -->
    <div *ngIf="isDataBus" class="container align-button" fxLayout="row" fxLayoutGap="30px">
      <div class="field-container" fxFlex>
        <mat-form-field>
          <mat-select style="color: black;" [(value)]="values.panelModel" placeholder='{{"Panel.PanelModelConfiguration" | translate}}' [disabled]="installed">
            <mat-option value="0"> {{"Constants.PanelModel.keyswitch" | translate}} </mat-option>
            <mat-option value="5"> {{"Constants.PanelModel.dsc" | translate}} </mat-option>
            <mat-option value="6"> {{"Constants.PanelModel.paradox" | translate}} </mat-option>
          </mat-select>    
        </mat-form-field>
      </div>
      <div>
        <!-- Boton de Edicion de Tiempo de zonas con demora -->
        <button
            mat-icon-button
            matTooltip='{{"Panel.Help" | translate}}'
            matTooltipShowDelay="750"
            matTooltipPosition="after"
            (click)="databusHelpUrl()"
            aria-hidden="false"
            aria-label="Help"
            class="custom-icon"
        > <mat-icon>help</mat-icon> </button>
        <button class="button-op" 
        type="button" mat-raised-button color="primary"
        [disabled]="validSetButton('panelModel')" 
        (click)="onConfigPanel()"
        > {{ (installed ? "Panel.Uninstall" : "Panel.SetUp") | translate}}
        </button>
      </div>
    </div>
     <div class="container align-button" fxLayout="row" fxLayoutGap="30px">
      <div class="field-container" fxFlex>
        <mat-form-field>
          <input matInput name="TimeSiren" type="number" [(ngModel)]="values.timeSiren" #TimeSiren="ngModel" 
          placeholder='{{"Panel.SirenTimeMin" | translate}}' 
          (ngModelChange)="validSirenTimeRange()" matInput timeSiren required 
          autocomplete="off" 
          [errorStateMatcher]="timeSirenErrorStatMatcher" [disabled]="!isConfig.timeSiren"/>        <button type="button" *ngIf="values.timeSiren && isConfig.timeSiren" matSuffix mat-icon-button aria-label="Clear" (click)="values.timeSiren=currentValues.timeSiren" tabindex="-1">
            <mat-icon>close</mat-icon>
          </button>
          <!-- Control de errores de campo de Duración de disparo de sirena -->
          <mat-error *ngIf="TimeSiren.invalid && TimeSiren.errors?.required">{{'Panel.RequiredField' | translate}}</mat-error>
          <mat-error *ngIf="timeSirenErrorStatMatcher.getExists()">{{'Panel.InvalidValue' | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div>
        <!-- Boton de edicion campo de Duración de disparo de sirena -->
        <button
              mat-icon-button
              matTooltip='{{"Panel.Edit" | translate}}'
              matTooltipShowDelay="750"
              matTooltipPosition="after"
              (click)="clickConfig('timeSiren')"
              aria-hidden="false"
              aria-label="Editar"
            > <mat-icon>edit</mat-icon> </button>
        <!-- Boton de consulta de Duración de disparo de sirena -->
        <button class="button-op" type="button" mat-raised-button [disabled]="validGetButton('timeSiren')" (click)="sendCommand('timeSiren', false)">{{ "Panel.Consult" | translate }}</button>
        <!-- Boton de configuracion de Duración de disparo de sirena. Se convierte en Cancelar si una Consulta/Configuracion esta en proceso -->
        <button class="button-op" type="button" mat-raised-button color="primary" [disabled]="validSetButton('timeSiren')" (click)="isConfiguration('timeSiren') ? sendCommand('timeSiren', true) : clickConfig('timeSiren')">{{ (isConfiguration('timeSiren') ? "Panel.SetUp" : "Panel.Cancel")  | translate}}</button>
      </div>
     </div>
     <!-- Tiempo de zonas con demora -->
     <div class="container align-button" fxLayout="row" fxLayoutGap="30px">
      <div class="field-container" fxFlex>
        <mat-form-field>
          <input matInput name="ZoneDelayTime" type="number" customEmail [(ngModel)]="values.zoneDelayTime" #ZoneDelayTime="ngModel" placeholder='{{"Panel.DelayZoneTimeSeg" | translate}}' (ngModelChange)="validZoneTimeRange()" matInput zoneDelayTime required autocomplete="off" [errorStateMatcher]="zoneDelayTimeErrorStatMatcher" [disabled]="!isConfig.zoneDelayTime"/>
          <button type="button" *ngIf="values.zoneDelayTime && isConfig.zoneDelayTime" matSuffix mat-icon-button aria-label="Clear" (click)="values.zoneDelayTime=currentValues.zoneDelayTime" tabindex="-1">
            <mat-icon>close</mat-icon>
          </button>
          <!-- Control de errores de campo de Tiempo de zonas con demora -->
          <mat-error *ngIf="ZoneDelayTime.invalid && ZoneDelayTime.errors?.required">{{'Panel.RequiredField' | translate}}</mat-error>
          <mat-error *ngIf="zoneDelayTimeErrorStatMatcher.getExists()">{{'Panel.InvalidValue' | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div>
        <!-- Boton de Edicion de Tiempo de zonas con demora -->
        <button
            mat-icon-button
            matTooltip='{{"Panel.Edit" | translate}}'
            matTooltipShowDelay="750"
            matTooltipPosition="after"
            (click)="clickConfig('zoneDelayTime')"
            aria-hidden="false"
            aria-label="Editar"
        > <mat-icon>edit</mat-icon> </button>
        <!-- Boton de Consulta de Tiempo de zonas con demora -->
        <button class="button-op" type="button" mat-raised-button [disabled]="validGetButton('zoneDelayTime')" (click)="sendCommand('zoneDelayTime', false)">{{ "Panel.Consult" | translate }}</button>
        <!-- Boton de Configuracion de Tiempo de zonas con demora. Se convierte en Cancelar si una Consulta/Configuracion esta en proceso -->
        <button class="button-op" type="button" mat-raised-button color="primary" [disabled]="validSetButton('zoneDelayTime')" (click)="isConfiguration('zoneDelayTime') ? sendCommand('zoneDelayTime', true) : clickConfig('zoneDelayTime')">{{ (isConfiguration('zoneDelayTime') ? "Panel.SetUp" : "Panel.Cancel")  | translate}}</button>
      </div>
     </div>
    <!-- Sonido de zona con demora -->
    <div class="container" fxLayout="row" fxLayoutGap="30px">
      <div class="field-container" fxFlex>
        <span class="label-title">{{"Panel.DelayZoneSound" | translate}}</span>
        <mat-slide-toggle name="AudibleDelayZone" color="primary" [(ngModel)]="values.audibleDelayZone" #AudibleDelayZone="ngModel" [disabled]="!isConfig.audibleDelayZone || isSet.audibleDelayZone">{{ (values.audibleDelayZone ? 'Panel.Enabled' : 'Panel.Disabled') | translate }}</mat-slide-toggle>
      </div>
      <div>
        <!-- Boton de Edicion de Sonido de zona con demora -->
        <button
            mat-icon-button
            matTooltip='{{"Panel.Edit" | translate}}'
            matTooltipShowDelay="750"
            matTooltipPosition="after"
            (click)="clickConfig('audibleDelayZone')"
            aria-hidden="false"
            aria-label="Editar"
        > <mat-icon>edit</mat-icon> </button>
        <!-- Boton de Consulta de Sonido de zona con demora -->
        <button class="button-op" type="button" mat-raised-button [disabled]="validGetButton('audibleDelayZone')" (click)="sendCommand('audibleDelayZone', false)">{{ "Panel.Consult" | translate }}</button>
        <!-- Boton de Configuracion de Sonido de zona con demora. Se convierte en Cancelar si una Consulta/Configuracion esta en proceso -->
        <button class="button-op" type="button" mat-raised-button color="primary" [disabled]="validSetButton('audibleDelayZone')" (click)="isConfiguration('audibleDelayZone') ? sendCommand('audibleDelayZone', true) : clickConfig('audibleDelayZone')">{{(isConfiguration('audibleDelayZone') ? "Panel.SetUp" : "Panel.Cancel")  | translate}}</button>
      </div>
    </div>
    <div *ngIf="!data.panel.isCommunicator && data.partitions != 0">
      <!-- Contraseñas de Desarme -->
      <div class="container align-button" fxLayout="row" fxLayoutGap="30px">
        <div class="field-container" fxFlex>
          <mat-form-field>
            <input matInput
            (input)="inputValidatorNumeric($event, 'dissarmPass')"
            maxlength="4" name="DissarmPass" customEmail [(ngModel)]="values.dissarmPass" #DissarmPass="ngModel" placeholder='{{"Panel.Password" | translate}}'  (ngModelChange)="validPasswords('dissarmPass')" matInput dissarmPass required autocomplete="off" [errorStateMatcher]="dissarmPassErrorStatMatcher" [disabled]="!isConfig.dissarmPass"/>
            <button type="button" *ngIf="values.dissarmPass && isConfig.dissarmPass" matSuffix mat-icon-button aria-label="Clear" (click)="values.dissarmPass=currentValues.dissarmPass" tabindex="-1">
              <mat-icon>close</mat-icon>
            </button>
            <!-- Control de errores de campo de Contraseña de Desarme -->
            <mat-error *ngIf="DissarmPass.invalid && DissarmPass.errors?.required">{{"Panel.RequiredField" | translate}}</mat-error>
            <mat-error *ngIf="dissarmPassErrorStatMatcher.getExists()">{{"Panel.InvalidValue" | translate}}</mat-error>
          </mat-form-field>
        </div>
        <div class="field-container" fxFlex>
          <!-- Numero de Cuenta de la Particion -->
          <mat-form-field>
            <input matInput
            (input)="inputValidatorHexa($event, 'account')"
            maxlength="4" name="AccountDissarm" customEmail [(ngModel)]="values.accountDissarm" #AccountDissarm="ngModel" placeholder='{{"Panel.AccountNumber" | translate}}' (ngModelChange)="validAccount('accountDissarm')" matInput accountDissarm required autocomplete="off" [errorStateMatcher]="accountDissarmErrorStatMatcher" [disabled]="!isConfig.dissarmPass"/>
            <button type="button" *ngIf="values.accountDissarm && isConfig.accountDissarm" matSuffix mat-icon-button aria-label="Clear" (click)="values.accountDissarm=currentValues.accountDissarm" tabindex="-1">
              <mat-icon>close</mat-icon>
            </button>
            <!-- Control de errores de campo de Cuenta de Particion -->
            <mat-error *ngIf="AccountDissarm.invalid && AccountDissarm.errors?.required">{{"Panel.RequiredField" | translate}}</mat-error>
            <mat-error *ngIf="accountDissarmErrorStatMatcher.getExists()">{{"Panel.InvalidValue" | translate}}</mat-error>
          </mat-form-field>
        </div>
        <div>
          <!-- Boton Edicion campo de contraseña de Desarme -->
          <button
              mat-icon-button
              matTooltip='{{"Panel.Edit" | translate}}'
              matTooltipShowDelay="750"
              matTooltipPosition="after"
              (click)="clickConfig('dissarmPass')"
              aria-hidden="false"
              aria-label="Editar"
          > <mat-icon>edit</mat-icon> </button>
          <!-- Boton de Configuracion de Contraseñas de Desarme. Se convierte en Cancelar si una Consulta/Configuracion esta en proceso-->
          <!-- Incluye un tooltip indicando que si no hay contraseña de Coacción se agregara una igual con el ultimo digito incrementado -->
          <button class="button-op" type="button" mat-raised-button color="primary" [disabled]="validSetButton('dissarmPass') && isConfiguration('dissarmPass')" (click)="isConfiguration('dissarmPass') ? sendCommand('dissarmPass', false) : clickConfig('dissarmPass')"
            matTooltip='{{"Panel.ConfDuress&DisPass" | translate}}'
            matTooltipShowDelay="750"
            matTooltipPosition="below"
            matTooltipClass="tooltip"
            >{{(isConfiguration('dissarmPass') ? 'Panel.SetUp' : 'Panel.Cancel') | translate}}</button>
        </div>
      </div>
     <!-- Contraseñas de Coacción -->
     <div class="container align-button" fxLayout="row" fxLayoutGap="30px">
      <div class="field-container" fxFlex>
        <mat-form-field>
          <input matInput
          (input)="inputValidatorNumeric($event, 'coercionPass')"
          maxlength="4" name="CoercionPass" customEmail [(ngModel)]="values.coercionPass" #CoercionPass="ngModel" placeholder='{{"Panel.DuressPassword" | translate}}' (ngModelChange)="validPasswords('coercionPass')" matInput coercionPass required autocomplete="off" [errorStateMatcher]="coercionPassErrorStatMatcher" [disabled]="!isConfig.coercionPass"/>
          <button type="button" *ngIf="values.coercionPass && isConfig.coercionPass" matSuffix mat-icon-button aria-label="Clear" (click)="values.coercionPass=currentValues.coercionPass" tabindex="-1">
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="CoercionPass.invalid && CoercionPass.errors?.required">{{"Panel.RequiredField" | translate}}</mat-error>
          <mat-error *ngIf="coercionPassErrorStatMatcher.getExists()">{{"Panel.InvalidValue" | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div class="field-container" fxFlex>
        <!-- Numero de Cuenta de la Particion -->
        <mat-form-field>
          <input matInput
          (input)="inputValidatorHexa($event, 'account')"
          maxlength="4" name="AccountCoercion" customEmail [(ngModel)]="values.accountCoercion" #AccountCoercion="ngModel" placeholder='{{"Panel.AccountNumber" | translate}}' (ngModelChange)="validAccount('accountCoercion')" matInput accountCoercion required autocomplete="off" [errorStateMatcher]="accountCoercionErrorStatMatcher" [disabled]="!isConfig.coercionPass"/>
          <button type="button" *ngIf="values.accountCoercion && isConfig.accountCoercion" matSuffix mat-icon-button aria-label="Clear" (click)="values.accountCoercion=currentValues.accountCoercion" tabindex="-1">
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="AccountCoercion.invalid && AccountCoercion.errors?.required">{{'Panel.RequiredField' | translate}}</mat-error>
          <mat-error *ngIf="accountCoercionErrorStatMatcher.getExists()">{{'Panel.InvalidValue' | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div>
        <!-- Boton de Edicion de Contraseñas de Coacción -->
        <button
            mat-icon-button
            matTooltip='{{"Panel.Edit" | translate}}'
            matTooltipShowDelay="750"
            matTooltipPosition="after"
            (click)="clickConfig('coercionPass')"
            aria-hidden="false"
            aria-label="Editar"
        > <mat-icon>edit</mat-icon> </button>
        <!-- Boton de Configuracion de Contraseñas de Coacción. Se convierte en Cancelar si una Consulta/Configuracion esta en proceso -->
        <!-- Incluye un tooltip indicando que debe ser diferente de la contraseña de desarme -->
        <button class="button-op" type="button" mat-raised-button color="primary" [disabled]="validSetButton('coercionPass') && isConfiguration('coercionPass')" (click)="isConfiguration('coercionPass') ? sendCommand('coercionPass', true) : clickConfig('coercionPass')"
          matTooltip='{{"Panel.NotSameDuressDisarmPass" | translate}}'
          matTooltipShowDelay="750"
          matTooltipPosition="below"
          matTooltipClass="tooltip"
          >{{ (isConfiguration('coercionPass') ? 'Panel.SetUp' : 'Panel.Cancel') | translate }}</button>
      </div>
     </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button type="button" mat-raised-button color="primary" [disabled]="false" (click)="onCancelClick()">{{"Shared.Close" | translate}}</button>
  </mat-dialog-actions >
</form>
