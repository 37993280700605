import { Injectable } from '@angular/core';
import { BreadcrumbedReceptor, BreadCrumberChannelTypes } from 'src/app/panel/models/event.model';
import { EventService } from 'src/app/panel/services/event.service';
import { ReceiversService } from 'src/app/shared/services/receivers.service';

export enum hoverMenuesEventTypes {
  ZOOM = 'zoom',
  USER = 'user',
  RECEIVER_PICKER = 'receiver',
  ZOOM_BEHAVIOR = 'zoom_behavior',
}

@Injectable({
  providedIn: 'root',
})
export class HoverMenuesService {
  // Side Menu
  sideMenuStatus = false;
  updateSideMenuByZoom = true;
  lastPanelID;

  // BreadCrumbed
  breadCrumbedStatus = false;
  breadcrumb: string;

  // Help Menu
  helpMenuStatus = false;
  helpBubbleMessageStatus = true;

  // Profile Menu
  profileMenuStatus = false;

  constructor(private readonly eventService: EventService, private readonly receiverService: ReceiversService) {
    this.eventService.breadcrumbedReceptor.subscribe((signalParams: BreadcrumbedReceptor) => {
      if (signalParams?.type === BreadCrumberChannelTypes.NEWFILTER) {
        this.breadcrumb = signalParams?.breadcrumb ?? undefined;
        this.receiverService.filterName = signalParams.filterName;
      }
    });
  }

  onToggleHelpMenu() {
    if (!this.helpMenuStatus) {
      this.sideMenuStatus = false;
      this.updateSideMenuByZoom = false;
      this.breadCrumbedStatus = false;
    }

    this.profileMenuStatus = false;
    this.helpMenuStatus = !this.helpMenuStatus;
    this.helpBubbleMessageStatus = false;
  }

  onToggleBreadCrumbed(value) {
    if (value) {
      this.sideMenuStatus = false;
      this.updateSideMenuByZoom = false;
      this.helpMenuStatus = false;
    }

    this.profileMenuStatus = false;
    this.breadCrumbedStatus = value;
  }

  onToggleProfileMenu() {
    this.sideMenuStatus = false;
    this.updateSideMenuByZoom = false;
    this.breadCrumbedStatus = false;
    this.helpMenuStatus = false;
    this.profileMenuStatus = !this.profileMenuStatus;
  }

  onClickInfoWindows(id) {
    const isOpen = this.lastPanelID != id || !this.sideMenuStatus;
    this.sideMenuStatus = isOpen;
    this.updateSideMenuByZoom = isOpen;
    this.lastPanelID = id;

    this.breadCrumbedStatus = false;
    this.helpMenuStatus = false;
  }

  onMarkOrClusterClick() {
    this.updateSideMenuByZoom = false;
  }

  onZoomIn() {
    if (this.updateSideMenuByZoom) {
      this.sideMenuStatus = true;
    }
  }

  onZoomOut() {
    if (this.updateSideMenuByZoom || this.sideMenuStatus) {
      this.sideMenuStatus = false;
      this.updateSideMenuByZoom = true;
    }
  }

  onOpenSideMenu() {
    this.sideMenuStatus = true;
    this.updateSideMenuByZoom = true;

    this.breadCrumbedStatus = false;
    this.helpMenuStatus = false;
  }

  onCloseSideMenu() {
    this.sideMenuStatus = false;
    this.updateSideMenuByZoom = false;
  }
}
