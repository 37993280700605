import { Injectable } from '@angular/core';
import { MessagesProcessorSocket } from 'src/app/services/socket.service';

// TO DO Combinar con comunicación de socket de teclado (keyboard) para evitar redundancia de código. 
class Device {
  mac?: string;
  accountNumber?: string;
  type?: string;  
}

class Message {
  hexRaw: string;
  type?: string;
  age?: number;
  sequence?: number;
}

class Gateway {
  device: Device;
  message: Message;
}

class Receiver {
  apiKey: string;
  name: string;
}

class Connection {
  date: string;
  deviceIP?: string;
  devicePort?: number;
  interface: string;
  sequence?: number;
}

class Decodification {
  reportedPartitions: number;
  id: number;
  date: string;
  signalType: string;
  type: string;
  status: boolean;
  reason?: string;
  param: number;
  monitoringStatus: boolean;
}

export class ListenInfo {
  device: Device;
  message: Message;
  gateway: Gateway;
  receiver: Receiver;
  connection: Connection;
  status: boolean;
  decodification?: Decodification;
}

@Injectable({
  providedIn: 'root'
})
export class PanelSocketService {

  listenEvent = this.mpSocket.fromEvent<ListenInfo>('message');
  connectMPEvent = this.mpSocket.fromEvent('connect');
  disconnectMPEvent = this.mpSocket.fromEvent('disconnect');

  connectedMP: boolean;

  constructor(private mpSocket: MessagesProcessorSocket) {
    this.disconnectMPEvent.subscribe(() => {
      this.connectedMP = false;
    });
  }

  connectMPSocket(panelMac: string) {
    this.connectMPEvent.subscribe(() => {
      this.emitMP('join', panelMac);
      this.connectedMP = true;
    });
  }

  initMPListener(apiKeys: string[]) {
    if (apiKeys && apiKeys.length > 0) {
      apiKeys.forEach(apiKey => {
        this.emitMP('join', apiKey);
      });
      this.connectedMP = true;
    }
  }
  
  initMPListenerMac(mac: string) {
    this.emitMP('join', mac);
  }


  unsubscribe(panelMac: string) {
    this.emitMP('leave', panelMac);
  }

  emitMP(eventName: string, data?: any, callback?: any) {
    this.mpSocket.emit(eventName, data, callback);
  }
}
