import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { PanelService } from '../panel/services/panel.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmActionDialogComponent } from 'src/app/shared/components/confirm-action-dialog/confirm-action-dialog.component';
import { EventService } from '../panel/services/event.service';
import { EventSignal } from '../panel/models/event.model';
import { firstValueFrom } from 'rxjs';
import { PanelsService } from '../shared/services/panels.service';

@Component({
  selector: 'app-signal-strength',
  templateUrl: './signal-strength.component.html',
  styleUrls: ['./signal-strength.component.scss'],
})
export class SignalStrengthComponent {
  timer: number;
  isLoading: boolean = false;
  valueSpinner: number = 0;
  snackBarDuration = 5000;
  snackBarErrorDuration = 10000;
  interval: any;
  @Input() signalStrength: number = 0; // Valor de señal (0-100)
  @Input() mac: string;
  @Input() signalType: string;
  @Input() signal: number;
  @Input() deviceId: string;

  constructor(
    private readonly dialog: MatDialog,
    private readonly ps: PanelService,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
    private readonly eventService: EventService,
    public panelsService: PanelsService
  ) {}

  // Método atento a cambio de estado de eventService mientras esté en la pantalla de equipos.
  ngOnChanges() {
    this.eventService.signalUpdatedEvent.subscribe((signalParams: EventSignal) => {
      this.verifyListener(signalParams);
    });
  }

  // Método que verifica la información que llega a través del evento, y compara con las macs y señales de cada botón para actualizarlo y detener el spinner en caso de estar activo.
  verifyListener(signalParams: EventSignal) {
    const macListened = signalParams.mac;
    const signalListened = signalParams.signalType;
    const signal = signalParams.signal;
    if (macListened == this.mac && signalListened == this.signalType) {
      this.signalStrength = signal;
      this.stopInterval();
    }
  }

  // Método que da inicio al spinner de carga
  async onRefreshClick() {
    this.timer = 240000; // tiempo en milisegundos
    this.isLoading = true;
    this.startInterval();
  }

  // Método para iniciar el tiempo de espera en la búsqueda de señal
  private startInterval() {
    const increment = 1;
    const intervalDuration = 1000;
    this.interval = setInterval(async () => {
      this.valueSpinner += increment;

      if (this.valueSpinner < this.timer / intervalDuration) return;

      const signalConsultTimeout = await firstValueFrom(this.translateService.get('SignalConsult.Timeout'));
      const signalConsultDeviceError = await firstValueFrom(this.translateService.get('SignalConsult.DeviceError'));

      const errorConsult = `${signalConsultTimeout} ${this.signalType}.${signalConsultDeviceError}${this.mac}`;

      this.snackBar.open(errorConsult, await firstValueFrom(this.translateService.get('Shared.Close')), {
        duration: this.snackBarErrorDuration,
      });

      this.stopInterval();
    }, intervalDuration);
  }

  // Método que maneja la interacción con el usuario al momento de iniciar la actualización de señal, y envía los datos necesarios al servicio para enviar el comando de consulta al equipo
  startPanelConnection = async () => {
    this.onRefreshClick();
    this.ps.consultLink(this.deviceId, this.signalType).subscribe({
      next: async (res: any) => {
        if (!res.success) {
          this.stopInterval();
          if (this.signalType == 'mesh') {
            this.openConfirmDialog(
              (await firstValueFrom(this.translateService.get('SignalConsult.MeshConsult'))) +
                `${this.mac}` +
                (await firstValueFrom(this.translateService.get('SignalConsult.MeshConsult2'))),
              await firstValueFrom(this.translateService.get('Shared.Accept')),
              (result: boolean) => {
                if (result) {
                  this.onRefreshClick();
                  this.ps.consultLink(this.deviceId, 'mesh forzado').subscribe({
                    next: async (secondRes: any) => {
                      if (!secondRes.success) {
                        this.stopInterval();
                        this.signalStrength = 0;
                        const errorConsult =
                          (await firstValueFrom(this.translateService.get('SignalConsult.ModuleError'))) +
                          `${this.signalType}.` +
                          (await firstValueFrom(this.translateService.get('SignalConsult.DeviceError'))) +
                          `${this.mac}`;
                        this.snackBar.open(
                          errorConsult,
                          await firstValueFrom(this.translateService.get('Shared.Close')),
                          {
                            duration: this.snackBarErrorDuration,
                          }
                        );
                      }
                    },
                    error: async (error) => {
                      this.stopInterval();
                      if (error && error.status == 401) {
                        this.snackBar.open(
                          await firstValueFrom(this.translateService.get('Shared.SessionExpired')),
                          await firstValueFrom(this.translateService.get('Shared.Close')),
                          { duration: this.snackBarDuration }
                        );
                      } else {
                        this.snackBar.open(
                          (await firstValueFrom(this.translateService.get('SignalConsult.SignalError'))) +
                            `${this.mac}` +
                            (await firstValueFrom(this.translateService.get('SignalConsult.SignalError2'))),
                          await firstValueFrom(this.translateService.get('Shared.Close')),
                          { duration: this.snackBarErrorDuration }
                        );
                      }
                    },
                  });
                }
              }
            );
          } else {
            this.stopInterval();
            this.signalStrength = 0;
            const errorConsult =
              (await firstValueFrom(this.translateService.get('SignalConsult.ModuleError'))) +
              `${this.signalType}.` +
              (await firstValueFrom(this.translateService.get('SignalConsult.DeviceError'))) +
              `${this.mac}`;
            this.snackBar.open(errorConsult, await firstValueFrom(this.translateService.get('Shared.Close')), {
              duration: this.snackBarErrorDuration,
            });
          }
        }
      },
      error: async (error) => {
        this.stopInterval();
        if (error && error.status == 401) {
          this.snackBar.open(
            await firstValueFrom(this.translateService.get('Shared.SessionExpired')),
            await firstValueFrom(this.translateService.get('Shared.Close')),
            { duration: this.snackBarDuration }
          );
        } else {
          this.snackBar.open(
            (await firstValueFrom(this.translateService.get('SignalConsult.SignalError'))) +
              `${this.mac}` +
              (await firstValueFrom(this.translateService.get('SignalConsult.SignalError2'))),
            await firstValueFrom(this.translateService.get('Shared.Close')),
            { duration: this.snackBarErrorDuration }
          );
        }
      },
    });
  };

  // Método para detener el spinner mediante isLoading, y setear el tiempo del timer
  private stopInterval() {
    this.isLoading = false;
    this.valueSpinner = 0;
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  openConfirmDialog(promptText: string, submitText: string, callback: Function): void {
    const dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      maxWidth: '450px',
      autoFocus: false,
      data: { promptText, submitText },
    });
    dialogRef.afterClosed().subscribe((result) => callback(result));
  }
}
