import { sortedEventsList } from './../../models/sorted-events-list';
import { sortedNotificationSubsText } from './../../models/sorted-notificationSub-text';
import { NotificationFormComponent } from './../notification-form/notification-form.component';
import { CompanyModel } from './../../../shared/models/company-model';
import { PartitionsService } from './../../services/partitions.service';
import { Partition } from './../../models/partition';
import { Component, Inject } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AuthService } from './../../../auth/services/auth.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ConfirmActionDialogComponent } from 'src/app/shared/components/confirm-action-dialog/confirm-action-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/shared/services/users.service';

export interface DialogData {
  title: string;
  operation: string;
  submitText: string;
  promptText: string;
  id: string;
  company: CompanyModel;
}

type Form = FormGroupDirective | NgForm | null;

@Component({
  selector: 'app-partition-form',
  templateUrl: './partition-form.component.html',
  styleUrls: ['./partition-form.component.scss'],
})
export class PartitionFormComponent {
  isLoading = false;
  partition: Partition = new Partition();
  originalPartition: Partition;
  crossFieldErrorMatcher = new CrossFieldErrorMatcher();
  accountErrorMatcher = new AccountErrorMatcher();
  availableUsers: {
    id: string;
    company: string;
    name: string;
    lastName: string;
    type: string;
    email: string;
    selected: boolean;
    topics: string[];
  }[] = [];
  filteredUsers: { id: string; company: string; name: string; lastName: string; email: string; selected: boolean }[] =
    [];
  currentUsers: {
    id: string;
    company: string;
    name: string;
    lastName: string;
    email: string;
    code: string;
    selected: boolean;
    topics: string[];
  }[] = [];
  userFilter = '';
  usersWithNewSubs = [];

  constructor(
    public authService: AuthService,
    public usersService: UsersService,
    private readonly dialogRef: MatDialogRef<PartitionFormComponent>,
    private readonly loadingBar: LoadingBarService,
    private readonly translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    private readonly abm: PartitionsService
  ) {
    dialogRef.disableClose = true;

    dialogRef.backdropClick().subscribe(() => this.onCancelClick());
    if (this.data.operation === 'update') {
      this.loadingBar.useRef().start();

      this.isLoading = true;
      this.abm.getPartition(this.data.id).subscribe({
        next: (res: any) => {
          this.isLoading = false;
          if (res.success) {
            delete res.partition.alarmStatus;
            delete res.partition.apiKey;
            this.partition = { ...res.partition };
            this.originalPartition = { ...res.partition };
            if (res.partition.users && res.partition.users.length > 0) {
              const users = res.partition.users;
              users.forEach((element) => {
                if (element.user) {
                  this.currentUsers.push({
                    id: element.user._id,
                    company: element.user.company,
                    name: element.user.name,
                    lastName: element.user.lastName,
                    email: element.user.email,
                    code: element.userCode,
                    topics: element.notificationSubs,
                    selected: true,
                  });
                }
              });
              this.currentUsers.sort((a, b) => {
                if (`${a.lastName} ${a.name}` > `${b.lastName} ${b.name}`) {
                  return 1;
                }
                if (`${a.lastName} ${a.name}` < `${b.lastName} ${b.name}`) {
                  return -1;
                }
                return 0;
              });
              if (this.availableUsers) {
                this.availableUsers.forEach((u) => {
                  u.topics = this.partition.notificationSubs;
                });
              }
            }

            this.onChangeCompany();
            this.loadingBar.useRef().complete();
          } else {
            const message = this.translateService.instant('Partition.ErrorGetPartition');
            this.dialogRef.close({
              status: 'error',
              message: message,
            });
            this.loadingBar.useRef().stop();
          }
        },
        error: (error) => {
          this.isLoading = false;
          this.loadingBar.useRef().stop();

          if (error && error.status === 401) {
            const message = this.translateService.instant('Shared.SessionExpired');
            this.dialogRef.close({
              status: 'error',
              message: message,
            });
            this.authService.logout();
          } else {
            const message = this.translateService.instant('Partition.ErrorGetPartition');
            this.dialogRef.close({
              status: 'error',
              message: message,
            });
          }
        },
      });
    } else if (this.data.operation === 'add') {
      this.partition.company = this.authService.getPrimaryCompany()._id;
      this.partition.supportEnabled = true;
      this.partition.notificationSubs = [...this.authService.getPrimaryCompany().notificationSubs];
    }

    if (this.usersService.users.length === 0) {
      if (!this.usersService.isLoadingResults) {
        this.usersService.requestUsers(() => {
          this.onChangeCompany();
        });
      }
    } else {
      this.onChangeCompany();
    }
  }

  openConfirmDialog(promptText: string, submitText: string, callback: Function): void {
    const dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      maxWidth: '450px',
      autoFocus: false,
      data: { promptText, submitText },
    });

    dialogRef.afterClosed().subscribe((result) => callback(result));
  }

  onCancelClick = async () => {
    this.openConfirmDialog(
      await this.translateService.instant('Partition.ExitForm'),
      await this.translateService.instant('Shared.Leave'),
      (result: boolean) => {
        if (result) {
          this.dialogRef.close({ status: 'cancel' });
        }
      }
    );
  };

  submit = () => {
    this.isLoading = true;
    this.loadingBar.useRef().start();

    if (this.data.operation === 'add') {
      this.addPartition();
    } else if (this.data.operation === 'update') {
      this.updatePartition();
    }
  };

  addPartition = async () => {
    this.accountErrorMatcher.setAccountExists(false);
    this.partition.users = [];
    this.availableUsers.forEach((user) => {
      if (user.selected) {
        this.partition.users.push({
          id: user.id,
          newNotificationSubs: user.topics,
        });
      }
    });

    this.abm.addPartition(this.partition).subscribe({
      next: (res: any) => {
        if (res?.success) {
          const message = this.translateService.instant('Partition.PartitionAdded');
          this.dialogRef.close({
            status: 'success',
            message: message,
          });
          this.loadingBar.useRef().complete();
        } else {
          if (res.code && res.code === 'accountExists') {
            this.accountErrorMatcher.setAccountExists(true);
          } else if (res.code && res.code === 'invalidParams') {
            const message = this.translateService.instant('Partition.InvalidParam');
            this.dialogRef.close({
              status: 'error',
              message: message,
            });
          } else {
            const message = this.translateService.instant('Partition.ErrorAddPartition');
            this.dialogRef.close({
              status: 'error',
              message: message,
            });
          }
          this.loadingBar.useRef().stop();
        }

        this.isLoading = false;
      },

      error: (error) => {
        this.isLoading = false;
        this.loadingBar.useRef().stop();

        if (error && error.status === 401) {
          const message = this.translateService.instant('Shared.SessionExpired');
          this.dialogRef.close({
            status: 'error',
            message: message,
          });
          this.authService.logout();
        } else {
          const message = this.translateService.instant('Partition.ErrorAddPartition');
          this.dialogRef.close({
            status: 'error',
            message: message,
          });
        }
      },
    });
  };

  updatePartition = async () => {
    this.accountErrorMatcher.setAccountExists(false);

    // Se borran los datos que no se modificaron.
    const updatedPartition = new Partition();
    updatedPartition._id = this.partition._id;
    updatedPartition.company = this.partition.company;
    updatedPartition.name = this.originalPartition.name !== this.partition.name ? this.partition.name : undefined;
    updatedPartition.account =
      this.originalPartition.account !== this.partition.account ? this.partition.account : undefined;
    updatedPartition.address = this.originalPartition.address !== this.partition.address
      ? this.partition.address
      : undefined;
    updatedPartition.supportEnabled =
      this.originalPartition.supportEnabled !== this.partition.supportEnabled
        ? this.partition.supportEnabled
        : undefined;
    updatedPartition.adminNotes =
      this.originalPartition.adminNotes !== this.partition.adminNotes ? this.partition.adminNotes : undefined;
    updatedPartition.disallowEvents =
      this.originalPartition.disallowEvents !== this.partition.disallowEvents
        ? this.partition.disallowEvents
        : undefined;
    updatedPartition.emergencyEmail =
      this.originalPartition.emergencyEmail !== this.partition.emergencyEmail
        ? this.partition.emergencyEmail
        : undefined;
    updatedPartition.disallowRemoteSiren =
      this.originalPartition.disallowRemoteSiren !== this.partition.disallowRemoteSiren
        ? this.partition.disallowRemoteSiren
        : undefined;
    updatedPartition.users = [];
    updatedPartition.notificationSubs =
      this.originalPartition.notificationSubs !== this.partition.notificationSubs
        ? this.partition.notificationSubs
        : undefined;
    this.availableUsers.forEach((user) => {
      if (user.selected) {
        updatedPartition.users.push({
          id: user.id,
          newNotificationSubs: user.topics ? user.topics : this.partition.notificationSubs,
        });
      }
    });
    updatedPartition.removeUsers = [];
    this.currentUsers.forEach((user) => {
      if (!user.selected) {
        updatedPartition.removeUsers.push(user.id);
      }
    });
    updatedPartition.usersWithNewSubs = [];
    this.usersWithNewSubs.forEach((user) => {
      if (user.selected) {
        updatedPartition.usersWithNewSubs.push({
          id: user.id,
          newNotificationSubs: user.topics,
        });
      }
    });
    updatedPartition.eventsLoggingConf = this.partition.eventsLoggingConf;

    this.abm.modifyPartition(updatedPartition).subscribe({
      next: (res: any) => {
        if (res?.success) {
          const message = this.translateService.instant('Partition.PartitionModified');
          this.dialogRef.close({
            status: 'success',
            message: message,
          });
          this.loadingBar.useRef().complete();
        } else {
          if (res.code && res.code === 'accountExists') {
            this.accountErrorMatcher.setAccountExists(true);
          } else if (res.code && res.code === 'invalidParams') {
            const message = this.translateService.instant('Partition.InvalidParam');
            this.dialogRef.close({
              status: 'error',
              message: message,
            });
          } else {
            const message = this.translateService.instant('Partition.ErrorModPartition');
            this.dialogRef.close({
              status: 'error',
              message: message,
            });
          }
          this.loadingBar.useRef().stop();
        }

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        this.loadingBar.useRef().stop();

        if (error && error.status === 401) {
          const message = this.translateService.instant('Shared.SessionExpired');
          this.dialogRef.close({
            status: 'error',
            message: message,
          });
          this.authService.logout();
        } else {
          const message = this.translateService.instant('Partition.ErrorModPartition');
          this.dialogRef.close({
            status: 'error',
            message: message,
          });
        }
      },
    });
  };

  private getNotificationList(): any[] {
    const list = [];

    if (this.authService.getPrimaryCompany().notificationSubs !== undefined) {
      sortedNotificationSubsText.forEach((n) => {
        list.push(n);
      });
    }
    return list;
  }

  openNotificationForm(user?: any) {
    const currentTopics = user ? user.topics : this.partition.notificationSubs;
    const dialogRef = this.dialog.open(NotificationFormComponent, {
      width: '40%',
      minWidth: '400px',
      maxWidth: '1000px',
      data: {
        availableTopics: this.getNotificationList(),
        currentTopics,
        isUser: user !== undefined,
        name: user ? `${user.lastName} ${user.name}` : undefined,
      },
    });

    dialogRef.afterClosed().subscribe((result: { status: string; topics: any }) => {
      if (result.status === 'success') {
        // Se cambia la estructura de seleccion y deseleccion a listado de notificaciones seleccionadas
        const keys = Object.keys(result.topics);

        const notificationlist = [];
        if (keys.length > 0) {
          keys.forEach((k) => {
            if (result.topics[k]) notificationlist.push(k);
          });
        }
        result.topics = notificationlist;

        if (user) {
          user.topics = result.topics;
          const i = this.usersWithNewSubs.findIndex((u) => u.id === user.id);
          if (i > -1) {
            this.usersWithNewSubs[i] = user;
          } else {
            this.usersWithNewSubs.push(user);
          }
        } else {
          this.partition.notificationSubs = result.topics;
          this.currentUsers.forEach((u) => {
            u.topics = result.topics;
          });
          this.usersWithNewSubs = this.currentUsers;
          this.availableUsers.forEach((u) => {
            u.topics = result.topics;
          });
        }
      }
    });
  }

  openEventForm() {
    const currentTopics = [];
    if (this.partition.eventsLoggingConf !== undefined) {
      const keys = Object.keys(this.partition.eventsLoggingConf);
      if (keys.length > 0) {
        keys.forEach((k) => {
          if (this.partition.eventsLoggingConf[k]) {
            currentTopics.push(k);
          }
        });
      }
    } else {
      sortedEventsList.forEach((e) => {
        currentTopics.push(e.topic);
      });
    }
    const dialogRef = this.dialog.open(NotificationFormComponent, {
      width: '40%',
      minWidth: '400px',
      maxWidth: '1000px',
      data: {
        availableTopics: sortedEventsList,
        currentTopics,
        events: true,
      },
    });

    dialogRef.afterClosed().subscribe((result: { status: string; topics: any }) => {
      if (result.status === 'success') {
        // Se reemplazan los valores de configuracion de Historial por las seleccion del usuario
        this.partition.eventsLoggingConf = result.topics;
      }
    });
  }

  onChangeCompany() {
    // Filter the company users
    if (this.partition.company) {
      this.availableUsers = this.usersService.users.reduce((acc, user) => {
        if (user.type != 'tech' && user.company === this.partition.company && !this.partition.users?.find((u) => u.user?._id === user._id)) {
          acc.push({
            id: user._id,
            company: user.company,
            name: user.name,
            lastName: user.lastName,
            email: user.email,
            topics: this.partition ? this.partition.notificationSubs : [],
            selected: false,
          });
        }
        return acc;
      }, []);

      this.filterUsers();
    }
  }

  filterUsers() {
    this.filteredUsers = !this.partition.company
      ? []
      : this.availableUsers.reduce((acc, user) => {
          const filtro = this.userFilter.toLowerCase();
          if (
            user.type != 'tech' &&
            (
              user.email.toLowerCase().includes(filtro) ||
              user.name.toLowerCase().includes(filtro) ||
              user.lastName.toLowerCase().includes(filtro)
            )
          ) {
            acc.push(user);
          }
          return acc;
        }, []);
  }

  onChangeAccount = () => {
    this.partition.account = this.partition.account.toUpperCase();
    this.partition.account = this.partition.account.replace('0', 'A');
  };
}

export class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: Form): boolean {
    return (control.dirty && control.errors) || (form.invalid && form.errors?.validTimerRange);
  }
}

export class AccountExistsErrorMatcher implements ErrorStateMatcher {
  accountExists = false;

  public getAccountExists(): boolean {
    return this.accountExists;
  }

  public setAccountExists(val: boolean) {
    this.accountExists = val;
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control.dirty && control.errors) || (form.invalid && form.errors?.validTimerRange);
  }
}

export class AccountErrorMatcher implements ErrorStateMatcher {
  AccountExists = false;

  public getAccountExists(): boolean {
    return this.AccountExists;
  }

  public setAccountExists(val: boolean) {
    this.AccountExists = val;
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (
      this.AccountExists ||
      ((control.touched || form.submitted) && control.errors !== undefined && control.errors !== null)
    );
  }
}
