import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { UserApp } from 'src/app/users/models/user-app';
import { WebappSubscriptionService } from 'src/app/shared/services/webapp-subscription.service';
import { WebppSocketService } from 'src/app/shared/services/webppSocket.service';
import constants from '../constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { UsersService as UHTTPService } from 'src/app/users/services/users.service';
import { firstValueFrom } from 'rxjs';
import { ReceiversService } from './receivers.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  users: UserApp[] = [];
  dataSource: MatTableDataSource<UserApp>;
  isLoadingResults = false;
  snackBarDuration = 5000;
  snackBarErrorDuration = 10000;
  deleteUserId: string;

  newUsersCallback = [];

  constructor(
    public loadingBar: LoadingBarService,
    private readonly abms: UHTTPService,
    private readonly snackBar: MatSnackBar,
    private readonly authService: AuthService,
    private readonly wsSocket: WebppSocketService,
    private readonly translateService: TranslateService,
    private readonly webappSocket: WebappSubscriptionService,
    private readonly receiverService: ReceiversService
  ) {
    this.dataSource = new MatTableDataSource(this.users);
    this.webappSocket.addSubscription(this.handleNewUsers, constants.webappSocketTypes.usersListPackage);
  }

  userEnableOnFilter(user) {
    if (this.receiverService.selectedReceiver[0] === 'default') return true;

    const companies = this.receiverService.receivers.reduce((acc, r) => {
      if (this.receiverService.selectedReceiver.includes(r.apiKey)) acc.push(r.company);
      return acc;
    }, []);
    return companies.includes(user.company);
  }

  filterReceiver() {
    const selectedReceivers = this.receiverService.receivers
      .filter((r) => this.receiverService.selectedReceiver.includes(r.apiKey))
      .map((r) => r.company);

    const filteredData = this.users.filter((p) => selectedReceivers.includes(p.company));
    this.dataSource.data = filteredData.length > 0 ? [...filteredData] : [...this.users];
  }

  public handleNewUsers = (message) => {
    if (message.finish) {
      this.loadingBar.useRef().complete();
      this.newUsersCallback.forEach((cb) => cb());
      if (this.receiverService.selectedReceiver.length > 0) {
        this.filterReceiver();
      }
    } else {
      this.isLoadingResults = false;
      this.users = [
        ...this.users,
        ...message.users.reduce((acc, user: any) => {
          const u = this.users.find((e) => e._id === user._id);
          if (!u) {
            acc.push(user);
          } else {
            const keys = Object.keys(user);
            keys.forEach((k) => (u[k] = user[k]));
          }
          return acc;
        }, []),
      ];
      this.dataSource.data = this.users;
    }
  };

  clear = () => {
    this.users = [];
    this.dataSource.filter = undefined;
    this.dataSource.data = [];
  };

  requestUsers = (callback = undefined) => {
    if (callback) {
      this.newUsersCallback.push(callback);
    }

    this.isLoadingResults = true;
    this.wsSocket.requestDataToService(() => {
      this.abms.getUsers().subscribe({
        next: (res: any) => {
          this.loadingBar.useRef().start();
        },
        error: (error) => {
          this.handleUserFetchError(error);
        },
      });
    });
  };

  private async handleUserFetchError(error: any) {
    this.isLoadingResults = false;

    if (error && error.status === 401) {
      this.snackBar.open(
        await firstValueFrom(this.translateService.get('Shared.SessionExpired')),
        await firstValueFrom(this.translateService.get('Shared.Close')),
        { duration: this.snackBarErrorDuration }
      );
      this.authService.logout();
    } else {
      this.snackBar.open(
        await firstValueFrom(this.translateService.get('User.ErrorGetUser')),
        await firstValueFrom(this.translateService.get('Shared.Close')),
        { duration: this.snackBarErrorDuration }
      );
    }
  }
}
