import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../app-material/app-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MapViewComponent } from './components/map-view/map-view.component';
import { TranslateModule } from '@ngx-translate/core';
import { MeshStatusComponent } from './components/mesh-status/mesh-status.component';
import { PanelStateComponent } from './components/panel-state/panel-state.component';
import { PanelStateSidemenuComponent } from './components/panel-state-sidemenu/panel-state-sidemenu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchComponent } from './components/search/search.component';
import { NeighborRequestComponent } from './components/neighbor-request/neighbor-request.component';
import { MacTableComponent } from './components/panel-state-sidemenu/mac-table/mac-table.component';
import { MapTypeSwitchComponent } from './components/map-view/map-type-switch/map-type-switch.component';
import { NeighborReloadComponent } from './components/neighbor-reload/neighbor-reload.component';
import { TooltipDeitresModule } from '../common/ui/tooltip/tooltipDeitres.module';
import { BrowserModule } from '@angular/platform-browser';
import { MonitoringStateComponent } from './components/monitoring-state/monitoring-state.component';

@NgModule({
  declarations: [
    MapViewComponent,
    MeshStatusComponent,
    PanelStateComponent,
    PanelStateSidemenuComponent,
    SearchComponent,
    NeighborRequestComponent,
    MacTableComponent,
    MapTypeSwitchComponent,
    NeighborReloadComponent,
    MonitoringStateComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppMaterialModule,
    FlexLayoutModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipDeitresModule,
  ],
})
export class MapModule {}
