const textControl = {
  name: /[^a-zA-Z0-9 ]/g,
  receiverName: /[^a-zA-Z0-9 ]/g,
  EZVIZAccountName: /[^a-zA-Z0-9]/g,
  linkName: /[^a-zA-Z0-9.]/g,
  apiKey:  /[^a-zA-Z0-9]/g,
}
  
export const updateValue = (key, value, form, currentCompany) => {
  currentCompany[key] = value;
  form.controls[key].setValue(value, { emitEvent: false });
} 

export const updateLocation = (key, value, form, currentCompany) => {
  currentCompany[key] = value;
  form.controls[key].setValue(value.lat + ',' + value.lng, { emitEvent: false });
} 

export const validatorString = (key, event, form, currentCompany, minLenght = 0) => {
  if (!event.value || currentCompany[key] === event.value) return;
  updateValue(key, event.value.replace(textControl[key], ''), form, currentCompany);

  if (minLenght) return currentCompany[key].length >= minLenght;
  return null;
}

export const validatorNumeric = (key, event, form, currentCompany, maxLimit = 5) => {
  if (!event.value || currentCompany[key] === event.value) return;
  updateValue(key, event.value, form, currentCompany);

  const invalidParam = !checkNumeric(event.value, maxLimit);
  return invalidParam ? { invalidParam } : null;
}

export const validateUserCode = (key, event, form, currentCompany) => {
  if (!event.value || currentCompany[key] === event.value) return;

  const resUserCode = formatUserCode(event.value);
  updateValue(key, resUserCode.result, form, currentCompany);

  const invalidParam = !resUserCode.success;
  return invalidParam ? { invalidParam } : null;
}
  
export const validateWebUrl = (key, event, form, currentCompany) => {
  if (!event.value || currentCompany[key] === event.value) return;
  updateValue(key, event.value, form, currentCompany);

  const invalidParam = !checkURL(currentCompany[key]);
  return invalidParam ? { invalidParam } : null;
}

export const validateIp = (key, event, form, currentCompany) => {
  if (!event.value || currentCompany[key] === event.value) return;

  const resIP = formatIP(event.value);
  if (resIP.result) updateValue(key, resIP.result, form, currentCompany);

  const invalidParam = !resIP.success;
  return invalidParam ? { invalidParam } : null;
}

export const validateEmail = (key, event, form, currentCompany) => {
  if (!event.value || currentCompany[key] === event.value) return;
  updateValue(key, event.value, form, currentCompany);

  const invalidParam = !checkEmailFormat(currentCompany[key]);
  return invalidParam ? { invalidParam } : null;
}

export const validateApikey = (key, event, form, currentCompany) => {
  if (!event.value || currentCompany[key] === event.value) return;

  let value = event.value.replace(textControl[key], '');
  value = value.slice(0, 8);
  updateValue(key, value, form, currentCompany);

  const invalidParam = value.length !== 8;
  return invalidParam ? { invalidParam } : null;
}

export const checkEmailFormat = (email) => {
  const regex = /^[\w.+-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
}

export const checkCoords = (coords) => {
  const regex = /^\s*(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)\s*$/;
  const match = coords.match(regex);
  if (!match) return { success: false };

  const lat = parseFloat(match[1]);
  const lng = parseFloat(match[3]);
  const success = lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180;
  return { success, location: { lat, lng } };
}

export const checkNumeric = (value, maxLength) => {
  return !isNaN(value) && value.toString().length <= maxLength; 
}

export const formatIP = (ip) => {
  let formated = ip?.replace(/[^\d.]/g, "");
  formated = formated?.replace(/\.{2,}/g, ".");
  
  const partes = formated?.split(".");
  const finalIP = partes?.map(part => String(Number(part))).join(".");
  
  if (!partes || partes.length !== 4 || partes.some(part => part === "" || Number(part) > 255)) {
     return { success: false, result: finalIP };
  }
  
  return { success: true, result: finalIP };
}

export const checkURL = (url) => {
  const regex = /^(https?:\/\/)?([a-zA-Z\d-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/.*)?$/;
  return regex.test(url.trim());
}

export const formatUserCode = (code) => {
  if (!code || code === '') return { success: true };
  const match = code.replace(/[^0-9A-Fa-f]/g, "").slice(0, 2);
  return { success: (match.length === 2), result: match.toUpperCase() };
}
