import { Device } from "src/app/partitions/models/device";

export class Panel {
  _id: string;
  mac: string;
  type: string;
  company: string;
  isCommunicator: boolean;
  receiverName: string;
  apiKey: string;
  ssid: string;
  groups: string[];
  meshDate: Date;
  ethDate: Date;
  wifiDate: Date;
  mobileDate: Date;
  meshSignal: number;
  ethSignal: number;
  wifiSignal: number;
  gsmSignal: number;
  mobileSignal: number;
  adminNotes: string;
  deviceErrorsPresent: boolean;
  isRepeating: boolean;
  selectedToSupport: boolean;
  state: string;
  partitions: [{
    name: String,
    account: string;
    partitionNumber: number;
    devices: Device[];
    mac: string;
  }];
  unavailableModules: {
    wifi: boolean,
    eth: boolean,
    mesh: boolean,
    mobile: boolean,
  };
  vitals: {
    batteryStatus: string,
    energyStatus: boolean,
    tampered: boolean,
  };
  location: {
    latitude: number,
    longitude: number
  };
  version: {
    deviceModel: string,
    firmVersion: string,
    hardVersion: string,
    zigbeeVersion: string,
    firmwareType: string,
    panelType: string,
  };
  map: {
    name: string,
    account: string,
    parent: string,
    path: string[],
    function: string,
    instalationType: string,
    interfaceReport: string,
    lastReport: Date,
    groups: string[]
    neighbors: string[],
    neighborsQty: number,
    address: string,
    splitString: string[]
  };
  reports: { _id: string, date: Date }[];
  clicked: boolean;
  reloadCounter = 0;
  errors = 0;
  active: boolean = true;
  
  constructor() {
    this.unavailableModules = {
      wifi: false,
      eth: false,
      mesh: false,
      mobile: false
    }
    this.vitals = {
      batteryStatus: 'normal',
      energyStatus: true,
      tampered: false
    }
    this.active = true;
  }
}
