<div leftmargin="0" topmargin="0" marginwidth="0" marginheight="0">
  <div class="left-part">
    <div style= "width: 700px; height: 0px;">
      <div class="keyboard" style="
        background-image: url('../../../assets/img/buttonPanel.png');
        background-repeat: no-repeat;
        background-size: 330px 475px;
        height: 453px;
      ">
        <!-- Botones del Teclado -->
        <div style="padding-top: 1.5%;">
        <div class="number-line">
          <button mat-mini-fab class="keyboard-btn" (click)="keyboardService.writeKey('1')"></button>
          <button mat-mini-fab class="keyboard-btn" (click)="keyboardService.writeKey('2')"></button>
          <button mat-mini-fab class="keyboard-btn" (click)="keyboardService.writeKey('3')"></button>
        </div>

        <div class="number-line">
          <button mat-mini-fab class="keyboard-btn" (click)="keyboardService.writeKey('4')"></button>
          <button mat-mini-fab class="keyboard-btn" (click)="keyboardService.writeKey('5')"></button>
          <button mat-mini-fab class="keyboard-btn" (click)="keyboardService.writeKey('6')"></button>
        </div>

        <div class="number-line">
          <button mat-mini-fab class="keyboard-btn" (click)="keyboardService.writeKey('7')"></button>
          <button mat-mini-fab class="keyboard-btn" (click)="keyboardService.writeKey('8')"></button>
          <button mat-mini-fab class="keyboard-btn" (click)="keyboardService.writeKey('9')"></button>
        </div>

        <div class="number-line">
          <button mat-mini-fab class="keyboard-btn" (click)="keyboardService.writeKey('*')"></button>
          <button mat-mini-fab class="keyboard-btn" (click)="keyboardService.writeKey('0')"></button>
          <button mat-mini-fab class="keyboard-btn" (click)="keyboardService.writeKey('#')"></button>
        </div>

        <div class="number-line" style="padding-top: 0.3%;">
          <button mat-mini-fab class="keyboard-btn keyboard-btn-spec" (click)="keyboardService.writeKey('F')"></button>
          <button mat-mini-fab class="keyboard-btn keyboard-btn-spec" (click)="keyboardService.writeKey('A')"></button>
          <button mat-mini-fab class="keyboard-btn keyboard-btn-spec" (click)="keyboardService.writeKey('P')"></button>
        </div>
        <div class="armed-buttons-line" style="padding-top: 0%;">
          <!-- Armado Total -->
          <button mat-mini-fab class="keyboard-btn keyboard-btn-arm" (click)="keyboardService.writeKey('ArmTotal')"></button>
          <!-- Armado parcial -->
          <button mat-mini-fab class="keyboard-btn keyboard-btn-arm" (click)="keyboardService.writeKey('ArmPerimeter')"></button>
        </div>
        </div>
      </div>
    </div>
  </div>
  <div class="right-part">
    <div class="keyboard" style="
        background-image: url('../../../assets/img/ledsPanel.png');
        background-repeat: no-repeat;
        background-size: 330px 475px;
        height: 453px;
        ">
      <div class="leds-container">
        <!-- Leds de estado del teclado -->
        <div class="stateleds-container">
          <div>
            <mat-icon class="leds" style="font-size: small;" [ngClass]="this.stateLedStatus[0].on ? 'greenLed' : ''">circle</mat-icon>
          </div>
          <div>
            <mat-icon class="leds" style="font-size: small;" [ngClass]="this.stateLedStatus[1].on ? 'redLed' : ''">circle</mat-icon>
          </div>
          <div>
            <mat-icon class="leds" style="font-size: small;" [ngClass]="this.stateLedStatus[2].on ?  'yellowLed' : ''">circle</mat-icon>
          </div>
        </div>
        <!-- Leds de Zonas -->
        <div class="zoneleds-container">
          <div class="zoneleds-column">
            <ng-container *ngFor="let item of [0,1,2,3,4,5,6,7,8,9,10]">
              <div>
                <mat-icon class="leds" style="font-size: small;" [ngClass]="this.zoneLedStatus[item].on ? 'redLed' : ''">circle</mat-icon>
              </div>
            </ng-container>
          </div>
          <div class="zoneleds-column">
            <ng-container *ngFor="let item of [11,12,13,14,15,16,17,18,19,20,21]">
              <div>
                <mat-icon class="leds" style="font-size: small;" [ngClass]="this.zoneLedStatus[item].on ? 'redLed' : ''">circle</mat-icon>
              </div>
            </ng-container>
          </div>
          <div class="zoneleds-column">
            <ng-container *ngFor="let item of [22,23,24,25,26,27,28,29,30,31]">
              <div>
                <mat-icon class="leds" style="font-size: small;" [ngClass]="this.zoneLedStatus[item].on ? 'redLed' : ''">circle</mat-icon>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>