<form (ngSubmit)="onSubmit()" name="f" #f="ngForm" novalidate formValidTimerRange>
  <div class="container">
    <div class="title-div">
      <h1 class="title">{{ 'Profile.EditPreferences' | translate }}</h1>
      <button mat-icon class="icon" type="button" (click)="close()"><mat-icon>close</mat-icon></button>
    </div>
    <div class="content">
      <div class="form-field">
        <div class="input-text" [class.selected-error]="nameModel.invalid && nameModel.errors?.required">
          <mat-form-field appearance="fill" class="custom-mat-field no-appearance">
            <mat-label class="input-label">{{ 'Profile.Name' | translate }}</mat-label>
            <input
              matInput
              type="text"
              minlength="2"
              maxlength="20"
              [(ngModel)]="name"
              name="name"
              required
              #nameModel="ngModel"
            />
          </mat-form-field>

          <mat-error class="error" *ngIf="nameModel.invalid && nameModel.errors?.required">{{
            'Partition.RequiredField' | translate
          }}</mat-error>
        </div>
        <div class="input-text" [class.selected-error]="surnameModel.invalid && surnameModel.errors?.required">
          <mat-form-field appearance="fill" class="custom-mat-field no-appearance">
            <mat-label class="input-label">{{ 'Profile.Surname' | translate }}</mat-label>
            <input
              matInput
              [(ngModel)]="surname"
              name="surname"
              minlength="2"
              maxlength="20"
              required
              #surnameModel="ngModel"
            />
          </mat-form-field>
          <mat-error class="error" *ngIf="surnameModel.invalid && surnameModel.errors?.required">{{
            'Partition.RequiredField' | translate
          }}</mat-error>
        </div>
      </div>
      <div class="form-field">
        <div
          class="input-text"
          [class.selected-error]="
            (emailModel.invalid && emailModel.errors?.required) || (emailModel.invalid && emailModel.errors?.email)
          "
        >
          <mat-form-field appearance="fill" class="selected no-appearance custom-mat-field">
            <mat-label class="input-label">{{ 'Profile.Email' | translate }}</mat-label>
            <input
              matInput
              [(ngModel)]="email"
              name="email"
              minlength="2"
              maxlength="50"
              required
              email
              #emailModel="ngModel"
              type="email"
            />
          </mat-form-field>
          <mat-error class="error" *ngIf="emailModel.invalid && emailModel.errors?.required">
            {{ 'Partition.RequiredField' | translate }}
          </mat-error>
          <mat-error class="error" *ngIf="emailModel.invalid && emailModel.errors?.email">
            {{ 'Profile.InvalidEmail' | translate }}
          </mat-error>
        </div>
        <div class="input-text" [class.selected-error]="phoneModel.invalid && phoneModel.errors?.required">
          <mat-form-field appearance="fill" class="custom-mat-field no-appearance">
            <mat-label class="input-label">{{ 'Profile.Phone' | translate }}</mat-label>
            <input
              matInput
              [(ngModel)]="phone"
              name="phone"
              maxlength="25"
              required
              #phoneModel="ngModel"
              type="text"
              mask="{{ mask }}"
              (ngModelChange)="applyMask()"
            />
          </mat-form-field>
          <mat-error class="error" *ngIf="invalidPhoneMask">{{ 'Profile.InvalidCountryCode' | translate }}</mat-error>
          <mat-error class="error" *ngIf="!invalidPhoneMask && phoneModel.invalid && phoneModel.errors?.required">{{
            'Partition.RequiredField' | translate
          }}</mat-error>
        </div>
      </div>
      <mat-form-field appearance="fill" class="no-appearance">
        <mat-label class="input-label">{{ 'Profile.Language' | translate }}</mat-label>
        <mat-select [(ngModel)]="selectedLanguage" name="language" class="custom-input">
          <mat-option *ngFor="let lang of language" [value]="lang.value" class="option">
            {{ lang.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="form-field" [ngClass]="{ 'transition-bottom': invalidPassword, 'selected-error': invalidPassword }">
        <div class="input-text">
          <mat-form-field appearance="fill" class="custom-mat-field no-appearance">
            <mat-label class="input-label">{{ 'Login.Password' | translate }}</mat-label>
            <input
              matInput
              [(ngModel)]="password"
              name="password"
              minlength="8"
              #passModel="ngModel"
              type="password"
              placeholder="{{ 'Profile.AlterPassword' | translate }}"
              autocomplete="new-password"
            />
            <p class="info-password" [class.invalidLenght]="invalidPassword">
              {{ 'Profile.Minimun8Characters' | translate }}
            </p>
          </mat-form-field>
        </div>
        <div class="input-text">
          <mat-form-field appearance="fill" class="custom-mat-field no-appearance">
            <mat-label class="input-label">{{ 'Profile.ConfirmPassword' | translate }}</mat-label>
            <input
              matInput
              [(ngModel)]="confirmPassword"
              name="confirmPassword"
              minlength="8"
              #confirmModel="ngModel"
              type="password"
              placeholder="{{ 'Profile.ConfirmPassword' | translate }}"
              autocomplete="new-password"
            />
            <p class="info-password" [class.invalidLenght]="invalidPassword">
              {{ 'Profile.Minimun8Characters' | translate }}
            </p>
            <p class="error-pass" *ngIf="invalidPassword">{{ 'Profile.PasswordsNotMatch' | translate }}</p>
          </mat-form-field>
        </div>
      </div>
      <div class="buttons">
        <button mat-button [disabled]="isLoading" type="button" class="white-default-button" (click)="close()">
          {{ 'Shared.Cancel' | translate }}
        </button>
        <button mat-button class="yellow-default-button" type="submit" [disabled]="isLoading">
          <div class="confirm-button">
            <mat-icon *ngIf="isLoading"><mat-spinner diameter="20"></mat-spinner></mat-icon>
            <mat-icon *ngIf="!isLoading">check</mat-icon>{{ 'Shared.SaveChanges' | translate }}
          </div>
        </button>
      </div>
    </div>
  </div>
</form>
