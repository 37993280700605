import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/auth/services/auth.service';
import { environment } from '../../../environments/environment';
import { Panel } from '../models/panel.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import constants from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class PanelService {
  uri = `${environment.webappEndpoint}/panels`;
  constructor(
    private readonly http: HttpClient, 
    private readonly auth: AuthService,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
  ) { }

  httpRequest(method, param, callback, callbackParams, errorMessage) {
    method(...param).subscribe({
      next: (res: any) => {
        callback(res, callbackParams);
        if (res.reason) {
          this.showSnackBar(res.reason);
        }
      },
      error: (error) => {
        callback({ success: false });
        if (error && error.status === 401) {
          this.showSnackBar('Shared.SessionExpired');
        } else {
          this.showSnackBar(errorMessage);
        }
      },
    });
  }
  
  showSnackBar = async (message) => {
    this.snackBar.open(await lastValueFrom(this.translateService.get(message)), await lastValueFrom(this.translateService.get('Shared.Close')), { duration: constants.snackBarDuration });
  }

  getPanels = (getHistory: boolean) => this.http.post(`${this.uri}/`, { getHistory }, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  getPanel = (id: string, mac: string) => this.http.get(`${this.uri}/${id ? id : mac}`, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  updatePanel = (panel: Panel) => this.http.post(`${this.uri}/update`, panel, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  deletePanel = (id: string) => this.http.post(`${this.uri}/delete`, { id }, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  toPanel = (id: string) => this.http.post(`${this.uri}/topanel`, { id }, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  timeSiren = (id: string, value?: any) => this.http.post(`${this.uri}/timeSiren`, { id, value, selectedLanguage: this.auth.selectedLanguage }, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  zoneDelayTime = (id: string, value?: any) => this.http.post(`${this.uri}/zoneDelayTime`, { id, value, selectedLanguage: this.auth.selectedLanguage }, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  audibleDelayZone = (id: string, value?: any) => this.http.post(`${this.uri}/audibleDelayZone`, { id, value, selectedLanguage: this.auth.selectedLanguage }, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  dissarmPass = (id: string, value?: any) => this.http.post(`${this.uri}/dissarmPass`, { id, value, selectedLanguage: this.auth.selectedLanguage }, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  coercionPass = (id: string, value?: any) => this.http.post(`${this.uri}/coercionPass`, { id, value, selectedLanguage: this.auth.selectedLanguage }, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  consultLink  = (id: string, signalType: string) => this.http.post(`${this.uri}/consultLink`, { id, signalType, selectedLanguage: this.auth.selectedLanguage }, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  getKeyboardPasswords = (mac: string, partitionNumber: string) => this.http.post(`${this.uri}/getKeyboardPasswords`, { mac, partitionNumber, selectedLanguage: this.auth.selectedLanguage }, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  setKeyboardPassword = (mac: string, partitionNumber: string, password: string, type: string, userCode: string) => this.http.post(`${this.uri}/setKeyboardPassword`, { mac, partitionNumber, password, type, userCode, selectedLanguage: this.auth.selectedLanguage }, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  removeKeyboardPassword = (mac: string, partitionNumber: string, password: string, type: string, userCode: string) => this.http.post(`${this.uri}/removeKeyboardPassword`, { mac, partitionNumber, password, type, userCode, selectedLanguage: this.auth.selectedLanguage }, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  switchToUnmonitored = (mac: string) => this.http.post(`${this.uri}/switchToUnmonitored`, { mac, selectedLanguage: this.auth.selectedLanguage }, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  informNeighbors = (mac: string) => this.http.post(`${this.uri}/informNeighbors`, { mac, selectedLanguage: this.auth.selectedLanguage }, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  neighborReloadPanel = (mac: string) => this.http.post(`${this.uri}/neighborReload`, { mac, selectedLanguage: this.auth.selectedLanguage }, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  getNeighborReloadStage = () => this.http.post(`${this.uri}/getNeighborReloadStage`, {}, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  dataBusFirmwareVersion = (id: string) => this.http.post(`${this.uri}/dataBusFirmwareVersion`, { id, selectedLanguage: this.auth.selectedLanguage }, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  panelModel = (id: string, panelModel: string) => this.http.post(`${this.uri}/panelModel`, { id, panelModel, selectedLanguage: this.auth.selectedLanguage }, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  databusLowBattery = (id: string, panelModel: string) => this.http.post(`${this.uri}/databusLowBattery`, { id, panelModel, selectedLanguage: this.auth.selectedLanguage }, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  setLocation = (id: string, latitude: number, longitude: number) => this.http.post(`${this.uri}/setLocation`, { id, latitude, longitude, selectedLanguage: this.auth.selectedLanguage }, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  checkZoneBattery = (id: string) => this.http.post(`${this.uri}/checkZoneBattery`, { id, selectedLanguage: this.auth.selectedLanguage }, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
}
