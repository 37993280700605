import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Event, Router } from '@angular/router';
import { AuthService } from './auth/services/auth.service';
import { ComandService } from './tech-support/services/comand.service';
import { ReceiverStatusComponent } from './shared/components/receiver-status/receiver-status.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { PanelStatusService } from './map/service/panel-status.service';
import { ReceiversService } from './shared/services/receivers.service';
import { PanelsService } from './shared/services/panels.service';
import { PartitionsService } from './shared/services/partitions.service';
import { UsersService } from './shared/services/users.service';
import { UsersService as UsersDataService } from 'src/app/shared/services/users.service';
import * as pkg from '../../package.json';
const { version } = pkg;
import { CustomMapService } from './map/service/custom-map.service';
import { PopupLanguageService } from './map/service/popup-language-service';
import { EventService } from './panel/services/event.service';
import { LeftSideMenuNavigation } from './panel/models/event.model';
import { HoverMenuesService } from './hover-menues/services/hover-menues.service';
import { ProfileService } from './profile/services/profile.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @ViewChild('helpButton', { static: false }) helpButton!: ElementRef;
  @ViewChild('receiverSelector', { static: false }) receiverSelector!: ElementRef;

  title = 'mialarma-users-ui';
  companyLogo: string;
  showMenu = false;
  v = version;

  helpBubble = {
    icon: 'info',
    title: 'AppComponent.HelpMessage_title',
    description: 'AppComponent.HelpMessage_description',
    confirm: 'AppComponent.HelpMessage_confirm',
    onConfirm: () => {
      this.authService.updateMessageCheck('helpMenuMessage');
    },
    onClose: () => {
      this.hoverMenuesService.helpBubbleMessageStatus = false;
    },
  };

  constructor(
    private readonly _loadingBar: LoadingBarService,
    public router: Router,
    public authService: AuthService,
    public commandService: ComandService,
    private readonly dialog: MatDialog,
    public translateService: TranslateService,
    public readonly panelStatus: PanelStatusService,
    public receiversService: ReceiversService,
    private readonly panelsService: PanelsService,
    private readonly partitionsService: PartitionsService,
    private readonly usersDataService: UsersDataService,
    private readonly usersService: UsersService,
    private readonly customMapService: CustomMapService,
    private readonly popupLanguageService: PopupLanguageService,
    private readonly eventService: EventService,
    public hoverMenuesService: HoverMenuesService,
    public profileService: ProfileService
  ) {
    this.router.events.subscribe((event: Event) => {
      this.translateService.setDefaultLang(this.authService.selectedLanguage);
      this.translateService.use(this.authService.selectedLanguage);
    });

    if (this.authService.isLoggedIn()) {
      this.receiversService.connectReceivers();
    }

    this.eventService.menuBarNavigation.subscribe((signalParams: LeftSideMenuNavigation) => {
      if (signalParams.closeLeftMenuNavigationBar && this.showMenu) this.toggleShowMenu();
    });

    this.authService.openBetaWarningModal();
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: Event): void {
    if (this.authService.user) {
      localStorage.setItem('last-navigation-path', this.router.url);
    }
  }

  logout = () => {
    this.authService.logout();
    this.panelStatus.closeMapSession(this.router.url == '/maps');
    this.commandService.cleanCommandData();
    this.commandService.cleanFilters();
    this.commandService.clearMessageArray();
    this.customMapService.setDefaultZoom();
    this.customMapService.clear();

    this.usersService.clear();
    this.panelsService.clear();
    this.partitionsService.clear();
    this.receiversService.clear();
    this.usersDataService.clear();
    this.profileService.clear();
    this.closeProfileMenu();
  };

  onReceiverStatusClick = () => {
    this.dialog.open(ReceiverStatusComponent, {
      width: '45%',
      maxWidth: '1000px',
    });
  };

  toggleShowMenu(): void {
    this.showMenu = !this.showMenu;
  }

  resetMapZoomAndCleanRestorationFocusSystem() {
    this.customMapService.setDefaultZoom();
    this.customMapService.cleanRestorationOfFocus();
    this.panelStatus.resetMapSelection();
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.toggleShowMenu();
    }
  }

  onToggleHelpMenu() {
    this.hoverMenuesService.onToggleHelpMenu();
  }

  onToggleProfileMenu() {
    this.hoverMenuesService.onToggleProfileMenu();
  }

  closeProfileMenu() {
    this.hoverMenuesService.profileMenuStatus = false;
  }
}
