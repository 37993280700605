import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ReceiverStatusComponent } from 'src/app/shared/components/receiver-status/receiver-status.component';
import { ReceiversService } from 'src/app/shared/services/receivers.service';

@Component({
  selector: 'app-monitoring-state',
  templateUrl: './monitoring-state.component.html',
  styleUrls: ['./monitoring-state.component.scss'],
})
export class MonitoringStateComponent implements OnInit {
  launchMonitoringStatusAtStartup: boolean;

  constructor(
    public readonly receiversService: ReceiversService,
    private readonly dialog: MatDialog,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    /** @see src/app/shared/components/receiver-status/receiver-status.component.ts */
    this.launchMonitoringStatusAtStartup =
      (localStorage.getItem('monitoring-at-startup') ?? `${this.authService.user._id}-show`) ===
      `${this.authService.user._id}-show`;
  }

  onReceiverStatusClick = () => {
    this.dialog.open(ReceiverStatusComponent, {
      width: '582px',
      minWidth: '582px',
      maxWidth: '582px',
    });
  };
}
