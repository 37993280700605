import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import constants from '../../constants'

export interface DialogData {
  paramType: string;
}

@Component({
  selector: 'app-help-list',
  templateUrl: './help-list.component.html',
  styleUrls: ['./help-list.component.scss']
})
export class HelpListComponent implements OnInit {

  title: string;
  translateKey: string;
  rowList: any[] = [];
  columnRight: number[] = [];
  columnLeft: number[] = [];

  constructor(
    private dialogRef: MatDialogRef<HelpListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { 
    this.title = 'Constants.' + this.data.paramType;
    this.translateKey = 'Constants.' + this.data.paramType + 'Hints.';
    const values = constants.helpHintsvalue[constants.helpHints[this.data.paramType]];

    this.columnRight = [];
    this.columnLeft = [];
    const halfLength = values.length/2;
    for (let i = 0; i < values.length; i++) {
      if (i <= halfLength) {
        this.columnLeft.push(values[i]);
      } else {
        this.columnRight.push(values[i]);
      }
    }
  }

  ngOnInit(): void {
  }

  onCancelClick(): void {
    this.dialogRef.close({ status: 'success' });
  }

}
