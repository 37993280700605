<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{'User.UserDetails' | translate}}
    </mat-card-title>
  </mat-card-header>
  <div style="margin: 10px; border-color: primary;">
    <div>
      <mat-card-title-group>{{'User.Information' | translate}}:</mat-card-title-group>
      <mat-card-content class="divider">
        <dl class="divided">
          <dt><b>{{'User.Name' | translate}}: </b> {{user.name}}</dt>
          <dt><b>{{'User.Surname' | translate}}: </b> {{user.lastName}}</dt>
          <dt><b>{{'User.Email' | translate}}: </b> {{user.email}}</dt>
          <dt><b>{{'User.Type' | translate}}: </b> {{(user.type === 'tech' ? 'User.Technician' : 'User.Final') | translate}}</dt>
          <dt><b>{{'User.Language' | translate}}: </b> {{('User.' +  (user.selectedLang === undefined ? 'Undefined' : user.selectedLang)) | translate}}</dt>
          <dt *ngIf="this.authService.isAdmin()"><b>{{"AppComponent.Company" | translate}}: </b> {{this.authService.getCompanyName(user.company)}}</dt>
        </dl>
        <dl class="divided">
          <dt><b>{{'User.SecureAccess' | translate}}: </b> {{user.secureAccessTime/1000 + 's'}}</dt>
          <dt><b>{{'User.SecureAccessWarn' | translate}}: </b> {{user.secureAccessWarning ? user.secureAccessWarning/1000 + 's' : 'User.No' | translate}}</dt>
          <dt><b>{{'User.UserActive' | translate}}: </b> {{(user.active ? 'User.Yes' : 'User.No') | translate}}</dt>
          <dt><b>{{'User.ResetPassApp' | translate}}: </b> {{(user.forceChangePass ? 'User.Yes' : 'User.No') | translate}}</dt>
          <dt><b>{{'User.ResetPassDis' | translate}}: </b> {{(user.forceChangeDisarmPass ? 'User.Yes' : 'User.No') | translate}}</dt>
          <div *ngIf="chatbotAdministrador">
            <dt><b>{{'User.AllowPushNotifications' | translate}}: </b> {{(user?.intelligentNotifications?.allowPushNotifications != undefined && user.intelligentNotifications.allowPushNotifications ? 'User.Yes' : 'User.No') | translate}}</dt>
            <dt><b>{{'User.AllowWhatsapp' | translate}}: </b> {{(user?.intelligentNotifications?.allowWhatsapp != undefined && user.intelligentNotifications.allowWhatsapp ? 'User.Yes' : 'User.No') | translate}}</dt>
            <dt><b>{{'User.WhatsappNumber' | translate}}: </b> {{user?.intelligentNotifications?.whatsappNumber != undefined && user.intelligentNotifications.whatsappNumber ? user.intelligentNotifications.whatsappNumber : '-'}}</dt>
          </div>
        </dl>
      </mat-card-content>
    </div>
    <div *ngIf="user.type === 'final'">
      <mat-card-title-group>{{'User.AssignedPart' | translate}}:</mat-card-title-group>
      <mat-card-content *ngIf="user.partitions && user.partitions.length > 0" style="max-height: 100px;overflow-y: auto;">
        <table mat-table [dataSource]="dataSource" matSort>
          <!-- Nombre -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> {{'User.Name' | translate}} </th>
            <td mat-cell *matCellDef="let element">{{element.partition.name}}</td>
          </ng-container>

          <!-- Cuenta -->
          <ng-container matColumnDef="account">
            <th mat-header-cell *matHeaderCellDef> {{'User.Account' | translate}} </th>
            <td mat-cell *matCellDef="let element">{{element.partition.account}}</td>
          </ng-container>

          <!-- Nº Usuario -->
          <ng-container matColumnDef="userCode">
            <th mat-header-cell *matHeaderCellDef> {{'User.UserNumber' | translate}} </th>
            <td mat-cell *matCellDef="let element">{{element.userCode}}</td>
          </ng-container>

          <!-- Mac -->
          <ng-container matColumnDef="mac">
            <th mat-header-cell *matHeaderCellDef> Mac </th>
            <td mat-cell *matCellDef="let element">{{element.partition.mac}}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'disabled': !row.active}"></tr>
        </table>
      </mat-card-content>
      <mat-card-content *ngIf="!user.partitions || user.partitions.length === 0">{{'User.NoAssignedPart' | translate}}</mat-card-content>
    </div>
  </div>
</mat-card>
<div style="margin-top: 20px; text-align: right;">
  <button type="button" mat-raised-button (click)="onCloseClick()">{{'Shared.Close' | translate}}</button>
</div>
