import { Injectable } from '@angular/core';
import { MessagesProcessorSocket } from 'src/app/services/socket.service';
import { KeyboardService } from 'src/app/keyboard/services/keyboard.service';

class Device {
  mac?: string;
  accountNumber?: string;
  type?: string;
}

class Message {
  hexRaw: string;
  type?: string;
  age?: number;
  sequence?: number;
}

class Gateway {
  device: Device;
  message: Message;
}

class Receiver {
  apiKey: string;
  name: string;
}

class Connection {
  date: string;
  deviceIP?: string;
  devicePort?: number;
  interface: string;
  sequence?: number;
}

class Decodification {
  reportedPartitions: number;
  id: number;
  date: string;
  partitionNumber: number;
  partitions: Partition[];
  type: string;
  status: boolean;
  reason?: string;
}

class Partition {
  partition: number;
  mode: string;
  sound: number;
  armSound: string;
  zones: number[];
  systemLeds: {
    light: number;
    bypass: number;
    program: number;
    system: number;
    armed: number;
    ready: number;
    fire: number;
    memory: number;
  };
}

export class ListenInfo {
  device: Device;
  message: Message;
  gateway: Gateway;
  receiver: Receiver;
  connection: Connection;
  status: boolean;
  decodification?: Decodification;
}

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  listenEvent = this.mpSocket.fromEvent<ListenInfo>('message');
  connectMPEvent = this.mpSocket.fromEvent('connect');
  disconnectMPEvent = this.mpSocket.fromEvent('disconnect');

  connectedMP: boolean;

  constructor(private mpSocket: MessagesProcessorSocket, private keyboardService: KeyboardService) {
    this.connectMPSocket();
    this.disconnectMPEvent.subscribe(() => {
      this.connectedMP = false;
    });
  }

  connectMPSocket() {
    this.connectMPEvent.subscribe(() => {
      console.log('suscripto a mensajes de teclado a: ' + this.keyboardService.mac);
      this.emitMP('join', this.keyboardService.mac);
      this.connectedMP = true;
    });
  }

  initMPListener() {
    console.log('suscripto a mensajes de teclado a: ' + this.keyboardService.mac);
    this.emitMP('join', this.keyboardService.mac);
  }

  unsubscribe() {
    this.emitMP('leave', this.keyboardService.mac);
  }

  emitMP(eventName: string, data?: any, callback?: any) {
    this.mpSocket.emit(eventName, data, callback);
  }
}
