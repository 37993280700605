import { Component } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  isVisible = true;
  tooltip: string = '';
  tooltipSize: string = '';
  tooltipMeshStatus: boolean = false;
  left: number = 0;
  top: number = 0;

  constructor() {}
}
