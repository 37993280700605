<div class="content" fxFlex>
  <header fxLayout="row" fxLayoutAlign="space-between center" class="header">
    <h1>{{ 'Profile.MyProfile' | translate }}</h1>
    <button mat-button class="button-filter" (click)="openDialog()">{{ 'Profile.EditProfile' | translate }}</button>
  </header>
  <main class="main">
    <div class="main-content">
      <section class="user-content">
        <div>
          <img src="assets/img/citymesh-logo-user.png" alt="Logo" class="img" />
        </div>
        <div class="user">
          <span class="name">{{ userCompanyValues.name + ' ' + userCompanyValues.lastName }}</span>
          <span class="desc">{{ 'Profile.UserCitymeshDesc' | translate }}</span>
        </div>
      </section>
      <section class="user-info">
        <span class="title">{{ 'Profile.GeneralInformations' | translate }}</span>
        <div class="info-content">
          <div class="info">
            <img src="assets/icons/devices/suitcase.svg" alt class="img-icon align-self" />
            <div>
              <span>{{ getCompanyKey() }}</span
              ><span class="bold">{{ getCompany() }}</span>
            </div>
          </div>
          <div class="info">
            <img src="assets/icons/devices/letter.svg" alt />
            <div>
              <span>{{ 'Profile.Email' | translate }}</span
              ><span class="bold">{{ userCompanyValues.email }}</span>
            </div>
          </div>
          <div class="info">
            <img src="assets/icons/devices/phone.svg" alt />
            <div>
              <span>{{ 'Profile.Phone' | translate }}</span
              ><span class="bold">{{ formattedPhone }}</span>
            </div>
          </div>
          <div class="info">
            <img src="assets/icons/devices/globe.svg" alt />
            <div>
              <span>{{ 'Profile.Language' | translate }}</span
              ><span class="bold">{{ getTranslatedLanguage() }}</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</div>
