<form name="f" #f="ngForm" novalidate formValidTimerRange>
  <div>
    <div>
      <h1 mat-dialog-title>{{ 'TechSupport.NewCommand' | translate }}</h1>

      <form style="margin-bottom: 20px">
        <div class="dialog-grid">
          <div class="dialog-one-column" *ngFor="let option of this.sendCommandService.allAvailableCommandSources">
            <input type="radio" name="commandSources" [value]="option" (click)="changeCommandsList(option)" />
            <span>{{ 'TechSupport.CommandSources.' + option | translate }}</span>
          </div>
        </div>
      </form>

      <mat-dialog-content class="scrollbar">
        <!-- Row 1 -->
        <div fxLayout="row" fxLayoutGap="30px">
          <!-- Nombre -->
          <div
            class="field-container"
            *ngIf="
              this.sendCommandService.allAvailableCommandsClusteredBySourceType[this.selectedSourceType].length > 0
            "
            fxFlex
          >
            <mat-form-field>
              <mat-label>{{ 'TechSupport.Commands' | translate }}</mat-label>
              <select
                class="prevent-select"
                name="selectedCommand"
                [(ngModel)]="selectedCommand"
                (ngModelChange)="onSelectCommand($event)"
                matNativeControl
                required
                [disabled]="this.data.operation === 'update'"
              >
                <option [ngValue]="undefined" selected>{{ 'TechSupport.SelectCommand' | translate }}</option>
                <option
                  *ngFor="
                    let c of this.sendCommandService.allAvailableCommandsClusteredBySourceType[this.selectedSourceType]
                  "
                  [ngValue]="c"
                >
                  {{ c.abbr + ' - ' + c.name }}
                </option>
              </select>
            </mat-form-field>
          </div>
        </div>
        <!-- Row 2 -->
        <div class="description" *ngIf="selectedCommand" fxLayout="row" fxLayoutGap="30px">
          <!-- Descripción -->
          <div class="field-container" fxFlex>
            <span
              style="font-size: 14px; color: #424242"
              [innerHTML]="selectedCommand ? formatText(selectedCommand.description) : ''"
            ></span>
          </div>
        </div>
        <!-- Row 3 -->
        <div
          *ngIf="selectedCommand && !selectedCommand?.onlySet && paramSetList && paramSetList.length > 0"
          fxLayout="row"
          fxLayoutGap="30px"
        >
          <!-- Consulta / Configuración -->
          <div class="field-container" fxFlex>
            <mat-checkbox
              style="margin-top: 10px; margin-left: 1px; margin-bottom: 5px"
              color="primary"
              class="user-check"
              [(ngModel)]="isConfig"
              type="checkbox"
              name="{{ 'TechSupport.Configuration' | translate }}"
              >{{ 'TechSupport.Configuration' | translate }}</mat-checkbox
            >
          </div>
        </div>
        <!-- Row 3 -->
        <div class="field-container" *ngIf="selectedCommand" fxLayout="30px">
          <!-- Parametros de Consulta -->
          <div class="field-container" *ngIf="!isConfig && !selectedCommand?.onlySet" fxFlex>
            <div *ngIf="paramGetList && paramGetList.length > 0" fxLayoutGap="20px">
              <div *ngFor="let param of paramGetList" style="display: flex">
                <div style="width: 100%">
                  <!-- Parametro Númerico, Hexa o String -->
                  <mat-form-field
                    *ngIf="
                      param.type === getParamTypes().Number ||
                      param.type === getParamTypes().Hexa ||
                      param.type === getParamTypes().String
                    "
                  >
                    <input
                      #tooltip="matTooltip"
                      matTooltip="{{ param.helpText }}"
                      (input)="inputValidator($event, param)"
                      [name]="param.prompt"
                      [(ngModel)]="param.value"
                      matInput
                      [required]="selectedCommand?.optionalParam !== true"
                      autocomplete="false"
                      [errorStateMatcher]="param.errorMatcher"
                    />
                    <mat-placeholder class="placeholder">{{ param.prompt }}</mat-placeholder>
                    <mat-error *ngIf="!param.value && selectedCommand?.optionalParam !== true">{{
                      'TechSupport.RequiredField' | translate
                    }}</mat-error>
                    <mat-error *ngIf="param.value && param.errorMatcher && !param.errorMatcher.getValid(param.value)">{{
                      'TechSupport.InvalidValue' | translate
                    }}</mat-error>
                  </mat-form-field>
                  <!-- Parametro Boolean -->
                  <div
                    *ngIf="param.type === getParamTypes().Boolean && param.prompt != saveConfigurationTranslated"
                    fxFlex
                  >
                    <mat-checkbox
                      #tooltip="matTooltip"
                      matTooltip="{{ param.helpText }}"
                      color="primary"
                      class="user-check"
                      [(ngModel)]="param.value"
                      type="checkbox"
                      [name]="param.prompt"
                      style="display: block; margin-left: 1px; font-size: 14px; color: gray"
                      >{{ param.prompt }}</mat-checkbox
                    >
                  </div>
                  <!-- Parametro Array -->
                  <mat-form-field
                    #tooltip="matTooltip"
                    matTooltip="{{ param.helpText }}"
                    *ngIf="param.type === getParamTypes().Array && param.arrayValues && param.arrayValues.length > 0"
                  >
                    <select
                      [(ngModel)]="param.value"
                      matNativeControl
                      [required]="selectedCommand?.optionalParam !== true"
                      name="arrayvalue"
                      style="font-size: 14px; color: gray"
                    >
                      <option [ngValue]="undefined" selected>{{ 'TechSupport.SelectOption' | translate }}</option>
                      <option *ngFor="let v of param.arrayValues" [ngValue]="v">{{ v }}</option>
                    </select>
                    <mat-error *ngIf="!param.value && selectedCommand?.optionalParam !== true">{{
                      'TechSupport.RequiredField' | translate
                    }}</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div
              *ngIf="!paramGetList || paramGetList.length === 0"
              fxLayout="row"
              fxLayoutGap="30px"
              style="margin-top: 10px; font-size: 14px; color: gray"
            >
              <p>{{ 'TechSupport.NoGetParams' | translate }}</p>
            </div>
          </div>
          <!-- Parametros de Configuración -->
          <div class="field-container" *ngIf="isConfig || selectedCommand?.onlySet" fxFlex>
            <div *ngIf="paramSetList && paramSetList.length > 0" fxLayoutGap="20px">
              <div *ngFor="let param of paramSetList" style="display: flex">
                <div style="width: 100%">
                  <!-- Parametro Númerico, Hexa o String -->
                  <mat-form-field
                    *ngIf="
                      param.type === getParamTypes().Number ||
                      param.type === getParamTypes().Hexa ||
                      param.type === getParamTypes().String
                    "
                  >
                    <input
                      #tooltip="matTooltip"
                      matTooltip="{{ param.helpText }}"
                      (input)="inputValidator($event, param)"
                      [name]="param.prompt"
                      [(ngModel)]="param.value"
                      matInput
                      [required]="selectedCommand?.optionalParam !== true"
                      autocomplete="false"
                      [errorStateMatcher]="param.errorMatcher"
                    />
                    <mat-placeholder class="placeholder">{{ param.prompt }}</mat-placeholder>
                    <mat-error *ngIf="!param.value && selectedCommand?.optionalParam !== true">{{
                      'TechSupport.RequiredField' | translate
                    }}</mat-error>
                    <mat-error *ngIf="param.value && param.errorMatcher && !param.errorMatcher.getValid(param.value)">{{
                      'TechSupport.InvalidValue' | translate
                    }}</mat-error>
                  </mat-form-field>
                  <!-- Parametro Boolean -->
                  <div *ngIf="param.type === getParamTypes().Boolean" style="margin-top: 4px; font-size: 14px" fxFlex>
                    <mat-checkbox
                      color="primary"
                      class="user-check"
                      [(ngModel)]="param.value"
                      type="checkbox"
                      [name]="param.prompt"
                      style="display: block; margin-left: 1px"
                      ><span style="margin-left: 1px; font-size: 14px; color: gray" class="prevent-select">{{
                        param.prompt + ' ' + (param.value ? 'Activado' : 'Desactivado')
                      }}</span></mat-checkbox
                    >
                    <!-- TODO Apply traduction when messages-processor support multi language  || (param.value ? ('TechSupport.Enabled' | translate) : ('TechSupport.Disabled' | translate))-->
                  </div>
                  <!-- Parametro Array -->
                  <mat-form-field
                    #tooltip="matTooltip"
                    matTooltip="{{ param.helpText }}"
                    *ngIf="param.type === getParamTypes().Array && param.arrayValues && param.arrayValues.length > 0"
                  >
                    <select
                      [(ngModel)]="param.value"
                      matNativeControl
                      [required]="selectedCommand?.optionalParam !== true"
                      name="arrayvalue"
                      style="font-size: 14px; color: gray"
                    >
                      <option [ngValue]="undefined" selected>{{ 'TechSupport.SelectOption' | translate }}</option>
                      <option *ngFor="let v of param.arrayValues" [ngValue]="v">{{ v }}</option>
                    </select>
                    <mat-error *ngIf="!param.value && selectedCommand?.optionalParam !== true">{{
                      'TechSupport.RequiredField' | translate
                    }}</mat-error>
                  </mat-form-field>
                </div>
                <div fxLayout fxLayoutAlign="center top">
                  <mat-icon
                    onKeyPress
                    onKeyDown
                    onKeyUp
                    onFocus
                    class="handover"
                    onmouseover=""
                    style="cursor: pointer; margin-left: 5px; margin-top: 15px"
                    *ngIf="this.parametersHint.includes(param.name)"
                    (click)="openConfForm(param.name)"
                    >help_outline</mat-icon
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Command Preview -->
        <div *ngIf="selectedCommand" fxLayout fxLayoutAlign="left center">
          <button
            mat-icon-button
            style="margin-left: -10px; margin-top: 5px"
            matTooltip="{{ 'TechSupport.Preview' | translate }}"
            matTooltipShowDelay="750"
            matTooltipPosition="after"
            (click)="checkMessage()"
            aria-hidden="false"
            aria-label="Preview"
          >
            <mat-icon>visibility</mat-icon>
          </button>
          <span style="margin-left: -4px; margin-top: 5px; font-size: 14px; color: gray">{{ commandPreview }}</span>
        </div>
      </mat-dialog-content>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary" (click)="f.form.valid && validateParms() && submit()">
      {{ this.data.submitText }}
    </button>
    <button type="button" mat-raised-button (click)="onCancelClick()">{{ 'TechSupport.Cancel' | translate }}</button>
  </mat-dialog-actions>
</form>
