import { PartitionFormComponent } from './../partition-form/partition-form.component';
import { Partition } from './../../models/partition';
import { PartitionsService as PartitionsDataService } from 'src/app/shared/services/partitions.service';
import { PartitionsService } from './../../services/partitions.service';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { AuthService } from './../../../auth/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmActionDialogComponent } from '../../../shared/components/confirm-action-dialog/confirm-action-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { PartitionInfoComponent } from '../partition-info/partition-info.component';
import { KeyboardService } from 'src/app/keyboard/services/keyboard.service';
import { Router } from '@angular/router';
import { ComandService } from 'src/app/tech-support/services/comand.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/services/notification.service';
import { BreadcrumbedReceptor, BreadCrumberChannelTypes } from 'src/app/panel/models/event.model';
import { EventService } from 'src/app/panel/services/event.service';
import { ReceiversService } from 'src/app/shared/services/receivers.service';
import { PanelStatusService } from 'src/app/map/service/panel-status.service';
import { PanelsService } from 'src/app/shared/services/panels.service';

@Component({
  selector: 'app-partition-list',
  templateUrl: './partition-list.component.html',
  styleUrls: ['./partition-list.component.scss'],
})
export class PartitionListComponent implements OnInit, AfterViewInit {
  filterOptions: string[] = ['partitions'];
  selectedOption: string;
  displayedColumns: string[];
  snackBarDuration = 5000;
  snackBarErrorDuration = 10000;
  color = {};
  forcedReloadCases = ['PartitionNotFound', 'AccountNotFound'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private readonly abm: PartitionsService,
    public partitionsDataService: PartitionsDataService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
    public authService: AuthService,
    public keyboardService: KeyboardService,
    public router: Router,
    private readonly commandService: ComandService,
    public notifyService: NotificationService,
    private readonly eventService: EventService,
    private readonly receiverService: ReceiversService,
    public readonly panelStatusService: PanelStatusService,
    public panelsService: PanelsService
  ) {
    this.displayedColumns = this.authService.isAdmin()
      ? ['name', 'account', 'mac', 'receiver', 'company', 'supportEnabled', 'partitionNumber', 'users', 'actions']
      : ['name', 'account', 'mac', 'receiver', 'supportEnabled', 'partitionNumber', 'users', 'actions'];
    this.partitionsDataService.dataSource.filter = '';

    this.eventService.breadcrumbedReceptor.subscribe((signalParams: BreadcrumbedReceptor) => {
      if (
        signalParams?.type === BreadCrumberChannelTypes.NEWFILTER &&
        this.receiverService.selectedReceiver.length > 0
      ) {
        this.partitionsDataService.filterReceiver();
      }
    });
  }

  ngOnInit() {
    this.partitionsDataService.dataSource.paginator = this.paginator;
    this.partitionsDataService.dataSource.sort = this.sort;
    this.partitionsDataService.updateSelectedToSupport();

    this.partitionsDataService.dataSource.filterPredicate = (partition: Partition, filterValue: string) => {
      const filter = filterValue.trim().toLowerCase();

      if (!this.partitionsDataService.partitionEnableOnFilter(partition)) {
        return false;
      }

      const companyName = this.authService.isAdmin() ? this.authService.getCompanyName(partition.company) : '';

      const fieldsToCheck = [
        partition.name,
        partition.account,
        partition.mac,
        partition.apiKey,
        partition.receiver,
        companyName,
      ];

      return fieldsToCheck.some((field) => field?.trim().toLowerCase().includes(filter));
    };

    if (this.partitionsDataService.partitions.length === 0 && !this.partitionsDataService.isLoadingResults) {
      this.getPartitions();
    }

    if (this.panelsService.panels.length !== 0) {
      this.panelsService.collectPanels();
    } else {
      this.getPanels();
    }
  }

  getPanels = async () => {
    if (!this.panelsService.isLoadingResults) {
      this.panelsService.getPanels();
    }
  };

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
  }

  applyFilter = (filterValue: string) => {
    this.partitionsDataService.dataSource.filter = filterValue;
  };

  onNewPartitionClick = () => {
    const dialogRef = this.dialog.open(PartitionFormComponent, {
      width: '65%',
      maxWidth: '1000px',
      data: { title: 'Partition.NewPartition', submitText: 'Partition.AddPartition', operation: 'add' },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        if (result.status === 'success') {
          this.getPartitions();
        }

        if (result.status !== 'cancel') {
          this.snackBar.open(result.message, this.translateService.instant('Shared.Close'), {
            duration: this.snackBarDuration,
          });
        }
      }
    });
  };

  // Recibe el número de cuenta de la partición seleccionada y crea un array con sus usuarios.
  async notifyPartition(mac: string, account: string, company: string) {
    const operation = 'Notify Partition';

    const title = this.translateService.instant('Partition.NotifyUserPart') + account;

    const arrayUsers = [];

    this.partitionsDataService.dataSource.filteredData.forEach((dataObject) => {
      if (dataObject && dataObject.active && dataObject.account === account) {
        if (dataObject.users.length > 0) {
          dataObject.users.forEach((user) => {
            arrayUsers.push(user.user);
          });
        }
      }
    });

    const partition = {
      mac: mac,
      account: account,
    };

    if (arrayUsers.length < 1) {
      this.snackBar.open(
        this.translateService.instant('Partition.NotUsersToNot'),
        this.translateService.instant('Shared.Close'),

        { duration: this.snackBarDuration }
      );
    } else {
      let companyData = {};
      companyData[company] = [partition];
      this.notifyService.notifyPartition(companyData, operation, title);
    }
  }

  // Agrupa en un array los números de cuenta de todas las particiones activas.
  async notifyPartitions() {
    const operation = 'Notify Partitions';

    const title = this.translateService.instant('Partition.NotifyActivePart');

    const arrayPartition = {};
    let atleastOnPartition = false;

    this.partitionsDataService.partitions.forEach((partition) => {
      if (partition?.active) {
        atleastOnPartition = true;
        let partitionObj = {
          mac: partition.mac,
          account: partition.account,
        };

        if (arrayPartition[partition.company]) {
          arrayPartition[partition.company].push(partitionObj);
        } else {
          arrayPartition[partition.company] = [partitionObj];
        }
      }
    });

    if (!atleastOnPartition) {
      this.snackBar.open(
        this.translateService.instant('Partition.NotActivePart'),
        this.translateService.instant('Shared.Close'),

        { duration: this.snackBarDuration }
      );
    } else {
      this.notifyService.notifyPartition(arrayPartition, operation, title);
    }
  }

  onCellPartitionClick = (id: string, event?) => {
    if (event.view.getSelection().type !== 'Range') {
      const dialogRef = this.dialog.open(PartitionInfoComponent, {
        width: '700px',
        data: { title: 'Partition.PartitionDetail', id },
      });

      dialogRef.afterClosed().subscribe((result) => {});
    }
  };

  updatePartition = (id: string) => {
    this.partitionsDataService.editingPartitionId = id;
    const dialogRef = this.dialog.open(PartitionFormComponent, {
      width: '65%',
      maxWidth: '1000px',
      data: { title: 'Partition.ModPartition', submitText: 'Partition.ModPartition', operation: 'update', id },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        if (result.status === 'success') {
          const updatedIndex = this.partitionsDataService.partitions.findIndex(
            (p) => p._id == this.partitionsDataService.editingPartitionId
          );
          this.partitionsDataService.partitions.splice(updatedIndex, updatedIndex >= 0 ? 1 : 0);
          this.getPartitions();
          this.partitionsDataService.editingPartitionId = undefined;
        }

        if (result.status !== 'cancel') {
          this.snackBar.open(result.message, this.translateService.instant('Shared.Close'), {
            duration: this.snackBarDuration,
          });
        }
      }
    });
  };

  deletePartition = async (id: string) => {
    this.partitionsDataService.editingPartitionId = id;
    this.openConfirmDialog(
      this.translateService.instant('Partition.AreYouSureDeletePartition'),
      this.translateService.instant('Partition.Delete'),

      (result: boolean) => {
        if (result) {
          this.partitionsDataService.isLoadingResults = true;

          this.abm.deletePartition(id).subscribe({
            next: async (res: any) => {
              this.handlePartitionResponse(res, 'Partition.PartitionDeleted', 'Partition.NotDeletePartition');
            },
            error: async (error) => {
              this.handlePartitionError(error, 'Partition.NotDeletePartition', 'Shared.SessionExpired');
            },
          });
        }
      }
    );
  };

  reloadPartitions = () => {
    if (!this.partitionsDataService.isLoadingResults) {
      this.getPartitions();
    }
  };

  getPartitions = async () => {
    this.partitionsDataService.isLoadingResults = true;
    this.partitionsDataService.requestPartitions(() => {
      this.abm.getPartitions().subscribe({
        next: (res: any) => {
          this.partitionsDataService.loadingBar.useRef().start();
        },
        error: async (error) => {
          if (error && error.status === 401) {
            this.snackBar.open(
              this.translateService.instant('Shared.SessionExpired'),
              this.translateService.instant('Shared.Close'),

              { duration: this.snackBarErrorDuration }
            );
            this.authService.logout();
          } else {
            this.snackBar.open(
              this.translateService.instant('Partition.NoGetPartition'),
              this.translateService.instant('Shared.Close'),

              { duration: this.snackBarErrorDuration }
            );
          }
        },
      });
    });
  };

  private handlePartitionResponse(res: any, successMessageKey: string, errorMessageKey: string) {
    if (res?.success) {
      const updatedIndex = this.partitionsDataService.partitions.findIndex(
        (p) => p._id == this.partitionsDataService.editingPartitionId
      );
      this.partitionsDataService.partitions.splice(updatedIndex, updatedIndex >= 0 ? 1 : 0);
      this.getPartitions();
      this.partitionsDataService.editingPartitionId = undefined;
      this.snackBar.open(
        this.translateService.instant(successMessageKey),
        this.translateService.instant('Shared.Close'),
        { duration: this.snackBarDuration }
      );
    } else {
      this.snackBar.open(
        res.reason || this.translateService.instant(errorMessageKey),
        this.translateService.instant('Shared.Close'),
        { duration: this.snackBarDuration }
      );
      if (this.forcedReloadCases.includes(res?.reasonKey)) {
        this.getPartitions();
      }
    }
    this.partitionsDataService.isLoadingResults = false;
  }

  private handlePartitionError(
    error: any,
    genericErrorKey: string,
    sessionExpiredKey: string,
    logoutOnUnauthorized: boolean = false
  ) {
    if (error && error.status === 401) {
      this.snackBar.open(
        this.translateService.instant(sessionExpiredKey),
        this.translateService.instant('Shared.Close'),
        { duration: this.snackBarDuration }
      );
      if (logoutOnUnauthorized) {
        this.authService.logout();
      }
    } else {
      this.snackBar.open(
        this.translateService.instant(genericErrorKey),
        this.translateService.instant('Shared.Close'),
        { duration: this.snackBarDuration }
      );
    }
    this.partitionsDataService.isLoadingResults = false;
  }

  activatePartition = async (id: string) => {
    this.partitionsDataService.editingPartitionId = id;
    this.openConfirmDialog(
      this.translateService.instant('Partition.ActivatePartition'),
      this.translateService.instant('Partition.Activate'),

      (result: boolean) => {
        if (result) {
          this.partitionsDataService.isLoadingResults = true;

          this.abm.activePartition(id, true).subscribe({
            next: async (res: any) => {
              this.handlePartitionResponse(res, 'Partition.PartitionActivated', 'Partition.ErrorActPartition');
            },
            error: async (error) => {
              this.handlePartitionError(error, 'Partition.ErrorActPartition', 'Shared.SessionExpired', true);
            },
          });
        }
      }
    );
  };

  desactivatePartition = async (id: string) => {
    this.partitionsDataService.editingPartitionId = id;
    this.openConfirmDialog(
      this.translateService.instant('Partition.DisablePartition'),
      this.translateService.instant('Partition.Deactivate'),

      (result: boolean) => {
        if (result) {
          this.partitionsDataService.isLoadingResults = true;

          this.abm.activePartition(id, false).subscribe({
            next: async (res: any) => {
              this.handlePartitionResponse(res, 'Partition.PartitionDeactivated', 'Partition.ErrorDisPartition');
            },
            error: async (error) => {
              this.handlePartitionError(error, 'Partition.ErrorDisPartition', 'Shared.SessionExpired', false);
            },
          });
        }
      }
    );
  };

  openConfirmDialog(promptText: string, submitText: string, callback: Function): void {
    const dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      maxWidth: '450px',
      autoFocus: false,
      data: { promptText, submitText },
    });

    dialogRef.afterClosed().subscribe((result) => callback(result));
  }

  openKeyboard = (account: string, mac: string, apiKey: string) => {
    this.keyboardService.setPartition(account, mac, apiKey);
    this.router.navigateByUrl('/keyboard');
  };

  addToTechSupport = (event: any, partition: Partition) => {
    partition.selectedToSupport = !partition.selectedToSupport;
    this.commandService.panelToTechSupport(partition.mac, partition.selectedToSupport);
  };
}
