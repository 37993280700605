import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ListenInfo, PanelSocketService } from 'src/app/shared/services/panelsocket.service';
import { ReceiversService } from 'src/app/shared/services/receivers.service';
import { ComandService } from './comand.service';
import { PanelsService } from 'src/app/shared/services/panels.service';
import { Message } from '../models/command';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConsoleMessagesService {
  listenSubscription: Subscription; // Suscripcion a Messages Processor
  intervalID: ReturnType<typeof setInterval>; // Clear console interval
  maxMessageBuffer = 500;
  repeatedMessages = {};

  constructor(
    private readonly socketMP: PanelSocketService,
    private readonly translateService: TranslateService,
    private readonly receiversService: ReceiversService,
    public commandService: ComandService,
    public panelsService: PanelsService
  ) {}

  init() {
    this.repeatedMessages = {};
    if (this.listenSubscription) return;

    this.listenSubscription = this.socketMP.listenEvent.subscribe((message) => {
      this.onMessage(message);
    });

    this.periodicCleanConsole();
  }

  private onMessage(message: ListenInfo) {
    const key = `${message?.receiver?.apiKey}-${message?.message?.hexRaw}`;
    if ((message?.receiver?.apiKey || '').length > 0 && (message?.message?.hexRaw || '').length > 0 && key in this.repeatedMessages) return;
    else this.repeatedMessages[key] = 'duplicated-timestamp';

    const macs = [message.device.mac];
    if (message.gateway) macs.push(message.gateway.device.mac);
    const msg: Message = {
      hexMsg: message.message.hexRaw,
      type: message.message.type,
      interface: message.connection.interface,
      status: message.decodification?.status || !message.decodification,
      reason: message.decodification ? message.decodification.reason : undefined,
      date: message.connection.date,
      msg: message.gateway ? message.gateway.message.hexRaw : undefined,
      account: message.device.accountNumber,
      macs,
      receiver: undefined,
      apiKey: undefined,
    };

    if (!message.connection.date) {
      msg.date = new Date().toLocaleString(this.translateService.instant('AppComponent.Locale'));
    }

    const receiver = this.receiversService.receivers.find((r) => r.apiKey === message.receiver.apiKey);
    if (receiver) {
      msg.receiver = receiver.name;
      msg.apiKey = receiver.apiKey;
    }

    this.commandService.addConsoleMessage(msg);
  }

  destroy() {
    if (this.listenSubscription) {
      this.listenSubscription.unsubscribe();
      this.listenSubscription = null;
    }
    clearInterval(this.intervalID);
    this.repeatedMessages = {};
  }

  periodicCleanConsole() {
    // Once each 10s clean old excedent messages in the console
    this.intervalID = setInterval(() => {
      if (this.commandService.messagesFiltered && this.commandService.messagesFiltered.length > this.maxMessageBuffer) {
        this.commandService.messagesFiltered = this.commandService.messagesFiltered.slice(-this.maxMessageBuffer);
        this.commandService.messageArray = this.commandService.messageArray.slice(-this.maxMessageBuffer);
      }
      this.repeatedMessages = {};
    }, 10_000);
  }
}
