
export const DataTypes = {
    Normal: 1,
    Update: 2,
    UpdateExtra: 3,
    NormalAndUpdateExtra: 4
};

export const CommandTypes = {
    City7: 1,
    DTrans: 2,
    PhoneLine: 3,
    CityPanel: 6,
    Amiar: 8,
    CityMesh: 9,
  };

  export const ParamTypes = {
    Number: 1,
    String: 2,
    Boolean: 3,
    Array: 4,
    Object: 5,
    Hexa: 6
  };

  export const queueStatus = {
    PENDING: 1,
    WAITING: 2,
    SENDING: 3,
    CONFIRMED: 4,
    CANCELED: 5,
    TIMEOUT: 6,
    ERROR: 7
  };

  export const commandListState = {
    STOP: 0,
    SENDING: 1,
    PAUSE: 2,
  }