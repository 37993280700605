import { Component, OnInit } from '@angular/core';
import { ReceiversService } from '../../services/receivers.service';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-receiver-status',
  templateUrl: './receiver-status.component.html',
  styleUrls: ['./receiver-status.component.scss'],
})
export class ReceiverStatusComponent implements OnInit {
  launchMonitoringStatusAtStartup: boolean;

  constructor(
    private readonly dialogRef: MatDialogRef<ReceiverStatusComponent>,
    public receiversService: ReceiversService,
    private readonly translateService: TranslateService,
    private readonly authService: AuthService
  ) {}

  ngOnInit() {
    this.launchMonitoringStatusAtStartup =
      localStorage.getItem('monitoring-at-startup') === `${this.authService.user._id}-dont-show`;
  }

  close() {
    this.dialogRef.close();
  }

  dontShowMonitoring(ev) {
    if (ev.target.checked)
      return localStorage.setItem('monitoring-at-startup', `${this.authService.user._id}-dont-show`);

    localStorage.setItem('monitoring-at-startup', `${this.authService.user._id}-show`);
  }

  toolTipContent() {
    return this.translateService.instant('Receiver.Explain');
  }
}
