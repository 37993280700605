<h1 mat-dialog-title>{{'Panel.PanelInstalation' | translate}}</h1>
<mat-dialog-content>
    <div class="container">
        <div class="proceso">
            {{"Panel.Progress" | translate}}
        </div>
        <div class="linea"></div>
    </div>
    <!-- PIN -->
    <div *ngIf="requestDisarmPin" class="stepConteiner" style=" height: 100px; overflow-y: hidden;">
        <!-- Contraseñas de Desarme -->
         <div style="padding-bottom: 10px;">
            {{"Panel.ConfigureKeyboardPIN" | translate}}
         </div>
        <div class="container align-button" fxLayout="row" fxLayoutGap="30px">
          <div class="field-container" fxFlex>
            <mat-form-field>
              <input matInput
              (input)="validateInput($event)"
              maxlength="4" name="DissarmPass" customEmail [(ngModel)]="newPin" placeholder='{{"Panel.Password" | translate}}'  
              matInput autocomplete="off" 
              />
            </mat-form-field>
          </div>
        </div>
    </div>
    <!-- Steps -->
    <div *ngIf="!requestDisarmPin" class="stepConteiner" style=" height: 100px;">
        <div *ngFor="let step of steps" style="display: flex;">
            <div style="margin-left: 30px; margin-right: 10px; margin-top: 5px;">
                <mat-icon *ngIf="step.state === states.FINISHED" class="material-symbols-outlined" style="color: green">
                    check_circle
                </mat-icon>
                <mat-icon *ngIf="step.state === states.FAILED" class="material-symbols-outlined" style="color: red">
                    cancel
                </mat-icon>
                <mat-icon *ngIf="step.state === states.READY" class="material-symbols-outlined">
                    radio_button_unchecked
                </mat-icon>
                <mat-icon *ngIf="step.state === states.ONPROCESS">
                    <mat-spinner color="primary" diameter="23"></mat-spinner>
                </mat-icon>
            </div>
            <div style="margin-top: 7px;">
                {{ ("Panel." + step.title) | translate}}
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button *ngIf="requestDisarmPin" mat-button (click)="confirmPin()">{{ 'Shared.Continue' | translate}}</button>
  <button *ngIf="!requestDisarmPin && (!finished || (currentStep < steps.length))" mat-button (click)="retry()">{{ 'Shared.Retry' | translate}}</button>
  <button *ngIf="!requestDisarmPin && finished && currentStep === steps.length" mat-button (click)="onNewPartitionClick()">{{ 'Shared.ConfigureAccount' | translate}}</button>
  <button mat-button (click)="onCancel()">{{'Shared.Cancel' | translate}}</button>
</mat-dialog-actions>
