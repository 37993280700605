<div class="title top">
    {{ 'MeshNet.NeighborProcessing' | translate }} {{ ': ' + panelStatus.neighborReloadState?.panel.mac }}
</div>
<div class="box">
    <figure>
        <div class="progress">
            <div class="progress-bar progress-bar-info progress-bar-striped progress-bar-success active success" style="width: 100%;"></div>
        </div>
        <figcaption class="title">{{ (('MeshNet.' + steps[panelStatus.neighborReloadState?.stage]) | translate) + continue }} </figcaption>
    </figure>
</div>