import { Injectable } from '@angular/core';
import { Panel } from 'src/app/panel/models/panel.model';
import { SendCommandsServiceService } from './send-commands-service.service';
import { commandListState } from '../models/constants';
import { DeviceComands, Message } from '../models/command';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PanelSocketService } from 'src/app/shared/services/panelsocket.service';
import { PanelService } from 'src/app/panel/services/panel.service';
import { PanelsService } from 'src/app/shared/services/panels.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ReceiversService } from 'src/app/shared/services/receivers.service';
import { WebappSubscriptionService } from 'src/app/shared/services/webapp-subscription.service';
import { TranslateService } from '@ngx-translate/core';
import constants from 'src/app/shared/constants';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ComandService {
  isLoadingResults: boolean = false;
  snackBarErrorDuration: number = 10000;
  snackBarDuration = 5000;
  unselectedPanels: Panel[] = [];
  selectedPanels: Panel[] = [];
  pendingPanels: string[] = [];

  commandProcessingList: DeviceComands[];

  selectedMac: string; // Define los comandos de que equipo se muestran (Resultado de Envios)

  filteredMacs: string[] = [];
  messagesFiltered: Message[] = [];
  messageArray: Message[] = [];
  filterHex: string = '';
  private filterMacSubject = new BehaviorSubject<string>('');
  filterMac$ = this.filterMacSubject.asObservable();
  filterAccountNumber: string = '';
  repeatedMessages = {};

  state: number;
  listIndex: number = 0; // Control de lista en ejecucion
  // Se utiliza para reconocer la lista en ejecucion

  // Habilita aviso en la barra de Enlaces de que se modifico el filtro de consola
  isFilterEdited: boolean = false;

  constructor(
    public sendCommandService: SendCommandsServiceService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private socketMP: PanelSocketService,
    private ps: PanelService,
    public authService: AuthService,
    private panelService: PanelsService,
    private receiversService: ReceiversService,
    private webppSocketSocket: WebappSubscriptionService
  ) {
    this.state = commandListState.STOP;
    this.webppSocketSocket.addSubscription(this.handler, constants.webappSocketTypes.panelsListPackage);
  }

  clearMessageArray() {
    this.messageArray = [];
  }

  get filterMac(): string {
    return this.filterMacSubject.value;
  }

  set filterMac(value: string) {
    this.filterMacSubject.next(value);
  }

  private handler = (message) => {
    if (message.finish) {
      this.panelService.panels = this.panelService.panels.map((panel) => {
        panel.selectedToSupport = !!this.selectedPanels.find((p) => p.mac === panel.mac);
        return panel;
      });
      this.matchPanels(this.panelService.panels);
      this.panelService.collectPanels();
    }
  };

  panelToTechSupport = (mac: string, selected: boolean) => {
    this.isFilterEdited = true;
    if (!this.selectedPanels) this.selectedPanels;
    if (selected && this.unselectedPanels.length === 0 && this.selectedPanels.length === 0) {
      this.pendingPanels.push(mac);
      this.panelService.getPanels();
    } else {
      this.onSelectPanel(mac, selected);
    }
  };

  addPaneltoFilter = (mac: string) => {
    if (!this.filteredMacs.includes(mac)) this.filteredMacs.push(mac);
    this.filter();
  };

  addAllPaneltoFilter = () => {
    for (let i = 0; i < this.selectedPanels.length; i++) {
      if (!this.filteredMacs.includes(this.selectedPanels[i].mac)) this.filteredMacs.push(this.selectedPanels[i].mac);
    }
    this.filter();
  };

  onSelectPanel = (mac: string, selected: boolean) => {
    // Solo se permite modificar el listado de mac si no se estan enviando comandos.
    if (this.state != commandListState.SENDING) {
      // Al editar el listado de macs, se detiene completamente el envio de comandos.
      if (this.state === commandListState.PAUSE) this.processCommandListStop();
      if (selected) {
        this.unselectedPanels = this.unselectedPanels.filter((d) => {
          if (d.mac !== mac) {
            return true;
          }
          this.selectedPanels.push(d);
          return false;
        });
      } else {
        this.selectedPanels = this.selectedPanels.filter((d) => {
          if (d.mac !== mac) {
            return true;
          }
          this.unselectedPanels.push(d);
          return false;
        });
      }
    }
  };

  onSelectAllPanel = (selected: boolean) => {
    // Solo se permite modificar el listado de mac si no se estan enviando comandos.
    if (this.state != commandListState.SENDING) {
      // Al editar el listado de macs, se detiene completamente el envio de comandos.
      if (this.state != commandListState.PAUSE) this.processCommandListStop();
      if (selected) {
        this.selectedPanels = [...this.selectedPanels, ...this.unselectedPanels];
        this.unselectedPanels = [];
      } else {
        this.unselectedPanels = [...this.selectedPanels, ...this.unselectedPanels];
        this.selectedPanels = [];
      }
    }
  };

  matchPanels = (panelList) => {
    if (this.selectedPanels.length > 0 || this.pendingPanels.length > 0) {
      this.unselectedPanels = [];
      for (let i = 0; i < panelList.length; i++) {
        if (this.pendingPanels.includes(panelList[i].mac)) {
          this.selectedPanels.push(panelList[i]);
        } else if (!this.selectedPanels.find((sp) => sp.mac === panelList[i].mac)) {
          this.unselectedPanels.push(panelList[i]);
        }
      }
      this.pendingPanels = [];
    } else {
      this.unselectedPanels = panelList;
    }
  };

  cleanCommandData = () => {
    this.unselectedPanels = [];
    this.selectedPanels = [];
    this.pendingPanels = [];
    this.sendCommandService.allCommands = [];
    this.sendCommandService.allAvailableCommands = [];
    this.sendCommandService.allAvailableCommandsClusteredBySourceType =
      this.sendCommandService.setupClusteredCommands();

    this.socketMP.emitMP('leaveAll');
  };

  processCommandListInit = (commandList) => {
    if (this.state === commandListState.STOP) {
      // Inicia de 0 el envio del listado de comandos.
      this.processCommandListStart(commandList);
    } else if (this.state === commandListState.PAUSE) {
      // Se reanudan los listados de comandos desde donde se detuvo.
      this.state = commandListState.SENDING;
      for (let i = 0; i < this.selectedPanels.length; i++) {
        let index = 0;
        const processList = this.commandProcessingList.find((l) => l.mac === this.selectedPanels[i].mac).commandList;
        // Se busca el ultimo comando sin enviar.
        while (processList.length > index && processList[index].result !== undefined) index++;
        this.controlMacCommands(commandList, this.selectedPanels[i], this.listIndex, index);
      }
    } else {
      // Se detiene temporalmente el envio de comandos.
      this.state = commandListState.PAUSE;
      for (let i = 0; i < this.commandProcessingList.length; i++) {
        const foundCommandProcessedInTheList = this.commandProcessingList[i].commandList.find(
          (c) => c.result === `${this.translateService.instant('Shared.Sending')}...`
        )
        if (foundCommandProcessedInTheList?.result) foundCommandProcessedInTheList.result = `${this.translateService.instant('Shared.Interrupting')}...`;
      }
    }
  };

  getProcessedCommandList = (commandList) => {
    const processedCommandList = [];
    for (let i = 0; i < commandList.length; i++) {
      processedCommandList.push({
        id: commandList[i].id,
        name: commandList[i].name,
        paramGet: commandList[i].get,
        paramSet: commandList[i].set,
        result: undefined,
      });
    }
    return processedCommandList;
  };

  processCommandListStart = (commandList) => {
    if (!this.selectedPanels || this.selectedPanels.length === 0) {
      return this.snackBar.open(
        this.translateService.instant('TechSupport.MissingAtLeastDevice'),
        this.translateService.instant('Shared.Close'),
        { duration: this.snackBarDuration }
      );
    }
    this.listIndex++;
    const controlIndex = this.listIndex;

    // Se arma listado de resultados
    this.commandProcessingList = [];
    this.state = commandListState.SENDING;
    for (let i = 0; i < this.selectedPanels.length; i++) {
      this.commandProcessingList.push({
        mac: this.selectedPanels[i].mac,
        apikey: this.selectedPanels[i].apiKey,
        commandList: this.getProcessedCommandList(commandList),
        done: false,
      });
    }

    // Si no hay mac seleccionada, o la misma ya no esta agregada, se muestra el listado de la mac inicial
    if (!this.selectedMac || !this.selectedPanels.find((m) => m.mac === this.selectedMac))
      this.selectedMac = this.selectedPanels[0].mac;

    // Se comienza el envio de comandos a los equipos listados
    for (let i = 0; i < this.selectedPanels.length; i++) {
      this.controlMacCommands(commandList, this.selectedPanels[i], controlIndex);
    }
  };

  processCommandListStop = () => {
    this.state = commandListState.STOP;
    if (this.commandProcessingList) {
      for (let i = 0; i < this.commandProcessingList.length; i++) {
        const com = this.commandProcessingList[i].commandList.find(
          (c) => c.result === `${this.translateService.instant('Shared.Sending')}...`
        );
        if (com) com.result = `${this.translateService.instant('Shared.Interrupting')}...`;
      }
    }
  };

  processCommandListRestart = (commandList) => {
    this.processCommandListStart(commandList);
  };

  controlMacCommands = (commandList, panel, controlIndex, i = 0) => {
    const command = this.commandProcessingList.find((m) => m.mac === panel.mac).commandList[i];
    command.result = `${this.translateService.instant('Shared.Sending')}...`;
    if (!panel.cState || i == 0) panel.cState = 'send';

    this.sendCommandService.sendCommand(panel.mac, commandList[i], this.listIndex).subscribe({
      next: (res: any) => {
        if (controlIndex === this.listIndex) {
          if (res && res.success) {
            command.result = res.result;
          } else if (res && res.reason === 'Timeout') {
            command.result = this.translateService.instant('Shared.Timeout');
            panel.cState = 'warning';
          } else {
            command.result = res.reason ?? 'Error';
            panel.cState = 'warning';
          }
          this.processConsoleResponseMsg(command.name + ': ' + command.result, panel.mac);
          this.processCommandList(commandList, panel, controlIndex, i);
        }
      },
      error: (error) => {
        // Timeout
        command.result = this.translateService.instant('Shared.Timeout');
        panel.cState = 'warning';
        this.processConsoleResponseMsg(command.name + ': ' + command.result, panel.mac);
        this.processCommandList(commandList, panel, controlIndex, i);
      },
    });
  };

  processConsoleResponseMsg = (msg, mac = '') => {
    const date = new Date().toLocaleString(this.translateService.instant('AppComponent.Locale'));
    this.addConsoleMessage({ date, msg, status: true, response: true, macs: [mac] });
  };

  processCommandList = (commandList, panel, controlIndex, i) => {
    i++;
    if (i < commandList.length) {
      if (this.state === commandListState.SENDING) this.controlMacCommands(commandList, panel, controlIndex, i);
    } else if (this.commandProcessingList) {
      // Si el listado de comandos finaliza para un equipo se marca como tal.
      const foundCommandProcessedInTheList = this.commandProcessingList.find((m) => m.mac === panel.mac);
      foundCommandProcessedInTheList['done'] = true;
      panel.cState = panel.cState === 'warning' ? panel.cState : 'check_circle';
      // Si todos los equipos finalizaron se marca el envio como detenido
      if (!this.commandProcessingList.find((m) => !m.done)) {
        this.state = commandListState.STOP;
        this.processConsoleResponseMsg(this.translateService.instant('TechSupport.QueueEnd'));
      }
    }
  };

  filter = () => {
    this.filterTags();
    this.filterAccountNumberMsg();
    this.filterMacMsg();
    this.filterHexMsg();
  };

  filterTags = () => {
    // Filtro de Macs seleccionadas desde listado de macs

    if (this.filteredMacs.length > 0) {
      this.messagesFiltered = this.messageArray.filter((m) =>
        this.filteredMacs.some(mac => m.macs.includes(mac))
      );
    } else {
      this.messagesFiltered = this.messageArray;
    }
  };

  filterMacMsg = () => {
    const filterList = this.getMacFilterList(this.filterMac.trim());

    if (filterList.length > 0) {
      // Se busca los mensajes que tenga una mac que coincida
      // O contenga el fragmento en el mensaje
      this.messagesFiltered = this.messagesFiltered.filter((m) =>
        m.macs.some((mc) => filterList.some((filter) => mc.includes(filter)))
      );
    }
  };

  filterAccountNumberMsg = () => {
    if (!this.filterAccountNumber) return;

    this.messagesFiltered = this.messagesFiltered.filter((m) => {
      return m.account === this.filterAccountNumber;
    });
  };

  filterHexMsg = () => {
    const filterList = this.getMacFilterList(this.filterHex);

    if (filterList.length > 0) {
      this.messagesFiltered = this.messagesFiltered.filter((m) =>
        m.hexMsg && filterList.some((filter) => m.hexMsg.replace(/ /g, '').includes(filter))
      );
    }
  };

  getMacFilterList = (list) => {
    // Se eliminan los caracteres separadores y sepasa todo a mayuscula
    let filters = list.toUpperCase();
    filters = filters.replace(/[^0-9a-fA-F,]/g, '');
    // Se toma como caracter separador de cadenas una coma
    let filterList = filters.split(',');
    return filterList.filter((f) => f !== '');
  };

  addConsoleMessage = (message) => {
    const key = `${message.apiKey}-${message.hexMsg}`;
    if (message.apiKey?.length > 0 && message.hexMsg?.length > 0 && key in this.repeatedMessages) return;
    else this.repeatedMessages[key] = 'duplicated-timestamp';

    // Se agrega al listado sin filtro de consola
    this.messageArray.push(message);

    // Controles de listado con filtro de consola.
    // Se verifica mac seleccionada
    let found = this.filteredMacs.some(mac => message.macs.includes(mac));

    if (!found && this.filteredMacs.length > 0) return;

    // Filtro de account
    if (this.filterAccountNumber && message.account != this.filterAccountNumber) return;

    // Filtro de mac
    let filterList = this.getMacFilterList(this.filterMac);

    found = message.macs.some((mc) => filterList.some((filter) => mc.includes(filter)));

    if (!found && this.filterMac.length > 0) return;

    // Filtro de contenido de mensaje
    filterList = this.getMacFilterList(this.filterHex);
    found = message.hexMsg && filterList.some((filter) => message.hexMsg.replace(/ /g, '').includes(filter));

    if (!found && this.filterHex.length > 0) return;

    // Si pasa todos los filtros se agrega al listado filtrado
    this.messagesFiltered.push(message);
  };

  cleanFilters = () => {
    this.filteredMacs = [];
    this.filterMac = '';
    this.filterAccountNumber = '';
    this.filterHex = '';
    this.filterAccountNumber = undefined;
    this.messagesFiltered = this.removeDuplicatesFromMesagesArray(this.messageArray);
  };

  removeDuplicatesFromMesagesArray = (messages) => {
    const repeatedMessages = {};
    const nonRepeatedMessages = [];

    for (const elementMessage of messages) {
      const key = `${elementMessage.apiKey}-${elementMessage.hexMsg}`;
      if (
        (elementMessage.apiKey || '').length > 0 &&
        (elementMessage.hexMsg || '').length > 0 &&
        key in repeatedMessages
      )
        return;
      else repeatedMessages[key] = 'duplicated-timestamp';

      nonRepeatedMessages.push(elementMessage);
    }

    return nonRepeatedMessages;
  };

  getPreviewMac = () => {
    // Returns a mac for preview message
    let alternativeSelectedFirstPanel = this.selectedPanels?.[0];
    const alternativeSelectedMac = alternativeSelectedFirstPanel
      ? alternativeSelectedFirstPanel?.mac
      : alternativeSelectedFirstPanel;

    return this.selectedMac ?? alternativeSelectedMac ?? '7AAAAAAA';
  };
}
