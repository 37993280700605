import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReceiverStatusComponent } from './components/receiver-status/receiver-status.component';
import { MatCardModule } from '@angular/material/card';
import { AppMaterialModule } from '../app-material/app-material.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { HelpListComponent } from './components/help-list/help-list.component';
import { TooltipDeitresModule } from '../common/ui/tooltip/tooltipDeitres.module';
import { BetaWarningComponent } from './components/beta-warning/beta-warning.component';

@NgModule({
  declarations: [ReceiverStatusComponent, HelpListComponent, BetaWarningComponent],
  imports: [
    CommonModule,
    AppMaterialModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule,
    TooltipDeitresModule,
  ],
  providers: [],
})
export class SharedModule {}
