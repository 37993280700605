<div leftmargin="0" topmargin="0" marginwidth="0" marginheight="0">
    <table id="Tabla_01" width="1001" height="1071" border="0" cellpadding="0" cellspacing="0" align="center">
        <tr>
            <td colspan="23">
                <img src="assets/img/paradox/index_01.png" width="1000" height="170" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="1" height="170" alt=""></td>
        </tr>
        <tr>
            <td rowspan="15">
                <img src="assets/img/paradox/index_02.png" width="134" height="725" alt=""></td>
            <td colspan="2" rowspan="4">
                <img src="assets/img/paradox/index_03.png" width="102" height="146" alt=""></td>
            <td>
                <img [src]="this.stateLedStatus[0]?.on ? 'assets/img/paradox/leds-system/led-rojo_04.jpg' : 'assets/img/paradox/index_04.jpg'" width="36" height="37" alt=""></td>
            <td rowspan="4">
                <img src="assets/img/paradox/index_05.png" width="37" height="146" alt=""></td>
            <td colspan="2">
                <img [src]="this.zoneLedStatus[0]?.on ? 'assets/img/paradox/led-zone/led-rojo_06.jpg' : 'assets/img/paradox/index_06.jpg'" width="48" height="37" alt=""></td>
            <td>
                <img [src]="this.zoneLedStatus[1]?.on ? 'assets/img/paradox/led-zone/led-rojo_07.jpg' : 'assets/img/paradox/index_07.jpg'" width="48" height="37" alt=""></td>
            <td colspan="3">
                <img [src]="this.zoneLedStatus[2]?.on ? 'assets/img/paradox/led-zone/led-rojo_08.jpg' : 'assets/img/paradox/index_08.jpg'" width="48" height="37" alt=""></td>
            <td colspan="2">
                <img [src]="this.zoneLedStatus[3]?.on ? 'assets/img/paradox/led-zone/led-rojo_09.jpg' : 'assets/img/paradox/index_09.jpg'" width="48" height="37" alt=""></td>
            <td>
                <img [src]="this.zoneLedStatus[4]?.on ? 'assets/img/paradox/led-zone/led-rojo_10.jpg' : 'assets/img/paradox/index_10.jpg'" width="47" height="37" alt=""></td>
            <td colspan="3">
                <img [src]="this.zoneLedStatus[5]?.on ? 'assets/img/paradox/led-zone/led-rojo_11.jpg' : 'assets/img/paradox/index_11.jpg'" width="48" height="37" alt=""></td>
            <td>
                <img [src]="this.zoneLedStatus[6]?.on ? 'assets/img/paradox/led-zone/led-rojo_12.jpg' : 'assets/img/paradox/index_12.jpg'" width="48" height="37" alt=""></td>
            <td colspan="2">
                <img [src]="this.zoneLedStatus[7]?.on ? 'assets/img/paradox/led-zone/led-rojo_13.jpg' : 'assets/img/paradox/index_13.jpg'" width="48" height="37" alt=""></td>
            <td rowspan="4">
                <img src="assets/img/paradox/index_14.png" width="37" height="146" alt=""></td>
            <td>
                <img [src]="this.stateLedStatus[4]?.on ? 'assets/img/paradox/leds-system/led-rojo_15.jpg' : 'assets/img/paradox/index_15.jpg'" width="31" height="37" alt=""></td>
            <td rowspan="15">
                <img src="assets/img/paradox/index_16.png" width="240" height="725" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="1" height="37" alt=""></td>
        </tr>
        <tr>
            <td>
                <img [src]="this.stateLedStatus[1]?.on ? 'assets/img/paradox/leds-system/led-naranja_17.jpg' : 'assets/img/paradox/index_17.jpg'" width="36" height="36" alt=""></td>
            <td colspan="2">
                <img [src]="this.zoneLedStatus[8]?.on ? 'assets/img/paradox/led-zone/led-rojo_18.jpg' : 'assets/img/paradox/index_18.jpg'" width="48" height="36" alt=""></td>
            <td>
                <img [src]="this.zoneLedStatus[9]?.on ? 'assets/img/paradox/led-zone/led-rojo_19.jpg' : 'assets/img/paradox/index_19.jpg'" width="48" height="36" alt=""></td>
            <td colspan="3">
                <img [src]="this.zoneLedStatus[10]?.on ? 'assets/img/paradox/led-zone/led-rojo_20.jpg' : 'assets/img/paradox/index_20.jpg'" width="48" height="36" alt=""></td>
            <td colspan="2">
                <img [src]="this.zoneLedStatus[11]?.on ? 'assets/img/paradox/led-zone/led-rojo_21.jpg' : 'assets/img/paradox/index_21.jpg'" width="48" height="36" alt=""></td>
            <td>
                <img [src]="this.zoneLedStatus[12]?.on ? 'assets/img/paradox/led-zone/led-rojo_22.jpg' : 'assets/img/paradox/index_22.jpg'" width="47" height="36" alt=""></td>
            <td colspan="3">
                <img [src]="this.zoneLedStatus[13]?.on ? 'assets/img/paradox/led-zone/led-rojo_23.jpg' : 'assets/img/paradox/index_23.jpg'" width="48" height="36" alt=""></td>
            <td>
                <img [src]="this.zoneLedStatus[14]?.on ? 'assets/img/paradox/led-zone/led-rojo_24.jpg' : 'assets/img/paradox/index_24.jpg'" width="48" height="36" alt=""></td>
            <td colspan="2">
                <img [src]="this.zoneLedStatus[15]?.on ? 'assets/img/paradox/led-zone/led-rojo_25.jpg' : 'assets/img/paradox/index_25.jpg'" width="48" height="36" alt=""></td>
            <td>
                <img [src]="this.stateLedStatus[5]?.on ? 'assets/img/paradox/leds-system/led-naranja_26.jpg' : 'assets/img/paradox/index_26.jpg'" width="31" height="36" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="1" height="36" alt=""></td>
        </tr>
        <tr>
            <td>
                <img [src]="this.stateLedStatus[2]?.on ? 'assets/img/paradox/leds-system/led-verde_27.jpg' : 'assets/img/paradox/index_27.jpg'" width="36" height="37" alt=""></td>
            <td colspan="2">
                <img [src]="this.zoneLedStatus[16]?.on ? 'assets/img/paradox/led-zone/led-rojo_28.jpg' : 'assets/img/paradox/index_28.jpg'" width="48" height="37" alt=""></td>
            <td>
                <img [src]="this.zoneLedStatus[17]?.on ? 'assets/img/paradox/led-zone/led-rojo_29.jpg' : 'assets/img/paradox/index_29.jpg'" width="48" height="37" alt=""></td>
            <td colspan="3">
                <img [src]="this.zoneLedStatus[18]?.on ? 'assets/img/paradox/led-zone/led-rojo_30.jpg' : 'assets/img/paradox/index_30.jpg'" width="48" height="37" alt=""></td>
            <td colspan="2">
                <img [src]="this.zoneLedStatus[19]?.on ? 'assets/img/paradox/led-zone/led-rojo_31.jpg' : 'assets/img/paradox/index_31.jpg'" width="48" height="37" alt=""></td>
            <td>
                <img [src]="this.zoneLedStatus[20]?.on ? 'assets/img/paradox/led-zone/led-rojo_32.jpg' : 'assets/img/paradox/index_32.jpg'" width="47" height="37" alt=""></td>
            <td colspan="3">
                <img [src]="this.zoneLedStatus[21]?.on ? 'assets/img/paradox/led-zone/led-rojo_33.jpg' : 'assets/img/paradox/index_33.jpg'" width="48" height="37" alt=""></td>
            <td>
                <img [src]="this.zoneLedStatus[22]?.on ? 'assets/img/paradox/led-zone/led-rojo_34.jpg' : 'assets/img/paradox/index_34.jpg'" width="48" height="37" alt=""></td>
            <td colspan="2">
                <img [src]="this.zoneLedStatus[23]?.on ? 'assets/img/paradox/led-zone/led-rojo_35.jpg' : 'assets/img/paradox/index_35.jpg'" width="48" height="37" alt=""></td>
            <td>
                <img [src]="this.stateLedStatus[6]?.on ? 'assets/img/paradox/leds-system/led-verde_36.jpg' : 'assets/img/paradox/index_36.jpg'" width="31" height="37" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="1" height="37" alt=""></td>
        </tr>
        <tr>
            <td>
                <img [src]="this.stateLedStatus[3]?.on ? 'assets/img/paradox/leds-system/led-rojo_37.jpg' : 'assets/img/paradox/index_37.jpg'" width="36" height="36" alt=""></td>
            <td colspan="2">
                <img [src]="this.zoneLedStatus[24]?.on ? 'assets/img/paradox/led-zone/led-rojo_38.jpg' : 'assets/img/paradox/index_38.jpg'" width="48" height="36" alt=""></td>
            <td>
                <img [src]="this.zoneLedStatus[25]?.on ? 'assets/img/paradox/led-zone/led-rojo_39.jpg' : 'assets/img/paradox/index_39.jpg'" width="48" height="36" alt=""></td>
            <td colspan="3">
                <img [src]="this.zoneLedStatus[26]?.on ? 'assets/img/paradox/led-zone/led-rojo_40.jpg' : 'assets/img/paradox/index_40.jpg'" width="48" height="36" alt=""></td>
            <td colspan="2">
                <img [src]="this.zoneLedStatus[27]?.on ? 'assets/img/paradox/led-zone/led-rojo_41.jpg' : 'assets/img/paradox/index_41.jpg'" width="48" height="36" alt=""></td>
            <td>
                <img [src]="this.zoneLedStatus[28]?.on ? 'assets/img/paradox/led-zone/led-rojo_42.jpg' : 'assets/img/paradox/index_42.jpg'" width="47" height="36" alt=""></td>
            <td colspan="3">
                <img [src]="this.zoneLedStatus[29]?.on ? 'assets/img/paradox/led-zone/led-rojo_43.jpg' : 'assets/img/paradox/index_43.jpg'" width="48" height="36" alt=""></td>
            <td>
                <img [src]="this.zoneLedStatus[30]?.on ? 'assets/img/paradox/led-zone/led-rojo_44.jpg' : 'assets/img/paradox/index_44.jpg'" width="48" height="36" alt=""></td>
            <td colspan="2">
                <img [src]="this.zoneLedStatus[31]?.on ? 'assets/img/paradox/led-zone/led-rojo_45.jpg' : 'assets/img/paradox/index_45.jpg'" width="48" height="36" alt=""></td>
            <td>
                <img [src]="this.stateLedStatus[7]?.on ? 'assets/img/paradox/leds-system/led-rojo_37.jpg' : 'assets/img/paradox/index_37.jpg'" width="31" height="36" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="1" height="36" alt=""></td>
        </tr>
        <tr>
            <td colspan="21">
                <img src="assets/img/paradox/index_47.jpg" width="626" height="95" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="1" height="95" alt=""></td>
        </tr>
        <tr>
            <td>
                <img src="assets/img/paradox/index_48.png" width="75" height="24" alt=""></td>
            <td colspan="4" rowspan="5">
                <img src="assets/img/paradox/index_49.jpg" width="113" height="146" alt=""></td>
            <td colspan="3" rowspan="3">
                <img src="assets/img/paradox/index_50.jpg" 
                style="cursor: 'pointer'" 
                (mousedown)="onMouseDown(constants.paradox.ON, constants.paradox.ONHOLD)"
                (mouseup)="onMouseUp()"
                (mouseleave)="onMouseUp()"
                width="89" height="79" alt=""
                ></td>
            <td colspan="3" rowspan="3">
                <img [src]="this.stateLedStatus[8]?.on ? ('assets/img/paradox/leds-keyboard/boton-rojo_51_'+keyboardService.lang+'.jpg') : ('assets/img/paradox/index_51_'+keyboardService.lang+'.jpg')" style="cursor: 'pointer'" (click)="keyboardService.writeKey(constants.paradox.TSL)" width="89" height="79" alt=""></td>
            <td colspan="4" rowspan="3">
                <img [src]="this.stateLedStatus[9]?.on ? ('assets/img/paradox/leds-keyboard/boton-rojo_52_'+keyboardService.lang+'.jpg') : ('assets/img/paradox/index_52_'+keyboardService.lang+'.jpg')" style="cursor: 'pointer'" (click)="keyboardService.writeKey(constants.paradox.MEM)" width="88" height="79" alt=""></td>
            <td colspan="3" rowspan="3">
                <img [src]="this.stateLedStatus[10]?.on ? ('assets/img/paradox/leds-keyboard/boton-rojo_53_'+keyboardService.lang+'.jpg') : ('assets/img/paradox/index_53_'+keyboardService.lang+'.jpg')" 
                style="cursor: 'pointer'" 
                (mousedown)="onMouseDown(constants.paradox.BYP, constants.paradox.BYPHOLD)"
                (mouseup)="onMouseUp()"
                (mouseleave)="onMouseUp()"
                width="89" height="79" alt=""
                ></td>
            <td colspan="3" rowspan="10">
                <img src="assets/img/paradox/index_54.png" width="83" height="484" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="1" height="24" alt=""></td>
        </tr>
        <tr>
            <td>
                <img [src]="this.stateLedStatus[11]?.on ? 'assets/img/paradox/leds-system/izq-on_55.jpg' : 'assets/img/paradox/index_55.jpg'" width="75" height="35" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="1" height="35" alt=""></td>
        </tr>
        <tr>
            <td rowspan="2">
                <img src="assets/img/paradox/index_56.png" width="75" height="52" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="1" height="20" alt=""></td>
        </tr>
        <tr>
            <td colspan="3" rowspan="3">
                <img [src]="'assets/img/paradox/index_57_'+keyboardService.lang+'.jpg'" 
                    width="89" height="79" alt=""
                    style="cursor: 'pointer'" 
                    (mousedown)="onMouseDown(constants.paradox.OFF, constants.paradox.OFFHOLD)"
                    (mouseup)="onMouseUp()"
                    (mouseleave)="onMouseUp()"
                ></td>
            <td colspan="3" rowspan="3">
                <img [src]="'assets/img/paradox/index_58_'+keyboardService.lang+'.jpg'" 
                    width="89" height="79" alt=""
                    style="cursor: 'pointer'" 
                    (mousedown)="onMouseDown(constants.paradox.STAY, constants.paradox.STAYHOLD)"
                    (mouseup)="onMouseUp()"
                    (mouseleave)="onMouseUp()"
                ></td>
            <td colspan="4" rowspan="3">
                <img [src]="'assets/img/paradox/index_59_'+keyboardService.lang+'.jpg'" 
                    width="88" height="79" alt=""
                    style="cursor: 'pointer'" 
                    (mousedown)="onMouseDown(constants.paradox.SLEEP, constants.paradox.SLEEPHOLD)"
                    (mouseup)="onMouseUp()"
                    (mouseleave)="onMouseUp()"
                ></td>
            <td colspan="3" rowspan="3">
                <img [src]="'assets/img/paradox/index_60_'+keyboardService.lang+'.jpg'" 
                    width="89" height="79" alt=""
                    style="cursor: 'pointer'" 
                    (mousedown)="onMouseDown(constants.paradox.ARM, constants.paradox.ARMHOLD)"
                    (mouseup)="onMouseUp()"
                    (mouseleave)="onMouseUp()"
                ></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="1" height="32" alt=""></td>
        </tr>
        <tr>
            <td>
                <img [src]="this.stateLedStatus[12]?.on ? 'assets/img/paradox/leds-system/izq-on_61.jpg' : 'assets/img/paradox/index_61.jpg'" width="75" height="35" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="1" height="35" alt=""></td>
        </tr>
        <tr>
            <td colspan="5" rowspan="5">
                <img src="assets/img/paradox/index_62.jpg" width="188" height="338" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="1" height="12" alt=""></td>
        </tr>
        <tr>
            <td colspan="4">
                <img src="assets/img/paradox/index_63.jpg" style="cursor: 'pointer'" (click)="keyboardService.writeKey('1')" width="118" height="82" alt=""></td>
            <td colspan="5">
                <img src="assets/img/paradox/index_64.jpg" style="cursor: 'pointer'" (click)="keyboardService.writeKey('2')" width="119" height="82" alt=""></td>
            <td colspan="4">
                <img src="assets/img/paradox/index_65.jpg" style="cursor: 'pointer'" (click)="keyboardService.writeKey('3')" width="118" height="82" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="1" height="82" alt=""></td>
        </tr>
        <tr>
            <td colspan="4">
                <img src="assets/img/paradox/index_66.jpg" style="cursor: 'pointer'" (click)="keyboardService.writeKey('4')" width="118" height="81" alt=""></td>
            <td colspan="5">
                <img src="assets/img/paradox/index_67.jpg" style="cursor: 'pointer'" (click)="keyboardService.writeKey('5')" width="119" height="81" alt=""></td>
            <td colspan="4">
                <img src="assets/img/paradox/index_68.jpg" style="cursor: 'pointer'" (click)="keyboardService.writeKey('6')" width="118" height="81" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="1" height="81" alt=""></td>
        </tr>
        <tr>
            <td colspan="4">
                <img src="assets/img/paradox/index_69.jpg" style="cursor: 'pointer'" (click)="keyboardService.writeKey('7')" width="118" height="82" alt=""></td>
            <td colspan="5">
                <img src="assets/img/paradox/index_70.jpg" style="cursor: 'pointer'" (click)="keyboardService.writeKey('8')" width="119" height="82" alt=""></td>
            <td colspan="4">
                <img src="assets/img/paradox/index_71.jpg" style="cursor: 'pointer'" (click)="keyboardService.writeKey('9')" width="118" height="82" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="1" height="82" alt=""></td>
        </tr>
        <tr>
            <td colspan="4">
                <img [src]="'assets/img/paradox/index_72_'+keyboardService.lang+'.jpg'" style="cursor: 'pointer'" (click)="keyboardService.writeKey(constants.paradox.DELETE)" width="118" height="81" alt=""></td>
            <td colspan="5">
                <img src="assets/img/paradox/index_73.jpg" style="cursor: 'pointer'" (click)="keyboardService.writeKey('0')" width="119" height="81" alt=""></td>
            <td colspan="4">
                <img [src]="'assets/img/paradox/index_74_'+keyboardService.lang+'.jpg'" style="cursor: 'pointer'" (click)="keyboardService.writeKey(constants.paradox.ENTER)" width="118" height="81" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="1" height="81" alt=""></td>
        </tr>
        <tr>
            <td colspan="23">
                <img src="assets/img/paradox/index_75.png" width="1000" height="175" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="1" height="175" alt=""></td>
        </tr>
        <tr>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="134" height="1" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="75" height="1" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="27" height="1" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="36" height="1" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="37" height="1" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="13" height="1" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="35" height="1" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="48" height="1" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="6" height="1" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="29" height="1" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="13" height="1" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="47" height="1" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="1" height="1" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="47" height="1" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="11" height="1" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="29" height="1" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="8" height="1" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="48" height="1" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="33" height="1" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="15" height="1" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="37" height="1" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="31" height="1" alt=""></td>
            <td>
                <img src="assets/img/paradox/espacio.gif" width="240" height="1" alt=""></td>
            <td></td>
        </tr>
    </table>
</div>
<div class="background-dark"></div>
<div class="emergency-button">
    <button mat-mini-fab class="keyboard-btn" (click)="keyboardService.writeKey(constants.paradox.MEDIC)"></button>
    <button mat-mini-fab class="keyboard-btn" style="margin-left: 44px;" (click)="keyboardService.writeKey(constants.paradox.PANIC)"></button>
    <button mat-mini-fab class="keyboard-btn" style="margin-left: 43px;" (click)="keyboardService.writeKey(constants.paradox.FIRE)"></button>
</div>