<div
  class="breadcrumb-container"
  *ngIf="hoverMenuesService.helpMenuStatus"
  [style.left.px]="calcOffset()"
  [style.top]="this.heightReference + 'px'"
>
  <div class="breadcrumb">
    <div class="breadcrumb-item row">
      <button class="clean-button-style" (click)="onOpenManual()">
        <div class="icon"><img alt src="assets/img/draft.svg" /></div>
        <div class="font-style-user-manual">{{ 'AppComponent.UserManual' | translate }}</div>
      </button>
    </div>
  </div>
</div>
