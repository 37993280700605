import { Component, Inject, Input, OnInit } from '@angular/core';
import { Partition } from '../models/partition';
import { PartitionsService } from '../services/partitions.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';

enum SoundTypes {
  Silent='Silent',
  Trigger='Trigger'
}

type IMessage = {
  success: boolean
  reason: string
}

@Component({
  selector: 'app-partition-alarm-trigger',
  templateUrl: './partition-alarm-trigger.component.html',
  styleUrls: ['./partition-alarm-trigger.component.scss'],
})
export class PartitionAlarmTriggerComponent implements OnInit {
  target: Element;
  isHeld: Boolean;
  activeHoldTimeoutId: any;
  activeInterval: any;
  @Input()
  partition: Partition;
  disabled = false;
  snackBarErrorDuration = 2000;
  document: Document;
  progressSpinner = 0;
  progressSpinnerInterval: any;
  type_of_action = 'unpressed';
  wooble = false;
  isLoadingResultsValue: boolean;
  @Input() set isLoadingResults(value: boolean) {
    this.isLoadingResultsValue = value;
    if (this.isLoadingResultsValue != value) {
      this.reset();
    }
  }
  get isLoadingResults(): boolean {
    return this.isLoadingResultsValue;
  }

  constructor(
    private abm: PartitionsService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    @Inject(DOCUMENT) document: Document
  ) {
    this.target = null;
    this.isHeld = false;
    this.activeHoldTimeoutId = null;
    this.document = document;
  }

  ngAfterViewInit() {
    const element = this.document.getElementById(`alarm-trigger-${this.partition._id}`);

    if (element) {
      ['mousedown', 'touchstart'].forEach((type) => {
        element.addEventListener(type, this._onHoldStart.bind(this));
      });

      ['mouseup', 'touchend', 'touchcancel'].forEach((type) => {
        element.addEventListener(type, this._onHoldStop.bind(this));
      });
    }
  }

  ngOnInit(): void {
    this.type_of_action = 'unpressed';
    this.disabled = false;
    this.wooble = false;
    this.isHeld = false;
    this.progressSpinner = 0;
    clearInterval(this.activeHoldTimeoutId);
    clearInterval(this.progressSpinnerInterval);
  }

  reset() {
    this.type_of_action = 'unpressed';
    this.disabled = false;
    this.wooble = false;
    this.isHeld = false;
    this.progressSpinner = 0;
    clearInterval(this.activeHoldTimeoutId);
    clearInterval(this.progressSpinnerInterval);
  }

  increment = () => {
    if (this.progressSpinner < 75) {
      this.progressSpinner = this.progressSpinner + 25;
    }
  };

  animateProgressBar = () => {
    this.progressSpinner = 0;
    this.progressSpinnerInterval = setInterval(this.increment, 600);
  };

  _onHoldStart = () => {
    this.isHeld = true;
    this.type_of_action = 'unknown';
    this.wooble = false;

    this.animateProgressBar();

    this.activeHoldTimeoutId = setInterval(() => {
      if (this.isHeld) {
        this.type_of_action = 'hold_press';
        this.progressSpinner = 100;
        this.chooseAction();
      }
    }, 3000);
  };

  _onHoldStop() {
    this.isHeld = false;

    clearInterval(this.activeHoldTimeoutId);
    clearInterval(this.progressSpinnerInterval);

    if (!this.isHeld) {
      // unpressed the button before 100%
      if (this.progressSpinner < 75) {
        this.type_of_action = 'standard_click';
        this.chooseAction();
      }
    }
  }

  chooseAction = () => {
    this.disabled = true;

    switch (this.type_of_action) {
      case 'standard_click':
        this.toggleAlarm(SoundTypes.Silent);
        break;
      case 'hold_press':
        this.toggleAlarm(SoundTypes.Trigger);
        break;
      default:
        break;
    }

    this.disabled = false;
  };

  toggleAlarm = (sound: String) => {
    const messageKey = `Partition.SendCommand.${sound}`;
    let message = `${this.translateService.instant(messageKey)} (MAC: ${this.partition.mac})`;

    this.abm
      .alarmTrigger(this.partition, sound)
      .toPromise()
      .then((res: IMessage) => {
        if (!res.success && res.reason == 'FailedSendCommandManager') {
          this.wooble = false;

          this.snackBar.open(
            this.translateService.instant('AppComponent.FailedSendCommandManager') + ` (MAC: ${this.partition.mac}).`,
            this.translateService.instant('Shared.Close'),
            {
              duration: this.snackBarErrorDuration,
            }
          );
        }

        if (res.success) {
          this.wooble = true;

          this.snackBar.open(message, this.translateService.instant('Shared.Close'), {
            duration: this.snackBarErrorDuration,
          });
        }
      })
      .catch((error) => {
        this.wooble = false;

        this.snackBar.open(
          this.translateService.instant('AppComponent.FailedSendCommandManager') + ` (MAC: ${this.partition.mac}).`,
          this.translateService.instant('Shared.Close'),
          {
            duration: this.snackBarErrorDuration,
          }
        );
      });
  };
}
