import { Component, OnInit } from '@angular/core';
import { PanelStatusService } from '../../service/panel-status.service';

@Component({
  selector: 'app-neighbor-request',
  templateUrl: './neighbor-request.component.html',
  styleUrls: ['./neighbor-request.component.scss']
})
export class NeighborRequestComponent implements OnInit {

  constructor(public panelStatus: PanelStatusService) { }

  ngOnInit(): void {
  }

}
