import { DistributorModel } from './distributor-model';

export class CompanyModel {
  _id: string;
  name: string;
  language: string;
  colorPalette: {
    primary: string; // hex color code,
    secondary: string; // hex color code
  };
  logo: string; // Base64 encoded image
  maxSecureAccessTime: number;
  minSecureAccessTime: number;
  defaultSecureAccessTime: number;
  notificationSubs: string[];
  eventsLoggingConf: {
    alarmTrigger: { type: boolean };
    panic: { type: boolean };
    medic: { type: boolean };
    fire: { type: boolean };
    armed: { type: boolean };
    disarmed: { type: boolean };
    userArmed: { type: boolean };
    userDisarmed: { type: boolean };
    groupArmed: { type: boolean };
    groupDisarmed: { type: boolean };
    automaticArmed: { type: boolean };
    automaticDisarmed: { type: boolean };
    quickArmed: { type: boolean };
    quickDisarmed: { type: boolean };
    remoteArmed: { type: boolean };
    remoteDisarmed: { type: boolean };
    keyswitchArmed: { type: boolean };
    keyswitchDisarmed: { type: boolean };
    energyProblem: { type: boolean };
    lowBattery: { type: boolean };
    comunicationProblem: { type: boolean };
    systemProblem: { type: boolean };
    tamper: { type: boolean };
    hs24aux: { type: boolean };
    bypassedZone: { type: boolean };
    panelTest: { type: boolean };
    panelTestError: { type: boolean };
    sensorFailure: { type: boolean };
    expanderModule: { type: boolean };
    silentTheft: { type: boolean };
    generalAlarm: { type: boolean };
    highTemperature: { type: boolean };
    lowTemperature: { type: boolean };
    systemShutdown: { type: boolean };
    armedStay: { type: boolean };
    resetPanicAlarm: { type: boolean };
    zoneTamper: { type: boolean };
    zoneLowBattery: { type: boolean };
    zoneEnergyProblem: { type: boolean };
    sirenFailure: { type: boolean };
    trouble: { type: boolean };
  };
  location: {
    latitude: number;
    longitude: number;
  };
  distributor: DistributorModel;
}
