import { Component, OnInit } from '@angular/core';
import { CompanyType, ContextualMenuType } from 'src/app/settings/constants';
import { CompaniesService } from 'src/app/settings/services/companies.service';
import { ContextualMenuService } from 'src/app/settings/services/contextual-menu.service';
import { getPhoneMask } from 'src/app/shared/utils/phone-mask.util';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { 
  updateValue, 
  updateLocation,
  validateEmail, 
  validateUserCode, 
  validateWebUrl, 
  validatorString,
  checkCoords, 
  checkEmailFormat
} from 'src/app/settings/validators';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-company-edit-info',
  templateUrl: './company-edit-info.component.html',
  styleUrls: ['./company-edit-info.component.scss']
})
export class CompanyEditInfoComponent implements OnInit {
  editInfoForm: FormGroup;
  submited: boolean;
  
  contextualMenuType = ContextualMenuType;
  location: string = '';
  notificationEmail: string = '';
  expand: boolean = false;

  contextualMenuWidth = '290px';
  
  textControl = {
    name: /[^a-zA-Z0-9 ]/g,
    receiverName: /[^a-zA-Z0-9 ]/g,
    EZVIZAccountName: /[^a-zA-Z0-9]/g,
    linkName: /[^a-zA-Z0-9.]/g,
    apiKey:  /[^a-zA-Z0-9]/g,
  }

  mask: string = '(+00) 0000000000000';

  invalidParams = {};

  constructor(
    private readonly formBuilder: FormBuilder,
    public companiesService: CompaniesService,
    public contextualMenuService: ContextualMenuService,
    private readonly authService: AuthService
  ) { 
  }

  ngOnInit(): void {
    this.initializeCompany();
    this.initializeForm()
  }

  private initializeCompany(): void{
    if (!this.companiesService.currentCompany) {
      this.companiesService.currentCompany = new CompanyType();
    }

    if (this.companiesService.currentCompany.whatsappNumber) this.mask = getPhoneMask(this.companiesService.currentCompany.whatsappNumber);
    if (!this.companiesService.currentCompany.language) this.companiesService.currentCompany.language = this.authService.selectedLanguage;
    if (this.companiesService.currentCompany.location?.lat && this.companiesService.currentCompany.location?.lng) {
      this.location = this.companiesService.currentCompany.location.lat + ', ' + this.companiesService.currentCompany.location.lng;
    }
  }

  private initializeForm(): void{
    this.notificationEmail = this.companiesService.currentCompany.notificationEmail?.join(',') ?? '';
    this.editInfoForm = this.formBuilder.group({
      name: ['', [Validators.required, (event) => validatorString('name', event, this.editInfoForm, this.companiesService.currentCompany)]],
      EZVIZAccountName: ['', [(event) => validatorString('EZVIZAccountName', event, this.editInfoForm, this.companiesService.currentCompany, 4)]],
      email: ['', [Validators.required, (event) => validateEmail('email', event, this.editInfoForm, this.companiesService.currentCompany)]],

      contact: ['', [(event) => validateEmail('contact', event, this.editInfoForm, this.companiesService.currentCompany)]],
      notificationEmail: ['', [(event) => this.validateNotifEmail('notificationEmail', event)]],
      techUserCode: ['', [(event) => validateUserCode('techUserCode', event, this.editInfoForm, this.companiesService.currentCompany)]],
      webUrl: ['', [(event) => validateWebUrl('webUrl', event, this.editInfoForm, this.companiesService.currentCompany)]],
      linkName: ['', [(event) => validatorString('linkName', event, this.editInfoForm, this.companiesService.currentCompany)]],
      whatsappNumber: ['', [(event) => this.applyMask('whatsappNumber', event)]],
      location: ['', [(event) => this.validateLocation('location', event)]],
    });
  }

  onContextalMenu(event, value, type, id) {
    const elemento1 = document.getElementById('language-selector');
    const width = elemento1.offsetWidth;
    this.contextualMenuWidth = `${width}px`;

    const target = document.getElementById(id);
    this.contextualMenuService.processContextualMenuPosition(event, target, undefined, type, width -10);
    this.expand = value;
  }

  setLanguage(value: string) {
    this.companiesService.currentCompany.language = value;
    this.expand = false;
  }

  blockEKey(event: KeyboardEvent) {
    if (event.key.toLowerCase() === 'e') {
      event.preventDefault();
    }
  }

  getError(event) {
    const field = this.editInfoForm.get(event);
    return (this.submited || field?.touched) && (field?.errors?.required || field?.errors?.invalidParam)
  }

  getErrorType(event, type) {
    return this.editInfoForm.get(event)?.errors?.[type];
  }

  onSubmit(callback = undefined) {
    this.submited = true;
    if (this.editInfoForm.valid && callback) {
      callback();
    }
  }

  // ----------------------- controls -------------------------------
  applyMask(key, event) {
    if (!event.value || this.companiesService.currentCompany[key] === event.value) return;
    updateValue(key, event.value, this.editInfoForm, this.companiesService.currentCompany);

    this.mask = getPhoneMask(event.value);
    return null;
  }

  validateNotifEmail(key, event) {
    if (!event.value || this.notificationEmail === event.value) return;
    updateValue(key, event.value, this.editInfoForm, this.companiesService.currentCompany);

    let emails = this.notificationEmail.trim().split(',');
    let invalidParam = false;
    emails = emails.filter(e => {
      const res = checkEmailFormat(e);
      if (!res) invalidParam = true;
      return res;
    });

    this.companiesService.currentCompany[key] = emails;
    return invalidParam ? { invalidParam } : null;
  }

  validateLocation(key, event) {
    if (!event.value || this.location === event.value) return;

    const coords = checkCoords(event.value);
    this.location = event.value.toString();
    if (coords.success) updateLocation(key, coords.location, this.editInfoForm, this.companiesService.currentCompany);

    const invalidParam = !coords.success;
    return invalidParam ? { invalidParam } : null;
  }
}
