import { Device } from "../partitions/models/device";

export const searchAvailableErrors = (element: Device) => {
  let errors: string[] = [];

  if (element?.batteryStatus) {
    switch (element?.batteryStatus) {
      case 'low':
        errors.push('BatteryLow');
        break;
      case 'disconnected':
        errors.push('BatteryDisconnected');
        break;
      case 'failed':
        errors.push('BatteryFailed');
        break;
      default:
        break;
    }
  }
  if (typeof element?.sirenStatus == 'boolean' && element?.sirenStatus == false) {
    errors.push('SirenFailed');
  }
  if (typeof element?.energyStatus == 'boolean' && element?.energyStatus == false) {
    errors.push('EnergyFailed');
  }
  if (element?.tampered) {
    errors.push('Tampered');
  }
  if (typeof element?.connected == 'boolean' && element?.connected == false) {
    errors.push('Disconnected');
  }

  return errors;
}

export default {
  searchAvailableErrors
}
