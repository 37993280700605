<app-company-edit-info #editInfoForm *ngIf="companiesService.screen === steps.INFO" class="info-container middle-scrollable enable-click">
</app-company-edit-info>

<app-company-edit-users *ngIf="companiesService.screen === steps.USERS" class="info-container middle-scrollable enable-click">
</app-company-edit-users>

<app-company-edit-customize *ngIf="companiesService.screen === steps.CUSTOMIZE" class="info-container info-container-logo middle-scrollable enable-click" style="height: 100%;">
</app-company-edit-customize>


<div fxLayout="row" fxLayoutGap="20px" class="footer">
    <div class="center">
        {{ (companiesService.currentStep +1) + '/' + totalSteps }}
    </div>
    <div class="center">
        <button class="center-button enable-click" mat-raised-button color="primary" (click)="onBack()" [disabled]="companiesService.screen === steps.INFO">
            <mat-icon class="icon">chevron_left</mat-icon>
            {{ 'Settings.Back' | translate }}
        </button>
        <button class="center-button enable-click" mat-raised-button color="primary" (click)="onNext()" > 
            {{ 'Settings.Next' | translate }}
            <mat-icon class="icon">chevron_right</mat-icon>
        </button>
    </div> 
</div>
