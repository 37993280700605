import { Component, OnInit, ViewChild } from '@angular/core';
import { ContextualMenuService } from '../../services/contextual-menu.service';
import { CompaniesService } from '../../services/companies.service';
import { Steps, Sections } from '../../constants';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CompanyEditInfoComponent } from './company-edit-info/company-edit-info.component';
import { CompanyUserService } from '../../services/company-user.service';
import constants from 'src/app/shared/constants';
import { AuthService } from 'src/app/auth/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmActionDialogNewComponent } from 'src/app/shared/components/confirm-action-dialog-new-style/confirm-action-dialog.component';

@Component({
  selector: 'app-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrls: ['./company-edit.component.scss']
})
export class CompanyEditComponent implements OnInit {
  @ViewChild('editInfoForm') editInfoForm!: CompanyEditInfoComponent;

  public steps = Steps;
  totalSteps = 3;

  subscription;
  
  constructor(
    public contextualMenuService: ContextualMenuService,
    public companiesService: CompaniesService,
    public dialog: MatDialog,
    private readonly translateService: TranslateService,
    private readonly companyUserService: CompanyUserService,
    private readonly authService: AuthService,
    private readonly snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.companiesService.setBackToMainEvent(false);
    this.subscription = this.companiesService.backToMainEvent$.subscribe((value) => {
      if (value) this.onLeave();
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  onSuccessNext = () => {
    if (this.companiesService.screen < (this.totalSteps -1)) {
      if (this.companiesService.screen === this.companiesService.currentStep) {
        this.companiesService.currentStep++;
      }
      this.companiesService.screen++;
    } else if (this.companiesService.currentSection === Sections.Create) {
      this.onCreate();
    } else {
      this.onEdit();
    }
  }

  onNext() {
    if (this.companiesService.screen === Steps.INFO) {
      this.editInfoForm.onSubmit(this.onSuccessNext);
    } else {
      this.onSuccessNext();
    }
  }

  onEdit() {
    this.companiesService.loading = true;
    this.companyUserService.updateCompany(this.companiesService.currentCompany).subscribe({
      next: (res: any) => {
        this.companiesService.loading = false;
        if (res.success) {
          this.onSuccessEdit();
        }
      },
      error: (error) => {
        this.companiesService.loading = false;
        if (error?.status === 412) {
          this.snackBar.open(
            this.translateService.instant('Settings.Error.' + error.error.reason),
            this.translateService.instant('Shared.Close'),

            { duration: constants.snackBarErrorDuration }
          );
        } else if (error?.status === 401) {
          this.snackBar.open(
            this.translateService.instant('Shared.SessionExpired'),
            this.translateService.instant('Shared.Close'),

            { duration: constants.snackBarErrorDuration }
          );
          this.authService.logout();
        } else {
          this.snackBar.open(
            this.translateService.instant('Partition.NoGetPartition'),
            this.translateService.instant('Shared.Close'),

            { duration: constants.snackBarErrorDuration }
          );
        }
      },
    });
  }

  onSuccessEdit() {
    const dialogRef = this.dialog.open(ConfirmActionDialogNewComponent, {
      width: '400px',
      data: {
        title: 'Settings.EditCompanySuccess',
        rightButton: {
          title: 'Settings.EditCompanySuccess_confirm',
          color: {
            background: '#EAB910',
            font: 'black'
          }
        }
      },
    });

    dialogRef.afterClosed().subscribe((result: { status: string; topics: any }) => {
      this.companiesService.goToInfo();
      this.companiesService.setRefetchCompanies(true);
    });
  }

  onCreate() {
    this.companiesService.loading = true;
    this.companyUserService.createCompany(this.companiesService.currentCompany).subscribe({
      next: (res: any) => {
        this.companiesService.loading = false;
        this.onSuccessCreate();
      },
      error: (error) => {
        this.companiesService.loading = false;
        if (error?.status === 412) {
          this.snackBar.open(
            this.translateService.instant('Settings.Error.' + error.error.reason),
            this.translateService.instant('Shared.Close'),

            { duration: constants.snackBarErrorDuration }
          );
        } else if (error?.status === 401) {
          this.snackBar.open(
            this.translateService.instant('Shared.SessionExpired'),
            this.translateService.instant('Shared.Close'),

            { duration: constants.snackBarErrorDuration }
          );
          this.authService.logout();
        } else {
          this.snackBar.open(
            this.translateService.instant('Settings.Error.NoGetCompany'),
            this.translateService.instant('Shared.Close'),

            { duration: constants.snackBarErrorDuration }
          );
        }
      },
    });
  }

  onSuccessCreate() {
    const dialogRef = this.dialog.open(ConfirmActionDialogNewComponent, {
      width: '400px',
      data: {
        image: {
          svg: 'assets/icons/mail-check.svg',
        },
        title: 'Settings.CreateCompanySuccess',
        description: this.translateService.instant('Settings.CreateCompanySuccess_desc').replace('{{companyEmail}}', this.companiesService.currentCompany.notificationEmail || 'example@deitres.com'),
        rightButton: {
          title: 'Settings.CreateCompanySuccess_confirm',
          color: {
            background: '#EAB910',
            font: 'black'
          }
        }
      },
    });

    dialogRef.afterClosed().subscribe((result: { status: string; topics: any }) => {
      this.companiesService.companies.push(this.companiesService.currentCompany);
      this.companiesService.goToInfo();
      this.companiesService.setRefetchCompanies(true);
    });
  }

  onBack() {
    this.companiesService.screen--;
  }

  onLeave() {
    const dialogRef = this.dialog.open(ConfirmActionDialogNewComponent, {
      width: '400px',
      data: {
        title: 'Settings.EditLeave_warning',
        description: 'Settings.EditLeave_warning_desc',
        rightButton: {
          title: 'Settings.EditLeave_warning_confirm',
          color: {
            background: '#EAB910',
            font: 'black'
          }
        },
        leftButton: {
          title: 'Shared.Cancel',
          color: {
            background: 'white',
            font: 'black'
          }
        }
      },
    });

    dialogRef.afterClosed().subscribe((result: { status: string; topics: any }) => {
      this.companiesService.setBackToMainEvent(false);
      if (result?.status === 'right') this.companiesService.goToInfo();
    });
  }


  circleStyle(step: number) {
    if (this.companiesService.currentStep > step) {
      return 'circle-completed';
    } else if (this.companiesService.currentStep == step) {
      return 'circle-current';
    }
    return '';
  }

  lineStyle(step: number) {
    if (this.companiesService.currentStep > step) {
      return 'line-completed';
    }
    return '';
  }
}
