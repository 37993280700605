import { UserInfoComponent } from './../user-info/user-info.component';
import { UserFormComponent } from './../user-form/user-form.component';
import { AuthService } from './../../../auth/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsersService } from './../../services/users.service';
import { UsersService as UsersDataService } from 'src/app/shared/services/users.service';
import { UserApp } from './../../models/user-app';
import { ConfirmActionDialogComponent } from '../../../shared/components/confirm-action-dialog/confirm-action-dialog.component';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/services/notification.service';
import { ReceiversService } from 'src/app/shared/services/receivers.service';
import { BreadcrumbedReceptor, BreadCrumberChannelTypes } from 'src/app/panel/models/event.model';
import { EventService } from 'src/app/panel/services/event.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit, AfterViewInit {
  filterOptions: string[] = ['users', 'partitions', 'devices'];
  selectedOption: string;
  companyLogo: string;
  displayedColumns: string[];
  snackBarDuration = 5000;
  snackBarErrorDuration = 10000;
  color = {};
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  userTypeLabels = [];

  constructor(
    private readonly abms: UsersService,
    public usersDataService: UsersDataService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
    public authService: AuthService,
    public notifyService: NotificationService,
    private readonly eventService: EventService,
    private readonly receiverService: ReceiversService
  ) {
    this.displayedColumns = this.authService.isAdmin()
      ? ['email', 'company', 'name', 'lastName', 'type', 'partitions', 'active', 'secureAccessTime', 'actions']
      : ['email', 'name', 'lastName', 'type', 'partitions', 'active', 'secureAccessTime', 'actions'];
    this.usersDataService.dataSource.filter = undefined;

    this.eventService.breadcrumbedReceptor.subscribe((signalParams: BreadcrumbedReceptor) => {
      if (
        signalParams?.type === BreadCrumberChannelTypes.NEWFILTER &&
        this.receiverService.selectedReceiver.length > 0
      ) {
        this.usersDataService.filterReceiver();
      }
    });
  }

  ngOnInit() {
    this.usersDataService.dataSource.paginator = this.paginator;
    this.usersDataService.dataSource.sort = this.sort;

    this.usersDataService.dataSource.filterPredicate = (user: UserApp, filterValue: string) => {
      const filter = filterValue.trim().toLowerCase();
      if (!this.usersDataService.userEnableOnFilter(user)) return false;

      const companyName = this.authService.isAdmin() ? this.authService.getCompanyName(user.company) : '';

      let userTypeLabel = '';

      if (user.type === 'tech') {
        userTypeLabel = this.userTypeLabels[0];
      } else if (user.type === 'final') {
        userTypeLabel = this.userTypeLabels[1];
      }
      const fieldsToCheck = [user.email, user.name, user.lastName, userTypeLabel, companyName];

      return fieldsToCheck.some((field) => field?.trim().toLowerCase().includes(filter));
    };

    if (this.usersDataService.users.length === 0) {
      this.getUsers();
    }

    this.setUserTypeTranslation();
    this.translateService.onLangChange.subscribe(async (event) => {
      this.setUserTypeTranslation();
    });
  }

  private handleUserResponse(res: any, successMessageKey: string, errorMessageKey: string) {
    if (res?.success) {
      const updatedIndex = this.usersDataService.users.findIndex((p) => p._id == this.usersDataService.deleteUserId);
      this.usersDataService.users.splice(updatedIndex, updatedIndex >= 0 ? 1 : 0);
      this.getUsers();
      this.usersDataService.deleteUserId = undefined;
      this.snackBar.open(
        this.translateService.instant(successMessageKey),
        this.translateService.instant('Shared.Close'),
        { duration: this.snackBarDuration }
      );
    } else {
      this.snackBar.open(
        res.reason || this.translateService.instant(errorMessageKey),
        this.translateService.instant('Shared.Close'),
        { duration: this.snackBarDuration }
      );
    }
    this.usersDataService.isLoadingResults = false;
  }

  private handleUserError(
    error: any,
    genericErrorKey: string,
    sessionExpiredKey: string,
    logoutOnUnauthorized: boolean = false
  ) {
    if (error && error.status === 401) {
      this.snackBar.open(
        this.translateService.instant(sessionExpiredKey),
        this.translateService.instant('Shared.Close'),
        { duration: this.snackBarDuration }
      );
      if (logoutOnUnauthorized) {
        this.authService.logout();
      }
    } else {
      this.snackBar.open(
        this.translateService.instant(genericErrorKey),
        this.translateService.instant('Shared.Close'),
        { duration: this.snackBarDuration }
      );
    }
    this.usersDataService.isLoadingResults = false;
  }

  setUserTypeTranslation = async () => {
    this.userTypeLabels = [
      (await this.translateService.instant('User.Technician')).trim().toLowerCase(),
      (await this.translateService.instant('User.Final')).trim().toLowerCase(),
    ];
  };

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
  }

  applyFilter = (filterValue: string) => {
    this.usersDataService.dataSource.filter = filterValue;
  };

  onNewUserClick = async () => {
    const dialogRef = this.dialog.open(UserFormComponent, {
      width: '70%',
      maxWidth: '700px',
      data: {
        title: await this.translateService.instant('User.NewUser'),
        submitText: await this.translateService.instant('User.AddUser'),
        operation: 'add',
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        if (result.status === 'success') {
          this.getUsers();
        }

        if (result.status !== 'cancel') {
          this.snackBar.open(result.message, await this.translateService.instant('Shared.Close'), {
            duration: this.snackBarDuration,
          });
        }
      }
    });
  };

  //Se crea un objeto con los datos del usuario seleccionado.
  notifyUser(userId: string, companyId: string, userEmail: string) {
    const operation = 'Notify User';
    const title = 'User.NotifyUser';

    const usersToNotify = {};
    usersToNotify[companyId] = [
      {
        userId: userId,
        userEmail: userEmail,
      },
    ];
    this.notifyService.notifyUser(usersToNotify, operation, title);
  }

  // Se crea un array de objetos de tipo usuario con su id, email, idCompany y tipo, a parir de los usuarios activos.
  async notifyUsers() {
    const operation = 'Notify Users';
    const title = 'User.NotifyActiveUsers';
    const usersToNotify = {};
    let atleastOneUser = false;

    this.usersDataService.users.forEach((user) => {
      if (user?.active) {
        atleastOneUser = true;
        let userObj = {
          userId: user._id,
          userEmail: user.email,
          userType: user.type,
        };
        if (usersToNotify[user.company]) {
          usersToNotify[user.company].push(userObj);
        } else {
          usersToNotify[user.company] = [userObj];
        }
      }
    });

    if (!atleastOneUser) {
      this.snackBar.open(
        await this.translateService.instant('User.NotUsersToNot'),
        await this.translateService.instant('Shared.Close'),
        { duration: this.snackBarDuration }
      );
    } else {
      this.notifyService.notifyUser(usersToNotify, operation, title);
    }
  }

  updateUser = async (id: string) => {
    const dialogRef = this.dialog.open(UserFormComponent, {
      width: '700px',
      data: {
        title: await this.translateService.instant('User.ModUser'),
        submitText: await this.translateService.instant('User.ModUserSub'),
        operation: 'update',
        id,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        if (result.status === 'success') {
          this.getUsers();
        }

        if (result.status !== 'cancel') {
          this.snackBar.open(result.message, await this.translateService.instant('Shared.Close'), {
            duration: this.snackBarDuration,
          });
        }
      }
    });
  };

  activateUser = async (user: UserApp) => {
    this.openConfirmDialog(
      await this.translateService.instant('User.ActiveUser'),
      await this.translateService.instant('User.Activate'),
      (result: boolean) => {
        if (result) {
          this.abms.activeUser(user, true).subscribe({
            next: async (res: any) => {
              this.handleUserResponse(res, 'User.ActivedUser', 'Shared.Close');
            },
            error: async (error) => {
              this.handleUserError(error, 'User.ErrorActUser', 'Shared.SessionExpired', true);
            },
          });
        }
      }
    );
  };

  deactivateUser = async (user: UserApp) => {
    this.openConfirmDialog(
      await this.translateService.instant('User.DisableUser'),
      await this.translateService.instant('User.Deactivate'),
      (result: boolean) => {
        if (result) {
          this.abms.activeUser(user, false).subscribe({
            next: async (res: any) => {
              this.handleUserResponse(res, 'User.DeactivedUser', 'User.ErrorDeacUser');
            },
            error: async (error) => {
              this.handleUserError(error, 'User.ErrorDeacUser', 'Shared.SessionExpired');
            },
          });
        }
      }
    );
  };

  deleteUser = async (id: string) => {
    this.usersDataService.deleteUserId = id;
    this.openConfirmDialog(
      await this.translateService.instant('User.DeletePermanentlyt'),
      await this.translateService.instant('User.Delete'),
      (result: boolean) => {
        if (result) {
          this.abms.deleteUser(id).subscribe({
            next: async (res: any) => {
              this.handleUserResponse(res, 'User.UserDeleted', 'User.ErrorDeleteUser');
            },
            error: async (error) => {
              this.handleUserError(error, 'Shared.SessionExpired', 'User.ErrorDeleteUser', true);
            },
          });
        }
      }
    );
  };

  getUsers = async () => {
    if (!this.usersDataService.isLoadingResults) {
      this.usersDataService.requestUsers();
    }
  };

  openConfirmDialog(promptText: string, submitText: string, callback: Function): void {
    const dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      maxWidth: '450px',
      autoFocus: false,
      data: { promptText, submitText },
    });

    dialogRef.afterClosed().subscribe((result) => callback(result));
  }

  onCellUserClick = async (id: string, event?) => {
    if (event.view.getSelection().type !== 'Range') {
      const dialogRef = this.dialog.open(UserInfoComponent, {
        width: '40%',
        maxWidth: '700px',
        data: { title: await this.translateService.instant('User.PartitionDetail'), id },
      });

      dialogRef.afterClosed().subscribe((result) => {});
    }
  };
}
