import { Component, Input, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { Device } from 'src/app/partitions/models/device';
import { searchAvailableErrors } from '../../panel-utills';
import { CameraDevice } from 'src/app/partitions/models/camera-device';

const deviceIconById = {
  0: 'magnetic',
  1: 'motion',
  2: 'siren',
  3: 'button',
  4: 'repeater',
  5: 'light',
  6: 'plug',
  7: 'panic-button',
  8: 'co',
  9: 'gas',
  10: 'smoke',
  11: 'water',
  12: 'vibration',
  13: 'keypad',
  14: 'expanderModule',
  15: 'em-generic',
};

const AlarmDeviceModels = {
  0: 'magnetic', // 'Sensor Magnético Aqara'
  1: 'motion', // 'Sensor de movimiento Aqara'
  2: 'siren', // 'Sirena Heiman'
  3: 'magnetic', // 'Sensor magnético Konke'
  4: 'motion', // 'Sensor de movimiento Konke'
  5: 'keyring', // 'Llavero Heiman'
  7: 'motion', // 'Sensor de movimiento Heiman'
  8: 'magnetic', // 'Sensor magnético Heiman'
  9: 'panic-button', // 'Botón de pánico Heiman'
  10: 'co', // 'Sensor de CO Heiman'
  11: 'gas', // 'Sensor de gas Heiman'
  12: 'smoke', // 'Sensor de humo Heiman'
  13: 'water', // 'Sensor de agua Heiman'
  14: 'vibration', // 'Sensor de vibración Heiman'
  15: 'keypad', // 'Teclado Iris'
  16: 'expanderModule', // 'Placa expansora Citymesh chica'
  17: 'expanderModule', // 'Placa expansora Citymesh grande  (12 Zonas)'
  18: 'generic', // 'Zona 1 de'
  19: 'generic', // 'Zona 2 de'
  20: 'generic', // 'Zona 3 de'
  21: 'generic', // 'Zona 4 de'
  22: 'generic', // 'Zona 5 de'
  23: 'generic', // 'Zona 6 de'
  24: 'generic', // 'Zona 7 de'
  25: 'generic', // 'Zona 8 de'
  26: 'generic', // 'Zona 9 de'
  27: 'generic', // 'Zona 10 de'
  28: 'generic', // 'Zona 11 de'
  29: 'generic', // 'Zona 12 de'
  30: 'generic', // 'Zona 13 de'
  31: 'generic', // 'Zona 14 de'
  32: 'generic', // 'Zona 15 de'
  33: 'generic', // 'Zona 16 de'
  34: 'generic', // 'Zona 17 de'
  35: 'generic', // 'Zona 18 de'
  36: 'generic', // 'Zona 19 de'
  37: 'generic', // 'Zona 20 de'
  38: 'plug', // 'PGM 1 de'
  39: 'plug', // 'PGM 2 de'
  40: 'plug', // 'PGM 3 de'
  41: 'plug', // 'S2 de'
  42: 'plug', // 'GPIO3 de'
  43: 'plug', // 'GPIO4 de'
  44: 'plug', // 'GPIO5 de'
  45: 'expanderModule', // 'Placa expansora Citymesh grande (19 Zonas)'
  46: 'magnetic', // 'Sensor magnético Meian'
  47: 'water', // 'Sensor de agua Meian'
  48: 'motion', // 'Sensor de movimiento Meian'
  49: 'panic-button', // 'Botón de emergencia Meian'
  50: 'gas', // 'Sensor de Gas Meian'
  51: 'keyring', // 'Control remoto Meian'
  52: 'siren', // 'Sirena Meian'
  53: 'smoke', // 'Sensor de humo Meian'
  54: 'keypad', // 'Teclado Meian'
  55: 'magnetic', // 'Sensor magnético Meian'
};

const DomoticDeviceModels = {
  6: 'plug', // 'Smartplug Heiman'
  56: 'plug', // 'Enchufe Tuya - Canal 1'
  57: 'plug', // 'Enchufe Tuya - Canal 2'
  58: 'plug', // 'Interruptor Sonoff'
  59: 'plug', // 'Interruptor Ewelink 1CH'
  60: 'plug', // 'Interruptor Ewelink 2CH - Canal 1'
  61: 'plug', // 'Interruptor Ewelink 2CH - Canal 2'
};

const ZoneTypes = {
  PERIMETER: 0, // "Perimetral",
  INTERIOR: 1, // "Interior",
  HS24: 2, // "24hs",
  KEYRING: 3, // "Keyring",
  SIREN: 4, // "Siren",
  DOMOTIC: 5, // "Domotic",
  HS24NOTHEFT: 6, // "24hs no Theft",
  PANICBUTTON: 7, // "Panic button",
  KEYPAD: 8, // "Keypad",
  EXPANDERMODULE: 9, // "Expander module"
};

type DeviceExtended = Device | CameraDevice;

@Component({
  selector: 'app-panel-errors-table',
  templateUrl: './panel-errors-table.component.html',
  styleUrls: ['./panel-errors-table.component.scss'],
})
export class PanelErrorsTableComponent implements OnInit {
  displayedColumns: string[];

  @Input()
  dataSource: DeviceExtended[];
  @Input()
  cameras: CameraDevice[];

  constructor(private readonly translateService: TranslateService) {
    this.displayedColumns = ['icon', 'name', 'zoneID', 'errors', 'firmwareVersion', 'observations'];
  }

  ngOnInit(): void {
    let lastIndex = 0;
    if (this.dataSource) {
      if (this.cameras?.length) {
        this.cameras.forEach((c) => {
          lastIndex++;
          this.dataSource.push({
            name: c.name,
            connected: c.connected,
            deviceType: c.deviceType,
            zoneID: c.zoneID,
            deviceSerial: c.deviceSerial,

            /** Pushing an camera device in the DataSource: DeviceExtended[] */
            macDevice: undefined,
            modelID: undefined,
            zoneType: undefined,
            audible: undefined,
            enabled: undefined,
            delay: undefined,
            excludable: undefined,
            isAlarmSystem: undefined,
            batteryStatus: undefined,
            sirenStatus: undefined,
            energyStatus: undefined,
            tampered: undefined,
            firmwareVersion: undefined,
            routingStatus: undefined,
          });
        });
      }

      let data = this.dataSource.slice();
      data.forEach((element, index) => {
        // here we add an index for icon and observations columns
        // we could improve this index if we need complex sorting
        element['icon'] = index;
        element['observations'] = index;
        lastIndex = index;
      });

      const compareZones = (a: any, b: any) => compare(a.zoneID, b.zoneID, true);
      const sortedData = data.slice().sort(compareZones);
      this.dataSource = sortedData;
    }
  }

  errorsPresent(element: Device) {
    return searchAvailableErrors(element);
  }

  buildImgLink(errorType: string) {
    return `assets/icons/failures/${errorType}.svg`;
  }

  buildDeviceIconPath(device: Device) {
    let icon: string = device.isAlarmSystem ? AlarmDeviceModels[device.modelID] : DomoticDeviceModels[device.modelID];

    if (!icon) {
      icon = deviceIconById[device.deviceType];
    }

    /** is a camera */
    if (device['deviceSerial'] != undefined && device.deviceType == 15) {
      return `assets/icons/devices/device-camera.svg`;
    }

    return `assets/icons/devices/device-${icon}.svg`;
  }

  deviceNameByIdTranslationKey = (id: number) => {
    return this.translateService.instant(`Constants.deviceTypeHints.${id}`).replace(/^\d{1,2}:\s/, '');
  };

  modelNameByIdTranslationKey = (id: number) => {
    return this.translateService.instant(`Constants.modelIDHints.${id}`).replace(/^\d{1,2}:\s/, '');
  };

  zoneTypeHintsTranslationKey = (id: number) => {
    return this.translateService.instant(`Constants.zoneTypeHints.${id}`).replace(/^\d{1,2}:\s/, '');
  };

  buildDeviceImgTitle(device: Device) {
    const deviceName = device.deviceType != undefined ? this.deviceNameByIdTranslationKey(device.deviceType) : '-';
    const modelName = device.modelID != undefined ? this.modelNameByIdTranslationKey(device.modelID) : '-';
    const zoneName = device.zoneType != undefined ? this.zoneTypeHintsTranslationKey(device.zoneType) : '-';
    const deviceType = this.translateService.instant('Constants.deviceType');
    const modelId = this.translateService.instant('Constants.modelID');
    const zoneType = this.translateService.instant('Constants.zoneType');
    return `${deviceType}: ${deviceName}\n${modelId}: ${modelName}\n${zoneType}: ${zoneName}`;
  }

  sortData(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }

    const isAsc = sort.direction === 'asc';
    const compareZones = (a, b) => {
      switch (sort.active) {
        case 'name':
          return compare(a.name, b.name, isAsc);
        case 'zoneID':
          return compare(a.zoneID, b.zoneID, isAsc);
        case 'firmwareVersion':
          return compare(a.version, b.version, isAsc);
        default:
          return 0;
      }
    };
    const sortedData = data.slice().sort(compareZones);
    this.dataSource = sortedData;
  }

  getRowVersion(row: Device) {
    return row.firmwareVersion || '-';
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
