import { UsersService } from './../../services/users.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from './../../../auth/services/auth.service';
import { UserApp } from './../../models/user-app';
import { Component, Inject } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ConfirmActionDialogComponent } from 'src/app/shared/components/confirm-action-dialog/confirm-action-dialog.component';
import { TranslateService } from '@ngx-translate/core';

export interface DialogData {
  title: string;
  operation: string;
  submitText: string;
  promptText: string;
  id: string;
}

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent {

  isLoading = false;
  user: UserApp = new UserApp();
  originalUser: UserApp;
  crossFieldErrorMatcher = new CrossFieldErrorMatcher();
  emailErrorMatcher = new EmailErrorMatcher();
  secureAccessTimeErrorMatcher = new SecureAccessTimeErrorMatcher();
  secureAccessWarningErrorMatcher = new SecureAccessWarningErrorMatcher();
  minSAT: number;
  maxSAT: number;
  defaultSAT: number;
  regeneratedPass = false;
  resetDisarmPass = false;
  allowPushNotifications: boolean;
  allowWhatsapp: boolean;
  whatsappNumber: string;
  chatbotAdministrador: boolean;

  generatingPassword = false;

  constructor(
    public authService: AuthService,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<UserFormComponent>,
    private loadingBar: LoadingBarService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    private abm: UsersService
  ) {
    this.chatbotAdministrador = this.authService.user.chatbotAdministrador;
    this.minSAT = this.authService.getPrimaryCompany().minSecureAccessTime ? this.authService.getPrimaryCompany().minSecureAccessTime / 1000 : 30;
    this.maxSAT = this.authService.getPrimaryCompany().maxSecureAccessTime ? this.authService.getPrimaryCompany().maxSecureAccessTime / 1000  : 300;
    this.defaultSAT = this.authService.getPrimaryCompany().defaultSecureAccessTime ? this.authService.getPrimaryCompany().defaultSecureAccessTime / 1000 : 60;
    dialogRef.disableClose = true;

    dialogRef.backdropClick().subscribe(() => this.onCancelClick());

    if (this.data.operation === 'update') {
      this.loadingBar.useRef().start();

      this.abm.getUser(this.data.id).subscribe({
        next: async (res: any) => {
          if (res.success) {
            this.user = {...res.user};
            this.originalUser = {...res.user};
            delete this.user.pass;
            delete this.originalUser.pass;
            this.user.secureAccessTime = this.user.secureAccessTime / 1000;
            this.user.secureAccessWarning = this.user.secureAccessWarning ? this.user.secureAccessWarning / 1000 : undefined;
            this.allowPushNotifications = this.user?.intelligentNotifications?.allowPushNotifications || false;
            this.allowWhatsapp = this.user?.intelligentNotifications?.allowWhatsapp || false;
            this.whatsappNumber = this.user?.intelligentNotifications?.whatsappNumber || '';
            this.loadingBar.useRef().complete();
          } else {
            this.dialogRef.close({ status: 'error', message: await this.translateService.get('User.ErrorGetUser').toPromise() });
            this.loadingBar.useRef().stop();
          }
        },
        error: async (error) => {
          this.loadingBar.useRef().stop();

          if (error && error.status === 401) {
            this.dialogRef.close({ status: 'error', message: await this.translateService.get('Shared.SessionExpired').toPromise() });
            this.authService.logout();
          } else {
            this.dialogRef.close({ status: 'error', message: await this.translateService.get('User.ErrorGetUser').toPromise() });
          }
        }
      });
    } else if (this.data.operation === 'add') {
      this.allowPushNotifications = false;
      this.allowWhatsapp = false;
      this.whatsappNumber = '';

      if (this.authService.user.companies?.length > 1) {
        this.user.selectedLang = this.authService.user.companies[0].language ?? this.authService.defaultLanguage;
      } else {
        this.user.company = this.authService.getPrimaryCompany()._id;
        this.user.selectedLang = this.authService.getPrimaryCompany()?.language ? this.authService.getPrimaryCompany()?.language : this.authService.defaultLanguage;
      }
      this.user.secureAccessTime = this.defaultSAT;
      this.user.type = 'final';
    }
  }

  submit = () => {
    this.isLoading = true;
    this.loadingBar.useRef().start();

    if (this.data.operation === 'add') {
      this.addUser();
    } else if (this.data.operation === 'update') {
      this.updateUser();
    }
  }

  addUser = async () => {
    this.user.intelligentNotifications.allowPushNotifications = this.allowPushNotifications.toString() == 'true';
    this.user.intelligentNotifications.allowWhatsapp = this.allowWhatsapp.toString() == 'true';
    this.user.intelligentNotifications.whatsappNumber = this.whatsappNumber;

    const newUser = {
      ...this.user,
      secureAccessTime: this.user.secureAccessTime * 1000,
      secureAccessWarning: this.user.secureAccessWarning ? this.user.secureAccessWarning * 1000 : undefined,
    }

    if (this.authService.user.companies.length === 1) {
      newUser.company = this.authService.getPrimaryCompany()._id;
    }

    this.emailErrorMatcher.setUserExists(false);

    this.abm.addUser(newUser).subscribe({
      next: async (res: any) => {
        if (res && res.success) {
          const message = res.reason || await this.translateService.get('User.UserAdded').toPromise();
          if (res.emailError) {
            navigator.clipboard.writeText(this.user.pass);
          }
          this.dialogRef.close({ status: 'success', message });
          this.loadingBar.useRef().complete();
        } else {
          if (res.code && res.code === 'emailExists') {
            this.emailErrorMatcher.setUserExists(true);
          } else if (res.code && res.code === 'invalidParams') {
            this.dialogRef.close({ status: 'error', message: await this.translateService.get('User.InvalidParam').toPromise() });
          } else if (res.code && res.code === 'EmailUsedOtherCompany') {
            this.dialogRef.close({ status: 'error', message: res.reason});
          } else {
            this.dialogRef.close({ status: 'error', message: await this.translateService.get('User.ErrorAddUser').toPromise() });
          }

          this.loadingBar.useRef().stop();
        }

      },
      error: async (error) => {
        this.isLoading = false;
        this.loadingBar.useRef().stop();

        if (error && error.status === 401) {
          this.dialogRef.close({ status: 'error', message: await this.translateService.get('Shared.SessionExpired').toPromise() });
          this.authService.logout();
        } else {
          this.dialogRef.close({ status: 'error', message: await this.translateService.get('User.ErrorAddUser').toPromise() });
        }
      }
    });
  }

  updateUser = async () => {
    this.emailErrorMatcher.setUserExists(false);
    const secureAccessWarning = this.user.secureAccessWarning ? this.user.secureAccessWarning * 1000 : undefined;

    // Se borran los datos que no se modificaron.
    const updatedUser = new UserApp();
    updatedUser._id = this.user._id;
    updatedUser.company = this.user.company;
    updatedUser.email = this.originalUser.email !== this.user.email ? this.user.email : undefined;
    updatedUser.name = this.originalUser.name !== this.user.name ? this.user.name : undefined;
    updatedUser.lastName = this.originalUser.lastName !== this.user.lastName ? this.user.lastName : undefined;
    updatedUser.pass = this.regeneratedPass ? this.user.pass : undefined;
    updatedUser.type = this.originalUser.type !== this.user.type ? this.user.type : undefined;
    updatedUser.selectedLang = this.originalUser.selectedLang !== this.user.selectedLang ? this.user.selectedLang : undefined;
    updatedUser.secureAccessTime = this.originalUser.secureAccessTime !== (this.user.secureAccessTime * 1000) ? (this.user.secureAccessTime * 1000) : undefined;
    updatedUser.secureAccessWarning = this.originalUser.secureAccessWarning !== secureAccessWarning ? secureAccessWarning : undefined;
    updatedUser.removeAccessWarning = !secureAccessWarning;
    updatedUser.forceChangeDisarmPass = this.resetDisarmPass ? true : this.originalUser.forceChangeDisarmPass;
    updatedUser.intelligentNotifications.allowPushNotifications = this.allowPushNotifications.toString() == 'true';
    updatedUser.intelligentNotifications.allowWhatsapp = this.allowWhatsapp.toString() == 'true';
    updatedUser.intelligentNotifications.whatsappNumber = this.whatsappNumber;

    this.abm.modifyUser(updatedUser).subscribe({
      next: async (res: any) => {
        if (res && res.success) {
          const message = res.reason || await this.translateService.get('User.UserMod').toPromise() ;
          if (res.emailError) {
            navigator.clipboard.writeText(this.user.pass);
          }
          this.dialogRef.close({ status: 'success', message });
          this.loadingBar.useRef().complete();
        } else {
          if (res.code && res.code === 'emailExists') {
            this.emailErrorMatcher.setUserExists(true);
          } else if (res.code && res.code === 'invalidParams') {
            this.dialogRef.close({ status: 'error', message: await this.translateService.get('User.InvalidParam').toPromise() });
          } else {
            this.dialogRef.close({ status: 'error', message: await this.translateService.get('User.ErrorModUser').toPromise() });
          }

          this.loadingBar.useRef().stop();
        }

      },
      error: async (error) => {
        this.isLoading = false;
        this.loadingBar.useRef().stop();

        if (error && error.status === 401) {
          this.dialogRef.close({ status: 'error', message: await this.translateService.get('Shared.SessionExpired').toPromise() });
          this.authService.logout();
        } else {
          this.dialogRef.close({ status: 'error', message: await this.translateService.get('User.ErrorModUser').toPromise() });
        }
      }
    });
  }

  resetingDisarmPass = async () => {
    let msgDialog;
    let btMsgDialog;
    if (this.resetDisarmPass) {
      msgDialog = await this.translateService.get('User.ResetPassCancel').toPromise();
      btMsgDialog = await this.translateService.get('User.CancelReset').toPromise();
    } else {
      msgDialog = await this.translateService.get('User.DeletePass').toPromise();
      btMsgDialog = await this.translateService.get('User.Reset').toPromise();
    }
    this.openConfirmDialog(msgDialog, btMsgDialog, (result: boolean) => {
      if (result) {
        this.resetDisarmPass = !this.resetDisarmPass;
      }
    });
  }

  generatePassword = async () => {
    this.regeneratedPass = true;
    const generate = () => {
      this.generatingPassword = true;
      this.user.pass = '';

      this.abm.generatePass().subscribe({
        next: async (res: any) => {
          if (res && res.success) {
            this.user.pass = res.newPassword;
          } else {
            this.snackBar.open(await this.translateService.get('User.ErrorGeneratePass').toPromise(), await this.translateService.get('Shared.Close').toPromise(), { duration: 10000 });
          }

          this.generatingPassword = false;
        },
        error: async (error) => {
          this.generatingPassword = false;

          if (error && error.status === 401) {
            this.dialogRef.close({ status: 'error', message: await this.translateService.get('Shared.SessionExpired').toPromise() });
            this.authService.logout();
          } else {
            this.snackBar.open(await this.translateService.get('User.ErrorGeneratePass').toPromise(), await this.translateService.get('Shared.Close').toPromise(), { duration: 10000 });
          }
        }
      });
    };

    if (this.data.operation === 'update') {
      this.openConfirmDialog(await this.translateService.get('User.GenerateNewPass').toPromise(), await this.translateService.get('User.Generate').toPromise(), (result: boolean) => {
        if (result) {
          generate();
        }
      });
    } else {
      generate();
    }
  }

  openConfirmDialog(promptText: string, submitText: string, callback: Function): void {
    const dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      maxWidth: '450px',
      autoFocus: false,
      data: { promptText, submitText }
    });

    dialogRef.afterClosed().subscribe(result => callback(result));
  }

  onCancelClick = async () => {
    this.openConfirmDialog(await this.translateService.get('User.ExitForm').toPromise(), await this.translateService.get('Shared.Leave').toPromise(), (result: boolean) => {
      if (result) {
        this.dialogRef.close({ status: 'cancel' });
      }
    });
  }

  validateSecureAccessTime = () => {
    this.secureAccessTimeErrorMatcher.setExists(false);
    if (this.user.secureAccessTime < this.minSAT || this.user.secureAccessTime > this.maxSAT) {
      this.secureAccessTimeErrorMatcher.setExists(true);
    }
  }

  validateSecureAccessWarning = () => {
    this.secureAccessWarningErrorMatcher.setExists(false);
    if (
      this.user.secureAccessWarning && (this.user.secureAccessWarning < this.minSAT || this.user.secureAccessWarning > this.maxSAT)
    ) {
      this.secureAccessWarningErrorMatcher.setExists(true);
    }
  }

  isValidData(): boolean {
    return !this.secureAccessTimeErrorMatcher.getExists() &&
      !this.secureAccessWarningErrorMatcher.getExists() &&
      !this.isLoading &&
      !this.emailErrorMatcher.getUserExists() &&
      this.user.email !== undefined && this.user.email !== '' &&
      this.user.name !== undefined && this.user.name !== '' &&
      this.user.lastName !== undefined && this.user.lastName !== '';
  }
}

export class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control.dirty && control.errors) || (form.invalid && form.errors && form.errors.validTimerRange);
  }
}

export class SecureAccessTimeErrorMatcher implements ErrorStateMatcher {
  SecureAccessTimeExists = false;

  public getExists(): boolean {
    return this.SecureAccessTimeExists;
  }

  public setExists(val: boolean) {
    this.SecureAccessTimeExists = val;
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return this.SecureAccessTimeExists || ((control.touched || form.submitted) && control.errors !== undefined && control.errors !== null);
  }
}

export class SecureAccessWarningErrorMatcher implements ErrorStateMatcher {
  SecureAccessWarningExists = false;

  public getExists(): boolean {
    return this.SecureAccessWarningExists;
  }

  public setExists(val: boolean) {
    this.SecureAccessWarningExists = val;
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return this.SecureAccessWarningExists || ((control.touched || form.submitted) && control.errors !== undefined && control.errors !== null);
  }
}

export class EmailErrorMatcher implements ErrorStateMatcher {
  EmailExists = false;

  public getUserExists(): boolean {
    return this.EmailExists;
  }

  public setUserExists(val: boolean) {
    this.EmailExists = val;
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return this.EmailExists || ((control.touched || form.submitted) && control.errors !== undefined && control.errors !== null);
  }
}
