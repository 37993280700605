import { UserCompanyModel } from './../../shared/models/user-company-model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserCredentialsModel } from '../models/user-credentials-model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';
import { CompanyModel } from 'src/app/shared/models/company-model';
import { TranslateService } from '@ngx-translate/core';
import { BetaWarningComponent } from 'src/app/shared/components/beta-warning/beta-warning.component';
import { MatDialog } from '@angular/material/dialog';
import { DistributorModel } from 'src/app/shared/models/distributor-model';
import { ToastrService } from 'ngx-toastr';

const jsonLang: string[] = ['es', 'en', 'pt'];
interface TokenResponse {
  success: boolean;
  token: string;
  availableCommands: number[];
  companies: CompanyModel[];
  distributor: DistributorModel;
  chatbotAdministrador: boolean;
  config: RepoConfig;
}

interface RepoConfig {
  databusGuideURL: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private token: string;
  private readonly jwtHelper: JwtHelperService = new JwtHelperService();
  public user: UserCompanyModel;
  private currentTokenCheckID;
  public defaultLanguage: string = 'es';
  public selectedLanguage: string;
  public repositoryConfig: any;
  private expiredTokenToastShow = false;

  private readonly currentBetaWarningVersion: number = 1; // Increase on future BetaWarningComponent updates
  emptyCompany = new CompanyModel();

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
    private readonly dialog: MatDialog,
    private readonly toastrService: ToastrService
  ) {
    this.selectedLanguage = this.managerLanguage();
  }

  private saveToken(
    token: string,
    userCommands: number[],
    userCompanies: CompanyModel[],
    userDistributor: DistributorModel
  ): void {
    localStorage.setItem('mean-token', token);
    localStorage.setItem('user-Commands', JSON.stringify(userCommands));
    localStorage.setItem('user-Companies', JSON.stringify(userCompanies));
    localStorage.setItem('user-distributor', JSON.stringify(userDistributor));
    this.token = token;
    this.expiredTokenToastShow = false;
  }

  private saveLoginData(chatbotAdministrador, config): void {
    localStorage.setItem('chatbot-administrador', JSON.stringify(chatbotAdministrador));
    localStorage.setItem('repository-config', JSON.stringify(config));
  }

  // Método para definir el idioma inicial, en caso de no haber uno seleccionado por el usuario se
  // definirá el del navegador, en caso de no tener traducción del mismo, se configurará español por default.
  public selectInitialLang(): string {
    let initialLang: string;

    if (this.selectedLanguage) {
      initialLang = this.selectedLanguage;
    } else if (jsonLang.includes(navigator.language.split('-')[0])) {
      initialLang = navigator.language.split('-')[0];
    } else {
      initialLang = this.defaultLanguage;
    }
    return initialLang;
  }

  //Método para manejar los cambios de idioma según haya o no idioma guardado en sesión.
  public managerLanguage(): string {
    this.selectedLanguage = localStorage.getItem('selectedLanguage')
      ? localStorage.getItem('selectedLanguage')
      : this.selectInitialLang();
    return this.selectedLanguage;
  }

  //Método para guardar en sesión el idioma seleccionado por el usuario.
  public saveSelectedLanguage(language?: string): void {
    if (language) this.selectedLanguage = language;

    if (this.token != undefined) {
      localStorage.setItem('selectedLanguage', this.selectedLanguage);
    }
  }

  failSafeParseJson(jsonTextData: string) {
    try {
      return JSON.parse(jsonTextData);
    } catch (error) {
      return undefined;
    }
  }

  public getToken(): string {
    if (!this.token && localStorage.getItem('user-Companies')) {
      this.token = localStorage.getItem('mean-token');
      const userCommands = this.failSafeParseJson(localStorage.getItem('user-Commands'));
      const companies = this.failSafeParseJson(localStorage.getItem('user-Companies'));
      const distributor = this.failSafeParseJson(localStorage.getItem('user-distributor'));
      const config = this.failSafeParseJson(localStorage.getItem('repository-config'));
      const betaWarning = this.failSafeParseJson(localStorage.getItem('beta-warning'));
      const messageChecks = this.failSafeParseJson(localStorage.getItem('message-checks'));
      const chatbotAdministrador = this.failSafeParseJson(localStorage.getItem('chatbot-administrador'));

      if (!this.jwtHelper.isTokenExpired(this.token)) {
        this.setUser(
          this.token,
          userCommands,
          companies,
          distributor,
          chatbotAdministrador,
          config,
          betaWarning,
          messageChecks
        );

        const path = localStorage.getItem('last-navigation-path') || '/maps';
        this.router.navigateByUrl(path);
      } else {
        this.token = undefined;
      }
    }
    if (this.token) this.checkTokenRenew();
    return this.token;
  }

  public checkTokenRenew() {
    const { exp } = this.jwtHelper.decodeToken(this.token);
    const expiresIn = exp ? exp * 1000 - new Date().getTime() : -1;
    if (expiresIn > 0 && expiresIn <= 10 * 60 * 1000) {
      // Si esta por vencer se intenta renovar
      this.checkAccessToken();
    } else if (!this.currentTokenCheckID) {
      this.currentTokenCheckID = setTimeout(() => {
        this.currentTokenCheckID = undefined;
        this.checkAccessToken();
      }, expiresIn - 10 * 60 * 1000);
    }
  }

  public login(user: UserCredentialsModel): Observable<any> {
    const request = this.http
      .post(`${environment.webappEndpoint}/auth/login`, { username: user.email, password: user.pass })
      .pipe(
        map((data: TokenResponse) => {
          if (data.success && data.token) {
            this.setUser(
              data.token,
              data.availableCommands,
              data.companies,
              data.distributor,
              data.chatbotAdministrador,
              data.config
            );
            this.saveToken(data.token, data.availableCommands, data.companies, data.distributor);
            this.saveLoginData(data.chatbotAdministrador, data.config);
            this.saveSelectedLanguage();
            this.openBetaWarningModal();
          }
          return data;
        })
      );

    return request;
  }

  public checkAccessToken() {
    this.http
      .post(
        `${environment.webappEndpoint}/auth/checkAccessToken`,
        {},
        { headers: { Authorization: `Bearer ${this.token}` } }
      )
      .subscribe({
        next: (res: any) => {
          if (res.success && res.token) {
            this.currentTokenCheckID = undefined;
            this.user.chatbotAdministrador = res.chatbotAdministrador;
            this.setUser(
              res.token,
              this.user.availableCommands,
              this.user.companies,
              this.user.distributor,
              this.user.chatbotAdministrador,
              undefined
            );
            this.saveToken(res.token, this.user.availableCommands, this.user.companies, this.user.distributor);
            this.checkTokenRenew();
          }
          // Si falla control de accessToken se fuerza el deslogueo
          if (!res.success) this.logout();
          return res;
        },
        error: (error) => {
          if (error.error.status == 'UNAUTHORIZED' && error.error.code == 401) {
            if (!this.expiredTokenToastShow)
              this.toastrService.info(
                this.translateService.instant('Shared.SessionExpired'),
                this.translateService.instant('Shared.Attention'),
                {
                  timeOut: 5_000,
                  closeButton: true,
                  easing: 'ease',
                  easeTime: 300,
                  tapToDismiss: true,
                  newestOnTop: true,
                }
              );
            this.expiredTokenToastShow = true;
            this.logout();
          }
        },
      });
  }

  public logout(): void {
    this.token = undefined;
    this.user = undefined;
    localStorage.removeItem('mean-token');
    localStorage.removeItem('user-Commands');
    localStorage.removeItem('user-Companies');
    localStorage.removeItem('user-distributor');
    localStorage.removeItem('selectedLanguage');
    localStorage.removeItem('last-navigation-path');
    localStorage.removeItem('beta-warning');
    localStorage.removeItem('message-checks');
    this.router.navigateByUrl('/login');
  }

  public getUserDetails(): UserCompanyModel {
    const token = this.getToken();
    if (token) {
      return this.jwtHelper.decodeToken(token);
    } else {
      return null;
    }
  }

  public isLoggedIn(): boolean {
    const token = this.getToken();
    if (token) {
      return !this.jwtHelper.isTokenExpired(token);
    } else {
      return false;
    }
  }

  private setUser(
    token: string,
    availableCommands: number[],
    companies: CompanyModel[],
    distributor: DistributorModel,
    chatbotAdministrador: boolean,
    config: RepoConfig,
    betaWarning: number = undefined,
    messagesChecks = undefined
  ) {
    const data = this.jwtHelper.decodeToken(token);
    const userCompanies = distributor?.companies || companies;
    this.user = {
      _id: data._id,
      email: data.email,
      name: data.name,
      lastName: data.lastName,
      phone: data.phone,
      language: data.language,
      enableTechSupport: data.enableTechSupport,
      unmonitoredCompany: data.unmonitoredCompany,
      availableCommands: availableCommands ?? [],
      chatbotAdministrador,
      betaWarning: betaWarning ?? data.betaWarning ?? 0,
      messagesChecks: messagesChecks ?? data.messagesChecks ?? {},
      companies: userCompanies,
      role: data.role,
      distributor: distributor,
    };

    Sentry.setUser({ id: data._id, email: data.email });

    this.saveSelectedLanguage(data.language);
    if (config) this.repositoryConfig = config;
  }

  getCompanyName = (companyID: string) => {
    if (!companyID) return '';

    const companyFound = this.user.companies.find((c) => c._id === companyID);

    if (!companyFound) {
      this.snackBar.open(
        this.translateService.instant('Shared.NotFound'),
        this.translateService.instant('Shared.Accept'),
        { duration: 5000 }
      );
    }

    return companyFound?.name ? companyFound.name : '';
  };

  getPrimaryCompany = () => {
    if (this.user.companies.length > 0) {
      return this.user.companies[0];
    }

    return this.emptyCompany;
  };

  getCompanies = () => {
    return this.user.companies;
  };

  isAdmin = () => {
    return this.user?.companies?.length > 1;
  };

  public updateUserBetaVersion(version: number): Observable<any> {
    const request = this.http
      .post(
        `${environment.webappEndpoint}/userCompany/betaVersion`,
        { version },
        { headers: { Authorization: `Bearer ${this.token}` } }
      )
      .pipe();

    return request;
  }

  openBetaWarningModal(): void {
    if (this.user && (!this.user?.betaWarning || this.user?.betaWarning < this.currentBetaWarningVersion)) {
      const dialogRef = this.dialog.open(BetaWarningComponent, {
        maxWidth: '570px',
        autoFocus: false,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result?.status === 'gotIt') {
          this.user.betaWarning = this.currentBetaWarningVersion;
          this.updateUserBetaVersion(this.currentBetaWarningVersion).subscribe((response) => {
            if (response.success) localStorage.setItem('beta-warning', JSON.stringify(this.currentBetaWarningVersion));
          });
        }
      });
    }
  }

  updateMessageCheck(field): void {
    this.user.messagesChecks[field] = true;
    this.updateUserMessageCheck(field).subscribe((response) => {
      if (response.success) localStorage.setItem('message-checks', JSON.stringify(this.user.messagesChecks));
    });
  }

  public updateUserMessageCheck(field: string): Observable<any> {
    const request = this.http
      .post(
        `${environment.webappEndpoint}/userCompany/messageChecks`,
        { field },
        { headers: { Authorization: `Bearer ${this.token}` } }
      )
      .pipe();

    return request;
  }
}
