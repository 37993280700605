import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../app-material/app-material.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PartitionListComponent } from './components/partition-list/partition-list.component';
import { PartitionFormComponent } from './components/partition-form/partition-form.component';
import { PartitionInfoComponent } from './components/partition-info/partition-info.component';
import { NotificationFormComponent } from './components/notification-form/notification-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { PartitionAlarmTriggerComponent } from './partition-alarm-trigger/partition-alarm-trigger.component';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    PartitionListComponent,
    PartitionFormComponent,
    PartitionInfoComponent,
    NotificationFormComponent,
    PartitionAlarmTriggerComponent,
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule,
    MatSelectModule,
  ],
})
export class PartitionsModule {}
