import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/services/auth.service';

@Injectable()
export class MessagesProcessorSocket extends Socket {
  constructor() {
    super({
      url: environment.messagesProcessorEndpoint,
      options: {
        query: {
          token: environment.messagesProcessorToken.replace('Bearer ', ''),
        },
        transports: ['websocket', 'polling'],
      },
    });
  }
}

@Injectable()
export class SocketService extends Socket {
  constructor(public authService: AuthService) {
    super({
      url: environment.webappSocketEndpoint,
      options: {
        query: {
          token: environment.webappSocketToken.replace('Bearer ', ''),
        },
        transports: ['websocket', 'polling'],
      },
    });
  }
}
