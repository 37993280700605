<div class="modal">
    <div class="title" mat-dialog-title>{{ 'Settings.CreateUserRequest' | translate}}</div>
    <form  *ngIf="editUserForm" [formGroup]="editUserForm" (ngSubmit)="onSubmit()">
      <mat-dialog-content>
        <div class="description">{{ 'Settings.CreateUserRequest_desc' | translate}}</div>
        <!-- Name, lastname, email and phone -->
        <div class="expanded-info-parent">
          <div class="expanded-info-column">
            <div class="input-box">
              <div class="expanded-info-title">
                  {{ ('Settings.Name' | translate) }}
              </div>
              <input 
                class="expanded-info-input" 
                formControlName="name"
                placeholder="{{ ('Settings.Name' | translate) }}" 
                matInput #message
                [(ngModel)]="newUser.name"
              >
              <div [class.visible]="getError('name')" class="expanded-info-detail error">
                  <span *ngIf="getErrorType('name', 'required')">{{ 'Settings.ErrorControl.RequiredField' | translate }}</span>
              </div>
            </div>
            <div class="input-box">
              <div class="expanded-info-title">
                  {{ ('Settings.Email' | translate) }}
              </div>
              <input 
                class="expanded-info-input" 
                formControlName="email"
                placeholder="{{ ('Settings.Email' | translate) }}" 
                matInput #message
                [(ngModel)]="newUser.email"
              >
              <div [class.visible]="getError('email')" class="expanded-info-detail error">
                  <span *ngIf="getErrorType('email', 'required')">{{ 'Settings.ErrorControl.RequiredField' | translate }}</span>
                  <span *ngIf="getErrorType('email', 'invalidParam')">{{ 'Settings.ErrorControl.EmailFormat' | translate }}</span>
              </div>
            </div>
            <div class="input-box">
              <div class="expanded-info-title">
                {{ ('Settings.Permissions' | translate) }}
              </div>
              <div 
                fxLayout="row" 
                fxLayoutGap="20px" 
                class="selector-field"
                style="width: 241px"
                (click)="onContextalMenu($event, !newUser.expand, contextualMenuType.Permission, 'permission-selector-create-user')"
                onKeyPress
                onKeyDown
                onKeyUp
              >
                  <div> {{ ('Settings.UserLevel.' + newUser.permission) | translate }} </div> 
                  <mat-icon id="permission-selector-create-user" class="permission-selector">keyboard_arrow_down</mat-icon>
              </div>
            </div>
          </div>
          <div class="expanded-info-column">
            <div class="input-box">
              <div class="expanded-info-title">
                  {{ ('Settings.Lastname' | translate) }}
              </div>
              <input 
                class="expanded-info-input" 
                formControlName="lastname"
                placeholder="{{ ('Settings.Lastname' | translate) }}" 
                matInput #message 
                [(ngModel)]="newUser.lastname"
              >
            </div>
            <div class="input-box">
              <div class="expanded-info-title">
                  {{ ('Settings.Phone' | translate) }}
              </div>
              <input 
                type="text"
                class="expanded-info-input" 
                formControlName="phone"
                [(ngModel)]="newUser.phone"
                mask="{{ mask }}"
                dropSpecialCharacters="false"
                placeholder="{{ ('Settings.Phone' | translate) }}"
              >
            </div>
            <div class="input-box">
              <div class="expanded-info-title">
                {{ ('Settings.Language' | translate) }}
              </div>
              <div 
                fxLayout="row" 
                fxLayoutGap="20px" 
                class="selector-field" 
                style="width: 241px"
                (click)="onContextalMenu($event, !newUser.expand, contextualMenuType.Language, 'language-selector-create-user')"
                onKeyPress
                onKeyDown
                onKeyUp
              >
                  <div> {{ ('Settings.Languages.' + newUser.language) | translate }} </div> 
                  <mat-icon id="language-selector-create-user" class="permission-selector">keyboard_arrow_down</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions class="button-line">
        <button 
          class="button-container-button cancel-button"
          mat-button [mat-dialog-close]="true"  
          (click)="closeModal('cancel')"
        >
          {{ 'Shared.Cancel' | translate}}
        </button>
        <button 
          class="button-container-button create-button"
          mat-button
          type="submit"
        >
          {{ 'Settings.CreateUserRequest_confirm' | translate}}
        </button>
      </mat-dialog-actions >
      <button class="close-button" (click)="closeModal('close')">
          <mat-icon class="close-icon">close</mat-icon>
      </button>
    </form>
</div>
<ul
    *ngIf="newUser?.expand"
    [ngStyle]="{'position': 'absolute', 'top.px': contextualMenuService.menuPosition.top, 'left.px': contextualMenuService.menuPosition.left}"
    id="menu-contextual"
    class="button-container"
>
    <button
        *ngFor="let value of contextualMenuService.getMenuContent()"
        class="table-row"
        (click)="setValue(value)"
        (keypress)="setValue(value)"
        matTooltipShowDelay="750"
        matTooltipPosition="after"
    >
        {{ (contextualMenuService.getTranslateKey(value)) | translate }}
    </button>
</ul>