<div class="mainContainer" mat-sidenav-container>
  <ngx-loading-bar color="green" [includeSpinner]="false"></ngx-loading-bar>

  <div
    *ngIf="router.url !== '/login' && authService.user"
    fxFlex
    autosize
    class="background scrollbar"
    [ngClass]="{ colapseMenu: !this.showMenu }"
  >
    <div
      fxLayout="column"
      class="sidenav"
      [class.border-menu]="hoverMenuesService.sideMenuStatus || hoverMenuesService.helpMenuStatus"
    >
      <div class="logo-direct" (click)="toggleShowMenu()" (keydown)="toggleShowMenu()">
        <div *ngIf="showMenu" class="logoDeitres">
          <img src="assets/img/logo.png" alt="Logo" />
        </div>
        <div *ngIf="!showMenu" class="logoDeitresSmall">
          <img src="../../../favicon.ico" alt="Logo" />
        </div>
      </div>

      <!-- Menu Navigation   -->
      <div *ngIf="router.url !== '/login' && authService.user" class="menu-navigation">
        <button
          [disableRipple]="true"
          mat-button
          routerLink="/maps"
          matTooltip="{{ 'AppComponent.NetMesh' | translate }}"
          routerLinkActive="selected"
          fxLayoutAlign="start"
          (click)="resetMapZoomAndCleanRestorationFocusSystem()"
          class="navigation-button"
        >
          <div class="icon" [class.icon-extended]="showMenu">
            <mat-icon>pin_drop</mat-icon>
            <span class="info" [class.info-extended]="showMenu">
              {{ 'AppComponent.NetMesh' | translate }}
            </span>
          </div>
        </button>

        <button
          [disableRipple]="true"
          mat-button
          routerLink="/users"
          matTooltip="{{ 'AppComponent.Users' | translate }}"
          routerLinkActive="selected"
          fxLayoutAlign="start"
          class="navigation-button"
        >
          <div class="icon" [class.icon-extended]="showMenu">
            <mat-icon>person_search</mat-icon>
            <span class="info" [class.info-extended]="showMenu">{{ 'AppComponent.Users' | translate }}</span>
          </div>
        </button>
        <button
          [disableRipple]="true"
          mat-button
          routerLink="/partitions"
          matTooltip="{{ 'AppComponent.Partitions' | translate }}"
          routerLinkActive="selected"
          fxLayoutAlign="start"
          class="navigation-button"
        >
          <div class="icon" [class.icon-extended]="showMenu">
            <mat-icon>home</mat-icon>
            <span class="info" [class.info-extended]="showMenu">
              {{ 'AppComponent.Partitions' | translate }}
            </span>
          </div>
        </button>
        <button
          [disableRipple]="true"
          mat-button
          routerLink="/panels"
          matTooltip="{{ 'AppComponent.CityMeshManagement' | translate }}"
          routerLinkActive="selected"
          fxLayoutAlign="start"
          class="navigation-button"
        >
          <div class="icon" [class.icon-extended]="showMenu">
            <mat-icon>settings_remote</mat-icon>
            <span class="info custom-info" [class.info-extended]="showMenu">
              {{ 'AppComponent.CityMeshManagement' | translate }}
            </span>
          </div>
        </button>
        <button
          [disableRipple]="true"
          mat-button
          routerLink="/techsupport"
          matTooltip="{{ 'AppComponent.TechnicalSupport' | translate }}"
          routerLinkActive="selected"
          *ngIf="authService.user.enableTechSupport"
          fxLayoutAlign="start"
          class="navigation-button"
        >
          <div class="icon" [class.icon-extended]="showMenu">
            <img src="assets/icons/menues/support.svg" alt width="24" height="24" />
            <mat-icon *ngIf="commandService.isFilterEdited" class="isFilterAlert">lens</mat-icon>

            <span class="info pt" [class.info-extended]="showMenu">
              {{ 'AppComponent.TechnicalSupport' | translate }}
            </span>
          </div>
        </button>
      </div>

      <!-- Login Button -->
      <div *ngIf="showMenu && router.url === '/login'">
        <button
          [disableRipple]="true"
          mat-button
          routerLink="/login"
          matTooltip="{{ 'AppComponent.Login' | translate }}"
          routerLinkActive="selected no-border txt-bold"
          fxLayoutAlign="start"
          class="py-2 pr-5"
        >
          <span class="menu-items-font-style">
            {{ showMenu ? ('AppComponent.Login' | translate) : '' }}
          </span>
        </button>
        <mat-divider></mat-divider>
      </div>

      <span class="fill-space"></span>

      <div class="menu-navigation" style="border-top: 1px solid #42526d">
        <div #helpButton [style]="showMenu ? 'width: 100%' : ''">
          <button
            [disableRipple]="true"
            mat-button
            (click)="onToggleHelpMenu()"
            matTooltip="{{ 'AppComponent.Help' | translate }}"
            class="navigation-button"
          >
            <div class="icon" [class.icon-extended]="showMenu">
              <mat-icon style="font-size: 20px" class="mat-icon-custom">help</mat-icon>
              <span class="info" [class.info-extended]="showMenu">
                {{ 'AppComponent.Help' | translate }}
              </span>
            </div>
            <mat-icon *ngIf="showMenu" class="subitem-right">chevron_right</mat-icon>
          </button>
        </div>

        <div
          #receiverSelector
          *ngIf="router.url !== '/login' && authService.getPrimaryCompany().name"
          class="navigation-button"
          style="width: 100%"
        >
          <app-breadcrumbed-receptor-picker
            [heightReference]="receiverSelector?.nativeElement?.getBoundingClientRect().top"
            [showMenu]="showMenu"
            [routerUrl]="router.url"
            style="width: 100%; height: 100%;"
          ></app-breadcrumbed-receptor-picker>
        </div>

        <!-- User -->
        <div *ngIf="router.url !== '/login' && authService.user" [style]="showMenu ? 'width: 100%' : ''">
          <button [disableRipple]="true" mat-button class="navigation-button" (click)="onToggleProfileMenu()">
            <div class="icon" [class.icon-extended]="showMenu">
              <img src="assets/icons/menues/account_circle.svg" alt />
              <span class="info" [class.info-extended]="showMenu" style="bottom: -8px">
                {{ authService.user.name }}
              </span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    [ngClass]="{
      containerPadding: router.url !== '/login' && authService.user && !this.showMenu,
      container: router.url !== '/login' && authService.user
    }"
  >
    <router-outlet></router-outlet>
    <span *ngIf="router.url !== '/maps'" class="version-text">{{
      ('AppComponent.Version' | translate) + ': ' + v
    }}</span>
  </div>
</div>

<app-breadcrumbed-help
  [showMenu]="showMenu"
  [heightReference]="helpButton?.nativeElement?.getBoundingClientRect().top"
></app-breadcrumbed-help>
<app-message-bubble
  *ngIf="
    router.url !== '/login' &&
    authService.user &&
    !authService.user.messagesChecks.helpMenuMessage &&
    hoverMenuesService.helpBubbleMessageStatus
  "
  [showMenu]="showMenu"
  [heightReference]="helpButton?.nativeElement?.getBoundingClientRect().top - 55"
  [params]="helpBubble"
></app-message-bubble>
<div
  class="profile-submenu"
  *ngIf="hoverMenuesService.profileMenuStatus && router.url !== '/login'"
  [class.profile-submenu-extended]="showMenu"
>
  <div class="submenu-content">
    <button
      [disableRipple]="true"
      mat-button
      routerLink="/profile"
      matTooltip="{{ 'AppComponent.MyProfile' | translate }}"
      class="submenu-item"
      (click)="closeProfileMenu()"
    >
      <div class="item icon">
        <img src="assets/icons/menues/account_circle.svg" alt />
        <span class="font-style-profile-submenu-item">
          {{ 'AppComponent.MyProfile' | translate }}
        </span>
      </div>
    </button>
    <button
      [disableRipple]="true"
      mat-button
      matTooltip="{{ 'AppComponent.Logout' | translate }}"
      (click)="logout()"
      class="submenu-item"
    >
      <div class="item">
        <mat-icon>logout</mat-icon>
        <span class="font-style-profile-submenu-item">
          {{ 'AppComponent.Logout' | translate }}
        </span>
      </div>
    </button>
  </div>
</div>
