import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  list: any[];
}

@Component({
  selector: 'app-receiver-selector',
  templateUrl: './receiver-selector.component.html',
  styleUrls: ['./receiver-selector.component.scss']
})
export class ReceiverSelectorComponent implements OnInit {
  selected: string;

  constructor(
    private dialogRef: MatDialogRef<ReceiverSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit() {
  }

  onSelect = (selected) => {
    this.dialogRef.close({ status: 'success', data: selected });
  }

  onCancelClick = () => {
    this.dialogRef.close({ status: 'cancel' });
  }
}
