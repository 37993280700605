<div #containerElement class="mainContainer" fxLayout="column" fxLayoutGap="15px">
  <div fxLayout="row" fxLayoutGap="20px" class="filters">
    <div fxLayout="row" fxLayoutGap="20px">
      <mat-form-field class="select-filter">
        <mat-label>{{ 'Panel.Select' | translate }} </mat-label>
        <mat-select [(ngModel)]="filterOptions" multiple>
          <mat-option value="devices">{{ 'Panel.Devices' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="filterField">
        <input
          matInput
          (keyup)="applyFilter($event.target.value)"
          [(ngModel)]="panelsService.dataSource.filter"
          placeholder="{{ 'Panel.Search' | translate }}"
        />
      </mat-form-field>

      <button
        FilterDevices
        mat-icon-button
        [class.filter-pressed]="!panelsService.showActive"
        [matMenuTriggerFor]="menu"
        matTooltip="{{ 'Panel.FilterDevices' | translate }}"
      >
        <mat-icon>filter_list</mat-icon>
      </button>
      <mat-menu #menu="matMenu" class="menu-button">
        <div class="menu-title">{{ 'Panel.FilterBy' | translate }}</div>
        <button mat-menu-item (click)="switchFilterDeleted()">
          <mat-icon class="selected-icon" [class.hidden]="panelsService.showActive">check</mat-icon>
          {{ 'Panel.RemovedDevices' | translate }}
        </button>
      </mat-menu>

      <!-- <button mat-icon-button (click)="getPanels()">
        <mat-icon>refresh</mat-icon>
      </button> -->
    </div>
    <!-- Temporarily Hidden -->
    <!-- <div fxLayout="row" fxLayoutGap="25px" class="buttons-filters">
      <button mat-button class="button-filter" (click)="OpenNotificationsDialog()">
        {{ 'Panel.GeneralNotifications' | translate }}
      </button>
      <button mat-button class="button-filter" (click)="OpenRegisterDialog()">
        {{ 'Panel.Register' | translate }}
      </button>
      <button
        matTooltipShowDelay="750"
        matTooltipPosition="after"
        mat-icon-button
        class="custom-button"
        (click)="goToUsers()"
      >
        <mat-icon class="custom-icon">person</mat-icon>
      </button>
    </div> -->
  </div>

  <div class="main-table-container mat-elevation-z8" fxFlex fxLayout="column" fxFlexFill>
    <div class="loading-shade" *ngIf="panelsService.isLoadingResults">
      <mat-spinner *ngIf="panelsService.isLoadingResults"></mat-spinner>
    </div>

    <div class="table-container" fxFlex>
      <table mat-table [dataSource]="panelsService.dataSource" matSort [class.disableClick]="!panelsService.showActive">
        <!-- Errores -->
        <ng-container matColumnDef="errors">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Panel.Errors' | translate }}</th>
          <td mat-cell *matCellDef="let element" (click)="openErrors(element._id)" (keydown)="openErrors(element._id)">
            <!-- Here we've update with a new panel and a reloadCounter (control variable) together
            because we need to make the component understand that its an update -->
            <app-panel-errors-info-button-wrapper
              [panel]="element"
              [update]="{ panel: element, reloadCounter: element.reloadCounter }"
            >
            </app-panel-errors-info-button-wrapper>
            {{ element.errorsCounter }}
          </td>
        </ng-container>

        <!-- MAC -->
        <ng-container matColumnDef="mac">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>MAC</th>
          <td mat-cell *matCellDef="let element" (click)="openErrors(element._id)" (keydown)="openErrors(element._id)">
            {{ element.mac }}
          </td>
        </ng-container>

        <!-- Tipo -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Panel.Type' | translate }}</th>
          <td mat-cell *matCellDef="let element" (click)="openErrors(element._id)" (keydown)="openErrors(element._id)">
            {{
              element.type === 'citypanel' && element.isCommunicator
                ? this.panelTypes['citypanelCom']
                : this.panelTypes[element.type]
            }}
          </td>
        </ng-container>

        <!-- Receptora -->
        <ng-container matColumnDef="receiverName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Panel.Receiver' | translate }}</th>
          <td mat-cell *matCellDef="let element" (click)="openErrors(element._id)" (keydown)="openErrors(element._id)">
            {{ element.receiverName }}
          </td>
        </ng-container>

        <!-- Company -->
        <ng-container matColumnDef="company" *ngIf="this.authService.isAdmin()">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'AppComponent.Company' | translate }}</th>
          <td
            mat-cell
            *matCellDef="let element"
            class="pointer"
            (click)="openPanel(element._id)"
            (keydown)="openPanel(element._id)"
          >
            {{ this.authService.getCompanyName(element.company) }}
          </td>
        </ng-container>

        <ng-template #infoUnavailable>
          <div class="horizontal-align div-info" matTooltip="{{ 'SignalConsult.InfoUnavailable' | translate }}">
            <img src="assets/icons/devices/info.svg" alt width="18" height="18" class="icon-info" />
          </div>
        </ng-template>

        <!-- Señal Mesh -->
        <ng-container matColumnDef="meshSignal">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div class="header-align">{{ 'Panel.MeshSignal' | translate }}</div>
          </th>
          <td mat-cell *matCellDef="let element">
            <app-signal-strength
              *ngIf="element.unavailableModules && !element.unavailableModules.mesh; else infoUnavailable"
              class="horizontal-align"
              [mac]="element.mac"
              [signalType]="'mesh'"
              [deviceId]="element._id"
              [signalStrength]="element.meshSignal"
            >
            </app-signal-strength>
          </td>
        </ng-container>

        <!-- Señal Ethernet -->
        <ng-container matColumnDef="ethSignal">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div class="header-align">{{ 'Panel.EtherSignal' | translate }}</div>
          </th>
          <td mat-cell *matCellDef="let element">
            <app-signal-strength
              *ngIf="element.unavailableModules && !element.unavailableModules.eth; else infoUnavailable"
              class="horizontal-align"
              [mac]="element.mac"
              [signalType]="'eth'"
              [deviceId]="element._id"
              [signalStrength]="element.ethSignal"
            >
            </app-signal-strength>
          </td>
        </ng-container>

        <!-- Señal 3G/4G -->
        <ng-container matColumnDef="mobileSignal">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div class="header-align">{{ 'Panel.3g4gSignal' | translate }}</div>
          </th>
          <td mat-cell *matCellDef="let element">
            <app-signal-strength
              *ngIf="element.unavailableModules && !element.unavailableModules.mobile; else infoUnavailable"
              class="horizontal-align"
              [mac]="element.mac"
              [signalType]="'mobile'"
              [deviceId]="element._id"
              [signalStrength]="element.mobileSignal"
            >
            </app-signal-strength>
          </td>
        </ng-container>

        <!-- Señal WiFi -->
        <ng-container matColumnDef="wifiSignal">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div class="header-align">{{ 'Panel.WifiSignal' | translate }}</div>
          </th>
          <td mat-cell *matCellDef="let element">
            <app-signal-strength
              *ngIf="element.unavailableModules && !element.unavailableModules.wifi; else infoUnavailable"
              class="horizontal-align"
              [mac]="element.mac"
              [signalType]="'wifi'"
              [deviceId]="element._id"
              [signalStrength]="element.wifiSignal"
            >
            </app-signal-strength>
          </td>

          <ng-template #infoUnavailable>
            <div class="horizontal-align div-info" matTooltip="{{ 'SignalConsult.InfoUnavailable' | translate }}">
              <img src="assets/icons/devices/info.svg" alt width="18" height="18" class="icon-info" />
            </div>
          </ng-template>
        </ng-container>

        <!-- SSID WiFi -->
        <ng-container matColumnDef="ssid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>SSID WiFi</th>
          <td mat-cell *matCellDef="let element" (click)="openErrors(element._id)" (keydown)="openErrors(element._id)">
            {{ getShortText(element.ssid) }}
          </td>
        </ng-container>

        <!-- Particiones -->
        <ng-container matColumnDef="partitions">
          <div class="horizontal-align">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <div class="header-align">{{ 'Panel.Partition' | translate }}</div>
            </th>
          </div>
          <td
            mat-cell
            *matCellDef="let element"
            (click)="openErrors(element._id)"
            (keydown)="openErrors(element._id)"
            class="horizontal-align-text"
          >
            {{ element.partitions ? element.partitions.length : '0' }}
          </td>
        </ng-container>

        <!-- Notas -->
        <ng-container matColumnDef="adminNotes">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Panel.Notes' | translate }}</th>
          <td mat-cell *matCellDef="let element" (click)="openErrors(element._id)" (keydown)="openErrors(element._id)">
            {{ getShortText(element.adminNotes) }}
          </td>
        </ng-container>

        <!-- Acciones -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
            <div style="display: flex; justify-content: center">{{ 'Panel.Actions' | translate }}</div>
          </th>
          <td mat-cell *matCellDef="let element" style="min-width: 150px; display: flex; justify-content: flex-end">
            <button
              mat-icon-button
              matTooltipShowDelay="750"
              matTooltipPosition="after"
              matTooltip="{{ 'Panel.GoToMap' | translate }}"
              (click)="panelStatusService.goToMap(element.mac, element.apiKey)"
              aria-hidden="false"
            >
              <img
                style="width: 25px"
                [class.disable-icon]="!panelsService.showActive"
                src="assets/icons/image_maps.png"
                alt="maps"
              />
            </button>
            <button
              mat-icon-button
              matTooltip="{{ 'Panel.Edit' | translate }}"
              matTooltipShowDelay="750"
              matTooltipPosition="after"
              (click)="updatePanel($event, element._id)"
              aria-hidden="false"
              [class.disable-icon]="!panelsService.showActive"
            >
              <mat-icon>edit</mat-icon>
            </button>

            <button
              *ngIf="showButtonMenu()"
              mat-icon-button
              [matMenuTriggerFor]="buttonMenu"
              [class.disable-icon]="!panelsService.showActive"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #buttonMenu="matMenu" class="menu-button">
              <button
                *ngIf="element.type === 'citypanel'"
                mat-menu-item
                matTooltip="{{ 'Panel.Settings' | translate }}"
                matTooltipShowDelay="750"
                matTooltipPosition="after"
                (click)="setupPanel($event, element)"
                [class.disable-icon]="!panelsService.showActive"
              >
                <mat-icon svgIcon="settings_icon" class="exclude-icon"></mat-icon>
                {{ 'Panel.Settings' | translate }}
              </button>
              <button
                *ngIf="authService.user.enableTechSupport && element.mac"
                mat-menu-item
                matTooltip="{{ 'Panel.AddToConsole' | translate }}"
                matTooltipShowDelay="750"
                matTooltipPosition="after"
                (click)="addToTechSupport($event, element)"
                aria-hidden="false"
                [class.disable-icon]="!panelsService.showActive"
              >
                <ng-container *ngIf="element.selectedToSupport; else deviceAdd">
                  <mat-icon svgIcon="remove_support_icon" class="exclude-icon"></mat-icon>
                </ng-container>
                <ng-template #deviceAdd>
                  <mat-icon svgIcon="add_support_icon" class="exclude-icon"></mat-icon>
                </ng-template>
                {{ 'Panel.AddToConsoleShort' | translate }}
              </button>
              <button
                mat-menu-item
                *ngIf="element.type == 'citypanel' && element.isCommunicator"
                matTooltip="{{ 'Panel.InstaMode' | translate }}"
                (click)="communicatorToPanel(element._id)"
                matTooltipShowDelay="750"
                matTooltipPosition="after"
                [class.disable-icon]="!panelsService.showActive"
              >
                <mat-icon>settings_backup_restore</mat-icon>
                {{ 'Panel.InstaMode' | translate }}
              </button>
              <button
                mat-menu-item
                *ngIf="element.type == 'citypanel' && !element.isCommunicator && element.partitions.length > 0"
                matTooltip="{{ 'Panel.UserPasswords' | translate }}"
                (click)="passwordPanel(element.mac, element.partitions)"
                matTooltipShowDelay="750"
                matTooltipPosition="after"
                [class.disable-icon]="!panelsService.showActive"
              >
                <mat-icon>lock</mat-icon>
                {{ 'Panel.UserPasswords' | translate }}
              </button>
              <button
                mat-menu-item
                *ngIf="authService.user.unmonitoredCompany"
                matTooltip="{{ 'Panel.DisableMonitoring' | translate }}"
                (click)="sendToUnmonitoredCompany(element.mac, element.company)"
                matTooltipShowDelay="750"
                matTooltipPosition="after"
                [class.disable-icon]="!panelsService.showActive"
              >
                <mat-icon>desktop_access_disabled</mat-icon>
                {{ 'Panel.DisableMonitoring' | translate }}
              </button>
              <button
                mat-menu-item
                matTooltip="{{ 'Panel.Delete' | translate }}"
                (click)="deletePanel(element._id, element.mac)"
                matTooltipShowDelay="750"
                matTooltipPosition="after"
              >
                <mat-icon
                  svgIcon="delete_icon"
                  class="exclude-icon"
                  [class.disabled-button]="!panelsService.showActive"
                ></mat-icon>
                {{ 'Panel.Delete' | translate }}
              </button>
            </mat-menu>

            <ng-container *ngIf="!showButtonMenu()">
              <button
                *ngIf="element.type === 'citypanel'"
                mat-icon-button
                matTooltip="{{ 'Panel.Settings' | translate }}"
                matTooltipShowDelay="750"
                matTooltipPosition="after"
                (click)="setupPanel($event, element)"
                aria-hidden="false"
                [class.disable-icon]="!panelsService.showActive"
              >
                <img
                  src="assets/icons/menues/settings-icon.svg"
                  alt="settings icon"
                  width="30"
                  height="30"
                  [class.disable-icon]
                />
              </button>
              <button
                *ngIf="authService.user.enableTechSupport && element.mac"
                mat-icon-button
                matTooltip="{{ 'Panel.AddToConsole' | translate }}"
                matTooltipShowDelay="750"
                matTooltipPosition="after"
                (click)="addToTechSupport($event, element)"
                aria-hidden="false"
                [class.disable-icon]="!panelsService.showActive"
              >
                <ng-container *ngIf="element.selectedToSupport; else deviceAdd">
                  <mat-icon svgIcon="remove_support_icon" class="exclude-icon"></mat-icon>
                </ng-container>
                <ng-template #deviceAdd>
                  <mat-icon svgIcon="add_support_icon" class="exclude-icon"></mat-icon>
                </ng-template>
              </button>
              <button
                mat-icon-button
                *ngIf="element.type == 'citypanel' && element.isCommunicator"
                matTooltip="{{ 'Panel.InstaMode' | translate }}"
                (click)="communicatorToPanel(element._id)"
                matTooltipShowDelay="750"
                matTooltipPosition="after"
                aria-hidden="false"
                [class.disable-icon]="!panelsService.showActive"
              >
                <mat-icon>settings_backup_restore</mat-icon>
              </button>
              <button
                mat-icon-button
                *ngIf="element.type == 'citypanel' && !element.isCommunicator && element.partitions.length > 0"
                matTooltip="{{ 'Panel.UserPasswords' | translate }}"
                (click)="passwordPanel(element.mac, element.partitions)"
                matTooltipShowDelay="750"
                matTooltipPosition="after"
                [class.disable-icon]="!panelsService.showActive"
              >
                <mat-icon>lock</mat-icon>
              </button>
              <button
                mat-icon-button
                *ngIf="authService.user.unmonitoredCompany"
                matTooltip="{{ 'Panel.DisableMonitoring' | translate }}"
                (click)="sendToUnmonitoredCompany(element.mac, element.company)"
                matTooltipShowDelay="750"
                matTooltipPosition="after"
                [class.disable-icon]="!panelsService.showActive"
              >
                <mat-icon>desktop_access_disabled</mat-icon>
              </button>
              <button
                mat-icon-button
                matTooltip="{{ 'Partition.Delete' | translate }}"
                (click)="deletePanel(element._id, element.mac)"
                matTooltipShowDelay="750"
                matTooltipPosition="after"
                [class.disable-icon]="!panelsService.showActive"
              >
                <mat-icon
                  svgIcon="delete_icon"
                  class="exclude-icon"
                  [class.disabled-button]="!panelsService.showActive"
                ></mat-icon>
              </button>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
          [class.disabled]="!panelsService.showActive"
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{ onMousehover: color[row._id] === 'over' }"
          (mouseover)="color[row._id] = 'over'"
          (mouseout)="color[row._id] = 'out'"
          (focus)="color[row._id] = 'over'"
          (blur)="color[row._id] = 'out'"
        ></tr>
      </table>
    </div>

    <mat-paginator class="mat-paginator-sticky" [pageSize]="20" [pageSizeOptions]="[20, 50, 100]"></mat-paginator>
  </div>
</div>
