export default {
  snackBarDuration: 5000,
  snackBarErrorDuration: 10000,
  webappSocketTypes: {
    panelVitalsUpdate: 'panel-vitals-update',
    newNeighbor: 'panel-new-neighbor',
    mapNetworkUpdate: 'map-network-update',
    mapInterfaceUpdate: 'map-interface-update',
    usersListPackage: 'users-list-package',
    partitionsListPackage: 'partitions-list-package',
    panelsListPackage: 'panels-list-package',
    monitoringUpdate: 'monitoring-update',
    reloadNeighborUpdate: 'reload-neighbor-update',
    neighborReloadState: 'neighbor-reload-state'
  },
  CLIENT_FILTER_ALL: '*',
  helpHints: {
    deviceType: 0, 
    modelID: 1, 
    zoneType: 2
  },
  helpHintsvalue: [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15],
    [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 17, 18, 19, 
      20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
      40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
      60, 61
    ],
    [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  ],
  firmwareType: {
      dataBus: 'Data bus',
      panel: 'Citypanel',
      tester: 'Tester'
  },
}
