<form name="f" (ngSubmit)="f.form.valid" #f="ngForm" novalidate formValidTimerRange>
    <div class="parent-div">
      <div class="partition-div">
        <h1 mat-dialog-title>{{data.title}}</h1>
        <mat-form-field>
          <mat-label>{{'TechSupport.Commands' | translate}}</mat-label>
          <select name="selectedCommand" [(ngModel)]="selected" (ngModelChange)="onSelect($event)" matNativeControl required>
            <option [ngValue]="undefined" selected>{{'TechSupport.SelectCompany' | translate}}</option>
            <option *ngFor="let r of this.data.list" [ngValue]="r">{{ r.name }}</option>
          </select>
        </mat-form-field>
      </div>
    </div>
    <mat-dialog-actions align="end">
      <button type="button" mat-raised-button (click)="onCancelClick()">{{'TechSupport.Cancel' | translate}}</button>
    </mat-dialog-actions >
</form>
  