<ng-container *ngIf="signalStrength > 0; else searchForSignal">
  <div
    matTooltip="{{ 'SignalConsult.Update' | translate }}"
    matTooltipShowDelay="750"
    matTooltipPosition="left"
    class="signal-strength-container"
    [class.inactive]="!panelsService.showActive"
    [class.disabled]="isLoading"
    (click)="startPanelConnection()"
    onKeyUp
  >
    <div>
      <mat-progress-spinner
        [value]="(this.valueSpinner +20) / 2.5"
        class="spinner"
        *ngIf="isLoading === true"
        diameter="30"
      ></mat-progress-spinner>
      {{ signalStrength }}
    </div>
  </div>
</ng-container>

<ng-template #searchForSignal>
  <div
    matTooltip="{{ 'SignalConsult.Update' | translate }}"
    matTooltipShowDelay="750"
    matTooltipPosition="left"
    [class.disabled]="isLoading"
    (click)="startPanelConnection()"
    style="position: relative"
    [class.disabled-no-connection]="isLoading"
    onKeyUp
  >
    <mat-spinner *ngIf="isLoading === true" diameter="20" class="loading-spinner"></mat-spinner>
    <span class="no-connection" *ngIf="isLoading === false">{{ 'Panel.SearchForSignal' | translate }}</span>
  </div>
</ng-template>
