<div class="comic-bubble" [style.left.px]="calcOffset()" [style.top]="this.heightReference + 'px'">
  <div class="title-row">
    <mat-icon class="icon">{{params.icon}}</mat-icon>
    <div class="title">{{ params.title | translate }}</div>
  </div>
  <div class="content">
    <div class="description">{{ params.description | translate }}</div>
    <div class="confirm" onKeyPress onKeyDown onKeyUp (click)="onConfirm()">{{ params.confirm | translate }}</div>
  </div>
  <div class="bubble-tail"></div>
  <button class="close-button" (click)="onClose()">
      <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>