<div *ngIf="_reload">
  <div *ngIf="!loading" class="subitem scrollbar" style="overflow-y: auto; max-height: 131px; width: 310px">
    <div style="border: 0.5px solid white; border-radius: 5px; width: 297px">
      <div class="prevent-select" style="border-bottom: 0.5px solid white; align-items: center; text-align: center">
        {{ title }}
      </div>
      <span *ngFor="let n of _dataset; let i = index">
        <div
          class="macs-table"
          [style]="_dataset.length - 1 == i ? 'border-bottom: none' : 'border-bottom: 0.5px solid white;'"
        >
          <div onKeyPress onKeyDown onKeyUp style="text-align: center" class="prevent-select" (click)="clip(n[0])">
            {{ n[0] }}
          </div>
          <div
            onKeyPress
            onKeyDown
            onKeyUp
            style="border-left: 0.5px solid white; text-align: center"
            class="prevent-select"
            (click)="clip(n[1])"
          >
            {{ n[1] || '-' }}
          </div>
        </div>
      </span>
    </div>
  </div>
</div>
