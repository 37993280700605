import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SendCommandsServiceService } from 'src/app/tech-support/services/send-commands-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PanelSocketService } from 'src/app/shared/services/panelsocket.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { WebppSocketService } from 'src/app/shared/services/webppSocket.service';
import { TranslateService } from '@ngx-translate/core';
import { Receiver } from '../models/receiver-model';
import { PanelsService } from './panels.service';
import { WebappSubscriptionService } from './webapp-subscription.service';
import constants from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ReceiversService {
  isLoadingResults: boolean = false;
  snackBarErrorDuration: number = 10000;
  snackBarDuration = 5000;

  monitoringReportID: number = 173;
  receivers: Receiver[] = [];
  dataSource: MatTableDataSource<Receiver>;
  filteredReceivers: Receiver[] = [];
  monitoringStateUp: boolean = true;
  selectedReceiver: string[] = [];

  onReceiversCallback = [];
  
  // Breadcrumbed - Filter Selected Name
  filterName: string;

  constructor(
    public sendCommandService: SendCommandsServiceService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private socketMP: PanelSocketService,
    public authService: AuthService,
    private panelChangesSocket: WebppSocketService,
    private panelsService: PanelsService,
    private webappSocket: WebappSubscriptionService,
  ) { 
    this.dataSource = new MatTableDataSource(this.receivers);
    this.webappSocket.addSubscription(this.handleIncomingMessages, constants.webappSocketTypes.monitoringUpdate);
    this.panelChangesSocket.connEvent.subscribe(() => {
      if (this.receivers) this.panelChangesSocket.connect(this.receivers);
    });
    this.panelChangesSocket.requestDataToService(() => {
      const apiKeys = this.receivers.map(r => r.apiKey);
      this.panelsService.filterAndSendSignalReport(apiKeys);
    });
  }

  handleIncomingMessages = async (message) => {
    const receiver = this.receivers.find(r => r.apiKey === message.apiKey);
    if (receiver) {
      if (message.monitoringStatus != undefined) receiver.monitoringStatus = message.monitoringStatus;
      receiver.status = message.status;
    }
  }

  causedErrorCloseTranslations = async () => {
    const causedError = await this.translateService.get('Shared.CausedError').toPromise();
    const close = await this.translateService.get('Shared.Close').toPromise();
    return [
      causedError,
      close
    ]
  }

  getReceivers = () => {
    this.sendCommandService.getAvailableReceivers().subscribe({
      next: (res: any) => {
        if (res && res.success) {
          this.receivers = res.receivers.map((receiver) => ({
            ...receiver,
            testPHourConfiguration: {
              lowControl: receiver.testPHourConfiguration?.lowControl ?? 6,
              goodControl: receiver.testPHourConfiguration?.goodControl ?? 10,
            }
          }));
          if (this.receivers) {
            // Init socket listeners for messages-processor & mobapp-service
            this.socketMP.initMPListener(this.receivers.map(r => r.apiKey));
            this.panelChangesSocket.connect(this.receivers);

            this.onReceiversCallback.forEach(cb => cb());
          }
          this.processReceiverStatus();
        }
      },
      error: async (error) => {
        if (error.reason) {
          return this.snackBar.open(error.reason, this.translateService.instant('Shared.Close'), { duration: this.snackBarDuration });
        }
        const [causedError, close] = await this.causedErrorCloseTranslations();
        this.snackBar.open(causedError, close, { duration: this.snackBarDuration });
      }
    });
  }

  connectReceivers = () => {
    if (this.receivers.length === 0) this.getReceivers();
    this.socketMP.listenEvent.subscribe((message) => {
      if (message.decodification && message.decodification.status && message.decodification.id === this.monitoringReportID) {
        const rec = this.receivers.find(r => r.apiKey === message.receiver.apiKey)
        rec.monitoringStatus = message.decodification.monitoringStatus;
        rec.status = true;
        if (!message.decodification.monitoringStatus) this.monitoringStateUp = false;
        else this.processReceiverStatus();
      }
    });
  }

  processReceiverStatus = () => {
    // Metodo para verificar si hay almenos una receptora asignada que este sin monitoreo
    this.filteredReceivers = this.receivers.filter(res => !!res.version);
    this.monitoringStateUp = !this.filteredReceivers.find(r => !r.status || !r.monitoringStatus);
  }
  
  clear = () => {
    this.panelChangesSocket.unsubscribe(this.receivers);
    this.receivers = [];
    this.dataSource.data = [];
    this.filteredReceivers = [];
  }

  requestDataToService = (callback) => {
    this.panelChangesSocket.requestDataToService(callback);
  }
}
