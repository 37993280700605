import { Component, HostListener, OnInit } from '@angular/core';
import { ComandService } from '../services/comand.service';

@Component({
  selector: 'app-tech-support',
  templateUrl: './tech-support.component.html',
  styleUrls: ['./tech-support.component.scss']
})
export class TechSupportComponent implements OnInit {
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
      alert('By refreshing this page you may lost all data.');
  }

  constructor(
    private commandService: ComandService
  ) { 
    this.commandService.isFilterEdited = false;
  }

  ngOnInit() {
  }

}
