import { Component, OnInit } from '@angular/core';
import { SendCommandsServiceService } from '../services/send-commands-service.service';
import { ComandService } from '../services/comand.service';
import { ReceiversService } from 'src/app/shared/services/receivers.service';
import { Command } from '../models/command';
import { NewCommandComponent } from '../new-command/new-command.component';
import { ReceiverSelectorComponent } from '../receiver-selector/receiver-selector.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { commandListState } from '../models/constants';
import { TranslateService } from '@ngx-translate/core';

export interface AddedCommand {
  id: number;
  name: string;
  set: any;
  get: any;
  index: number;
  enable: boolean;
}

@Component({
  selector: 'app-comand-selector',
  templateUrl: './comand-selector.component.html',
  styleUrls: ['./comand-selector.component.scss']
})
export class ComandSelectorComponent implements OnInit {
  selectedCommand: Command;
  addedCommandList: AddedCommand[] = [];
  snackBarDuration = 5000;
  sellectAll: boolean = false;

  constructor(
    private sendCommandService: SendCommandsServiceService,
    private translateService: TranslateService,
    public commandService: ComandService,
    public receiversService: ReceiversService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.sendCommandService.getAllCommands();
  }

  onRemoveCommand = (commandID) => {
    this.addedCommandList = this.addedCommandList.filter(c => c.index != commandID);
  }

  onRemoveAllCommands = () => {
    this.addedCommandList = [];
  }

  addReceiverSwichCommands = (name, panID, url, port) => {
    if (!this.addedCommandList) this.addedCommandList = [];
    const index = this.addedCommandList.length;
    if (panID) {
      // Se controla configuracion de PanID de la receptora
      this.addedCommandList.push({
        id: 31,
        name: 'Pan ID - ' + name,
        get: undefined,
        set: panID,
        index,
        enable: true,
      });
    }
    if (url || port) {
      // Se controla configuracion de IP-Puerto de la receptora
      let ip;
      if (port) {
        ip = url ? (url + ':' + port) : port;
      } else {
        ip = url;
      }
      this.addedCommandList.push({
        id: 58,
        name: 'Conexiones a Internet - ' + name,
        get: 0, // Vinculo
        set: {
          ip, // Url - IP:Puerto
          save: true
        },
        index: index +1,
        enable: true
      });
    }
  }

  onMove = (command, offset) => {
    const toIndex = command.index + offset;

    // verify if its out of bounds
    if (command.index < 0 || command.index >= this.addedCommandList.length || toIndex < 0 || toIndex >= this.addedCommandList.length) {
      return;
    }

    // swap elements
    let temp = this.addedCommandList[command.index];
    this.addedCommandList[command.index] = this.addedCommandList[toIndex];
    this.addedCommandList[toIndex] = temp;

    // updated indexes
    this.addedCommandList[command.index].index = command.index;
    this.addedCommandList[toIndex].index = toIndex;
  }

  checkAll = () => {
    this.sellectAll = !this.addedCommandList.find(c => !c.enable);
  }

  selectAllToSend = () => {
    // Sellect all or unsellect all commands to send
    this.sellectAll = !!this.addedCommandList.find(c => !c.enable);
    for (let i = 0; i < this.addedCommandList.length; i++) {
      this.addedCommandList[i].enable = this.sellectAll;
    }
  }

  isEnabledCommand = (id) => {
    return !!this.sendCommandService.allAvailableCommands.find(c => c.id === id);
  }

  getCommandList = () => {
    return this.addedCommandList.sort((a, b) => { if (a.index > b.index) return 1; return -1; });
  }

  onNewCommandClick = () => {
    const dialogRef = this.dialog.open(NewCommandComponent, {
      width: '65%',
      maxWidth: '1000px',
      data: { title: this.translateService.instant('TechSupport.NewCommand'), submitText: this.translateService.instant('TechSupport.AddCommand'), operation: 'add' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.status === 'success') {
          result.command.index = this.addedCommandList.length;
          this.addedCommandList.push(result.command);
          this.checkAll();
        } else if (result.status !== 'cancel') {
          this.snackBar.open(result.message, this.translateService.instant('Shared.Close'), { duration: this.snackBarDuration });
        }
      }
    });
  }

  onEditCommand = (command) => {
    const dialogRef = this.dialog.open(NewCommandComponent, {
      width: '65%',
      maxWidth: '1000px',
      data: { title: this.translateService.instant('TechSupport.NewCommand'), submitText: this.translateService.instant('TechSupport.EditCommand'), operation: 'update', command }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.status === 'success') {
          command.get = result.command.get;
          command.set = result.command.set;
        } else if (result.status !== 'cancel') {
          this.snackBar.open(result.message, this.translateService.instant('Shared.Close'), { duration: this.snackBarDuration });
        }
      }
    });
  }

  addReceiverSwich = () => {
    const dialogRef = this.dialog.open(ReceiverSelectorComponent, {
      width: '25%',
      maxWidth: '600px',
      data: { title: this.translateService.instant('TechSupport.SelectReceptor'), list: this.receiversService.receivers }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.status === 'success') {
          this.addReceiverSwichCommands(result.data.name, result.data.panID, result.data.ip, result.data.receiverEventPort);
          return;
        }

        if (result.status !== 'cancel') {
          this.snackBar.open(result.message, this.translateService.instant('Shared.Close'), { duration: this.snackBarDuration });
        }
      }
    });
  }

  onSend = () => {
    // Filter enabled commands before send
    const filteredList = this.addedCommandList.filter(c => c.enable);
    if (filteredList.length > 0) {
      this.commandService.processCommandListInit(filteredList);
      this.commandService.processConsoleResponseMsg(this.translateService.instant('TechSupport.QueueSend'));
    } else {
      this.snackBar.open(this.translateService.instant('TechSupport.MissingAtLeastCommand'), this.translateService.instant('Shared.Close'), { duration: this.snackBarDuration });
    }
  }

  onStop = () => {
    if (this.addedCommandList.length > 0) {
      this.commandService.processCommandListStop();
      this.commandService.processConsoleResponseMsg(this.translateService.instant('TechSupport.QueueStop'));
    }
  }

  onReload = () => {
    if (this.addedCommandList.length > 0) {
      this.commandService.processCommandListRestart(this.addedCommandList);
      this.commandService.processConsoleResponseMsg(this.translateService.instant('TechSupport.QueueReload'));
    } else {
      this.snackBar.open(this.translateService.instant('TechSupport.MissingAtLeastCommand'), this.translateService.instant('Shared.Close'), { duration: this.snackBarDuration });
    }
  }

  getPlayIcon = () => {
    // play_arrow
    if (this.commandService.state === commandListState.SENDING) {
      return 'pause';
    }
    return 'play_arrow';
  }
}
