<mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
  <ng-container matColumnDef="icon">
    <mat-header-cell class="header-cell" *matHeaderCellDef>
      {{ 'Panel.Type' | translate }}
    </mat-header-cell>

    <mat-cell class="cell" *matCellDef="let row">
      <svg-icon
        alt
        [tooltipDeitres]="buildDeviceImgTitle(row)"
        [tooltipCustomLeft]="-6"
        [tooltipCustomTop]="-120"
        tooltipSize="fit"
        *ngIf="row.modelID != undefined || row.deviceSerial != undefined"
        [src]="buildDeviceIconPath(row)"
        [svgStyle]="{ 'width.px': 15, 'height.px': 20 }"
      >
      </svg-icon>
      <div *ngIf="row.modelID == undefined && row.deviceSerial == undefined">-</div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header="name" class="header-cell">
      {{ 'Panel.Name' | translate }}
    </mat-header-cell>

    <mat-cell class="cell" *matCellDef="let row">
      {{ row.name || '-' }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="zoneID">
    <mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header="zoneID">
      {{ 'Panel.ZoneID' | translate }}
    </mat-header-cell>

    <mat-cell class="cell" *matCellDef="let row">
      {{ row.zoneID || '-' }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="errors">
    <mat-header-cell *matHeaderCellDef class="header-cell">
      {{ 'Panel.Errors' | translate }}
    </mat-header-cell>

    <mat-cell class="cell" *matCellDef="let row">
      <div *ngIf="errorsPresent(row).length">
        <div class="errors-icons-grid">
          <!-- Grid: [IconColumn] [IconColumn] [IconColumn] [IconColumn] -->
          <img
            alt
            *ngFor="let errorType of errorsPresent(row)"
            width="20px"
            [src]="buildImgLink(errorType)"
            [tooltipDeitres]="'Device.Errors.' + errorType | translate"
            [tooltipCustomLeft]="-6"
            [tooltipCustomTop]="-162"
          />
        </div>
      </div>

      <div *ngIf="!errorsPresent(row).length">
        {{ 'AppComponent.NoErrors' | translate }}
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="firmwareVersion">
    <mat-header-cell *matHeaderCellDef mat-sort-header="firmwareVersion" class="header-cell">
      {{ 'Device.Version' | translate }}
    </mat-header-cell>

    <mat-cell class="cell" *matCellDef="let row">
      {{ getRowVersion(row) }}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="observations">
    <mat-header-cell *matHeaderCellDef mat-sort-header="observations" class="header-cell">
      {{ 'Shared.Observations' | translate }}
    </mat-header-cell>

    <mat-cell class="cell" *matCellDef="let row">
      <svg-icon
        *ngIf="row.routingStatus === 'routing'"
        [tooltipDeitres]="'Device.Routing' | translate"
        [tooltipCustomLeft]="-6"
        [tooltipCustomTop]="-155"
        tooltipSize="fit"
        [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"
        src="assets/icons/misc/routing.svg"
        alt
      >
      </svg-icon>
      <div *ngIf="!row.routing">
        {{ '-' }}
      </div>
    </mat-cell>
  </ng-container>

  <mat-header-row
    [style.border-bottom]="'1px solid #F5F6F7'"
    *matHeaderRowDef="displayedColumns; sticky: true"
  ></mat-header-row>

  <mat-row
    *matRowDef="let row; columns: displayedColumns; let i = index"
    [style.border-bottom]="dataSource.length == i + 1 ? '0px solid transparent' : '1px solid #F5F6F7'"
    [style.margin-bottom]="dataSource.length == i + 1 ? '10px' : ''"
  ></mat-row>
</mat-table>
