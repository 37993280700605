import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { UserCredentialsModel } from '../models/user-credentials-model';
import { Router } from '@angular/router';
import { ReceiversService } from 'src/app/shared/services/receivers.service';
import { TranslateService } from '@ngx-translate/core';
import { KeyboardService } from 'src/app/keyboard/services/keyboard.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {
  user: UserCredentialsModel = new UserCredentialsModel();
  failedAuth = false;
  authErrorMsg: string;
  loggingIn = false;
  showPassword = false;

  constructor(
    private readonly loadingBar: LoadingBarService,
    public authService: AuthService,
    private readonly router: Router,
    private readonly receiversService: ReceiversService,
    private readonly translateService: TranslateService,
    private readonly keyboardService: KeyboardService
  ) {}

  login = () => {
    this.loadingBar.useRef().start();
    this.loggingIn = true;

    // Keyboard default language
    this.keyboardService.lang = this.translateService.currentLang;

    this.authService.login(this.user).subscribe({
      next: (data) => {
        if (data.success && data.token) {
          // Provisorio hasta tener otros componentes
          // this.router.navigateByUrl('/users');
          this.router.navigateByUrl('maps');
          this.receiversService.connectReceivers();
        } else {
          this.failedAuth = true;
          this.authErrorMsg = 'Login.AuthFailed';
        }

        this.loadingBar.useRef().complete();
        this.loggingIn = false;
      },
      error: () => {
        this.failedAuth = true;
        this.authErrorMsg = 'Login.ErrorOcurred';

        this.loadingBar.useRef().stop();
        this.loggingIn = false;
      },
    });
  };
}
