import { Injectable } from '@angular/core';
import { CompaniesService } from './companies.service';
import { ContextualMenuType, PermissionType } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class ContextualMenuService {
  currentContextualItem;
  menuPosition;
  permissionAvailableTypes = [PermissionType.Distributor, PermissionType.Company, PermissionType.End_Company];
  languagesAvailableTypes = ['es', 'en', 'pt'];
  currentMenuType = 0;

  constructor(
    public companiesService: CompaniesService
  ) { }
  
  setPermission(value) {
    if (this.currentContextualItem) this.currentContextualItem.expand = false;

    this.currentContextualItem.permission = value;
  }

  onClose() {
    if (this.currentContextualItem) this.currentContextualItem.expand = false;
  }

  onContextalMenu(event, item, value, id) {
    const target = document.getElementById(id);
    this.processContextualMenuPosition(event, target, item);
    if (this.currentContextualItem) this.currentContextualItem.expand = false;
    this.currentContextualItem = item;

    item.expand = value;
  }

  public processContextualMenuPosition(event, target, item, type = 0, offset = 243) {
    this.currentMenuType = type;
    this.currentContextualItem = item;
    const buttonRect = (target as HTMLElement).getBoundingClientRect();
    this.menuPosition = {
        top: buttonRect.bottom +10,
        left: buttonRect.left - offset,
    };

    event.stopPropagation();
  }

  public getMenuContent() {
    switch (this.currentMenuType) {
      case ContextualMenuType.Language:
        return this.languagesAvailableTypes;
      case ContextualMenuType.Receiver: 
        return this.companiesService.currentCompany.receivers?.map(r => r.name) ?? [];
      case ContextualMenuType.Permission: 
      default:
        return this.permissionAvailableTypes;
    }
  }

  public getTranslateKey(typeKey) {
    switch (this.currentMenuType) {
      case ContextualMenuType.Language:
        return 'Settings.Languages.' + typeKey;
      case ContextualMenuType.Receiver:
        return typeKey;
      case ContextualMenuType.Permission: 
      default:
        return 'Settings.UserLevel.' + typeKey;
    }
  }
}
