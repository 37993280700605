<div *ngIf="partitions.length > 0">
  <div *ngFor="let partition of partitions" class="table-border">
    <details style="flex-direction: row; height: max-content">
      <summary>
        <div class="partition-name-style">{{ 'Partition.Label' | translate }}: {{ partition.name }}</div>
      </summary>

      <div *ngIf="partition.devices.length > 0">
        <app-panel-errors-table
          [dataSource]="partition.devices"
          [cameras]="partition.cameraDevices"
        ></app-panel-errors-table>
      </div>

      <div *ngIf="partition.devices.length == 0">
        <div class="body-content-style" style="margin: 0px 0px 20px 34px">
          {{ 'Partition.NoDevicesFound' | translate }}
        </div>
      </div>
    </details>
  </div>
</div>
