import { Component, OnInit, Input } from '@angular/core';
import { KeyboardService } from '../../services/keyboard.service';
import constants from '../../constants';

class LedsState {
  on?: boolean;
  value?: number;
  blinkStatusInterval?: number;
}

@Component({
  selector: 'app-paradox',
  templateUrl: './paradox.component.html',
  styleUrls: ['./paradox.component.scss']
})
export class ParadoxComponent implements OnInit {
  @Input() zoneLedStatus: LedsState[];
  @Input() stateLedStatus: LedsState[];
  constants = constants;

  isPressing = false;
  pressKey = '';
  pressTimeout;
  
  constructor(
    public keyboardService: KeyboardService
  ) {}

  ngOnInit(): void {
  }

  onMouseDown(pressKey, holdKey) {
    this.isPressing = true;
    this.pressKey = pressKey;
    this.pressTimeout = setTimeout(() => {
      if (this.isPressing) {
        this.isPressing = false;
        this.keyboardService.writeKey(holdKey);
        this.pressKey = '';
      }
    }, 1000);
  }

  onMouseUp() {
    if (this.isPressing) {
      this.isPressing = false;
      clearTimeout(this.pressTimeout);
      this.keyboardService.writeKey(this.pressKey);
      this.pressKey = '';
    }
  }
}
