<mat-card>
    <mat-card-header>
      <mat-card-title>
        {{ title | translate}}
      </mat-card-title>
    </mat-card-header>
    <div style="margin: 10px; border-color: primary;">
      <div style="display: flex; overflow-y: auto; max-height: 400px;" class="scrollbar">
        <mat-card-content class="divider">
          <dl class="divided">
            <dt *ngFor="let row of columnLeft">
                <div>
                    {{ (translateKey + row) | translate}}
                </div> 
            </dt>
          </dl>
          <dl class="divided" style="margin-left: 10px;">
            <dt *ngFor="let row of columnRight">
                <div>
                    {{ (translateKey + row) | translate}}
                </div> 
            </dt>
          </dl>
        </mat-card-content>
      </div>
    </div>
</mat-card>
