import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef } from '@angular/material/dialog';
import { PanelErrorStatusService } from 'src/app/tech-support/services/panel-error-status-service';
import { WebappSubscriptionService } from 'src/app/shared/services/webapp-subscription.service';
import { PanelChange, WebppSocketService } from 'src/app/shared/services/webppSocket.service';
import { PanelErrorsComponent } from 'src/app/panel/components/panel-errors/panel-errors.component';
import { PanelSocketService } from 'src/app/shared/services/panelsocket.service';
import { EventService } from 'src/app/panel/services/event.service';
import { searchAvailableErrors } from 'src/app/panel/panel-utills';
import { Panel } from 'src/app/panel/models/panel.model';
import { Partition } from 'src/app/partitions/models/partition';
import { Device } from 'src/app/partitions/models/device';
import constants from '../constants';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { PanelService } from 'src/app/panel/services/panel.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PanelsService {
  panels: Panel[] = [];
  inactivePanels: Panel[] = [];
  panelsCount: number;
  accounts = [];
  dataSource: MatTableDataSource<Panel>;
  showActive = true;
  isLoadingResults = false;
  fetchingPanels = false;

  reportSubscription: any;
  signalReportId = 99;

  errorsDialogRef: MatDialogRef<PanelErrorsComponent>;
  availableBatteryStates = ['disconnected', 'low', 'failed'];

  snackBarDuration = 5000;
  snackBarErrorDuration = 10000;
  accountSelectState = 'fetching-accounts';

  removePanelFromMap;

  constructor(
    private readonly ps: PanelService,
    private readonly authService: AuthService,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
    public loadingBar: LoadingBarService,
    private readonly panelErrorStatusService: PanelErrorStatusService,
    private readonly webappSocket: WebappSubscriptionService,
    private readonly panelSocket: PanelSocketService,
    private readonly eventService: EventService,
    private readonly wsSocket: WebppSocketService
  ) {
    this.setup();
  }

  setup() {
    this.dataSource = new MatTableDataSource(this.panels);
    this.webappSocket.addSubscription(this.handleIncomingMessages, constants.webappSocketTypes.panelVitalsUpdate);
  }

  panelEnableOnFilter(panel, rs) {
    if (rs[0] === 'default') return true;

    return rs.includes(panel.apiKey);
  }

  public collectPanels = async (updateDataSource = false) => {
    if (this.panels?.length > 0) {
      this.panels.forEach((panel: Panel) => {
        if (panel.isRepeating || this.panelErrorStatusService.errorsPresent(panel)) {
          this.panelErrorStatusService.onNewError(panel._id);
        } else {
          this.panelErrorStatusService.onClean(panel._id);
        }
        panel.reloadCounter = 0;
        panel.errors = this.countErrors(panel);
      });

      if (updateDataSource) {
        this.dataSource.data = this.panels;
      }

      const auxAccounts = this.panels.map((panel: Panel) =>
        panel.partitions.map((partition: Partition) => ({
          account: partition.account,
          name: partition.name,
          mac: partition.mac,
        }))
      );
      this.accounts = [].concat(...auxAccounts);
      this.accounts.sort((a, b) => a.account.localeCompare(b.account));
      this.accountSelectState = this.accounts.length > 0 ? 'found-results' : 'no-results-found';
    }
  };

  // Método encargado de pasar al socket un array de apikeys de la empresa para activar la escucha, y luego se suscribe a los mensajes para filtrar
  // los que sean de reporte de señal, y de un equipo el cual esté en pantalla. Una vez cumplidas esas condiciones, envía un evento con la información
  // necesaria al componente signal-strength a través de un servicio.
  filterAndSendSignalReport = async (apiKeys: string[]) => {
    this.panelSocket.initMPListener(apiKeys);
    this.reportSubscription = this.panelSocket.listenEvent.subscribe((message) => {
      if (message.decodification) {
        if (message.decodification.id == this.signalReportId && message.decodification.signalType) {
          this.dataSource.data.forEach((element) => {
            if (element.mac == message.device.mac) {
              Object.keys(element).forEach((key) => {
                if (key.includes(message.decodification.signalType)) {
                  this.eventService.signalUpdatedEvent.emit({
                    signal: message.decodification.param,
                    signalType: message.decodification.signalType,
                    mac: message.device.mac,
                  });
                }
              });
            }
          });
        }
      }
    });
  };

  handleIncomingMessages = (message: PanelChange) => {
    const panel = this.dataSource.data.find((p) => p.mac === message.mac);
    if (panel) {
      panel.reloadCounter += 1;

      if (this.errorsDialogRef) {
        this.errorsDialogRef.componentInstance.perform();
      } else {
        this.panelErrorStatusService.onNewError(panel._id);
      }
    }
  };

  countErrors = (panel: Panel): number => {
    const panelErrorsCount = this.countVitalsErrors(panel);

    const devicesErrorsCount = panel.partitions.map((p: Partition) =>
      p.devices.map((d: Device) => this.errorsPresentDevice(d))
    );

    const initialValue = 0;
    const devicesErrorsAccumulators = devicesErrorsCount.map((arr) =>
      arr.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue)
    );

    const devicesFinalErrorsCount = devicesErrorsAccumulators.reduce((acc, curr) => acc + curr, initialValue);

    return panelErrorsCount + devicesFinalErrorsCount;
  };

  countVitalsErrors = (element: Panel) => {
    let errors = 0;

    if (this.availableBatteryStates.includes((element.vitals?.batteryStatus || 'normal').toString())) errors++;
    if ((element.vitals?.energyStatus ?? '').toString() == 'false') errors++;
    if (element.vitals?.tampered) errors++;

    return errors;
  };

  errorsPresentDevice(element: Device) {
    return searchAvailableErrors(element).length;
  }

  clear = () => {
    this.panels = [];
    this.inactivePanels = [];
    this.panelsCount = undefined;
    this.accounts = [];
    this.dataSource.filter = undefined;
    this.showActive = true;
    this.dataSource.data = [];
  };

  getPanels = async () => {
    this.isLoadingResults = true;

    this.wsSocket.requestDataToService(() => {
      if (this.fetchingPanels) return;

      this.fetchingPanels = true;
      this.ps.getPanels(true).subscribe({
        next: (res: any) => {
          this.panelsCount = res.count;
          this.loadingBar.useRef().start();
        },
        error: (error) => {
          this.processError(error);
        },
      });
    });
  };

  processError = async (error) => {
    this.isLoadingResults = false;

    if (error && error.status == 401) {
      this.snackBar.open(
        await firstValueFrom(this.translateService.get('Shared.SessionExpired')),
        await firstValueFrom(this.translateService.get('Shared.Close')),
        { duration: this.snackBarErrorDuration }
      );
      this.authService.logout();
    } else {
      this.snackBar.open(
        await firstValueFrom(this.translateService.get('Panel.FailedGetDev')),
        await firstValueFrom(this.translateService.get('Shared.Close')),
        { duration: this.snackBarErrorDuration }
      );
    }
  };

  getPanel = async (mac) => {
    const panel = this.panels.find((p) => p.mac === mac);
    if (panel) {
      return panel;
    } else {
      this.ps.getPanel(undefined, mac).subscribe({
        next: (res: any) => {
          if (res.success) {
            return res.panel;
          }
        },
        error: (error) => {
          return undefined;
        },
      });
    }
  };

  removePanel = (mac) => {
    const panel = this.panels.find((p) => mac === p.mac);
    this.inactivePanels.push({ ...panel, active: false });

    this.panels = this.panels.filter((p) => mac !== p.mac);
    this.panelsCount = this.panelsCount - 1;

    this.dataSource.data = this.panels;

    if (this.removePanelFromMap) this.removePanelFromMap(panel);
  };

  toggleActive = () => {
    this.showActive = !this.showActive;
  };
}
