import { Injectable } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Partition } from 'src/app/partitions/models/partition';
import { ComandService } from 'src/app/tech-support/services/comand.service';
import { WebappSubscriptionService } from 'src/app/shared/services/webapp-subscription.service';
import { WebppSocketService } from 'src/app/shared/services/webppSocket.service';
import constants from '../constants';
import { ReceiversService } from './receivers.service';

@Injectable({
  providedIn: 'root',
})
export class PartitionsService {
  partitions: Partition[] = [];
  dataSource: MatTableDataSource<Partition>;
  isLoadingResults = false;
  editingPartitionId: string;

  constructor(
    private readonly commandService: ComandService,
    public loadingBar: LoadingBarService,
    private readonly webappSocket: WebappSubscriptionService,
    private readonly wsSocket: WebppSocketService,
    private readonly receiverService: ReceiversService
  ) {
    this.setup();
  }
  setup() {
    this.dataSource = new MatTableDataSource(this.partitions);
    this.webappSocket.addSubscription(this.handleNewPartitions, constants.webappSocketTypes.partitionsListPackage);
  }

  partitionEnableOnFilter(partition) {
    if (this.receiverService.selectedReceiver[0] === 'default') return true;

    if (partition.apiKey) {
      return this.receiverService.selectedReceiver.includes(partition.apiKey);
    }
    const companies = this.receiverService.receivers.reduce((acc, r) => {
      if (this.receiverService.selectedReceiver.includes(r.apiKey)) acc.push(r.company);
      return acc;
    }, []);
    return companies.includes(partition.company);
  }

  updateSelectedToSupport() {
    this.dataSource.filteredData = this.dataSource.filteredData.map((panel) => {
      panel.selectedToSupport = !!this.commandService.selectedPanels.find((p) => p.mac === panel.mac);
      return panel;
    });
  }

  public handleNewPartitions = (message) => {
    if (message.finish) {
      this.isLoadingResults = false;
      this.loadingBar.useRef().complete();
      if (this.receiverService.selectedReceiver.length > 0) {
        this.filterReceiver();
      }
    } else {
      this.isLoadingResults = true;
      this.partitions = [
        ...this.partitions,
        ...message.partitions.reduce((acc, partition: any) => {
          const p = this.partitions.find((p) => p._id === partition._id);
          if (!p) {
            const auxPartition = { ...partition };
            auxPartition.selectedToSupport = this.commandService.selectedPanels.find((p) => p.mac === partition.mac);
            acc.push(auxPartition);
          } else {
            const keys = Object.keys(partition);
            keys.forEach((k) => (p[k] = partition[k]));
          }
          return acc;
        }, []),
      ];
      this.dataSource.data = this.partitions;
    }
  };

  filterReceiver() {
    const selectedReceivers = this.receiverService.receivers
      .filter((r) => this.receiverService.selectedReceiver.includes(r.apiKey))
      .map((r) => r.company);

    const filteredData = this.partitions.filter((p) => selectedReceivers.includes(p.company));

    this.dataSource.data = filteredData.length > 0 ? [...filteredData] : [...this.partitions];
  }

  clear = () => {
    this.partitions = [];
    this.dataSource.filter = undefined;
    this.dataSource.data = [];
  };

  requestPartitions = (callback) => {
    this.wsSocket.requestDataToService(callback);
  };

  setKeyboard = (mac, keyboard) => {
    this.partitions.forEach((p) => {
      if (p.mac === mac) {
        p.keyboard = keyboard;
      }
    });
    this.dataSource.data = this.partitions;
  };

  removePanel = (mac) => {
    this.partitions = this.partitions.filter((p) => p.mac != mac);
    this.dataSource.data = this.partitions;
  };
}
