import { Component, Inject, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PanelService } from '../../services/panel.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { PanelInfoComponent } from '../panel-info/panel-info.component';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../auth/services/auth.service';
import { Partition } from 'src/app/partitions/models/partition';
import { Panel } from '../../models/panel.model';
import { PanelErrorStatusService } from 'src/app/tech-support/services/panel-error-status-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';

export interface DialogData {
  id: string;
  onDestroy: (id: string) => void;
}

export interface IVitals {
  batteryStatus: string;
  energyStatus: boolean;
  tampered: boolean;
}

@Component({
  selector: 'app-panel-errors',
  templateUrl: './panel-errors.component.html',
  styleUrls: ['./panel-errors.component.scss'],
})
export class PanelErrorsComponent implements OnInit {
  vitals: IVitals = {
    batteryStatus: null,
    energyStatus: null,
    tampered: null,
  };
  partitions: Partition[];
  panel: Panel;
  deviceErrorsCheck = false;
  dissarmClose = false;
  isCommunicator = false;
  fetchControlAvailable = true;
  pendingFetch = false;

  constructor(
    private readonly ps: PanelService,
    private readonly authService: AuthService,
    private readonly loadingBar: LoadingBarService,
    private readonly translateService: TranslateService,
    public panelErrorStatusService: PanelErrorStatusService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private readonly dialogRef: MatDialogRef<PanelInfoComponent>,
    private readonly snackBar: MatSnackBar,
    private readonly toastrService: ToastrService
  ) {
    this.ps = ps;
    this.dialogRef = dialogRef;
    this.loadingBar = loadingBar;
    this.ps.checkZoneBattery(this.data.id).subscribe();
  }

  onClose() {
    this.dialogRef.close();
    this.data.onDestroy(this.data.id);
  }

  panelTitle() {
    if (this.isCommunicator) {
      return this.translateService.instant('Panel.CommunicatorPanel');
    }

    return this.translateService.instant('Panel.Label');
  }

  ngOnDestroy(): void {
    this.data.onDestroy(this.data.id);
  }

  ngOnInit(): void {
    this.perform();

    if (this.panel?.isRepeating) {
      this.toastrService.warning(
        this.translateService.instant('Panel.DeviceRepeating'),
        this.translateService.instant('Panel.MessagesRepeated'),
        {
          timeOut: 15_000,
          disableTimeOut: true,
          closeButton: true,
          easing: 'ease',
          easeTime: 300,
          tapToDismiss: true,
          newestOnTop: true,
        }
      );
    }
  }

  fetch(): void {
    if (this.fetchControlAvailable) {
      this.fetchControlAvailable = false;
      this.perform();

      setTimeout(() => {
        this.fetchControlAvailable = true;

        if (this.pendingFetch) {
          this.perform();
        }

        this.pendingFetch = false;
      }, 10000);
    } else {
      this.pendingFetch = true;
    }
  }

  perform(): void {
    this.dialogRef.backdropClick().subscribe(() => {
      this.onClose();
    });

    this.loadingBar.useRef().start();

    this.ps.getPanel(this.data.id, undefined).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.processPanel(res);
        } else {
          this.showWarning('Panel.FailedGetDev', 'error');
          this.showSnackBar('Panel.FailedGetDev');

          this.loadingBar.useRef().stop();
        }
      },
      error: (error) => {
        this.loadingBar.useRef().stop();

        if (error && error.status == 401) {
          this.showWarning('Shared.SessionExpired', 'error');
          this.authService.logout();
        } else {
          this.showWarning('Panel.FailedGetDev', 'error');
        }
      },
    });
  }

  processPanel = (res) => {
    this.isCommunicator = res.panel.isCommunicator;
    this.panel = res.panel;
    this.vitals = res.panel.vitals;
    this.loadingBar.useRef().complete();
    this.loadingBar.useRef().stop();
    if (!this.partitions) {
      this.partitions = res.panel.partitions;
    } else {
      /**
       * This code here updates the values of the properties of the partition.devices and partition.cameraDevices
       */
      res.panel.partitions.forEach((p: Partition) => {
        const partition = this.partitions.find((currPart: Partition) => p.account === currPart.account);
        if (Array.isArray(p?.devices)) {
          p.devices.forEach((d) => {
            const device = partition.devices.find((currDev) => currDev.zoneID === d.zoneID);
            const keys = Object.keys(d);
            for (const key of keys) {
              device[key] = d[key];
            }
          });
        }
        if (Array.isArray(p?.cameraDevices)) {
          p.cameraDevices.forEach((d) => {
            const cameraDevice = partition.cameraDevices.find((currDev) => currDev.zoneID === d.zoneID);
            const keys = Object.keys(d);
            for (const key of keys) {
              cameraDevice[key] = d[key];
            }
          });
        }
      });
    }
  };

  showWarning = async (message, type) => {
    this.dialogRef.close({
      status: type,
      message: await lastValueFrom(this.translateService.get(message)),
    });
  };

  showSnackBar = async (message) => {
    this.snackBar.open(
      await lastValueFrom(this.translateService.get(message)),
      await lastValueFrom(this.translateService.get('Shared.Close')),
      { duration: 5000 }
    );
  };
}
