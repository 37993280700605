<div *ngIf="this.data.type === 'register'">
  <div class="title-bar">
    <span class="mat-tab">
      {{ 'Keyboard.Partition' | translate }}
    </span>
  </div>
  <div class="border-title-bar">
  </div>
  <app-panel-dialog-partition (cancelClicked)="onCancelClick()"></app-panel-dialog-partition>
</div>
<div *ngIf="this.data.type === 'notifications'">
  <div class="notifications-content">
    <mat-tab-group (selectedTabChange)="onTabChange($event)">
      <mat-tab [label]="'Partition.NotifyPartitions' | translate">
        <div class="notifications-div">
          <form name="f" (ngSubmit)="submitNotifyPartitions()" novalidate>
            <div>
              <div fxLayout="column" fxLayoutGap="10px">
                <div fxFlex>
                  <mat-form-field>
                    <mat-label>{{ 'Notification.NoteTitle' | translate }}</mat-label>
                    <textarea
                      [(ngModel)]="data.titleNote"
                      matInput
                      name="note"
                      matInput
                      placeholder="{{ 'Notification.WriteTitle' | translate }}"
                      maxlength="64"
                      cdkTextareaAutosize
                      cdkAutosizeMaxRows="2"
                      #note="ngModel"
                    ></textarea>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field>
                    <mat-label>{{ 'Notification.Note' | translate }}</mat-label>
                    <textarea
                      #note="ngModel"
                      [(ngModel)]="data.note"
                      name="note"
                      matInput
                      placeholder="{{ 'Notification.WriteNote' | translate }}"
                      maxlength="600"
                      cdkTextareaAutosize
                      cdkAutosizeMaxRows="10"
                    ></textarea>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <mat-dialog-actions>
              <button *ngIf="isLoading === false" mat-raised-button color="primary" [disabled]="validateField()">
                {{ 'Shared.Send' | translate }}
              </button>
              <div class="loading-spinner" *ngIf="isLoading === true"><mat-spinner diameter="20"></mat-spinner></div>
              <button type="button" mat-raised-button (click)="onCancelClick()">
                {{ 'Shared.Cancel' | translate }}
              </button>
            </mat-dialog-actions>
          </form>
        </div>
      </mat-tab>
      <mat-tab [label]="'User.NotifyUsers' | translate">
        <div class="notifications-div">
          <form name="f" (ngSubmit)="submitNotifyUsers()" novalidate>
            <div>
              <div fxLayout="column" fxLayoutGap="5px">
                <div fxFlex>
                  <mat-form-field>
                    <mat-label>{{ 'Notification.NoteTitle' | translate }}</mat-label>
                    <textarea
                      [(ngModel)]="data.titleNote"
                      matInput
                      name="note"
                      matInput
                      placeholder="{{ 'Notification.WriteTitle' | translate }}"
                      maxlength="64"
                      cdkTextareaAutosize
                      cdkAutosizeMaxRows="2"
                      #note="ngModel"
                    ></textarea>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field>
                    <mat-label>{{ 'Notification.Note' | translate }}</mat-label>
                    <textarea
                      #note="ngModel"
                      [(ngModel)]="data.note"
                      name="note"
                      matInput
                      placeholder="{{ 'Notification.WriteNote' | translate }}"
                      maxlength="600"
                      cdkTextareaAutosize
                      cdkAutosizeMaxRows="10"
                    ></textarea>
                  </mat-form-field>
                </div>
                <div fxFlex>
                  <section>
                    <mat-checkbox
                      color="primary"
                      style="margin-right: 30px"
                      [checked]="data.final"
                      type="checkbox"
                      title="{{ 'Notification.Final' | translate }}"
                      (change)="sendToFinal()"
                    >
                      {{ 'Notification.Final' | translate }}
                    </mat-checkbox>
                    <mat-checkbox
                      color="primary"
                      style="margin-right: 30px"
                      [checked]="data.technician"
                      type="checkbox"
                      title="{{ 'Notification.Tech' | translate }}"
                      (change)="sendToTechnician()"
                    >
                      {{ 'Notification.Tech' | translate }}
                    </mat-checkbox>
                  </section>
                </div>
              </div>
            </div>
            <mat-dialog-actions>
              <button *ngIf="isLoading === false" mat-raised-button color="primary" [disabled]="validateField(true)">
                {{ 'Shared.Send' | translate }}
              </button>
              <div class="loading-spinner" *ngIf="isLoading === true"><mat-spinner diameter="20"></mat-spinner></div>
              <button type="button" mat-raised-button (click)="onCancelClick()">
                {{ 'Shared.Cancel' | translate }}
              </button>
            </mat-dialog-actions>
          </form>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
