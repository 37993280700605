export const sortedEventsList = [
  { topic: 'fire', text: 'SortedEvent.Fire' },
  { topic: 'panic', text: 'SortedEvent.Panic' },
  { topic: 'tamper', text: 'SortedEvent.Tamper' },
  { topic: 'alarmTrigger', text: 'SortedEvent.AlarmTrigger' },
  { topic: 'expanderModule', text: 'SortedEvent.ExpModuleFailure' },
  { topic: 'zoneTamper', text: 'SortedEvent.PanelZoneTamper' },
  { topic: 'silentTheft', text: 'SortedEvent.SilentRobbery' },
  { topic: 'generalAlarm', text: 'SortedEvent.GeneralAlarm' },
  { topic: 'highTemperature', text: 'SortedEvent.HighTemp' },
  { topic: 'lowTemperature', text: 'SortedEvent.LowTemp' },
  { topic: 'hs24aux', text: 'SortedEvent.Zone24H' },
  { topic: 'medic', text: 'SortedEvent.MedEmergency' },
  { topic: 'sirenFailure', text: 'SortedEvent.SirenFailure'},
  { topic: 'zoneEnergyProblem', text: 'SortedEvent.ZoneEnergyProblem' },
  { topic: 'comunicationProblem', text: 'SortedEvent.ComFailure' },
  { topic: 'zoneLowBattery', text: 'SortedEvent.ZoneLowBattery' },
  { topic: 'sensorFailure', text: 'SortedEvent.SenFailure' },
  { topic: 'energyProblem', text: 'SortedEvent.EnergyConLost' },
  { topic: 'lowBattery', text: 'SortedEvent.LowBattery' },
  { topic: 'systemShutdown', text: 'SortedEvent.SystReset' },
  { topic: 'systemProblem', text: 'SortedEvent.SystProblem' },
  { topic: 'armedStay', text: 'SortedEvent.ArmedStay' },
  { topic: 'resetPanicAlarm', text: 'SortedEvent.PanicReset' },
  { topic: 'userArmed', text: 'SortedEvent.ArmedByUser' },
  { topic: 'userDisarmed', text: 'SortedEvent.DisByUser' },
  { topic: 'groupArmed', text: 'SortedEvent.ArmedByGroup' },
  { topic: 'groupDisarmed', text: 'SortedEvent.DisarmedByGroup' },
  { topic: 'automaticArmed', text: 'SortedEvent.AutoArming' },
  { topic: 'automaticDisarmed', text: 'SortedEvent.AutoDis' },
  { topic: 'remoteArmed', text: 'SortedEvent.RemoteArmed' },
  { topic: 'remoteDisarmed', text: 'SortedEvent.RemoteDis' },
  { topic: 'quickArmed', text: 'SortedEvent.QuickArmed' },
  { topic: 'quickDisarmed', text: 'SortedEvent.QuickDis' },
  { topic: 'keyswitchArmed', text: 'SortedEvent.ArmedKeys' },
  { topic: 'keyswitchDisarmed', text: 'SortedEvent.DisKeys' },
  { topic: 'armed', text: 'SortedEvent.Armed' },
  { topic: 'disarmed', text: 'SortedEvent.Disarmed' },
  { topic: 'bypassedZone', text: 'SortedEvent.CancelZone' },
  { topic: 'panelTest', text: 'SortedEvent.PanelTest' },
  { topic: 'panelTestError', text: 'SortedEvent.PanelTestError' },
  { topic: 'trouble', text: 'SortedEvent.Trouble' }
];
