import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  operation: string;
  submitText: string;
  promptText: string;
  id: string;
  type: string;
}

@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss'],
})
export class GenericDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<GenericDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private readonly dialog: MatDialog
  ) {}
  closeModal() {
    if (this.data.type == 'changesSuccessfully') {
      return this.dialog.closeAll();
    }
    this.dialogRef.close();
  }
  closeAllModals() {
    this.dialog.closeAll();
  }
}
