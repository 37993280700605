import { Component, OnInit, Inject } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Panel } from '../../models/panel.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { PanelService } from '../../services/panel.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

export interface DialogData {
  id: string;
}

@Component({
  selector: 'app-panel-info',
  templateUrl: './panel-info.component.html',
  styleUrls: []
})
export class PanelInfoComponent implements OnInit {
  isLoading = false;
  panel: Panel = new Panel();

  constructor(
    private readonly ps: PanelService,
    private readonly dialogRef: MatDialogRef<PanelInfoComponent>,
    private readonly loadingBar: LoadingBarService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    public authService: AuthService,
    private readonly translateService: TranslateService
  ) {}
  
  ngOnInit() {
    this.dialogRef.disableClose = true;

    this.dialogRef.backdropClick().subscribe(() => {
      this.onClose();
    });

    this.loadingBar.useRef().start();

    this.ps.getPanel(this.data.id, undefined).subscribe({
      next: (res: any) => {
        if (res.success) {
          res.panel.type = this.parsePanelType(res.panel.type);

          this.panel = { 
            ...res.panel,
            ethSignal: res.panel.ethSignal != -1 ? res.panel.ethSignal : 0,
            meshSignal: res.panel.meshSignal != -1 ? res.panel.meshSignal : 0,
            mobileSignal: res.panel.mobileSignal != -1 ? res.panel.mobileSignal : 0,
            wifiSignal: res.panel.wifiSignal != -1 ? res.panel.wifiSignal : 0,
          };
          this.loadingBar.useRef().complete();
        } else {
          this.showWarning('Shared.FailedGetDev', 'error');
          this.loadingBar.useRef().stop();
        }
      },
      error: (error) => {
        this.loadingBar.useRef().stop();

        if (error && error.status == 401) {
          this.showWarning('Shared.SessionExpired', 'error');
          this.authService.logout();
        } else {
          this.showWarning('Panel.FailedGetDev', 'error');
        }
      }
    });
  }
  
  parsePanelType = (type) => {
    if (type === 'citypanel') {
      return 'City Panel';
    } else {
      return (type === 'city8') ? 'City 8' : 'City 7';
    }
  }

  showWarning = async (message, type) => {
    this.dialogRef.close({
      status: type,
      message: await lastValueFrom(this.translateService.get(message)),
    });
  }
  
  onClose() {
    this.dialogRef.close();
  }

}
