import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { SocketService } from 'src/app/services/socket.service';
import { Receiver } from 'src/app/shared/models/receiver-model';

export interface PanelChange {
  mac: string;
  type: string;
}

@Injectable({
  providedIn: 'root',
})
export class WebppSocketService {
  listenEvent = this.socket.fromEvent<PanelChange>('message');
  connEvent = this.socket.fromEvent('connect');
  dcEvent = this.socket.fromEvent('disconnect');

  isHooked: boolean;
  connected: boolean;
  callbackList = [];

  constructor(private readonly socket: SocketService, private readonly authService: AuthService) {
    this.connected = false;
    this.dcEvent.subscribe(() => {
      this.isHooked = false;
    });
  }

  connect(receivers) {
    if (receivers.length > 0) {
      this.socket.emit('join', this.authService.user._id);
      receivers.forEach((receiver: Receiver) => {
        this.socket.emit('join', receiver.apiKey);
      });
      this.connected = true;

      if (this.callbackList.length > 0) {
        this.callbackList.forEach((cb) => {
          cb();
        });
      }
    }
  }

  unsubscribe(receivers = []) {
    receivers.forEach((receiver: Receiver) => {
      this.socket.emit('leave', receiver.apiKey);
    });
  }

  close() {
    this.socket.removeAllListeners();
    this.socket.disconnect();
    this.connected = false;
  }

  requestDataToService = (callback) => {
    if (this.connected) {
      callback();
    } else {
      this.callbackList.push(callback);
    }
  };
}
