import { UsersService } from './../../services/users.service';
import { UserApp } from './../../models/user-app';
import { Component, Inject } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DialogData } from '../user-form/user-form.component';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent {

  isLoading = false;
  user: UserApp = new UserApp();
  displayedColumns: string[];
  dataSource: MatTableDataSource<any>;
  chatbotAdministrador: boolean;

  constructor(
    public authService: AuthService,
    private dialogRef: MatDialogRef<UserInfoComponent>,
    private loadingBar: LoadingBarService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    private abm: UsersService
  ) {
    this.chatbotAdministrador = this.authService.user.chatbotAdministrador;
    this.displayedColumns = ['name', 'account', 'userCode', 'mac'];
    dialogRef.disableClose = true;

    dialogRef.backdropClick().subscribe(() => this.onCloseClick());
    this.loadingBar.useRef().start();
    this.abm.getUser(this.data.id).subscribe({
      next: async (res: any) => {
        if (res.success) {
          this.user = {...res.user};
          this.user.secureAccessTime = this.user.secureAccessTime ? this.user.secureAccessTime : 0;
          this.user.secureAccessWarning = this.user.secureAccessWarning ? this.user.secureAccessWarning : undefined;
          if (this.user.partitions && this.user.partitions.length > 0) {
            this.user.partitions.sort((a , b) =>  {
              if (a.partition.name > b.partition.name) {
                return +1;
              }
              if (a.partition.name < b.partition.name) {
                return -1;
              }
              return 0;
            });
            this.dataSource = this.dataSource = new MatTableDataSource(this.user.partitions);
          }
          this.loadingBar.useRef().complete();
        } else {
          this.dialogRef.close({ status: 'error', message: await this.translateService.get('User.ErrorGetUser').toPromise() });
          this.loadingBar.useRef().stop();
        }
      },
      error: async (error) => {
        this.loadingBar.useRef().stop();

        if (error && error.status === 401) {
          this.dialogRef.close({ status: 'error', message: await this.translateService.get('Shared.SessionExpired').toPromise() });
          this.authService.logout();
        } else {
          this.dialogRef.close({ status: 'error', message: await this.translateService.get('User.ErrorGetUser').toPromise() });
        }
      }
    });
  }

  onCloseClick = () => {
    this.dialogRef.close({ status: 'cancel' });
  }

}
