import { Component, OnInit } from '@angular/core';
import { ContextualMenuService } from '../../services/contextual-menu.service';
import { CompaniesService } from '../../services/companies.service';
import { Sections, Steps } from '../../constants';
import { CompanyUserService } from '../../services/company-user.service';
import constants from 'src/app/shared/constants';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  public steps = Steps;
  public sections = Sections;
  subscription;

  constructor(
    public authService: AuthService,
    public companiesService: CompaniesService,
    public contextualMenuService: ContextualMenuService,
    private readonly companyUserService: CompanyUserService,
    private readonly translateService: TranslateService,
    private readonly snackBar: MatSnackBar,
  ) { 
    this.companiesService.onReset();
  }

  ngOnInit(): void {
    this.onGetCompanies();

    this.subscription = this.companiesService.refetchCompanies$.subscribe((value) => {
      if (value) this.onGetCompanies();
    });
  }

  onGetCompanies() {
    this.companiesService.loading = true;
    this.companyUserService.getCompanies().subscribe({
      next: (res: any) => {
        this.companiesService.loading = false;
        this.companiesService.companies = res.company ?? [];
      },
      error: (error) => {
        this.companiesService.loading = false;
        this.companiesService.companies = error.error.company ?? [];

        if (error?.status === 401) {
          this.snackBar.open(
            this.translateService.instant('Shared.SessionExpired'),
            this.translateService.instant('Shared.Close'),

            { duration: constants.snackBarErrorDuration }
          );
          this.authService.logout();
        } else {
          this.snackBar.open(
            this.translateService.instant('Settings.Error.NoGetCompany'),
            this.translateService.instant('Shared.Close'),

            { duration: constants.snackBarErrorDuration }
          );
        }
      },
    });
  }

  newCompany() {
    this.companiesService.currentCompany = undefined;
    this.companiesService.onCreate();
  }

  checkSection(section) {
    return section === this.companiesService.currentSection;
  }
  
  onClickOut(event: MouseEvent) {
    if (event.target === event.currentTarget) {
      this.companiesService.setBackToMainEvent(true);
    }
  }
}
