import { Partition } from './../models/partition';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from './../../auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class PartitionsService {
  private readonly uriPartition = `${environment.webappEndpoint}/partition`;
  private readonly uriUser = `${environment.webappEndpoint}/user`;

  constructor(private readonly http: HttpClient, private readonly auth: AuthService) {}

  notifyPartition = async (notification: object, partitionToNotify: string, users: string, company: string) => {
    return this.http.post(
      `${this.uriPartition}/notify`,
      { notification, partitionToNotify, users, company },
      { headers: { Authorization: `Bearer ${this.auth.getToken()}` } }
    );
  };

  addPartition = (partition: Partition) => {
    let selectedLanguage = this.auth.selectedLanguage;
    return this.http.post(
      `${this.uriPartition}/create`,
      { partition, selectedLanguage },
      { headers: { Authorization: `Bearer ${this.auth.getToken()}` } }
    );
  };

  modifyPartition = (partition: Partition) => {
    let selectedLanguage = this.auth.selectedLanguage;
    return this.http.post(
      `${this.uriPartition}/update`,
      { partition, selectedLanguage },
      { headers: { Authorization: `Bearer ${this.auth.getToken()}` } }
    );
  };

  addUserToPartition = (user: string, partition: string, userCode: string) => {
    return this.http.post(
      `${this.uriPartition}/addUser`,
      { user, partition, userCode, company: this.auth.getPrimaryCompany()._id },
      { headers: { Authorization: `Bearer ${this.auth.getToken()}` } }
    );
  };

  activePartition = (id: string, active: boolean) => {
    let selectedLanguage = this.auth.selectedLanguage;
    return this.http.post(
      `${this.uriPartition}/active`,
      { id, active, selectedLanguage },
      { headers: { Authorization: `Bearer ${this.auth.getToken()}` } }
    );
  };

  removeUserFromPartition = (user: string, partition: string) => {
    return this.http.post(
      `${this.uriPartition}/removeUser`,
      { user, partition, company: this.auth.getPrimaryCompany()._id },
      { headers: { Authorization: `Bearer ${this.auth.getToken()}` } }
    );
  };

  deletePartition = (partition: string) => {
    let selectedLanguage = this.auth.selectedLanguage;
    return this.http.post(
      `${this.uriPartition}/delete`,
      { partition, company: this.auth.getPrimaryCompany()._id, selectedLanguage },
      { headers: { Authorization: `Bearer ${this.auth.getToken()}` } }
    );
  };

  getPartitions = () => {
    return this.http.get(`${this.uriPartition}/getAll`, {
      headers: { Authorization: `Bearer ${this.auth.getToken()}` },
    });
  };

  getPartition = (partition: string) => {
    return this.http.get(`${this.uriPartition}/get/${partition}`, {
      headers: { Authorization: `Bearer ${this.auth.getToken()}` },
    });
  };

  getUsersAvailable = (partition) => {
    if (partition) {
      return this.http.get(`${this.uriUser}/getUnattached/${partition}`, {
        headers: { Authorization: `Bearer ${this.auth.getToken()}` },
      });
    }
    const params = { type: 'final' };
    return this.http.get(`${this.uriUser}/getAll`, {
      params,
      headers: { Authorization: `Bearer ${this.auth.getToken()}` },
    });
  };

  alarmTrigger = (partition, sound) => {
    let selectedLanguage = this.auth.selectedLanguage;

    if (partition) {
      return this.http.post(
        `${this.uriPartition}/alarmTrigger`,
        {
          partitionId: partition._id,
          sound,
          macId: partition.mac,
          companyId: this.auth.getPrimaryCompany()._id,
          selectedLanguage,
        },
        { headers: { Authorization: `Bearer ${this.auth.getToken()}` } }
      );
    }
  };
}
