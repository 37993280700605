import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { PanelsService } from 'src/app/shared/services/panels.service';
import { ReceiversService } from 'src/app/shared/services/receivers.service';
import { EventService } from 'src/app/panel/services/event.service';
import { BreadcrumbedReceptor, BreadCrumberChannelTypes } from 'src/app/panel/models/event.model';
import { PanelStatusService } from '../../service/panel-status.service';
import { HoverMenuesService } from '../../../hover-menues/services/hover-menues.service';
import constants from '../../constants';
import mapIcons from '../../icons';

enum DeviceTypes {
  typeInterior = 'typeInterior',
  typeExteriorLow = 'typeExteriorLow',
  typeExteriorHigh = 'typeExteriorHigh',
  typeRF = 'typeRF',
  typeWIFI = 'typeWIFI',
  typeMobile = 'typeMobile',
  typeFullInternet = 'typeFullInternet',
  typeMeshDevice = 'typeMeshDevice',
  typeGateway = 'typeGateway',
  typeLinkingDisconnected = 'typeLinkingDisconnected',
  typeLinkingNull = 'typeLinkingNull',
  typeLinkingLow = 'typeLinkingLow',
  typeLinkingGood = 'typeLinkingGood',
  typeLinkingOptimum = 'typeLinkingOptimum',
}

enum LinkTypes {
  linkRF = 'linkRF',
  linkWifiEth = 'linkWifiEth',
  linkCelular = 'linkCelular',
  linkFull = 'linkFull',
}

enum ColorTypes {
  gray = 'gray',
  wine = 'wine',
  null = 'null',
  low = 'low',
  good = 'good',
  optimum = 'optimum',
}

@Component({
  selector: 'app-mesh-status',
  templateUrl: './mesh-status.component.html',
  styleUrls: ['./mesh-status.component.scss'],
})
export class MeshStatusComponent implements OnInit {
  showLegends;

  filters: string[] = [constants.viewTypes.None, constants.viewTypes.ViewLinking];
  glossary = {};
  myTypes = {};

  constants = constants;

  mapFilters: string[] = [];

  installationTypeFilter: string[] = [];
  conectivityTypeFilter: string[] = [];

  conditionTranslation = '';
  languageChangeSub;
  receiverFilterChangeSub;

  constructor(
    public panelStatus: PanelStatusService,
    public panelsService: PanelsService,
    public hoverMenuesService: HoverMenuesService,
    private readonly translateService: TranslateService,
    private readonly sanitizer: DomSanitizer,
    private readonly receiversService: ReceiversService,
    private readonly eventService: EventService
  ) {}

  ngOnInit(): void {
    this.receiverFilterChangeSub = this.eventService.breadcrumbedReceptor.subscribe((signalParams: BreadcrumbedReceptor) => {
      if (signalParams?.type === BreadCrumberChannelTypes.NEWFILTER) {
        this.translateLabels();
      }
    });
    this.languageChangeSub = this.translateService.onLangChange.subscribe((_event: Event) => {
      this.translateLabels();
    });
    this.translateLabels();
    this.receiversService.onReceiversCallback.push(() => this.translateLabels());

    this.showLegends = false;
    this.clearFilters();

    this.glossary = this.startGlossary();

    this.panelStatus.processMarkIcons();
  }

  ngOnDestroy(): void {
    this.languageChangeSub?.unsubscribe();
    this.receiverFilterChangeSub?.unsubscribe();
  }

  translateLabels(): void {
    let lowControl = 6;
    let goodControl = 10;
    let edited = false;

    let receivers = this.receiversService.receivers;
    if (this.receiversService.selectedReceiver.length > 0 && this.receiversService.selectedReceiver[0] != constants.defaultReceiver) {
      receivers = receivers.filter(r => this.receiversService.selectedReceiver.includes(r.apiKey));
    }

    for (let receiver of receivers) {
      if (!edited) {
        edited = true;
        lowControl = receiver?.testPHourConfiguration.lowControl;
        goodControl = receiver?.testPHourConfiguration.goodControl;
      } else if (receiver?.testPHourConfiguration.goodControl > goodControl) {
        lowControl = receiver?.testPHourConfiguration.lowControl;
        goodControl = receiver?.testPHourConfiguration.goodControl;
      }
    }

    this.conditionTranslation = this.translateService.instant(lowControl > 0 ? "MeshNet.Conditions" : "MeshNet.Conditions_noOrange").join('\n');
    this.conditionTranslation = this.conditionTranslation.toString().replace('{{LowerMargin}}',  lowControl.toString());
    this.conditionTranslation = this.conditionTranslation.replace('{{LowerMargin+}}', (lowControl+1).toString());
    this.conditionTranslation = this.conditionTranslation.replace('{{GratterMargin}}', goodControl.toString());
    this.conditionTranslation = this.conditionTranslation.replace('{{GratterMargin+}}', (goodControl+1).toString());
  
  }

  clearFilters() {
    this.panelStatus.installationTypeFilters = [];
    this.panelStatus.conectivityTypeFilters = [];
  }

  isBreadcrumb = () => {
    return this.hoverMenuesService.breadcrumb;
  };

  startGlossary = () => {
    return {
      ViewNone: [],
      ViewInstallation: [
        {
          icon: this.manualSanitizing('../../../../assets/icons/panels/Interior-icon.svg'),
          type: DeviceTypes.typeInterior.valueOf(),
          value: 'Interior',
        },
        {
          icon: this.manualSanitizing('../../../../assets/icons/panels/Exterior-low-icon.svg'),
          type: DeviceTypes.typeExteriorLow.valueOf(),
          value: 'ExteriorLow',
        },
        {
          icon: this.manualSanitizing('../../../../assets/icons/panels/Exterior-high-icon.svg'),
          type: DeviceTypes.typeExteriorHigh.valueOf(),
          value: 'ExteriorHigh',
        },
      ],
      ViewConectivity: [
        {
          icon: this.manualSanitizing('../../../../assets/icons/panels/Circle.svg'),
          type: DeviceTypes.typeRF.valueOf(),
          value: 'mesh',
        },
        {
          icon: this.manualSanitizing('../../../../assets/icons/panels/Triangle.svg'),
          type: DeviceTypes.typeWIFI.valueOf(),
          value: 'wifi-ethernet',
        },
        {
          icon: this.manualSanitizing('../../../../assets/icons/panels/Diamond.svg'),
          type: DeviceTypes.typeMobile.valueOf(),
          value: 'mobile',
        },
        {
          icon: this.manualSanitizing('../../../../assets/icons/panels/Pentagon.svg'),
          type: DeviceTypes.typeFullInternet.valueOf(),
          value: 'full-internet',
        },
      ],
      ViewMeshFunction: [
        {
          icon: this.manualSanitizing(
            mapIcons.getIconCode(
              LinkTypes.linkRF.valueOf(),
              constants.iconTypes.Interior,
              ColorTypes.gray.valueOf(),
              false
            )
          ),
          type: DeviceTypes.typeMeshDevice.valueOf(),
        },
        {
          icon: this.manualSanitizing(
            mapIcons.getIconCode(
              LinkTypes.linkCelular.valueOf(),
              constants.iconTypes.ExteriorLow,
              ColorTypes.gray.valueOf(),
              false
            )
          ),
          type: DeviceTypes.typeGateway.valueOf(),
        },
      ],
      ViewLinking: [
        {
          icon: this.manualSanitizing(
            mapIcons.getIconCode(
              LinkTypes.linkRF.valueOf(),
              constants.iconTypes.Interior,
              ColorTypes.wine.valueOf(),
              true
            )
          ),
          type: DeviceTypes.typeLinkingDisconnected.valueOf(),
        },
        {
          icon: this.manualSanitizing(
            mapIcons.getIconCode(
              LinkTypes.linkRF.valueOf(),
              constants.iconTypes.Interior,
              ColorTypes.null.valueOf(),
              true
            )
          ),
          type: DeviceTypes.typeLinkingNull.valueOf(),
        },
        {
          icon: this.manualSanitizing(
            mapIcons.getIconCode(
              LinkTypes.linkRF.valueOf(),
              constants.iconTypes.Interior,
              ColorTypes.low.valueOf(),
              true
            )
          ),
          type: DeviceTypes.typeLinkingLow.valueOf(),
        },
        {
          icon: this.manualSanitizing(
            mapIcons.getIconCode(
              LinkTypes.linkRF.valueOf(),
              constants.iconTypes.Interior,
              ColorTypes.good.valueOf(),
              true
            )
          ),
          type: DeviceTypes.typeLinkingGood.valueOf(),
        },
        {
          icon: this.manualSanitizing(
            mapIcons.getIconCode(
              LinkTypes.linkRF.valueOf(),
              constants.iconTypes.Interior,
              ColorTypes.optimum.valueOf(),
              true
            )
          ),
          type: DeviceTypes.typeLinkingOptimum.valueOf(),
        },
      ],
    };
  };

  manualSanitizing(icon: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(icon); // NOSONAR
  }

  handleShowLegends = () => {
    this.showLegends = !this.showLegends;
  };

  getImageSource = (status: string | number, index: number) => {
    let level: number;
    const url = 'assets/icons/networks-status/';

    if (this.panelStatus.isViewLinking()) {
      level = this.panelStatus.totalCount() / 4;

      return this.panelStatus.neighborCountFor(status) > level * index
        ? `${url}neighbors-equalizador-${status}.png`
        : `${url}neighbors-equalizador-none.png`;
    }

    level = this.panelStatus.rendererStatusControl.total / 4;

    return this.panelStatus.rendererStatusControl[status] > level * index
      ? `${url}equalizador-${status}.png`
      : `${url}equalizador-none.png`;
  };

  getTypeCount = (g: { type: string }) => {
    let count = this.panelStatus.glossaryCount[this.panelStatus.selectedFilter][g.type];

    if (this.panelStatus.selectedFilter === constants.viewTypes.Conectivity && g.type === constants.iconTypes.WIFI) {
      count += this.panelStatus.glossaryCount[this.panelStatus.selectedFilter][constants.iconTypes.Ethernet];
    }

    if (this.panelStatus.selectedFilter === constants.viewTypes.Conectivity && g.type === constants.iconTypes.Mobile) {
      count += this.panelStatus.glossaryCount[this.panelStatus.selectedFilter][constants.iconTypes.Mobile2G];
      count += this.panelStatus.glossaryCount[this.panelStatus.selectedFilter][constants.iconTypes.Mobile3G];
      count += this.panelStatus.glossaryCount[this.panelStatus.selectedFilter][constants.iconTypes.Mobile4G];
    }

    return count;
  };

  getTypeCountLegends = (viewType: string, g: { type: string }) => {
    let count = this.panelStatus.glossaryCount[viewType][g.type];

    if (viewType === constants.viewTypes.Conectivity && g.type === constants.iconTypes.WIFI) {
      count += this.panelStatus.glossaryCount[viewType][constants.iconTypes.Ethernet];
    }

    if (viewType === constants.viewTypes.Conectivity && g.type === constants.iconTypes.Mobile) {
      count += this.panelStatus.glossaryCount[viewType][constants.iconTypes.Mobile2G];
      count += this.panelStatus.glossaryCount[viewType][constants.iconTypes.Mobile3G];
    }

    return count;
  };

  getLoadingStatus = () => {
    if (!this.panelsService.panelsCount) {
      return 0;
    }
    return ((this.panelsService.panels.length/this.panelsService.panelsCount)*100).toFixed(0);
  }

  getTotal = () => {
    if (
      this.receiversService.selectedReceiver[0] === 'default' ||
      this.receiversService.selectedReceiver.length === this.receiversService.receivers.length
    ) {
      return this.panelsService.panelsCount ?? 0;
    }

    return this.panelStatus.rendererStatusControl.total;
  }

  nextView = () => {
    let i = this.filters.indexOf(this.panelStatus.selectedFilter) + 1;
    if (i === this.filters.length) i = 0;
    this.panelStatus.selectedFilter = this.filters[i];
    this.panelStatus.processMarkIcons();
  };

  previousView = () => {
    let i = this.filters.indexOf(this.panelStatus.selectedFilter) - 1;
    if (i < 0) i = this.filters.length - 1;
    this.panelStatus.selectedFilter = this.filters[i];
    this.panelStatus.processMarkIcons();
  };

  switchView = (view: string) => {
    this.panelStatus.selectedFilter = view;
    this.panelStatus.processMarkIcons();
  };

  mapFilter(filterInfo) {
    const filterMap = {
      ViewInstallation: this.installationTypeFilter,
      ViewConectivity: this.conectivityTypeFilter,
    };

    const targetFilter = filterMap[filterInfo.type];

    if (targetFilter) {
      if (targetFilter.includes(filterInfo.value)) {
        targetFilter.splice(targetFilter.indexOf(filterInfo.value), 1);
      } else {
        targetFilter.push(filterInfo.value);
      }
    }

    this.panelStatus.installationTypeFilters = this.installationTypeFilter;
    this.panelStatus.conectivityTypeFilters = this.conectivityTypeFilter;

    this.panelStatus.processMarkIcons();
  }
}
