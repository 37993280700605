import { Component, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { NotificationFormComponent } from 'src/app/partitions/components/notification-form/notification-form.component';
import { Partition } from 'src/app/partitions/models/partition';
import { sortedEventsList } from 'src/app/partitions/models/sorted-events-list';
import { sortedNotificationSubsText } from 'src/app/partitions/models/sorted-notificationSub-text';
import { PartitionsService } from 'src/app/partitions/services/partitions.service';
import { CompanyModel } from 'src/app/shared/models/company-model';
import { UsersService } from 'src/app/shared/services/users.service';
import { PanelDialogComponent } from '../panel-dialog/panel-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { GenericDialogComponent } from 'src/app/shared/components/generic-dialog/generic-dialog.component';

export interface DialogData {
  title: string;
  operation: string;
  submitText: string;
  promptText: string;
  id: string;
  company: CompanyModel;
  type: string;
  note: string;
  titleNote: string;
  final: boolean;
  technician: boolean;
}

@Component({
  selector: 'app-panel-dialog-partition',
  templateUrl: './panel-dialog-partition.component.html',
  styleUrls: ['./panel-dialog-partition.component.css'],
})
export class PanelDialogPartitionComponent {
  @ViewChild('f') form: NgForm;

  partition: Partition = new Partition();
  originalPartition: Partition;

  accountErrorMatcher = new AccountErrorMatcher();
  public title: string;

  isLoading = false;
  filteredUsers: { id: string; company: string; name: string; lastName: string; email: string; selected: boolean }[] =
    [];
  availableUsers: {
    id: string;
    company: string;
    name: string;
    lastName: string;
    email: string;
    selected: boolean;
    topics: string[];
  }[] = [];
  userFilter = '';
  currentUsers: {
    id: string;
    company: string;
    name: string;
    lastName: string;
    email: string;
    code: string;
    selected: boolean;
    topics: string[];
  }[] = [];
  usersWithNewSubs = [];
  @Output() cancelClicked = new EventEmitter<void>();

  constructor(
    private readonly translateService: TranslateService,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public usersService: UsersService,
    public dialog: MatDialog,
    private readonly loadingBar: LoadingBarService,
    private readonly abm: PartitionsService,
    private readonly dialogRef: MatDialogRef<PanelDialogComponent>
  ) {
    if (this.data.operation === 'update') {
      this.loadingBar.useRef().start();

      this.isLoading = true;
      this.abm.getPartition(this.data.id).subscribe({
        next: (res: any) => {
          this.handleGetPartition(res);
        },
      });
    } else if (this.data.operation === 'add') {
      this.partition.company = this.authService.getPrimaryCompany()._id;
      this.partition.supportEnabled = true;
      this.partition.notificationSubs = [...this.authService.getPrimaryCompany().notificationSubs];
    }

    if (this.usersService.users.length === 0) {
      this.usersService.requestUsers(() => {
        this.onChangeCompany();
      });
    }

    this.onChangeCompany();
  }

  async handleGetPartition(res: any) {
    this.isLoading = false;
    if (!res.success) {
      this.dialogRef.close({
        status: 'error',
        message: await firstValueFrom(this.translateService.get('Partition.ErrorGetPartition')),
      });
      this.loadingBar.useRef().stop();
      return;
    }

    const { partition } = res;
    delete partition.alarmStatus;
    delete partition.apiKey;

    this.partition = { ...partition };
    this.originalPartition = { ...partition };

    if (partition.users?.length) {
      this.currentUsers = partition.users
        .filter((element) => element.user)
        .map((element) => ({
          id: element.user._id,
          company: element.user.company,
          name: element.user.name,
          lastName: element.user.lastName,
          email: element.user.email,
          code: element.userCode,
          topics: element.notificationSubs,
          selected: true,
        }))
        .sort((a, b) => `${a.lastName} ${a.name}`.localeCompare(`${b.lastName} ${b.name}`));

      if (this.availableUsers) {
        this.availableUsers.forEach((u) => (u.topics = this.partition.notificationSubs));
      }
    }

    this.onChangeCompany();
    this.loadingBar.useRef().complete();
  }

  cancel() {
    this.cancelClicked.emit();
  }

  private getNotificationList(): any[] {
    const list = [];

    if (this.authService.getPrimaryCompany().notificationSubs !== undefined) {
      sortedNotificationSubsText.forEach((n) => {
        list.push(n);
      });
    }
    return list;
  }

  openNotificationForm(user?: any) {
    const currentTopics = user ? user.topics : this.partition.notificationSubs;

    this.dialog.open(NotificationFormComponent, {
      width: '40%',
      minWidth: '400px',
      maxWidth: '1000px',
      data: {
        availableTopics: this.getNotificationList(),
        currentTopics,
        isUser: user !== undefined,
        name: user ? `${user.lastName} ${user.name}` : undefined,
      },
    });
  }
  openEventForm() {
    const currentTopics = [];
    if (this.partition.eventsLoggingConf !== undefined) {
      const keys = Object.keys(this.partition.eventsLoggingConf);
      if (keys.length > 0) {
        keys.forEach((k) => {
          if (this.partition.eventsLoggingConf[k]) {
            currentTopics.push(k);
          }
        });
      }
    } else {
      sortedEventsList.forEach((e) => {
        currentTopics.push(e.topic);
      });
    }
    const dialogRef = this.dialog.open(NotificationFormComponent, {
      width: '40%',
      minWidth: '400px',
      maxWidth: '1000px',
      data: {
        availableTopics: sortedEventsList,
        currentTopics,
        events: true,
      },
    });

    dialogRef.afterClosed().subscribe((result: { status: string; topics: any }) => {
      if (result.status === 'success') {
        // Se reemplazan los valores de configuracion de Historial por las seleccion del usuario
        this.partition.eventsLoggingConf = result.topics;
      }
    });
  }

  filterUsers() {
    this.filteredUsers = !this.partition.company
      ? []
      : this.availableUsers.reduce((acc, user) => {
          const filtro = this.userFilter.toLowerCase();
          if (
            user.email.toLowerCase().includes(filtro) ||
            user.name.toLowerCase().includes(filtro) ||
            user.lastName.toLowerCase().includes(filtro)
          ) {
            acc.push(user);
          }
          return acc;
        }, []);
  }
  onChangeCompany() {
    // Filter the company users
    if (this.partition.company) {
      this.availableUsers = this.usersService.users.reduce((acc, user) => {
        if (user.company === this.partition.company && !this.partition.users?.find((u) => u.user?._id === user._id)) {
          acc.push({
            id: user._id,
            company: user.company,
            name: user.name,
            lastName: user.lastName,
            email: user.email,
            topics: this.partition ? this.partition.notificationSubs : [],
            selected: false,
          });
        }
        return acc;
      }, []);

      this.filterUsers();
    }
  }

  submit = () => {
    if (this.form.valid) {
      this.isLoading = true;
      this.loadingBar.useRef().start();

      if (this.data.operation === 'add') {
        this.addPartition();
      } else if (this.data.operation === 'update') {
        this.updatePartition();
      }
    } else {
      this.form.control.markAllAsTouched();
    }
  };

  addPartition = async () => {
    this.accountErrorMatcher.setAccountExists(false);
    this.partition.users = [];
    this.availableUsers.forEach((user) => {
      if (user.selected) {
        this.partition.users.push({
          id: user.id,
          newNotificationSubs: user.topics,
        });
      }
    });

    this.abm.addPartition(this.partition).subscribe({
      next: (res: any) => {
        this.handleAddPartitionResponse(res);
      },

      error: (error) => {
        this.handlePartitionError(error);
      },
    });
  };

  private async handleAddPartitionResponse(res: any) {
    if (res?.success) {
      this.dialogRef.close();
      this.dialog.open(GenericDialogComponent, {
        disableClose: true,
        autoFocus: false,
        data: {
          title: await firstValueFrom(this.translateService.get('Partition.PartitionAdded')),
          type: 'successConfirmation',
        },
      });
      this.loadingBar.useRef().complete();
    } else {
      if (res.code === 'accountExists') {
        this.accountErrorMatcher.setAccountExists(true);
      } else if (res.code === 'invalidParams') {
        this.dialogRef.close({
          status: 'error',
          message: await firstValueFrom(this.translateService.get('Partition.InvalidParam')),
        });
      } else {
        this.dialogRef.close({
          status: 'error',
          message: await firstValueFrom(this.translateService.get('Partition.ErrorAddPartition')),
        });
      }
      this.loadingBar.useRef().stop();
    }
    this.isLoading = false;
  }

  private async handlePartitionError(error: any) {
    this.isLoading = false;
    this.loadingBar.useRef().stop();

    if (error && error.status === 401) {
      this.dialogRef.close({
        status: 'error',
        message: await firstValueFrom(this.translateService.get('Shared.SessionExpired')),
      });
      this.authService.logout();
    } else {
      this.dialogRef.close({
        status: 'error',
        message: await firstValueFrom(this.translateService.get('Partition.ErrorAddPartition')),
      });
    }
  }
  updatePartition = async () => {
    this.accountErrorMatcher.setAccountExists(false);

    // Se borran los datos que no se modificaron.
    const updatedPartition = new Partition();
    updatedPartition._id = this.partition._id;
    updatedPartition.company = this.partition.company;
    updatedPartition.name = this.originalPartition.name !== this.partition.name ? this.partition.name : undefined;
    updatedPartition.account =
      this.originalPartition.account !== this.partition.account ? this.partition.account : undefined;
    updatedPartition.address = this.originalPartition.address !== this.partition.address
      ? this.partition.address
      : undefined;
    updatedPartition.supportEnabled =
      this.originalPartition.supportEnabled !== this.partition.supportEnabled
        ? this.partition.supportEnabled
        : undefined;
    updatedPartition.adminNotes =
      this.originalPartition.adminNotes !== this.partition.adminNotes ? this.partition.adminNotes : undefined;
    updatedPartition.disallowEvents =
      this.originalPartition.disallowEvents !== this.partition.disallowEvents
        ? this.partition.disallowEvents
        : undefined;
    updatedPartition.emergencyEmail =
      this.originalPartition.emergencyEmail !== this.partition.emergencyEmail
        ? this.partition.emergencyEmail
        : undefined;
    updatedPartition.disallowRemoteSiren =
      this.originalPartition.disallowRemoteSiren !== this.partition.disallowRemoteSiren
        ? this.partition.disallowRemoteSiren
        : undefined;
    updatedPartition.users = [];
    updatedPartition.notificationSubs =
      this.originalPartition.notificationSubs !== this.partition.notificationSubs
        ? this.partition.notificationSubs
        : undefined;
    this.availableUsers.forEach((user) => {
      if (user.selected) {
        updatedPartition.users.push({
          id: user.id,
          newNotificationSubs: user.topics ? user.topics : this.partition.notificationSubs,
        });
      }
    });
    updatedPartition.removeUsers = [];
    this.currentUsers.forEach((user) => {
      if (!user.selected) {
        updatedPartition.removeUsers.push(user.id);
      }
    });
    updatedPartition.usersWithNewSubs = [];
    this.usersWithNewSubs.forEach((user) => {
      if (user.selected) {
        updatedPartition.usersWithNewSubs.push({
          id: user.id,
          newNotificationSubs: user.topics,
        });
      }
    });
    updatedPartition.eventsLoggingConf = this.partition.eventsLoggingConf;

    this.abm.modifyPartition(updatedPartition).subscribe({
      next: (res: any) => {
        this.handlePartitionModificationResponse(res);
      },
      error: (error) => {
        this.handlePartitionModificationError(error);
      },
    });
  };

  private async handlePartitionModificationResponse(res: any) {
    if (res?.success) {
      this.dialogRef.close({
        status: 'success',
        message: await firstValueFrom(this.translateService.get('Partition.PartitionModified')),
      });
      this.loadingBar.useRef().complete();
    } else {
      if (res.code === 'accountExists') {
        this.accountErrorMatcher.setAccountExists(true);
      } else if (res.code === 'invalidParams') {
        this.dialogRef.close({
          status: 'error',
          message: await firstValueFrom(this.translateService.get('Partition.InvalidParam')),
        });
      } else {
        this.dialogRef.close({
          status: 'error',
          message: await firstValueFrom(this.translateService.get('Partition.ErrorModPartition')),
        });
      }
      this.loadingBar.useRef().stop();
    }
    this.isLoading = false;
  }

  private async handlePartitionModificationError(error: any) {
    this.isLoading = false;
    this.loadingBar.useRef().stop();

    if (error && error.status === 401) {
      this.dialogRef.close({
        status: 'error',
        message: await firstValueFrom(this.translateService.get('Shared.SessionExpired')),
      });
      this.authService.logout();
    } else {
      this.dialogRef.close({
        status: 'error',
        message: await firstValueFrom(this.translateService.get('Partition.ErrorModPartition')),
      });
    }
  }

  onChangeAccount = () => {
    this.partition.account = this.partition.account.toUpperCase();
    this.partition.account = this.partition.account.replace('0', 'A');
  };
}

export class AccountExistsErrorMatcher implements ErrorStateMatcher {
  accountExists = false;

  public getAccountExists(): boolean {
    return this.accountExists;
  }

  public setAccountExists(val: boolean) {
    this.accountExists = val;
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control.dirty && control.errors) || (form.invalid && form?.errors?.validTimerRange);
  }
}

export class AccountErrorMatcher implements ErrorStateMatcher {
  AccountExists = false;

  public getAccountExists(): boolean {
    return this.AccountExists;
  }

  public setAccountExists(val: boolean) {
    this.AccountExists = val;
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (
      this.AccountExists ||
      ((control.touched || form.submitted) && control.errors !== undefined && control.errors !== null)
    );
  }
}
