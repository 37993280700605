import { Component, Input } from '@angular/core';
import { Partition } from '../models/partition';

@Component({
  selector: 'app-partitions-errors-details-summary',
  templateUrl: './partitions-errors-details-summary.component.html',
  styleUrls: ['./partitions-errors-details-summary.component.scss'],
})
export class PartitionsErrorsDetailsSummaryComponent {
  @Input()
  partitions: Partition[];

  constructor() {}
}
