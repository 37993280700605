<div fxLayout="column" fxLayoutGap="15px" style="padding-bottom: 40px">
    <div *ngFor="let company of companiesService.companies" class="companyRow">
        <div fxLayout="row" fxLayoutGap="20px" class="filters">
            <!-- Company Left side -->
            <div class="center">
                <img
                    class="img"
                    [src]="company.logo ?? 'assets/img/citymesh-logo-user.png'"
                    alt
                />
                {{ company.name }}
            </div>
            <!-- company Right side -->
            <div class="center">
                <button 
                  *ngIf="companiesService.settingsAvailableRoles.includes(authService.user.role)"
                  class="center-button edit-button" 
                  mat-raised-button 
                  (click)="editCompany(company)"
                >
                    <mat-icon svgIcon="edit_square" class="icon edit-icon"></mat-icon>
                    {{ 'Settings.EditLabel' | translate }}
                </button>
                <mat-icon 
                  style="cursor: pointer;" 
                  (click)="onExpand(company, !company.expand)"
                  onKeyPress
                  onKeyDown
                  onKeyUp
                >{{ company.expand ? 'keyboard_arrow_down' : 'chevron_right'}}</mat-icon>
            </div> 
        </div>
        <div *ngIf="company.expand" class="expanded-box">
            <div>
                <mat-tab-group>
                    <mat-tab [label]="'Settings.Info' | translate">
                        <div class="expanded-info-parent">
                            <div class="expanded-info-column">
                                <div class="expanded-info-title">
                                    {{ ('Settings.Company' | translate).toUpperCase() }}
                                </div>
                                <div class="expanded-info-value">
                                    {{ company.name }}
                                </div>
        
                                <div class="expanded-info-title">
                                    {{ ('Settings.Contact' | translate).toUpperCase() }}
                                </div>
                                <div class="expanded-info-value">
                                    {{ company.contact }}
                                </div>
        
                                <div class="expanded-info-title">
                                    {{ ('Settings.Language' | translate).toUpperCase() }}
                                </div>
                                <div class="expanded-info-value">
                                    {{ (('Settings.Languages.' + (company.language || 'es')) | translate) }}
                                </div>
                                
                                <div class="expanded-info-title">
                                    {{ ('Settings.WhatsappNumber' | translate).toUpperCase() }}
                                </div>
                                <div class="expanded-info-value">
                                    {{ company.whatsappNumber }}
                                </div>
                            </div>
                            <div class="expanded-info-column">
                                <div class="expanded-info-title">
                                    {{ ('Settings.EZVIZAccountName' | translate).toUpperCase() }}
                                </div>
                                <div class="expanded-info-value">
                                    {{ company.EZVIZAccountName }}
                                </div>

                                <div class="expanded-info-title">
                                    {{ ('Settings.NotificationEmail' | translate).toUpperCase() }}
                                </div>
                                <div class="expanded-info-value">
                                    {{ company.notificationEmail?.lenght > 0 ? company.notificationEmail[0] : '' }}
                                </div>

                                <div class="expanded-info-title">
                                    {{ ('Settings.WebLink' | translate).toUpperCase() }}
                                </div>
                                <div class="expanded-info-value">
                                    {{ company.webUrl }}
                                </div>
        
                                <div class="expanded-info-title">
                                    {{ ('Settings.Location' | translate).toUpperCase() }}
                                </div>
                                <div class="expanded-info-value">
                                    {{ company.location.lat && company.location.lng ? (company.location.lat + ', ' + company.location.lng) : '' }}
                                </div>
                            </div>
                            <div class="expanded-info-column">
                                <div class="expanded-info-title">
                                    {{ ('Settings.Email' | translate).toUpperCase() }}
                                </div>
                                <div class="expanded-info-value">
                                    {{ company.email }}
                                </div>
        
                                <div class="expanded-info-title">
                                    {{ ('Settings.TechUserCode' | translate).toUpperCase() }}
                                </div>
                                <div class="expanded-info-value">
                                    {{ company.techUserCode }}
                                </div>
        
                                <div class="expanded-info-title">
                                    {{ ('Settings.LinkName' | translate).toUpperCase() }}
                                </div>
                                <div class="expanded-info-value">
                                    {{ company.linkName }}
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab [label]="'Settings.ReceiverAndUsers' | translate"> 
                        <div class="expanded-info-section-title">
                            {{ ('Settings.Receivers' | translate) }}
                        </div> 
                        <div *ngFor="let receiver of company.receivers">
                            <div class="expanded-info-parent">
                                <div class="expanded-info-column">
                                    <div class="expanded-info-title">
                                        <img
                                            class="img"
                                            [src]="receiver.logo ?? company.logo ?? 'assets/img/citymesh-logo-user.png'"
                                            alt
                                        />
                                    </div>
                                </div>
                                <div class="expanded-info-column">
                                    <div class="expanded-info-title">
                                        {{ ('Settings.Receiver' | translate).toUpperCase() }}
                                    </div>
                                    <div>
                                        {{ receiver.name }}
                                    </div>
                                </div>
                                
                                <div class="expanded-info-column">
                                    <div class="expanded-info-title">
                                        {{ ('Settings.Apikey' | translate).toUpperCase() }}
                                    </div>
                                    <div>
                                        {{ receiver.apiKey }}
                                    </div>
                                </div>
        
                                <div class="expanded-info-column">
                                    <div class="expanded-info-title">
                                        {{ ('Settings.IP' | translate).toUpperCase() }}
                                    </div>
                                    <div>
                                        {{ receiver.ip }}
                                    </div>
                                </div>
                                
                                <div class="expanded-info-column">
                                    <div class="expanded-info-title">
                                        {{ ('Settings.UdpPort' | translate).toUpperCase() }}
                                    </div>
                                    <div>
                                        {{ receiver.udpPort }}
                                    </div>
                                </div>

                                <div class="expanded-info-column">
                                    <div class="expanded-info-title">
                                        {{ ('Settings.SnifferPort' | translate).toUpperCase() }}
                                    </div>
                                    <div>
                                        {{ receiver.snifferPort }}
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!isLast(receiver, company.receivers)"  class="separator"></div>
                        </div>
                        <div class="expanded-info-section-title">
                            {{ ('Settings.Users' | translate) }}
                        </div> 
                        <div *ngFor="let user of company.users">
                            <div class="expanded-info-parent">
                                <div class="expanded-info-column">
                                    <div class="expanded-info-title">
                                        {{ ('Settings.Name' | translate).toUpperCase() }}
                                    </div>
                                    <div>
                                        {{ user.name }}
                                    </div>
                                </div>
                                
                                <div class="expanded-info-column">
                                    <div class="expanded-info-title">
                                        {{ ('Settings.Lastname' | translate).toUpperCase() }}
                                    </div>
                                    <div>
                                        {{ user.lastname }}
                                    </div>
                                </div>
        
                                <div class="expanded-info-column">
                                    <div class="expanded-info-title">
                                        {{ ('Settings.Email' | translate).toUpperCase() }}
                                    </div>
                                    <div>
                                        {{ user.email }}
                                    </div>
                                </div>
                                
                                <div 
                                  class="expanded-info-column" 
                                  *ngIf="companiesService.settingsAvailableRoles.includes(authService.user.role)"
                                >
                                    <div class="expanded-info-title">
                                        {{ ('Settings.Permissions' | translate).toUpperCase() }}
                                    </div>
                                    <div 
                                      fxLayout="row" 
                                      fxLayoutGap="20px" 
                                      class="selector-field" 
                                      (click)="contextualMenuService.onContextalMenu($event, user, !user.expand, 'permission-selector-settings-' + user.email)"
                                      onKeyPress
                                      onKeyDown
                                      onKeyUp
                                    >
                                        <div> {{ ('Settings.UserLevel.' + (user.permission ?? 'END_COMPANY')) | translate }} </div> 
                                        <mat-icon [id]="'permission-selector-settings-' + user.email" class="permission-selector">keyboard_arrow_down</mat-icon>
                                    </div>
                                </div>
                                <div 
                                  class="expanded-info-column"
                                  *ngIf="!companiesService.settingsAvailableRoles.includes(authService.user.role)"
                                >
                                    <div class="expanded-info-title">
                                        {{ ('Settings.Permissions' | translate).toUpperCase() }}
                                    </div>
                                    <div>
                                        {{ ('Settings.UserLevel.' + (user.permission ?? 'END_COMPANY')) | translate }}
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!isLast(user, company.users)"  class="separator"></div>
                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</div>
