import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  ElementRef,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ComandService } from '../services/comand.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { Message } from '../models/command';
import { TranslateService } from '@ngx-translate/core';
import { PanelsService } from 'src/app/shared/services/panels.service';
import { ConsoleMessagesService } from '../services/console-messages-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-command-console',
  templateUrl: './command-console.component.html',
  styleUrls: ['./command-console.component.scss'],
})
export class CommandConsoleComponent implements OnInit, OnChanges {
  private filterMacSubscription: Subscription;
  header: boolean = true;
  fontSize: number = 14;
  @ViewChild('console', { read: ElementRef, static: false }) console: ElementRef;

  scrollIsBlocked = true;
  wrap: boolean = true;
  showLogs = false;

  accounts = [];

  constructor(
    public commandService: ComandService,
    public panelsService: PanelsService,
    private readonly clipboard: Clipboard,
    private readonly translateService: TranslateService,
    private readonly consoleMessagesService: ConsoleMessagesService
  ) {
    this.commandService.filterAccountNumber = undefined;
    this.wakeUpPanelsService();
  }

  wakeUpPanelsService() {
    if (this.accounts.length === 0) {
      if (this.panelsService.panels.length === 0) {
        this.panelsService.setup();
        this.panelsService.getPanels();
      } else {
        this.filteredAccounts();
      }
    }
  }

  ngOnInit() {
    this.filterMacSubscription = this.commandService.filterMac$.subscribe(() => {
      this.filteredAccounts();
    });
  }

  get allOrNotFoundTrans() {
    if (this.panelsService.accountSelectState == 'fetching-accounts')
      return this.translateService.instant('Shared.Loading');
    if (this.panelsService.accountSelectState == 'found-results') return this.translateService.instant('Shared.All');
    if (this.panelsService.accountSelectState == 'no-results-found')
      return this.translateService.instant('Shared.NoPartitionsFound');
  }

  ngOnDestroy() {
    this.consoleMessagesService.destroy();
    this.cleanConsole();
    this.filterMacSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.filter();
  }

  startStopConsoleLogs = () => {
    if (this.showLogs) {
      this.consoleMessagesService.destroy();
      this.showLogs = false;
    } else {
      this.consoleMessagesService.init();
      this.showLogs = true;
    }
  }

  get startStopButtonText(): string {
    if (this.showLogs) {
      return this.translateService.instant('TechSupport.Stop');
    } else {
      return this.translateService.instant('TechSupport.Start');
    }
  }

  onRemoveMac = (mac: string) => {
    this.commandService.filteredMacs = this.commandService.filteredMacs.filter((m) => m != mac);
    this.filter();
  };

  filter = () => {
    this.commandService.filter();
    if (this.scrollIsBlocked) {
      this.console.nativeElement.scrollTop =
        this.console.nativeElement.scrollHeight - this.console.nativeElement.offsetHeight;
    }
    this.updateStatus();
  };

  updateStatus() {
    this.panelsService.accountSelectState = this.accounts.length > 0 ? 'found-results' : 'no-results-found';
  }

  showHeader() {
    this.header = !this.header;
  }

  changeFontSize(size) {
    if (this.fontSize + size === 0) {
      this.fontSize = 1;
    } else {
      this.fontSize += size;
    }
  }

  restoreFontSize() {
    this.fontSize = 14;
  }

  wrapText() {
    this.wrap = !this.wrap;
  }

  blockScroll() {
    this.scrollIsBlocked = !this.scrollIsBlocked;
    if (this.scrollIsBlocked) {
      this.console.nativeElement.scrollTop =
        this.console.nativeElement.scrollHeight - this.console.nativeElement.offsetHeight;
    }
  }

  cleanConsole() {
    this.commandService.messagesFiltered = [];
  }

  copyConsole() {
    this.clipboard.copy(this.console.nativeElement.textContent);
  }

  formatDate(value: string) {
    return new Date(value).toLocaleString(this.translateService.instant('AppComponent.Locale'));
  }

  buildTextWhenStatusResponse(item: Message) {
    let aux = `${item.date}`;

    if (item.account) aux += ` - ${item.account}`;
    if (item.macs[0]) aux += ` - ${item.macs[0]}`;

    return `${aux} -> ${item.msg}`;
  }

  filteredAccounts() {
    const filterMac = this.commandService.filterMac.trim().toLocaleUpperCase();

    if (filterMac.length == 0) return (this.accounts = this.panelsService.accounts);
    if (filterMac.length != 8) return (this.accounts = this.panelsService.accounts);

    this.accounts = this.panelsService.accounts.filter(
      (p) => p.mac === filterMac
    );
  }
}
