  
  export enum ContextualMenuType {
    Permission = 0,
    Language = 1,
    Receiver = 2,
  }

  export enum PermissionType {
    Company = 'ADMIN_COMPANY',
    Distributor = 'ADMIN_DISTRIBUTOR',
    End_Company = 'END_COMPANY',
  }

  export enum Sections {
    Info = 'Info',
    Edit = 'Edit',
    Create = 'Create'
  }

  export enum Steps {
    INFO= 0,
    USERS= 1,
    CUSTOMIZE= 2
  }
  export class User {
    _id: string;
    companyID: string;
    companyName: string;
    companyColor: string;
    name: string;
    lastname: string;
    email: string;
    phone: string;
    permission: PermissionType;
    language: string;
    expand: boolean;

    constructor(language) {
      this.name = '';
      this.lastname = '';
      this.email = '';
      this.phone = '';
      this.permission = PermissionType.Company;
      this.language = language;
      this.expand = false;
    }
  }

  export class Receiver {
    name: string;
    apiKey: string;
    ip: string;
    udpPort: number;
    snifferPort: number;
    gestionPort: number;
    bridgePort: number;
    monitoringPort: number;
    panID: string;
    receiverID: string;
    receiverNumber: number;
    logo: string;
    colorPalette: {
      primary: string,
      secondary: string
    };
  }

  export class CompanyType {
    _id: string;
    name: string;
    expand: boolean;
    apiKey: string;
    snifferPort: number;
    udpPort: number;
    notificationEmail: string[];
    webUrl: string;
    location: {
      lat: number;
      lng: number;
    }
    EZVIZAccountName: string;
    ip: string;
    email: string;
    techUserCode: string;
    linkName: string;
    receiverName: string;
    contact: string;
    whatsappNumber: string;
    language: string;
    users: User[];
    colorPalette: {
      primary: string,
      secondary: string,
    };
    logo: string;
    receivers: Receiver[];
  
    constructor() {
      this.location = {
        lat: 0,
        lng: 0,
      }
      this.colorPalette = {
        primary: undefined,
        secondary: undefined
      };
      this.users = [];
      this.notificationEmail = [];
    }
  }