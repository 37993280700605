<form name="f" (ngSubmit)="submit()" novalidate>
  <div class="parent-div">
    <div class="partition-div">
      <h2 mat-dialog-title>{{ data.title }}</h2>
      <mat-dialog-content>
        <div class="container" fxLayout="row" fxLayoutGap="30px">
          <div class="field-container" fxFlex>
            <mat-form-field class="full-width">
              <mat-label>{{ 'Notification.NoteTitle' | translate }}</mat-label>
              <textarea
                matInput
                name="note"
                [(ngModel)]="data.titleNote"
                #note="ngModel"
                matInput
                placeholder="{{ 'Notification.WriteTitle' | translate }}"
                maxlength="64"
                cdkTextareaAutosize
                cdkAutosizeMaxRows="2"
                required
              ></textarea>
            </mat-form-field>
          </div>
          <div class="field-container" fxFlex>
            <mat-form-field class="full-width">
              <mat-label>{{ 'Notification.Note' | translate }}</mat-label>
              <textarea
                name="note"
                [(ngModel)]="data.note"
                #note="ngModel"
                matInput
                placeholder="{{ 'Notification.WriteNote' | translate }}"
                maxlength="600"
                cdkTextareaAutosize
                cdkAutosizeMaxRows="10"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </mat-dialog-content>
    </div>
  </div>
  <div *ngIf="data.operation === 'Notify Users'" fxFlex style="margin-bottom: 20px">
    <section>
      <mat-checkbox
        color="primary"
        style="margin-right: 30px"
        [checked]="data.final"
        type="checkbox"
        title="{{ 'Notification.Final' | translate }}"
        (change)="sendToFinal()"
      >
        {{ 'Notification.Final' | translate }}
      </mat-checkbox>
      <mat-checkbox
        color="primary"
        style="margin-right: 30px"
        [checked]="data.technician"
        type="checkbox"
        title="{{ 'Notification.Tech' | translate }}"
        (change)="sendToTechnician()"
      >
        {{ 'Notification.Tech' | translate }}
      </mat-checkbox>
    </section>
  </div>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" [disabled]="validateField()">{{ 'Shared.Accept' | translate }}</button>
    <button type="button" mat-raised-button (click)="onCancelClick()">{{ 'Shared.Cancel' | translate }}</button>
  </mat-dialog-actions>
</form>
