<form name="f" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate formValidTimerRange>
  <div class="parent-div">
    <div class="partition-div">
      <h1 mat-dialog-title>{{ this.data.title | translate }}</h1>
      <mat-dialog-content>
        <!-- Row 1 -->
        <div class="container" fxLayout="row" fxLayoutGap="30px">
          <!-- Nombre -->
          <div class="field-container" fxFlex>
            <mat-form-field>
              <input matInput  name="name" type="text" [(ngModel)]="partition.name" #name="ngModel" minlength="2" maxlength="20" placeholder='{{"Partition.Name" | translate}}' matInput required autocomplete="false"/>
              <button type="button" *ngIf="partition.name" matSuffix mat-icon-button aria-label="Clear" (click)="partition.name=''" tabindex="-1">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="name.invalid && name.errors?.required">{{'Partition.RequiredField' | translate}}</mat-error>
            </mat-form-field>
          </div>
          <!-- Cuenta -->
          <div class="field-container" *ngIf="this.authService.user.companies?.length === 1" fxFlex>
            <mat-form-field>
              <input matInput  
                name="account" type="text" 
                customEmail 
                [(ngModel)]="partition.account" 
                (ngModelChange)="accountErrorMatcher.setAccountExists(false)" 
                (keyup)="onChangeAccount()" 
                #account="ngModel" 
                [maxLength]="4" 
                placeholder='{{"Partition.AccountNumber" | translate}}' 
                matInput account required 
                autocomplete="false" 
                [errorStateMatcher]="accountErrorMatcher" 
                [disabled]="partition.mac!=undefined"
                matTooltip='{{"Partition.AccountChars" | translate}}'
              />
              <button type="button" *ngIf="partition.account && partition.mac == undefined" matSuffix mat-icon-button aria-label="Clear" (click)="partition.account=''" tabindex="-1">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="account.invalid && account.errors?.required">{{'Partition.RequiredField' | translate}}</mat-error>
              <mat-error *ngIf="accountErrorMatcher.getAccountExists()">{{'Partition.AccountNumUse' | translate}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <!-- Row 1 bis-->
        <div *ngIf="this.authService.isAdmin()" class="container" fxLayout="row" fxLayoutGap="30px">
          <!-- Cuenta -->
          <div class="field-container" fxFlex>
            <mat-form-field>
              <input matInput  
                name="account" type="text" 
                customEmail 
                [(ngModel)]="partition.account" 
                (ngModelChange)="accountErrorMatcher.setAccountExists(false)" 
                (keyup)="onChangeAccount()" 
                #account="ngModel" 
                [maxLength]="4" 
                placeholder='{{"Partition.AccountNumber" | translate}}' 
                matInput account required 
                autocomplete="false" 
                [errorStateMatcher]="accountErrorMatcher" 
                [disabled]="partition.mac!=undefined"
                matTooltip='{{"Partition.AccountChars" | translate}}'
              />
              <button type="button" *ngIf="partition.account && partition.mac == undefined" matSuffix mat-icon-button aria-label="Clear" (click)="partition.account=''" tabindex="-1">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="account.invalid && account.errors?.required">{{'Partition.RequiredField' | translate}}</mat-error>
              <mat-error *ngIf="accountErrorMatcher.getAccountExists()">{{'Partition.AccountNumUse' | translate}}</mat-error>
            </mat-form-field>
          </div>
          <!-- Receptora - Solo para usuarios multiempresa -->
          <div class="field-container" fxFlex>
            <mat-form-field>
              <mat-label>{{"AppComponent.Company" | translate}}</mat-label> 
              <select name="selectedCompany" [(ngModel)]="partition.company" (change)="onChangeCompany()" matNativeControl required [disabled]="this.data.operation === 'update'">
                <option [ngValue]="undefined" selected>{{'Partition.SelectCompany' | translate}}</option>
                <option *ngFor="let c of this.authService.user.companies" [ngValue]="c._id">{{ c.name }}</option>
              </select>
            </mat-form-field>
          </div>
        </div>
        <!-- Row 2 -->
        <div class="container" fxLayout="row" fxLayoutGap="30px">
          <!-- Dirección -->
          <div class="field-container" fxFlex>
            <mat-form-field>
              <input matInput  name="address" [(ngModel)]="partition.address" #name="ngModel" placeholder='{{"Partition.Address" | translate}}' minlength="2" maxlength="40" matInput required autocomplete="false"/>
              <button type="button" *ngIf="partition.address" matSuffix mat-icon-button aria-label="Clear" (click)="partition.address=''" tabindex="-1">
                <mat-icon>close</mat-icon>
              </button>
              <mat-error *ngIf="name.invalid && name.errors.required">{{'Partition.RequiredField' | translate}}</mat-error>
              <mat-error *ngIf="name.invalid && name.errors.minlength">{{'Partition.MinField' | translate}}</mat-error>
              <mat-error *ngIf="name.invalid && name.errors.maxlength">{{'Partition.MaxField' | translate}}</mat-error>
            </mat-form-field>
          </div>

           <!-- Soporte -->
           <div class="field-container" fxFlex>
            <mat-form-field>
              <mat-label>{{'Partition.EnabTechSupport' | translate}}</mat-label>
              <select matNativeControl [(ngModel)]="partition.supportEnabled" name="supportEnabled" required     
              matTooltip='{{"Partition.EnabTechSupport_hint" | translate}}'>
                <option value="true" selected>{{'Partition.Yes' | translate}}</option>
                <option value="false">{{'Partition.No' | translate}}</option>
              </select>
            </mat-form-field>
          </div>
        </div>
        <!-- Row 3 -->
        <div class="container" fxLayout="row" fxLayoutGap="30px">
          <!-- Envío de eventos -->
          <div class="field-container" fxFlex>
            <mat-form-field>
              <mat-label>{{'Partition.BlockEventSend' | translate}}</mat-label>
              <select matNativeControl [(ngModel)]="partition.disallowEvents" name="disallowEvents" required     
              matTooltip='{{"Partition.BlockEventSend_hint" | translate}}'>
                <option value="true">{{'Partition.Yes' | translate}}</option>
                <option value="false" selected>{{ 'Partition.No' | translate}}</option>
              </select>
            </mat-form-field>
          </div>
          <!-- Notificar emergenccias a usuarios de la partición -->
          <div class="field-container" fxFlex>
           <mat-form-field>
             <mat-label>{{'Partition.EnableEmergencyEmail' | translate}}</mat-label>
             <select matNativeControl [(ngModel)]="partition.emergencyEmail" name="emergencyEmail" required     
              matTooltip='{{"Partition.EnableEmergencyEmail_hint" | translate}}'>
               <option value="true" selected>{{'Partition.Yes' | translate}}</option>
               <option value="false">{{'Partition.No' | translate}}</option>
             </select>
           </mat-form-field>
         </div>
        </div>
        <!-- Row 4 -->
        <!-- HIDE BY FIRMWARE PROBLEM
        <div class="container" fxLayout="row" fxLayoutGap="30px">
          </!-- Disallow remote siren de la partición -/->
          <div class="field-container" fxFlex>
            <mat-form-field>
              <mat-label>{{'Partition.DisallowRemoteSiren' | translate}}</mat-label>
              <select matNativeControl [(ngModel)]="partition.disallowRemoteSiren" name="disallowRemoteSiren" required     
               matTooltip='{{"Partition.DisallowRemoteSiren_hint" | translate}}'>
                <option value="true" selected>{{'Partition.Yes' | translate}}</option>
                <option value="false">{{'Partition.No' | translate}}</option>
              </select>
            </mat-form-field>
          </div>
        </div>
        -->
        <!-- Row 4 -->
        <div class="container" fxLayout="row" fxLayoutGap="30px">
          <!-- Notas -->
          <div class="field-container" fxFlex>
            <mat-form-field class="example-full-width">
              <mat-label>{{'Partition.Notes' | translate}}</mat-label>
              <textarea style="height: 20px;" name="adminNotes" [(ngModel)]="partition.adminNotes" #adminNotes="ngModel" matInput placeholder='{{"Partition.WriteNotes" | translate}}'></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="partition-buttons">
          <button type="button" mat-raised-button (click)="openNotificationForm()" [disabled]="isLoading"><mat-icon>notifications_active</mat-icon> {{'Partition.Notification' | translate}}</button>
          <button class="events-button" type="button" mat-raised-button (click)="openEventForm()" [disabled]="isLoading"><mat-icon>calendar_today</mat-icon> {{'Partition.EventHistory' | translate}}</button>
        </div>
      </mat-dialog-content>
    </div>
    <div class="user-div">
      <h4>{{'Partition.Users' | translate}}</h4>
      <div>
        <span>{{'Partition.Current' | translate}}</span>
        <div *ngIf="currentUsers && currentUsers.length > 0" class="user-list user-current">
          <div *ngFor="let user of currentUsers" class="div-inline">
            <mat-checkbox
            color="primary" class="user-check"
            [(ngModel)]="user.selected" type="checkbox"
            [ngModelOptions]="{standalone: true}"
            [title]="user.lastName + ' ' + user.name + ' - ' + user.email + ' (' + user.code + ')'">
              {{ user.lastName + ' ' + user.name + ' - ' + user.email + ' (' + user.code + ')' }}
            </mat-checkbox>
            <div class="user-button-container">
              <a (click)="openNotificationForm(user)"><mat-icon class="user-button" align="end">notifications_active</mat-icon></a>
            </div>
          </div>
        </div>
        <div *ngIf="!currentUsers || currentUsers.length == 0" class="user-list user-current">
          <span style="margin: 10px; font-size: 12px;">{{'Partition.NoUserAssig' | translate}}</span>
        </div>
      </div>
      <div>
        <span>{{'Partition.Available' | translate}}</span>
        <mat-form-field>
          <input matInput name="userFilter" [(ngModel)]="userFilter" (keyup)="filterUsers()" placeholder='{{"Partition.SearchUser" | translate}}'/>
          <button type="button" *ngIf="userFilter" matSuffix mat-icon-button aria-label="Clear" (click)="userFilter=''; filterUsers()" tabindex="-1">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <div class="user-list">
          <div *ngFor="let user of filteredUsers">
            <div class="div-inline">
              <mat-checkbox
              color="primary" class="user-check"
              [(ngModel)]="user.selected"
              type="checkbox"
              [ngModelOptions]="{standalone: true}"
              [title]="user.lastName + ' ' + user.name + ' - ' + user.email">
                {{ user.lastName + ' ' + user.name + ' - ' + user.email }}
              </mat-checkbox>
              <a *ngIf="user.selected" (click)="openNotificationForm(user)" class="user-button-container"><mat-icon class="user-button">notifications_active</mat-icon></a>
            </div>
          </div>
          <div class="loading-shade" *ngIf="usersService.isLoadingResults">
            <mat-spinner *ngIf="usersService.isLoadingResults"></mat-spinner>
          </div>
        </div>
      </div>
      <span style="margin-top: 20px; font-size: 10px;">{{'Partition.IdentifyUser' | translate}}</span>
    </div>
  </div>
  <mat-dialog-actions align="end">
      <button mat-raised-button color="primary" [disabled]="isLoading">{{ this.data.submitText | translate}}</button>
      <button type="button" mat-raised-button (click)="onCancelClick()">{{'Shared.Cancel' | translate}}</button>
  </mat-dialog-actions>
</form>

