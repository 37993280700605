<h1 mat-dialog-title>{{ 'Panel.ModifyDevice' | translate }}</h1>
<form name="f" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate formValidTimerRange>
  <mat-dialog-content>
    <!-- Row 1 -->
    <div class="container" fxLayout="row" fxLayoutGap="30px">
      <!-- MAC -->
      <div class="field-container" fxFlex>
        <mat-form-field>
          <input name="mac" [ngModel]="panel.mac" placeholder="MAC" matInput disabled/>
        </mat-form-field>
      </div>

      <!-- Type -->
      <div class="field-container" fxFlex>
        <mat-form-field>
          <input name="type" [ngModel]="panel.type" placeholder='{{"Panel.Type" | translate}}' matInput disabled/>
        </mat-form-field>
      </div>
    </div>

    <!-- Row 2 -->
    <div class="container" fxLayout="row" fxLayoutGap="30px">
      <!-- Receiver -->
      <div class="field-container" fxFlex>
        <mat-form-field>
          <input name="receiverName" [ngModel]="panel.receiverName" placeholder='{{"Panel.Receiver" | translate}}' matInput disabled/>
        </mat-form-field>
      </div>
      <!-- Company -->
      <div class="field-container" *ngIf="this.authService.isAdmin()" fxFlex>
        <mat-form-field>
          <input name="companyName" [ngModel]="this.authService.getCompanyName(panel.company)" placeholder='{{"AppComponent.Company" | translate}}' matInput disabled/>
        </mat-form-field>
      </div>
    </div>

    <!-- Row 3 -->
    <div class="container" fxLayout="row" fxLayoutGap="30px">
      <!-- eth -->
      <div class="field-container" fxFlex>
        <mat-form-field>
          <input name="ethSignal" [ngModel]="panel.ethSignal" placeholder='{{"Panel.EtherSignal" | translate}}' matInput disabled/>
        </mat-form-field>
      </div>

      <!-- mesh -->
      <div class="field-container" fxFlex>
        <mat-form-field>
          <input name="meshSignal" [ngModel]="panel.meshSignal" placeholder='{{"Panel.MeshSignal" | translate}}' matInput disabled/>
        </mat-form-field>
      </div>

      <!-- mobile 3G/4G -->
      <div class="field-container" fxFlex>
        <mat-form-field>
          <input name="mobileSignal" [ngModel]="panel.mobileSignal" placeholder='{{"Panel.3g4gSignal" | translate}}' matInput disabled/>
        </mat-form-field>
      </div>
    </div>

    <!-- Row 4 -->
    <div class="container" fxLayout="row" fxLayoutGap="30px">
      <!-- ssid -->
      <div class="field-container" fxFlex>
        <mat-form-field>
          <input name="ssid" [ngModel]="panel.ssid" placeholder="SSID" matInput disabled/>
        </mat-form-field>
      </div>

      <!-- wifi -->
      <div class="field-container" fxFlex>
        <mat-form-field>
          <input name="wifiSignal" [ngModel]="panel.wifiSignal" placeholder='{{"Panel.WifiSignal" | translate}}' matInput disabled/>
        </mat-form-field>
      </div>
    </div>

    <!-- Row 5 -->
    <div class="container" fxLayout="row" fxLayoutGap="30px">
      <!-- adminNotes -->
      <div class="field-container" fxFlex>
        <mat-form-field>
          <textarea name="adminNotes" [(ngModel)]="panel.adminNotes" #adminNotes="ngModel" placeholder='{{"Panel.Notes" | translate}}' matInput autocomplete="off"></textarea>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary" [disabled]="isLoading">{{"Panel.Modify" | translate}}</button>
    <button type="button" mat-raised-button (click)="onCancelClick()">{{"Shared.Cancel" | translate}}</button>
  </mat-dialog-actions >
</form>
