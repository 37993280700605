import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

export interface DialogData {
  image: { // Only one, URL or MATICON
    url: string, 
    matIcon: string,
  },
  title: string,
  description: string,
  rightButton: {
    title: string,
    color: string
  },
  leftButton: {
    title: string,
    color: string
  }
}

@Component({
  selector: 'app-color-selector',
  templateUrl: './color-selector.component.html',
  styleUrls: ['./color-selector.component.scss']
})
export class ColorSelectorComponent implements OnInit {
  @Output() accion = new EventEmitter<string>();
  
  sliderColorValue: number = 0;
  selectedColor = '';

  sliderGrayscaleValue: number = 0;
  selectedGrayscale = '';

  combinedColor = '';
  combinedHEX = '';
  combinedRGB = '';
  combinedCMYK = '';

  constructor(
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.onHexColor('#EAB910');
  }

  closeModal() {
    this.accion.emit(undefined);
  }

  saveChanges(): void {
    this.accion.emit(this.combinedHEX);
  }

  calculateCombined() {
    const rbg_combined = this.combineColors(
      this.selectedColor,
      this.selectedGrayscale,
      this.sliderGrayscaleValue
    );

    this.combinedColor = `rgb(${rbg_combined.r}, ${rbg_combined.g}, ${rbg_combined.b})`;
    this.combinedRGB = rbg_combined.r + ', ' + rbg_combined.g + ', ' + rbg_combined.b;
    this.combinedHEX = this.rgbToHex(rbg_combined.r, rbg_combined.g, rbg_combined.b).toUpperCase();

    const cmyK_combined = this.rgbToCmyk(rbg_combined.r, rbg_combined.g, rbg_combined.b);
    this.combinedCMYK = cmyK_combined.c + '% ' + cmyK_combined.m + '% ' + cmyK_combined.y + '% ' + cmyK_combined.k + '%';
  }

  onColorSliderChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.sliderColorValue = +input.value;
    const rgb = this.getRainbowColor(this.sliderColorValue);
    this.selectedColor = `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
    
    this.calculateCombined();
  }

  onGrayscaleSliderChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.sliderGrayscaleValue = +input.value;
    const rgb = this.getGrayscaleColor(this.sliderGrayscaleValue);
    this.selectedGrayscale = `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
    
    this.calculateCombined();
  }

  getRainbowColor(value: number) {
    const rainbowColors = [
      '#FF0000',
      '#FFA500',
      '#FFFF00',
      '#00FF00',
      '#0000FF',
      '#9300FF',
      '#FF00FF',
      '#FF0000'
    ];

    // Color interpolation
    const colorIndex = Math.floor((value / 100) * (rainbowColors.length - 1));
    const nextColorIndex = Math.min(colorIndex + 1, rainbowColors.length - 1);

    const color1 = this.hexToRgb(rainbowColors[colorIndex]);
    const color2 = this.hexToRgb(rainbowColors[nextColorIndex]);

    const percentage = (value % (100 / (rainbowColors.length - 1))) / (100 / (rainbowColors.length - 1));

    const r = Math.round(color1.r + (color2.r - color1.r) * percentage);
    const g = Math.round(color1.g + (color2.g - color1.g) * percentage);
    const b = Math.round(color1.b + (color2.b - color1.b) * percentage);

    return { r, g, b };
  }

  getGrayscaleColor(value: number) {
    const gray = Math.round((1 - value / 100) * 255);
    return { r: gray, g: gray, b: gray };
  }

  combineColors(color1: string, color2: string, grayscaleValue: number) {
    const rgb1 = this.rgbStringToRgb(color1);
    const rgb2 = this.rgbStringToRgb(color2);

    const factor = grayscaleValue / 100; 
    const r = Math.round(rgb1.r * (1 - factor) + rgb2.r * factor);
    const g = Math.round(rgb1.g * (1 - factor) + rgb2.g * factor);
    const b = Math.round(rgb1.b * (1 - factor) + rgb2.b * factor);

    return { r, g, b };
  }

  rgbStringToRgb(rgb: string) {
    const result = rgb.match(/\d+/g)?.map(Number);
    return {
      r: result?.[0] || 0,
      g: result?.[1] || 0,
      b: result?.[2] || 0
    };
  }

  // Back Process - User input a Hex/RBG/CMYK Color
  onHexColor(hexColor: string) {
    if (this.checkHexFormat(hexColor)) {
      const rgb = this.hexToRgb(hexColor);
      const hsl = this.rgbToHsl(rgb.r, rgb.g, rgb.b);
      const cmyk = this.rgbToCmyk(rgb.r, rgb.g, rgb.b);
  
      this.calculateSliders(hexColor, rgb, cmyk, hsl);
    }
  }

  onRGBColor(rgb_unformated: string) {
    if (this.checkRGBFormat(rgb_unformated)) {
      const [r, g, b] = rgb_unformated.split(',').map(Number);

      const rgb = { r, g, b };
      const hsl = this.rgbToHsl(r, g, b);
      const cmyk = this.rgbToCmyk(r, g, b);
      const hex = this.rgbToHex(r, g, b);

      this.calculateSliders(hex, rgb, cmyk, hsl);
    }
  }

  onCMYKColor(cmyk_unformated: string) {
    if (this.checkCMYKFormat(cmyk_unformated)) {
      const [c, m, y, k] = cmyk_unformated.split('%').map(Number);

      const cmyk = { c, m, y, k };
      const rgb = this.cmykToRgb(c, m, y, k);
      const hsl = this.rgbToHsl(rgb.r, rgb.g, rgb.b);
      const hex = this.rgbToHex(rgb.r, rgb.g, rgb.b);

      this.calculateSliders(hex, rgb, cmyk, hsl);
    }
  }

  calculateSliders(hex, rgb, cmyk, hsl) {
    this.sliderColorValue = (hsl.h /360) * 100; 
    const rgb_2 = this.getRainbowColor(this.sliderColorValue);
    this.selectedColor = `rgb(${rgb_2.r}, ${rgb_2.g}, ${rgb_2.b})`;

    this.sliderGrayscaleValue = hsl.s + hsl.l;
    const rgb_gs = this.getGrayscaleColor(this.sliderGrayscaleValue);
    this.selectedGrayscale = `rgb(${rgb_gs.r}, ${rgb_gs.g}, ${rgb_gs.b})`;

    this.combinedHEX = hex.toUpperCase();
    this.combinedRGB = rgb.r + ', ' + rgb.g + ', ' + rgb.b;
    this.combinedCMYK = cmyk.c + '% ' + cmyk.m + '% ' + cmyk.y + '% ' + cmyk.k + '%';
    this.combinedColor = `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
  }

  // COLOR FORMAT CONVERSION
  hexToRgb(hex: string) {
    const bigint = parseInt(hex.slice(1), 16);
    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255
    };
  };

  rgbToHsl = (r: number, g: number, b: number) => {
    r /= 255;
    g /= 255;
    b /= 255;
    const l = Math.max(r, g, b);
    const s = l - Math.min(r, g, b);
    const h = s
      ? l === r
        ? (g - b) / s
        : l === g
        ? 2 + (b - r) / s
        : 4 + (r - g) / s
      : 0;
    return {
      h: Math.round(60 * h < 0 ? 60 * h + 360 : 60 * h),
      s: Math.round(100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0)),
      l: Math.round((100 * (2 * l - s)) / 2),
    };
  };

  rgbToCmyk( r: number, g: number, b: number ) {
    const k = 1 - Math.max(r, g, b) / 255;
    const c = (1 - r / 255 - k) / (1 - k) || 0;
    const m = (1 - g / 255 - k) / (1 - k) || 0;
    const y = (1 - b / 255 - k) / (1 - k) || 0;
    return { c: Math.floor(c * 100), m: Math.floor(m * 100), y: Math.floor(y * 100), k: Math.floor(k * 100) };
  };

  rgbToHex( r: number, g: number, b: number ) {
    const toHex = (n) => n.toString(16).padStart(2, "0").toUpperCase();
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };

  cmykToRgb(c: number, m: number, y: number, k: number) {
    c = c / 100;
    m = m / 100;
    y = y / 100;
    k = k / 100;

    const r = Math.round(255 * (1 - c) * (1 - k));
    const g = Math.round(255 * (1 - m) * (1 - k));
    const b = Math.round(255 * (1 - y) * (1 - k));

    return { r, g, b };
  };

  // Format Controls
  checkHexFormat(hexColor: string) {
    let hex = hexColor.replace(/[^0-9A-Fa-f]/g, ''); 
  
    hex = hex.substring(0, 6);
    this.combinedHEX = `#${hex.toUpperCase()}`;

    return this.combinedHEX.length === 7;
  }

  checkRGBFormat(rgb_unformated: string) {
    const regex = /^\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*$/;
    const match = rgb_unformated.match(regex);
  
    if (!match) return false;
  
    const [r, g, b] = match.slice(1).map(Number);
    return [r, g, b].every(num => num >= 0 && num <= 255);
  }
  
  checkCMYKFormat(checkCMYKFormat: string) {
    const regex = /^\s*(\d{1,2})%\s+(\d{1,2})%\s+(\d{1,2})%\s+(\d{1,2})%\s*$/;
    const match = checkCMYKFormat.match(regex);
  
    if (!match) return false;
  
    const [c, m, y, k] = match.slice(1).map(Number);
    return [c, m, y, k].every(num => num >= 0 && num <= 100);
  }
}
