export class UserApp {
  _id: string;
  email: string;
  pass: string;
  type: string;
  selectedLang: string;
  name: string;
  lastName: string;
  secureAccessTime: number;
  secureAccessWarning: number;
  removeAccessWarning: boolean;
  forceChangePass: boolean;
  forceChangeDisarmPass: boolean;
  company: string;
  partitions: any[];
  active: boolean;
  intelligentNotifications: {
    allowWhatsapp: boolean;
    allowPushNotifications: boolean;
    whatsappNumber: string;
  };

  constructor() {
    this.intelligentNotifications = {
      allowWhatsapp: false,
      allowPushNotifications: false,
      whatsappNumber: '',
    }
  }
}
