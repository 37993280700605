<div id="mySidebar" class="sidebar">
  <div class="breadcrumb-style" *ngIf="isBreadcrumb()">
    <span>{{ hoverMenuesService.breadcrumb }}</span>
  </div>
  <div class="content">
    <div class="tab-bar">
      <div [ngClass]="{ 'tab-bar-unselected': panelStatus.selectedFilter === constants.viewTypes.None }"></div>
      <div [ngClass]="{ 'tab-bar-unselected': panelStatus.selectedFilter === constants.viewTypes.ViewLinking }"></div>
    </div>
    <div class="viewSelector">
      <div
        onKeyPress
        onKeyDown
        onKeyUp
        class="view-tab"
        [ngClass]="{ 'view-selected': panelStatus.selectedFilter === constants.viewTypes.None }"
        (click)="switchView(constants.viewTypes.None)"
      >
        {{ 'MeshNet.ViewNone' | translate }}
      </div>
      <div
        onKeyPress
        onKeyDown
        onKeyUp
        class="view-tab right-tab"
        [ngClass]="{ 'view-selected': panelStatus.selectedFilter === constants.viewTypes.ViewLinking }"
        (click)="switchView(constants.viewTypes.ViewLinking)"
      >
        {{ 'MeshNet.ViewLinking' | translate }}
      </div>
    </div>
    <div class="box">
      <!-- Status level -->
      <div class="status-box">
        <div *ngIf="panelStatus.isViewLinking()">
          <div class="row">
            <div
              [tooltipDeitres]="'MeshNet.ConditionsNeighbors' | translate"
              [tooltipMeshStatus]="true"
              [tooltipCustomLeft]="-6"
              [tooltipCustomTop]="60"
              class="box-column-neigbor"
            >
              <img alt class="img-status-neighbor" [src]="getImageSource('disconnected', 3)" />
              <img alt class="img-status-neighbor" [src]="getImageSource('disconnected', 2)" />
              <img alt class="img-status-neighbor" [src]="getImageSource('disconnected', 1)" />
              <img alt class="img-status-neighbor" [src]="getImageSource('disconnected', 0)" />
              <div class="status-count-one neighbors">{{ panelStatus.neighborCountFor('disconnected') }}</div>
            </div>
            <div
              [tooltipDeitres]="'MeshNet.ConditionsNeighbors' | translate"
              [tooltipMeshStatus]="true"
              [tooltipCustomLeft]="-16"
              [tooltipCustomTop]="60"
              class="box-column-neigbor"
            >
              <img alt class="img-status-neighbor" [src]="getImageSource('null', 3)" />
              <img alt class="img-status-neighbor" [src]="getImageSource('null', 2)" />
              <img alt class="img-status-neighbor" [src]="getImageSource('null', 1)" />
              <img alt class="img-status-neighbor" [src]="getImageSource('null', 0)" />
              <div class="status-count-one neighbors">{{ panelStatus.neighborCountFor('null') }}</div>
            </div>
            <div
              [tooltipDeitres]="'MeshNet.ConditionsNeighbors' | translate"
              [tooltipMeshStatus]="true"
              [tooltipCustomLeft]="-16"
              [tooltipCustomTop]="60"
              class="box-column-neigbor"
            >
              <img alt class="img-status-neighbor" [src]="getImageSource('low', 3)" />
              <img alt class="img-status-neighbor" [src]="getImageSource('low', 2)" />
              <img alt class="img-status-neighbor" [src]="getImageSource('low', 1)" />
              <img alt class="img-status-neighbor" [src]="getImageSource('low', 0)" />
              <div class="status-count-one neighbors">{{ panelStatus.neighborCountFor('low') }}</div>
            </div>
            <div
              [tooltipDeitres]="'MeshNet.ConditionsNeighbors' | translate"
              [tooltipMeshStatus]="true"
              [tooltipCustomLeft]="-16"
              [tooltipCustomTop]="60"
              class="box-column-neigbor"
            >
              <img alt class="img-status-neighbor" [src]="getImageSource('good', 3)" />
              <img alt class="img-status-neighbor" [src]="getImageSource('good', 2)" />
              <img alt class="img-status-neighbor" [src]="getImageSource('good', 1)" />
              <img alt class="img-status-neighbor" [src]="getImageSource('good', 0)" />
              <div class="status-count-one neighbors">{{ panelStatus.neighborCountFor('good') }}</div>
            </div>
            <div
              [tooltipDeitres]="'MeshNet.ConditionsNeighbors' | translate"
              [tooltipMeshStatus]="true"
              [tooltipCustomLeft]="-16"
              [tooltipCustomTop]="60"
              class="box-column-neigbor"
            >
              <img alt class="img-status-neighbor" [src]="getImageSource('optimum', 3)" />
              <img alt class="img-status-neighbor" [src]="getImageSource('optimum', 2)" />
              <img alt class="img-status-neighbor" [src]="getImageSource('optimum', 1)" />
              <img alt class="img-status-neighbor" [src]="getImageSource('optimum', 0)" />
              <div class="status-count-one neighbors">{{ panelStatus.neighborCountFor('optimum') }}</div>
            </div>
          </div>
        </div>
        <div *ngIf="!panelStatus.isViewLinking()">
          <div class="row">
            <div
              [tooltipDeitres]="conditionTranslation"
              [tooltipSize]="'large'"
              [tooltipCustomLeft]="-6"
              [tooltipCustomTop]="60"
              class="box-column"
            >
              <img alt class="img-status" [src]="getImageSource('null', 3)" />
              <img alt class="img-status" [src]="getImageSource('null', 2)" />
              <img alt class="img-status" [src]="getImageSource('null', 1)" />
              <img alt class="img-status" [src]="getImageSource('null', 0)" />
              <div class="status-count-one">{{ panelStatus.rendererStatusControl.null }}</div>
            </div>
            <div
              [tooltipDeitres]="conditionTranslation"
              [tooltipSize]="'large'"
              [tooltipCustomLeft]="-6"
              [tooltipCustomTop]="60"
              class="box-column"
            >
              <img alt class="img-status" [src]="getImageSource('low', 3)" />
              <img alt class="img-status" [src]="getImageSource('low', 2)" />
              <img alt class="img-status" [src]="getImageSource('low', 1)" />
              <img alt class="img-status" [src]="getImageSource('low', 0)" />
              <div class="status-count-one">{{ panelStatus.rendererStatusControl.low }}</div>
            </div>
            <div
              [tooltipDeitres]="conditionTranslation"
              [tooltipSize]="'large'"
              [tooltipCustomLeft]="-6"
              [tooltipCustomTop]="60"
              class="box-column"
            >
              <img alt class="img-status" [src]="getImageSource('good', 3)" />
              <img alt class="img-status" [src]="getImageSource('good', 2)" />
              <img alt class="img-status" [src]="getImageSource('good', 1)" />
              <img alt class="img-status" [src]="getImageSource('good', 0)" />
              <div class="status-count-one">{{ panelStatus.rendererStatusControl.good }}</div>
            </div>
            <div
              [tooltipDeitres]="conditionTranslation"
              [tooltipSize]="'large'"
              [tooltipCustomLeft]="-6"
              [tooltipCustomTop]="60"
              class="box-column"
            >
              <img alt class="img-status" [src]="getImageSource('optimum', 3)" />
              <img alt class="img-status" [src]="getImageSource('optimum', 2)" />
              <img alt class="img-status" [src]="getImageSource('optimum', 1)" />
              <img alt class="img-status" [src]="getImageSource('optimum', 0)" />
              <div class="status-count-one">{{ panelStatus.rendererStatusControl.optimum }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- Panel Count -->
      <div class="progress-bar" *ngIf="panelsService.fetchingPanels">
        <mat-progress-bar
          [value]="getLoadingStatus()"
        ></mat-progress-bar>
        <span>{{ getLoadingStatus() + '%' }}</span>
      </div>
      <div class="count-box" onKeyPress onKeyDown onKeyUp (click)="handleShowLegends()">
        <button mat-button class="count-box-button">
          {{ getTotal() }} {{ 'MeshNet.Devices' | translate }}
          <img
            alt
            class="img-button"
            [src]="showLegends ? 'assets/icons/menues/arrow_up.svg' : 'assets/icons/menues/arrow_down.svg'"
          />
        </button>
      </div>
      <!-- LEGENDS -->
      <div class="legends showLegends" [ngClass]="showLegends ? 'active' : 'inactive'">
        <div class="legends-content">
          <span style="color: #98a1b0; font-size: 12px; font-weight: 500; margin-left: 5px">{{
            'MeshNet.FilterByConnectivity' | translate
          }}</span>
          <div class="connection-type-container">
            <div *ngFor="let g of glossary['ViewConectivity']" matTooltip="{{ 'MeshNet.' + g.type | translate }}">
              <button
                mat-button
                class="button-item"
                [ngClass]="{ 'button-item-selected': conectivityTypeFilter.includes(g.value) }"
                (click)="mapFilter({ value: g.value, type: 'ViewConectivity' })"
              >
                <img alt class="img-glossary" [src]="g.icon" />

                {{ +getTypeCountLegends('ViewConectivity', g) }}
              </button>
            </div>
          </div>
        </div>
        <div class="legends-content">
          <span style="color: #98a1b0; font-size: 12px; font-weight: 500; margin-left: 5px">{{
            'MeshNet.FilterByInstallation' | translate
          }}</span>
          <div class="installation-type-container">
            <div
              *ngFor="let g of glossary['ViewInstallation']"
              matTooltip="{{ 'MeshNet.' + g.type | translate }}"
              class="installation-type-item"
            >
              <button
                type="button"
                class="button-item installation-type-button"
                [ngClass]="{
                  'button-item-selected': installationTypeFilter.includes(g.value)
                }"
                (click)="mapFilter({ value: g.value, type: 'ViewInstallation' })"
              >
                <img alt class="img-glossary-installation" [src]="g.icon" />

                {{ getTypeCountLegends('ViewInstallation', g) }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
