<h1 mat-dialog-title>{{ this.data.title | translate}}</h1>
<form name="f" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
  <mat-dialog-content>
    <!-- Row 0 -->
    <div class="container" fxLayout="row" fxLayoutGap="30px">
      <!-- Tipo -->
      <div class="field-container" fxFlex>
        <mat-form-field>
          <mat-label>{{'User.Type' | translate}}</mat-label>
          <select matNativeControl [(ngModel)]="user.type" name="type" required>
            <option value="final" selected>{{'User.Final' | translate}}</option>
            <option value="tech">{{'User.Technician' | translate}}</option>
          </select>
        </mat-form-field>
      </div>
      <!-- Idioma -->
      <div class="field-container" fxFlex>
        <mat-form-field>
          <mat-label>{{'AppComponent.Language' | translate}}</mat-label>
          <select matNativeControl [(ngModel)]="this.user.selectedLang" name="selectedLang" required>
            <option value="pt">{{'AppComponent.LanguagePortuguese' | translate}}</option>
            <option value="en">{{'AppComponent.LanguageEnglish' | translate}}</option>
            <option value="es">{{'AppComponent.LanguageSpanish' | translate}}</option>
          </select>
        </mat-form-field>
      </div>
      <!-- Empresa - Solo para usuarios multiempresa -->
      <div class="field-container" *ngIf="this.authService.isAdmin()" fxFlex>
        <mat-form-field>
          <mat-label>{{"AppComponent.Company" | translate}}</mat-label>
          <select name="selectedCompany" [(ngModel)]="user.company" matNativeControl required [disabled]="this.data.operation === 'update'">
            <option [ngValue]="undefined" selected>{{'Partition.SelectCompany' | translate}}</option>
            <option *ngFor="let c of this.authService.user.companies" [ngValue]="c._id">{{ c.name }}</option>
          </select>
        </mat-form-field>
      </div>
    </div>
    <!-- Row 1 -->
    <div class="container" fxLayout="row" fxLayoutGap="30px">
      <!-- Usuario -->
      <div class="field-container" fxFlex>
        <mat-form-field>
          <input matInput  name="email" type="email" customEmail [(ngModel)]="user.email" (ngModelChange)="emailErrorMatcher.setUserExists(false)" #email="ngModel" placeholder='{{"User.Email" | translate}}' matInput email required autocomplete="false" [errorStateMatcher]="emailErrorMatcher"/>
          <button type="button" *ngIf="user.email" matSuffix mat-icon-button aria-label="Clear" (click)="user.email=''" tabindex="-1">
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="email.invalid && email.errors?.required">{{'User.RequiredField' | translate}}</mat-error>
          <mat-error *ngIf="user.email && email.invalid && email.errors?.customEmail">{{'User.InvalidFormat' | translate}}</mat-error>
          <mat-error *ngIf="emailErrorMatcher.getUserExists()">{{'User.EmailUsed' | translate}}</mat-error>
        </mat-form-field>
      </div>
      <!-- Contraseña -->
      <div class="field-container" fxFlex>
        <mat-form-field>
          <button type="button" matPrefix [disabled]="generatingPassword" type="button" mat-button (click)="generatePassword()" class="genPassButton" color="accent">
            {{( generatingPassword ? 'User.Generating' : data.operation === 'add' ? 'User.Generate' : 'User.Regenerate' ) | translate}}
          </button>
          <input matInput  name="password" [ngModel]="user.pass" #password="ngModel" matInput readonly autocomplete="false" class="generatedPassInput" [required]="data.operation === 'add' && !generatingPassword">
          <mat-spinner matSuffix *ngIf="generatingPassword" class="generatedPassInput" [diameter]="20" color="accent"></mat-spinner>
          <mat-hint> {{'User.PassSend' | translate}}</mat-hint>
          <mat-error *ngIf="password.invalid && password.errors?.required">{{'User.RequiredField' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </div>
    <!-- Row 2 -->
    <div class="container" fxLayout="row" fxLayoutGap="30px">
      <!-- Nombre -->
      <div class="field-container" fxFlex>
        <mat-form-field>
          <input matInput  name="name" [(ngModel)]="user.name" #name="ngModel" placeholder='{{"User.Name" | translate}}' minlength="2" maxlength="20" matInput required autocomplete="false"/>
          <button type="button" *ngIf="user.name" matSuffix mat-icon-button aria-label="Clear" (click)="user.name=''" tabindex="-1">
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="name.invalid && name.errors.required">{{'User.RequiredField' | translate}}</mat-error>
          <mat-error *ngIf="name.invalid && name.errors.minlength">{{'User.MinField' | translate}}</mat-error>
          <mat-error *ngIf="name.invalid && name.errors.maxlength">{{'User.MaxField' | translate}}</mat-error>
        </mat-form-field>
      </div>

      <!-- Apellido -->
      <div class="field-container" fxFlex>
        <mat-form-field>
          <input matInput name="lastName" [(ngModel)]="user.lastName" #lastName="ngModel" placeholder='{{"User.Surname" | translate}}' minlength="2" maxlength="20" matInput required autocomplete="false"/>
          <button type="button" *ngIf="user.lastName" matSuffix mat-icon-button aria-label="Clear" (click)="user.lastName=''" tabindex="-1">
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="lastName.invalid && lastName.errors.required">{{'User.RequiredField' | translate}}</mat-error>
          <mat-error *ngIf="lastName.invalid && lastName.errors.minlength">{{'User.MinField' | translate}}</mat-error>
          <mat-error *ngIf="lastName.invalid && lastName.errors.maxlength">{{'User.MaxField' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <!-- Row 3 -->
    <div class="container" fxLayout="row" fxLayoutGap="30px">
      <!-- Tiempo de acceso seguro -->
      <div class="field-container" fxFlex>
        <mat-form-field>
          <input matInput type="number" name="secureAccessTime" [(ngModel)]="user.secureAccessTime" #secureAccessTime="ngModel" placeholder='{{"User.SecureAccess" | translate}}' (ngModelChange)="validateSecureAccessTime()" [errorStateMatcher]="secureAccessTimeErrorMatcher" matInput required autocomplete="false"/>
          <button type="button" *ngIf="user.secureAccessTime" matSuffix mat-icon-button aria-label="Clear" (click)="user.secureAccessTime = defaultSAT" tabindex="-1">
            <mat-icon>close</mat-icon>
          </button>
          <mat-hint> {{'User.Time' | translate}} </mat-hint>
          <mat-error *ngIf="user.secureAccessTime === undefined">{{'User.RequiredField' | translate}}</mat-error>
          <mat-error *ngIf="user.secureAccessTime && (user.secureAccessTime > maxSAT || user.secureAccessTime < minSAT )">{{'User.InvalidValue' | translate}}</mat-error>
        </mat-form-field>
      </div>

      <!-- Tiempo de aviso de acceso seguro -->
      <div class="field-container" fxFlex>
        <mat-form-field>
          <input matInput type="number" name="secureAccessWarning" [(ngModel)]="user.secureAccessWarning" #secureAccessWarning="ngModel" placeholder='{{"User.SecureAccessWarn" | translate}}' (ngModelChange)="validateSecureAccessWarning()" [errorStateMatcher]="secureAccessWarningErrorMatcher" matInput autocomplete="false"/>
          <button type="button" *ngIf="user.secureAccessWarning" matSuffix mat-icon-button aria-label="Clear" (click)="user.secureAccessWarning = undefined" tabindex="-1">
            <mat-icon>close</mat-icon>
          </button>
          <mat-hint> {{'User.Time' | translate}} </mat-hint>
          <mat-error *ngIf="user.secureAccessWarning && (user.secureAccessWarning > maxSAT || user.secureAccessWarning < minSAT )">{{'User.InvalidValue' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="chatbotAdministrador">
      <!-- Row 4 -->
      <div class="container" fxLayout="row" fxLayoutGap="30px">
        <!-- AllowPushNotifications -->
        <div class="field-container" fxFlex>
          <mat-form-field>
            <mat-label>{{'User.AllowPushNotifications' | translate}}</mat-label>
            <select matNativeControl [(ngModel)]="allowPushNotifications" name="allowPushNotifications" required     
            matTooltip='{{"User.AllowPushNotifications" | translate}}'>
              <option value="true" selected>{{'User.Yes' | translate}}</option>
              <option value="false">{{'User.No' | translate}}</option>
            </select>
          </mat-form-field>
        </div>
        <!-- AllowWhatsapp -->
        <div class="field-container" fxFlex>
          <mat-form-field>
            <mat-label>{{'User.AllowWhatsapp' | translate}}</mat-label>
            <select matNativeControl [(ngModel)]="allowWhatsapp" name="allowWhatsapp" required     
            matTooltip='{{"User.AllowWhatsapp" | translate}}'>
              <option value="true" selected>{{'User.Yes' | translate}}</option>
              <option value="false">{{'User.No' | translate}}</option>
            </select>
          </mat-form-field>
        </div>
      </div>

      <!-- Row 4 -->
      <div class="container" fxLayout="row" fxLayoutGap="30px">
        <!-- WhatsappNumber -->
        <div class="field-container" fxFlex>
          <mat-form-field>
            <mat-label>{{'User.WhatsappNumber' | translate}}</mat-label>
            <input matNativeControl type="text" pattern="^[0-9]*$" inputmode="number" [(ngModel)]="whatsappNumber" name="whatsappNumber"     
            matTooltip='{{"User.WhatsappNumber" | translate}}'/>
          </mat-form-field>
        </div>
      </div>
    </div>

    <!-- Row 5 -->
    <div *ngIf="data.operation !== 'add'" class="container" fxLayout="row" fxLayoutGap="30px">
      <!-- Blanqueo de pines -->
      <div class="field-container" fxFlex>
        <mat-label *ngIf="user.forceChangeDisarmPass">{{'User.ResetDisarmPass' | translate}}</mat-label>
        <button *ngIf="!user.forceChangeDisarmPass" type="button" color="primary" mat-raised-button (click)="resetingDisarmPass()" style="width: 50%;">
          {{( resetDisarmPass ? 'User.CancelResetPass' : 'User.ResetDisPass' ) | translate}}
        </button>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary" [disabled]="!isValidData()">{{ this.data.submitText | translate }}</button>
    <button type="button" mat-raised-button (click)="onCancelClick()">{{'Shared.Cancel' | translate}}</button>
  </mat-dialog-actions >
</form>
