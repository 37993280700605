import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CompaniesService } from 'src/app/settings/services/companies.service';
import { ContextualMenuService } from 'src/app/settings/services/contextual-menu.service';
import { CreateUserComponent } from './create-user/create-user.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Receiver, Sections, User } from 'src/app/settings/constants';
import { CompanyUserService } from 'src/app/settings/services/company-user.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import constants from 'src/app/shared/constants';
import { ConfirmActionDialogNewComponent } from 'src/app/shared/components/confirm-action-dialog-new-style/confirm-action-dialog.component';
import { CreateReceiverComponent } from './create-receiver/create-receiver.component';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-company-edit-users',
  templateUrl: './company-edit-users.component.html',
  styleUrls: ['./company-edit-users.component.scss']
})
export class CompanyEditUsersComponent implements OnInit {

  receiverMessage: SafeHtml;
  userMessage: SafeHtml;

  constructor(
    public contextualMenuService: ContextualMenuService,
    public companiesService: CompaniesService,
    public dialog: MatDialog,
    private readonly translateService: TranslateService,
    private readonly matIconRegistry: MatIconRegistry, 
    private readonly domSanitizer: DomSanitizer,
    private readonly companyUserService: CompanyUserService,
    private readonly authService: AuthService,
    private readonly snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.matIconRegistry.addSvgIcon(
      'delete_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/delete.svg')
    );
    this.getTranslation();
  }
  
  getTranslation = async () => {
    this.receiverMessage = this.domSanitizer.bypassSecurityTrustHtml(await lastValueFrom(this.translateService.get('Settings.AddReceiver_desc')));
    this.userMessage = this.domSanitizer.bypassSecurityTrustHtml(await lastValueFrom(this.translateService.get('Settings.AddUser_desc')));
  }

  ngOnDestroy(): void {
    this.contextualMenuService.onClose();
  }

  createReceiver() {
    const dialogRef = this.dialog.open(CreateReceiverComponent, {
      width: '70%',
      minWidth: '550px',
      maxWidth: '700px',
      data: {
      },
    });

    dialogRef.afterClosed().subscribe((result: { status: string; newReceiver: Receiver }) => {
      if (result.status === 'confirm') this.onSendCreateReceiver(result.newReceiver);
    });
  }

  onSendCreateReceiver(newReceiver) {
    if (!this.companiesService.currentCompany.receivers) this.companiesService.currentCompany.receivers = [];
    this.companiesService.currentCompany.receivers.push(newReceiver);
  }

  deleteReceiver(receiver) {
    this.companiesService.currentCompany.receivers = this.companiesService.currentCompany.receivers.filter(r => r.apiKey !== receiver.apiKey);
  }

  createUser() {
    const dialogRef = this.dialog.open(CreateUserComponent, {
      width: '40%',
      minWidth: '550px',
      maxWidth: '600px',
      data: {
      },
    });

    dialogRef.afterClosed().subscribe((result: { status: string; newUser: User }) => {
      if (result.status === 'confirm') {
        if (this.companiesService.currentSection === Sections.Edit) {
          this.onSendCreate(result.newUser);
        } else {
          if (!this.companiesService.currentCompany.users) this.companiesService.currentCompany.users = [];
          this.companiesService.currentCompany.users.push(result.newUser);
        }
      }
    });
  }
  
  onSendCreate(newUser) {
    this.companiesService.loading = true;
    this.companyUserService.createUser(newUser).subscribe({
      next: (res: any) => {
        this.companiesService.loading = false;
        this.onSuccessCreate({ ...res.user, ...newUser });
      },
      error: (error) => {
        this.companiesService.loading = false;
        if (error?.status === 401) {
          this.snackBar.open(
            this.translateService.instant('Shared.SessionExpired'),
            this.translateService.instant('Shared.Close'),

            { duration: constants.snackBarErrorDuration }
          );
          this.authService.logout();
        } else {
          this.snackBar.open(
            this.translateService.instant('Settings.Error.NoGetCompany'),
            this.translateService.instant('Shared.Close'),

            { duration: constants.snackBarErrorDuration }
          );
        }
      },
    });
  }

  onSuccessCreate(newUser) {
    this.companiesService.currentCompany.users.push(newUser);
    const dialogRef = this.dialog.open(ConfirmActionDialogNewComponent, {
      width: '400px',
      data: {
        image: {
          svg: 'assets/icons/mail-check.svg',
        },
        title: 'Settings.CreateUserSuccess',
        description: this.translateService.instant('Settings.CreateUserSuccess_desc').replace('{{email}}', newUser?.email || 'example@deitres.com'),
        rightButton: {
          title: 'OK',
          color: {
            background: '#EAB910',
            font: 'black'
          }
        }
      },
    });

    dialogRef.afterClosed().subscribe((result: { status: string; topics: any }) => {
    });
  }

  deleteUser(user) {
    const dialogRef = this.dialog.open(ConfirmActionDialogNewComponent, {
      width: '400px',
      data: {
        title: 'Settings.ExcludeUserRequest',
        description: this.translateService.instant('Settings.ExcludeUserRequest_desc').replace('{{email}}', user.email),
        rightButton: {
          title: 'Settings.ExcludeUserRequest_confirm',
          color: {
            background: 'red',
            font: 'white'
          }
        },
        leftButton: {
          title: 'Shared.Cancel',
          color: {
            background: 'white',
            font: 'black'
          }
        }
      },
    });

    dialogRef.afterClosed().subscribe((result: { status: string; topics: any }) => {
      if (result?.status === 'right') {
        if (this.companiesService.currentSection === Sections.Edit) {
          this.onSendDelete(user._id);
        } else if (this.companiesService.currentCompany.users) {
          this.companiesService.currentCompany.users = this.companiesService.currentCompany.users.filter(u => u.email != user.email);
        }
      }
    });
  }

  onSendDelete(userID) {
    this.companiesService.loading = true;
    this.companyUserService.deleteUser(userID).subscribe({
      next: (res: any) => {
        this.companiesService.loading = false;
        this.companiesService.currentCompany.users = this.companiesService.currentCompany.users.filter(u => u._id !== userID);
        this.onSuccessDelete();
      },
      error: (error) => {
        this.companiesService.loading = false;
        if (error?.status === 401) {
          this.snackBar.open(
            this.translateService.instant('Shared.SessionExpired'),
            this.translateService.instant('Shared.Close'),

            { duration: constants.snackBarErrorDuration }
          );
          this.authService.logout();
        } else {
          this.snackBar.open(
            this.translateService.instant('Settings.Error.NoGetCompany'),
            this.translateService.instant('Shared.Close'),

            { duration: constants.snackBarErrorDuration }
          );
        }
      },
    });
  }

  onSuccessDelete() {
    const dialogRef = this.dialog.open(ConfirmActionDialogNewComponent, {
      width: '400px',
      data: {
        title: 'Settings.ExcludeUserSuccess',
        rightButton: {
          title: 'Ok',
          color: {
            background: '#EAB910',
            font: 'black'
          }
        }
      },
    });

    dialogRef.afterClosed().subscribe((result: { status: string; topics: any }) => {
    });
  }

  isLast(item, array) {
    return (array[array.length - 1] === item)
  }

  disableCreateUser() {
    return !this.companiesService.currentCompany?.receivers?.length;
  }
}
