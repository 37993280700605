import { Component, Inject, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DialogDataPanelConfig } from '../config-panel/config-panel.component';
import { TranslateService } from '@ngx-translate/core';
import { PanelService } from '../../services/panel.service';
import { PartitionFormComponent } from 'src/app/partitions/components/partition-form/partition-form.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PartitionsService } from 'src/app/shared/services/partitions.service';
import constants from 'src/app/shared/constants';

@Component({
  selector: 'app-databus-configuration',
  templateUrl: './databus-configuration.component.html',
  styleUrls: ['./databus-configuration.component.scss'],
})
export class DatabusConfigurationComponent implements OnInit {
  panelTypes = { 5: 'DSC', 6: 'Paradox' };

  iconByState = {
    0: 'radio_button_unchecked',
    2: 'check_circle',
    3: 'cancel',
  };

  states = {
    READY: 0,
    ONPROCESS: 1,
    FINISHED: 2,
    FAILED: 3,
  };
  currentStep = 0;
  finished = false;
  steps = [
    {
      id: 0,
      title: 'ValidateFirmware',
      state: this.states.READY,
    },
    {
      id: 1,
      title: 'ConfigurePanelModel',
      state: this.states.READY,
    },
  ];

  requestDisarmPin: boolean;
  newPin: string;

  DSC = [
    {
      id: 0,
      title: 'ValidateFirmware',
      state: this.states.READY,
    },
    {
      id: 1,
      title: 'ConfigurePanelModel',
      state: this.states.READY,
    },
    {
      id: 3,
      title: 'ConfigureLowBattery',
      state: this.states.READY,
    },
  ];
  Paradox = [
    {
      id: 0,
      title: 'ValidateFirmware',
      state: this.states.READY,
    },
    // Password Config is not necessary for Paradox (only for JFL and Intelbras)
    // {
    //   id: 2,
    //   title: 'ConfigureKeyboardPIN',
    //   state: this.states.READY
    // },
    {
      id: 1,
      title: 'ConfigurePanelModel',
      state: this.states.READY,
    },
    {
      id: 3,
      title: 'ConfigureLowBattery',
      state: this.states.READY,
    },
  ];

  constructor(
    private readonly partitionsDataService: PartitionsService,
    private readonly translateService: TranslateService,
    private readonly panelService: PanelService,
    public dialogRef: MatDialogRef<DatabusConfigurationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataPanelConfig,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    // Check Panel Model Steps
    switch (this.data.panelModel) {
      case 0:
        this.requestDisarmPin = false;
        break;
      case 5:
        this.requestDisarmPin = false;
        this.steps = this.DSC;
        break;
      case 6:
        this.requestDisarmPin = false;
        this.steps = this.Paradox;
        break;
    }
    // 1° Require PIN (requestDisarmPin)
    // 2° Start config process
    if (!this.requestDisarmPin) this.processNextStep();
  }

  defaultCallback = (res, params) => {
    this.steps[this.currentStep].state = res.success;
    if (res.success) {
      // Set Step finished and folow next step
      this.steps[this.currentStep].state = this.states.FINISHED;
      this.currentStep++;
      this.processNextStep();
      if (params.type === 'panelConfig') {
        this.data.panel.version.panelType = this.panelTypes[this.data.panelModel];
        this.partitionsDataService.setKeyboard(this.data.panel.mac, !!this.data.panelModel);
      }
    } else {
      // Set step failed and stop the process.
      this.steps[this.currentStep].state = this.states.FAILED;
      this.finished = true;
    }
  };

  processNextStep = () => {
    if (this.steps.length <= this.currentStep) {
      // Finish the whole process
      this.finished = true;
      return;
    }
    // Start next step
    this.steps[this.currentStep].state = this.states.ONPROCESS;
    switch (this.steps[this.currentStep].id) {
      case 0:
        this.processCheckFirmware();
        break;
      case 1:
        this.processConfigPanelModel();
        break;
      case 2:
        this.processConfigurePIN();
        break;
      case 3:
        this.processConfigureLowBattery();
        break;
    }
  };

  processCheckFirmware = () => {
    // Request Firmware version Check
    // Minimum firmware version controled server side.
    this.panelService.httpRequest(
      this.panelService.dataBusFirmwareVersion,
      [this.data.panel._id],
      this.defaultCallback,
      {},
      'Panel.FailedConfDev'
    );
  };

  processConfigPanelModel = () => {
    // Config selected Panel Model
    const params = [this.data.panel._id, this.data.panelModel];
    this.panelService.httpRequest(
      this.panelService.panelModel,
      params,
      this.defaultCallback,
      { type: 'panelConfig' },
      'Panel.FailedConfDev'
    );
  };

  processConfigurePIN = () => {
    // Config disarm PIN for databus (JFL and Intelbras)
    const params = [this.data.panel._id, { dissarmPass: this.newPin, databus: true, partitionNumber: 0 }];
    this.panelService.httpRequest(
      this.panelService.dissarmPass,
      params,
      this.defaultCallback,
      {},
      'Panel.FailedConfDev'
    );
  };

  processConfigureLowBattery = () => {
    const params = [this.data.panel._id, this.data.panelModel];
    this.panelService.httpRequest(
      this.panelService.databusLowBattery,
      params,
      this.defaultCallback,
      {},
      'Panel.FailedConfDev'
    );
  }

  retry = () => {
    switch (this.data.panelModel) {
      case 0:
      case 5:
      case 6:
        // Restar without require PIN.
        this.requestDisarmPin = false;
        break;
      case 1:
      case 2:
      case 3:
      case 4:
        // Restar requiring PIN (JFL & Intelbras)
        this.requestDisarmPin = true;
        this.newPin = '';
        break;
    }

    // Steps back to READY
    for (let s of this.steps) {
      s.state = this.states.READY;
    }

    this.currentStep = 0;
    if (!this.requestDisarmPin) this.processNextStep();
  };

  confirmPin = async () => {
    // PIN control
    if (this.newPin?.length === 4) {
      this.requestDisarmPin = false;
      this.processNextStep();
    } else {
      this.snackBar.open(
        await lastValueFrom(this.translateService.get('Panel.InvalidPin')),
        await lastValueFrom(this.translateService.get('Shared.Close')),
        { duration: constants.snackBarDuration }
      );
    }
  };

  validateInput(event: any) {
    // PIN input controls
    // Numeric, 4 digit
    const pattern = /^\d*$/;
    const replacePattern = /\D/g;

    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(replacePattern, '');
      this.newPin = event.target.value;
    }
    event.target.value = event.target.value.toUpperCase();
    this.newPin = event.target.value;
  }

  onNewPartitionClick = () => {
    // New partition modal.
    // Close the current modal on success.
    const newPRef = this.dialog.open(PartitionFormComponent, {
      width: '65%',
      maxWidth: '1000px',
      autoFocus: false,
      data: { title: 'Partition.NewPartition', submitText: 'Partition.AddPartition', operation: 'add' },
    });

    newPRef.afterClosed().subscribe(async (result) => {
      this.dialogRef.close({ status: 'success', installed: this.finished && this.currentStep === this.steps.length });
      if (result?.status !== 'cancel') {
        this.snackBar.open(result.message, await lastValueFrom(this.translateService.get('Shared.Close')), {
          duration: constants.snackBarDuration,
        });
      }
    });
  };

  onCancel = () => {
    this.dialogRef.close({ status: 'success', installed: this.finished && this.currentStep === this.steps.length });
  };
}
