<div class="container">
  <div class="half left">
    <img class="img" src="assets/img/equipo+app.png" alt="logo equipo" />
    <img class="by-deitres" src="assets/img/by_deitres.png" alt="logo" />
  </div>
  <div class="half right">
    <div class="login-form">
      <img src="assets/img/citymesh.png" alt="logo" />
      <div class="start-session">{{ 'Login.StartSession' | translate }}</div>
      <form name="f" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
        <!-- Usuario -->
        <mat-form-field class="input-login">
          <input
            name="email"
            customEmail
            type="email"
            [(ngModel)]="user.email"
            #email="ngModel"
            placeholder="{{ 'Login.Email' | translate }}"
            matInput
            email
            required
            autocomplete="off"
          />
          <button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="user.email = ''" tabindex="-1">
            <mat-icon>close</mat-icon>
          </button>
          <mat-error *ngIf="email.invalid && email.errors?.required">{{ 'Login.RequiredField' | translate }}</mat-error>
          <mat-error *ngIf="user.email && email.invalid && email.errors?.customEmail">{{
            'Login.InvalidFormat' | translate
          }}</mat-error>
        </mat-form-field>

        <!-- Contraseña -->
        <div style="display: flex">
          <mat-form-field class="input-login">
            <input
              [type]="showPassword ? 'text' : 'password'"
              show-password="showPassword"
              class="password"
              name="pass"
              [(ngModel)]="user.pass"
              #password="ngModel"
              matInput
              autocomplete="off"
              placeholder="{{ 'Login.Password' | translate }}"
              required
            />
            <button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="user.pass = ''" tabindex="-1">
              <mat-icon>close</mat-icon>
            </button>
            <button type="button" matSuffix mat-icon-button (click)="showPassword = !showPassword" tabindex="-1">
              <mat-icon> {{ showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
            </button>
            <mat-error *ngIf="password.invalid && password.errors?.required">{{
              'Login.RequiredField' | translate
            }}</mat-error>
          </mat-form-field>
        </div>

        <div class="form-group button-group">
          <button type="submit" class="login-button" color="primary" [disabled]="!f.form.valid || loggingIn">
            {{ 'Login.Login' | translate }}
          </button>
          <div class="login-error">{{ authErrorMsg | translate }}</div>
        </div>
      </form>
    </div>
    <div class="footer">
      <div class="help-link">
        <div style="margin-right: 15px">
          <a href="https://citymesh.deitres.com/soporte/" target="_blank">{{ 'Login.Help' | translate }}</a>
        </div>
        <div>
          <a href="https://citymesh.deitres.com/politica-de-privacidad-de-deitres/" target="_blank">{{
            'Login.Terms&Conditions' | translate
          }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
