<div
  onKeyPress
  onKeyDown
  onKeyUp
  class="clean-button-style"
  (click)="openDistributors()"
  [matTooltip]="getFilterName()"
>
  <div *ngIf="showMenu" class="work-btn-activator">
    <mat-icon class="gap-work-icon-filter-name" style="font-size: 20px">work_fill</mat-icon>
    <div class="breadcrumb-company-receiver-name">{{ getFilterName() }}</div>
    <mat-icon class="subitem-right" style="font-size: 24px">chevron_right</mat-icon>
  </div>

  <mat-icon *ngIf="!showMenu" class="placement-work-icon-collapsed" style="font-size: 20px">work_fill</mat-icon>
</div>

<div
  class="breadcrumb-container"
  *ngIf="hoverMenuesService.breadCrumbedStatus"
  [style.left.px]="calcOffset()"
  [style.top.px]="this.heightReference"
>
  <div class="breadcrumb scrollbar">
    <div class="breadcrumb-item">
      <button class="clean-button-style" (click)="openCompanies()">
        <div class="grid">
          <div class="breadcrumb-subitem-left" style="display: flex; align-items: center;">
            <mat-icon class="icon" style="font-size: 24px;">work_fill</mat-icon>
            {{ getFilterName() }}
          </div>
          <div class="breadcrumb-subitem-right">
            <mat-icon style="font-size: 24px" *ngIf="showReceiverFilters"
              >keyboard_arrow_up</mat-icon
            >
            <mat-icon style="font-size: 24px" *ngIf="!showReceiverFilters">chevron_right</mat-icon>
          </div>
        </div>
      </button>
    </div>
    <!-- *ngIf provisory while validating Settings/Company screen -->
    <div *ngIf="authService.user.role === 'ADMIN'" class="separator"></div>
    <div *ngIf="authService.user.role === 'ADMIN'" class="breadcrumb-item">
      <button class="clean-button-style" style="justify-content: left;" (click)="onSettings()">
        <div class="icon"><img alt src="assets/img/settings.svg" /></div>
        <div class="breadcrumb-subitem-left">{{ 'Settings.Settings' | translate }}</div>
      </button>
    </div>
  </div>

  <div class="breadcrumb scrollbar" *ngIf="(state == 'companies' || state == 'receptors') && showCompanies() && showReceiverFilters">
    <div class="breadcrumb-item" *ngFor="let company of getCompanies()">
      <button class="clean-button-style" (click)="openReceptors(company)">
        <div class="grid">
          <div class="breadcrumb-subitem-left">{{ company.name }}</div>
          <div class="breadcrumb-subitem-right">
            <span class="item-counter">{{ getQtyReceiversPerCompany(company) }}</span>
            <mat-icon style="font-size: 24px" *ngIf="selectedCompany?.name == company.name">keyboard_arrow_up</mat-icon>
            <mat-icon style="font-size: 24px" *ngIf="selectedCompany?.name != company.name">chevron_right</mat-icon>
          </div>
        </div>
      </button>
    </div>
  </div>

  <div class="breadcrumb scrollbar" *ngIf="state == 'receptors' && showReceiverFilters">
    <div class="breadcrumb-item" *ngFor="let receiver of receivers">
      <button class="clean-button-style" (click)="selectReceiver(receiver)">
        <div class="grid">
          <div class="breadcrumb-subitem-left">{{ receiver.name }}</div>
        </div>
      </button>
    </div>
  </div>
</div>
