import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { CompaniesService } from 'src/app/settings/services/companies.service';

@Component({
  selector: 'app-company-edit-customize',
  templateUrl: './company-edit-customize.component.html',
  styleUrls: ['./company-edit-customize.component.scss']
})
export class CompanyEditCustomizeComponent implements OnInit {

  mensaje: SafeHtml;
  showColorSelector = false;

  currentCustomField: string;
  
  customColorSelected = {
    primary: false,
    secondary: false
  };

  primaryColors = [
    {
      selected: false,
      color: '#000000',
      selectedColor: '#ffffff'
    },
    {
      selected: false,
      color: '#7d7d7d',
      selectedColor: '#ffffff'
    },
    {
      selected: false,
      color: '#ffffff',
      selectedColor: '#000000'
    },
    {
      selected: false,
      color: '#10a9ea',
      selectedColor: '#000000'
    },
    {
      selected: false,
      color: '#10ea51',
      selectedColor: '#000000'
    },
    {
      selected: false,
      color: '#eab910',
      selectedColor: '#000000'
    },
    {
      selected: false,
      color: '#ea1010',
      selectedColor: '#000000'
    },
    {
      selected: false,
      color: '#ea10a5',
      selectedColor: '#000000'
    }
  ]

  secondaryColors = [
    {
      selected: false,
      color: '#000000',
      selectedColor: '#ffffff'
    },
    {
      selected: false,
      color: '#7d7d7d',
      selectedColor: '#ffffff'
    },
    {
      selected: false,
      color: '#ffffff',
      selectedColor: '#000000'
    },
    {
      selected: false,
      color: '#10a9ea',
      selectedColor: '#000000'
    },
    {
      selected: false,
      color: '#10ea51',
      selectedColor: '#000000'
    },
    {
      selected: false,
      color: '#eab910',
      selectedColor: '#000000'
    },
    {
      selected: false,
      color: '#ea1010',
      selectedColor: '#000000'
    },
    {
      selected: false,
      color: '#ea10a5',
      selectedColor: '#000000'
    }
  ]
  
  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly translateService: TranslateService,
    public companiesService: CompaniesService
  ) { }

  ngOnInit(): void {
    this.getTranslation();
    this.configureColorPalette();
  }

  private configureColorPalette(): void {
    if (this.companiesService.currentCompany.colorPalette?.primary) {
      this.setSelectedColors();
    } else {
      this.setDefaultColors();
    }
  }
  
  private setSelectedColors(): void {
    this.checkSelectedColor(this.primaryColors, 'primary');
    this.checkSelectedColor(this.secondaryColors, 'secondary');
  }

  private setDefaultColors(): void {
    this.companiesService.currentCompany.colorPalette = {
      primary: this.primaryColors[5].color,
      secondary: this.secondaryColors[0].color
    };
  }

  checkSelectedColor = (array, key) => {
    if (this.companiesService.currentCompany.colorPalette[key]) {
      let find = false;
      for (let color of array) {
        if (color.color.toUpperCase() === this.companiesService.currentCompany.colorPalette[key].toUpperCase()) {
          color.selected = true;
          find = true;
        } else {
          color.selected = false;
        }
      }
      if (!find) this.customColorSelected[key] = true;
    }
  }

  getTranslation = async () => {
    this.mensaje = this.sanitizer.bypassSecurityTrustHtml(await lastValueFrom(this.translateService.get('Settings.UploadLogoDetails')));
  }

  removeIcon() {
    this.companiesService.currentCompany.logo = undefined;
  }

  onSelectNewColor(field) {
    this.currentCustomField = field;
    this.showColorSelector = true;
  }

  setColor(color, field) {
    this.customColorSelected[field] = false;
    this.showColorSelector = false;
    this[field + 'Colors'].map(c => c.selected = false);
    color.selected = true;
    if (this.companiesService.currentCompany?.colorPalette) 
      this.companiesService.currentCompany.colorPalette[field] = color.color;
  }

  onNewColor(color) {
    this.showColorSelector = false;
    if (color) {
      this.customColorSelected[this.currentCustomField] = true;

      this[this.currentCustomField + 'Colors'].map(c => c.selected = false);
      if (this.companiesService.currentCompany?.colorPalette) 
        this.companiesService.currentCompany.colorPalette[this.currentCustomField] = color;
    }
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files?.[0]) {
      const file = input.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        this.companiesService.currentCompany.logo = reader.result as string;
      };

      reader.readAsDataURL(file);
    }
  }
}
