import { Injectable } from '@angular/core';
import { NoteFormComponent } from '../shared/note-form/note-form.component';
import { ConfirmActionDialogComponent } from '../shared/components/confirm-action-dialog/confirm-action-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { UsersService } from '../users/services/users.service';
import { PartitionsService } from '../partitions/services/partitions.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserListComponent } from '../users/components/user-list/user-list.component';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/services/auth.service';

@Injectable()
export class NotificationService {
  userList: UserListComponent;
  snackBarDuration = 5000;
  snackBarErrorDuration = 10000;
  constructor(
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private abmsUser: UsersService,
    private abmsPartition: PartitionsService,
    public dialog: MatDialog,
    private authService: AuthService
  ) {}

  notifyPartition(companyData: any, operation: string, title: string) {
    const dialogRef = this.dialog.open(NoteFormComponent, {
      width: '50%',
      maxWidth: '700px',
      data: { title: title, operation: operation },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.status) {
        const dialogConfirm = this.dialog.open(ConfirmActionDialogComponent, {
          disableClose: true,
          maxWidth: '450px',
          autoFocus: false,
          data: { promptText: title, submitText: await this.translateService.get('Shared.Continue').toPromise() },
        });

        dialogConfirm.afterClosed().subscribe(async (resultConfirm) => {
          let notification = {
            title: result.titleNote,
            body: result.data,
          };
          if (resultConfirm) {
            const keys = Object.keys(companyData);
            for (let i = 0; i < keys.length; i++) {
              (
                await this.abmsPartition.notifyPartition(notification, companyData[keys[i]], undefined, keys[i])
              ).subscribe({
                next: async (res: any) => {
                  if (res.body.success) {
                    this.snackBar.open(
                      await this.translateService.get('Notification.SuccessfullyNot').toPromise(),
                      await this.translateService.get('Shared.Close').toPromise(),
                      { duration: this.snackBarDuration }
                    );
                  } else {
                    this.snackBar.open(
                      await this.translateService.get('Notification.ErrorNot').toPromise(),
                      await this.translateService.get('Shared.Close').toPromise(),
                      { duration: this.snackBarDuration }
                    );
                  }
                },
                error: async (error) => {
                  if (error && error.status === 401) {
                    this.snackBar.open(
                      await this.translateService.get('Shared.SessionExpired').toPromise(),
                      await this.translateService.get('Shared.Close').toPromise(),
                      { duration: this.snackBarDuration }
                    );
                    this.authService.logout();
                  } else {
                    this.snackBar.open(
                      await this.translateService.get('Notification.ErrorNot').toPromise(),
                      await this.translateService.get('Shared.Close').toPromise(),
                      { duration: this.snackBarDuration }
                    );
                  }
                },
              });
            }
          } else {
            this.snackBar.open(
              await this.translateService.get('Notification.CanceledNot').toPromise(),
              await this.translateService.get('Shared.Close').toPromise(),
              { duration: this.snackBarDuration }
            );
          }
        });
      }
    });
  }

  async notifyUser(usersToNotify: any, operation: string, title: string) {
    const dialogRef = this.dialog.open(NoteFormComponent, {
      width: '50%',
      maxWidth: '700px',
      data: {
        title: await this.translateService.get(title).toPromise(),
        operation: operation,
        submitText: await this.translateService.get('Shared.Continue').toPromise(),
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.status) {
        let techCount = 0;
        let finalCount = 0;
        if (operation === 'Notify Users') {
          const keys = Object.keys(usersToNotify);
          for (let i = 0; i < keys.length; i++) {
            usersToNotify[keys[i]] = this.filterUsersByType(result.final, result.technician, usersToNotify[keys[i]]);
            finalCount += usersToNotify[keys[i]].filter((user) => user.userType === 'final').length;
            techCount += usersToNotify[keys[i]].filter((user) => user.userType === 'tech').length;
          }
        }

        const dialogConfirm = this.dialog.open(ConfirmActionDialogComponent, {
          disableClose: true,
          maxWidth: '450px',
          autoFocus: false,
          data: {
            promptText: await this.confirmUsersToNotify(usersToNotify, techCount, finalCount),
            submitText: await this.translateService.get('Shared.Continue').toPromise(),
          },
        });

        dialogConfirm.afterClosed().subscribe(async (resultConfirm) => {
          let notification = {
            title: result.titleNote,
            body: result.data,
          };

          if (resultConfirm) {
            (await this.abmsUser.notifyUser(notification, usersToNotify)).subscribe({
              next: async (res: any) => {
                if (res.body.success) {
                  this.snackBar.open(
                    await this.translateService.get('Notification.SuccessfullyNot').toPromise(),
                    await this.translateService.get('Shared.Close').toPromise(),
                    { duration: this.snackBarDuration }
                  );
                } else {
                  this.snackBar.open(
                    await this.translateService.get('Notification.ErrorNot').toPromise(),
                    await this.translateService.get('Shared.Close').toPromise(),
                    { duration: this.snackBarDuration }
                  );
                }
              },
              error: async (error) => {
                if (error && error.status === 401) {
                  this.snackBar.open(
                    await this.translateService.get('Shared.SessionExpired').toPromise(),
                    await this.translateService.get('Shared.Close').toPromise(),
                    { duration: this.snackBarDuration }
                  );
                  this.authService.logout();
                } else {
                  this.snackBar.open(
                    await this.translateService.get('Notification.ErrorNot').toPromise(),
                    await this.translateService.get('Shared.Close').toPromise(),
                    { duration: this.snackBarDuration }
                  );
                }
              },
            });
          } else {
            this.snackBar.open(
              await this.translateService.get('Notification.CanceledNot').toPromise(),
              await this.translateService.get('Shared.Close').toPromise(),
              { duration: this.snackBarDuration }
            );
          }
        });
      }
    });
  }

  // Retorna un array de usuarios luego de filtrar según el tipo de usuario que se haya seleccionado para notificar.
  filterUsersByType(final: boolean, technician: boolean, arrayUsers: any) {
    let arrayUsersFiltered = [];
    if (final && technician) {
      arrayUsersFiltered = arrayUsers;
    } else if (final && !technician) {
      arrayUsers.forEach((user) => {
        if (user.userType === 'final') arrayUsersFiltered.push(user);
      });
    } else if (!final && technician) {
      arrayUsers.forEach((user) => {
        if (user.userType === 'tech') {
          arrayUsersFiltered.push(user);
        }
      });
    }
    return arrayUsersFiltered;
  }

  // Retorna un mensaje de confirmación, en base al tipo de usuario que se haya seleccionado para notificar.
  async confirmUsersToNotify(usersFiltered: any, techCount: number, finalCount: number) {
    let message: string;
    const keys = Object.keys(usersFiltered);
    if (keys.length === 1 && usersFiltered[keys[0]].length === 1) {
      message =
        (await this.translateService.get('Notification.NotifyTo').toPromise()) + usersFiltered[keys[0]][0].userEmail;
    } else {
      if (techCount > 0 && finalCount > 0) {
        message =
          (await this.translateService.get('Notification.NotFinalU').toPromise()) +
          techCount +
          (await this.translateService.get('Notification.TechUsers').toPromise()) +
          finalCount;
      } else if (techCount > 0 && !(finalCount > 0)) {
        message = (await this.translateService.get('Notification.NotFinalU').toPromise()) + techCount;
      } else if (!(techCount > 0) && finalCount > 0) {
        message = (await this.translateService.get('Notification.NotFinalT').toPromise()) + finalCount;
      }
    }
    return message;
  }
}
