import { CameraDevice } from "./camera-device";
import { Device } from "./device";

export class Partition {
  _id: string;
  name: string;
  account: string;
  receiver: string;
  active: boolean;
  mac: string;
  company: any;
  apiKey: string;
  address: string;
  supportEnabled: boolean;
  partitionNumber: number;
  devices: Device[];
  cameraDevices: CameraDevice[];
  isCommunicator: boolean;
  alarmStatus: {
    armed: boolean,
    type: string,
    zones: []
  };
  techNotes: {
    notes: string,
    user: string,
    updatedAt: Date
  };
  adminNotes: string;
  notificationSubs: string[];
  users: any[];
  removeUsers: any[];
  usersWithNewSubs: any[];
  eventsLoggingConf: {
    medic: boolean,
    fire: boolean,
    panic: boolean,
    alarmTrigger: boolean,
    tamper: boolean,
    hs24aux: boolean,
    energyProblem: boolean,
    lowBattery: boolean,
    systemProblem: boolean,
    comunicationProblem: boolean,
    armed: boolean,
    disarmed: boolean,
    userArmed: boolean,
    userDisarmed: boolean,
    groupArmed: boolean,
    groupDisarmed: boolean,
    automaticArmed: boolean,
    automaticDisarmed: boolean,
    quickArmed: boolean,
    quickDisarmed: boolean,
    remoteArmed: boolean,
    remoteDisarmed: boolean,
    keyswitchArmed: boolean,
    keyswitchDisarmed: boolean,
    bypassedZone: boolean,
    panelTest: boolean,
    panelTestError: boolean,
    sensorFailure: boolean,
    highTemperature: boolean,
    lowTemperature: boolean,
    resetPanicAlarm: boolean,
    expanderModule: boolean,
    silentTheft: boolean,
    armedStay: boolean,
    generalAlarm: boolean,
    systemShutdown: boolean,
    zoneTamper: boolean,
    zoneLowBattery: boolean,
    zoneEnergyProblem: boolean,
    sirenFailure: boolean,
    trouble: boolean,
  };
  disallowEvents: boolean;
  selectedToSupport: boolean;
  emergencyEmail: boolean;
  disallowRemoteSiren: boolean;
  keyboard: boolean;

  constructor() {
    this.eventsLoggingConf = {
      medic: true,
      fire: true,
      panic: true,
      alarmTrigger: true,
      tamper: true,
      hs24aux: true,
      energyProblem: true,
      lowBattery: true,
      systemProblem: true,
      comunicationProblem: true,
      armed: true,
      disarmed: true,
      userArmed: true,
      userDisarmed: true,
      groupArmed: true,
      groupDisarmed: true,
      automaticArmed: true,
      automaticDisarmed: true,
      quickArmed: true,
      quickDisarmed: true,
      remoteArmed: true,
      remoteDisarmed: true,
      keyswitchArmed: true,
      keyswitchDisarmed: true,
      bypassedZone: true,
      panelTest: true,
      panelTestError: true,
      sensorFailure: true,
      highTemperature: true,
      lowTemperature: true,
      resetPanicAlarm: true,
      expanderModule: true,
      silentTheft: true,
      armedStay: true,
      generalAlarm: true,
      systemShutdown: true,
      zoneTamper: true,
      zoneLowBattery: true,
      zoneEnergyProblem: true,
      sirenFailure: true,
      trouble: true,
    };
  }
}
