<form name="f" (ngSubmit)="submit()" #f="ngForm" novalidate formValidTimerRange>
  <h1 mat-dialog-title>{{'NotForm.First' | translate}} {{ ((data.events ?  'NotForm.Second' : 'NotForm.SecondTwoOp') | translate) }} {{ data.isUser ? data.name : 'NotForm.Third' | translate}}</h1>  <mat-dialog-content>
    <ng-container *ngFor="let topic of topicsList">
      <div fxLayout="row" fxLayoutGap="30px">
        <div fxFlex>
          <mat-checkbox
            color="primary" class="user-check"
            [(ngModel)]="topic.selected"
            type="checkbox"
            [ngModelOptions]="{standalone: true}"
            title="topic.text">
            {{ topic.text | translate}}
          </mat-checkbox>
        </div>
      </div>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary">{{"Shared.Accept" | translate}}</button>
    <button type="button" mat-raised-button (click)="onCancelClick()">{{"Shared.Cancel" | translate}}</button>
  </mat-dialog-actions >
</form>

