import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppMaterialModule } from './app-material/app-material.module';
import { ConfirmActionDialogComponent } from './shared/components/confirm-action-dialog/confirm-action-dialog.component';
import { AcceptDialogComponent } from './shared/accept-dialog/accept-dialog.component';
import { AuthModule } from './auth/auth.module';
import { PanelModule } from './panel/panel.module';
import { PartitionsModule } from './partitions/partitions.module';
import { UsersModule } from './users/users.module';
import { KeyboardModule } from './keyboard/keyboard.module';
import { MessagesProcessorSocket, SocketService } from './services/socket.service';
import { TechSupportModule } from './tech-support/tech-support.module';
import { SharedModule } from './shared/shared.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatSelectModule } from '@angular/material/select';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { NoteFormComponent } from './shared/note-form/note-form.component';
import { FormsModule } from '@angular/forms';
import { NotificationService } from './services/notification.service';
import { EventService } from './panel/services/event.service';
import { MapModule } from './map/map.module';
import { StadisticsModule } from './stadistics/stadistics.module';
import { TooltipDeitresModule } from './common/ui/tooltip/tooltipDeitres.module';
import { SettingsModule } from './settings/settings.module';
import { ProfileModule } from './profile/profile.module';
import { NgxMaskModule } from 'ngx-mask';
import { ConfirmActionDialogNewComponent } from './shared/components/confirm-action-dialog-new-style/confirm-action-dialog.component';
import { BreadcrumbedReceptorPickerComponent } from './hover-menues/components/breadcrumbed-receptor-picker/breadcrumbed-receptor-picker.component';
import { BreadcrumbedHelpComponent } from './hover-menues/components/breadcrumbed-help/breadcrumbed-help.component';
import { HoverMenuesModule } from './hover-menues/hover-menues.module';
import { MessageBubbleComponent } from './hover-menues/components/message-bubble/message-bubble.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    ConfirmActionDialogComponent,
    ConfirmActionDialogNewComponent,
    AcceptDialogComponent,
    NoteFormComponent,
    BreadcrumbedReceptorPickerComponent,
    BreadcrumbedHelpComponent,
    MessageBubbleComponent
  ],
  imports: [
    TooltipDeitresModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    MatSelectModule,
    HttpClientModule,
    AppMaterialModule,
    AuthModule,
    PanelModule,
    PartitionsModule,
    KeyboardModule,
    UsersModule,
    TechSupportModule,
    SharedModule,
    LoadingBarModule,
    FormsModule,
    MapModule,
    StadisticsModule,
    SettingsModule,
    ProfileModule,
    HoverMenuesModule,
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    MessagesProcessorSocket,
    SocketService,
    NotificationService,
    EventService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
