import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { GenericDialogComponent } from 'src/app/shared/components/generic-dialog/generic-dialog.component';
import { getPhoneMask, countryMasksList } from '../../../shared/utils/phone-mask.util';
import { ProfileService } from '../../services/profile.service';
import { NgForm } from '@angular/forms';

export interface DialogData {
  title: string;
  operation: string;
  submitText: string;
  promptText: string;
  id: string;
  type: string;
}

@Component({
  selector: 'edit-profile-dialog',
  templateUrl: './edit-profile-dialog.component.html',
  styleUrls: ['./edit-profile-dialog.component.scss'],
})
export class EditProfileDialogComponent {
  _id: string = this.auth.user._id;
  name: string;
  surname: string;
  email: string;
  phone: string = '';
  selectedLanguage: string;
  password: string;
  confirmPassword: string;
  isLoading: boolean = false;

  invalidPassword: boolean = false;
  invalidPhoneMask: boolean = false;
  minPassLength: boolean = false;

  language = [
    { value: 'en', viewValue: this.translateService.instant('AppComponent.LanguageEnglish') },
    { value: 'es', viewValue: this.translateService.instant('AppComponent.LanguageSpanish') },
    { value: 'pt', viewValue: this.translateService.instant('AppComponent.LanguagePortuguese') },
  ];

  originalValues = {
    name: '',
    surname: '',
    email: '',
    phone: '',
    selectedLanguage: '',
  };

  mask: string | null = '(+00) 0000000000000';

  @ViewChild('f') form: NgForm;

  constructor(
    public dialogRef: MatDialogRef<EditProfileDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private readonly translateService: TranslateService,
    private readonly auth: AuthService,
    private readonly profileService: ProfileService
  ) {
    this.loadValuesFromStorage();
    this.mask = this.phone ? getPhoneMask(this.phone) : null;
  }

  private loadValuesFromStorage() {
    const storedValues = JSON.parse(localStorage.getItem('userCompanyValues') || '{}');

    this.name = storedValues.name || this.auth.user.name;
    this.surname = storedValues.lastName || this.auth.user.lastName;
    this.email = storedValues.email || this.auth.user.email;
    this.phone = storedValues.phone || this.auth.user.phone || undefined;
    this.selectedLanguage = storedValues.language || this.auth.user.language || this.auth.selectedLanguage;

    this.originalValues = {
      name: this.name,
      surname: this.surname,
      email: this.email,
      phone: this.phone,
      selectedLanguage: this.selectedLanguage,
    };
  }

  confirmExitDialog = () => {
    this.dialog.open(GenericDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: {
        type: 'exitConfirmation',
      },
    });
  };

  changesSuccessfullyDialog = () => {
    this.dialog.open(GenericDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: {
        type: 'changesSuccessfully',
      },
    });
  };

  applyMask() {
    this.mask = this.phone && this.phone.length > 2 ? getPhoneMask(this.phone) : '';
  }

  verifyPhoneMask(phone: string): boolean {
    if (!phone?.trim()) return false;

    const prefixes = [phone.slice(0, 2), phone.slice(0, 3), phone.slice(0, 4)];

    return prefixes.some((prefix) => countryMasksList.includes(prefix));
  }

  close() {
    const hasChanged = Object.keys(this.originalValues).some((key) => this[key] !== this.originalValues[key]);
    if (hasChanged) {
      this.confirmExitDialog();
    } else {
      this.dialogRef.close();
      this.form.resetForm();
    }
  }

  verifyPassword() {
    this.invalidPassword =
      !!this.password !== !!this.confirmPassword ||
      this.password !== this.confirmPassword ||
      ((this.password?.length ?? 0) > 0 && (this.password?.length ?? 0) < 8);
  }

  onSubmit() {
    this.isLoading = true;
    this.verifyPassword();

    if (!this.verifyPhoneMask(this.form.value.phone)) {
      this.invalidPhoneMask = true;
      this.isLoading = false;
      return;
    }

    if (this.invalidPassword) {
      this.isLoading = false;
      return;
    }

    if (this.selectedLanguage !== this.auth.selectedLanguage) {
      this.auth.saveSelectedLanguage(this.selectedLanguage);
      this.translateService.use(this.selectedLanguage);
    }

    const newValues = {
      name: this.name,
      lastName: this.surname,
      email: this.email,
      phone: this.phone,
      pass: this.password,
      language: this.selectedLanguage,
    };
    this.profileService.updateUser(newValues, this._id).subscribe({
      next: (response) => {
        this.isLoading = false;
        const { pass, ...valuesWithoutPass } = newValues;
        this.dialogRef.close(valuesWithoutPass);
        this.changesSuccessfullyDialog();
      },
      error: (error) => {
        this.isLoading = false;
      },
    });
  }
}
