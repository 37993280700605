import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import constants from 'src/app/shared/constants';
import { UserCompanyModel } from 'src/app/shared/models/user-company-model';
import { environment } from 'src/environments/environment';
import { CompanyType } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class CompanyUserService {
  uriCompany = `${environment.webappEndpoint}/company`;
  uriUserCompany = `${environment.webappEndpoint}/userCompany`;

  constructor(
    private readonly http: HttpClient, 
    private readonly auth: AuthService,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
  ) { }

  httpRequest(method, param, callback, callbackParams, errorMessage) {
    method(...param).subscribe({
      next: (res: any) => {
        callback(res, callbackParams);
        if (res.reason) {
          this.showSnackBar(res.reason);
        }
      },
      error: (error) => {
        callback({ success: false });
        if (error?.status === 401) {
          this.showSnackBar('Shared.SessionExpired');
        } else {
          this.showSnackBar(errorMessage);
        }
      },
    });
  }
  
  showSnackBar = async (message) => {
    this.snackBar.open(await lastValueFrom(this.translateService.get(message)), await lastValueFrom(this.translateService.get('Shared.Close')), { duration: constants.snackBarDuration });
  }

  getCompanies = () => this.http.get(`${this.uriCompany}/getCompanies`, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  getCompany = (_id: string) => this.http.get(`${this.uriCompany}/getCompany/${_id}`, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  createCompany = (company: CompanyType) => this.http.post(`${this.uriCompany}/create`, company, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  updateCompany = (company: CompanyType) => this.http.post(`${this.uriCompany}/update`, company, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  deleteCompany = (_id: string) => this.http.post(`${this.uriCompany}/delete`, { _id }, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  
  createUser = (user: UserCompanyModel) => this.http.post(`${this.uriUserCompany}/create`, user, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
  deleteUser = (_id: string) => this.http.post(`${this.uriUserCompany}/delete`, { _id }, { headers: { Authorization: `Bearer ${this.auth.getToken()}` }});
}
