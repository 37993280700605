import { Injectable } from '@angular/core';
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { PanelSignal } from 'src/app/panel/models/event.model';
import { EventService } from 'src/app/panel/services/event.service';
import { ILatLon, IPanelClickedState, PanelMapMark, PanelPathShowing, PathNode } from 'src/app/types';
import constants from '../constants';
import icons from '../icons';
import styles from './google-maps-styles';
import { GoogleMapsLoaderService } from './map-loader.service';
import { PopupLanguageService } from './popup-language-service';
import { HoverMenuesService } from '../../hover-menues/services/hover-menues.service';

/** this is a hack because import { google } from '@types/googlemaps' was breaking for angular */
declare let google;

// <reference types="@types/googlemaps" />

const DEFAULT_MAP_TYPE = 'roadmap';
const MIN_ZOOM = 4;
const MAX_ZOOM = 22;
const PANORAMA_VIEW_OFFSET = 2;
const CLUSTER_LIMIT_2KM = 12;

enum ExpandPanelInformationState {
  panelClickedOnGroupedMacs = 'panelClickedOnGroupedMacs',
  panelIdSwitched = 'panelIdSwitched',
  popUpInfoBoxSwitchedId = 'popUpInfoBoxSwitchedId',
  popUpInfoBox = 'popUpInfoBox',
  leftSidePanel = 'leftSidePanel',
  closingSidePanelAndPopup = 'closingSidePanelAndPopup',
  groupedMacPopupOpened = 'groupedMacPopupOpened',
  closeGroupMacPopup = 'closeGroupMacPopup',
}

enum ConnectionStrengthStates {
  OPTIMUM = 'optimum',
  GOOD = 'good',
  LOW = 'low',
  NULL = 'null',
}

const ColorWeight = {
  [ConnectionStrengthStates.OPTIMUM]: 1,
  [ConnectionStrengthStates.GOOD]: 1.1,
  [ConnectionStrengthStates.LOW]: 1.2,
  [ConnectionStrengthStates.NULL]: 1.3,
};

enum TypeLinking {
  LOW = 'low',
  GOOD = 'good',
  OPTIMUM = 'optimum',
  DISCONNECTED = 'disconnected',
  NULL = 'null',
}

const ColorWeightNeighbor = {
  [TypeLinking.OPTIMUM]: 1,
  [TypeLinking.GOOD]: 1.1,
  [TypeLinking.LOW]: 1.2,
  [TypeLinking.NULL]: 1.3,
  [TypeLinking.DISCONNECTED]: 1.4,
};

interface ISelectedPath {
  positions: PathNode[];
  type: string;
  mapPath: ILatLon[];
}

@Injectable({
  providedIn: 'root',
})
export class CustomMapService {
  mapTypeId = DEFAULT_MAP_TYPE;
  showTerrain = false;

  map;

  currentMarkListSubject: BehaviorSubject<Array<PanelMapMark>> = new BehaviorSubject<PanelMapMark[]>([]);
  currentMarkList$: Observable<PanelMapMark[]>;
  currentMarkListSubscription: Subscription;

  markers = [];

  markerCluster: MarkerClusterer;
  selectionMarkers = [];
  selectionMarkerCluster: MarkerClusterer;
  flightPath: google.maps.Polyline;
  showPanelPath: PanelPathShowing;

  onClickEventLocation;

  /**
   * More info about SuperClusterAlgorithm
   * @see: https://www.npmjs.com/package/supercluster
   */
  // Business rule: must be 12 equal to 2KM
  algorithm: SuperClusterAlgorithm = new SuperClusterAlgorithm({ maxZoom: CLUSTER_LIMIT_2KM });

  renderer = {
    render: (cluster: any) => this.genRender(cluster),
  };

  viewType = constants.viewTypes.None;
  showPlaces = false;
  optionsDefault = {};
  optionsWithoutMapId = {};
  savedCenter: ILatLon = undefined;
  savedZoom: number = undefined;
  southAmerica = { lat: -15, lng: -56 };
  selectedPanelList = [];

  markSelectionState = false;
  isDoubleClickState = false;

  /** typing google.maps.InfoWindow does not work */
  infoWindow = undefined;
  toogleRemainderAccounts = false;
  plusSignRemainderAccountsSaved = '';
  markClickCurrentState = ExpandPanelInformationState.popUpInfoBox;
  contentIdSaved: string;

  /** Start: Do not use this control variables they are
   * used for popup refresh when language switches */
  currentMarkerClicked: any;
  currentPanelMarkClicked: PanelMapMark;
  validStatusForPopupRefresh = [
    ExpandPanelInformationState.popUpInfoBoxSwitchedId,
    ExpandPanelInformationState.popUpInfoBox,
  ];
  /** End */

  setTimeoutIdClusters: ReturnType<typeof setTimeout>;

  zoomSavedForRestoringFocusAnimation: number;
  panelSavedForRestoringFocusAnimation: any;
  selectedPathForRestoringFocusAnimation: ISelectedPath;
  markHiddenByZoomState = false;
  onlyOpenWithScroll: boolean = true;
  activateHandleZoomRestoration: boolean = false;

  constructor(
    private readonly googleMapsLoader: GoogleMapsLoaderService,
    private readonly eventService: EventService,
    private readonly translateService: TranslateService,
    private readonly popupLanguageService: PopupLanguageService,
    private readonly hoverMenuesService: HoverMenuesService
  ) {
    this.currentMarkList$ = this.currentMarkListSubject.asObservable();
    this.popupLanguageService.languageOnTheWatch.subscribe(this.handleLanguageSwitch);
  }

  perform() {
    this.initMap();
    this.setupSubscription();
  }

  handleLanguageSwitch = () => {
    if (this.validStatusForPopupRefresh.includes(this.markClickCurrentState)) return;
    if (!this.currentMarkerClicked) return;
    if (!this.currentPanelMarkClicked) return;

    this.createContentAndPopUp(this.currentMarkerClicked, this.currentPanelMarkClicked);
  };

  cleanRestorationOfFocus() {
    this.cleanRestorationOfZoomProperties();

    this.cleanPropertiesRelatedToRestorationOfZoom();
  }

  private cleanPropertiesRelatedToRestorationOfZoom() {
    if (this.flightPath) this.flightPath.setMap(null);
    if (this.selectionMarkerCluster) this.selectionMarkerCluster.setMap(null);
    if (this.selectionMarkers) this.selectionMarkers.forEach((marker) => marker.setMap(null));

    this.selectionMarkers = undefined;
    this.showPanelPath = undefined;

    this.eventService.selectionUpdatedEvent.emit({ closePanel: true });

    this.onGetUnclusteredMarks();

    this.infoWindow?.close();
  }

  private cleanRestorationOfZoomProperties() {
    this.zoomSavedForRestoringFocusAnimation = undefined;

    this.panelSavedForRestoringFocusAnimation = {
      mark: undefined,
      panel: undefined,
    };

    this.markHiddenByZoomState = false;
    this.selectedPathForRestoringFocusAnimation = undefined;
  }

  clearSelectedPathForRestoringFocusAnimation() {
    this.selectedPathForRestoringFocusAnimation = undefined;
  }

  mainOptions() {
    if (!google) return;

    return {
      center: this.savedCenter ?? this.southAmerica,
      zoom: this.savedZoom ?? MIN_ZOOM,
      maxZoom: MAX_ZOOM,
      mapTypeControl: false,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        position: google.maps.ControlPosition.RIGHT_TOP,
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      scaleControl: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
      fullscreenControl: true,
    };
  }

  setDefaultZoom() {
    if (!this.map) return;

    this.map.setCenter(new google.maps.LatLng(this.southAmerica));
    this.map.setZoom(MIN_ZOOM);
  }

  setDefaultOptions() {
    this.optionsDefault = {
      // mapId: DEFAULT_MAP_TYPE,
      // mapTypeId: this.mapTypeId,
      ...this.mainOptions(),
      styles: styles.getCustomStyles(this.showPlaces),
    };
  }

  setOptionsWithNoMapIds() {
    this.optionsWithoutMapId = {
      ...this.mainOptions(),
      styles: styles.getCustomStyles(this.showPlaces),
    };
  }

  saveCenter(zoom: number, center: ILatLon) {
    this.savedCenter = center;
    this.savedZoom = zoom;
    if (this.map) {
      this.map.setCenter(new google.maps.LatLng(this.savedCenter));
      this.map.setZoom(this.savedZoom);
    }
  }

  saveRestoreZoom = (panel: PanelMapMark) => {
    if (!this.map) return;

    this.panelSavedForRestoringFocusAnimation = { marker: this.selectionMarkers[0], panel: panel };
    this.zoomSavedForRestoringFocusAnimation = this.map.getZoom();
  };

  setSelectedMark(position: ILatLon) {
    this.clearPath();

    const marker = new google.maps.Marker({
      position,
      icon: {
        ...constants.pathIconSize,
        url: constants.iconPath.typeSelected,
      },
    });

    this.selectionMarkers = [marker];
    this.buildSelectedMarkers();
    this.markSelectionState = true;
  }

  unsetSelectedMark() {
    this.clearPath();
    this.selectionMarkers = [];
    this.buildSelectedMarkers();
    this.markSelectionState = false;
  }

  setSelectedPath(positions: PathNode[], type: string, mapPath: ILatLon[] = undefined) {
    this.selectedPathForRestoringFocusAnimation = {
      positions,
      type,
      mapPath,
    };

    if (!this.map) return;
    if (this.selectionMarkers) this.selectionMarkers.forEach((marker) => marker.setMap(null));
    this.selectionMarkers = [];
    for (const position of positions) {
      this.selectionMarkers.push(
        new google.maps.Marker({
          position: position.position,
          icon: {
            ...constants.pathIconSize,
            url: constants.iconPath.typeSelected,
          },
        })
      );
    }

    this.showPanelPath = {
      mac: positions[0].mac,
      type,
    };
    this.renderPaths(mapPath ?? positions.map((p) => p.position));
    this.buildSelectedMarkers();
  }

  renderPaths(coordinatesArray: ILatLon[]) {
    if (this.flightPath) this.flightPath.setMap(null);
    this.flightPath = new google.maps.Polyline({
      path: coordinatesArray,
      geodesic: true,
      strokeColor: '#0000FF',
      strokeOpacity: 1.0,
      strokeWeight: 1.55,
    });

    this.flightPath.setMap(this.map);
  }

  buildSelectedMarkers() {
    this.selectionMarkers.forEach((marker) => {
      marker.setMap(this.map);
      marker.setIcon({
        ...constants.pathIconSize,
        url: constants.iconPath.typeSelected,
      });
    });
  }

  genRender(cluster) {
    const color = this.prepareColorForClusterMarker(cluster);

    const svg = icons.getFullClusterIcon(color, cluster.count, 60, 60, 0.6, 70);

    const zIndex = Number(google.maps.Marker.MAX_ZINDEX) + cluster.count;

    const clusterOptions = {
      position: cluster.position,
      zIndex,
      icon: {
        url: `data:image/svg+xml;base64,${btoa(svg)}`,
        anchor: new google.maps.Point(25, 25),
      },
    };

    return new google.maps.Marker(clusterOptions);
  }

  countMarkersByConnectionState(cluster) {
    const markers = cluster.markers;
    const { ViewLinking } = constants.viewTypes;

    const MarkColorCount = {
      greenMarksCounter: 0,
      lightGreenMarksCounter: 0,
      yellowMarksCounter: 0,
      orangeMarksCounter: 0,
      redWineMarksCounter: 0,
    };

    markers.forEach((marker) => {
      if (marker.content) {
        const type = this.viewType === ViewLinking ? this.getNeighborType(marker) : this.getStateType(marker);
        MarkColorCount[type]++;
      }
    });

    return { ...MarkColorCount };
  }

  getStateType(marker) {
    if (marker.content.state == ConnectionStrengthStates.OPTIMUM) {
      return 'greenMarksCounter';
    } else if (marker.content.state == ConnectionStrengthStates.GOOD) {
      return 'yellowMarksCounter';
    } else if (marker.content.state == ConnectionStrengthStates.LOW) {
      return 'orangeMarksCounter';
    }
    return 'redWineMarksCounter';
  }

  getNeighborType(marker) {
    if (marker.content.neighborViewColor == TypeLinking.OPTIMUM) {
      return 'greenMarksCounter';
    } else if (marker.content.neighborViewColor == TypeLinking.GOOD) {
      return 'lightGreenMarksCounter';
    } else if (marker.content.neighborViewColor == TypeLinking.LOW) {
      return 'yellowMarksCounter';
    } else if (marker.content.neighborViewColor == TypeLinking.NULL) {
      return 'orangeMarksCounter';
    }
    return 'redWineMarksCounter';
  }

  prepareColorForClusterMarker(cluster) {
    const { ViewLinking } = constants.viewTypes;
    const colorCount = this.countMarkersByConnectionState(cluster);

    return this.viewType === ViewLinking
      ? this.findClusterColorBasedOnNeighborCount(colorCount)
      : this.findClusterColorBasedOnConnectionSignalStrength(colorCount);
  }

  findClusterColorBasedOnConnectionSignalStrength(colorCount): string {
    /**
     * Change percetanges of the ranges for putting the colors in the cluster markers
     */
    const { greenColor, yellowColor, orangeColor, redColor } = icons.clusterColors;
    const { greenMarksCounter, yellowMarksCounter, orangeMarksCounter, redWineMarksCounter } = colorCount;

    const colors = [
      {
        key: ConnectionStrengthStates.OPTIMUM,
        count: greenMarksCounter * ColorWeight[ConnectionStrengthStates.OPTIMUM],
      },
      {
        key: ConnectionStrengthStates.GOOD,
        count: yellowMarksCounter * ColorWeight[ConnectionStrengthStates.GOOD],
      },
      {
        key: ConnectionStrengthStates.LOW,
        count: orangeMarksCounter * ColorWeight[ConnectionStrengthStates.LOW],
      },
      {
        key: ConnectionStrengthStates.NULL,
        count: redWineMarksCounter * ColorWeight[ConnectionStrengthStates.NULL],
      },
    ];

    const colorKey = {
      optimum: greenColor,
      good: yellowColor,
      low: orangeColor,
      null: redColor,
    };

    colors.sort(this.colorASCCompare);
    let acc = 0;
    for (let j = 1; j < colors.length; j++) {
      if (colors[j].key != ConnectionStrengthStates.OPTIMUM) acc += colors[j].count;
    }

    if (colors[0].count < acc) {
      if (colors[1].key != ConnectionStrengthStates.OPTIMUM) return colorKey[colors[1].key];
      return colorKey[colors[2].key];
    }
    return colorKey[colors[0].key];
  }

  findClusterColorBasedOnNeighborCount(colorCount): string {
    /**
     * Change percetanges of the ranges for putting the colors in the cluster markers
     */
    const { greenColor, lightGreenColor, yellowColor, orangeColor, wineColor } = icons.clusterColors;
    const { greenMarksCounter, lightGreenMarksCounter, yellowMarksCounter, orangeMarksCounter, redWineMarksCounter } =
      colorCount;

    const colors = [
      {
        key: TypeLinking.OPTIMUM,
        count: greenMarksCounter * ColorWeightNeighbor[TypeLinking.OPTIMUM],
      },
      {
        key: TypeLinking.GOOD,
        count: lightGreenMarksCounter * ColorWeightNeighbor[TypeLinking.GOOD],
      },
      {
        key: TypeLinking.LOW,
        count: yellowMarksCounter * ColorWeightNeighbor[TypeLinking.LOW],
      },
      {
        key: TypeLinking.NULL,
        count: orangeMarksCounter * ColorWeightNeighbor[TypeLinking.NULL],
      },
      {
        key: TypeLinking.DISCONNECTED,
        count: redWineMarksCounter * ColorWeightNeighbor[TypeLinking.DISCONNECTED],
      },
    ];

    const colorKey = {
      optimum: greenColor,
      good: lightGreenColor,
      low: yellowColor,
      null: orangeColor,
      disconnected: wineColor,
    };

    colors.sort(this.colorASCCompare);
    let acc = 0;
    for (let j = 1; j < colors.length; j++) {
      acc += colors[j].count;
    }

    if (colors[0].count.toFixed(2) == acc.toFixed(2)) {
      return ColorWeightNeighbor[colors[0].key] > ColorWeightNeighbor[colors[1].key]
        ? colorKey[colors[0].key]
        : colorKey[colors[1].key];
    } else if (colors[0].count.toFixed(2) < acc.toFixed(2)) {
      return colorKey[colors[1].key];
    }
    return colorKey[colors[0].key];
  }

  colorASCCompare(b, a) {
    if (a.count === b.count) {
      return ColorWeight[a.key] < ColorWeight[b.key] ? -1 : 1;
    }
    return a.count < b.count ? -1 : 1;
  }

  setViewType(viewType: string) {
    this.viewType = viewType;
  }

  initMap() {
    /** This load enables google.maps to be called anywhere within this scope */
    this.googleMapsLoader.load().then(async () => {
      this.setDefaultOptions();
      this.setOptionsWithNoMapIds();
      this.setupMap();
      this.setupSingleInfoWindow();

      if (this.selectionMarkers) this.buildSelectedMarkers();
    });
  }

  setupSingleInfoWindow() {
    this.infoWindow = new google.maps.InfoWindow({
      /** disableAutoPan: true because if you navigate
       * trought other cluster the map will reframe the window info */
      disableAutoPan: true,
    });
    this.infoWindow.setHeaderDisabled(true);
    this.infoWindow.addListener('closeclick', () => {});
  }

  clear() {
    if (this.markerCluster) this.markerCluster.setMap(null);
    if (this.markers) this.markers.forEach((marker) => marker.setMap(null));
    if (this.flightPath) this.flightPath.setMap(null);
    if (this.selectionMarkerCluster) this.selectionMarkerCluster.setMap(null);
    if (this.selectionMarkers) this.selectionMarkers.forEach((marker) => marker.setMap(null));

    this.map = undefined;
    this.markers = undefined;
    this.selectionMarkers = undefined;
    this.showPanelPath = undefined;
    this.cleanRestorationOfFocus();
  }

  clearPath() {
    if (this.flightPath) this.flightPath.setMap(null);
    if (this.selectionMarkers) {
      this.selectionMarkers.forEach((marker) => {
        marker.setMap(null);
      });
    }
    this.showPanelPath = undefined;
  }

  resetPath(position: ILatLon) {
    if (this.flightPath) this.flightPath.setMap(null);
    if (this.selectionMarkers) {
      this.selectionMarkers.forEach((marker) => {
        marker.setMap(null);
      });
    }

    const marker = new google.maps.Marker({
      position,
      icon: {
        ...constants.pathIconSize,
        url: constants.iconPath.typeSelected,
      },
    });
    this.selectionMarkers = [marker];
    this.buildSelectedMarkers();
  }

  setupSubscription() {
    if (this.currentMarkList$) {
      this.currentMarkListSubscription = this.currentMarkList$.subscribe(async (markList) => {
        this.remakeMarkers(markList);
      });
    }
  }

  mapDClickHandler = () => {
    /** Animation handler when you click the map twice
     * only does zoom in does not closes and does not
     * unselect the mark */
    this.isDoubleClickState = true;
  };

  mapClickHandler = () => {
    this.isDoubleClickState = false;

    setTimeout(this.debounceSingleClickOnMap, 200);
  };

  debounceSingleClickOnMap = () => {
    if (!this.isDoubleClickState) {
      /** Animation when you click the map it closes the left side
       * panel and unselect the marker */
      this.eventService.selectionUpdatedEvent.emit({ closePanel: true });
    }

    this.isDoubleClickState = false;
  };

  moveToMarker(lat: number, lng: number) {
    this.map.panTo(new google.maps.LatLng({ lat, lng }));
  }

  onStreetView() {
    this.relocateExitPanoramaButton();
  }

  relocateExitPanoramaButton = () => {
    this.setTimeoutIdClusters = setTimeout(() => {
      const elementsStreetViewExistButton = document.getElementsByClassName('gm-iv-container gm-iv-small-container');
      const elementsStreetViewAddress = document.getElementsByClassName('gm-iv-address');

      if (!(elementsStreetViewExistButton?.length > 0 && elementsStreetViewAddress?.length > 0)) return;

      elementsStreetViewExistButton[0].parentElement.style.position = 'absolute';
      elementsStreetViewExistButton[0].parentElement.style.left = `${64 + PANORAMA_VIEW_OFFSET}px`;
      elementsStreetViewAddress[0].parentElement.style.position = 'absolute';
      elementsStreetViewAddress[0].parentElement.style.left = `${92 + PANORAMA_VIEW_OFFSET}px`;

      clearTimeout(this.setTimeoutIdClusters);
    }, 1100);
  };

  setupMap(options = null, withSavedZoomCenter: boolean = false) {
    this.map = new google.maps.Map(document.getElementById('map'), options ?? this.optionsDefault);

    this.map.addListener('rightclick', (event: google.maps.MapMouseEvent) => {
      if (event.latLng) {
        this.onClickEventLocation = {
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        };
      }
    });

    /** Set min and max zoom */
    this.map.setOptions({ minZoom: MIN_ZOOM });

    /** Begin: This code move the panorama exit and panorama
     * location when the user is in the street view mdoe */
    google.maps.event.addListener(this.map.getStreetView(), 'visible_changed', function () {
      const visible = this.getVisible();
      if (!visible) return;

      /** To close side panel when in street view mode */
      window['closeSidePanelOnStreetView'](); // NOSONAR
      window['relocateExitPanoramaButton'](); // NOSONAR
      window['ifWalkOnStreetViewRelocateCloseButton'](); // NOSONAR
      /** End */
    });

    google.maps.event.addListener(this.map, 'zoom_changed', () => {
      this.onZoomOfTheMapChanged();
    });

    google.maps.event.addListener(this.map, 'dragend', () => {
      this.saveZoomAndCurrentLatLon();
    });

    google.maps.event.addListener(this.map, 'click', this.mapClickHandler);
    google.maps.event.addListener(this.map, 'dblclick', this.mapDClickHandler);

    if (withSavedZoomCenter) {
      if (this.savedCenter) this.map.setCenter(new google.maps.LatLng(this.savedCenter));
      if (this.savedZoom) this.map.setZoom(this.savedZoom);
    }
  }

  onZoomOfTheMapChanged() {
    this.saveZoomAndCurrentLatLon();
  }

  saveZoomAndCurrentLatLon() {
    if (!this.map) return;
    const center = this.map.getCenter();
    this.savedCenter = { lat: center.lat(), lng: center.lng() };
    this.savedZoom = this.map.getZoom();
  }

  remakeMarkers(markList: PanelMapMark[]) {
    this.googleMapsLoader.load().then(async () => {
      if (this.markers) this.markers.forEach((marker) => marker.setMap(null));
      if (this.markerCluster) this.markerCluster.setMap(null);
      this.markers = this.createMarkers(markList);
      this.createCluster();
    });
  }

  createCluster() {
    this.markerCluster = new MarkerClusterer({
      map: this.map,
      markers: this.markers,
      algorithm: this.algorithm,
      renderer: this.renderer,
    });

    this.markerCluster.addListener('click', (cluster) => {
      this.hoverMenuesService.onMarkOrClusterClick();
    });

    this.onGetUnclusteredMarks();
  }

  onGetUnclusteredMarks = () => {
    if (!this.map) return;

    const setMarkerIcon = (mark: any, color: string) => {
      const svg = icons.getFullClusterIcon(color, 1, 60, 60, 0.6, 70);
      mark.setIcon({
        url: `data:image/svg+xml;base64,${btoa(svg)}`,
        anchor: new google.maps.Point(25, 25),
      });
    };

    const handleZoomChange = (mark: any) => {
      if (this.map.getZoom() > MIN_ZOOM) {
        mark.setIcon(mark.content.prevIcon);
      }
    };

    this.markerCluster.addListener('clusteringend', () => {
      const unclusteredMarks = this.markerCluster.getUnclusteredMarkers();

      unclusteredMarks.forEach((mark: any) => {
        if (this.map.getZoom() <= MIN_ZOOM) {
          const color =
            this.viewType === constants.viewTypes.ViewLinking
              ? icons.panelNeighColors[mark.content.neighborViewColor]
              : icons.panelNormalColors[mark.content.state];

          setMarkerIcon(mark, color.replace('%23', '#'));
        }

        google.maps.event.addListenerOnce(mark, 'click', () => {
          if (this.map.getZoom() < MIN_ZOOM + 1) {
            this.map.setZoom(MIN_ZOOM + 1);

            this.map.panTo(mark.getPosition());
            this.hoverMenuesService.onMarkOrClusterClick();
          }
        });

        google.maps.event.addListenerOnce(this.map, 'zoom_changed', () => handleZoomChange(mark));

        this.handleZoomRestoration(mark.content._id);
      });
    });
  };

  buildInfoWindowForCurrentLatLng = (marker: any, currentSignalPanelValue: PanelSignal) => {
    const panel: PanelMapMark = this.findPanelByMark(currentSignalPanelValue);
    this.selectedPanelList = this.getPanelsListCurrentLocation(panel);

    /** Save it: to be used if the user change language */
    this.currentMarkerClicked = marker;
    this.currentPanelMarkClicked = panel;
    this.panelSavedForRestoringFocusAnimation = { marker, panel };
    this.zoomSavedForRestoringFocusAnimation = this.map.getZoom();
    /** End */

    this.createContentAndPopUp(marker, panel);
  };

  createContentAndPopUp = (marker: any, panel: PanelMapMark) => {
    let currentContent: string;

    this.infoWindow.close();
    this.infoWindow.open({
      anchor: marker,
      map: this.map,
    });

    if (this.selectedPanelList.length > 1) {
      currentContent = this.groupedWindowHtml(this.selectedPanelList);
      this.infoWindow.setContent(currentContent);
      this.modifyCloseButtonOfTheInfoWindow();
      return;
    }

    currentContent = this.panelMarkInfoWindowHtml(panel);
    this.infoWindow.setContent(currentContent);
    this.modifyCloseButtonOfTheInfoWindow();
  };

  modifyCloseButtonOfTheInfoWindow = () => {
    /** Wait 1 ms for the info window to appear so we can be able to get the element
     * ::ng-deep didnt work and :last-child didn't work as well
     */
    setTimeout(() => {
      const closeButtonSession = document.getElementsByClassName('gm-style-iw-chr') as HTMLCollectionOf<HTMLElement>;
      if (!closeButtonSession) return;

      const closeButtonImageOfTheInfoWindowBox = closeButtonSession?.[0]?.lastChild?.lastChild as HTMLElement;

      if (closeButtonImageOfTheInfoWindowBox) {
        closeButtonImageOfTheInfoWindowBox.style.width = '18px';
        closeButtonImageOfTheInfoWindowBox.style.height = '18px';
      }
    }, 1);
  };

  private handleZoomRestoration(markId: string) {
    if (!this.activateHandleZoomRestoration) return;
    if (
      (this.panelSavedForRestoringFocusAnimation?.panel?._id != markId &&
        this.map.getZoom() < this.zoomSavedForRestoringFocusAnimation) ||
      this.map.getZoom() === MIN_ZOOM
    ) {
      this.closeSideMenu();
    } else if (
      this.map.getZoom() >= this.zoomSavedForRestoringFocusAnimation &&
      this.panelSavedForRestoringFocusAnimation?.panel?._id &&
      this.markHiddenByZoomState &&
      this.hoverMenuesService.updateSideMenuByZoom
    ) {
      this.openSideMenu();
    }
  }

  closeSideMenu() {
    this.markHiddenByZoomState = true;
    this.hoverMenuesService.onZoomOut();
  }

  openSideMenu() {
    this.onMarkClicked(this.panelSavedForRestoringFocusAnimation?.panel?._id, true);
    this.hoverMenuesService.onZoomIn();
    this.markHiddenByZoomState = false;
    if (this.selectedPathForRestoringFocusAnimation) {
      this.setSelectedPath(
        this.selectedPathForRestoringFocusAnimation.positions,
        this.selectedPathForRestoringFocusAnimation.type,
        this.selectedPathForRestoringFocusAnimation.mapPath
      );
    }
  }

  findPanelByMark(marker: PanelSignal) {
    let panel: PanelMapMark;

    this.currentMarkListSubject.subscribe((marks: PanelMapMark[]) => {
      panel = marks.find((panel: PanelMapMark) => marker._id === panel._id);
    });

    return panel;
  }

  getPanelsListCurrentLocation = (panel: PanelMapMark) => {
    let selectedPanelList = [];

    this.currentMarkListSubject.subscribe((marks: PanelMapMark[]) => {
      selectedPanelList = marks.reduce((acc, curr) => {
        if (curr.lat === panel.lat && curr.lng === panel.lng) {
          acc.push(curr);
        }
        return acc;
      }, []);
    });

    return selectedPanelList;
  };

  findAndUpdatePanelIcon(marker: PanelMapMark, icon) {
    if (!this.map) return;
    if (!this.markerCluster) return;

    const mark = this.markers.find((m) => m.content._id === marker._id);
    if (mark) {
      mark.setIcon(icon);
    }
  }

  closeButtonOfThePopupWindow = () => {
    return `<div onclick="closeInfoWindow()" style="position: absolute; top: 0px; right: 5px; font-weight: bolder; color: grey; cursor: pointer; font-size: 14px">×</div>`;
  };

  groupedWindowHtml = (panels: PanelMapMark[]) => {
    const selectedDevices = this.translateService.instant('MeshNet.SelectedDevices');
    const accountsMouseAnimations = `onmouseover="this.style.color='#F4B319'" onmouseout="this.style.color='black'"`;

    return (
      this.closeButtonOfThePopupWindow() +
      `<div style='font-size: 14px; font-weight: bolder; padding-bottom: 8px'>${selectedDevices}</div>` +
      '<div style="max-height: 150px; display: grid; grid-template-columns: auto auto">' +
      panels
        .map(
          (p) =>
            `<div style='height: 22px; padding: 3px'>
              <div ${accountsMouseAnimations} onclick="passAheadWhichMacWasClickedInsideWindow('${
              p._id
            }')" style='color: black; font-size: 12px; font-weight: 400; cursor: pointer'>${this.getMacLabel(p)}</div>
            </div>`
        )
        .join(' ') +
      '</div>'
    );
  };

  getMacLabel = (panel) => {
    if (panel.map?.account) {
      return panel.mac + '-' + panel.map?.account;
    }
    return panel.mac;
  };

  panelMarkInfoWindowHtml = (panel: PanelMapMark) => {
    const checkQtyPartitionsAssociatedGreaterThanOne = panel.partitions.length > 1;

    const displayAccounts = this.extractAccountsFromPartitionsAssociated(
      checkQtyPartitionsAssociatedGreaterThanOne,
      panel
    );

    const accountsPart = this.buildAccountsGridForInfoWindow(
      checkQtyPartitionsAssociatedGreaterThanOne,
      panel,
      displayAccounts
    );

    const macPart = this.buildMacIdentificationForInfoWindow(panel);

    return accountsPart + macPart;
  };

  private buildMacIdentificationForInfoWindow(panel: PanelMapMark) {
    const accountLabelWordSize = this.translateService.instant('MeshNet.Account').length * 8;
    const autoGridColumnSwitcher = `${accountLabelWordSize}px auto auto`;
    const macIdentification = `${panel.mac || '-'}`;
    const macStyle = 'style="font-weight: 500;"';

    return (
      `<div onclick="passAheadWhichMacWasClickedInsideWindow('${panel._id}')" style="cursor: pointer; display: grid; grid-template-columns: ${autoGridColumnSwitcher}">` +
      `<div>${this.translateService.instant('MeshNet.MAC')}:</div>` +
      `<div ${macStyle}>${macIdentification}</div>` +
      '</div>'
    );
  }

  private buildAccountsGridForInfoWindow(
    checkQtyPartitionsAssociatedGreaterThanOne: boolean,
    panel: PanelMapMark,
    displayAccounts: string[]
  ) {
    const accountsStyle = 'font-weight: 500; color: #F4B319; cursor: pointer';
    const accountsMouseAnimations = `onmouseover="this.style.color='black'" onmouseout="this.style.color='#F4B319'"`;
    const plusSignSeeMoreAccountsMouseClick = `onclick="toogleRemainderAccountsOnInfoWindow()"`;
    const preventSelect = '-webkit-user-select: none; -ms-user-select: none; user-select: none;';
    const plusSignDirectives = `${plusSignSeeMoreAccountsMouseClick} style='position: absolute; right: 5px; font-weight: 500; color: #F4B319; cursor: pointer; ${preventSelect}' ${accountsMouseAnimations} id="plus-sign-remainder-accounts"`;
    const accountLabelWordSize = this.translateService.instant('MeshNet.Account').length * 8;

    /**
     * Resets toogleRemainderAccounts everytime the user opens a popup
     */
    this.toogleRemainderAccounts = this.toogleRemainderAccounts ?? false;
    /**
     * Save plus sign text with the remainder quantity of accounts
     * everytime a user opens a popup
     */
    this.plusSignRemainderAccountsSaved = `(+${panel.partitions.length - 1})`;

    return (
      this.closeButtonOfThePopupWindow() +
      `<div style="cursor: pointer; display: grid; grid-template-columns: ${accountLabelWordSize}px auto 60px">` +
      `<div onclick="passAheadWhichMacWasClickedInsideWindow('${panel._id}')">${this.translateService.instant(
        'MeshNet.Account'
      )}</div>` +
      `<div onclick="passAheadWhichMacWasClickedInsideWindow('${panel._id}')" style='${accountsStyle}' ${accountsMouseAnimations}>` +
      `<div>${displayAccounts[0]}</div>` +
      '<div id="remainder-accounts-info-window" style="display: none">' +
      (checkQtyPartitionsAssociatedGreaterThanOne ? `<div>${displayAccounts[1]}</div>` : '') +
      '</div>' +
      `</div>` +
      /** This variable here this.plusSignRemainderAccountsSaved will not be updated reactively */
      /** Do not put onclick="passAheadWhichMacWasClickedInsideWindow('${panel._id}')" over the sign plus button */
      (panel.partitions.length > 1 ? `<div ${plusSignDirectives}>${this.plusSignRemainderAccountsSaved}</div>` : '') +
      '</div>'
    );
  }

  private extractAccountsFromPartitionsAssociated(
    checkQtyPartitionsAssociatedGreaterThanOne: boolean,
    panel: PanelMapMark
  ) {
    if (panel.partitions.length === 0) {
      if (panel.map?.account) return [panel.map?.account];
      return [this.translateService.instant('MeshNet.DisconnectedDevice')];
    }

    let sortedPartitions = panel.partitions.sort(this.comparePartitions);

    if (panel.map?.account && sortedPartitions[0].account != panel.map?.account) {
      sortedPartitions = [
        {
          account: panel.map?.account,
          name: '',
          partitionNumber: 0,
        },
        ...sortedPartitions,
      ];
    }

    const firstPartition = sortedPartitions[0];

    if (checkQtyPartitionsAssociatedGreaterThanOne) {
      const remainderPartitions = sortedPartitions.slice(1);

      return [
        `<div>${firstPartition.account} ${firstPartition.name}</div>`,
        remainderPartitions.map((partition) => `<div>${partition.account} ${partition.name}</div>`).join(' '),
      ];
    }

    return [`<div>${firstPartition.account} ${firstPartition.name}</div>`];
  }

  comparePartitions(a, b) {
    return a.partitionNumber < b.partitionNumber ? -1 : 1;
  }

  setNeighborCount = (neighborsQty: number, state: string) => {
    if (neighborsQty == 1 && state != 'null') return TypeLinking.LOW;
    else if (neighborsQty == 2 && state != 'null') return TypeLinking.GOOD;
    else if (neighborsQty >= 3 && state != 'null') return TypeLinking.OPTIMUM;
    else if (state === 'null') return TypeLinking.DISCONNECTED;
    else return TypeLinking.NULL;
  };

  buildMarker(item: PanelMapMark) {
    const marker = new google.maps.Marker({
      position: { lat: item.lat, lng: item.lng },
      icon: item.img,
      zIndex: item.index,
      content: {
        state: item.state,
        _id: item._id,
        prevIcon: item.img,
        neighborViewColor: this.setNeighborCount(item.map?.neighborsQty, item.state),
      },
    });

    google.maps.event.addListener(marker, 'click', (value) => {
      /** We need to keep this method here because
       * createWindowHelperHooks defines window functions
       * to cooperate with the click actions */
      /** Build window */
      this.buildInfoWindowForCurrentLatLng(marker, { _id: marker.content._id });

      /** Focus on the marker clicked */
      this.moveToMarker(marker.position.lat(), marker.position.lng());

      /** Notify parent about marker clicked */
      this.onMarkClicked(marker.content._id, false);
      this.hoverMenuesService.onMarkOrClusterClick();
    });

    return marker;
  }

  createWindowHelperHooks() {
    /** Welcome to centre of gambiarras */
    /** Hack to get information from onclick mac */
    window['passAheadWhichMacWasClickedInsideWindow'] = (panelId: string) => {
      this.onlyOpenWithScroll = true;
      this.zoomSavedForRestoringFocusAnimation = this.map.getZoom();

      this.infoWindow.close();
      this.onMarkClicked(panelId, true);
      this.hoverMenuesService.onClickInfoWindows(panelId);
    };

    window['toogleRemainderAccountsOnInfoWindow'] = () => {
      this.toogleRemainderAccounts = !this.toogleRemainderAccounts;
      const el = document.getElementById('remainder-accounts-info-window');
      el.style.display = this.toogleRemainderAccounts ? 'block' : 'none';

      const sign = document.getElementById('plus-sign-remainder-accounts');
      sign.innerText = this.toogleRemainderAccounts ? '( - )' : this.plusSignRemainderAccountsSaved;
    };

    window['closeInfoWindow'] = () => {
      this.infoWindow.close();
      this.markClickCurrentState = ExpandPanelInformationState.popUpInfoBox;
    };

    window['closeSidePanelOnStreetView'] = () => {
      this.eventService.selectionUpdatedEvent.emit({ closePanel: true });
      this.eventService.menuBarNavigation.emit({ closeLeftMenuNavigationBar: true });
      this.cleanRestorationOfZoomProperties();
    };

    window['relocateExitPanoramaButton'] = () => {
      this.relocateExitPanoramaButton();
    };

    window['ifWalkOnStreetViewRelocateCloseButton'] = () => {
      this.map.getStreetView().addListener('position_changed', () => {
        this.relocateExitPanoramaButton();
      });
    };
  }

  createMarkers(markList: PanelMapMark[]) {
    this.createWindowHelperHooks();

    if (!markList) return [];

    return markList.map((item) => this.buildMarker(item));
  }

  setMapType = (type: string) => {
    if (!this.map) return;

    this.mapTypeId = type;

    this.map.setMapTypeId(type);
  };

  placesShowSwitch = (value: boolean) => {
    if (!this.map) return;

    /** Switch boolean value */
    this.showPlaces = value;

    let options;

    if (this.showPlaces) {
      options = {
        ...this.optionsWithoutMapId,
        styles: styles.getCustomStyles(this.showPlaces),
      };
    } else {
      options = {
        ...this.optionsDefault,
        styles: styles.getCustomStyles(this.showPlaces),
      };
    }

    /** !! After using cloud based ids remove this whole styles thing */
    /** !! Remove this after changing to Map ID only */
    this.onSwitchPlacesChangeOptions(options);
  };

  onSwitchPlacesChangeOptions(options) {
    const center = this.map.getCenter();
    const mapSwitchCenter = { lat: center.lat(), lng: center.lng() };
    const mapSwitchZoom = this.map.getZoom();

    this.map.setOptions(options);

    if (mapSwitchCenter) this.map.setCenter(new google.maps.LatLng(mapSwitchCenter));
    if (mapSwitchZoom) this.map.setZoom(mapSwitchZoom);
  }

  /** begin: States for click animations
   * please to change things here you have
   * to take care with varios impacts of
   * other features:  search, view-on-map
   */
  setMapClickedStateByName(name: string) {
    this.markClickCurrentState = ExpandPanelInformationState[name];
  }

  panelClickedOnGroupedMacs = (props: IPanelClickedState) => {
    const { panelId, panelClickedOnGroupedMacs } = props;
    this.markClickCurrentState = ExpandPanelInformationState.popUpInfoBox;
    this.infoWindow.close();
    this.contentIdSaved = undefined;
    this.eventService.selectionUpdatedEvent.emit({
      _id: panelId,
      panelClickedOnGroupedMacs,
      closePanel: this.markSelectionState,
    });
    this.currentMarkerClicked = undefined;
    this.currentPanelMarkClicked = undefined;

    // activate HandleZoomRestoration function after the first device selected
    this.activateHandleZoomRestoration = true;
  };

  panelIdSwitched = (props: IPanelClickedState) => {
    this.selectedPathForRestoringFocusAnimation = undefined;
    const { panelId } = props;
    this.contentIdSaved = panelId;
  };

  popUpInfoBoxSwitchedId = (props: IPanelClickedState) => {
    this.cleanRestorationOfZoomProperties();
    const { panelId, panelClickedOnGroupedMacs } = props;
    this.infoWindow.close();
    this.eventService.selectionUpdatedEvent.emit({
      _id: panelId,
      panelClickedOnGroupedMacs,
      closePanel: this.markSelectionState,
    });
    this.markClickCurrentState = ExpandPanelInformationState.closingSidePanelAndPopup;
  };

  popUpInfoBox = (props: IPanelClickedState) => {
    const { panelId } = props;
    this.contentIdSaved = panelId;
    this.markClickCurrentState = ExpandPanelInformationState.leftSidePanel;
  };

  stateMarkIconAnimationsHandlers = {
    panelClickedOnGroupedMacs: this.panelClickedOnGroupedMacs,
    panelIdSwitched: this.panelIdSwitched,
    popUpInfoBoxSwitchedId: this.popUpInfoBoxSwitchedId,
    popUpInfoBox: this.popUpInfoBox,
  };
  /** end: States for click animations */

  onMarkClicked(panelId: string, panelClickedOnGroupedMacs: boolean) {
    /** Here the ifs order matters */
    if (this.contentIdSaved != panelId) this.markClickCurrentState = ExpandPanelInformationState.panelIdSwitched;
    if (panelClickedOnGroupedMacs) this.markClickCurrentState = ExpandPanelInformationState.panelClickedOnGroupedMacs;

    /** Metaprogramming: markAnimationHandler will mutate accordingly with the enum ExpandPanelInformationState */
    const markAnimationHandler = this.stateMarkIconAnimationsHandlers[this.markClickCurrentState];
    if (markAnimationHandler) markAnimationHandler({ panelId, panelClickedOnGroupedMacs });
  }
}
