import { Component, OnInit, Input } from '@angular/core';
import { KeyboardService } from '../../services/keyboard.service';
import constants from '../../constants';

class LedsState {
  on?: boolean;
  value?: number;
  blinkStatusInterval?: number;
}

@Component({
  selector: 'app-dsc',
  templateUrl: './dsc.component.html',
  styleUrls: ['./dsc.component.scss']
})
export class DscComponent implements OnInit {
  @Input() zoneLedStatus: LedsState[];
  @Input() stateLedStatus: LedsState[];
  constants = constants;

  constructor(
    public keyboardService: KeyboardService
  ) {}

  ngOnInit(): void {
  }

}
