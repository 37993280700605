import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PopupLanguageService {
  timeoutId: any;
  languageOnTheWatch = new BehaviorSubject<string>(this.translateService.currentLang);

  constructor(private readonly translateService: TranslateService) {}

  setLang(lang) {
    this.languageOnTheWatch.next(lang);
  }
}
