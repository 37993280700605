<div class="container">
  <div class="close-button">
    <button mat-icon-button (click)="closeModal()"><mat-icon>close</mat-icon></button>
  </div>
  <div class="content">
    <div class="success-confirm" *ngIf="this.data.type === 'successConfirmation'">
      <h1>{{ data.title }}</h1>
      <mat-dialog-actions align="end">
        <button mat-button (click)="closeModal()">OK</button>
      </mat-dialog-actions>
    </div>
    <div class="exit-confirmation" *ngIf="this.data.type === 'exitConfirmation'">
      <h1>{{ 'Shared.ChangesWereNotSaved' | translate }}</h1>
      <p>
        {{ 'Shared.ChangesWereNotSavedInfo' | translate }}
      </p>
      <mat-dialog-actions>
        <div class="buttons">
          <button mat-button (click)="closeModal()" class="white-default-button">
            {{ 'Shared.Cancel' | translate }}
          </button>
          <button mat-button (click)="closeAllModals()" class="yellow-default-button">
            {{ 'Shared.BacktoHome' | translate }}
          </button>
        </div>
      </mat-dialog-actions>
    </div>
    <div class="changes-successfully" *ngIf="this.data.type === 'changesSuccessfully'">
      <h1>{{ 'Shared.ChangesSuccessfully' | translate }}</h1>
      <div class="changes-successfully-button">
        <button mat-button (click)="closeAllModals()" class="yellow-default-button">
          {{ 'Shared.BacktoHome' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
