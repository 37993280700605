import { Component, OnInit, OnDestroy } from '@angular/core';
import { ComandService } from '../services/comand.service';
import { DeviceComands } from '../models/command';


@Component({
  selector: 'app-command-state',
  templateUrl: './command-state.component.html',
  styleUrls: ['./command-state.component.scss']
})
export class CommandStateComponent implements OnInit, OnDestroy {
  currentDevice: DeviceComands;
  currentListIndex: number;

  constructor(
    private commandService: ComandService
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.commandService.commandProcessingList = [];
  }

  getDisplayCommandList = () => {
    if (this.commandService.commandProcessingList) {
      if (!this.currentDevice || ((this.currentDevice.mac !== this.commandService.selectedMac) || this.currentListIndex !== this.commandService.listIndex)) {
        this.currentListIndex = this.commandService.listIndex;
        this.currentDevice = this.commandService.commandProcessingList.find(c => c.mac === this.commandService.selectedMac);
      }
      if (this.currentDevice) {
        return this.currentDevice.commandList;
      }
    }
    return [];
  }

  isParLine = (command) => {
    if (this.currentDevice) {
      return !!(this.currentDevice.commandList.indexOf(command) %2);
    }
    return false;
  }
}
