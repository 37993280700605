import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipDeitresDirective } from './tooltip.directive';
import { BrowserModule } from '@angular/platform-browser';
import { TooltipComponent } from './tooltip/tooltip.component';

@NgModule({
  declarations: [TooltipDeitresDirective, TooltipComponent],
  imports: [CommonModule, BrowserModule],
  exports: [TooltipDeitresDirective],
})
export class TooltipDeitresModule {}
