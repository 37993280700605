<div id="mySidebar" class="sidebar" [ngClass]="{ 'show-menu': hoverMenuesService.sideMenuStatus }">
  <!-- Hide/Show Status -->
  <div *ngIf="panel" class="side-panel-content">
    <div class="panel-info-content">
      <!-- Content -->
      <!-- Name, Account, partitions -->
      <!-- Status Leds Status -->
      <div class="content-section">
        <!-- begin: leds -->
        <div class="side-panel-top-leds">
          <div class="grid-leds-icons">
            <img
              alt
              class="img-button"
              matTooltip="{{ 'MeshNet.MeshState' | translate }}"
              [src]="printDeviceStateIcon()"
            />
            <img alt class="img-button" matTooltip="{{ 'MeshNet.ReceiverState' | translate }}" [src]="receiverState" />
            <img
              alt
              class="img-button"
              matTooltip="{{ 'MeshNet.MonitoringState' | translate }}"
              [src]="monitoringState"
            />
          </div>
          <!-- end: leds -->

          <!-- begin: go to partition icon and close icon -->
          <div class="grid-leds-icons-right">
            <img
              alt
              class="img-button"
              style="cursor: pointer;"
              onKeyPress
              onKeyDown
              onKeyUp
              matTooltip="{{ 'MeshNet.GoToPanelList' | translate }}"
              [src]="printRedirectDeviceIcon()"
              (click)="goToList()"
            />
            <div
              onKeyPress
              onKeyDown
              onKeyUp
              class="exit-button"
              matTooltip="{{ 'MeshNet.ExitDevice' | translate }}"
              (click)="onExit()"
            >
              <mat-icon class="exit-button">logout</mat-icon>
            </div>
          </div>
        </div>
        <!-- end: go to partition icon and close icon -->
      </div>

      <!-- begin: main partition title -->
      <div class="content-section">
        <span class="side-panel-main-header">{{ getName() }}</span>
      </div>
      <!-- end: main partition title -->

      <div class="scrollbar-content scrollbar">
        <!-- begin basic info -->
        <div class="content-section">
          <div class="flex-container-expander">
            <div>
              <span class="side-panel-sub-header">{{ 'MeshNet.BasicInfo' | translate }}</span>
            </div>
            <div>
              <button
                matTooltip="{{ (showInterface ? 'MeshNet.Collapse' : 'MeshNet.Expand') | translate }}"
                mat-icon-button
                matTooltipShowDelay="750"
                matTooltipPosition="after"
                (click)="switchShowBasicInfo(!showBasicInfo)"
                aria-hidden="false"
                class="expander-arrow"
              >
                <img
                  alt
                  class="img-button"
                  [src]="showBasicInfo ? 'assets/icons/menues/arrow_up.svg' : 'assets/icons/menues/arrow_down.svg'"
                />
              </button>
            </div>
          </div>
          <div class="hide-section">
            <hr class="side-panel-divider-hr" />
          </div>

          <div *ngIf="showBasicInfo" class="row">
            <div class="section">
              <div>
                <span class="item">{{ 'MeshNet.PanelMac' | translate }}</span>
                <span class="subitem"> {{ panel.mac }}</span>
              </div>
              <div *ngIf="panel.map?.account">
                <span class="item">{{ 'MeshNet.Account' | translate }}</span>
                <span class="subitem"> {{ panel.map?.account }}</span>
              </div>
              <div>
                <span class="item">{{ 'MeshNet.Company' | translate }}</span>
                <span class="subitem">
                  {{ authService.getCompanyName(panel.company) }}
                </span>
              </div>
              <div>
                <span class="item">{{ 'MeshNet.PanelCoordinates' | translate }}</span>
                <span class="subitem"> {{ getCoords() }}</span>
              </div>
              <div>
                <span class="item">{{ 'MeshNet.PanelType' | translate }}</span>
                <span class="subitem"> {{ getType(panel) }}</span>
              </div>
              <div *ngIf="panel.map?.function">
                <span class="item">{{ 'MeshNet.PanelFunction' | translate }}</span>
                <span class="subitem" *ngIf="panel.map?.function"> {{ panel.map?.function }}</span>
              </div>
              <div *ngIf="panel.map?.instalationType">
                <span class="item">{{ 'MeshNet.PanelInstType' | translate }}</span>
                <span class="subitem" *ngIf="panel.map?.instalationType">
                  {{ 'MeshNet.type' + panel.map?.instalationType | translate }}
                </span>
              </div>
              <div>
                <span class="item">{{ 'MeshNet.MeshNetRol' | translate }}</span>
                <span class="subitem"> {{ 'MeshNet.' + panelStatus.getPanelFunction(panel) | translate }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- end: basic info -->

        <!-- start: accounts -->
        <div *ngIf="panel.partitions.length > 0" class="content-section">
          <div class="flex-container-expander">
            <div>
              <span class="side-panel-sub-header">{{ ('MeshNet.PanelPartitions' | translate).replace(':', '') }}</span>
            </div>
            <div>
              <button
                matTooltip="{{ (showPartitions ? 'MeshNet.Collapse' : 'MeshNet.Expand') | translate }}"
                mat-icon-button
                matTooltipShowDelay="750"
                matTooltipPosition="after"
                (click)="switchShowPartitions(!showPartitions)"
                aria-hidden="false"
                class="expander-arrow"
              >
                <img
                  alt
                  class="img-button"
                  [src]="showPartitions ? 'assets/icons/menues/arrow_up.svg' : 'assets/icons/menues/arrow_down.svg'"
                />
              </button>
            </div>
          </div>
          <div class="hide-section">
            <hr class="side-panel-divider-hr" />
          </div>
          <span *ngIf="showPartitions">
            <span class="item">{{ 'MeshNet.PartitionsList' | translate }}</span>
            <div *ngFor="let c of panel.partitions">
              <div class="grid-template-columns">
                <span class="subitem">{{ c.account }} - </span>
                <span class="subitem">{{ c.name || '-' }}</span>
              </div>
            </div>
          </span>
        </div>
        <!-- end: accounts -->

        <!-- begin: Comportamiento en la red -->
        <div class="content-section">
          <div class="flex-container-expander">
            <div>
              <span class="side-panel-sub-header">{{ ('MeshNet.Neighbors' | translate).replace(':', '') }}</span>
            </div>
            <div>
              <button
                matTooltip="{{ (showDeviceType ? 'MeshNet.Collapse' : 'MeshNet.Expand') | translate }}"
                mat-icon-button
                matTooltipShowDelay="750"
                matTooltipPosition="after"
                (click)="switchShowDeviceType(!showDeviceType)"
                aria-hidden="false"
                class="expander-arrow"
              >
                <img
                  alt
                  class="img-button"
                  [src]="showDeviceType ? 'assets/icons/menues/arrow_up.svg' : 'assets/icons/menues/arrow_down.svg'"
                />
              </button>
            </div>
          </div>
          <div class="hide-section">
            <hr class="side-panel-divider-hr" />
          </div>

          <!-- Panel's Net Behaviour -->
          <div *ngIf="showDeviceType" class="row">
            <div class="grid-template-columns">
              <div
                class="item"
                matTooltip="{{ 'MeshNet.InfoNeighborQty' | translate }}"
                matTooltipShowDelay="750"
                matTooltipPosition="after"
              >
                {{ 'MeshNet.NeighborsQty' | translate }}
                <span
                  class="subitem"
                  matTooltip="{{ 'MeshNet.InfoNeighborQty' | translate }}"
                  matTooltipShowDelay="750"
                  matTooltipPosition="after"
                >
                  {{ panel.map?.neighborsQty != undefined ? panel.map?.neighborsQty : 0 }}
                </span>
              </div>
            </div>
          </div>

          <!-- Panel's Neighbor -->
          <div *ngIf="showDeviceType">
            <div class="section">
              <div style="display: flex; justify-content: space-between; width: 100%">
                <div>
                  <span class="item">{{ 'MeshNet.NeighborsList' | translate }}</span>
                </div>
                <div>
                  <mat-icon
                    onKeyPress
                    onKeyDown
                    onKeyUp
                    matTooltip="{{ getNeighborTooltip() | translate }}"
                    matTooltipShowDelay="750"
                    matTooltipPosition="after"
                    (click)="showNeighbors()"
                    class="external-icon-buttons prevent-select"
                    >{{ getNeighborIconSource() }}</mat-icon
                  >
                  <mat-icon
                    onKeyPress
                    onKeyDown
                    onKeyUp
                    matTooltip="{{ 'MeshNet.Reload' | translate }}"
                    matTooltipShowDelay="750"
                    matTooltipPosition="after"
                    (click)="reloadNeighbors(panel)"
                    *ngIf="!panel.requesting"
                    class="external-icon-buttons prevent-select"
                    >refresh</mat-icon
                  >
                  <span *ngIf="panel.requesting" class="loader"></span>
                </div>
              </div>

              <div
                class="subitem"
                *ngIf="
                  !panelStatus?.neighborReloadState?.started &&
                  !panelStatus?.neighborReloadState &&
                  (panel.map?.neighbors == undefined || panel.map?.neighbors?.length == 0) &&
                  !isLoadingResults
                "
              >
                {{
                  (noNeighborsCheck == 'neighbors-equal-zero' ? 'MeshNet.NoNeighbors' : 'MeshNet.NeighboursNotFound')
                    | translate
                }}
                <br />
                <span *ngIf="noNeighborsCheck != 'neighbors-equal-zero'">
                  {{ 'MeshNet.TryRefreshing' | translate }}
                </span>
              </div>

              <div
                class="skel-loader"
                *ngIf="
                  (panel.map?.neighbors?.length == 0 && isLoadingResults) || panelStatus?.neighborReloadState?.started
                "
                style="height: 131px; width: 297px; background-color: #3946509b; border-radius: 10px"
              ></div>

              <app-mac-table
                *ngIf="panel?.map?.neighbors?.length > 0"
                id="app-neighbors-mac-table"
                [loading]="
                  (panel.map?.neighbors?.length == 0 && isLoadingResults) || panelStatus?.neighborReloadState?.started
                "
                [title]="'MeshNet.MacAddresses' | translate"
                [dataset]="panel?.map?.neighbors"
                [update]="panel?.map?.neighbors.length"
              ></app-mac-table>
            </div>
            <div *ngIf="!isLoadingResults && isConnected()" class="restart-button-parent">
              <span class="subitem"
                >{{ 'SignalConsult.MeshConsult' | translate }}
                <span>{{ 'SignalConsult.MeshConsult2' | translate }}</span>
              </span>
              <button
                mat-icon-button
                class="restart-button"
                matTooltipShowDelay="750"
                matTooltipPosition="after"
                (click)="reloadNeighborProcess()"
                aria-hidden="false"
              >
                <span style="padding-top: 1px">
                  {{ 'MeshNet.RestartDevice' | translate }}
                </span>
              </button>
            </div>
          </div>
        </div>
        <!-- end: Comportamiento en la red -->

        <!-- Panel's Path -->
        <!--   <div class="content-section" *ngIf="!!panel.map?.parent">
          <div class="flex-container-expander">
            <div>
              <span class="side-panel-sub-header">{{ ('MeshNet.Paths' | translate).replace(':', '') }}</span>
            </div>
            <div>
              <button
                matTooltip="{{ (showPathSession ? 'MeshNet.Collapse' : 'MeshNet.Expand') | translate }}"
                mat-icon-button
                matTooltipShowDelay="750"
                matTooltipPosition="after"
                (click)="switchShowPathSession(!showPathSession)"
                aria-hidden="false"
                class="expander-arrow"
              >
                <img
                  alt
                  class="img-button"
                  [src]="showPathSession ? 'assets/icons/menues/arrow_up.svg' : 'assets/icons/menues/arrow_down.svg'"
                />
              </button>
            </div>
          </div>
          <div class="hide-section">
            <hr class="side-panel-divider-hr" />
          </div>

          <div *ngIf="showPathSession && !!panel.map?.parent">
            <div class="grid-template-columns">
              <div class="item">
                <span>{{ 'MeshNet.PathLength' | translate }}</span>
                <span class="subitem">
                  {{ validPath() ? panel.map.path.length : ('MeshNet.PathUnknown' | translate) }}</span
                >
              </div>
            </div>
            <div style="display: flex; justify-content: space-between; width: 100%">
              <div class="item">
                <span>{{ 'MeshNet.PathsList' | translate }}</span>
              </div>
              <div>
                <mat-icon
                  onKeyPress
                  onKeyDown
                  onKeyUp
                  matTooltip="{{ (getPathActive() ? 'MeshNet.Hide' : 'MeshNet.Show') | translate }}"
                  mat-icon-button
                  matTooltipShowDelay="750"
                  matTooltipPosition="after"
                  (click)="showPath()"
                  class="prevent-select"
                  style="color: white; font-size: 17px; padding-right: 30px; height: 0; width: 0; cursor: pointer"
                  >{{ getPathActive() ? 'visibility' : 'visibility_off' }}</mat-icon
                >
                <span *ngIf="panel.requesting" class="loader"></span>
              </div>
            </div>

            <div
              class="skel-loader"
              *ngIf="isLoadingResults"
              style="height: 128px; width: 297px; background-color: #3946509b; border-radius: 10px"
            ></div>

            <div class="subitem" *ngIf="panel.map?.parent == undefined && !isLoadingResults">
              {{ 'MeshNet.PathsNotFound' | translate }}
            </div>

            <div class="subitem" *ngIf="!pathEyeClicked && !isLoadingResults">
              {{ 'MeshNet.ClickThePathEye' | translate }}
            </div>

            <app-mac-table
              *ngIf="panel.map?.parent && panel?.map?.path?.length > 0"
              id="app-paths-mac-table"
              [loading]="panel.map?.parent && isLoadingResults"
              [title]="('MeshNet.Paths' | translate).replace(':', '')"
              [dataset]="panel?.map?.path"
              [update]="panel?.map?.path && panel?.map?.path.length"
            ></app-mac-table>
          </div>
        </div> -->
        <!-- end: Panel's Path -->

        <!-- begin: Connectivity -->
        <div class="content-section">
          <div class="flex-container-expander">
            <div>
              <span class="side-panel-sub-header">{{ 'MeshNet.Conectivity' | translate }}</span>
            </div>
            <div>
              <button
                matTooltip="{{ (showInterface ? 'MeshNet.Collapse' : 'MeshNet.Expand') | translate }}"
                mat-icon-button
                matTooltipShowDelay="750"
                matTooltipPosition="after"
                (click)="switchShowInterface(!showInterface)"
                aria-hidden="false"
                class="expander-arrow"
              >
                <img
                  alt
                  class="img-button"
                  [src]="showInterface ? 'assets/icons/menues/arrow_up.svg' : 'assets/icons/menues/arrow_down.svg'"
                />
              </button>
            </div>
          </div>
          <div class="hide-section">
            <hr class="side-panel-divider-hr" />
          </div>
          <div *ngIf="panel.map?.interfaceReport || panel.map?.lastReport; else noConnection">
            <div *ngIf="showInterface" class="row">
              <div class="grid-template-columns">
                <div class="item" *ngIf="panel.map?.lastReport">
                  {{ 'MeshNet.InterfaceDate' | translate }}
                  <span class="subitem" *ngIf="panel.map?.lastReport">{{
                    panel.map?.lastReport | date : 'dd/MM/yyyy - HH:mm:ss'
                  }}</span>
                </div>
                <div class="item">
                  {{ 'MeshNet.ReportQty' | translate }}
                  <span class="subitem">{{
                    panel.reports?.length ?? '0'
                  }}</span>
                </div>
                <div class="item" *ngIf="panel.map?.interfaceReport">
                  {{ 'MeshNet.Interface' | translate }}
                  <span class="subitem" *ngIf="panel.map?.interfaceReport">{{
                    translateInterfaceReport(panel.map?.interfaceReport)
                  }}</span>
                </div>
              </div>
            </div>
            <div *ngIf="showInterface" class="row">
              <span class="item">{{ 'MeshNet.SignalStrengthConnection' | translate }}</span>
            </div>
            <div *ngIf="showInterface" class="row">
              <div class="section">
                <div class="subitem">{{ 'MeshNet.SignalRF' | translate }}</div>
                <div class="subitem">{{ 'MeshNet.SignalWIFI' | translate }}</div>
                <div class="subitem">
                  {{ 'MeshNet.SignalEthernet' | translate }}
                </div>
                <div class="subitem">
                  {{ 'MeshNet.SignalCelular' | translate }}
                </div>
              </div>
              <div style="display: block; padding-left: 10px">
                <div class="subitem">{{ getSignal('mesh') }}</div>
                <div class="subitem">{{ getSignal('wifi') }}</div>
                <div class="subitem">{{ getSignal('eth') }}</div>
                <div class="subitem">{{ getSignal('mobile') }}</div>
              </div>
            </div>
          </div>
          <ng-template #noConnection>
            <div *ngIf="showInterface" class="subitem">
              {{ 'MeshNet.NotRespondedConnectivity' | translate }}
            </div></ng-template
          >
        </div>
        <!-- end: Connectivity -->

        <!-- begin: Hawrdware, Firmware, Zigbee & Model -->
        <div class="content-section" *ngIf="panelVersionExists()">
          <div class="flex-container-expander">
            <div>
              <span class="side-panel-sub-header">{{ 'MeshNet.HardFirm' | translate }}</span>
            </div>
            <div>
              <button
                matTooltip="{{ (showHwFw ? 'MeshNet.Collapse' : 'MeshNet.Expand') | translate }}"
                mat-icon-button
                matTooltipShowDelay="750"
                matTooltipPosition="after"
                (click)="switchShowHwFw(!showHwFw)"
                aria-hidden="false"
                class="expander-arrow"
              >
                <img
                  alt
                  class="img-button"
                  [src]="showHwFw ? 'assets/icons/menues/arrow_up.svg' : 'assets/icons/menues/arrow_down.svg'"
                />
              </button>
            </div>
          </div>
          <div class="hide-section">
            <hr class="side-panel-divider-hr" />
          </div>
          <div *ngIf="showHwFw">
            <div class="grid-template-columns">
              <div class="item" *ngIf="panel.version?.deviceModel">
                {{ 'MeshNet.PanelModel' | translate }}
                <span class="subitem" *ngIf="panel.version?.deviceModel">{{ panel.version?.deviceModel }}</span>
              </div>
              <div class="item" *ngIf="panel.version?.hardVersion">
                {{ 'MeshNet.PanelHardware' | translate }}
                <span class="subitem" *ngIf="panel.version?.hardVersion">{{ panel.version?.hardVersion }}</span>
              </div>
              <div class="item" *ngIf="panel.version?.firmVersion">
                {{ 'MeshNet.PanelFirmware' | translate }}
                <span class="subitem" *ngIf="panel.version?.firmVersion">{{ panel.version?.firmVersion }}</span>
              </div>
              <div
                class="item"
                *ngIf="
                  panel.version?.zigbeeVersion != 'UNKNOWN' &&
                  panel.version?.deviceModel &&
                  panel.version?.deviceModel.startsWith('CMP')
                "
              >
                {{ 'MeshNet.PanelZigbee' | translate }}
                <span class="subitem" *ngIf="panel.version?.zigbeeVersion">{{ panel.version?.zigbeeVersion }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Neighbor reload process -->
        <app-neighbor-reload *ngIf="panelStatus.neighborReloadState"></app-neighbor-reload>
      </div>
      <!-- end: Hawrdware, Firmware, Zigbee & Model -->
    </div>

    <div class="close-side-panel">
      <button
        class="close-last-panel"
        *ngIf="panelStatus.saveLastPanel"
        mat-icon-button
        matTooltipShowDelay="750"
        matTooltipPosition="after"
        (click)="closeShowStatus()"
        aria-hidden="false"
      >
        <img src="assets/icons/menues/first-page-icon.svg" alt class="img-button" />
      </button>
    </div>
  </div>
</div>

<div class="open-last-panel-container">
  <button
    class="open-last-panel"
    *ngIf="panelStatus.saveLastPanel && !hoverMenuesService.sideMenuStatus"
    mat-icon-button
    matTooltipShowDelay="750"
    matTooltipPosition="after"
    (click)="openSideMenuButton()"
    aria-hidden="false"
  >
    <img src="assets/icons/menues/last-page-icon.svg" alt class="img-button" />
  </button>
</div>
