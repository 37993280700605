<button
  type="button"
  mat-icon-button
  aria-label="Info"
  aria-label="Device Errors"
  [matTooltip]="'Panel.DeviceInformation' | translate"
  matTooltipShowDelay="750"
  matTooltipPosition="after"
  style="width: 39px; height: 39px"
>
  <mat-icon
    *ngIf="this.panelErrorStatusService.states[this.panelId] === 'normal'"
    [color]="panelsService.showActive ? 'primary' : '#808080'"
    [ngStyle]="{ fontSize: '38px', paddingRight: '18px', paddingBottom: '2px' }"
    [style]="!panelsService.showActive ? 'color: gray' : ''"
    >info</mat-icon
  >
  <mat-icon
    *ngIf="this.panelErrorStatusService.states[this.panelId] === 'visited'"
    [ngStyle]="{ color: '#808080', fontSize: '38px', paddingRight: '18px', paddingBottom: '2px' }"
    >info</mat-icon
  >
  <mat-icon
    *ngIf="this.panelErrorStatusService.states[this.panelId] === 'hidden'"
    style="display: none"
    [ngStyle]="{ fontSize: '38px', paddingRight: '18px', paddingBottom: '2px' }"
    >info</mat-icon
  >
</button>
