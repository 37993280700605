import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TechSupportComponent } from './tech-support/tech-support.component';
import { AppMaterialModule } from '../app-material/app-material.module';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MacListComponent } from './mac-list/mac-list.component';
import { ComandSelectorComponent } from './comand-selector/comand-selector.component';
import { CommandStateComponent } from './command-state/command-state.component';
import { NewCommandComponent } from './new-command/new-command.component';
import { CommandConsoleComponent } from './command-console/command-console.component';
import { ReceiverSelectorComponent } from './receiver-selector/receiver-selector.component';
import { TranslateModule } from '@ngx-translate/core';
import { SendCommandsServiceService } from './services/send-commands-service.service';
import { ComandService } from './services/comand.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

@NgModule({
  declarations: [
    TechSupportComponent,
    MacListComponent,
    ComandSelectorComponent,
    CommandStateComponent,
    NewCommandComponent,
    CommandConsoleComponent,
    ReceiverSelectorComponent,
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatSlideToggleModule,
    TranslateModule,
  ],
  providers: [
    SendCommandsServiceService,
    ComandService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'always' },
    },
  ],
})
export class TechSupportModule {}
