import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private auth: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.auth.isLoggedIn() && state.url !== '/login') {
      this.router.navigateByUrl('/login');
      return false;
    } else if (this.auth.isLoggedIn() && state.url === '/login') {
      this.router.navigateByUrl('/maps');
      return false;
    } else if (this.auth.isLoggedIn() && state.url === '/techsupport' && !this.auth.user.enableTechSupport) {
      this.router.navigateByUrl('/maps');
      return true;
    }
    return true;
  }
}
