import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsLoaderService {
  private readonly apiKey: string = environment.googleMapApiKey;
  private readonly libraries: string[] = ['places', 'streetView'];
  private scriptLoadingPromise: Promise<void>;
  promiseState = {
    isFulfilled: false,
  };

  constructor() {}

  load(): Promise<void> {
    if (this.promiseState.isFulfilled) {
      return this.scriptLoadingPromise;
    }

    return this.fetchGoogleMapsApi();
  }

  private fetchGoogleMapsApi() {
    this.scriptLoadingPromise = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      const params = new URLSearchParams({
        key: this.apiKey,
        v: 'weekly',
        libraries: this.libraries.join(','),
      });
      script.src = `https://maps.googleapis.com/maps/api/js?${params.toString()}`;
      script.async = true;
      script.defer = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error('The Google Maps JavaScript API could not load.'));
      document.head.appendChild(script);
      this.promiseState.isFulfilled = true;
    });

    return this.scriptLoadingPromise;
  }
}
