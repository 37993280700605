<div class="basic-card-style basic-font-style scrollbar">
  <div class="grid-title-close-btn">
    <div class="card-title">{{ 'Panel.DeviceInformation' | translate }}</div>
    <mat-icon onKeyPress onKeyDown onKeyUp class="card-close-btn" (click)="onClose()">close</mat-icon>
  </div>

  <div style="margin-bottom: 24px">
    <div class="card-subtitle">{{ panelTitle() }} (MAC: {{ panel && panel?.mac ? panel.mac : '' }})</div>

    <div
      *ngIf="panelErrorStatusService.panelErrorsPresent(vitals)"
      style="display: grid; grid-template-columns: 35px 35px 35px 35px 35px; margin-left: 24px"
    >
      <div *ngIf="!panel.isCommunicator && vitals.batteryStatus === 'low'">
        <img
          [tooltipDeitres]="'Device.Errors.BatteryLow' | translate"
          [tooltipCustomLeft]="-6"
          [tooltipCustomTop]="-160"
          width="20px"
          src="assets/icons/failures/BatteryLow.svg"
          alt
        />
      </div>
      <div *ngIf="!panel.isCommunicator && vitals.batteryStatus === 'disconnected'">
        <img
          width="20px"
          src="assets/icons/failures/BatteryDisconnected.svg"
          alt
          [tooltipDeitres]="'Device.Errors.BatteryDisconnected' | translate"
          [tooltipCustomLeft]="-6"
          [tooltipCustomTop]="-160"
        />
      </div>
      <div *ngIf="!panel.isCommunicator && vitals.batteryStatus === 'failed'">
        <img
          width="20px"
          src="assets/icons/failures/BatteryFailed.svg"
          alt
          [tooltipDeitres]="'Device.Errors.BatteryFailed' | translate"
          [tooltipCustomLeft]="-6"
          [tooltipCustomTop]="-160"
        />
      </div>
      <div *ngIf="vitals.tampered">
        <img
          width="20px"
          src="assets/icons/failures/Tampered.svg"
          alt
          [tooltipDeitres]="'Device.Errors.Tampered' | translate"
          [tooltipCustomLeft]="-6"
          [tooltipCustomTop]="-160"
        />
      </div>
      <div *ngIf="panelErrorStatusService.checkEnergyStatus(vitals)">
        <img
          width="20px"
          src="assets/icons/failures/EnergyFailed.svg"
          alt
          [tooltipDeitres]="'Device.Errors.EnergyFailed' | translate"
          [tooltipCustomLeft]="-6"
          [tooltipCustomTop]="-160"
        />
      </div>
    </div>

    <div *ngIf="!panelErrorStatusService.panelErrorsPresent(vitals) && !panel?.isRepeating">
      <div class="body-font-style">{{ 'AppComponent.NoErrors' | translate }}</div>
    </div>
  </div>

  <div style="margin-bottom: 24px">
    <div class="content-title">{{ 'AppComponent.Partitions' | translate }}</div>

    <div *ngIf="partitions != undefined && partitions.length > 0">
      <app-partitions-errors-details-summary [partitions]="partitions"></app-partitions-errors-details-summary>
    </div>

    <div *ngIf="partitions == undefined || partitions.length === 0">
      <div style="font-size: 14px">{{ 'Partition.NoPartitions' | translate }}</div>
    </div>
  </div>

  <div onKeyPress onKeyDown onKeyUp class="modal-btn" (click)="onClose()">
    {{ 'Shared.Close' | translate }}
  </div>
</div>
