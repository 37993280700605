<div class="modal-container scrollbar">
    <img src="assets/img/Illustration.png" alt class="modal-image">

    <div class="modal-text">
        <p class="title" [innerHTML]="title"></p>
        <p class="subtitle">{{ 'Shared.BetaWarning_description' | translate }}</p>
        <div class="text-line">
          <img src="assets/icons/betaWarning/ad_group.svg" alt class="feature_icon">
          <p>{{ 'Shared.BetaWarning_feature_1' | translate }}</p>
        </div>
        <div class="text-line">
          <img src="assets/icons/betaWarning/groups.svg" alt class="feature_icon">
          <p>{{ 'Shared.BetaWarning_feature_2' | translate }}</p>
        </div>
        <div class="text-line">
          <img src="assets/icons/betaWarning/support.svg" alt class="feature_icon">
          <p>{{ 'Shared.BetaWarning_feature_3' | translate }}</p>
        </div>
    </div>

    <button 
        class="modal-button"
        mat-button [mat-dialog-close]="true"
        (click)="onClose('gotIt')"
    >
        {{ 'Shared.BetaWarning_confirm' | translate }}
    </button>
    <button class="close-button" (click)="onClose('close')">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
</div>

