<div
  class="tooltip"
  [class.tooltip-small]="tooltipSize === 'small'"
  [class.tooltip-medium]="tooltipSize === 'medium'"
  [class.tooltip-large]="tooltipSize === 'large'"
  [class.tooltip-xlarge]="tooltipSize === 'xlarge'"
  [class.tooltip-fit]="tooltipSize === 'fit'"
  [style.left.px]="left"
  [style.top.px]="top"
  *ngIf="!tooltipMeshStatus"
>
  {{ tooltip }}
</div>
<div
  class="tooltip mesh-status"
  [class.tooltip-small]="tooltipSize === 'small'"
  [class.tooltip-medium]="tooltipSize === 'medium'"
  [class.tooltip-large]="tooltipSize === 'large'"
  [class.tooltip-xlarge]="tooltipSize === 'xlarge'"
  [class.tooltip-fit]="tooltipSize === 'fit'"
  [style.left.px]="left"
  [style.top.px]="top"
  *ngIf="tooltipMeshStatus"
>
  <div class="color-blocks">
    <div class="optimum"></div>
    <div class="good"></div>
    <div class="low"></div>
    <div class="null"></div>
    <div class="disconnected"></div>
  </div>
  <div>
    {{ tooltip }}
  </div>
</div>
