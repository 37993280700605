import { Injectable, EventEmitter } from '@angular/core';
import { BreadcrumbedReceptor, EventSignal, LeftSideMenuNavigation, PanelSignal } from '../models/event.model';

// Servicio creado para comunicar desde panel-list a signal-strength información en los casos en que el método filterAndSendSignalReport(param) detecte un mensaje de reporte de señal de algún equipo de la lista
@Injectable()
export class EventService {
  signalUpdatedEvent = new EventEmitter<EventSignal>();
  selectionUpdatedEvent = new EventEmitter<PanelSignal>();
  menuBarNavigation = new EventEmitter<LeftSideMenuNavigation>();
  breadcrumbedReceptor = new EventEmitter<BreadcrumbedReceptor>();
}
