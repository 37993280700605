<div class="container" [ngClass]="loading ? 'container spinner_overlay' : 'container'">

  <!-- Language -->
  <div *ngIf="keyboardService.keyboardType === constants.keyboardTypes.Paradox" class="language-selector">
    <mat-form-field class="toolbar-select">
      <mat-select style="color: white;" [(value)]="this.keyboardService.lang" class="toolbarSelectBackground">
          <mat-option value="en"> {{"AppComponent.LanguageEnglish" | translate}} </mat-option>
          <mat-option value="es"> {{"AppComponent.LanguageSpanish" | translate}} </mat-option>
          <mat-option value="pt"> {{"AppComponent.LanguagePortuguese" | translate}} </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-divider></mat-divider>
  </div>

  <div class="sound-volume">
    <mat-card>
      <mat-card-header>
        <mat-card-title style="margin: 0%;">
          <div (mouseenter)="showVolumeSlider = true" (mouseleave)="showVolumeSlider = false">
            <mat-slider *ngIf="showVolumeSlider"
              [value]="getVolume()"
              step="1"
              min="0"
              max="100"
              (change)="setVolume($event, true)"
              [vertical]="true"
              aria-label="units"
              >
            </mat-slider>
            <button mat-icon-button aria-label="Silenciar" (click)="invertVolume()">
              <mat-icon style="padding: 0%;"> {{ !soundState ? 'volume_off_icon' : (volumeValue === 0 ? 'volume_mute_icon' : 'volume_up_icon') }} </mat-icon>
            </button>
          </div>
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </div>

  <div class="send-bar">
    <mat-card>
      <div class="send-controller">
        <div class="send-inputLine">
          <mat-form-field  class="content-label" appearance="fill" style="width: 350px;">
            <input matInput [type]="getType()" (keyup.enter)="translateAndSend(keyboardService.keysToSend)" (input)="eventWriteKey($event)" [ngModel]="keyboardService.keysToSend" placeholder= '{{"Keyboard.ContentSend" | translate}}'>
          </mat-form-field>
          <mat-select class="content-label" [(ngModel)]="partitionNumber" (selectionChange)="changePartition()" style="width: 110px; padding-left: 10px;">
            <mat-option *ngFor="let i of keyboardService.partitions" [value]="i.toString()" selected="i === 1">{{('Keyboard.Partition' | translate) + ' ' + i}} </mat-option>
          </mat-select>
          <button mat-icon-button (click)="deleteLast()" aria-label="Eliminar último caracter" title="{{'Keyboard.DeleteLastChar' | translate}}">
            <mat-icon>arrow_back</mat-icon>
          </button>
          <button mat-icon-button (click)="deleteAll()" aria-label="Eliminar todos caracteres introducidos" title="{{'Keyboard.DeleteAllChar' | translate}}">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-icon-button (click)="turnHide()" aria-label="Ocultar caracteres" title="{{'Keyboard.HideChar' | translate}}">
            <mat-icon>remove_red_eye</mat-icon>
          </button>
          <button mat-raised-button color="primary" (click)="translateAndSend(keyboardService.keysToSend)" [disabled]="this.blockSend || this.keyboardService.keysToSend.length === 0 || this.partitionNumber.length === 0" style="display: flex;">

            <div *ngIf="blockSend; else processCommand">
              <mat-icon><mat-spinner color="primary" diameter="30">
              </mat-spinner></mat-icon>
            </div>
            <ng-template #processCommand>
              {{'Keyboard.Send' | translate}}
            </ng-template>

          </button>
        </div>
      </div>
    </mat-card>
  </div>
  <div class="full-keyboard">
    <!-- Paradox -->
    <div *ngIf="keyboardService.keyboardType === constants.keyboardTypes.Paradox" class="paradox-keyboard" [ngStyle]="{'width': width, 'height': height}">
      <div style="transform-origin: top left;" [ngStyle]="{'transform': 'scale(' + scale + ')'}">
        <app-paradox [zoneLedStatus]="this.zoneLedStatus" [stateLedStatus]="this.stateLedStatus"></app-paradox>
      </div>
    </div>
    <!-- DSC -->
    <div *ngIf="keyboardService.keyboardType === constants.keyboardTypes.DSC" class="dsc-keyboard" [ngStyle]="{'width': width, 'height': height}">
      <div style="transform-origin: top left;" [ngStyle]="{'transform': 'scale(' + scale + ')'}">
        <app-dsc [zoneLedStatus]="this.zoneLedStatus" [stateLedStatus]="this.stateLedStatus"></app-dsc>
      </div>
    </div>

    <div class="chrono-part">
      <p style="font-size: large; font-weight: bold;">
        {{'Keyboard.PanelDate' | translate}}
      </p>
        <mat-card class="centeredText">
          <div *ngIf="!dateUnsetted">
            <span style="font-size: xx-large;"> {{ timeDisplay }} </span>
            <span style="font-size: x-large;"> {{ dateDisplay }} </span>
          </div>

          <div *ngIf="dateUnsetted" style="font-size: large; font-weight: bold;">
            {{'Keyboard.UnconfTime' | translate}}
          </div>
        </mat-card>
      <p></p>
      <img [src]="stateDisplay" style="width: 100px;" alt="logo">
    </div>
  </div>
</div>


<!-- Spinner y logo de carga, visible mientras se espera un reporte de teclado tras enviar un mensaje al mismo -->
<div *ngIf="loading">
  <img class="loading" src="../../../favicon.ico" style="height: 40px;" alt="loading">
  <mat-spinner class="loading" [diameter]="75"></mat-spinner>
</div>
