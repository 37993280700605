<div
  onKeyPress
  onKeyDown
  onKeyUp
  matTooltip="{{ 'AppComponent.Monitoring' | translate }}"
  *ngIf="launchMonitoringStatusAtStartup && !receiversService.monitoringStateUp"
  class="grid monitoring-warning"
  (click)="onReceiverStatusClick()"
>
  <mat-icon class="monitoring-icon">contactless</mat-icon>
  <span class="monitoring-text">
    {{ 'AppComponent.Monitoring' | translate }}
  </span>
</div>
