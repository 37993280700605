import { Component, Inject } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { lastValueFrom } from 'rxjs';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ConfirmActionDialogComponent } from 'src/app/shared/components/confirm-action-dialog/confirm-action-dialog.component';
import { PanelService } from '../../services/panel.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Panel } from '../../models/panel.model';
import { TranslateService } from '@ngx-translate/core';
import { Partition } from 'src/app/partitions/models/partition';

export interface DialogData {
  mac: string;
  partitions: Partition[];
}

export class UserPassword {
  userCode: string;
  type: string;
}

export class Response {
  success: boolean;
  data: UserPassword[];
}

@Component({
  selector: 'app-panel-password',
  templateUrl: './panel-password.component.html',
  styleUrls: ['./panel-password.component.scss']
})
export class PanelPasswordComponent {
  isLoadingResults = false;
  snackBarErrorDuration = 10000;
  numericPattern = /\d/ig;
  selectedAccount = "0";

  allAccountUsers = {};
  partitions = [];
  currentAccountUsers = [];
  newUser = {
    userID: '',
    type: '',
    password: '',
    isLoading: false
  };

  isLoading = true;
  panel: Panel = new Panel();

  userCodeFormControl = new FormControl('', [
    Validators.required,
    Validators.max(49),
    Validators.min(1),
  ]);
  passCodeFormControl = new FormControl('', [
    Validators.required,
    Validators.maxLength(4),
    Validators.minLength(4),
    Validators.pattern(this.numericPattern),
  ]);
  passwordErrorMatcher = new PasswordErrorMatcher();

  constructor(
    private readonly ps: PanelService,
    private readonly snackBar: MatSnackBar,
    private readonly dialogRef: MatDialogRef<PanelPasswordComponent>,
    private readonly loadingBar: LoadingBarService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    private readonly authService: AuthService,
    private readonly translateService: TranslateService
  ) {
    dialogRef.disableClose = true;

    dialogRef.backdropClick().subscribe(() => {
      this.onCancelClick();
    });

    this.loadingBar.useRef().start();

    this.getPanelAccounts();
  }

  getPanelAccounts(): void {
    for (let partition of this.data.partitions) {
      this.partitions.push({
        number: partition.partitionNumber,
        account: partition.account
      });
    }
  }

  selectAccount(): void {
    // Metodo para alternar entre una cuenta y otra de la misma mac
    this.getAccountPasswords(this.selectedAccount);
  }

  openConfirmDialog(promptText: string, submitText: string, callback: Function): void {
    const dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      maxWidth: '450px',
      autoFocus: false,
      data: { promptText, submitText }
    });

    dialogRef.afterClosed().subscribe(result => callback(result));
  }

  onCancelClick = async () => {
    this.openConfirmDialog(await lastValueFrom(this.translateService.get('Panel.ExitForm')), await lastValueFrom(this.translateService.get('Shared.Leave')), (result: boolean) => {
      if (result) {
        this.dialogRef.close({ status: 'cancel' });
      }
    });
  }

  invalidSetButton = (item) => {
    // Metodo para habilitar/deshabilitar el boton de configuración
    if (item.isLoading || !item.userID || parseInt(item.userID) > 49 || parseInt(item.userID) < 1 || !item.type || 
      !item.password || item.password.length !== 4 || !item.password.match(this.numericPattern)) {
        return true;
    }
    return false;
  }

  getAccountPasswords = async (accountNumber) => {
    // Meotodo para filtrar o solicitar las macs correspondientes a una cuenta especifica
    if (this.allAccountUsers[accountNumber]) {
      // Si ya se habian consultado se devuelven
      this.currentAccountUsers = this.allAccountUsers[accountNumber];
    } else {
      // Sino, se solicita al servicio la consulta directa al equipo
      this.isLoadingResults = true;
      this.ps.getKeyboardPasswords(this.data.mac, accountNumber).subscribe({
        next: (res: Response) => {
          this.isLoadingResults = false;
          if (res.success) {
            this.allAccountUsers[accountNumber] = [];
            for (let item of res.data) {
              this.allAccountUsers[accountNumber].push({
                userID: `00${item.userCode}`.slice(-3),
                type: item.type + '',
                password: '',
                isLoading: false,
                passCodeFormControl: new FormControl('', [
                  Validators.required,
                  Validators.maxLength(4),
                  Validators.minLength(4),
                  Validators.pattern(this.numericPattern),
                ]),
                passwordErrorMatcher: new PasswordErrorMatcher()
              });
            }
            this.currentAccountUsers = this.allAccountUsers[accountNumber];
          }
          this.isLoadingResults = false;
        },
        error: (error) => {
          this.isLoadingResults = false;

          if (error && error.status == 401) {
            this.showSnackBar('Shared.SessionExpired');
            this.authService.logout();
          } else {
            this.showSnackBar('Panel.ErrorOperation');
          }
        }
      }); 
    }
  }

  sendCommand = async (item, accountNumber) => {
    // Metodo para configurar una contraseña de teclado
    if (accountNumber !== undefined && accountNumber !== '' ) {
      item.isLoading = true;
      this.ps.setKeyboardPassword(
        this.data.mac, 
        this.selectedAccount,
        item.password,
        item.type,
        item.userID
      ).subscribe({
        next: (res: any) => {
          item.isLoading = false;
          if (res.success) {
            const userID = `00${item.userID}`.slice(-3);
            const type = item.type + '';
            if (!this.allAccountUsers[accountNumber].find(p => p.type === type && p.userID === userID)) {
              this.allAccountUsers[accountNumber].push({
                userID,
                type,
                password: '',
                isLoading: false,
                passCodeFormControl: new FormControl('', [
                  Validators.required,
                  Validators.maxLength(4),
                  Validators.minLength(4),
                  Validators.pattern(this.numericPattern),
                ])
              });
            }
            this.showSnackBar('Panel.SuccessConfig');
          } else {
            this.showSnackBar(res.reason);
          }
        },
        error: (error) => {
          item.isLoading = false;
          if (error && error.status == 401) {
            this.showSnackBar('Shared.SessionExpired');
            this.authService.logout();
          } else {
            this.showSnackBar('Panel.ErrorOperation');
          }
        }
      }); 
    } else {
      this.showSnackBar('Panel.UnselectedAccount');
    }
  }

  onDeletePassword = async (item, accountNumber) => {
    // Metodo para eliminar una contraseña
    item.isLoading = true;
    this.ps.removeKeyboardPassword(
      this.data.mac, 
      this.selectedAccount,
      item.password,
      item.type,
      item.userID
    ).subscribe({
      next: (res: any) => {
        item.isLoading = false;
        if (res.success) {
          let index = this.allAccountUsers[accountNumber].indexOf(item);
          this.allAccountUsers[accountNumber].splice(index,1);
          this.showSnackBar('Panel.SuccessDelete');
        } else {
          this.showSnackBar(res.reason);
        }
      },
      error: (error) => {
        item.isLoading = false;

        if (error && error.status == 401) {
          this.showSnackBar('Shared.SessionExpired');
          this.authService.logout();
        } else {
          this.showSnackBar('Panel.ErrorOperation');
        }
      }
    }); 
  }

  showSnackBar = async (message) => {
    this.snackBar.open(await lastValueFrom(this.translateService.get(message)), await lastValueFrom(this.translateService.get('Shared.Close')), { duration: 5000 });
  }
}

export class PasswordErrorMatcher implements ErrorStateMatcher {
  // Control de errores utilizado para Codigo de usuario y Contraseña
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control.touched || form.submitted) && ((control.invalid) || (control.errors !== undefined && control.errors !== null));
  }
}
