import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Panel } from 'src/app/panel/models/panel.model';
import { ComandService } from '../services/comand.service';
import { PanelsService } from 'src/app/shared/services/panels.service';
import { ReceiversService } from 'src/app/shared/services/receivers.service';

export interface PanelData {
  panel: Panel;
  selected: boolean;
}

@Component({
  selector: 'app-mac-list',
  templateUrl: './mac-list.component.html',
  styleUrls: ['./mac-list.component.scss']
})

export class MacListComponent implements OnInit {
  hideMacsAvailable: boolean = true;
  filter: string = '';

  constructor(
    public authService: AuthService,
    public commandsService: ComandService,
    private readonly panelsService: PanelsService,
    private readonly receiverService: ReceiversService,
  ) { }

  ngOnInit() {
    if (this.panelsService.panels.length === 0) {
      // Load panels when get receiver list
      this.filter = '';
    } else {
      this.panelsService.panels = this.panelsService.panels.map(panel => {
        panel.selectedToSupport = !!this.commandsService.selectedPanels.find(p => p.mac === panel.mac);
        return panel;
      });
      this.commandsService.matchPanels(this.panelsService.panels);
      this.panelsService.collectPanels();
    }
  }

  onSelectMac = (mac: string) => {
    this.commandsService.selectedMac = mac;
  }

  swichHideMacs = (hide: boolean) => {
    this.hideMacsAvailable = hide;
  }

  getPanels = () => {
    this.filter = '';
    this.panelsService.getPanels();
  }

  getFilteredList = (list: Panel[], getfiltered = false) => {
    if (!list) return [];
    if (getfiltered) {
      if (this.filter) {
        list = list.filter(p => p.mac && this.panelsService.panelEnableOnFilter(p, this.receiverService.selectedReceiver) && (
          p.mac.trim().toLowerCase().indexOf(this.filter.trim().toLowerCase()) !== -1 ||
          p.receiverName && p.receiverName.trim().toLowerCase().indexOf(this.filter.trim().toLowerCase()) !== -1 ||
          p.apiKey && p.apiKey.trim().toLowerCase().indexOf(this.filter.trim().toLowerCase()) !== -1)
        );
      } else {
        list = list.filter(p => p.mac && this.panelsService.panelEnableOnFilter(p, this.receiverService.selectedReceiver));
      }
    }
    return list.sort((a, b) => {
      if (a.mac >= b.mac) return 1;
      return -1;
    });
  }

}
