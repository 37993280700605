import { Component, Inject, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ConfirmActionDialogComponent } from 'src/app/shared/components/confirm-action-dialog/confirm-action-dialog.component';
import { Panel } from '../../models/panel.model';
import { PanelService } from '../../services/panel.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DatabusConfigurationComponent } from '../databus-configuration/databus-configuration.component';
import constants from 'src/app/shared/constants';
import { PartitionsService } from 'src/app/shared/services/partitions.service';

export interface VersionData {
  firmwareType: string,
  panelType: string,
}

export interface DialogData {
  panel: Panel;
  partitions: number;
}

export interface DialogDataPanelConfig {
  panel: Panel;
  panelModel: number;
}

export interface Values {
  panelModel: string;
  timeSiren: number;
  zoneDelayTime: number;
  audibleDelayZone: boolean;
  dissarmPass: string;
  coercionPass: string;
  accountDissarm: string;
  accountCoercion: string;
}

export interface Flags {
  panelModel: boolean;
  timeSiren: boolean;
  zoneDelayTime: boolean;
  audibleDelayZone: boolean;
  dissarmPass: boolean;
  coercionPass: boolean;
  accountDissarm: boolean;
  accountCoercion: boolean;
}

@Component({
  selector: 'app-config-panel',
  templateUrl: './config-panel.component.html',
  styleUrls: ['./config-panel.component.scss']
})
export class ConfigPanelComponent implements OnInit {
  snackBarDuration = 5000;
  snackBarErrorDuration = 10000;
  defaultTimeout = 70000;

  panel: Panel = new Panel();
  isDataBus: boolean;
  installed: boolean;

  pdfUrl = 'assets/docs/Citymesh -Integration via Data Bus of Paradox® Keypad v2.0-ES.pdf';

  timeSirenErrorStatMatcher: RangeErrorMatcher;
  private readonly maxSirenTime = 20;
  private readonly minSirenTime = 1;

  zoneDelayTimeErrorStatMatcher: RangeErrorMatcher;
  private readonly maxZoneTime = 120;
  private readonly minZoneTime = 5;

  dissarmPassErrorStatMatcher: RangeErrorMatcher;
  coercionPassErrorStatMatcher: RangeErrorMatcher;
  accountDissarmErrorStatMatcher: RangeErrorMatcher;
  accountCoercionErrorStatMatcher: RangeErrorMatcher;

  errorStateMatchers: {
    timeSiren: any,
    zoneDelayTime: any,
    dissarmPass: any,
    coercionPass: any,
    accountDissarm: any,
    accountCoercion: any,
  };

  private readonly names = {
    timeSiren: 'Panel.SirenTime',
    zoneDelayTime: 'Panel.ZoneDelayTime',
    audibleDelayZone: 'Panel.DelayZoneSound',
    dissarmPass: 'Panel.Password',
    coercionPass: 'Panel.DuressPassword',
  };

  values = { audibleDelayZone: false } as Values;
  currentValues = { audibleDelayZone: false } as Values;

  isConfig = {} as Flags;
  isGet = {} as Flags;
  isSet = {} as Flags;

  private currentTimeout: any;
  private currentRequest: any;

  constructor(
    private readonly dialogRef: MatDialogRef<ConfigPanelComponent>,
    private readonly loadingBar: LoadingBarService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    private readonly authService: AuthService,
    private readonly panelService: PanelService,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
    private readonly partitionsDataService: PartitionsService,
  ) {}
  
  ngOnInit() {
    this.timeSirenErrorStatMatcher = new RangeErrorMatcher();
    this.zoneDelayTimeErrorStatMatcher = new RangeErrorMatcher();
    this.dissarmPassErrorStatMatcher = new RangeErrorMatcher();
    this.coercionPassErrorStatMatcher = new RangeErrorMatcher();
    this.accountDissarmErrorStatMatcher = new RangeErrorMatcher();
    this.accountCoercionErrorStatMatcher = new RangeErrorMatcher();

    this.errorStateMatchers = {
      timeSiren: this.timeSirenErrorStatMatcher,
      zoneDelayTime: this.zoneDelayTimeErrorStatMatcher,
      dissarmPass: this.dissarmPassErrorStatMatcher,
      coercionPass: this.coercionPassErrorStatMatcher,
      accountDissarm: this.accountDissarmErrorStatMatcher,
      accountCoercion: this.accountCoercionErrorStatMatcher,
    };

    this.dialogRef.disableClose = true;

    this.dialogRef.backdropClick().subscribe(() => {
      this.onCancelClick();
    });

    this.loadingBar.useRef().start();


    // Check Databus firmware (Unknown is treated as data bus preventively)
    this.isDataBus = !this.data.panel.version.firmwareType || this.data.panel.version.firmwareType === constants.firmwareType.dataBus;
    if (this.isDataBus) {
      // Check Panel already instalEd
      switch (this.data.panel.version.panelType) {
        case 'Intelbras 4010':
          this.installed = true;
          this.values.panelModel = "1";
          break;
        case 'JFL':
          this.installed = true;
          this.values.panelModel = "2";
          break;
        case 'Intelbras 1000':
          this.installed = true;
          this.values.panelModel = "3";
          break;
        case 'Intelbras 2018':
          this.installed = true;
          this.values.panelModel = "4";
          break;
        case 'DSC':
          this.installed = true;
          this.values.panelModel = "5";
          break;
        case 'Paradox':
          this.installed = true;
          this.values.panelModel = "6";
          break;
        case 'Panel unconnected':
        default:
          this.values.panelModel = "0";
          break;
      }
    }
  }

  openConfirmDialog(promptText: string, submitText: string, callback: (result: any) => any): void {
    const dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      maxWidth: '450px',
      autoFocus: false,
      data: { promptText, submitText }
    });

    dialogRef.afterClosed().subscribe(result => callback(result));
  }

  onCancelClick = async () => {
    if (this.currentTimeout !== undefined) {
      this.openConfirmDialog(
        await lastValueFrom(this.translateService.get('Panel.UnfinishOp')),
        await lastValueFrom(this.translateService.get('Shared.Leave')), 
        (result: boolean) => {
          if (result) {
            clearTimeout(this.currentTimeout);
            this.currentRequest.unsubscribe();
            this.dialogRef.close({ status: 'cancel' });
          }
      });
    } else {
      this.dialogRef.close({ status: 'cancel' });
    }
  }

  validGetButton(type: string): boolean {
    return (this.isGet[type] || (this.isGet.audibleDelayZone || this.isGet.timeSiren || this.isGet.zoneDelayTime
        || this.isGet.dissarmPass || this.isGet.coercionPass)) ||
      (this.isSet[type] || (this.isSet.audibleDelayZone || this.isSet.timeSiren || this.isSet.zoneDelayTime
        || this.isSet.dissarmPass || this.isSet.coercionPass));
  }

  validSetButton(type: string): boolean {
    return this.isConfiguration(type) &&
      (this.isGet.timeSiren || this.isSet.timeSiren ||
      this.isGet.audibleDelayZone || this.isSet.audibleDelayZone ||
      this.isGet.zoneDelayTime || this.isSet.zoneDelayTime ||
      this.isGet.dissarmPass || this.isSet.dissarmPass ||
      this.isGet.coercionPass || this.isSet.coercionPass || this.values[type] === undefined ||
      (type === 'dissarmPass' && this.values.accountDissarm == undefined) ||
      (type === 'coercionPass' && this.values.accountCoercion == undefined) ||
      (this.isConfig[type] &&
      (
        // Se verifica que, para un boton especifico, su campo contenga un valor correcto
        // y en caso de que sea Configuracion de Desarme o Caccion se controla que el cambo de cuenta tenga un valor correcto
        this.values[type] === undefined ||
        (this.errorStateMatchers[type] !== undefined ? this.errorStateMatchers[type].getExists() : false)
        || (
          ((type === 'dissarmPass') &&
          (this.errorStateMatchers.accountDissarm !== undefined ? this.errorStateMatchers.accountDissarm.getExists() : false))
          || ((type === 'coercionPass') &&
          (this.errorStateMatchers.accountCoercion !== undefined ? this.errorStateMatchers.accountCoercion.getExists() : false))
        ))));
  }

  validSirenTimeRange(): void {
    if (this.values.timeSiren > this.maxSirenTime || this.values.timeSiren < this.minSirenTime) {
      this.timeSirenErrorStatMatcher.setExists(true);
    } else {
      this.timeSirenErrorStatMatcher.setExists(false);
    }
  }

  validZoneTimeRange(): void {
    if (this.values.zoneDelayTime > this.maxZoneTime || this.values.zoneDelayTime < this.minZoneTime) {
      this.zoneDelayTimeErrorStatMatcher.setExists(true);
    } else {
      this.zoneDelayTimeErrorStatMatcher.setExists(false);
    }
  }

  validPasswords(type): void {
    // Validar Claves de Teclado Desarmado y desarmado por coacción
    const pattern = /^\d+$/;
    if (this.values[type] !== undefined &&
      (!pattern.test(this.values[type]) || this.values[type].length < 4 || this.values[type].length > 4 )) {
      this.errorStateMatchers[type].setExists(true);
    } else {
      this.errorStateMatchers[type].setExists(false);
    }
  }

  validAccount(type): void {
    const pattern = /^[a-fA-F0-9]*$/;
    if (this.values[type] !== undefined &&
      (!pattern.test(this.values[type]) || this.values[type].length < 4 || this.values[type].length > 4)) {
      this.errorStateMatchers[type].setExists(true);
    } else {
      this.errorStateMatchers[type].setExists(false);
    }
  }

  inputValidatorNumeric(event: any, type: string) {
    const pattern = /^\d+$/;
    const replacePattern = /\D/g;
    this.validateInput(event, pattern, replacePattern, type);
  }

  inputValidatorHexa(event: any, type: string) {
    const pattern = /^[a-fA-F0-9]*$/;
    const replacePattern = /[^a-fA-F0-9]/g;
    this.validateInput(event, pattern, replacePattern, type);
  }

  validateInput(event: any, pattern: any, replacePattern: any, type: string) {
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(replacePattern, '');
      this.values[type] = event.target.value;
    }
    event.target.value =  event.target.value.toUpperCase();
    this.values[type] =  event.target.value;
  }

  clickConfig(type: string): void {
    if (this.isGet[type] || this.isSet[type]) {
      clearTimeout(this.currentTimeout);
      this.currentRequest.unsubscribe();
      this.isGet[type] = false;
      this.isSet[type] = false;
    } else {
      this.isConfig[type] = !this.isConfig[type];
      this.values[type] = this.currentValues[type];

      if (!this.isConfig[type]) {
        this.validSirenTimeRange();
        this.validZoneTimeRange();
        this.validPasswords('dissarmPass');
        this.validPasswords('coercionPass');
        this.validAccount('accountDissarm');
        this.validAccount('accountCoercion');
      }
    }
  }

  isConfiguration(type: string) {
    return !this.isSet[type] && !this.isGet[type];
  }

  databusHelpUrl() {
    window.open(this.authService.repositoryConfig.databusGuideURL, '_blank', 'noopener');
  }

  onConfigPanel() {
    if (this.installed) {
      // Uninstall panel
      const callback = (res, params) => {
        if (res.success) {
          // reenable panel instalation
          this.values.panelModel = '0';
          this.installed = false;

          this.data.panel.version.panelType = undefined;
          this.partitionsDataService.setKeyboard(this.data.panel.mac, false);
        } 
      }
      const params = [
        this.data.panel._id,
        0
      ]
      this.panelService.httpRequest(
        this.panelService.panelModel,
        params, 
        callback, 
        {},
        'Panel.FailedConfDev'
      );
    } else {
      // Show modal and start Configuration
      const dialogRefA = this.dialog.open(DatabusConfigurationComponent, {
        maxWidth: '550px',
        autoFocus: false,
        data: { panel: this.data.panel, panelModel: parseInt(this.values.panelModel) }
      });

      dialogRefA.afterClosed().subscribe(async result => {
        if (result?.status !== 'cancel') {
          this.installed = result.installed;
        }
      });
    }
  }

  sendCommand(type: string, isConfig: boolean): void {
    if (type === 'dissarmPass') { this.values.accountDissarm = this.values.accountDissarm.toUpperCase(); }
    if (type === 'coercionPass') { this.values.accountCoercion = this.values.accountCoercion.toUpperCase(); }
    this.isGet[type] = !isConfig;
    this.isSet[type] = isConfig;
    this.currentTimeout =  setTimeout(async () => {
      this.isGet[type] = false;
      this.isSet[type] = false;
      this.currentRequest.unsubscribe();
      
      this.snackBar.open(
        await lastValueFrom(this.translateService.get('Shared.Timeout')),
        await lastValueFrom(this.translateService.get('Shared.Leave')), 
        { duration: this.snackBarErrorDuration }
      );
    }, this.defaultTimeout);

    // Si es tipo es de Cambio de contraseña de Desarme/Coaccion envio tanto Contraseña como Account en lugar de un solo parametro
    let values = this.values[type];
    switch (type) {
      case 'dissarmPass':
        values = { dissarmPass: this.values.dissarmPass || undefined, account: this.values.accountDissarm };
        break;
      case 'coercionPass':
        values = { coercionPass: this.values.coercionPass || undefined, account: this.values.accountCoercion };
        break;
        default:
        break;
    }

    this.currentRequest = this.panelService[type](this.data.panel._id,
      (isConfig || type === 'dissarmPass' || type === 'coercionPass') ? values : undefined).subscribe({
      next: async (res: any) => {
        if (res.success) {
          if (type === 'audibleDelayZone') {
            this.values[type] = typeof res.data === 'boolean' ? res.data : res.data === 'true';
          } else {
            this.values[type] = res.data;
          }
          
          this.currentValues[type] = this.values[type];
          const message = this.isConfig[type] ? `${await lastValueFrom(this.translateService.get(this.names[type]))} ${await lastValueFrom(this.translateService.get('Panel.CorretlyConf'))}` : `${this.names[type]} ${await lastValueFrom(this.translateService.get('Panel.CorretlyObt'))}`;

          this.snackBar.open(message, await lastValueFrom(this.translateService.get('Shared.Close')), { duration: this.snackBarDuration });
        } else {
          this.snackBar.open(res.reason, await lastValueFrom(this.translateService.get('Shared.Close')), { duration: this.snackBarErrorDuration });
        }
        clearTimeout(this.currentTimeout);
        delete this.currentTimeout;
        this.isGet[type] = false;
        this.isSet[type] = false;
      },
      error: async (error) => {
        clearTimeout(this.currentTimeout);
        delete this.currentTimeout;
        this.isGet[type] = false;
        this.isSet[type] = false;
        if (error && error.status === 401) {
          this.snackBar.open(await lastValueFrom(this.translateService.get('Shared.SessionExpired')), await lastValueFrom(this.translateService.get('Shared.Close')), { duration: this.snackBarErrorDuration });
          this.authService.logout();
        } else {
          this.snackBar.open(await lastValueFrom(this.translateService.get('Shared.ErrorOperation')), await lastValueFrom(this.translateService.get('Shared.Close')), { duration: this.snackBarErrorDuration });
        }
      }
    });
  }
}

export class RangeErrorMatcher implements ErrorStateMatcher {
  RangeErrorExists = false;

  public getExists(): boolean {
    return this.RangeErrorExists;
  }

  public setExists(val: boolean) {
    this.RangeErrorExists = val;
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return this.RangeErrorExists || ((control.touched || form.submitted) && control.errors !== undefined && control.errors !== null);
  }
}
