<!-- RECEIVERS -->
<div class="info-title">{{ 'Settings.ManageReceivers' | translate }}</div>
<!-- At least one receiver -->
<div 
  *ngIf="companiesService.currentCompany?.receivers?.length > 0"
  class="section-container"
>
    <div *ngFor="let receiver of companiesService.currentCompany.receivers">
        <div class="expanded-info-parent">
            <div class="expanded-info-column">
                <div class="expanded-info-title">
                    <img
                        class="img"
                        [src]="receiver.logo ?? companiesService.currentCompany.logo ?? 'assets/img/citymesh-logo-user.png'"
                        alt
                    />
                </div>
            </div>
            <div class="expanded-info-column">
                <div class="expanded-info-title">
                    {{ ('Settings.ReceiverName' | translate).toUpperCase() }}
                </div>
                <div>
                    {{ receiver.name }}
                </div>
            </div>
            
            <div class="expanded-info-column">
                <div class="expanded-info-title">
                    {{ ('Settings.Apikey' | translate).toUpperCase() }}
                </div>
                <div>
                    {{ receiver.apiKey }}
                </div>
            </div>

            <div class="expanded-info-column">
                <div class="expanded-info-title">
                    {{ ('Settings.IP' | translate).toUpperCase() }}
                </div>
                <div>
                    {{ receiver.ip }}
                </div>
            </div>
            
            <div class="expanded-info-column">
                <div class="expanded-info-title">
                    {{ ('Settings.UdpPort' | translate).toUpperCase() }}
                </div>
                <div>
                    {{ receiver.udpPort }}
                </div>
            </div>

            <div class="expanded-info-column">
                <div class="expanded-info-title">
                    {{ ('Settings.SnifferPort' | translate).toUpperCase() }}
                </div>
                <div>
                    {{ receiver.snifferPort }}
                </div>
            </div>

            <div class="expanded-info-column-remove-button">
                <mat-icon 
                  svgIcon="delete_icon" 
                  class="icon button" 
                  (click)="deleteReceiver(receiver)"
                  onKeyPress
                  onKeyDown
                  onKeyUp
                ></mat-icon>
            </div>
        </div>
        <div class="separator"></div>
    </div>

    <button class="create-user-button" mat-raised-button color="primary" (click)="createReceiver()">
        <mat-icon class="icon">add</mat-icon>
        {{ 'Settings.AddReceiver' | translate }}
    </button>
</div>

<!-- No receivers -->
<diV 
  *ngIf="!companiesService.currentCompany?.receivers || companiesService.currentCompany?.receivers.length === 0"
  class="line-box"
>
    <img
        src="assets/icons/add_circle.svg"
        alt
        class="line-box-icon"
    />
    <div class="line-box-title">{{ 'Settings.AddReceiver' | translate }}</div>
    <p class="line-box-desc" [innerHTML]="receiverMessage"></p>
    <button class="create-user-button" mat-raised-button color="primary" (click)="createReceiver()">
        <mat-icon class="icon">add</mat-icon>
        {{ 'Settings.AddReceiver' | translate }}
    </button>
</diV>
<!-- USERS -->
<div class="info-title">{{ 'Settings.ManageUsers' | translate }}</div>
<!-- At least one user -->
<div 
  *ngIf="companiesService.currentCompany?.users?.length > 0"
>
    <div *ngFor="let user of companiesService.currentCompany.users">
        <div class="expanded-info-parent">
            <div class="expanded-info-column">
                <div class="expanded-info-title">
                    {{ ('Settings.Name' | translate).toUpperCase() }}
                </div>
                <div>
                    {{ user.name }}
                </div>
            </div>
            
            <div class="expanded-info-column">
                <div class="expanded-info-title">
                    {{ ('Settings.Lastname' | translate).toUpperCase() }}
                </div>
                <div>
                    {{ user.lastname }}
                </div>
            </div>

            <div class="expanded-info-column">
                <div class="expanded-info-title">
                    {{ ('Settings.Email' | translate).toUpperCase() }}
                </div>
                <div>
                    {{ user.email }}
                </div>
            </div>
            
            <div class="expanded-info-column">
                <div class="expanded-info-title">
                    {{ ('Settings.Permissions' | translate).toUpperCase() }}
                </div>
                <div 
                fxLayout="row" 
                fxLayoutGap="20px" 
                class="selector-field" 
                (click)="contextualMenuService.onContextalMenu($event, user, !user.expand, 'permission-selector-edit-' + user.email)"
                onKeyPress
                onKeyDown
                onKeyUp
                >
                    <div> {{ ('Settings.UserLevel.' + (user.permission || 'END_COMPANY')) | translate }} </div> 
                    <mat-icon [id]="'permission-selector-edit-' + user.email" class="permission-selector">keyboard_arrow_down</mat-icon>
                </div>
            </div>
            
            <div class="expanded-info-column-remove-button">
                <mat-icon 
                  svgIcon="delete_icon" 
                  class="icon button" 
                  (click)="deleteUser(user)"
                  onKeyPress
                  onKeyDown
                  onKeyUp
                ></mat-icon>
            </div>
        </div>
        <div class="separator"></div>
    </div>

    <button class="create-user-button" mat-raised-button color="primary" (click)="createUser()" [disabled]="disableCreateUser()">
        <mat-icon class="icon">add</mat-icon>
        {{ 'Settings.AddUser' | translate }}
    </button>
</div>

<!-- No users -->
<diV 
  *ngIf="!companiesService.currentCompany?.users || companiesService.currentCompany?.users.length === 0"
  class="line-box"
>
    <img
        src="assets/icons/person.svg"
        alt
        class="line-box-icon"
    />
    <div class="line-box-title">{{ 'Settings.AddUser' | translate }}</div>
    <p class="line-box-desc" [innerHTML]="userMessage"></p>
    <button class="create-user-button" mat-raised-button color="primary" (click)="createUser()" [disabled]="disableCreateUser()">
        <mat-icon class="icon">add</mat-icon>
        {{ 'Settings.AddUser' | translate }}
    </button>
</diV>