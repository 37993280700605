<mat-card class="container">
    <mat-card-header>
        <mat-card-title>
        {{'TechSupport.Macs' | translate}}
        </mat-card-title>
    </mat-card-header>
    <div class="column-container">
        <!-- Listado de MACs No Seleccionadas -->
        <div *ngIf="!hideMacsAvailable" class="selectorContainer">
            <div class="table-responsive table-wrapper-scroll-y d-flex f-85 scrollbar" style="max-height: 100%; overflow-x: hidden;">
                <table class="table table-bordered table-sm table-hover" style="height: 100%; width: 100%;">
                    <thead>
                        <tr class="d-flex" style="font-size: 14px">
                            <th scope="col" class="col-3 text-sm-center align-middle">{{'TechSupport.Available' | translate}}</th>
                            <th scope="col" class="col-1 text-sm-left align-middle icon-column">
                                <button
                                    mat-icon-button
                                    matTooltip="{{'TechSupport.SelectAll' | translate}}"
                                    matTooltipShowDelay="750"
                                    matTooltipPosition="after"
                                    (click)="commandsService.onSelectAllPanel(true)"
                                    aria-hidden="false"
                                > <mat-icon class="mat-icon-button-sm">chevron_right</mat-icon> </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody style="font-size: 14px; height: 100%;">
                        <ng-container *ngFor="let panel of getFilteredList(commandsService.unselectedPanels, true)">
                            <tr class="d-flex">
                                <td class="col-3 text-sm-center align-middle"> {{panel.mac}} </td>
                                <td class="col-1 text-sm-center align-middle icon-column">
                                    <button
                                        mat-icon-button
                                        matTooltip="{{'TechSupport.SelectMac' | translate}}"
                                        matTooltipShowDelay="750"
                                        matTooltipPosition="after"
                                        (click)="commandsService.onSelectPanel(panel.mac, true)"
                                        aria-hidden="false"
                                    > <mat-icon class="mat-icon-button-sm">chevron_right</mat-icon> </button>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- Listado de MACs Seleccionadas -->
        <div class="selectorContainer">
            <div class="table-responsive table-wrapper-scroll-y d-flex f-85 scrollbar" style="max-height: 100%; width: 100%; overflow-x: hidden;">
                <table class="table table-bordered table-sm table-hover" style="height: 100%; width: 100%;">
                    <thead>
                        <tr class="d-flex" style="font-size: 14px; width: 100%;">
                            <th scope="col" class="col-4 text-sm-center align-middle">{{'TechSupport.Selected' | translate}}</th>
                            <th scope="col" class="col-1 text-sm-left align-middle icon-column">
                                <button
                                    mat-icon-button
                                    matTooltip="{{'TechSupport.DeselectAll' | translate}}"
                                    matTooltipShowDelay="750"
                                    matTooltipPosition="after"
                                    (click)="commandsService.onSelectAllPanel(false)"
                                    aria-hidden="false"
                                > <mat-icon class="mat-icon-button-sm">delete</mat-icon> </button>
                            </th>
                            <th scope="col-1" class="col-1 text-sm-left align-middle icon-column">
                                <button
                                    mat-icon-button
                                    matTooltip="{{'TechSupport.FilterAll' | translate}}"
                                    matTooltipShowDelay="750"
                                    matTooltipPosition="after"
                                    (click)="commandsService.addAllPaneltoFilter()"
                                    aria-hidden="false"
                                > <mat-icon class="mat-icon-button-sm">filter_alt</mat-icon> </button>
                            </th>
                            <th scope="col" class="col-1 text-sm-left align-middle icon-column">
                                <mat-icon class="mat-icon-button-sm"><!-- Panel State --></mat-icon>
                            </th>
                        </tr>
                    </thead>
                    <tbody style="font-size: 14px; height: 100%;">
                        <ng-container *ngFor="let panel of getFilteredList(commandsService.selectedPanels)">
                            <tr class="d-flex" [style.background-color]="panel.mac === commandsService.selectedMac ? '#ffeeb3' : 'white'">
                              <td class="col-4 text-sm-center align-middle pointer" (click)="onSelectMac(panel.mac)"> {{panel.mac}} </td>
                              <td class="col-1 text-sm-left align-middle icon-column">
                                <button
                                    mat-icon-button
                                    matTooltip="{{'TechSupport.DeselectMac' | translate}}"
                                    matTooltipShowDelay="750"
                                    matTooltipPosition="after"
                                    (click)="commandsService.onSelectPanel(panel.mac, false)"
                                    aria-hidden="false"
                                > <mat-icon class="mat-icon-button-sm">delete</mat-icon> </button>
                              </td>
                              <td class="col-1 text-sm-left align-middle icon-column">
                                <button
                                    mat-icon-button
                                    matTooltip="{{'TechSupport.FilterMac' | translate}}"
                                    matTooltipShowDelay="750"
                                    matTooltipPosition="after"
                                    (click)="commandsService.addPaneltoFilter(panel.mac)"
                                    aria-hidden="false"
                                > <mat-icon class="mat-icon-button-sm">filter_alt</mat-icon> </button>
                              </td>
                              <td class="col-1 text-sm-left align-middle icon-column">
                                  <mat-icon class="mat-icon-button-sm {{ panel.cState }}">{{ panel.cState }}</mat-icon>
                              </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutGap="10px">
        <button
          *ngIf="!hideMacsAvailable"
          mat-icon-button
          matTooltip="{{'TechSupport.HideSelectedMac' | translate}}"
          matTooltipShowDelay="750"
          matTooltipPosition="after"
          (click)="swichHideMacs(true)"
          aria-hidden="false"
        > <mat-icon>keyboard_double_arrow_left</mat-icon> </button>
        <button
          *ngIf="hideMacsAvailable"
          mat-icon-button
          matTooltip="{{'TechSupport.ShowSelectedMac' | translate}}"
          matTooltipShowDelay="750"
          matTooltipPosition="after"
          (click)="swichHideMacs(false)"
          aria-hidden="false"
        > <mat-icon>keyboard_double_arrow_right</mat-icon> </button>
        <mat-form-field class="filterField" fxFlex>
          <input matInput [(ngModel)]="filter" placeholder="{{'TechSupport.SearchDevice' | translate}}">
        </mat-form-field>
        <button
            mat-icon-button
            matTooltip="{{'TechSupport.RefreshMacList' | translate}}"
            matTooltipShowDelay="750"
            matTooltipPosition="after"
            mat-icon-button (click)="getPanels()"
            aria-hidden="false"
        >
          <mat-icon>refresh</mat-icon>
        </button>
    </div>
</mat-card>
