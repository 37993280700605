import { Injectable } from '@angular/core';
import { Panel } from 'src/app/panel/models/panel.model';
import { IVitals } from 'src/app/panel/components/panel-errors/panel-errors.component';

@Injectable({
  providedIn: 'root',
})
export class PanelErrorStatusService {
  public states = {};
  private readonly availableBatteryStates = ['disconnected', 'low', 'failed'];

  onNewError(panelID: string) {
    this.states[panelID] = 'normal';
  }

  onClick(panelID: string) {
    this.states[panelID] = 'visited';
  }

  onClean(panelID: string) {
    this.states[panelID] = 'hidden';
  }

  errorsPresent = (element: Panel) => {
    if (!element) return true;
    return (
      element.deviceErrorsPresent ||
      // @ts-ignore
      (!element.isCommunicator &&
        this.availableBatteryStates.includes((element.vitals?.batteryStatus || 'normal').toString())) ||
      element.vitals?.energyStatus == false || // NOSONAR
      element.vitals?.tampered
    );
  };

  panelErrorsPresent(vitals: IVitals) {
    return (
      ['disconnected', 'low', 'failed'].includes((vitals.batteryStatus || 'normal').toString()) ||
      vitals.energyStatus == false || // NOSONAR
      vitals.tampered
    );
  }

  checkEnergyStatus(vitals: IVitals) {
    return vitals.energyStatus == false; // NOSONAR
  }
}
