import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { EditProfileDialogComponent } from '../components/edit-profile-dialog-component/edit-profile-dialog.component';
import { formatPhoneNumber } from '../../shared/utils/phone-mask.util';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  company: string;
  companies = [];
  formattedPhone: string;

  userCompanyValues = {
    name: '',
    lastName: '',
    email: '',
    phone: '',
    language: '',
  };

  constructor(
    private readonly authService: AuthService,
    private readonly translateService: TranslateService,
    private readonly dialog: MatDialog
  ) {
    this.companies = this.authService?.user?.companies || [];
    this.formattedPhone = this.authService.user.phone ? formatPhoneNumber(this.authService.user.phone) : undefined;
    this.initializeUserCompanyValues();
  }

  initializeUserCompanyValues(): void {
    const storedValues = JSON.parse(localStorage.getItem('userCompanyValues') || '{}');

    this.userCompanyValues = {
      name: storedValues.name || this.authService.user.name,
      lastName: storedValues.lastName || this.authService.user.lastName,
      email: storedValues.email || this.authService.user.email,
      phone: storedValues.phone || this.authService.user.phone,
      language: storedValues.language || this.authService.user.language || this.authService.selectedLanguage,
    };

    this.formattedPhone = formatPhoneNumber(this.userCompanyValues.phone);
  }

  getTranslatedLanguage(): string {
    switch (this.userCompanyValues.language) {
      case 'en':
        return this.translateService.instant('AppComponent.LanguageEnglish');
      case 'es':
        return this.translateService.instant('AppComponent.LanguageSpanish');
      case 'pt':
        return this.translateService.instant('AppComponent.LanguagePortuguese');
    }
  }

  getCompanyKey(): string {
    return this.companies.length === 1
      ? this.translateService.instant('Profile.Company')
      : this.translateService.instant('Profile.Companies');
  }

  getCompany(): string | null {
    if (!this.companies || this.companies.length === 0) {
      return null;
    }

    const names = this.companies.map((company: any) => company.name);

    if (names.length === 1) {
      this.company = this.translateService.instant('Profile.Company');
      return names[0];
    } else if (names.length === 2) {
      return `${names[0]} ${this.translateService.instant('Profile.And')} ${names[1]}`;
    } else {
      const lastName = names.pop();
      return `${names.join(', ')} ${this.translateService.instant('Profile.And')} ${lastName}`;
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(EditProfileDialogComponent, {
      width: '60%',
      height: window.innerWidth < 1367 ? '465px' : '520px',
      maxWidth: '1000px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((newValues) => {
      if (newValues) {
        localStorage.setItem('userCompanyValues', JSON.stringify(newValues));
        this.initializeUserCompanyValues();
      }
    });
  }
}
