export const environment = {
  production: true,
  env: 'Production',
  webappEndpoint: 'https://webapp.deitres.com',
  messagesProcessorEndpoint: 'https://mp.reddomo.com.ar',
  webappSocketEndpoint: 'wss://webapp.deitres.com',
  messagesProcessorToken: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImRlaXRyZXMiLCJpYXQiOjE1Njg4MTU5Mzh9.9Ehp6F6gtkjQf-hXG1zvp7iT-JQeBVDK5VPBU6IEx-k',
  webappSocketToken: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoic2VydmljZSIsImlhdCI6MTcxNzc3MTY4Mn0.oQz7fhMH-eA0Ctt4Pljd_wqIURJtO9-4uHGZCUiuQ74',
  googleMapApiKey: 'AIzaSyC7RBmSqvqJms0I2Y28Bguv-O4pLznNzUo',
};
