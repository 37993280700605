import { AfterContentInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { PanelsService } from 'src/app/shared/services/panels.service';
import { ReceiversService } from 'src/app/shared/services/receivers.service';
import { WebppSocketService } from 'src/app/shared/services/webppSocket.service';
import mapConstants from '../../constants';
import { CustomMapService } from '../../service/custom-map.service';
import { PanelStatusService } from '../../service/panel-status.service';
import { PanelMapMark } from '../../../types';

const MINIMUM_XR_DISTANCE = 30;
const MINIMUM_XL_DISTANCE = 220;
const MINIMUM_YT_DISTANCE = 30;
const MINIMUM_YB_DISTANCE = 70; // Edit if add options
@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss'],
})
export class MapViewComponent implements OnInit, OnDestroy, AfterContentInit {
  displayedColumns: string[];
  defaultReceiver = '';
  showContextMenu = true;
  contextMenuPosition = { x: 0, y: 0 };
  markerPosition;
  shouldShowMonitoring: boolean;

  constructor(
    public authService: AuthService,
    public panelStatus: PanelStatusService,
    public panelsService: PanelsService,
    private readonly panelChangesSocket: WebppSocketService,
    private readonly route: ActivatedRoute,
    private readonly receiversService: ReceiversService,
    public customMapService: CustomMapService
  ) {
    this.defaultReceiver = mapConstants.defaultReceiver;
  }

  ngAfterContentInit(): void {
    this.customMapService.perform();
  }

  ngOnInit() {
    this.panelStatus.selectedFilter = mapConstants.viewTypes.None;

    let params;
    if (this.route.snapshot.queryParams.mac) {
      params = {
        mac: this.route.snapshot.queryParams.mac,
        apiKey: this.route.snapshot.queryParams.apiKey,
      };
    }

    if (this.panelStatus.selectedPanel) {
      setTimeout(() => {
        this.customMapService.saveRestoreZoom(this.panelStatus.selectedPanel);
      }, 500);
    }

    this.panelStatus.loadFullPanelList(params);
  }

  ngOnDestroy() {
    this.customMapService.clear();

    this.panelChangesSocket.unsubscribe();
    this.panelStatus.selectedPanel = undefined;
    this.panelStatus.saveLastPanel = undefined;
  }

  initMap() {
    this.customMapService.perform();
  }

  public onSelectPanel = (panel: PanelMapMark) => {
    this.panelStatus.selectedPanel = panel;
  };

  public getState = (panel: PanelMapMark) => {
    const receiver = this.receiversService.filteredReceivers.find((r) => r.apiKey === panel.apiKey);
    return receiver?.status ? panel.state : 'null';
  };

  setLocation() {
    this.panelStatus.updatePanelLocation(this.panelStatus.selectedPanel);
    this.closeContextMenu();
  }

  onMapRightClick(event: MouseEvent): void {
    if (this.panelStatus.selectedPanel) {
      event.preventDefault();
      let mouseX = event.offsetX;
      let mouseY = event.offsetY;

      this.markerPosition = this.latLngToPixel(
        new google.maps.LatLng({
          lat: this.panelStatus.selectedPanel.lat,
          lng: this.panelStatus.selectedPanel.lng,
        })
      );
      const distance = {
        x: mouseX - this.markerPosition.x,
        y: mouseY - this.markerPosition.y,
      };

      const res = this.offset(distance);
      this.contextMenuPosition.x = mouseX + res.x;
      this.contextMenuPosition.y = mouseY + res.y;

      this.showContextMenu = true;
    }
  }

  private offset(distance) {
    if (distance.x > 0 && distance.x < MINIMUM_XR_DISTANCE) {
      const y = this.secondOffset(distance);
      if (y != 0) {
        return {
          y,
          x: MINIMUM_XR_DISTANCE - distance.x,
        };
      }
    } else if (distance.x < 0 && -1 * distance.x < MINIMUM_XL_DISTANCE) {
      const y = this.secondOffset(distance);
      if (y != 0) {
        return {
          y,
          x: -(MINIMUM_XL_DISTANCE + distance.x),
        };
      }
    }
    return {
      y: 0,
      x: 0,
    };
  }

  private secondOffset(distance) {
    if (distance.y > 0 && distance.y < MINIMUM_YT_DISTANCE) {
      return MINIMUM_YT_DISTANCE - distance.y;
    } else if (distance.y < 0 && -1 * distance.y < MINIMUM_YB_DISTANCE) {
      return -(MINIMUM_YB_DISTANCE + distance.y);
    } else {
      return 0;
    }
  }

  private latLngToPixel(latLng: google.maps.LatLng): { x: number; y: number } | null {
    const projection = this.customMapService.map.getProjection();
    if (!projection) return null;

    // Coord to pixels
    const scale = Math.pow(2, this.customMapService.map.getZoom() ?? 0);
    const bounds = this.customMapService.map.getBounds();
    if (!bounds) return null;

    const nw = projection.fromLatLngToPoint(bounds.getNorthEast() ?? 0);
    const se = projection.fromLatLngToPoint(bounds.getSouthWest() ?? 0);

    const worldPoint = projection.fromLatLngToPoint(latLng);
    const x = (worldPoint!.x - nw!.x) * scale;
    const y = (worldPoint!.y - se!.y) * scale;

    // Offset
    const mapDiv = this.customMapService.map.getDiv();
    const mapWidth = mapDiv.offsetWidth;
    const mapHeight = mapDiv.offsetHeight;

    const adjustedX = mapWidth + x;
    const adjustedY = mapHeight + y;

    return { x: adjustedX, y: adjustedY };
  }

  closeContextMenu(): void {
    this.showContextMenu = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.customMapService.onStreetView();
  }
}
