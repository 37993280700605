<div class="modal">
    <div class="title" mat-dialog-title>{{ 'Settings.AddReceiver' | translate}}</div>
    <form  *ngIf="editReceiverForm" [formGroup]="editReceiverForm" (ngSubmit)="onSubmit()">
      <mat-dialog-content>
        <div class="description">{{ 'Settings.CompleteFields' | translate}}</div>
        <div class="expanded-info-parent">
          <div class="expanded-info-column">
            <div class="input-box">
                <div class="expanded-info-title">
                  {{ ('Settings.Name' | translate) }}
                </div>
                <input 
                  class="expanded-info-input" 
                  formControlName="receiverName"
                  placeholder="{{ ('Settings.Name' | translate) }}" 
                  matInput #message
                  [(ngModel)]="newReceiver.name"
                >
                <div [class.visible]="getError('receiverName')" class="expanded-info-detail error">
                  <span *ngIf="getErrorType('receiverName', 'required')">{{ 'Settings.ErrorControl.RequiredField' | translate }}</span>
                </div>
            </div>
            <div class="input-box">
                <div class="expanded-info-title">
                  {{ ('Settings.PanID' | translate) }}
                </div>
                <input 
                  class="expanded-info-input" 
                  formControlName="panID"
                  placeholder="{{ ('Settings.PanID' | translate) }}" 
                  matInput #message
                  [(ngModel)]="newReceiver.panID"
                >
                <div [class.visible]="getError('panID')" class="expanded-info-detail error">
                  <span *ngIf="getErrorType('panID', 'required')">{{ 'Settings.ErrorControl.RequiredField' | translate }}</span>
                </div>
            </div>
            <div class="input-box">
                <div class="expanded-info-title">
                  {{ ('Settings.IP' | translate) }}
                </div>
                <input 
                  class="expanded-info-input" 
                  formControlName="ip"
                  placeholder="{{ ('Settings.IP' | translate) }}" 
                  matInput #message
                  [(ngModel)]="newReceiver.ip"
                >
                <div [class.visible]="getError('ip')" class="expanded-info-detail error">
                  <span *ngIf="getErrorType('ip', 'required')">{{ 'Settings.ErrorControl.RequiredField' | translate }}</span>
                  <span *ngIf="getErrorType('ip', 'invalidParam')">{{ 'Settings.ErrorControl.IpFormat' | translate }}</span>
                </div>
            </div>
            <div class="input-box">
                <div class="expanded-info-title">
                  {{ ('Settings.SnifferPort' | translate) }}
                </div>
                <input 
                  class="expanded-info-input" 
                  formControlName="snifferPort"
                  placeholder="{{ ('Settings.SnifferPort' | translate) }}" 
                  matInput #message
                  [(ngModel)]="newReceiver.snifferPort"
                >
                <div [class.visible]="getError('snifferPort')" class="expanded-info-detail error">
                  <span *ngIf="getErrorType('snifferPort', 'required')">{{ 'Settings.ErrorControl.RequiredField' | translate }}</span>
                  <span *ngIf="getErrorType('snifferPort', 'invalidParam')">{{ 'Settings.ErrorControl.PortLenght' | translate }}</span>
                </div>
            </div>
            <div class="input-box">
                <div class="expanded-info-title">
                  {{ ('Settings.MonitoringPort' | translate) }}
                </div>
                <input 
                  class="expanded-info-input" 
                  formControlName="monitoringPort"
                  placeholder="{{ ('Settings.MonitoringPort' | translate) }}" 
                  matInput #message
                  [(ngModel)]="newReceiver.monitoringPort"
                >
                <div [class.visible]="getError('monitoringPort')" class="expanded-info-detail error">
                  <span *ngIf="getErrorType('monitoringPort', 'required')">{{ 'Settings.ErrorControl.RequiredField' | translate }}</span>
                  <span *ngIf="getErrorType('monitoringPort', 'invalidParam')">{{ 'Settings.ErrorControl.PortLenght' | translate }}</span>
                </div>
            </div>
            <div class="input-box">
                <div class="expanded-info-title">
                  {{ ('Settings.BridgePort' | translate) }}
                </div>
                <input 
                  class="expanded-info-input" 
                  formControlName="bridgePort"
                  placeholder="{{ ('Settings.BridgePort' | translate) }}" 
                  matInput #message
                  [(ngModel)]="newReceiver.bridgePort"
                >
                <div [class.visible]="getError('bridgePort')" class="expanded-info-detail error">
                  <span *ngIf="getErrorType('bridgePort', 'required')">{{ 'Settings.ErrorControl.RequiredField' | translate }}</span>
                  <span *ngIf="getErrorType('bridgePort', 'invalidParam')">{{ 'Settings.ErrorControl.PortLenght' | translate }}</span>
                </div>
            </div>
          </div>
          <div class="expanded-info-column">
            <div class="input-box">
                <div class="expanded-info-title">
                  {{ ('Settings.ReceiverID' | translate) }}
                </div>
                <input 
                  class="expanded-info-input" 
                  formControlName="receiverID"
                  placeholder="{{ ('Settings.ReceiverID' | translate) }}" 
                  matInput #message
                  [(ngModel)]="newReceiver.receiverID"
                >
                <div [class.visible]="getError('receiverID')" class="expanded-info-detail error">
                  <span *ngIf="getErrorType('receiverID', 'required')">{{ 'Settings.ErrorControl.RequiredField' | translate }}</span>
                </div>
            </div>
            <div class="input-box">
                <div class="expanded-info-title">
                  {{ ('Settings.ReceiverNumber' | translate) }}
                </div>
                <input 
                  class="expanded-info-input" 
                  formControlName="receiverNumber"
                  placeholder="{{ ('Settings.ReceiverNumber' | translate) }}" 
                  matInput #message
                  [(ngModel)]="newReceiver.receiverNumber"
                >
                <div [class.visible]="getError('receiverNumber')" class="expanded-info-detail error">
                  <span *ngIf="getErrorType('receiverNumber', 'required')">{{ 'Settings.ErrorControl.RequiredField' | translate }}</span>
                  <span *ngIf="getErrorType('receiverNumber', 'invalidParam')">{{ 'Settings.ErrorControl.OneDigitLenght' | translate }}</span>
                </div>
            </div>
            <div class="input-box">
                <div class="expanded-info-title">
                  {{ ('Settings.Apikey' | translate) }}
                </div>
                <input 
                  class="expanded-info-input" 
                  formControlName="apiKey"
                  placeholder="{{ ('Settings.Apikey' | translate) }}" 
                  matInput #message
                  [(ngModel)]="newReceiver.apiKey"
                >
                <div [class.visible]="getError('apiKey')" class="expanded-info-detail error">
                  <span *ngIf="getErrorType('apiKey', 'required')">{{ 'Settings.ErrorControl.RequiredField' | translate }}</span>
                  <span *ngIf="getErrorType('apiKey', 'invalidParam')">{{ 'Settings.ErrorControl.ApikeyLenght' | translate }}</span>
                </div>
            </div>
            <div class="input-box">
                <div class="expanded-info-title">
                  {{ ('Settings.UdpPort' | translate) }}
                </div>
                <input 
                  class="expanded-info-input" 
                  formControlName="udpPort"
                  placeholder="{{ ('Settings.UdpPort' | translate) }}" 
                  matInput #message
                  [(ngModel)]="newReceiver.udpPort"
                >
                <div [class.visible]="getError('udpPort')" class="expanded-info-detail error">
                  <span *ngIf="getErrorType('udpPort', 'required')">{{ 'Settings.ErrorControl.RequiredField' | translate }}</span>
                  <span *ngIf="getErrorType('udpPort', 'invalidParam')">{{ 'Settings.ErrorControl.PortLenght' | translate }}</span>
                </div>
            </div>
            <div class="input-box">
                <div class="expanded-info-title">
                  {{ ('Settings.GestionPort' | translate) }}
                </div>
                <input 
                  class="expanded-info-input" 
                  formControlName="gestionPort"
                  placeholder="{{ ('Settings.GestionPort' | translate) }}" 
                  matInput #message
                  [(ngModel)]="newReceiver.gestionPort"
                >
                <div [class.visible]="getError('gestionPort')" class="expanded-info-detail error">
                  <span *ngIf="getErrorType('gestionPort', 'required')">{{ 'Settings.ErrorControl.RequiredField' | translate }}</span>
                  <span *ngIf="getErrorType('gestionPort', 'invalidParam')">{{ 'Settings.ErrorControl.PortLenght' | translate }}</span>
                </div>
            </div>
          </div>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions class="button-line">
        <button 
          class="button-container-button cancel-button"
          mat-button [mat-dialog-close]="true"  
          (click)="closeModal('cancel')"
        >
          {{ 'Shared.Cancel' | translate}}
        </button>
        <button 
          class="button-container-button create-button"
          mat-button
          type="submit"
        >
          {{ 'Settings.Add' | translate}}
        </button>
      </mat-dialog-actions >
      <button class="close-button" (click)="closeModal('close')">
          <mat-icon class="close-icon">close</mat-icon>
      </button>
    </form>
</div>