import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmActionDialogComponent } from 'src/app/shared/components/confirm-action-dialog/confirm-action-dialog.component';
import { TranslateService } from '@ngx-translate/core';

export interface DialogData {
  availableTopics: any[];
  currentTopics: string[];
  isUser: boolean;
  name: string;
  events: boolean;
}

@Component({
  selector: 'app-notification-form',
  templateUrl: './notification-form.component.html',
  styleUrls: ['./notification-form.component.scss']
})
export class NotificationFormComponent {
  public topicsList: {
    topic: string,
    text: string,
    selected: boolean,
  }[] = [];

  constructor(
    private dialogRef: MatDialogRef<NotificationFormComponent>,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
  ) {
    dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => {this.onCancelClick(); });
    this.data.availableTopics.forEach((t) => {
      this.topicsList.push({
        topic: t.topic,
        text: t.text,
        selected: this.data.currentTopics && this.data.currentTopics.length > 0 ? this.data.currentTopics.includes(t.topic) : false,
      });
    });
  }

  openConfirmDialog(promptText: string, submitText: string, callback: Function): void {
    const dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      maxWidth: '450px',
      autoFocus: false,
      data: { promptText, submitText }
    });

    dialogRef.afterClosed().subscribe(result => callback(result));
  }

  async submit() {
    const topics = {};

    this.topicsList.forEach((t) => {
      topics[t.topic] = t.selected;
    });

    if (this.data.isUser || this.data.events) {
      this.dialogRef.close({ status: 'success', topics });
    } else {
      this.openConfirmDialog(await this.translateService.get('NotForm.ModifPartitionSett').toPromise(), await this.translateService.get('Shared.Accept').toPromise(), (result: boolean) => {
        if (result) {
          this.dialogRef.close({ status: 'success', topics });
        }
      });
    }
  }

  onCancelClick = async () => {
    this.openConfirmDialog(await this.translateService.get('NotForm.ExitForm').toPromise(), await this.translateService.get('Shared.Leave').toPromise(), (result: boolean) => {
      if (result) {
        this.dialogRef.close({ status: 'cancel' });
      }
    });
  }


}
