<div style="display: grid; grid-template-columns: 1fr 22px">
  <div class="card-header">
    {{ 'Receiver.MonitoringState' | translate }}
  </div>
  <div style="text-align: end">
    <mat-icon onKeyPress onKeyDown onKeyUp style="color: #42526d; cursor: pointer" (click)="close()"> close </mat-icon>
  </div>
</div>

<table class="font-style" style="margin-bottom: 30px">
  <tr>
    <th>{{ 'Receiver.Name' | translate }}</th>
    <th style="float: right">
      <div class="info-header-grid">
        <div style="padding-bottom: 4px">{{ 'Receiver.State' | translate }}</div>
        <mat-icon
          [tooltipDeitres]="toolTipContent()"
          [tooltipCustomLeft]="-4"
          [tooltipCustomTop]="-120"
          tooltipSize="fit"
          style="margin-left: 10px; font-size: 20px; cursor: pointer"
        >
          info_outlined
        </mat-icon>
      </div>
    </th>
  </tr>
  <tr *ngFor="let receiver of this.receiversService.filteredReceivers">
    <td class="receiver-name">
      {{ receiver.name }}
    </td>
    <td style="float: right">
      <div [class]="!receiver.status ? 'grey-chip' : receiver.monitoringStatus ? 'green-chip' : 'red-chip'">
        <mat-icon [class]="!receiver.status ? 'grey-icon' : receiver.monitoringStatus ? 'green-icon' : 'red-icon'">
          circle
        </mat-icon>
        <span class="receiver-status">
          {{
            !receiver.status
              ? ('Receiver.OfflineReceiver' | translate)
              : receiver.monitoringStatus
              ? ('Receiver.Online' | translate)
              : ('Receiver.Offline' | translate)
          }}
        </span>
      </div>
    </td>
  </tr>
</table>

<!-- <div class="font-style dont-show-monitoring">
  <input
    onKeyPress
    onKeyDown
    onKeyUp
    type="checkbox"
    [checked]="launchMonitoringStatusAtStartup"
    (change)="dontShowMonitoring($event)"
    style="cursor: pointer"
  />
  <span style="margin-left: 10px">{{ 'Shared.DontShowAgain' | translate }}</span>
</div>

<div class="font-style" style="margin-bottom: 30px; display: flex">
  <mat-icon style="color: #ff9500; margin: 0; padding: 0; font-size: 19px; margin-left: 2px"> warning </mat-icon>
  <span style="color: #5d6b82; font-size: 12px; margin-left: 5px; padding-top: 5px">
    {{ 'Receiver.WarningClose' | translate }}
  </span>
</div> -->

<div onKeyPress onKeyDown onKeyUp class="close-btn" (click)="close()">{{ 'Shared.Close' | translate }}</div>
