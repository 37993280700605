import { Component, OnInit, SecurityContext } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-beta-warning',
  templateUrl: './beta-warning.component.html',
  styleUrls: ['./beta-warning.component.scss']
})
export class BetaWarningComponent implements OnInit {
  title: SafeHtml;
  
  constructor(
    private readonly iconRegistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
    private readonly translateService: TranslateService,
    public dialogRef: MatDialogRef<BetaWarningComponent>
  ) {}

  ngOnInit(): void {
    this.iconRegistry.addSvgIcon(
      'custom_icon', 
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/betaWarning/ad_group.svg')
    );
    this.iconRegistry.addSvgIcon(
      'groups_icon',
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/betaWarning/groups.svg')
    );
    this.iconRegistry.addSvgIcon(
      'support_icon', 
      this.sanitizer.bypassSecurityTrustResourceUrl('./assets/icons/betaWarning/support.svg')
    );
    
    this.getTitle();
  }

  onClose(status) {
    this.dialogRef.close({ status });
  }

  getTitle = async () => {
    const title = await lastValueFrom(this.translateService.get('Shared.BetaWarning_title'));
    this.title = this.sanitizer.sanitize(SecurityContext.HTML, title);
  }

}
