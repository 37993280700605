export class EventSignal {
  signal: number;
  signalType: string;
  mac: string;
}

export class PanelSignal {
  _id?: string;
  panelClickedOnGroupedMacs?: boolean;
  closePanel?: boolean;
  processMarkIcons?: boolean;
}

export class LeftSideMenuNavigation {
  closeLeftMenuNavigationBar?: boolean;
}

export class BreadcrumbedReceptor {
  type?: BreadCrumberChannelTypes;
  breadcrumb?: string;
  filterName?: string;
}

export enum BreadCrumberChannelTypes {
  CLOSE = 'close',
  NEWFILTER = 'new-filter'
}
