import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { User, ContextualMenuType } from 'src/app/settings/constants';
import { CompaniesService } from 'src/app/settings/services/companies.service';
import { ContextualMenuService } from 'src/app/settings/services/contextual-menu.service';
import { updateValue, validateEmail, validatorString } from 'src/app/settings/validators';
import { getPhoneMask } from 'src/app/shared/utils/phone-mask.util';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  editUserForm: FormGroup;
  submited: boolean;
  
  contextualMenuType = ContextualMenuType;
  currentType: number;

  newUser: User;
  mask: string = '(+00) 0000000000000';

  constructor(
    private readonly formBuilder: FormBuilder,
    public companiesService: CompaniesService,
    public dialogRef: MatDialogRef<CreateUserComponent>,
    public contextualMenuService: ContextualMenuService,
  ) { 
    this.editUserForm = this.formBuilder.group({
      name: ['', [Validators.required, (event) => validatorString('name', event, this.editUserForm, this.newUser)]],
      lastname: ['', [(event) => validatorString('lastname', event, this.editUserForm, this.newUser)]],
      email: ['', [Validators.required, (event) => validateEmail('email', event, this.editUserForm, this.newUser)]],
      phone: ['', [(event) => this.applyMask('phone', event)]],
    });
  }
  
  ngOnInit(): void {
    this.newUser = new User(this.companiesService.currentCompany?.language || 'es');
    this.newUser.companyID = this.companiesService.currentCompany._id;
    this.newUser.companyName = this.companiesService.currentCompany.name;
    this.newUser.companyColor = this.companiesService.currentCompany.colorPalette?.primary;
    if (this.newUser.phone) this.mask = getPhoneMask(this.newUser.phone);
  }

  applyMask(key, event) {
    if (!event.value || this.newUser[key] === event.value) return;
    updateValue(key, event.value, this.editUserForm, this.newUser);

    this.mask = getPhoneMask(event.value);
    return null;
  }
  
  closeModal(action) {
    this.newUser.expand = false;
    this.dialogRef.close({ status: action, newUser: this.newUser });
  }
  
  onContextalMenu(event, value, type, id) {
    this.currentType = type;
    const target = document.getElementById(id);
    this.contextualMenuService.processContextualMenuPosition(event, target, undefined, type);
    this.newUser.expand = value;
  }

  setValue(value) {
    switch (this.currentType) {
      case ContextualMenuType.Language:
        this.newUser.language = value;
        break;
      case ContextualMenuType.Permission:
      default:
        this.newUser.permission = value;
        break;
    }
    this.newUser.expand = false;
  };

  getError(event) {
    const field = this.editUserForm.get(event);
    return (this.submited || field?.touched) && (field?.errors?.required || field?.errors?.invalidParam)
  }

  getErrorType(event, type) {
    return this.editUserForm.get(event)?.errors?.[type];
  }

  onSubmit() {
    this.submited = true;
    if (this.editUserForm.valid && this.newUser.permission) {
      this.newUser.expand = false;
      this.dialogRef.close({ status: 'confirm', newUser: this.newUser });
    }
  }
}
