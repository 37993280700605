import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ContextualMenuService } from '../../services/contextual-menu.service';
import { CompaniesService } from '../../services/companies.service';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CompanyUserService } from '../../services/company-user.service';
import constants from 'src/app/shared/constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ConfirmActionDialogNewComponent } from 'src/app/shared/components/confirm-action-dialog-new-style/confirm-action-dialog.component';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss']
})
export class CompanyInfoComponent implements OnInit {

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly domSanitizer: DomSanitizer,
    private readonly matIconRegistry: MatIconRegistry, 
    private readonly translateService: TranslateService,
    private readonly companyUserService: CompanyUserService,
    public contextualMenuService: ContextualMenuService,
    public companiesService: CompaniesService,
    public authService: AuthService,
    public dialog: MatDialog,
  ) { 
  } 

  ngOnInit(): void {
    this.matIconRegistry.addSvgIcon(
      'delete_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/delete.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'edit_square',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/editsquare.svg')
    );
  }

  deleteCompany(companyID) {
    const company = this.companiesService.getCompany(companyID)
    const dialogRef = this.dialog.open(ConfirmActionDialogNewComponent, {
      width: '400px',
      data: {
        title: this.translateService.instant('Settings.ExcludeCompanyRequest').replace('{{companyName}}', company.name),
        description: 'Settings.ExcludeCompanyRequest_desc',
        rightButton: {
          title: 'Settings.ExcludeCompanyRequest_confirm',
          color: {
            background: 'red',
            font: 'white'
          }
        },
        leftButton: {
          title: 'Shared.Cancel',
          color: {
            background: 'white',
            font: 'black'
          }
        }
      },
    });

    dialogRef.afterClosed().subscribe((result: { status: string; topics: any }) => {
      if (result?.status === 'right') this.onSendDelete(companyID);
    });
  }

  onSendDelete(companyID) {
    this.companyUserService.deleteCompany(companyID).subscribe({
      next: (res: any) => {
        this.onSuccessDelete(companyID);
      },
      error: (error) => {
        if (error?.status === 401) {
          this.snackBar.open(
            this.translateService.instant('Shared.SessionExpired'),
            this.translateService.instant('Shared.Close'),

            { duration: constants.snackBarErrorDuration }
          );
          this.authService.logout();
        } else {
          this.snackBar.open(
            this.translateService.instant('Settings.Error.NoGetCompany'),
            this.translateService.instant('Shared.Close'),

            { duration: constants.snackBarErrorDuration }
          );
        }
      },
    });
  }

  onSuccessDelete(companyID) {
    const dialogRef = this.dialog.open(ConfirmActionDialogNewComponent, {
      width: '400px',
      data: {
        title: 'Settings.ExcludeCompanySuccess',
        rightButton: {
          title: 'Ok',
          color: {
            background: '#EAB910',
            font: 'black'
          }
        }
      },
    });

    dialogRef.afterClosed().subscribe((result: { status: string; topics: any }) => {
      this.companiesService.removeCompany(companyID);
      this.companiesService.setRefetchCompanies(true);
    });
  }

  editCompany(company) {
    this.companiesService.onEdit(company);
  }

  onExpand(item, value) {
    item.expand = value;
  }
  
  isLast(item, array) {
    return (array[array.length - 1] === item)
  }
}
