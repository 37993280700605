<!-- Map Component -->
<div class="mainContainer">
  <div class="loading-shade" *ngIf="panelsService.isLoadingResults">
    <mat-spinner *ngIf="panelsService.isLoadingResults"></mat-spinner>
  </div>
  <div
    id="map"
    class="map-view"
    role="button"
    tabindex="0"
    onclick="hideMenu('menu-contextual');"
    onKeyPress
    oncontextmenu="showMenu(event, this.id, 'menu-contextual', false); return false;"
    (contextmenu)="onMapRightClick($event)"
  ></div>
</div>

<app-map-type-switch></app-map-type-switch>

<!-- Info Modals -->
<app-mesh-status *ngIf="panelStatus.rendererStatusControl" class="statusMenu"></app-mesh-status>

<!-- Attention: Remove the ngIf and close using emit close ginal with
 this.cd.detectChanges() this enables us to make the component detect the change
 without ngIf which makes a little slower because ngIf forces recreating the component
 and also ngIf disables the transition animation! -->
<app-panel-state-sidemenu class="sideMenu" *ngIf="panelStatus?.selectedPanel" [changePanel]="panelStatus?.selectedPanel"></app-panel-state-sidemenu>

<app-search class="search"></app-search>
<app-neighbor-request class="neighborsQueue"></app-neighbor-request>

<!-- Menu contextual del mapa -->
<div
  *ngIf="panelStatus.selectedPanel && showContextMenu"
  id="menu-contextual"
  class="button-container"
  [style.top.px]="contextMenuPosition.y"
  [style.left.px]="contextMenuPosition.x"
>
  <button
    class="table-row"
    (click)="setLocation()"
    (keypress)="setLocation()"
    matTooltipShowDelay="750"
    matTooltipPosition="after"
  >
    {{ 'MeshNet.SetLocation' | translate }}
  </button>
</div>

<app-monitoring-state></app-monitoring-state>
