import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Panel } from 'src/app/panel/models/panel.model';
import { WebappSubscriptionService } from 'src/app/shared/services/webapp-subscription.service';
import { MatTableDataSource } from '@angular/material/table';
import { Receiver } from 'src/app/shared/models/receiver-model';
import { ReceiversService } from 'src/app/shared/services/receivers.service';
import { PanelsService } from 'src/app/shared/services/panels.service';
import constants from 'src/app/shared/constants';
import mapConstants from '../constants';
import mapIcons from '../icons';
import { CustomMapService } from './custom-map.service';
import { BreadCrumberChannelTypes, PanelSignal } from 'src/app/panel/models/event.model';
import { EventService } from 'src/app/panel/services/event.service';
import { PanelMapMark } from '../../types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PanelService } from 'src/app/panel/services/panel.service';
import { HoverMenuesService } from '../../hover-menues/services/hover-menues.service';
import { Router } from '@angular/router';

const SELECTED_MAC_ZOOM = 15;
const SHORT_TEST_EXPIRE_TIME = 60 * 60 * 1000;
interface PanelTypeCount {
  optimum: number;
  good: number;
  low: number;
  null: number;
  total: number;
}

@Injectable({
  providedIn: 'root',
})
export class PanelStatusService {
  renewPanelListIntervalID;
  snackBarDuration = 5000;
  snackBarErrorDuration = 10000;

  rendererMarKList = [];
  allMarksList = [];
  public rendererStatusControl: PanelTypeCount;

  panelList: Panel[];
  marksByReceiver = {};
  filteredDevices = [];
  parentDevices = [];
  selectedPanel;
  savePanelOnFocus;

  saveLastPanel;

  // Views
  selectedFilter: string = 'ViewNone';
  // Glosary
  glossaryCount = this.defaultGlossary();
  iconPriority = {};

  nodeState: string = '';

  // Map Paths
  mapPanelMark: string;

  // Default position
  defPosition = {
    latitude: -7.01161,
    longitude: -65.1171,
  };

  intervalId: ReturnType<typeof setInterval>;

  // Sidemenu
  reloadSideMenu = false;

  // Status
  public statusControl = {};
  dataSource: MatTableDataSource<Receiver>;

  // Interface Types
  /** We need to maintain a source of truth unchanged because
   * changing the data-source of truth by language before its used
   * causes code-smell and causes confusion for debugging
   * consumers will adapt to the data and use differently as they see fit
   */
  interfaceTypes = {
    wifi: 'wifi',
    ethernet: 'ethernet',
    mobile: '3g/4g',
    mobile2g: 'gsm',
    mobile3g: '3g',
    mobile4g: '4g',
    rf: 'rf',
  };

  // Neighbor Queue
  public neighborQueue = [];

  qtyInSameLatLngPosition = [];
  savedPanelClicked: Panel;

  // Reload Neighbors
  public neighborReloadState: {
    panel;
    stage: number;
    started: boolean;
  };

  installationTypeFilters;
  conectivityTypeFilters;

  constructor(
    private readonly authService: AuthService,
    private readonly receiversService: ReceiversService,
    private readonly translateService: TranslateService,
    private readonly webppSocketSocket: WebappSubscriptionService,
    private readonly panelsService: PanelsService,
    private readonly customMapService: CustomMapService,
    private readonly eventService: EventService,
    private readonly snackBar: MatSnackBar,
    private readonly panelApi: PanelService,
    private readonly hoverMenuesService: HoverMenuesService,
    private readonly router: Router
  ) {
    this.webppSocketSocket.addSubscription(this.handleIncomingNeighbor, constants.webappSocketTypes.newNeighbor);
    this.webppSocketSocket.addSubscription(this.handleIncomingPanelTest, constants.webappSocketTypes.mapNetworkUpdate);
    this.webppSocketSocket.addSubscription(
      this.handleIncomingInterfaceUpdate,
      constants.webappSocketTypes.mapInterfaceUpdate
    );
    this.webppSocketSocket.addSubscription(this.handleIncomingPanelList, constants.webappSocketTypes.panelsListPackage);
    this.webppSocketSocket.addSubscription(this.handleProcessUpdate, constants.webappSocketTypes.neighborReloadState);
    setInterval(() => {
      // Check panel connection loss
      // And renew legends count
      this.checkPanelConnectionAndRenewMapLegend();
    }, 5 * 60 * 1000);
    this.buildIconPriority();
    this.eventService.selectionUpdatedEvent.subscribe((signalParams: PanelSignal) => {
      if (signalParams.processMarkIcons) return this.processMarkIcons();
      this.onSelectedPanelChange(signalParams);
      if (!signalParams.closePanel || signalParams.panelClickedOnGroupedMacs) {
        this.saveLastPanel = signalParams;
      }
    });

    this.onInitReceivers();
    this.panelsService.removePanelFromMap = this.removePanelFromMap;
  }

  onInitReceivers() {
    this.receiversService.selectedReceiver = [mapConstants.defaultReceiver];
    this.receiversService.onReceiversCallback.push(this.onGetReceivers);
    if (!this.statusControl[mapConstants.defaultReceiver]) {
      this.statusControl[mapConstants.defaultReceiver] = {
        optimum: 0,
        good: 0,
        low: 0,
        null: 0,
        total: 0,
      };
    }
  }

  readonly onGetReceivers = () => {
    for (let r of this.receiversService.receivers) {
      this.marksByReceiver[r.apiKey] = [];
      this.statusControl[r.apiKey] = {
        optimum: 0,
        good: 0,
        low: 0,
        null: 0,
        total: 0,
      };
    }
  };

  getReceivers() {
    return this.receiversService.receivers;
  }

  openTooltipAndCloseSideMenu = (value: PanelSignal) => {
    this.onMarkClick(undefined, value.panelClickedOnGroupedMacs, true);
  };

  openSideMenu = (value?: PanelSignal) => {
    if (!value) {
      value = this.saveLastPanel;
    }

    const panel = this.findPanelByMark(value);

    if (this.hoverMenuesService.sideMenuStatus) {
      // Selecte a new panel showing the another trigger de menu load animation
      this.reloadSideMenu = !!value._id;
    } else {
      this.customMapService.markHiddenByZoomState = false;
    }
    this.onMarkClick(panel, value.panelClickedOnGroupedMacs, false);
  };

  onSelectedPanelChange = (value: PanelSignal) => {
    if (!value._id || (value.closePanel && this.selectedPanel && this.selectedPanel._id === value._id)) {
      // miss click or Double click - Close side menu
      this.openTooltipAndCloseSideMenu(value);
    } else {
      if (this.customMapService.showPanelPath?.type === mapConstants.PathTypes.Neighbor) {
        // Redraw icon colors removing grayscale on select a diferent panel
        this.customMapService.showPanelPath = undefined;
        this.processMarkIcons();
      }
      this.openSideMenu(value);
    }
  };

  findPanelByMark(marker: PanelSignal) {
    return this.rendererMarKList.find((panel: PanelMapMark) => marker._id === panel._id);
  }

  onMarkClick = (panel: PanelMapMark, panelClickedOnGroupedMacs: boolean, closePanel: boolean) => {
    /** do not remove this safe-guard, sometimes this method is called without a panel present */
    if (!panel) return;

    this.getCurrentLocation(panel, panelClickedOnGroupedMacs, closePanel);
  };

  getCurrentLocation = (panel: PanelMapMark, panelClickedOnGroupedMacs: boolean, closePanel: boolean) => {
    if (panelClickedOnGroupedMacs) {
      return this.onSelectPanel(panel, false);
    }

    this.qtyInSameLatLngPosition = this.rendererMarKList.reduce((acc, curr) => {
      if (curr.lat === panel.lat && curr.lng === panel.lng) {
        acc.push(curr);
      }
      return acc;
    }, []);

    if (this.qtyInSameLatLngPosition.length === 1) {
      this.onSelectPanel(panel, closePanel);
    } else {
      this.selectedPanel = undefined;
    }
  };

  onSelectPanel = (panel: PanelMapMark, closePanel: boolean) => {
    if (this.selectedPanel?._id != panel._id) {
      this.selectedPanel = panel;
      // When its a closing of the left side flap don't set selected
      if (closePanel) {
        this.customMapService.unsetSelectedMark();
        this.customMapService.cleanRestorationOfFocus();
      } else {
        this.customMapService.setSelectedMark({ lat: panel.lat, lng: panel.lng });
      }
    }
  };

  getState = (panel: PanelMapMark) => {
    const receiver = this.receiversService.filteredReceivers.find((r) => r.apiKey === panel.apiKey);
    return receiver?.status ? panel.state : 'null';
  };

  buildIconPriority = () => {
    // Null & Low are displayed above Good and Optimum regardless of the installation type.
    // Same case is applied for Wine, Null & Low on Linking View
    this.iconPriority[mapConstants.viewTypes.None] = {
      null: 804,
      low: 803,
      good: 702,
      optimum: 701,
    };
    this.iconPriority[mapConstants.viewTypes.ViewLinking] = {
      wine: 805,
      null: 804,
      low: 803,
      good: 702,
      optimum: 701,
    };
  };

  // Socket Handler
  handleIncomingNeighbor = async (message) => {
    const panel = this.rendererMarKList.find((p) => p.mac === message.mac);

    if (message.neighbor && panel && !panel.map.neighbors.includes(message.neighbor)) {
      panel.map.neighbors.push(message.neighbor);
    }

    if (
      this.customMapService.showPanelPath?.type == mapConstants.PathTypes.Neighbor &&
      message.mac === this.selectedPanel.mac
    ) {
      this.processPanelNeighbor(panel);
    }
  };

  handleIncomingPanelTest = async (message) => {
    this.processPanelTest(message.mac, message.date, message.interfaceReport);
  };

  handleIncomingInterfaceUpdate = async (message) => {
    const panel = this.marksByReceiver[mapConstants.defaultReceiver]?.find((p) => p.mac === message.mac);
    if (panel) {
      if (panel?.link) {
        panel.link[message.interface] = message.interfaceValue;
      } else {
        panel.link = { [message.interface]: message.interfaceValue };
      }
      if (panel.map) {
        panel.map.interfaceReport = message.interfaceReport;
      } else {
        panel.map = { interfaceReport: message.interfaceReport };
      }

      this.processSingleMarkIcon(panel);
      this.customMapService.findAndUpdatePanelIcon(panel, panel.img);
    }
  };

  handleIncomingPanelList = async (message) => {
    if (this.panelsService.fetchingPanels) {
      if (message.finish) {
        // Only process finish if the fetch has started to avoid:
        // * Closing the loading bar that never started
        // * Reconfiguring the map
        this.configureMap();
        this.panelsService.loadingBar.useRef().complete();
        this.panelsService.fetchingPanels = false;

        /**
         * Unecessary overload?
         * I tried to count them all in one swoop
         * but the counting gets everything wrong
         * and duplicates, i don't know why
         */
        this._iterateOverMarkersCalculatingGlosarryByViewType(mapConstants.viewTypes.Installation);
        this._iterateOverMarkersCalculatingGlosarryByViewType(mapConstants.viewTypes.Conectivity);

        this.panelApi.getNeighborReloadStage().subscribe({
          next: async (res: any) => {
            if (res?.success) {
              // Process Start Successfully
              const panel = this.marksByReceiver[mapConstants.defaultReceiver].find((p) => p.mac === res.state.mac);
              if (panel) {
                this.neighborReloadState = {
                  panel,
                  stage: res.state.stage,
                  started: true,
                };
              }
            }
          },
          error: async (error) => {},
        });
      } else {
        this.panelsService.isLoadingResults = false;

        const { activePanels, inactivePanels } = message.markList.reduce(
          (acc, panel) => {
            if (panel.active === false) {
              acc.inactivePanels.push(panel);
            } else {
              acc.activePanels.push(panel);
            }
            return acc;
          },
          { activePanels: [], inactivePanels: [] }
        );

        const inactivePanelsMap = new Map(this.panelsService.inactivePanels.map((panel) => [panel.mac, panel]));

        inactivePanels.forEach((panel) => {
          inactivePanelsMap.set(panel.mac, panel);
        });

        this.panelsService.inactivePanels = Array.from(inactivePanelsMap.values());

        const incomingMarks = await this.updatePanelList(activePanels);

        this.marksByReceiver[message.apiKey] = this.marksByReceiver[message.apiKey]
          ? this.marksByReceiver[message.apiKey].concat(incomingMarks)
          : incomingMarks;

        this.marksByReceiver[mapConstants.defaultReceiver] = this.marksByReceiver[mapConstants.defaultReceiver]
          ? this.marksByReceiver[mapConstants.defaultReceiver].concat(incomingMarks)
          : incomingMarks;

        // Update Panels Reference
        this.panelsService.panels = this.marksByReceiver[mapConstants.defaultReceiver];
        this.panelsService.collectPanels(true);

        this.updateCurrentMarkList();

        this.receiversService.dataSource.data = this.receiversService.receivers.map((r) => {
          return {
            ...r,
            ...this.statusControl[r.apiKey],
          };
        });
      }
    }
  };

  removePanelFromMap = (panel: Panel) => {
    const { apiKey, state } = panel;

    this.marksByReceiver[apiKey] = this.marksByReceiver[apiKey].filter((p) => p.mac !== panel.mac);
    this.marksByReceiver[mapConstants.defaultReceiver] = this.marksByReceiver[mapConstants.defaultReceiver].filter(
      (p) => p.mac !== panel.mac
    );

    this._iterateOverMarkersCalculatingGlosarryByViewType(mapConstants.viewTypes.Installation);
    this._iterateOverMarkersCalculatingGlosarryByViewType(mapConstants.viewTypes.Conectivity);
    this.updateCurrentMarkList();

    this.statusControl[apiKey][state]--;
    this.statusControl[apiKey].total--;
    this.statusControl[mapConstants.defaultReceiver][state]--;
    this.statusControl[mapConstants.defaultReceiver].total--;

    if (this.receiversService.selectedReceiver.includes(apiKey)) {
      this.rendererStatusControl[state]--;
      this.rendererStatusControl.total--;
    }
  };

  handleProcessUpdate = async (message) => {
    if (message.mac === this.neighborReloadState?.panel.mac) {
      if (!message.failed) {
        this.neighborReloadState.stage = message.stage;
        switch (message.stage) {
          case 1:
            this.neighborReloadState.panel.map.neighborsQty = 0;
            this.neighborReloadState.panel.map.neighbors = [];
            break;
          case 2:
            this.updateNeighborQuantity(message);
            break;
          case 3:
            this.finishReloadNeighborProcess();
            break;
        }
      } else if (this.neighborReloadState.stage <= message.stage) {
        setTimeout(async () => {
          this.snackBar.open(
            await this.translateService.get('MeshNet.NeighborProcessFail').toPromise(),
            await this.translateService.get('Shared.Close').toPromise(),
            { duration: this.snackBarDuration }
          );
          this.neighborReloadState.panel.requesting = false;
          this.neighborQueue = this.neighborQueue.filter((p) => p.mac != this.neighborReloadState.panel.mac);
          this.neighborReloadState = undefined;
        }, 3000);
      }
    }
  };

  private updateNeighborQuantity(message) {
    // Update Quantity
    if (message.qty != undefined) {
      if (this.neighborReloadState.panel.map) {
        this.neighborReloadState.panel.map.neighborsQty = message.qty;
      } else {
        this.neighborReloadState.panel.map = { neighborsQty: message.qty };
      }
    }
  }

  private async finishReloadNeighborProcess() {
    this.snackBar.open(
      await this.translateService.get('MeshNet.NeighborProcessSuccess').toPromise(),
      await this.translateService.get('Shared.Close').toPromise(),
      { duration: this.snackBarDuration }
    );

    this.neighborReloadState.panel.requesting = false;
    setTimeout(() => {
      this.neighborQueue = this.neighborQueue.filter((p) => p.mac != this.neighborReloadState.panel.mac);
      this.neighborReloadState = undefined;
    }, 2000);
  }

  private updateCurrentMarkList() {
    this.rendererMarKList = [];
    this.allMarksList = [];
    for (let r of this.receiversService.selectedReceiver) {
      this.rendererMarKList = [...this.rendererMarKList, ...this.marksByReceiver[r]];
      this.allMarksList = [...this.rendererMarKList];
    }

    let filteredValues = false;

    if (this.conectivityTypeFilters && this.conectivityTypeFilters.length > 0) {
      this.conectivityTypeFilter();
      filteredValues = true;
    }

    if (this.installationTypeFilters && this.installationTypeFilters.length > 0) {
      this.installationTypeFilter();
      filteredValues = true;
    }

    this.customMapService.currentMarkListSubject.next(this.rendererMarKList);

    this.calculateStatusCount(filteredValues);

    if (this.selectedPanel) {
      this.alwaysShowSelectedPanel();
    }

    if (!this.selectedPanel) {
      this.configureMap();
    }
  }

  private alwaysShowSelectedPanel() {
    if (!this.rendererMarKList.includes(this.selectedPanel)) {
      this.processSingleMarkIcon(this.selectedPanel, true);
      this.rendererMarKList.push(this.selectedPanel);
    }

    if (
      this.customMapService.showPanelPath?.type === mapConstants.PathTypes.Neighbor &&
      this.selectedPanel.map.neighbors
    ) {
      for (let neighborMAC of this.selectedPanel.map.neighbors) {
        const neighbor = this.allMarksList.find((p) => p.mac === neighborMAC);
        if (neighbor && !this.rendererMarKList.find((p) => p.mac === neighbor.mac)) {
          this.rendererMarKList.push(neighbor);
          this.processSingleMarkIcon(neighbor, true);
        }
      }
    }
  }

  private conectivityTypeFilter() {
    let conectivityFilteredValues = [];

    const mobile = ['3g/4g', 'gsm', '3g', '4g'];
    const wifiEthernet = 'wifi-ethernet';

    const filters = this.conectivityTypeFilters.map(String);
    if (filters.includes(wifiEthernet)) {
      conectivityFilteredValues = conectivityFilteredValues.concat(
        this.rendererMarKList.filter(
          (panel) =>
            (panel.map?.interfaceReport === 'wifi' || panel.map?.interfaceReport === 'ethernet') &&
            !panel.link?.mobileStatus
        )
      );
    }

    if (filters.includes('mobile')) {
      conectivityFilteredValues = conectivityFilteredValues.concat(
        this.rendererMarKList.filter(
          (panel) => mobile.includes(panel.map?.interfaceReport) && !panel.link?.wifiStatus && !panel.link?.ethStatus
        )
      );
    }

    if (filters.includes('full-internet')) {
      conectivityFilteredValues = conectivityFilteredValues.concat(
        this.rendererMarKList.filter(
          (panel) =>
            (wifiEthernet.includes(panel.map?.interfaceReport) && panel.link?.mobileStatus) ||
            (mobile.includes(panel.map?.interfaceReport) && (panel.link?.wifiStatus || panel.link?.ethStatus))
        )
      );
    }

    if (filters.includes('mesh')) {
      const excludedInterfaces = ['wifi', 'ethernet', '3g/4g', 'gsm', '3g', '4g'];

      const remainingPanels = this.rendererMarKList.filter(
        (panel) =>
          !conectivityFilteredValues.includes(panel) && !excludedInterfaces.includes(panel.map?.interfaceReport)
      );

      conectivityFilteredValues = conectivityFilteredValues.concat(remainingPanels);
    }

    this.rendererMarKList = [...new Set(conectivityFilteredValues)];
  }

  private installationTypeFilter() {
    let installationFilteredValues = [];
    const exteriorPanels = ['ExteriorLow', 'ExteriorHigh'];

    if (this.installationTypeFilters.includes('ExteriorLow') || this.installationTypeFilters.includes('ExteriorHigh')) {
      installationFilteredValues = this.rendererMarKList.filter((panel) =>
        this.installationTypeFilters.includes(panel.map?.instalationType)
      );
    }

    if (this.installationTypeFilters.includes('Interior')) {
      const interiorPanels = this.rendererMarKList.filter(
        (panel) => !exteriorPanels.includes(panel.map?.instalationType)
      );

      installationFilteredValues = installationFilteredValues.concat(interiorPanels);
    }

    this.rendererMarKList = [...new Set(installationFilteredValues)];
  }

  private calculateStatusCount(filteredValues: boolean) {
    this.rendererStatusControl = {
      optimum: 0,
      good: 0,
      low: 0,
      null: 0,
      total: 0,
    };

    for (let r of this.receiversService.selectedReceiver) {
      this.rendererStatusControl.total += this.statusControl[r]?.total ?? 0;
    }

    if (filteredValues) {
      const statusCount = {
        optimum: 0,
        good: 0,
        low: 0,
        null: 0,
      };

      this.glossaryCount[this.selectedFilter] = {
        typeLinkingLow: 0,
        typeLinkingGood: 0,
        typeLinkingOptimum: 0,
        typeLinkingDisconnected: 0,
        typeLinkingNull: 0,
      };

      for (let panel of this.rendererMarKList) {
        const status = this.getStatus(panel);

        if (statusCount.hasOwnProperty(status)) {
          statusCount[status]++;
        }

        this.setNeighborCount(panel.map?.neighborsQty, panel?.state);
      }

      this.rendererStatusControl.optimum = statusCount.optimum;
      this.rendererStatusControl.good = statusCount.good;
      this.rendererStatusControl.low = statusCount.low;
      this.rendererStatusControl.null = statusCount.null;
    } else {
      for (let r of this.receiversService.selectedReceiver) {
        this.rendererStatusControl.optimum += this.statusControl[r]?.optimum ?? 0;
        this.rendererStatusControl.good += this.statusControl[r]?.good ?? 0;
        this.rendererStatusControl.low += this.statusControl[r]?.low ?? 0;
        this.rendererStatusControl.null += this.statusControl[r]?.null ?? 0;
      }
    }
  }

  private readonly processPanel = (panel) => {
    // Status process
    const status = this.getStatus(panel);
    if (panel.active || panel.active === undefined) this.updateNetworkStatus(panel.apiKey, status);

    // Location process
    let companyLocation = this.authService.user.companies.find((c) => c._id === panel.company)?.location;

    if (!companyLocation?.latitude || !companyLocation?.longitude) {
      companyLocation = this.defPosition;
    }
    const hasLocation = panel.location?.latitude && panel.location?.longitude;

    const rawAddress = panel.map?.address;
    if (rawAddress) panel.map.splitString = panel.map.address.split(',');

    // Map Mark
    const res = {
      ...panel,
      lat: hasLocation ? panel.location?.latitude : companyLocation.latitude,
      lng: hasLocation ? panel.location?.longitude : companyLocation.longitude,
      state: status,
    };

    if (res.map?.parent) this.parentDevices.push(res.map.parent);

    this.processSingleMarkIcon(res);

    return res;
  };

  getPriority = (instalationType, status, neighbors) => {
    let instalationPriority = 0;
    if (instalationType === 'ExteriorHigh') {
      instalationPriority = 50;
    } else if (instalationType === 'ExteriorLow') {
      instalationPriority = 30;
    }

    let finalStatus;
    if (this.selectedFilter === mapConstants.viewTypes.ViewLinking) {
      if (status === 'null') {
        finalStatus = 'wine';
      } else {
        switch (neighbors) {
          case 0:
            finalStatus = 'null';
            break;
          case 1:
            finalStatus = 'low';
            break;
          case 2:
            finalStatus = 'good';
            break;
          case 0:
            finalStatus = 'optimum';
            break;
        }
      }
    } else {
      finalStatus = status;
    }

    return this.iconPriority[this.selectedFilter][finalStatus] + instalationPriority;
  };

  public updatePanelList = async (newpanelList: Panel[]) => {
    const lastHour = new Date().getTime() - SHORT_TEST_EXPIRE_TIME;
    return newpanelList.reduce((acc, panel) => {
      this.parseInterfaceReport(panel);
      if (panel.reports) {
        panel.reports = panel.reports.filter((report) => new Date(report.date).getTime() > lastHour);
      }
      const p = this.marksByReceiver[mapConstants.defaultReceiver]?.find((p) => p.mac === panel.mac);
      if (!p) {
        acc.push(this.processPanel(panel));
      } else {
        const keys = Object.keys(panel);
        keys.forEach((k) => (p[k] = panel[k]));
      }
      return acc;
    }, []);
  };

  private readonly updateState = (panel, lastHour, date = undefined) => {
    const oldLength = panel.reports?.length ?? 0;
    const oldState = panel.state;

    panel.reports = (panel.reports ?? []).filter((r) => new Date(r.date).getTime() > lastHour);

    if (date) panel.reports.push({ date });

    if (oldLength != panel.reports.length && mapConstants.viewTypes.ViewLinking !== this.selectedFilter) {
      // Update Panel Status
      panel.state = this.getStatus(panel);
      this.processSingleMarkIcon(panel);
      if (panel.state != oldState) {
        this.customMapService.findAndUpdatePanelIcon(panel, panel.img);
      }
    }
  };

  private readonly processPanelTest = async (mac, date, interfaceReport) => {
    const lastHour = new Date().getTime() - SHORT_TEST_EXPIRE_TIME;
    const panel = this.marksByReceiver[mapConstants.defaultReceiver]?.find((p) => p.mac === mac);
    if (panel) {
      /** interfaceReport will be lowercased later by processSingleMark */
      panel.map.interfaceReport = interfaceReport ?? panel.map.interfaceReport;
      panel.map.lastReport = date;
      this.updateState(panel, lastHour, date);
    }
  };

  private readonly checkPanelConnectionAndRenewMapLegend = () => {
    const lastHour = new Date().getTime() - SHORT_TEST_EXPIRE_TIME;
    this.statusControl = {
      [mapConstants.defaultReceiver]: {
        optimum: 0,
        good: 0,
        low: 0,
        null: 0,
        total: 0,
      },
    };

    for (const panel of this.marksByReceiver[mapConstants.defaultReceiver]) {
      this.updateState(panel, lastHour);
      this.updateNetworkStatus(panel.apiKey, panel.state);
    }
  };

  // Panel Locations Functions
  public loadFullPanelList = (navigateData = undefined) => {
    if (!this.panelsService.dataSource?.data || this.panelsService.dataSource.data.length === 0) {
      // Load Panels
      this.getPanels();
    } else {
      this.selectedPanel = undefined;
      this.configureMap(navigateData);
    }
  };

  public closeMapSession = async (reloadMarks) => {
    this.clearMap();
    this.clearReceiver(reloadMarks);
  };

  public getStatus = (panel: Panel) => {
    let lowControl = 6;
    let goodControl = 10;
    const testCount = panel.reports?.length ?? 0;
    const receiver = this.receiversService.receivers.find((r) => r.apiKey === panel.apiKey);
    if (receiver && receiver.testPHourConfiguration) {
      lowControl = receiver.testPHourConfiguration.lowControl;
      goodControl = receiver.testPHourConfiguration.goodControl;
    }

    // Map Status (ONLINE-OFFLINE)
    if (testCount > 0) {
      if (testCount <= lowControl) {
        return 'low';
      } else if (testCount <= goodControl) {
        return 'good';
      } else {
        return 'optimum';
      }
    }
    return 'null';
  };

  private readonly updateNetworkStatus = (apiKey, state) => {
    if (!this.statusControl[apiKey]) {
      this.statusControl[apiKey] = {
        optimum: 0,
        good: 0,
        low: 0,
        null: 0,
        total: 0,
      };
    }

    this.statusControl[apiKey][state]++;
    this.statusControl[apiKey].total++;

    this.statusControl[mapConstants.defaultReceiver][state]++;
    this.statusControl[mapConstants.defaultReceiver].total++;
  };

  public _iterateOverMarkersCalculatingGlosarryByViewType = (selectedFilter: string) => {
    /** clear counters of the selected filter first */
    const keys = Object.keys(this.glossaryCount[selectedFilter]);
    keys.forEach((k) => (this.glossaryCount[selectedFilter][k] = 0));

    for (let r of this.receiversService.selectedReceiver) {
      this.marksByReceiver[r].forEach((panel: Panel) => {
        this._calculateGlosaryByViewTypeAndMark(panel, selectedFilter);
      });
    }
  };

  public processMarkIcons = async () => {
    /** clear counters of the selected filter first */
    const keys = Object.keys(this.glossaryCount[this.selectedFilter]);
    keys.forEach((k) => (this.glossaryCount[this.selectedFilter][k] = 0));
    const promiseList = [];
    for (let r of this.receiversService.selectedReceiver) {
      if (this.marksByReceiver[r]) {
        if (!Array.isArray(this.marksByReceiver[r])) return;

        promiseList.push(
          this.marksByReceiver[r].forEach((panel) => {
            this.processSingleMarkIcon(panel);
          })
        );
      }
    }
    await Promise.all(promiseList);

    this.customMapService.setViewType(this.selectedFilter);
    this.updateCurrentMarkList();
  };

  private setNeighborCount(neighborsQty: number, state: string) {
    if (neighborsQty == 1 && state != 'null') this.glossaryCount[this.selectedFilter].typeLinkingLow += 1;
    else if (neighborsQty == 2 && state != 'null') this.glossaryCount[this.selectedFilter].typeLinkingGood += 1;
    else if (neighborsQty >= 3 && state != 'null') this.glossaryCount[this.selectedFilter].typeLinkingOptimum += 1;
    else if (state === 'null') this.glossaryCount[this.selectedFilter].typeLinkingDisconnected += 1;
    else this.glossaryCount[this.selectedFilter].typeLinkingNull += 1;
  }

  private neighborsCountingUrlImgMappping(
    neighborsQty: number,
    state: string,
    deviceLocationType: string,
    deviceProtocolType: string,
    panel: Panel,
    forceGrayScale
  ) {
    let levelType;
    if (neighborsQty == 1 && state != 'null') levelType = 'low';
    else if (neighborsQty == 2 && state != 'null') levelType = 'good';
    else if (neighborsQty >= 3 && state != 'null') levelType = 'optimum';
    else if (state == 'null') levelType = 'wine';
    else levelType = 'null';

    const protocolConverted = mapConstants.conectivityTylesConversor[deviceProtocolType];
    const iconStyles = this.checkEnableGrayscale(panel, forceGrayScale);
    return mapIcons.getIconCode(
      protocolConverted,
      deviceLocationType,
      levelType,
      true,
      iconStyles.grayscale,
      iconStyles.solid
    );
  }

  conectivityIconBuilder = (instalationType: string, deviceProtocolType: string, state: string) => {
    const protocolConverted = mapConstants.conectivityTylesConversor[deviceProtocolType];

    const folderStatusType = mapConstants.stateLocation[state];
    return mapIcons.getIconCode(protocolConverted, instalationType, folderStatusType, false);
  };

  meshFunctionIconBuilder = (instalationType: string, type: string, state: string) => {
    let deviceLocationType: string;
    if (instalationType === 'ExteriorLow') {
      deviceLocationType = mapConstants.iconTypes.ExteriorLow;
    } else if (instalationType === 'ExteriorHigh') {
      deviceLocationType = mapConstants.iconTypes.ExteriorHigh;
    } else {
      deviceLocationType = mapConstants.iconTypes.Interior;
    }

    const deviceProtocolType = type == mapConstants.iconTypes.MeshDevice ? 'linkRF' : 'linkMobile';

    const folderStatusType = mapConstants.stateLocation[state];
    return mapIcons.getIconCode(deviceProtocolType, deviceLocationType, folderStatusType, false);
  };

  linkStateIconBuilder = (
    instalationType: string,
    interfaceReport: string,
    state: string,
    panel: Panel,
    forceGrayScale
  ) => {
    let deviceLocationType: string;
    if (instalationType === 'ExteriorLow') {
      deviceLocationType = mapConstants.iconTypes.ExteriorLow;
    } else if (instalationType === 'ExteriorHigh') {
      deviceLocationType = mapConstants.iconTypes.ExteriorHigh;
    } else {
      deviceLocationType = mapConstants.iconTypes.Interior;
    }

    const deviceProtocolType = this._extractDeviceProtocolType(interfaceReport, panel);

    /**
     * converts the device protocol category to a category of the icons
     */
    const protocolConverted = mapConstants.conectivityTylesConversor[deviceProtocolType];
    let folderStatusType = mapConstants.stateLocation[state];
    const iconStyles = this.checkEnableGrayscale(panel, forceGrayScale);
    return mapIcons.getIconCode(
      protocolConverted,
      deviceLocationType,
      folderStatusType,
      false,
      iconStyles.grayscale,
      iconStyles.solid
    );
  };

  installationIconBuilder = (deviceLocationType: string, state: string) => {
    const folderStatusType = mapConstants.stateLocation[state];
    return mapIcons.getIconCode('linkRF', deviceLocationType, folderStatusType, false);
  };

  public _calculateGlosaryByViewTypeAndMark = (panel: Panel, selectedFilter: string) => {
    let deviceProtocolType: string;
    let deviceLocationType: string;

    const instalationType = panel.map?.instalationType;
    const interfaceReport = panel.map?.interfaceReport;

    switch (selectedFilter) {
      case mapConstants.viewTypes.Installation:
        deviceLocationType = this._extractDeviceLocatioType(instalationType);
        this.glossaryCount[selectedFilter][deviceLocationType]++;
        break;

      case mapConstants.viewTypes.Conectivity:
        deviceProtocolType = this._extractDeviceProtocolType(interfaceReport, panel);
        this.glossaryCount[selectedFilter][deviceProtocolType]++;
        break;

      default:
        break;
    }
  };

  public processSingleMarkIcon = async (panel, forceGrayScale = false) => {
    panel.index = this.getPriority(panel.map?.instalationType, panel.state, panel.map?.neighborsQty);

    let iconPath: string;
    let deviceProtocolType: string;
    let deviceLocationType: string;
    let type = mapConstants.iconTypes.Normal;

    const instalationType = panel.map?.instalationType;
    const interfaceReport = panel.map?.interfaceReport;

    switch (this.selectedFilter) {
      case mapConstants.viewTypes.None:
        type = mapConstants.iconTypes.Normal;
        panel.markType = type;
        this.glossaryCount[this.selectedFilter][panel.state]++;
        iconPath = this.linkStateIconBuilder(instalationType, interfaceReport, panel.state, panel, forceGrayScale);
        panel.img = { ...mapConstants.commonIconSize, url: iconPath };
        break;

      case mapConstants.viewTypes.Installation:
        if (instalationType === 'ExteriorLow') {
          deviceLocationType = mapConstants.iconTypes.ExteriorLow;
        } else if (instalationType === 'ExteriorHigh') {
          deviceLocationType = mapConstants.iconTypes.ExteriorHigh;
        } else {
          deviceLocationType = mapConstants.iconTypes.Interior;
        }

        panel.markType = deviceLocationType;
        this.glossaryCount[this.selectedFilter][deviceLocationType]++;
        iconPath = this.installationIconBuilder(deviceLocationType, panel?.state);
        panel.img = { ...mapConstants.commonIconSize, url: iconPath };
        break;

      case mapConstants.viewTypes.Conectivity:
        deviceProtocolType = this._extractDeviceProtocolType(interfaceReport, panel);

        panel.markType = deviceProtocolType;
        this.glossaryCount[this.selectedFilter][deviceProtocolType]++;
        iconPath = this.conectivityIconBuilder(instalationType, deviceProtocolType, panel?.state);
        panel.img = { ...mapConstants.commonIconSize, url: iconPath };
        break;

      case mapConstants.viewTypes.MeshFunction:
        if (
          !panel.map ||
          panel.map.parent ||
          !panel.map.interfaceReport ||
          panel.map.interfaceReport === this.interfaceTypes.rf ||
          !this.parentDevices.includes(panel.mac)
        ) {
          type = mapConstants.iconTypes.MeshDevice;
        } else {
          type = mapConstants.iconTypes.Gateway;
        }

        panel.markType = type;
        this.glossaryCount[this.selectedFilter][type]++;
        iconPath = this.meshFunctionIconBuilder(instalationType, type, panel?.state);
        panel.img = { ...mapConstants.commonIconSize, url: iconPath };
        break;

      case mapConstants.viewTypes.ViewLinking:
        if (instalationType === 'ExteriorLow') {
          deviceLocationType = mapConstants.iconTypes.ExteriorLow;
        } else if (instalationType === 'ExteriorHigh') {
          deviceLocationType = mapConstants.iconTypes.ExteriorHigh;
        } else {
          deviceLocationType = mapConstants.iconTypes.Interior;
        }

        deviceProtocolType = this._extractDeviceProtocolType(interfaceReport, panel);

        /**
         * Handle ViewLinking's glossary countings
         */
        this.setNeighborCount(panel.map?.neighborsQty, panel?.state);
        /**
         * Handle ViewLinking's icons
         */
        panel.markType = type;
        iconPath = this.neighborsCountingUrlImgMappping(
          panel.map?.neighborsQty,
          panel?.state,
          deviceLocationType,
          deviceProtocolType,
          panel,
          forceGrayScale
        );
        panel.img = { ...mapConstants.commonIconSize, url: iconPath };
        break;

      default:
        break;
    }
  };

  getInstallType = (m: Panel) => {
    if (m.map?.instalationType === 'ExteriorLow') {
      return mapConstants.iconTypes.ExteriorLow;
    } else if (m.map?.instalationType === 'ExteriorHigh') {
      return mapConstants.iconTypes.ExteriorHigh;
    }
    return mapConstants.iconTypes.Interior;
  };

  private readonly getPanels = async () => {
    this.panelsService.isLoadingResults = true;
    this.statusControl = {
      default: {
        optimum: 0,
        good: 0,
        low: 0,
        null: 0,
        total: 0,
      },
    };
    this.onGetReceivers();
    if (!this.marksByReceiver[mapConstants.defaultReceiver]) this.marksByReceiver[mapConstants.defaultReceiver] = [];
    this.panelsService.getPanels();
  };

  private readonly calculateZoom = (maxLng, minLng, maxLat, minLat, center) => {
    let zoom;
    // Define zoom
    if (maxLng === minLng && maxLat === minLat) {
      // For a unique location
      zoom = 3;
    } else {
      let mapdisplay = 322; //min of height and width of element which contains the map
      let dist =
        6371 *
        Math.acos(
          Math.sin(minLat / 57.2958) * Math.sin(maxLat / 57.2958) +
            Math.cos(minLat / 57.2958) * Math.cos(maxLat / 57.2958) * Math.cos(maxLng / 57.2958 - minLng / 57.2958)
        );

      zoom = Math.floor(8 - Math.log((1.6446 * dist) / Math.sqrt(2 * (mapdisplay * mapdisplay))) / Math.log(2));

      if (zoom > 15) {
        zoom = 15;
      } else if (zoom < 3) {
        zoom = 3;
      }
    }

    this.customMapService.saveCenter(zoom, center);
    this.panelsService.isLoadingResults = false;
  };

  public configureMap = (navigateData = undefined) => {
    if (navigateData) {
      if (!this.receiversService.selectedReceiver.includes(navigateData.apiKey)) {
        this.receiversService.selectedReceiver = [mapConstants.defaultReceiver];
      }

      // Filtro desde listado de mac
      const panel = this.rendererMarKList.find((p) => p.mac === navigateData.mac);
      this.selectedPanel = panel;
      this.customMapService.saveCenter(SELECTED_MAC_ZOOM, { lat: panel.lat, lng: panel.lng });
      this.customMapService.setSelectedMark({ lat: panel.lat, lng: panel.lng });
      return;
    }

    // Define max/min latitude and longitude
    const receiverPanels = this.rendererMarKList;
    if (receiverPanels?.length > 0) {
      let maxLng = receiverPanels[0].lng;
      let minLng = receiverPanels[0].lng;
      let maxLat = receiverPanels[0].lat;
      let minLat = receiverPanels[0].lat;
      for (let i = 1; i < receiverPanels.length; i++) {
        if (receiverPanels[i].lng > maxLng) {
          maxLng = receiverPanels[i].lng;
        } else if (receiverPanels[i].lng < minLng) {
          minLng = receiverPanels[i].lng;
        }

        if (receiverPanels[i].lat > maxLat) {
          maxLat = receiverPanels[i].lat;
        } else if (receiverPanels[i].lat < minLat) {
          minLat = receiverPanels[i].lat;
        }
      }

      // Define Center
      const centerLat = (maxLat + minLat) / 2;
      const centerLng = (maxLng + minLng) / 2;

      this.calculateZoom(maxLng, minLng, maxLat, minLat, { lat: centerLat, lng: centerLng });
    } else {
      // Default Center
      this.customMapService.setDefaultOptions();
    }
  };

  public updatePanelLocation = (panel) => {
    const newLocation = this.customMapService.onClickEventLocation;
    panel.lat = newLocation.lat;
    panel.lng = newLocation.lng;

    const callback = (res, params) => {
      if (res.success) {
        // Update panel Mark
        this.updateCurrentMarkList();
        this.markPanelAsSelected(panel);
        this.snackBar.open(
          this.translateService.instant('MeshNet.SetLocationSuccess'),
          this.translateService.instant('Shared.Close'),
          { duration: this.snackBarDuration }
        );
      }
    };
    // Update DB
    const params = [panel._id, panel.lat, panel.lng];
    this.panelApi.httpRequest(this.panelApi.setLocation, params, callback, {}, 'MeshNet.FailedSetLocation');
  };

  public parseInterfaceReport = async (panel) => {
    if (panel.map?.interfaceReport) {
      panel.map.interfaceReport = panel.map.interfaceReport.toLowerCase();
    }
  };

  public getPanelFunction = (m) => {
    if (
      !m.map ||
      m.map.parent ||
      !m.map.interfaceReport ||
      m.map.interfaceReport === this.interfaceTypes.rf ||
      !this.parentDevices.includes(m.mac)
    ) {
      return 'MeshPanel';
    }
    return 'GatewayPanel';
  };

  // Path & Neighbor Functions
  public processPanelPath = (panel) => {
    this.mapPanelMark = panel.mac;
    const pathMarkArray = [];
    panel.map.path = [];
    let node = panel;
    while (node && !pathMarkArray.find((m) => m.mac === node.mac)) {
      if (panel.mac != node.mac) panel.map.path.push(node.mac);
      const newNode = {
        mac: node.mac,
        position: {
          lat: node.lat,
          lng: node.lng,
        },
        icon: undefined,
      };

      if (!node.map?.parent) {
        // Gateway
        newNode.icon = mapConstants.iconPath.typeGateway;
      } else if (panel.mac === node.mac) {
        // Selected
        newNode.icon = mapConstants.iconPath.typeSelected;
      } else {
        // Node
        newNode.icon = mapConstants.iconPath.typeNode;
      }

      pathMarkArray.push(newNode);
      node = node.map?.parent ? this.rendererMarKList.find((p) => p.mac === node.map?.parent) : undefined;
    }
    this.customMapService.setSelectedPath(pathMarkArray, mapConstants.PathTypes.Path);
  };

  clickedMarker = (panel = null) => {
    if (!panel) panel = this.savedPanelClicked;

    if (this.savedPanelClicked && this.savedPanelClicked._id === panel._id) {
      // Set
      this.savedPanelClicked = null;
      this.markPanelAsSelected(panel);
      return;
    }

    this.markPanelAsSelected(panel);
    this.savedPanelClicked = panel;
  };

  public markPanelAsSelected = (panel, dsRipost = false) => {
    if (!panel?.mac) return;

    if (dsRipost) {
      // Delete Saved panel on focus and assume 'reset' of the clicked on focus panel
      // This is a state control for 'showPath' and 'showNeighbors'
      this.savePanelOnFocus = undefined;
    }

    this.clearMap(false);
    this.customMapService.setSelectedMark({
      lat: panel.lat,
      lng: panel.lng,
    });
    if (this.savePanelOnFocus && this.savePanelOnFocus._id === panel._id) {
      this.savePanelOnFocus = undefined;
      return;
    }

    this.savePanelOnFocus = panel;
  };

  public clearSelectedMarker = (panel) => {
    if (!panel?.mac) return;

    this.customMapService.setSelectedMark({
      lat: panel.lat,
      lng: panel.lng,
    });

    this.savePanelOnFocus = undefined;

    this.clearMap(false);
  };

  public processPanelNeighbor = (panel) => {
    this.mapPanelMark = panel.mac;
    const mapNeighbor = [];
    const pathMarkArray = [];

    // Set selected mark
    pathMarkArray.push({
      position: {
        lat: panel.lat,
        lng: panel.lng,
      },
      icon: mapConstants.iconPath.typeSelected,
    });

    for (const neighbor of panel.map.neighbors) {
      // Set Neighbors mark
      const node = this.allMarksList.find((p: PanelMapMark) => p.mac === neighbor);
      if (node) {
        pathMarkArray.push({
          position: {
            lat: node.lat,
            lng: node.lng,
          },
          icon: mapConstants.iconPath.typeNeighbor,
        });

        // For each neigbor add a new path from selected to neighbor
        mapNeighbor.push({ lat: node.lat, lng: node.lng });
        mapNeighbor.push({ lat: panel.lat, lng: panel.lng });
      }
    }
    this.customMapService.setSelectedPath(pathMarkArray, mapConstants.PathTypes.Neighbor, mapNeighbor);
    this.processMarkIcons();
  };

  public checkEnableGrayscale = (panel, forceGrayScale) => {
    if (forceGrayScale) {
      return {
        grayscale: true,
        solid: false,
      };
    }
    if (this.customMapService.showPanelPath?.type === mapConstants.PathTypes.Neighbor) {
      // Showing Neighbors
      // Check selected panel or neighbor of the selected panel
      const grayscale = !(panel.mac === this.selectedPanel.mac || this.selectedPanel.map.neighbors.includes(panel.mac));
      return {
        grayscale,
        solid: !grayscale,
      };
    }
    return {
      grayscale: false,
      solid: false,
    };
  };

  public clearMap = (fullClean = true) => {
    if (fullClean) {
      this.statusControl = {};
      this.statusControl[mapConstants.defaultReceiver] = {
        optimum: 0,
        good: 0,
        low: 0,
        null: 0,
        total: 0,
      };
      this.onGetReceivers();

      this.marksByReceiver = {};
      this.marksByReceiver[mapConstants.defaultReceiver] = [];
      this.customMapService.currentMarkList$ = undefined;
      this.selectedPanel = undefined;
      this.savePanelOnFocus = undefined;
      this.glossaryCount = this.defaultGlossary();
    }

    this.customMapService.clearPath();
    this.mapPanelMark = undefined;
  };

  public resetMapSelection = () => {
    this.selectedPanel = undefined;
    this.saveLastPanel = undefined;
    this.customMapService.unsetSelectedMark();
    this.hoverMenuesService.onCloseSideMenu();
    this.clearMap(false);

    this.processMarkIcons();
  };

  clearAfterClosingLeftSidePanel = () => {
    this.clearSelectedMarker(this.savedPanelClicked);
    this.savedPanelClicked = null;
  };

  // Search Bar Functions
  public applyFilter = (filterValue: string) => {
    if (this.checkCoords(filterValue)) {
      return;
    }

    this.filteredDevices = [];

    if (filterValue != undefined && filterValue.length > 0) {
      const filter = filterValue.trim().toLowerCase();

      for (const panel of this.rendererMarKList) {
        this._processPanelInTheSearchFilter(panel, filter);
      }
    }

    return this.filteredDevices;
  };

  private _processPanelInTheSearchFilter(panel: any, filter: string) {
    const mac = panel.mac && panel.mac.trim().toLowerCase().indexOf(filter) !== -1 ? panel.mac : undefined;
    const receiver =
      panel.receiverName && panel.receiverName.trim().toLowerCase().indexOf(filter) !== -1
        ? panel.receiverName
        : undefined;
    const partition = panel.partitions.find(
      (p: { account: string; name: string }) =>
        p.account.toLowerCase().toLowerCase().indexOf(filter) !== -1 ||
        p.name.toLowerCase().toLowerCase().indexOf(filter) !== -1
    );
    const address =
      panel.map?.address && panel.map.address.trim().toLowerCase().indexOf(filter) !== -1
        ? panel.map.address
        : undefined;

    this._buildTextOfTheSearchResult(mac, receiver, partition, address, panel);
  }

  private _buildTextOfTheSearchResult(mac: any, receiver: any, partition: any, address: any, panel: any) {
    if (!(mac || receiver || partition || address)) {
      return;
    }

    let text = panel.mac;

    if (receiver) {
      text += `-${receiver}`;
    } else if (partition) {
      text += `-${partition.account} ${partition.name}`;
    } else if (address) {
      text += `-${address}`;
    }

    this.filteredDevices.push({
      label: text,
      panel,
    });
  }

  public selectPanel = (panel) => {
    this.customMapService.saveCenter(SELECTED_MAC_ZOOM, { lat: panel.lat, lng: panel.lng });
    this.customMapService.setSelectedMark({ lat: panel.lat, lng: panel.lng });

    this.selectedPanel = panel;

    // Here panelClickedOnGroupedMacs: true because we dont know if its grouped macs
    // or a isolated mark on the map we assume its both and panelClickedOnGroupedMacs: true
    // to always open the left side panel either way
    this.eventService.selectionUpdatedEvent.emit({
      _id: this.selectedPanel._id,
      panelClickedOnGroupedMacs: true,
      closePanel: false,
    });
  };

  public filterAndSearch = (filterValue: string) => {
    const list = this.applyFilter(filterValue);
    this.selectPanel(list[0].panel);
  };

  public checkCoords = (filterValue: string) => {
    if (filterValue?.length <= 0) return false;

    const tentativeCoords = filterValue.split(',');
    const lat = parseFloat(tentativeCoords[0]);
    const lng = parseFloat(tentativeCoords[1]);
    if (tentativeCoords.length === 2 && !isNaN(lat) && !isNaN(lng)) {
      this.customMapService.saveCenter(SELECTED_MAC_ZOOM, { lat, lng });
      this.customMapService.setSelectedMark({ lat, lng });
      return true;
    }
    return false;
  };

  // Receiver Filter Functions
  public clearReceiver = async (reloadMarks) => {
    this.receiversService.selectedReceiver = [mapConstants.defaultReceiver];
    if (reloadMarks) await this.processMarkIcons();

    this.eventService.breadcrumbedReceptor.emit({
      type: BreadCrumberChannelTypes.NEWFILTER,
      breadcrumb: undefined,
      filterName: undefined,
    });
  };

  public isViewLinking = () => {
    return this.selectedFilter == mapConstants.viewTypes.ViewLinking;
  };

  public isNotViewNone = () => {
    return this.selectedFilter != mapConstants.viewTypes.None;
  };

  public totalCount = () => {
    return (
      this.glossaryCount.ViewLinking.typeLinkingDisconnected +
      this.glossaryCount.ViewLinking.typeLinkingNull +
      this.glossaryCount.ViewLinking.typeLinkingLow +
      this.glossaryCount.ViewLinking.typeLinkingGood +
      this.glossaryCount.ViewLinking.typeLinkingOptimum
    );
  };

  public neighborCountFor = (category) => {
    switch (category) {
      case 'disconnected':
        return this.glossaryCount.ViewLinking.typeLinkingDisconnected;
      case 'null':
        return this.glossaryCount.ViewLinking.typeLinkingNull;
      case 'low':
        return this.glossaryCount.ViewLinking.typeLinkingLow;
      case 'good':
        return this.glossaryCount.ViewLinking.typeLinkingGood;
      case 'optimum':
        return this.glossaryCount.ViewLinking.typeLinkingOptimum;
      default:
        return this.glossaryCount.ViewLinking.typeLinkingDisconnected;
    }
  };

  private _extractDeviceProtocolType(interfaceReport: any, m: any) {
    let deviceProtocolType;
    const lowerCaseInterfaceReport = (interfaceReport || '').toLowerCase();

    if (lowerCaseInterfaceReport === this.interfaceTypes.wifi) {
      deviceProtocolType = m.link?.mobileStatus ? mapConstants.iconTypes.FullInternet : mapConstants.iconTypes.WIFI;
    } else if (lowerCaseInterfaceReport === this.interfaceTypes.ethernet) {
      deviceProtocolType = m.link?.mobileStatus ? mapConstants.iconTypes.FullInternet : mapConstants.iconTypes.Ethernet;
    } else if (lowerCaseInterfaceReport === this.interfaceTypes.rf) {
      deviceProtocolType = mapConstants.iconTypes.RF;
    } else if (
      lowerCaseInterfaceReport === this.interfaceTypes.mobile ||
      lowerCaseInterfaceReport === this.interfaceTypes.mobile2g ||
      lowerCaseInterfaceReport === this.interfaceTypes.mobile3g ||
      lowerCaseInterfaceReport === this.interfaceTypes.mobile4g
    ) {
      deviceProtocolType =
        m.link?.wifiStatus || m.link?.ethStatus ? mapConstants.iconTypes.FullInternet : mapConstants.iconTypes.Mobile;
    } else {
      deviceProtocolType = mapConstants.iconTypes.RF;
    }

    return deviceProtocolType;
  }

  private _extractDeviceLocatioType(instalationType: any) {
    let deviceLocationType;
    if (instalationType === 'ExteriorLow') {
      deviceLocationType = mapConstants.iconTypes.ExteriorLow;
    } else if (instalationType === 'ExteriorHigh') {
      deviceLocationType = mapConstants.iconTypes.ExteriorHigh;
    } else {
      deviceLocationType = mapConstants.iconTypes.Interior;
    }
    return deviceLocationType;
  }

  private defaultGlossary() {
    return {
      ViewNone: {
        optimum: 0,
        good: 0,
        low: 0,
        null: 0,
      },
      ViewInstallation: {
        typeInterior: 0,
        typeExteriorLow: 0,
        typeExteriorHigh: 0,
        typeExteriorFilter: 0,
      },
      ViewConectivity: {
        typeRF: 0,
        typeWIFI: 0,
        typeEthernet: 0,
        typeMobile: 0,
        typeMobile2g: 0,
        typeMobile3g: 0,
        typeMobile4g: 0,
        typeFullInternet: 0,
      },
      ViewMeshFunction: {
        typeMeshDevice: 0,
        typeGateway: 0,
      },
      ViewLinking: {
        typeLinkingNull: 0,
        typeLinkingLow: 0,
        typeLinkingGood: 0,
        typeLinkingOptimum: 0,
        typeLinkingDisconnected: 0,
      },
    };
  }

  setSelectedPanel(panel: PanelMapMark) {
    this.selectedPanel = panel;
    const { _id } = panel;
    this.saveLastPanel = { _id, panelClickedOnGroupedMacs: true, closePanel: false };
    this.customMapService.activateHandleZoomRestoration = true;
  }

  goToMap = (mac, apiKey) => {
    const selectedPanel = this.rendererMarKList.find(
      (panel: PanelMapMark) => panel.mac === mac && panel.apiKey === apiKey
    );
    if (selectedPanel) {
      this.setSelectedPanel(selectedPanel);
      this.router.navigate(['/maps'], { queryParams: { mac, apiKey, recoverSelected: true } });
    }
  };
}
