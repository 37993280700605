<div class="loading" *ngIf="companiesService.loading">
    <mat-spinner></mat-spinner>
</div>
<div class="mainContainer" fxLayout="column" fxLayoutGap="15px" (click)="onClickOut($event)" onKeyPress onKeyDown onKeyUp>
    <div fxLayout="row" fxLayoutGap="20px" class="filters disable-click">
        <div fxLayout="row" fxLayoutGap="20px" class="center">
            <p class="section-title" [ngClass]="{ 'title-grey': !checkSection(sections.Info) }">{{ 'Settings.Settings' | translate }}</p>
            <mat-icon *ngIf="!checkSection(sections.Info)" class="icon-title title-grey center">chevron_right</mat-icon>
            <p *ngIf="!checkSection(sections.Info)" class="section-title" [ngClass]="{ 'title-grey': companiesService.screen !== steps.INFO }">
                {{ 'Settings.' + companiesService.currentSection | translate }}
            </p>
            <mat-icon *ngIf="!checkSection(sections.Info)" class="icon-title title-grey center">chevron_right</mat-icon>
            <p *ngIf="!checkSection(sections.Info)" class="section-title" [ngClass]="{ 'title-grey': companiesService.screen !== steps.USERS }">
                {{ 'Settings.ReceiverName' | translate }}
            </p> 
        </div>
        <button 
          *ngIf="companiesService.settingsAvailableRoles.includes(authService.user.role)"
          class="button-filter enable-click" 
          style="color: black" 
          mat-raised-button color="primary" 
          (click)="newCompany()"
        >
          {{ 'Settings.NewCompany' | translate }}
        </button>
    </div>
    <app-company-info *ngIf="checkSection(sections.Info)"></app-company-info>
    <app-company-edit class="container-edit disable-click" *ngIf="checkSection(sections.Edit) || checkSection(sections.Create)"></app-company-edit>
</div>
<ul
    *ngIf="contextualMenuService.currentContextualItem?.expand"
    [ngStyle]="{'position': 'absolute', 'top.px': contextualMenuService.menuPosition.top, 'left.px': contextualMenuService.menuPosition.left}"
    id="menu-contextual"
    class="button-container"
>
    <button
        *ngFor="let p of contextualMenuService.getMenuContent()"
        class="table-row"
        (click)="contextualMenuService.setPermission(p)"
        (keypress)="contextualMenuService.setPermission(p)"
        matTooltipShowDelay="750"
        matTooltipPosition="after"
    >
        {{ ('Settings.UserLevel.' + p) | translate }}
    </button>
</ul>