<mat-card class="container">
    <mat-card-header>
        <mat-card-title>
        {{'TechSupport.Configurations' | translate}}
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div>
            <div>
                <!-- Row 1 -->
                <div style="margin: 10px; border-color: primary; display: flex; justify-content: space-around;">
                    <button mat-raised-button color="primary" (click)="onNewCommandClick()"> {{'TechSupport.NewCommand' | translate}} </button>
                    <button *ngIf="receiversService.receivers && receiversService.receivers.length > 0" mat-raised-button color="primary" (click)="addReceiverSwich()">
                        {{'TechSupport.RecevierSwitch' | translate}} </button>
                </div>
            </div>

            <!-- Divisor -->
            <div class="divider-line" style="height: 1%;"></div>

            <!-- Listado de comandos agregados -->
            <div style="overflow-y: scroll; height: 430px; width: 100%" class="scrollbar">
                <table class="table table-bordered table-sm table-hover" style="width: 100%;">
                    <thead>
                        <tr class="d-flex" style="font-size: 15px">
                            <th scope="col" class="col-1 text-sm-left align-left"></th> <!-- Move Down/Up -->
                            <th scope="col" class="col-1 text-sm-left align-left">  <!-- Enable/Disable -->
                                <mat-checkbox
                                style="margin-top: 10px; margin-left: 1px; margin-bottom: 5px;"
                                color="primary" class="user-check"
                                [(ngModel)]="sellectAll"
                                (change)="selectAllToSend()"
                                type="checkbox"
                              ></mat-checkbox>
                            </th>
                            <th scope="col" class="col-3 text-sm-left align-left">{{'TechSupport.Commands' | translate}}</th>
                            <th scope="col" class="col-1 text-sm-right align-right icon-column"></th> <!-- Edit -->
                            <th scope="col" class="col-1 text-sm-right align-right icon-column">
                                <button
                                    mat-icon-button
                                    matTooltip="{{'TechSupport.removeAll' | translate}}"
                                    matTooltipShowDelay="750"
                                    matTooltipPosition="after"
                                    (click)="onRemoveAllCommands()"
                                    aria-hidden="false"
                                > <mat-icon class="mat-icon-button-sm">close</mat-icon> </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody style="font-size: 14px; height: 100%; " >
                        <ng-container *ngFor="let command of getCommandList()">
                            <tr class="d-flex">
                                <td class="col-1 text-sm-left align-left">
                                    <button
                                        mat-icon-button
                                        matTooltip="{{'TechSupport.moveUp' | translate}}"
                                        matTooltipShowDelay="750"
                                        matTooltipPosition="after"
                                        (click)="onMove(command, -1)"
                                        aria-hidden="false"
                                    > <mat-icon class="mat-icon-button-sm">arrow_drop_up</mat-icon> </button>
                                    <button
                                        mat-icon-button
                                        matTooltip="{{'TechSupport.moveDown' | translate}}"
                                        matTooltipShowDelay="750"
                                        matTooltipPosition="after"
                                        (click)="onMove(command, 1)"
                                        aria-hidden="false"
                                    > <mat-icon class="mat-icon-button-sm">arrow_drop_down</mat-icon> </button>
                                </td>
                                <td class="col-2 text-sm-left align-left">  
                                    <mat-checkbox
                                      style="margin-top: 10px; margin-left: 1px; margin-bottom: 5px;"
                                      color="primary" class="user-check"
                                      [(ngModel)]="command.enable"
                                      type="checkbox"
                                      (change)="checkAll()" 
                                    ></mat-checkbox>
                                </td>
                                <td class="col-2 text-sm-left align-left"> {{command.name}} </td>
                                <td class="col-1 text-sm-right align-right icon-column">
                                    <button
                                        mat-icon-button
                                        matTooltip="{{'TechSupport.Edit' | translate}}"
                                        matTooltipShowDelay="750"
                                        matTooltipPosition="after"
                                        (click)="onEditCommand(command)"
                                        aria-hidden="false"
                                    > <mat-icon class="mat-icon-button-sm">{{isEnabledCommand(command.id) ? 'edit' : ''}}</mat-icon> </button>
                                </td>
                                <td class="col-1 text-sm-right align-right icon-column">
                                    <button
                                        mat-icon-button
                                        matTooltip="{{'TechSupport.Remove' | translate}}"
                                        matTooltipShowDelay="750"
                                        matTooltipPosition="after"
                                        (click)="onRemoveCommand(command.index)"
                                        aria-hidden="false"
                                    > <mat-icon class="mat-icon-button-sm">close</mat-icon> </button>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <!-- Divisor -->
            <div class="divider-line" style="height: 1%;"></div>

            <div class="column-container" style="height: 24%">
                <!-- Row 1 -->
                <div style="border-color: primary; display: flex; justify-content:space-around;" class="selectorContainer">
                <div>
                    <button class="commandControlls" mat-icon-button matTooltip="{{'TechSupport.Start' | translate}}" (click)="onSend()">
                      <mat-icon style="padding: 0%;"> {{ getPlayIcon() }} </mat-icon>
                    </button>
                    <button class="commandControlls" mat-icon-button matTooltip="{{'TechSupport.Stop' | translate}}" (click)="onStop()">
                      <mat-icon style="padding: 0%;"> stop </mat-icon>
                    </button>
                    <button class="commandControlls" mat-icon-button matTooltip="{{'TechSupport.Restart' | translate}}" (click)="onReload()">
                      <mat-icon style="padding: 0%;"> replay </mat-icon>
                    </button>
                </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
