import { Component } from '@angular/core';
import { CustomMapService } from 'src/app/map/service/custom-map.service';
import { PanelsService } from 'src/app/shared/services/panels.service';

@Component({
  selector: 'app-map-type-switch',
  templateUrl: './map-type-switch.component.html',
  styleUrls: ['./map-type-switch.component.scss'],
})
export class MapTypeSwitchComponent {
  timeoutId;

  constructor(public customMapService: CustomMapService, public panelsService: PanelsService) {}

  public setMapType = (type: string) => {
    this.debounce(() => {
      this.customMapService.setMapType(type);
    });
  };

  public setPlaces = (value) => {
    this.debounce(() => {
      this.customMapService.placesShowSwitch(value);
    });
  };

  public toogleShowTerrain = (value: boolean) => {
    this.debounce(() => {
      this.customMapService.showTerrain = value;
    });
  };

  debounce = (f: Function) => {
    if (this.panelsService.isLoadingResults) return;

    this.panelsService.isLoadingResults = true;

    this.timeoutId = setTimeout(() => {
      clearTimeout(this.timeoutId);

      f();

      this.panelsService.isLoadingResults = false;
    }, 2000);
  };
}
