import { Component, Input, OnDestroy, HostListener } from '@angular/core';
import { KeyboardService } from '../../services/keyboard.service';
import { WebsocketService } from '../../services/websocket.service';
import { AcceptDialogComponent } from 'src/app/shared/accept-dialog/accept-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import constants from '../../constants';

class LedsState {
  on?: boolean;
  value?: number;
  blinkStatusInterval?: number;
}

@Component({
  selector: 'app-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss']
})
export class KeyboardComponent implements OnDestroy {
  constants = constants;

  public partitionNumber = '1';
  public multiPartitionState;
  public dateUnsetted = true;
  public hide = false;

  private keyboardDSCReportID = 132;
  private keyboardParadoxReportID = 184;
  private reportSubscription;

  public zoneLedStatus: LedsState[];
  public stateLedStatus: LedsState[];

  private disconectTimer;
  private timerCount = 0;

  public loading = true;
  public blockSend = true;

  private waitingReportTimeoutID;

  private snackBarDuration = 5000;
  private sequence;

  private activeSound;
  private activeArmSound;
  public soundState = true;
  public volumeValue = 100;

  public showVolumeSlider: boolean;

  private disconectionSubscription;
  private waitingReportTimeout;

  public timeDisplay;
  public dateDisplay;
  public stateDisplay;
  private clockTimer;
  private lastHourValue = '';

  private cpArmedPerTypes = ['20', '28', '60', '68'];

  public scale = 1;
  public width;
  public height;

  constructor(
    public keyboardService: KeyboardService,
    private socket: WebsocketService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private translateService: TranslateService
  ) {
    this.showVolumeSlider = false;
    this.activeSound = new Audio();
    this.activeArmSound = new Audio();
    this.keyboardService.keysToSend = '';

    // Si se registro un cambio de volumen se vuelve a levantar
    this.volumeValue = this.keyboardService.volume != undefined ? this.keyboardService.volume : 100;

    if (!keyboardService.mac) {
      this.router.navigateByUrl('/partitions');
    } else {
      // Se inicializan los leds apagados
      this.stateLedStatus = [];
      for (let i = 0; i < 10; i++) { // System Leds: 3 - DSC, 10 - Paradox
        this.stateLedStatus[i] = { on: false };
      }
      this.zoneLedStatus = [];
      for (let i = 0; i < 32; i++) { // 32 Zones
        this.zoneLedStatus[i] = { on: false };
      }

      // Se inicia la escucha de reportes de Teclado (0x0C)
      this.socket.initMPListener();
      this.reportSubscription = this.socket.listenEvent.subscribe((message) => {
        if (message.decodification 
          && this.keyboardService.mac === message.device.mac
        ) {
          if (message.decodification.id === this.keyboardDSCReportID) {
            if (this.keyboardService.keyboardType != constants.keyboardTypes.DSC) {
              this.keyboardService.keyboardType = constants.keyboardTypes.DSC;
              this.processScale();
            }
            // Se verifica que la particion seleccionada venga en el reporte
            message.decodification.partitions.forEach((element: any) => {
              if (element.partition === parseInt(this.partitionNumber)) {
                // Si el mensaje recibido del equipo es Reporte de Teclado
                // Se finaliza la espera por reporte
                clearTimeout(this.waitingReportTimeoutID);
                this.loading = false;
                // Se procesa el reporte recibido
                this.processKeyboardDSCReport(message.decodification);
              }
            });
          } else if (message.decodification.id === this.keyboardParadoxReportID) {
            if (this.keyboardService.keyboardType != constants.keyboardTypes.Paradox) {
              this.keyboardService.keyboardType = constants.keyboardTypes.Paradox;
              this.processScale();
            }
            if (!this.multiPartitionState) this.multiPartitionState = [ '../../../assets/img/alarm-disarm.png', '../../../assets/img/alarm-disarm.png'];
            clearTimeout(this.waitingReportTimeoutID);
            this.loading = false;
            this.processKeyboardParadoxReport(message.decodification);
          }
        }
      });

      // Se inicia conexión con el teclado
      this.startKeyboardConnection();
    }
  }

  @Input() disabled: boolean;
  @Input() spin: boolean;

  ngOnDestroy() {
    // Al cerrar o cambiar de pantalla se detienen los Timers y suscripciones para Control de Desconexión
    clearTimeout(this.disconectTimer);
    clearTimeout(this.waitingReportTimeout);
    if (this.disconectionSubscription) {
      this.disconectionSubscription.unsubscribe();
    }

    // Se detiene la subsciption a reportes de teclado
    if (this.reportSubscription) {
      this.reportSubscription.unsubscribe();
    }

    // Se desuscribe a los comandos de la MAC utilizada
    this.socket.unsubscribe();

    // Se detienen los audios en ejecución
    this.activeArmSound.pause();
    this.activeArmSound.loop = false;
    this.activeSound.pause();
    this.activeSound.loop = false;

    // Se detiene el incremento de reloj
    clearInterval(this.clockTimer);

    // Se registra el valor de volumen seteado
    this.keyboardService.volume = this.soundState ? this.volumeValue : 0;
  }

  ngOnInit() {
    this.processScale();
    this.translateService.onLangChange.subscribe(async (event: LangChangeEvent) => {
      this.keyboardService.keysToSend = await this.translateService.get('Keyboard.' + this.keyboardService.specialKey).toPromise();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.processScale();
  }

  processScale = () => {
    const height = window.innerHeight - 100 - 20;

    if (this.keyboardService.keyboardType === constants.keyboardTypes.Paradox) {
      this.scale = height / 1071;
      this.height = (1071 * this.scale) + 'px';
      this.width = (1001 * this.scale) + 'px';
    } else if (this.keyboardService.keyboardType === constants.keyboardTypes.DSC) {
      this.scale = height / 490;
      this.height = (490 * this.scale) + 'px';
      this.width = (700 * this.scale) + 'px';
    }
  }

  startKeyboardConnection = () => {
    // Se envia mensaje de consulta
    this.disconectionSubscription = this.keyboardService.sendCommand('', '00', this.partitionNumber).subscribe({
      next: (res: any) => {
        if (res.success) {
          // Respuesta correcta
          // Se inicia el timer de desconexión
          this.blockSend = false;
          this.restartConnectionTimer();
        } else {
          // Si aún recibo mensajes del equipo vuelvo a intentar
          this.waitingReportTimeout = setTimeout(() => {
            // Si no recibe mensajes del equipo en un lapso de 1 minuto se deja de esperar
            this.disconectionSubscription.unsubscribe();
            // Aviso fallo de conexion con el teclado
            this.deviceDisconectionMessage();
          }, 300000); // 5 minutos de espera por mensaje desde el equipo

          this.disconectionSubscription = this.socket.listenEvent.subscribe((message) => {
            // Se deja de esperar mensajes del equipo
            clearTimeout(this.waitingReportTimeout);
            this.disconectionSubscription.unsubscribe();

            if (message.decodification && message.decodification.id === 131) {
              this.loading = false;
            }

            // Se envia segundo intento de consulta
            this.disconectionSubscription = this.keyboardService.sendCommand('', '00', this.partitionNumber).subscribe({
              next: (secondRes: any) => {
                this.loading = false;
                if (!secondRes.success) {
                  // Si 2do Timeout
                  // Aviso fallo de conexion con el teclado
                  this.deviceDisconectionMessage();
                } else {
                  this.blockSend = false;
                  // Respuesta correcta
                  // Se inicia el timer de desconexión
                  this.restartConnectionTimer();
                }
              },
              error: async (err: any) => {
                // En caso de que el servidor nunca responda se detiene el spinner de carga
                this.loading = false;
                this.blockSend = false;
                this.snackBar.open(await this.translateService.get('Keyboard.LostConnec').toPromise(), await this.translateService.get('Shared.Accept').toPromise(), { duration: this.snackBarDuration });
              }
            });
          });
        }
      },
      error: async (err: any) => {
        // En caso de que el servidor nunca responda se detiene el spinner de carga
        this.loading = false;
        this.blockSend = false;
        this.snackBar.open(await this.translateService.get('Keyboard.LostConnec').toPromise(), await this.translateService.get('Shared.Accept').toPromise(), { duration: this.snackBarDuration });
      }
    });
  }

  restartConnectionTimer = () => {
    this.timerCount++;
    // Se limpia el Timer existente (si lo hay)
    clearTimeout(this.disconectTimer);
    clearTimeout(this.waitingReportTimeout);
    this.disconectionSubscription.unsubscribe();
    // Se setea el nuevo timer en 5min
    this.disconectTimer = setTimeout(async () => {
      if (this.timerCount < 5) {
        this.startKeyboardConnection();
      } else {
        // En caso de cumplir dos minutos del quinto mensaje de consulta seguido sin interaccion con el usuario. (10 minutos)
        // Se silencian el sonido de Armado
        this.activeArmSound.loop = false;

        this.openConfirmDialog(
          await this.translateService.get('Keyboard.NotStatusInfo').toPromise(),
          await this.translateService.get('Shared.Accept').toPromise(),
          () => {}
        );
      }
    }, 120000); // Duración de dos minutos.
  }

  // Metodo para hacer parpadear Leds
  blinkStatus = (obj, ledIndex) => {
    // Se limpia el intervalo de parpadeo existente, si lo hubiera
    if (obj[ledIndex].blinkStatusInterval) clearInterval(obj[ledIndex].blinkStatusInterval);
    if (obj[ledIndex].value === 2) {
      obj[ledIndex].on = true;
      // Si el valor obtenido en el reporte, del led, es 2 se habilita un intervalo de 3/4 de segundo para encender y apagar
      obj[ledIndex].blinkStatusInterval = setInterval(() => {
        obj[ledIndex].on = !obj[ledIndex].on;
      }, 750);
    } if (obj[ledIndex].value === 3) {
      obj[ledIndex].on = true;
      // Si el valor obtenido en el reporte, del led, es 3 se habilita un intervalo de 4/10 de segundo para encender y apagar
      obj[ledIndex].blinkStatusInterval = setInterval(() => {
        obj[ledIndex].on = !obj[ledIndex].on;
      }, 200);
    } else {
      // Si el estado en el reporte no es 2
      // Se deja el led apagado o encendido según corresponda
      obj[ledIndex].on = obj[ledIndex].value === 1;
    }
  }

  // Metodo para determinar la visibilidad de las teclas ingresadas
  getType = () => {
    if (this.hide) {
      // Las teclas ingresadas se ocultan mostrando puntos.
      return 'password';
    }
    return '';
  }

  // Metodo para escritura de teclas a mano en el campo
  eventWriteKey = async (event: any) => {
    if (event.target.value.length <= 32) {
      if (this.keyboardService.keyboardType === constants.keyboardTypes.DSC) {
        this.keyboardService.keysToSend = event.target.value.toUpperCase().replace(/[^0-9*#APF]/g, '');
      } else if (this.keyboardService.keyboardType === constants.keyboardTypes.Paradox) {
        if (event.target.value != '') {
          let value = event.target.value[event.target.value.length -1];
          if ('MT'.includes(value)) value = value.toLowerCase(); // Only Lowercase
          if ('afpdq'.includes(value)) value = value.toUpperCase(); // Only Uppercase
          event.target.value = event.target.value.slice(0, -1) + value;
        }
        this.keyboardService.keysToSend = event.target.value.replace(/[^0-9elmorstvDELOQRSVAFP]/g, '');
      }
    }
    event.target.value = this.keyboardService.keysToSend;
  }

  getKeys = () => {
    return this.keyboardService.keysToSend;
  }

  // Metodo para borrar todas las teclas ingresadas
  deleteAll = () => {
    this.keyboardService.keysToSend = '';
    this.keyboardService.specialKey = undefined;
  }

  // Metodo para borrar la ultima tecla ingresada
  deleteLast = () => {
    if (this.keyboardService.specialKey && (this.keyboardService.specialKey === 'ArmTotal' || this.keyboardService.specialKey === 'ArmPerimeter')) {
      this.keyboardService.keysToSend = '';
      this.keyboardService.specialKey = undefined;
    } else {
      this.keyboardService.keysToSend = this.keyboardService.keysToSend.substring(0, this.keyboardService.keysToSend.length - 1);
    }
  }

  // Metodo para ocultar o desocultar las teclas ingresadas
  turnHide = () => {
    this.hide = !this.hide;
  }

  translateAndSend = (keys: string) => {
    let hexaKeys = '';

    if (this.keyboardService.specialKey === 'ArmTotal') {
      // Si esta activo Armado Total se envía 0D
      this.send('0D');
    } else {
      if (this.keyboardService.specialKey === 'ArmPerimeter') {
        // Si esta activo Armado Parcial se envía 0C
        this.send('0C');
      } else {
        // Si no estan activos los armados se envía el contenido del campo de texto tras su traducción.

        // Traduccion de teclas ingresadas a sus respectivos valores en hexadecimal
        for (let i = 0; i < keys.length; i++) {
          if (parseInt(keys[i], 10) >= 0 && parseInt(keys[i], 10) <= 9) {
            hexaKeys += '0' + keys[i];
          } else {
            if (this.keyboardService.keyboardType === constants.keyboardTypes.DSC) {
              switch (keys[i]) {
                case '*':
                  hexaKeys += '0A';
                  break;
                case '#':
                  hexaKeys += '0B';
                  break;
                case constants.dsc.FIRE:
                  hexaKeys += '0E';
                  break;
                case constants.dsc.MEDIC:
                  hexaKeys += '0F';
                  break;
                case constants.dsc.PANIC:
                  hexaKeys += '10';
                  break;
                default:
                  return { status: false, reason: 'Invalid Key value' };
              }
            } else if (this.keyboardService.keyboardType === constants.keyboardTypes.Paradox) {
              switch (keys[i]) {
                case constants.paradox.ON: // On
                  hexaKeys += '0A';
                  break;
                case constants.paradox.ONHOLD: // Hold On
                  hexaKeys += '0B';
                  break;
                case constants.paradox.TSL: // Fail
                  hexaKeys += '0C';
                  break;
                case constants.paradox.MEM: // Mem
                  hexaKeys += '0D';
                  break;
                case constants.paradox.BYP: // Exc
                  hexaKeys += '0E';
                  break;
                case constants.paradox.BYPHOLD: // Hold Exc
                  hexaKeys += '0F';
                  break;
                case constants.paradox.OFF: // Off
                  hexaKeys += '10';
                  break;
                case constants.paradox.OFFHOLD: // Hold Off
                  hexaKeys += '11';
                  break;
                case constants.paradox.STAY: // Stay
                  hexaKeys += '12';
                  break;
                case constants.paradox.STAYHOLD: // Hold Stay
                  hexaKeys += '13';
                  break;
                case constants.paradox.SLEEP: // Sleep
                  hexaKeys += '14';
                  break;
                case constants.paradox.SLEEPHOLD: // Hold Sleep
                  hexaKeys += '15';
                  break;
                case constants.paradox.ARM: // Arm
                  hexaKeys += '16';
                  break;
                case constants.paradox.ARMHOLD: // Hold Arm
                  hexaKeys += '17';
                  break;
                case constants.paradox.DELETE: // Delete
                  hexaKeys += '18';
                  break;
                case constants.paradox.ENTER: // Enter
                  hexaKeys += '19';
                  break;
                case constants.paradox.PANIC: // Panic
                  hexaKeys += '1A';
                  break;
                case constants.paradox.MEDIC: // Medic
                  hexaKeys += '1B';
                  break;
                case constants.paradox.FIRE: // Fire
                  hexaKeys += '1C';
                  break;
                default:
                  return { status: false, reason: 'Invalid Key value' };
              }
            }
          }
        }
        this.send(hexaKeys);
      }
    }
  }

  send = async (hexaKeys: string) => {
    // Se genera un valor de secuencia diferente al último usado, (Si se envio algun mensaje previamente)
    if (!this.sequence) {
      while (this.sequence === '00' || !this.sequence) {
        this.sequence = `0${Math.floor(Math.random() * (255)).toString(16)}`.slice(-2).toUpperCase();
      }
    } else {
      let newSequence = `0${Math.floor(Math.random() * (255)).toString(16)}`.slice(-2).toUpperCase();
      while (newSequence === '00' || newSequence === this.sequence) {
        newSequence = `0${Math.floor(Math.random() * (255)).toString(16)}`.slice(-2).toUpperCase();
      }
      this.sequence = newSequence;
    }

    // Se inicia la transicion de carga con el logo de citymesh, hasta el proximo reporte.
    this.blockSend = true;

    // Se envian las teclas ingresadas.
    this.keyboardService.sendCommand(hexaKeys, this.sequence, this.partitionNumber).subscribe({
      next: (res: any) => {
        this.blockSend = false;
        if (res.success) {
          // Respuesta correcta a comando 0xKK

          // Se reinicia el contador de envios seguidos por Timer y se vuelve a setear el mismo (5min)
          this.timerCount = 0;
          this.restartConnectionTimer();

          // Se avisa el exito del comando y espera al primer reporte
          this.successMessageSended();
        } else {
          // Fallo de envio del comando (Timeout)
          // Aviso fallo de conexion con el teclado
          this.deviceDisconectionMessage();
        }
      },
      error: async (err: any) => {
        // En caso de que el servidor nunca responda se detiene el spinner de carga del boton
        this.blockSend = false;
        this.snackBar.open(await this.translateService.get('Keyboard.LostConnec').toPromise(), await this.translateService.get('Shared.Accept').toPromise(), { duration: this.snackBarDuration });
      }
    });
  }

  successMessageSended = async () => {
    // Mensaje Exitoso
    this.snackBar.open(await this.translateService.get('Keyboard.SentMessage').toPromise(), await this.translateService.get('Shared.Accept').toPromise(), { duration: this.snackBarDuration });

    // Se vacia el campo de mensaje
    this.keyboardService.keysToSend = '';

    if (this.loading) {
      this.waitingReportTimeoutID = setTimeout(() => {
        // Si al cabo de un minuto no se recibe reporte
        this.blockSend = false;
        this.deviceDisconectionMessage();
      }, 30000);
    }
  }

  deviceDisconectionMessage = async () => {
    // Fallo de conexion con el teclado
    this.loading = false;
    this.blockSend = false;
    this.openConfirmDialog(
      await this.translateService.get('Keyboard.ErrorConnecDevice').toPromise(),
      await this.translateService.get('Shared.Accept').toPromise(),
      () => {}
    );
  }

  processKeyboardDSCReport = (dec) => {
    if (dec) {
      let partitionPos = 0;
      // SE verifica que la particion seleccionada venga informada en el reporte
      while (partitionPos < dec.reportedPartitions &&
        dec.partitions[partitionPos].partition !== parseInt((this.partitionNumber), 10)) {
        partitionPos++;
      }

      if (partitionPos < dec.reportedPartitions &&
        dec.partitions[partitionPos].partition === parseInt((this.partitionNumber), 10)) {
        const { systemLeds, zones, sound, armSound, mode } = dec.partitions[partitionPos];
        const { date } = dec;

        // Si la particion es correcta se detiene el spinner de carga en caso de estar ejecutandose
        this.loading = false;

        // Se actualizan los valores de los Leds de estado
        // 0: Apagado, 1: Encendido, 2: Parpadeante
        this.stateLedStatus = [
          { value: (systemLeds.ready ? systemLeds.ready : 0) },
          { value: (systemLeds.armed ? systemLeds.armed : 0) },
          { value: (systemLeds.system ? systemLeds.system : 0) },
        ];
        this.blinkStatus(this.stateLedStatus, 0);
        this.blinkStatus(this.stateLedStatus, 1);
        this.blinkStatus(this.stateLedStatus, 2);

        for (let i = 0; i < 32; i++) {
          // Para cada zona (1 a 32)
          // Si la zona esta incluida en el listado recibido del mensaje se da por encendida
          // Sino permanece apagada
          if (zones.includes(i + 1)) {
            this.zoneLedStatus[i] = { on: true };
          } else {
            this.zoneLedStatus[i] = { on: false };
          }
        }

        // Sound Processing
        this.activeArmSound.loop = false;
        this.setVolume(null, undefined);


        switch (sound) {
          case (0):
            this.activeSound.src = "../../../assets/sound/long beep.mp3";
            this.activeSound.load();
            this.activeSound.play();
            break;
          case (2):
            this.activeSound.src = "../../../assets/sound/double short beep.mp3";
            this.activeSound.load();
            this.activeSound.play();
            break;
          case (3):
            this.activeSound.src = "../../../assets/sound/3 short beep.mp3";
            this.activeSound.load();
            this.activeSound.play();
            break;
          case (4):
            this.activeSound.src = "../../../assets/sound/4 short beep.mp3";
            this.activeSound.load();
            this.activeSound.play();
            break;
          case (5):
            this.activeSound.src = "../../../assets/sound/5 short beep.mp3";
            this.activeSound.load();
            this.activeSound.play();
            break;
          default:
            break;
        }

        switch (armSound) {
          case ('Beep continuo'):
            this.activeArmSound.src = "../../../assets/sound/long beep 45s.mp3";
            this.activeArmSound.load();
            this.activeArmSound.loop = true;
            this.activeArmSound.play();
            break;
          case ('Beep intermitente rapido'):
            this.activeArmSound.src = "../../../assets/sound/double short beep.mp3";
            this.activeArmSound.load();
            this.activeArmSound.loop = true;
            this.activeArmSound.play();
            break;
          case ('Beep intermitente lento'):
            this.activeArmSound.src = "../../../assets/sound/slow Beep.mp3";
            this.activeArmSound.load();
            this.activeArmSound.loop = true;
            this.activeArmSound.play();
            break;
          default:
            this.activeArmSound.loop = false;
            this.activeArmSound.pause();
            break;
        }
        
        this.processKeyboardDate(date);

        switch (mode) {
          case '01':
          case '02':
          case '03':
          case '3D':
          case '3E':
          case '22':
            // En caso de modo: 01 - 02 - 03 - 3D - 3E - 22
            // Se mostrara estado Desarmado
            this.stateDisplay = '../../../assets/img/alarm-disarm.png';
            break;
          case '04':
            // En caso de modo: 04
            // Se mostrara estado Armado Perimetral
            this.stateDisplay = '../../../assets/img/alarm-arm-perimetral.png';
            break;
          case '05':
          case '06':
          case '09':
          case '16':
            // En caso de modo: 05 - 06 - 09 - 16
            // Se mostrara estado Armado Total
            this.stateDisplay = '../../../assets/img/alarm-arm-total.png';
            break;
          case '11':
          case '15':
            // En caso de modo: 11- 15
            // Se mostrara estado Disparado/Excluido
            this.stateDisplay = '../../../assets/img/alarm-arm-total_excluded.png';
            break;
          default:
            // En caso de que no llegue ninguno de estos modos se dejara el estado previamente establecido
            // Por defetco Desarmado
            break;
        }
      }
    }
  }

  processKeyboardParadoxReport = (dec) => {
    const { systemLeds, zones, sound, date, partitionState1, partitionState2 } = dec;
    if (systemLeds && zones && sound) {
      // System Leds
      if (this.stateLedStatus?.length >= 13) {
        this.stateLedStatus[0].value =  (systemLeds.arm1 ? systemLeds.arm1 : 0);
        this.stateLedStatus[1].value =  (systemLeds.sleep1 ? systemLeds.sleep1 : 0);
        this.stateLedStatus[2].value =  (systemLeds.stay1 ? systemLeds.stay1 : 0);
        this.stateLedStatus[3].value =  (systemLeds.off1 ? systemLeds.off1 : 0);
        this.stateLedStatus[4].value =  (systemLeds.arm2 ? systemLeds.arm2 : 0);
        this.stateLedStatus[5].value =  (systemLeds.sleep2 ? systemLeds.sleep2 : 0);
        this.stateLedStatus[6].value =  (systemLeds.stay2 ? systemLeds.stay2 : 0);
        this.stateLedStatus[7].value =  (systemLeds.off2 ? systemLeds.off2 : 0);
        this.stateLedStatus[8].value =  (systemLeds.fail ? systemLeds.fail : 0);
        this.stateLedStatus[9].value =  (systemLeds.mem ? systemLeds.mem : 0);
        this.stateLedStatus[10].value =  (systemLeds.exc ? systemLeds.exc : 0);
        this.stateLedStatus[11].value =  (systemLeds.ac ? systemLeds.ac : 0);
        this.stateLedStatus[12].value =  (systemLeds.stayD ? systemLeds.stayD : 0);
      } else {
        this.stateLedStatus = [
          { value: (systemLeds.arm1 ? systemLeds.arm1 : 0) },
          { value: (systemLeds.sleep1 ? systemLeds.sleep1 : 0) },
          { value: (systemLeds.stay1 ? systemLeds.stay1 : 0) },
          { value: (systemLeds.off1 ? systemLeds.off1 : 0) },
          { value: (systemLeds.arm2 ? systemLeds.arm2 : 0) },
          { value: (systemLeds.sleep2 ? systemLeds.sleep2 : 0) },
          { value: (systemLeds.stay2 ? systemLeds.stay2 : 0) },
          { value: (systemLeds.off2 ? systemLeds.off2 : 0) },
          { value: (systemLeds.fail ? systemLeds.fail : 0) },
          { value: (systemLeds.mem ? systemLeds.mem : 0) },
          { value: (systemLeds.exc ? systemLeds.exc : 0) },
          { value: (systemLeds.ac ? systemLeds.ac : 0) },
          { value: (systemLeds.stayD ? systemLeds.stayD : 0) }
        ];
      }
      for (let i = 0; i < this.stateLedStatus.length; i++) this.blinkStatus(this.stateLedStatus, i);

      // Zone Leds
      if (this.zoneLedStatus?.length >= 32) {
        for (let i = 0; i < 32; i++) {
          // Para cada zona (1 a 32)
          // De forma similar a los leds de sistema, se procesa el estado de los leds de zonas
          this.zoneLedStatus[i].value = zones[i+1];
          this.blinkStatus(this.zoneLedStatus, i);
        }
      } else {
        for (let i = 0; i < 32; i++) {
          // Para cada zona (1 a 32)
          // De forma similar a los leds de sistema, se procesa el estado de los leds de zonas
          this.zoneLedStatus[i] = { value: zones[i+1] };
          this.blinkStatus(this.zoneLedStatus, i);
        }
      }

      // Keyboard sound
      switch (sound) {
        case (0): // Beep largo
          this.activeSound.src = "../../../assets/sound/long beep.mp3";
          this.activeSound.load();
          this.activeSound.loop = false;
          this.activeSound.play();
          break;
        case (1): // Beep corto
          this.activeSound.src = "../../../assets/sound/slow Beep.mp3";
          this.activeSound.load();
          this.activeSound.loop = false;
          this.activeSound.play();
          break;
        case (2): // Double beep 
          this.activeSound.src = "../../../assets/sound/double short beep.mp3";
          this.activeSound.load();
          this.activeSound.loop = false;
          this.activeSound.play();
          break;
        case (3): // Double beep repating
          this.activeSound.src = "../../../assets/sound/double short beep.mp3";
          this.activeSound.load();
          this.activeSound.loop = true;
          this.activeSound.play();
          break;
        default: // Silence
          this.activeSound.loop = false;
          this.activeSound.pause();
          break;
      }

      switch (partitionState1) {
        case 'totalArm':
          // Total Arm
          this.multiPartitionState[0] = '../../../assets/img/alarm-arm-total.png';
          if (this.partitionNumber === '1') this.stateDisplay = this.multiPartitionState[0];
          break;
        case 'perimeterArm':
          // Perimetral Arm
          this.multiPartitionState[0] = '../../../assets/img/alarm-arm-perimetral.png';
          if (this.partitionNumber === '1') this.stateDisplay = this.multiPartitionState[0];
          break;
        case 'trigger':
          // Trigger/Excluded state
          this.multiPartitionState[0] = '../../../assets/img/alarm-arm-total_excluded.png';
          if (this.partitionNumber === '1') this.stateDisplay = this.multiPartitionState[0];
          break;
        case 'disarm':
        case 'delay':
          // Disarm
          this.multiPartitionState[0] = '../../../assets/img/alarm-disarm.png';
          if (this.partitionNumber === '1') this.stateDisplay = this.multiPartitionState[0];
          break;
        default:
          // By default it does not edit the last state
          break;
      }

      switch (partitionState2) {
        case 'totalArm':
          // Total Arm
          this.multiPartitionState[1] = '../../../assets/img/alarm-arm-total.png';
          if (this.partitionNumber === '2') this.stateDisplay = this.multiPartitionState[1];
          break;
        case 'perimeterArm':
          // Perimetral Arm
          this.multiPartitionState[1] = '../../../assets/img/alarm-arm-perimetral.png';
          if (this.partitionNumber === '2') this.stateDisplay = this.multiPartitionState[1];
          break;
        case 'trigger':
          // Trigger/Excluded state
          this.multiPartitionState[1] = '../../../assets/img/alarm-arm-total_excluded.png';
          if (this.partitionNumber === '2') this.stateDisplay = this.multiPartitionState[1];
          break;
        case 'disarm':
        case 'delay':
          // Disarm
          this.multiPartitionState[1] = '../../../assets/img/alarm-disarm.png';
          if (this.partitionNumber === '2') this.stateDisplay = this.multiPartitionState[1];
          break;
        default:
          // By default it does not edit the last state
          break;
      }

      this.processKeyboardDate(date);
    }
  }

  processKeyboardDate = (date) => {
    // Formato: 01/01/2000 01:01
    // Año: el reporte solo trae los ultimos dos digitos, message-interpteter agrega el 20 de 20xx para el formato del año
    // Es decir, aun si la fecha esta desconfigurada en el panel vendra un año '2000'
    if (date !== '00/00/2000 00:00') {
      if (date !== this.lastHourValue) {
        this.dateUnsetted = false;
        this.lastHourValue = date;

        this.dateDisplay = date.substring(0, 10);
        this.timeDisplay = date.substring(11, 16);
        clearInterval(this.clockTimer);
        this.clockTimer = setInterval(() => {
          let hour = parseInt(this.timeDisplay.substring(0, 2), 10);
          let minutes = parseInt(this.timeDisplay.substring(3, 5), 10);

          let day = parseInt(this.timeDisplay.substring(0, 2), 10);
          let month = parseInt(this.timeDisplay.substring(3, 5), 10);
          let year = parseInt(this.timeDisplay.substring(6, 10), 10);

          if (minutes === 59) {
            hour++;
            minutes = 0;
          } else {
            minutes++;
          }

          if (hour === 24) {
            day++;
            hour = 0;
          }

          switch (month) {
            case 2:
              // Febrero
              if ((year % 4 === 0 && day === 30) || (year % 4 !== 0 && day === 29)) {
                day = 1;
                month++;
              }
              break;
            case 4:
            case 6:
            case 9:
            case 11:
              // Abril, Junio, Septiembre, Noviembre
              if (day === 31) {
                day = 1;
                month++;
              }
              break;
            default:
              // Enero, Marzo, Mayo, Julio, Agosto, Octubre, Diciembre
              if (day === 32) {
                day = 1;
                month++;
              }
              break;
          }

          if (month === 12) {
            month = 0;
            year++;
          }

          this.timeDisplay = (hour < 10 ? '0' + hour : hour) + ':';
          this.timeDisplay = this.timeDisplay + (minutes < 10 ? '0' + minutes : minutes);
        }, 60000);
      }
    } else {
      this.dateUnsetted = true;
    }
  }

  processPanelState = (dec) => {
    if (dec) {
      if (dec.armed && dec.triggered) {
        this.stateDisplay = '../../../assets/img/alarm-arm-total_excluded.png';
      } else if (dec.armed && this.cpArmedPerTypes.includes(dec.armType)) {
        this.stateDisplay = '../../../assets/img/alarm-arm-perimetral.png';
      } else if (dec.armed) {
        this.stateDisplay = '../../../assets/img/alarm-arm-total.png';
      } else {
        this.stateDisplay = '../../../assets/img/alarm-disarm.png';
      }
    }
  }

  // Metodo para silenciar o devolver el volumen de los beeps recibidos por el reporte
  invertVolume = () => {
    this.soundState = !this.soundState;
    this.setVolume(null, this.soundState);
  }

  // Metodo para cambiar en ejecucion el volumen de los beeps recibidos por el reporte
  setVolume = (event: any, state: boolean) => {
    if (!(state === undefined)) {
      this.soundState = state;
    }

    if (this.soundState) {
      if (event) this.volumeValue = event.value;
      this.activeSound.volume = this.volumeValue / 100; // Configuracion de volumen (0-1)
      this.activeArmSound.volume = this.volumeValue / 100; // Configuracion de volumen (0-1)
    } else {
      this.activeSound.volume = 0;
      this.activeArmSound.volume = 0;
    }
  }

  getVolume = () => {
    if (this.soundState) {
      return this.volumeValue;
    }
    return 0;
  }

  // Metodo para mostrar Mensajes de aviso (Mensaje exitoso y perdida de conexión)
  openConfirmDialog(promptText: string, submitText: string, callback: Function): void {
    const dialogRef = this.dialog.open(AcceptDialogComponent, {
      maxWidth: '450px',
      autoFocus: false,
      data: { promptText, submitText }
    });

    dialogRef.afterClosed().subscribe(result => callback(result));
  }

  // Metodo para cambiar de partición
  changePartition = () => {
    if (this.keyboardService.keyboardType == constants.keyboardTypes.Paradox) {
      // Paradox report always both partition info
      this.stateDisplay = this.multiPartitionState[parseInt(this.partitionNumber) -1];
    } else {
      // Se detienen controles de la partición anterior
      clearTimeout(this.disconectTimer);
      clearTimeout(this.waitingReportTimeout);
      this.disconectionSubscription.unsubscribe();
  
      // Se inicia el spinner de carga y el spinner que bloquea el boton de envío
      this.loading = true;
      this.blockSend = true;
  
      // Se inicia la conexión con la nueva partición
      this.timerCount = 0;
      this.startKeyboardConnection();
    }
  }
}
