import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from './../../auth/services/auth.service';

interface UpdateUser {
  name: string;
  lastName: string;
  email: string;
  phone: string;
  pass?: string;
  language: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private readonly http: HttpClient, private readonly auth: AuthService) {}

  clear = () => {
    localStorage.removeItem('userCompanyValues');
  };

  updateUser(newValues: UpdateUser, id: string) {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.auth.getToken()}`,
    });

    const sanitizedValues = { ...newValues };
    if (!sanitizedValues.pass) {
      delete sanitizedValues.pass;
    }

    return this.http.post(`${environment.webappEndpoint}/userCompany/update/${id}`, sanitizedValues, { headers });
  }
}
