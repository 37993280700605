<div class="new-color-palette">
    <div class="upload-logo-title">{{ 'Settings.PrimaryColor' | translate }}</div>
    <div class="rainbow-slider">
        <div class="rainbow-bar"></div>
        <input
          type="range"
          min="0"
          max="100"
          [(ngModel)]="sliderColorValue"
          class="slider"
          (input)="onColorSliderChange($event)"
        />
    </div>
    <div class="rainbow-slider">
        <div class="grayscale-bar"></div>
        <input
          type="range"
          min="0"
          max="100"
          [(ngModel)]="sliderGrayscaleValue"
          class="slider"
          (input)="onGrayscaleSliderChange($event)"
        />
    </div>
    <div class="row">
        <div class="box" [style.background-color]="combinedColor"></div>
        <div class="box">
            <div class="expanded-info-column">
                <div class="expanded-info-title">
                    {{ 'HEX' }}
                </div>
                <input class="expanded-info-input" matInput #message maxlength="256" placeholder="" [(ngModel)]="combinedHEX" (ngModelChange)="onHexColor(combinedHEX)">
            </div>
        </div>
    </div>

    <div class="row" style="padding-top: 10px;">
        <div class="box">
            <div class="expanded-info-column">
                <div class="expanded-info-title">
                    {{ 'RGB' }}
                </div>
                <input class="expanded-info-input" matInput #message maxlength="256" placeholder="" [(ngModel)]="combinedRGB" (ngModelChange)="onRGBColor(combinedRGB)">
            </div>
        </div>
        <div class="box">
            <div class="expanded-info-column">
                <div class="expanded-info-title">
                    {{ 'CMYK' }}
                </div>
                <input class="expanded-info-input" matInput #message maxlength="256" placeholder="" [(ngModel)]="combinedCMYK" (ngModelChange)="onCMYKColor(combinedCMYK)">
            </div>
        </div>
    </div>

    <button mat-raised-button color="primary" (click)="saveChanges()">
        <span>✔</span>
        {{ 'Settings.SaveChanges' | translate }}
    </button>

    <button class="close-button" (click)="closeModal()">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
</div>