import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Receiver, ContextualMenuType } from 'src/app/settings/constants';
import { CompaniesService } from 'src/app/settings/services/companies.service';
import { validateApikey, validatorString, validateIp, validatorNumeric } from 'src/app/settings/validators';
import { CreateUserComponent } from '../create-user/create-user.component';
import { ContextualMenuService } from 'src/app/settings/services/contextual-menu.service';

@Component({
  selector: 'app-create-receiver',
  templateUrl: './create-receiver.component.html',
  styleUrls: ['./create-receiver.component.scss']
})
export class CreateReceiverComponent implements OnInit {
  editReceiverForm: FormGroup;
  submited: boolean;
  
  contextualMenuType = ContextualMenuType;
  currentType: number;

  newReceiver: Receiver;

  constructor(
    private readonly formBuilder: FormBuilder,
    public companiesService: CompaniesService,
    public dialogRef: MatDialogRef<CreateUserComponent>,
    public contextualMenuService: ContextualMenuService,
  ) { 
    this.editReceiverForm = this.formBuilder.group({
      receiverName: ['', [Validators.required, (event) => validatorString('receiverName', event, this.editReceiverForm, this.companiesService.currentCompany)]],
      panID: ['', [Validators.required, (event) => validatorString('panID', event, this.editReceiverForm, this.companiesService.currentCompany)]],
      receiverID: ['', [Validators.required, (event) => validatorString('receiverID', event, this.editReceiverForm, this.companiesService.currentCompany)]],
      receiverNumber: ['', [Validators.required, (event) => validatorNumeric('receiverNumber', event, this.editReceiverForm, this.companiesService.currentCompany, 1)]],
      apiKey: ['', [Validators.required, (event) => validateApikey('apiKey', event, this.editReceiverForm, this.companiesService.currentCompany)]],
      ip: ['', [Validators.required, (event) => validateIp('ip', event, this.editReceiverForm, this.companiesService.currentCompany)]],
      udpPort: ['', [Validators.required, (event) => validatorNumeric('udpPort', event, this.editReceiverForm, this.companiesService.currentCompany, 5)]],
      snifferPort: ['', [Validators.required, (event) => validatorNumeric('snifferPort', event, this.editReceiverForm, this.companiesService.currentCompany, 5)]],
      monitoringPort: ['', [Validators.required, (event) => validatorNumeric('monitoringPort', event, this.editReceiverForm, this.companiesService.currentCompany, 5)]],
      gestionPort: ['', [Validators.required, (event) => validatorNumeric('gestionPort', event, this.editReceiverForm, this.companiesService.currentCompany, 5)]],
      bridgePort: ['', [Validators.required, (event) => validatorNumeric('bridgePort', event, this.editReceiverForm, this.companiesService.currentCompany, 5)]],
    });
  }
  
  ngOnInit(): void {
    this.newReceiver = new Receiver();
  }

  closeModal(action) {
    this.dialogRef.close({ status: action, newReceiver: this.newReceiver });
  }

  getError(event) {
    const field = this.editReceiverForm.get(event);
    return (this.submited || field?.touched) && (field?.errors?.required || field?.errors?.invalidParam)
  }

  getErrorType(event, type) {
    return this.editReceiverForm.get(event)?.errors?.[type];
  }

  onSubmit() {
    this.submited = true;
    if (this.editReceiverForm.valid) {
      this.dialogRef.close({ status: 'confirm', newReceiver: this.newReceiver });
    }
  }
}
