<div class="searchContainer">
    <div class="searchContent">
        <form class="search-form">
            <div fxLayout="row" fxLayoutGap="10px">
                <mat-form-field class="filterMapField">
                  <span class="placeholder">{{ filterValue?.length == 0 ? ('MeshNet.mapSearch' | translate) : '' }}</span>
                  <input type="text"
                    class="placeholder-inside-input"
                    matTooltip='{{"MeshNet.mapSearchTooltip" | translate}}'
                    matInput
                    [(ngModel)]="filterValue"
                    (keyup)="panelStatus.applyFilter(filterValue)"
                    [formControl]="myControl" 
                    [matAutocomplete]="auto">
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngFor="let c of panelStatus.filteredDevices" 
                      (click)="panelStatus.selectPanel(c.panel)"
                      class="displayAutocomplete"
                    >{{c.label}}</mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              <button mat-icon-button class="icon" (click)="panelStatus.filterAndSearch(filterValue)">
                <mat-icon>search</mat-icon>
              </button>
            </div>
        </form>
    </div>
</div>