import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../app-material/app-material.module';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { KeyboardComponent } from './components/keyboard/keyboard.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { ParadoxComponent } from './components/paradox/paradox.component';
import { DscComponent } from './components/dsc/dsc.component';

@NgModule({
  declarations: [ KeyboardComponent, ParadoxComponent, DscComponent ],
  imports: [
    CommonModule,
    AppMaterialModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatDialogModule,
    MatSliderModule,
    MatDividerModule,
    MatListModule,
    MatSelectModule,
    TranslateModule
  ]
})
export class KeyboardModule { }
