import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export interface DialogData {
  image: { // Only one, URL or MATICON
    svg: string, 
    matIcon: string,
  },
  title: string,
  description: string,
  rightButton: {
    title: string,
    color: {
      background: string,
      font: string
    }
  },
  leftButton: {
    title: string,
    color: {
      background: string,
      font: string
    }
  }
}
@Component({
  selector: 'app-confirm-action-dialog',
  templateUrl: './confirm-action-dialog.component.html',
  styleUrls: ['./confirm-action-dialog.component.scss']
})
export class ConfirmActionDialogNewComponent {
  rightButtonBorder;
  leftButtonBorder;

  constructor(
    public dialogRef: MatDialogRef<ConfirmActionDialogNewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private readonly matIconRegistry: MatIconRegistry, 
    private readonly sanitizer: DomSanitizer,
  ) { 
    if (data.image?.svg) {
      this.matIconRegistry.addSvgIcon(
        'custom_icon',
        this.manualSanitizing(data.image.svg)
      );
    }

    this.leftButtonBorder = '1px solid ' + ((data.leftButton?.color.background === 'white')
      ? '#f6f7f8'
      : data.leftButton?.color.background);
    this.rightButtonBorder = '1px solid ' + ((data.rightButton?.color.background === 'white')
      ? '#f6f7f8'
      : data.rightButton?.color.background);
  }
  
  manualSanitizing(icon: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(icon);
  }

  onRight() {
    this.dialogRef.close({ status: 'right' });
  };

  onLeft() {
    this.dialogRef.close({ status: 'left' });
  };
  
  closeModal() {
    this.dialogRef.close({ status: 'close' });
  }
}
