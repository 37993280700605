export default {
  defaultReceiver: 'default',
  limitConcurrentRequest: 5,
  viewTypes: {
    None: 'ViewNone',
    Installation: 'ViewInstallation',
    Conectivity: 'ViewConectivity',
    MeshFunction: 'ViewMeshFunction',
    ViewLinking: 'ViewLinking',
  },
  iconTypes: {
    Normal: 'typeNormal',
    Interior: 'typeInterior',
    ExteriorLow: 'typeExteriorLow',
    ExteriorHigh: 'typeExteriorHigh',
    RF: 'typeRF',
    WIFI: 'typeWIFI',
    Ethernet: 'typeEthernet',
    Mobile: 'typeMobile',
    Mobile2G: 'typeMobile2g',
    Mobile3G: 'typeMobile3g',
    Mobile4G: 'typeMobile4g',
    FullInternet: 'typeFullInternet',
    MeshDevice: 'typeMeshDevice',
    Gateway: 'typeGateway',
    TypeLinking: 'typeLinking{stateLocation}',
    DeviceState: 'typeDeviceState',
    MonitoringState: 'typeMonitoringState',
    ReceiverState: 'typeReceiverState',
  },
  conectivityTylesConversor: {
    typeWIFI: 'linkWifiEth',
    typeEthernet: 'linkWifiEth',
    typeRF: 'linkRF',
    typeMobile: 'linkCelular',
    typeFullInternet: 'linkFull',
    // @todo in the future change link4G to link2G3G for typeMobile
  },
  iconPath: {
    typeSelected: 'assets/icons/filters/Selected.png',
    typeNeighbor: 'assets/icons/filters/Neighbor.png',
    typeNode: 'assets/icons/filters/Node.png',
    typeGateway: 'assets/icons/filters/Gateway.png',
  },
  stateLocation: {
    optimum: 'optimum',
    good: 'good',
    low: 'low',
    null: 'null',
    glossary: 'optimum',
    blue: 'blue',
  },
  commonIconSize: {
    scaledSize: {
      width: 23,
      height: 23,
      cursor: 'pointer',
      zIndex: 700,
    },
    anchor: {
      x: 11.1,
      y: 11.9,
    },
  },
  pathIconSize: {
    scaledSize: {
      width: 37,
      height: 37,
      zIndex: 500,
    },
    anchor: {
      x: 17.6,
      y: 17.6,
    },
  },
  PathTypes: {
    Neighbor: 'Neighbor',
    Path: 'Path',
  },
};
