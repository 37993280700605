import { Component, OnInit } from '@angular/core';
import { PanelService } from '../../../panel/services/panel.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PanelStatusService } from '../../service/panel-status.service';
import constants from 'src/app/shared/constants';

@Component({
  selector: 'app-neighbor-reload',
  templateUrl: './neighbor-reload.component.html',
  styleUrls: ['./neighbor-reload.component.scss']
})
export class NeighborReloadComponent implements OnInit {

  public continue: string = '';

  public panel;
  public steps: string[] = ['NeighborStep1', 'NeighborStep2', 'NeighborStep3'];

  public intervalIds = [];

  constructor(
    public ps: PanelService,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
    public panelStatus: PanelStatusService
  ) { }

  ngOnInit(): void {
    this.panel = this.panelStatus.neighborReloadState.panel;
    if (!this.panelStatus.neighborReloadState.started) {
      this.panelStatus.neighborReloadState.started = true;
      this.initProcess();
    }
    this.intervalIds = [
      setInterval(() => {
        if (this.continue.length < 3) {
          this.continue += '.';
        } else {
          this.continue = '';
        }
      }, 700)
    ];
  }

  ngOnDestroy(): void {
    this.intervalIds.forEach((id) => {
      clearInterval(id);
    })
  }

  private initProcess() {
    this.continue = '';
    
    this.ps.httpRequest(
      this.ps.neighborReloadPanel,
      [ this.panel.mac ],
      async (res, params) => {
        if (res?.success) {
          // Process Start Successfully
          this.panel.requesting = true;
          this.panelStatus.neighborQueue.push({ mac: this.panel.mac, failed: false });
        } else {
          this.panelStatus.neighborReloadState = undefined;
          this.snackBar.open(
            res.reason || (await this.translateService.get('Panel.FailedConfDev').toPromise()),
            await this.translateService.get('Shared.Close').toPromise(),
            { duration: constants.snackBarDuration }
          );
        }
      },
      {},
      'Panel.FailedConfDev'
    );
  }

}
