import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { HoverMenuesService } from 'src/app/hover-menues/services/hover-menues.service';

@Component({
  selector: 'app-breadcrumbed-help',
  templateUrl: './breadcrumbed-help.component.html',
  styleUrls: ['./breadcrumbed-help.component.scss']
})
export class BreadcrumbedHelpComponent {
  @Input() showMenu: boolean;
  @Input() heightReference: any = 0;


  constructor(
    public hoverMenuesService: HoverMenuesService,
    private readonly translateService: TranslateService,
    private readonly authService: AuthService,
  ) { }
  
  getFilterName = () => {
    const name = this.translateService.instant("AppComponent.Help");
    return this.showMenu ? name : name.slice(0, 1) + '...';
  };

  onOpenManual() {
    let url = 'https://citymesh.deitres.com/en/home/';
    switch (this.authService.selectedLanguage) {
      case 'es':
        url = 'https://citymesh.deitres.com/manuales/';
        break;
      case 'pt':
        url = 'https://citymesh.deitres.com/pt/manuais/';
        break;
    }
    window.open(url, '_blank');
  }

  onFeedback() {

  }

  onContactSupport() {

  }

  calcOffset = () => {
    const isSmallScreen = window.innerWidth <= 1300;

    let sideMenu = 205;
    let noneOpen = 75;

    if (isSmallScreen) {
      sideMenu = 160;
      noneOpen = 65;
    }

    return this.showMenu ? sideMenu : noneOpen;
  };
}
