import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './../../auth/services/auth.service';
import { PanelsService } from 'src/app/shared/services/panels.service';
import { TranslateService } from '@ngx-translate/core';
import constants from '../constants';

@Injectable({
  providedIn: 'root'
})
export class KeyboardService {

  public mac: string;
  public account: string;
  public apiKey: string;
  public volume: number;
  public keyboardType: string;
  public partitions: number[] = [1, 2, 3, 4];
  public lang = 'en';

  public keysToSend = '';
  public specialKey;
  private keyboardPartitions = {
    DSC: [1, 2, 3, 4],
    Paradox: [1, 2]
  }

  constructor(
    private http: HttpClient, 
    private auth: AuthService,
    private panelsService: PanelsService,
    private translateService: TranslateService
  ) {}

  setPartition = async (account: string, mac: string, apiKey: string) => {
    this.account = account;
    this.mac = mac;
    this.apiKey = apiKey;

    const panel = await this.panelsService.getPanel(mac);
    // By default is DSC
    this.keyboardType = panel?.version?.panelType ?? constants.keyboardTypes.DSC; 
    if (this.keyboardPartitions[this.keyboardType]) this.partitions = this.keyboardPartitions[this.keyboardType];
  }

  sendCommand = (keysToSend: string, sequence: string, partition: string) => {
    return this.http.post(
      `${environment.webappEndpoint}/partition/sendKeys`,
      { keysToSend, account: this.account, apiKey: this.apiKey,  mac: this.mac, sequence, partition },
      { headers: { Authorization: `Bearer ${this.auth.getToken()}` }}
    );
  }

  // Metodo para agregar teclas al campo de texto. (Desde el teclado o desde el mismo campo)
  writeKey = async (key: string) => {
    if (key === 'ArmTotal' || key === 'ArmPerimeter') {
      // Si se presiono Armado Total o Perimetral se borra el contenido actual del campo y reemplaza por dicho valor.
      this.keysToSend = await this.translateService.get('Keyboard.' + key).toPromise();
      this.specialKey = key;
    } else {
      if (this.specialKey === 'ArmTotal' || this.specialKey === 'ArmPerimeter') {
        // Si se presiono una tecla que no es de armado y anteriormente si lo habia hecho,
        // se elimina el armado del campo y procede a agregar la nueva tecla
        this.keysToSend = '';
        this.specialKey = undefined;
      }

      if (this.keyboardType === constants.keyboardTypes.DSC) {
        key = key.toUpperCase();
        const validKeys = /[0-9AFP#*]{1}/;
        if (this.keysToSend.length < 32 && validKeys.test(key)) {
          this.keysToSend = this.keysToSend + key;
        }
      } else if (this.keyboardType === constants.keyboardTypes.Paradox) {
        if ('MT'.includes(key)) key = key.toLowerCase(); // Only Lowercase
        if ('afpdq'.includes(key)) key = key.toUpperCase(); // Only Uppercase
        const validKeys = /[0-9elmorstvDELOQRSVAFP]{1}/;
        if (this.keysToSend.length < 32 && validKeys.test(key)) {
          this.keysToSend = this.keysToSend + key;
        }
      }   
    }
  }
}
