import { ConfigPanelComponent } from './../config-panel/config-panel.component';
import { lastValueFrom } from 'rxjs';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Panel } from '../../models/panel.model';
import { PanelService } from '../../services/panel.service';
import { PanelsService } from 'src/app/shared/services/panels.service';
import { PartitionsService } from 'src/app/shared/services/partitions.service';
import { MatDialog } from '@angular/material/dialog';
import { PanelEditComponent } from '../panel-edit/panel-edit.component';
import { PanelPasswordComponent } from '../panel-password/panel-password.component';
import { ConfirmActionDialogComponent } from 'src/app/shared/components/confirm-action-dialog/confirm-action-dialog.component';
import { PanelInfoComponent } from '../panel-info/panel-info.component';
import { ComandService } from 'src/app/tech-support/services/comand.service';
import { TranslateService } from '@ngx-translate/core';
import { PanelErrorsComponent } from '../panel-errors/panel-errors.component';
import { PanelErrorStatusService } from 'src/app/tech-support/services/panel-error-status-service';
import { Router, ActivatedRoute } from '@angular/router';
import { PanelDialogComponent } from '../panel-dialog/panel-dialog.component';
import { PartitionListComponent } from 'src/app/partitions/components/partition-list/partition-list.component';
import { PanelStatusService } from 'src/app/map/service/panel-status.service';
import { ReceiversService } from 'src/app/shared/services/receivers.service';
import { EventService } from '../../services/event.service';
import { BreadcrumbedReceptor, BreadCrumberChannelTypes } from '../../models/event.model';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmActionDialogNewComponent } from 'src/app/shared/components/confirm-action-dialog-new-style/confirm-action-dialog.component';
import { PanelMapMark } from 'src/app/types';
import mapConstants from 'src/app/map/constants';

@Component({
  selector: 'app-panel-list',
  templateUrl: './panel-list.component.html',
  styleUrls: ['./panel-list.component.scss'],
})
export class PanelListComponent implements OnInit, OnDestroy {
  @ViewChild('containerElement') containerElement!: ElementRef;

  filterOptions: string[] = ['devices'];
  companyLogo: string;
  displayedColumns: string[];
  snackBarDuration = 5000;
  snackBarErrorDuration = 10000;
  color = {};
  apiKeys: string[];
  signalUpdated: boolean = false;
  mac: string;
  signalType: string;
  signal: number;

  panelTypes = {
    citypanelCom: 'CP-Comunicador',
    citypanel: 'CityPanel',
    city8: 'CityMesh 8',
    city7: 'CityMesh 7',
  };

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(PartitionListComponent) partitionListComponent!: PartitionListComponent;

  constructor(
    private readonly ps: PanelService,
    public panelsService: PanelsService,
    public readonly panelStatusService: PanelStatusService,
    private readonly partitionsService: PartitionsService,
    private readonly snackBar: MatSnackBar,
    public authService: AuthService,
    public dialog: MatDialog,
    private readonly commandService: ComandService,
    private readonly translateService: TranslateService,
    private readonly panelErrorStatusService: PanelErrorStatusService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly receiverService: ReceiversService,
    private readonly eventService: EventService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer
  ) {
    this.displayedColumns = this.authService.isAdmin()
      ? [
          'errors',
          'mac',
          'type',
          'receiverName',
          'company',
          'meshSignal',
          'ethSignal',
          'mobileSignal',
          'wifiSignal',
          'ssid',
          'partitions',
          'adminNotes',
          'actions',
        ]
      : [
          'errors',
          'mac',
          'type',
          'receiverName',
          'meshSignal',
          'ethSignal',
          'mobileSignal',
          'wifiSignal',
          'ssid',
          'partitions',
          'adminNotes',
          'actions',
        ];
    this.panelsService.dataSource.filter = undefined;

    this.eventService.breadcrumbedReceptor.subscribe((signalParams: BreadcrumbedReceptor) => {
      if (
        signalParams?.type === BreadCrumberChannelTypes.NEWFILTER &&
        this.receiverService.selectedReceiver.length > 0
      ) {
        this.filterReceiver();
      }
    });
  }

  ngOnInit() {
    if (this.route.snapshot.queryParams.mac) {
      this.applyFilter(this.route.snapshot.queryParams.mac);
    }

    this.panelsService.dataSource.paginator = this.paginator;
    this.panelsService.dataSource.sort = this.sort;
    this.panelsService.dataSource.filterPredicate = (panel: Panel, filterValue: string) => {
      const filter = filterValue.trim().toLowerCase();

      if (!this.panelsService.panelEnableOnFilter(panel, this.receiverService.selectedReceiver)) {
        return false;
      }

      const panelType =
        panel.type === 'citypanel' && panel.isCommunicator
          ? this.panelTypes['citypanelCom']
          : this.panelTypes[panel.type];

      const fieldsToCheck = [
        panel.mac,
        panelType,
        panel.receiverName,
        panel.ssid,
        panel.adminNotes,
        this.authService.isAdmin() ? this.authService.getCompanyName(panel.company) : '',
      ];

      return fieldsToCheck.some((field) => field?.trim().toLowerCase().includes(filter));
    };

    this.matIconRegistry.addSvgIcon(
      'delete_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/delete.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'settings_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menues/settings-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'remove_support_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menues/device-remove-support.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'add_support_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/menues/device-add-support.svg')
    );

    if (this.receiverService.selectedReceiver.length > 0 && this.panelsService.panels.length !== 0) {
      return this.filterReceiver();
    }

    if (this.panelsService.panels.length !== 0) {
      this.panelsService.collectPanels(true);
    } else {
      this.getPanels();
    }
  }

  showButtonMenu() {
    return this.containerElement?.nativeElement.offsetWidth < 1540;
  }

  ngOnDestroy() {
    // Cuando el componente se destruye, se desuscribe de la suscripción
    if (this.panelsService.reportSubscription) {
      this.panelsService.reportSubscription.unsubscribe();
    }
    this.panelsService.showActive = true;

    // Check recconnect, when switch to other tab and return.
    // this.panelChangesSocket.close();
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
  }

  filterReceiver() {
    const panelsToFilter = this.panelsService.showActive
      ? this.panelsService.panels
      : this.panelsService.inactivePanels;

    if (this.receiverService.selectedReceiver.includes(mapConstants.defaultReceiver)) {
      this.panelsService.dataSource.data = panelsToFilter;
    } else {
      this.panelsService.dataSource.data = [
        ...panelsToFilter.filter((panel) => this.receiverService.selectedReceiver.includes(panel.apiKey)),
      ];
    }
    this.panelsService.dataSource._updateChangeSubscription();
  }

  applyFilter = (filterValue: string) => {
    this.panelsService.dataSource.filter = filterValue;
  };

  deletePanel(_id, mac) {
    const dialogRef = this.dialog.open(ConfirmActionDialogNewComponent, {
      autoFocus: false,
      width: '400px',
      data: {
        title: 'Panel.ConfirmDeleteDevice_title',
        description: 'Panel.ConfirmDeleteDevice_description',
        rightButton: {
          title: 'Panel.ConfirmDeleteDevice_confirmButton',
          color: {
            background: '#FF0000',
            font: 'white',
          },
        },
        leftButton: {
          title: 'Shared.Cancel',
          color: {
            background: 'white',
            font: 'black',
          },
        },
      },
    });

    dialogRef.afterClosed().subscribe((result: { status: string; topics: any }) => {
      if (result?.status === 'right') this.onConfirmDeletePanel(_id, mac);
    });
  }

  onConfirmDeletePanel(_id, mac) {
    this.ps.deletePanel(_id).subscribe({
      next: (res: any) => {
        if (res?.success) {
          this.deletePanelSuccess();
          this.panelsService.removePanel(mac);
          this.partitionsService.removePanel(mac);
        } else {
          this.showSnackBar('Panel.FailedConfDev');
        }
      },
      error: (error) => {
        if (error && error.status === 401) {
          this.showSnackBar('Shared.SessionExpired');
        } else {
          this.showSnackBar('Panel.FailedConfDev');
        }
      },
    });
  }

  deletePanelSuccess() {
    const dialogRef = this.dialog.open(ConfirmActionDialogNewComponent, {
      width: '400px',
      data: {
        title: 'Panel.ConfirmDeleteDevice_success',
        rightButton: {
          title: 'Ok',
          color: {
            background: '#EAB910',
            font: 'white',
          },
        },
      },
    });

    dialogRef.afterClosed().subscribe((result: { status: string; topics: any }) => {});
  }

  switchFilterDeleted = () => {
    this.panelsService.toggleActive();
    this.filterReceiver();
  };

  getPanels = async () => {
    if (!this.panelsService.isLoadingResults) {
      this.panelsService.getPanels();
    }
  };

  openPanel = (id: string) => {
    this.dialog.open(PanelInfoComponent, {
      width: '65%',
      maxWidth: '700px',
      data: { id },
    });
  };

  openErrors = (id: string) => {
    this.panelsService.errorsDialogRef = this.dialog.open(PanelErrorsComponent, {
      width: '50%',
      minWidth: '800px',
      data: {
        id,
        onDestroy: (id: string) => {
          this.panelsService.dataSource.data.forEach((panel: Panel) => {
            if (panel._id === id) {
              this.panelErrorStatusService.onClick(panel._id);
            }
          });
        },
      },
    });

    this.panelsService.errorsDialogRef.afterClosed().subscribe(async () => {
      delete this.panelsService.errorsDialogRef;
    });
  };

  updatePanel = (event: any, id: string) => {
    event.stopPropagation();

    const dialogRef = this.dialog.open(PanelEditComponent, {
      width: '65%',
      maxWidth: '700px',
      data: { id },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        if (result.status === 'success') {
          this.getPanels();
        }

        if (result.status !== 'cancel') {
          this.showSnackBar(result.message);
        }
      }
    });
  };

  passwordPanel = (mac: string, partitions: any) => {
    this.dialog.open(PanelPasswordComponent, {
      width: '65%',
      maxWidth: '700px',
      data: { mac, partitions },
    });
  };

  setupPanel = (event: any, panel: Panel) => {
    event.stopPropagation();

    this.dialog.open(ConfigPanelComponent, {
      width: '60%',
      maxWidth: '750px',
      data: { panel, partitions: panel.partitions.map((p) => p.account) },
    });
  };

  communicatorToPanel = async (id: string) => {
    const panel = this.panelsService.panels.find((p) => p._id === id);
    const partitions = panel.partitions.map((p) => p.account);
    const accountMsg =
      partitions && partitions.length === 1
        ? (await lastValueFrom(this.translateService.get('Panel.DevUnlink1'))) +
          `(${partitions[0]})` +
          (await lastValueFrom(this.translateService.get('Panel.DevUnlink2')))
        : '';

    const msg = `${accountMsg}${await lastValueFrom(this.translateService.get('Panel.ConfDevInstMode'))}`;

    this.openConfirmDialog(msg, await lastValueFrom(this.translateService.get('Shared.Accept')), (result: boolean) => {
      if (result) {
        this.ps.toPanel(id).subscribe({
          next: (res: any) => {
            if (res?.success) {
              this.getPanels();
              this.unlinkPartitions(panel);
              this.showSnackBar('Panel.CityConfPanel');
            } else {
              this.showSnackBar('Panel.FailedConfDev');
            }
          },
          error: (error) => {
            if (error && error.status === 401) {
              this.showSnackBar('Shared.SessionExpired');
              // this.authService.logout();
            } else {
              this.showSnackBar('Panel.FailedConfDev');
            }
          },
        });
      }
    });
  };

  unlinkPartitions(panel): void {
    const partitions = this.partitionsService.partitions.filter((p) => p.mac === panel.mac);
    for (let p of partitions) {
      p.mac = null;
      p.apiKey = null;
      p.partitionNumber = null;
      p.keyboard = false;
    }
  }

  openConfirmDialog(promptText: string, submitText: string, callback: Function): void {
    const dialogRef = this.dialog.open(ConfirmActionDialogComponent, {
      maxWidth: '450px',
      autoFocus: false,
      data: { promptText, submitText },
    });

    dialogRef.afterClosed().subscribe((result) => callback(result));
  }

  getShortText = (text: string) => {
    return text && text.length > 18 ? text.substring(0, 15) + '...' : text;
  };

  addToTechSupport = (event: any, panel: Panel) => {
    panel.selectedToSupport = !panel.selectedToSupport;
    this.commandService.panelToTechSupport(panel.mac, panel.selectedToSupport);
  };

  sendToUnmonitoredCompany = async (mac: string, companyId: string) => {
    const company = this.authService.user.companies.find((c) => c._id === companyId);

    let msg = this.translateService.instant('Panel.ConfirmUnmonitoredConfig', {
      macId: mac,
      companyIdOrName: company ? company.name : companyId,
    });

    this.openConfirmDialog(msg, await lastValueFrom(this.translateService.get('Shared.Accept')), (result: boolean) => {
      if (result) {
        this.ps.switchToUnmonitored(mac).subscribe({
          next: (res: any) => {
            if (res?.success) {
              this.getPanels();
              this.showSnackBar('Panel.CityConfUnmonitored');
            } else {
              this.showSnackBar('Panel.FailedConfDev');
            }
          },
          error: (error) => {
            if (error && error.status === 401) {
              this.showSnackBar('Shared.SessionExpired');
            } else {
              this.showSnackBar('Panel.FailedConfDev');
            }
          },
        });
      }
    });
  };

  showSnackBar = async (message) => {
    this.snackBar.open(
      await lastValueFrom(this.translateService.get(message)),
      await lastValueFrom(this.translateService.get('Shared.Close')),
      { duration: 5000 }
    );
  };

  goToMap = (mac, apiKey) => {
    const selectedPanel = this.panelStatusService.rendererMarKList.find(
      (panel: PanelMapMark) => panel.mac === mac && panel.apiKey === apiKey
    );

    if (selectedPanel) {
      this.panelStatusService.setSelectedPanel(selectedPanel);
      this.router.navigate(['/maps'], { queryParams: { mac, apiKey, recoverSelected: true } });
    }
  };

  goToUsers() {
    this.router.navigate(['/users']);
  }

  OpenRegisterDialog() {
    const dialogRef = this.dialog.open(PanelDialogComponent, {
      width: '40%',
      maxWidth: '1000px',
      autoFocus: false,
      data: {
        title: 'Partition.NewPartition',
        submitText: 'Partition.AddPartition',
        operation: 'add',
        type: 'register',
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        if (result.status === 'success') {
          this.partitionListComponent.getPartitions();
        }

        if (result.status !== 'cancel') {
          this.snackBar.open(result.message, await this.translateService.instant('Shared.Close'), {
            duration: this.snackBarDuration,
          });
        }
      }
    });
  }

  OpenNotificationsDialog() {
    this.dialog.open(PanelDialogComponent, {
      width: '40%',
      maxWidth: '1000px',
      autoFocus: false,
      data: { title: 'Partition.NewPartition', submitText: 'Panel.Add', operation: 'add', type: 'notifications' },
    });
  }
}
