<span>
  <button
    [id]="'alarm-trigger-' + partition._id"
    *ngIf="partition.active"
    mat-icon-button
    [matTooltip]="'Partition.AlarmTrigger' | translate"
    matTooltipShowDelay="750"
    matTooltipPosition="after"
    aria-hidden="false"
    [disabled]="disabled"
    style="margin-top: 10px;"
  >
    <mat-spinner [diameter]="40"
      *ngIf="isHeld"
      [value]="progressSpinner"
      class="spinner"
      mode="determinate">
    </mat-spinner>
    <span class="bell-alarm-trigger">
      <mat-icon>
        <img [class]="type_of_action == 'hold_press' && wooble ? 'shake' : ''"
          style="width: 32px; margin-left: -4px; margin-bottom: -10px;"
          [src]="type_of_action == 'hold_press' && wooble ? 'assets/icons/SirenTrigger.png' : 'assets/icons/SirenSilent.png'"/>
      </mat-icon>
    </span>
  </button>
</span>
