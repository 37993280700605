import { Component, Input } from '@angular/core';
import { PanelsService } from 'src/app/shared/services/panels.service';
import { PanelErrorStatusService } from 'src/app/tech-support/services/panel-error-status-service';

@Component({
  selector: 'app-panel-errors-info-button',
  templateUrl: './panel-errors-info-button.component.html',
  styleUrls: ['./panel-errors-info-button.component.css'],
})
export class PanelErrorsInfoButtonComponent {
  @Input() panelId: string;

  constructor(
    public panelErrorStatusService: PanelErrorStatusService,
    public panelsService: PanelsService
  ) {}
}
