import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/services/auth.service';
import { CompanyModel } from '../../../shared/models/company-model';
import { ReceiversService } from '../../../shared/services/receivers.service';
import { Receiver } from '../../../shared/models/receiver-model';
import { PanelStatusService } from '../../../map/service/panel-status.service';
import { EventService } from '../../../panel/services/event.service';
import { CustomMapService } from '../../../map/service/custom-map.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BreadCrumberChannelTypes } from '../../../panel/models/event.model';
import { HoverMenuesService } from '../../../hover-menues/services/hover-menues.service';
import { DistributorModel } from '../../../shared/models/distributor-model';

enum BreadcrumbedReceptorPicker {
  companies = 'companies',
  receptors = 'receptors',
  distributors = 'distributors',
  closed = 'none',
}

@Component({
  selector: 'app-breadcrumbed-receptor-picker',
  templateUrl: './breadcrumbed-receptor-picker.component.html',
  styleUrls: ['./breadcrumbed-receptor-picker.component.scss'],
})
export class BreadcrumbedReceptorPickerComponent implements OnInit {
  @Input() showMenu: boolean;
  @Input() set routerUrl(value: string) {
    this.savedRouterUrl = value;
    this.cleanState();
  }
  @Input() heightReference: any = 0;

  savedRouterUrl: string;

  state: string;

  selectedDistributor: string;
  selectedCompany: CompanyModel;
  selectedReceiver: Receiver;
  showReceiverFilters: boolean = false;

  receivers: Receiver[];
  receiversQtyByCompany = {};
  companiesQtyByDistributor = {};

  distributor: DistributorModel;

  allStates = ['distributors', 'companies', 'receptors'];

  routesConfigureMap = ['/panels', '/partitions', '/maps'];

  constructor(
    private readonly router: Router,
    public authService: AuthService,
    private readonly panelStatus: PanelStatusService,
    private readonly receiverService: ReceiversService,
    private readonly eventService: EventService,
    private readonly customMapService: CustomMapService,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
    public hoverMenuesService: HoverMenuesService
  ) {}

  ngOnInit(): void {
    this.setDistributor();
  }

  getFilterName = () => {
    return this.receiverService.filterName ?? this.getPrimaryCompanyName();
  };

  openDistributors = () => {
    this.openCompanies(false);
    this.setToggleOpen();
    this.showReceiverFilters = false;
  };

  openCompanies = (toggleReceiver = true) => {
    if (toggleReceiver) this.showReceiverFilters = !this.showReceiverFilters;
    this.calculateReceiversByCompany();

    if (this.showCompanies()) {
      if (this.state === BreadcrumbedReceptorPicker.closed) {
        if (this.distributor) {
          this.state = BreadcrumbedReceptorPicker.distributors;
        } else {
          this.state = BreadcrumbedReceptorPicker.companies;
        }
        return;
      }

      if (this.state == BreadcrumbedReceptorPicker.distributors) {
        this.state = BreadcrumbedReceptorPicker.companies;
        this.selectedDistributor = this.distributor?.name;
        return;
      }
      
      if (this.distributor) {
        this.selectedDistributor = '';
        this.state = BreadcrumbedReceptorPicker.distributors;
        return;
      }
    } else {
      this.openReceptors(this.getCompanies()[0]);
    }
  };

  openReceptors = (company: CompanyModel) => {
    if (this.selectedCompany?.name == company.name) {
      this.selectedCompany = null;
      this.state = BreadcrumbedReceptorPicker.companies;
      this.panelStatus.clearReceiver(this.router.url == '/maps');
      return;
    }

    if (!this.getQtyReceiversPerCompany(company)) return this.thereAreNoReceiversInThisCompany();

    this.selectedCompany = company;

    this.receiverService.selectedReceiver = [];
    this.receivers = this.receiverService.receivers.filter((receiver: Receiver) => {
      if (receiver.company === company._id) {
        this.receiverService.selectedReceiver.push(receiver.apiKey);
        return true;
      }
      return false;
    });
    this.checkSelectedPanel();

    if (this.router.url == '/maps') {
      this.customMapService.cleanRestorationOfFocus();
    }

    if (this.routesConfigureMap.includes(this.router.url)) {
      this.panelStatus.configureMap();
      this.panelStatus.processMarkIcons();
    }

    this.state = BreadcrumbedReceptorPicker.receptors;

    if (!this.distributor) {
      return this.eventService.breadcrumbedReceptor.emit({
        type: BreadCrumberChannelTypes.NEWFILTER,
        breadcrumb: `${this.selectedCompany.name}`,
        filterName: this.selectedCompany.name,
      });
    }

    this.eventService.breadcrumbedReceptor.emit({
      type: BreadCrumberChannelTypes.NEWFILTER,
      breadcrumb: `${this.distributor.name} >> ${this.selectedCompany.name}`,
      filterName: this.selectedCompany.name,
    });
  };

  thereAreNoReceiversInThisCompany = () => {
    this.snackBar.open(
      this.translateService.instant('Shared.NoReceivers'),
      this.translateService.instant('Shared.Accept'),
      { duration: 5000 }
    );
  };

  selectReceiver = (receiver: Receiver) => {
    if (this.selectedReceiver?.name == receiver.name) {
      this.selectedReceiver = null;
      this.panelStatus.clearReceiver(this.router.url == '/maps');
      this.setToggleOpen();
      return;
    }

    this.selectedReceiver = receiver;

    this.receiverService.selectedReceiver = [receiver.apiKey];
    this.checkSelectedPanel();

    if (this.router.url == '/maps') {
      this.customMapService.cleanRestorationOfFocus();
    }

    if (this.routesConfigureMap.includes(this.router.url)) {
      this.panelStatus.configureMap();
      this.panelStatus.processMarkIcons();
    }

    if (!this.distributor) {
      return this.eventService.breadcrumbedReceptor.emit({
        type: BreadCrumberChannelTypes.NEWFILTER,
        breadcrumb: `${this.selectedCompany.name} > > ${this.selectedReceiver.name}`,
        filterName: this.selectedReceiver.name,
      });
    }

    this.eventService.breadcrumbedReceptor.emit({
      type: BreadCrumberChannelTypes.NEWFILTER,
      breadcrumb: `${this.distributor.name} >> ${this.selectedCompany.name} >> ${this.selectedReceiver.name}`,
      filterName: this.selectedReceiver.name,
    });

    this.setToggleOpen();
  };

  calculateReceiversByCompany = () => {
    const companies = this.authService.getCompanies();
    const receivers = this.receiverService.receivers;

    for (const company of companies) {
      const receiversByCompany = receivers.filter((receiver: Receiver) => receiver.company === company._id);

      this.receiversQtyByCompany[company.name] = {
        qtyReceivers: receiversByCompany.length,
      };
    }
  };

  getQtyReceiversPerCompany = (company: CompanyModel) => {
    if (this.receiversQtyByCompany[company?.name]?.qtyReceivers > -1) {
      return this.receiversQtyByCompany[company.name].qtyReceivers;
    }

    return '';
  };

  getQtyCompaniesPerDistributor = (distributorName: string) => {
    if (this.companiesQtyByDistributor[distributorName]?.qtyCompanies > -1) {
      return this.companiesQtyByDistributor[distributorName].qtyCompanies;
    }

    return '';
  };

  getPrimaryCompanyName = () => {
    return this.authService.user.distributor?.name || this.authService.getPrimaryCompany().name;
  };

  getCompanies = () => {
    return this.authService.getCompanies();
  };

  setToggleOpen = () => {
    if (this.hoverMenuesService.breadCrumbedStatus) this.cleanState();
    this.hoverMenuesService.onToggleBreadCrumbed(!this.hoverMenuesService.breadCrumbedStatus);
  };

  cleanState = () => {
    this.state = BreadcrumbedReceptorPicker.closed;
    this.selectedDistributor = null;
    this.selectedCompany = null;
    this.selectedReceiver = null;
    this.showReceiverFilters = false;
  };

  setDistributor = () => {
    const { distributor } = this.authService.user;
    this.distributor = distributor;
  };

  canOpenDistributors = () => {
    return this.distributor && this.allStates.includes(this.state); 
  };

  calcOffset = () => {
    const isSmallScreen = window.innerWidth <= 1300;

    let sideMenu = 205;
    let noneOpen = 75;

    if (isSmallScreen) {
      sideMenu = 160;
      noneOpen = 65;
    }

    return this.showMenu ? sideMenu : noneOpen;
  };

  enableFilter = () => {
    return this.distributor && this.receiverService.receivers?.length > 1;
  };

  checkSelectedPanel = () => {
    if (
      this.panelStatus.selectedPanel &&
      !this.receiverService.selectedReceiver.includes(this.panelStatus.selectedPanel.apiKey)
    ) {
      this.panelStatus.resetMapSelection();
    }
  };

  showCompanies = () => {
    return this.authService.getCompanies().length > 1;
  };

  onSettings = () => {
    this.setToggleOpen()
    if (this.router.url !== '/settings') {
      this.router.navigateByUrl('settings');
    }
  }
}
