import { Injectable } from '@angular/core';
import { CompanyType, Steps, Sections } from '../constants';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  loading = false;
  currentSection = Sections.Info;
  currentStep = Steps.INFO;
  screen = Steps.INFO;

  private readonly backToMainEvent = new BehaviorSubject<boolean>(false);
  backToMainEvent$ = this.backToMainEvent.asObservable();

  private readonly refetchCompanies = new BehaviorSubject<boolean>(false);
  refetchCompanies$ = this.refetchCompanies.asObservable();

  companies: CompanyType[] = []
  currentCompany: CompanyType;
  
  settingsAvailableRoles = ['ADMIN'];

  constructor() {}

  onReset = () => {
    this.currentSection = Sections.Info;
    this.resetEditScreen();
  }

  goToInfo = () => {
    this.currentSection = Sections.Info;
    this.resetEditScreen();
  }

  removeCompany(companyID) {
    this.companies = this.companies.filter(c => c._id != companyID)
  }

  getCompany(companyID) {
    return this.companies.find(c => c._id === companyID);
  }

  resetEditScreen = () => {
    this.currentStep = Steps.INFO;
    this.screen = Steps.INFO;
  }

  onEdit = (company) => {
    this.currentCompany = company;
    this.currentSection = Sections.Edit;
    this.resetEditScreen();
  }

  onCreate = () => {
    this.currentCompany = new CompanyType();
    this.currentSection = Sections.Create;
    this.resetEditScreen();
  }

  onBack = () => {
    this.currentSection = Sections.Info;
  }

  setBackToMainEvent(value: boolean): void {
    this.backToMainEvent.next(value);
  }

  setRefetchCompanies(value: boolean): void {
    this.refetchCompanies.next(value);
  }
}
