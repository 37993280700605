<mat-card class="container">
    <mat-card-header>
        <mat-card-title>
        {{'TechSupport.CommandState' | translate}}
        </mat-card-title>
    </mat-card-header>
    <div style="padding: 0.1%;">
        <div style="overflow-y: auto; height: 500px;" class="scrollbar">
            <table class="table table-bordered table-sm table-hover">
                <thead>
                    <tr class="d-flex" style="font-size: 15px;">
                        <th scope="col" class="col-1 text-sm-left align-left" style="width: 50%; padding-top: 10px;">{{'TechSupport.Commands' | translate}}</th>
                        <th scope="col" class="col-1 text-sm-left align-left" style="padding-top: 10px;">{{'TechSupport.Results' | translate}}</th>
                    </tr>
                </thead>
                <tbody style="font-size: 14px;">
                    <ng-container *ngFor="let command of getDisplayCommandList()">
                        <tr class="d-flex tr" [style.background-color]="!isParLine(command) ? '#ffeeb3' : 'white'">
                            <td class="col-3 text-sm-left align-left"> {{command.name}} </td>
                            <td class="col-1 text-sm-right align-right">{{command.result}}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</mat-card>
